import React from "react";
import {
  CardHeader,
  Button,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import { verifyToken } from "components/Functions/Functions";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import jsPDF from "jspdf";
import Img from "../../assets/img/theme/site-logo.png";
import axiosInstance from "axiosInstance";
import { RotatingLines } from "react-loader-spinner";
import moment from "moment";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";

function RentRollReport() {
  const [loader, setLoader] = useState(false);
  const [rentalsData, setRentalsData] = useState([]);
  const [accessType, setAccessType] = useState(null);
  const [rentRollData, setRentRollData] = useState(null);
  const [selectedRentalOwners, setSelectedRentalOwners] = useState([]);
  const [activeButton, setActiveButton] = useState("Details");
  const [grandTotal, setGrandTotal] = useState({});
  const [bedBathSummaryData, setBedBathSummaryData] = useState([]);
  const [totalsAndAveragesBedBath, setTotalsAndAveragesBedBath] =
    useState(null);
  const [PropertySummary, setPropertySummary] = useState([]);
  const [totalsAndAveragesProperty, settotalsAndAveragesProperty] =
    useState(null);
  const [rentalOwnerData, setRentalOwnerData] = useState([]);

  const [rentalownerdropdownopen, setRentalownerDropdownOpen] = useState(false);
  const toggle3 = () => setRentalownerDropdownOpen((prevState) => !prevState);

  const [cookies, setCoockie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();

  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const fetchRentalsData = async () => {
    setLoader(true);
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rental-owners/${accessType?.admin_id}`
        );
        if (response.status === 200) {
          const slicedData = response.data;
          setRentalsData(slicedData);
          setLoader(false);
        } else {
          console.error("Invalid API response structure: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching rentals data: ", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    fetchRentalsData();
  }, [accessType]);

  const fetchRentRollData = async () => {
    setActiveButton("Details");
    setLoader(true);
    try {
      const response = await axiosInstance.get(
        `/rental_owner/rent-roll-report/${accessType.admin_id}`,
        { params: { rentalowner_id: selectedRentalOwners } }
      );
      if (response.data?.statusCode === 200) {
        const data = response.data?.data;

        setRentRollData(data);
        setGrandTotal(data.grandTotal);
        setBedBathSummaryData(data.BedBathSummary.bedBathSummary);
        setTotalsAndAveragesBedBath(
          data.BedBathSummary.totalsAndAveragesBedBath
        );
        setPropertySummary(data.PropertySummary.propertySummary);
        settotalsAndAveragesProperty(
          data.PropertySummary.totalsAndAveragesProperty
        );
        setRentalOwnerData(data.rentalOwners);
      } else {
        console.error(response.data?.message);
      }
    } catch (error) {
      console.error("Error fetching rent roll data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchRentRollData();
  }, [accessType]);

  const handleRentalOwnerSelect = (owner) => {
    if (owner === "ALL") {
      setSelectedRentalOwners(rentalsData.map((e) => e.rentalowner_id));
    } else {
      setSelectedRentalOwners([owner?.rentalowner_id]);
    }
  };

  const getSelectedOwnersLabel = () => {
    if (selectedRentalOwners.length === 0) return "Select Rental Owners";
    if (selectedRentalOwners.length === rentalsData.length) return "ALL";

    const selectedNames = rentalsData
      .filter((owner) => selectedRentalOwners.includes(owner?.rentalowner_id))
      .map((owner) => owner?.rentalOwner_name);

    return selectedNames.join(", ");
  };

  const formatCurrency = (amount) => {
    if (amount < 0) {
      return `($${new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Math.abs(amount))})`;
    }
    return `$${new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount ?? 0)}`;
  };

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data?.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
      console.log(option);
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("l");

    // Add logo
    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    // Add title
    const titleYPosition = 10 + 10;
    doc.setFontSize(20);
    doc.text(
      "RentRoll Report",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    const currentDate = moment().format("YYYY-MM-DD");
    const rentalOwnerName =
      rentalOwnerData.length === 1
        ? rentalOwnerData[0].rentalOwner_name
        : "All";

    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(14);
    doc.text(
      `As of ${currentDate} - ${rentalOwnerName}, Current Leases, All Units`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add contact information
    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state].filter(Boolean).join(", "),
      [userProfile?.country, userProfile?.postal_code]
        .filter(Boolean)
        .join(", "),
    ]
      .filter(Boolean)
      .join("\n");
    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    // Table Headers
    const tableColumns = [
      "Unit",
      "Tenants",
      "Lease Start",
      "Lease End",
      "Bed/Bath",
      "Rent Cycle",
      "Rent Start",
      "Rent",
      "Charges",
      "Credits",
      "Total",
      "Deposits",
      "Prepayments",
      "Balance",
    ];

    let tableRows = [];

    rentRollData?.rentals?.forEach((rental) => {
      if (rental?.activeLeases?.length > 0) {
        tableRows.push([
          {
            content: rental.rentalAddress,
            colSpan: 14,
            styles: { fontStyle: "bold", fontSize: 10 },
          },
        ]);

        rental?.activeLeases?.forEach((lease) => {
          tableRows.push([
            { content: lease.unit?.rental_unit || "N/A" },
            {
              content: lease.tenants
                .map(
                  (tenant) =>
                    `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
                )
                .join(", "),
            },
            { content: moment(lease.lease_start).format(dateFormat) },
            { content: moment(lease.lease_end).format(dateFormat) },
            { content: lease.unit?.bed_bath || "- / -" },
            { content: lease.rent_cycle },
            { content: moment(lease.lease_start).format(dateFormat) },
            {
              content: formatCurrency(lease.rent_amount),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.charge_amount),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.credit_amount),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.charge_total),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.deposit_held),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.prepayments),
              styles: { halign: "right" },
            },
            {
              content: formatCurrency(lease.balance_due),
              styles: { halign: "right" },
            },
          ]);
        });

        tableRows.push([
          {
            content: `Total for ${rental.rentalAddress}`,
            colSpan: 7,
            styles: { fontStyle: "bold" },
          },
          {
            content: formatCurrency(rental.totals.totalRent),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalCharges),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalCredits),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalAmount),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalDeposits),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalPrepayments),
            styles: { fontStyle: "bold", halign: "right" },
          },
          {
            content: formatCurrency(rental.totals.totalBalanceDue),
            styles: { fontStyle: "bold", halign: "right" },
          },
        ]);
      }
    });
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 50,
      styles: { fontSize: 9, cellPadding: 2 },
      theme: "plain",
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    });

    doc.addPage();
    doc.setFontSize(12);
    doc.text("Grand Totals", 14, 18);

    doc.autoTable({
      startY: 20,
      head: [["", { content: "Amount", style: { halign: "right" } }]],
      body: [
        ["Market Rent", formatCurrency(grandTotal.totalMarketRent)],
        ["Rent", formatCurrency(grandTotal.totalRent)],
        ["Recurring Charges", formatCurrency(grandTotal.totalCharges)],
        ["Recurring Credits", formatCurrency(grandTotal.totalCredits)],
        ["Deposits Held", formatCurrency(grandTotal.totalDeposits)],
        ["Balance Due", formatCurrency(grandTotal.totalBalanceDue)],
      ],
      styles: { fontSize: 10 },
      theme: "plain",
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      // columnStyles: { 1: { halign: "right" } },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 40, halign: "right" },
      },
      rowStyles: { minCellHeight: 3 },
    });

    const finalY = doc.lastAutoTable?.finalY || 8;
    doc.setFontSize(16);

    doc.autoTable({
      startY: finalY + 8,
      body: [
        [
          {
            content: "Summary by Bed/Bath",
            colSpan: 2,
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: "Occupancy",
            colSpan: 3,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "Square Feet",
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "Market Rent",
            colSpan: 3,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
      ],
      theme: "plain",
      styles: { fontSize: 10 },
    });

    doc.autoTable({
      startY: finalY + 15,
      head: [
        [
          {
            content: "Bed/Bath",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: "No. of Units",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Vacant", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Occupied",
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content: "% Occupied",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Total", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Average",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Total", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Average",
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content: "Avg/Sq.Ft.",
            styles: { halign: "right", fontStyle: "bold" },
          },
        ],
      ],
      body: bedBathSummaryData
        .map((item) => [
          item.bedBath,
          item.units,
          item.vacantUnits,
          item.occupiedUnits,
          `${item.occupancyRate}%`,
          item.totalSqFt,
          item.avgSqFt,
          item.totalRent,
          item.avgRent,
          item.avgRentPerSqFt,
        ])
        .concat([
          [
            { content: "Totals and Averages", styles: { fontStyle: "bold" } },
            {
              content: totalsAndAveragesBedBath.totalUnits,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.totalVacantUnits,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.totalOccupiedUnits,
              styles: { fontStyle: "bold" },
            },
            {
              content: `${totalsAndAveragesBedBath.avgOccupancyRate}%`,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.totalSqFt,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.avgSqFt,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.totalMarketRent,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.avgMarketRent,
              styles: { fontStyle: "bold" },
            },
            {
              content: totalsAndAveragesBedBath.avgMarketRentPerSqFt,
              styles: { fontStyle: "bold" },
            },
          ],
        ]),
      theme: "plain",
      styles: { fontSize: 10 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      columnStyles: {
        1: { halign: "right" },
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
        6: { halign: "right" },
        7: { halign: "right" },
        8: { halign: "right" },
        9: { halign: "right" },
      },
    });

    const finalY2 = doc.lastAutoTable?.finalY || 8;
    doc.setFontSize(16);

    doc.autoTable({
      startY: finalY2 + 8,
      body: [
        [
          {
            content: "Summary by Property",
            colSpan: 2,
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: "Occupancy",
            colSpan: 3,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "Square Feet",
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
          {
            content: "Market Rent",
            colSpan: 3,
            styles: { halign: "center", fontStyle: "bold" },
          },
        ],
      ],
      theme: "plain",
      styles: { fontSize: 10 },
    });

    doc.autoTable({
      startY: finalY2 + 15,
      head: [
        [
          {
            content: "Property",
            styles: { halign: "left", fontStyle: "bold" },
          },
          {
            content: "No. of Units",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Vacant", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Occupied",
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content: "% Occupied",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Total", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Average",
            styles: { halign: "right", fontStyle: "bold" },
          },
          { content: "Total", styles: { halign: "right", fontStyle: "bold" } },
          {
            content: "Average",
            styles: { halign: "right", fontStyle: "bold" },
          },
          {
            content: "Avg/Sq.Ft.",
            styles: { halign: "right", fontStyle: "bold" },
          },
        ],
      ],
      body: PropertySummary.map((item) => [
        item.property,
        item.units,
        item.vacantUnits,
        item.occupiedUnits,
        `${item.occupancyRate}%`,
        item.totalSqFt,
        item.avgSqFt,
        item.totalRent,
        item.avgRent,
        item.avgRentPerSqFt,
      ]).concat([
        [
          { content: "Totals and Averages", styles: { fontStyle: "bold" } },
          {
            content: totalsAndAveragesProperty?.totalUnits,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.totalVacantUnits,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.totalOccupiedUnits,
            styles: { fontStyle: "bold" },
          },
          {
            content: `${totalsAndAveragesProperty?.avgOccupancyRate}%`,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.totalSqFt,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.avgSqFt,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.totalMarketRent,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.avgMarketRent,
            styles: { fontStyle: "bold" },
          },
          {
            content: totalsAndAveragesProperty?.avgMarketRentPerSqFt,
            styles: { fontStyle: "bold" },
          },
        ],
      ]),
      theme: "plain",
      styles: { fontSize: 10, cellPadding: 2 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      columnStyles: {
        1: { halign: "right" },
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
        6: { halign: "right" },
        7: { halign: "right" },
        8: { halign: "right" },
        9: { halign: "right" },
      },
    });

    // Save PDF
    doc.save("RentRoll_Report.pdf");
  };

  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];

    const headers = [
      "Unit",
      "Tenants",
      "Lease Start",
      "Lease End",
      "Bed/Bath",
      "Rent Cycle",
      "Rent Start",
      "Rent",
      "Charges",
      "Credits",
      "Total",
      "Deposits Held",
      "PrePayments",
      "Balance Due",
    ];

    wsData.push(headers);

    // Header Style
    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" }, sz: 14 },
      border: {
        top: { style: "thin", color: "000000" },
        bottom: { style: "thin", color: "000000" },
        left: { style: "thin", color: "000000" },
        right: { style: "thin", color: "000000" },
      },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "5A86D7" } },
    };

    // Add rental data to the sheet
    rentRollData.rentals.forEach((rental) => {
      if (rental.activeLeases.length > 0) {
        wsData.push([
          {
            v: rental.rentalAddress || "N/A",
            s: { font: { bold: true, sz: 12 } },
          },
        ]);

        rental.activeLeases.forEach((lease) => {
          const leaseData = [
            { v: lease.unit.rental_unit || "N/A", s: { font: { sz: 12 } } },
            {
              v:
                lease.tenants
                  ?.map(
                    (tenant) =>
                      `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
                  )
                  .join(", ") || "N/A",
              s: { font: { sz: 12 } },
            },
            {
              v: moment(lease.lease_start).format(dateFormat) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: moment(lease.lease_end).format(dateFormat) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: `${lease.unit.bed_bath}`,
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: lease.rent_cycle || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: moment(lease.lease_start).format(dateFormat) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.rent_amount) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.charge_amount) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.credit_amount) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.charge_total) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.deposit_held) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.prepayments) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
            {
              v: formatCurrency(lease.balance_due) || "N/A",
              s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
            },
          ];
          wsData.push(leaseData);
        });

        // Rental totals
        const rentalTotals = [
          { v: `Total for ${rental.rentalAddress}`, s: { font: { sz: 12 } } },
          "",
          "",
          "",
          "",
          "",
          "",
          {
            v: formatCurrency(rental.totals.totalRent) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalCharges) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalCredits) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalAmount) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalDeposits) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalPrepayments) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
          {
            v: formatCurrency(rental.totals.totalBalanceDue) || "N/A",
            s: {
              font: { bold: true, sz: 12 },
              alignment: { horizontal: "right" },
            },
          },
        ];
        wsData.push(rentalTotals);
        wsData.push([]);
      }
    });

    // Grand totals
    wsData.push([]);
    wsData.push([
      {
        v: "Grand Totals",
        s: {
          font: { sz: 14, bold: true },
          alignment: { horizontal: "center" },
        },
      },
    ]);
    wsData.push(["", { v: "Amounts", s: { font: { bold: true } } }]);
    Object.entries(grandTotal).forEach(([key, value]) => {
      wsData.push([
        { v: key, s: { font: { bold: true } } },
        { v: formatCurrency(value), s: { alignment: { horizontal: "right" } } },
      ]);
    });
    wsData.push([]);
    wsData.push([]);

    // Bed/Bath summary
    wsData.push([
      { v: "Summary by Bed/Bath", s: { font: { sz: 14, bold: true } } },
      "",
      { v: "Occupancy", s: { font: { sz: 14, bold: true } } },
      "",
      "",
      { v: "Square Feet", s: { font: { sz: 14, bold: true } } },
      "",
      { v: "Market Rent", s: { font: { sz: 14, bold: true } } },
    ]);
    const bedBathHeader = [
      "Bed/Bath",
      "No. of Units",
      "Vacant",
      "Occupied",
      "% Occupied",
      "Total SqFt",
      "Avg SqFt",
      "Total Rent",
      "Avg Rent",
      "Avg Rent/SqFt",
    ];
    wsData.push(
      bedBathHeader.map((cell) => ({ v: cell, s: { font: { bold: true } } }))
    );
    bedBathSummaryData.forEach((summary) => {
      wsData.push([
        { v: summary.bedBath },
        { v: summary.units, s: { alignment: { horizontal: "right" } } },
        { v: summary.vacantUnits, s: { alignment: { horizontal: "right" } } },
        { v: summary.occupiedUnits, s: { alignment: { horizontal: "right" } } },
        {
          v: `${summary.occupancyRate}%`,
          s: { alignment: { horizontal: "right" } },
        },
        { v: summary.totalSqFt, s: { alignment: { horizontal: "right" } } },
        { v: summary.avgSqFt, s: { alignment: { horizontal: "right" } } },
        {
          v: formatCurrency(summary.totalRent),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: formatCurrency(summary.avgRent),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: formatCurrency(summary.avgRentPerSqFt),
          s: { alignment: { horizontal: "right" } },
        },
      ]);
    });

    // Totals and Averages for Bed/Bath
    wsData.push([
      { v: "Totals and Averages", s: { font: { bold: true } } },
      {
        v: totalsAndAveragesBedBath.totalUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesBedBath.totalVacantUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesBedBath.totalOccupiedUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: `${totalsAndAveragesBedBath.avgOccupancyRate}%`,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesBedBath.totalSqFt,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesBedBath.avgSqFt,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesBedBath.totalMarketRent),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesBedBath.avgMarketRent),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesBedBath.avgMarketRentPerSqFt),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
    ]);
    wsData.push([]);
    wsData.push([]);

    // Property Summary
    wsData.push([
      { v: "Summary by Property", s: { font: { sz: 14, bold: true } } },
      "",
      { v: "Occupancy", s: { font: { sz: 14, bold: true } } },
      "",
      "",
      { v: "Square Feet", s: { font: { sz: 14, bold: true } } },
      "",
      { v: "Market Rent", s: { font: { sz: 14, bold: true } } },
    ]);
    const propertyHeader = [
      "Property",
      "No. of Units",
      "Vacant",
      "Occupied",
      "% Occupied",
      "Total SqFt",
      "Avg SqFt",
      "Total Rent",
      "Avg Rent",
      "Avg Rent/SqFt",
    ];
    wsData.push(
      propertyHeader.map((cell) => ({ v: cell, s: { font: { bold: true } } }))
    );
    PropertySummary.forEach((summary) => {
      wsData.push([
        { v: summary.property },
        { v: summary.units, s: { alignment: { horizontal: "right" } } },
        { v: summary.vacantUnits, s: { alignment: { horizontal: "right" } } },
        { v: summary.occupiedUnits, s: { alignment: { horizontal: "right" } } },
        {
          v: `${summary.occupancyRate}%`,
          s: { alignment: { horizontal: "right" } },
        },
        { v: summary.totalSqFt, s: { alignment: { horizontal: "right" } } },
        { v: summary.avgSqFt, s: { alignment: { horizontal: "right" } } },
        {
          v: formatCurrency(summary.totalRent),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: formatCurrency(summary.avgRent),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: formatCurrency(summary.avgRentPerSqFt),
          s: { alignment: { horizontal: "right" } },
        },
      ]);
    });

    // Totals and Averages for Property
    wsData.push([
      { v: "Totals and Averages", s: { font: { bold: true } } },
      {
        v: totalsAndAveragesProperty.totalUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesProperty.totalVacantUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesProperty.totalOccupiedUnits,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: `${totalsAndAveragesProperty.avgOccupancyRate}%`,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesProperty.totalSqFt,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: totalsAndAveragesProperty.avgSqFt,
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesProperty.totalMarketRent),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesProperty.avgMarketRent),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
      {
        v: formatCurrency(totalsAndAveragesProperty.avgMarketRentPerSqFt),
        s: { alignment: { horizontal: "right" }, font: { bold: true } },
      },
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    ws["!cols"] = headers.map(() => ({ width: 20 }));

    // Apply header style to header row
    headers.forEach((_, i) => {
      ws[XLSX.utils.encode_cell({ r: 0, c: i })].s = headerStyle;
    });

    XLSX.utils.book_append_sheet(wb, ws, "RentRoll Report");
    XLSX.writeFile(wb, "RentRoll_Report.xlsx");
  };

  const generateCSV = () => {
    console.log("Generating CSV");
    const formatValue = (value) =>
      value !== undefined && value !== null ? `"${value}"` : `"N/A"`;
    let csvData = [];

    const headers = [
      "Unit",
      "Tenants",
      "Lease Start",
      "Lease End",
      "Bed/Bath",
      "Rent Cycle",
      "Rent Start",
      "Rent",
      "Charges",
      "Credits",
      "Total",
      "Deposits Held",
      "PrePayments",
      "Balance Due",
    ];

    csvData?.push(headers.join(","));

    rentRollData.rentals.map((rental) => {
      if (rental.activeLeases.length > 0) {
        csvData?.push([`"${rental.rentalAddress || "N/A"}"`].join(","));

        // const formatValue = (value) =>
        //   value !== undefined && value !== null ? `"${value}"` : `"N/A"`;

        rental.activeLeases.forEach((lease) => {
          const tenantNames =
            lease.tenants
              ?.map(
                (tenant) =>
                  `${tenant?.tenant_firstName || ""} ${
                    tenant?.tenant_lastName || ""
                  }`
              )
              .join(", ") || "N/A";

          csvData.push(
            [
              formatValue(lease.unit?.rental_unit),
              formatValue(tenantNames),
              formatValue(lease.lease_start),
              formatValue(lease.lease_end),
              formatValue(lease.unit?.bed_bath),
              formatValue(lease.rent_cycle),
              formatValue(lease.lease_start),
              `"${formatCurrency(lease.rent_amount)}"`,
              `"${formatCurrency(lease.charge_amount)}"`,
              `"${formatCurrency(lease.credit_amount)}"`,
              `"${formatCurrency(lease.charge_total)}"`,
              `"${formatCurrency(lease.deposit_held)}"`,
              `"${formatCurrency(lease.prepayments)}"`,
              `"${formatCurrency(lease.balance_due)}"`,
            ].join(",")
          );
        });

        csvData.push([
          `"Total for ${rental.rentalAddress}"`,
          "",
          "",
          "",
          "",
          "",
          "",
          `"${formatCurrency(rental.totals.totalRent)}"`,
          `"${formatCurrency(rental.totals.totalCharges)}"`,
          `"${formatCurrency(rental.totals.totalCredits)}"`,
          `"${formatCurrency(rental.totals.totalAmount)}"`,
          `"${formatCurrency(rental.totals.totalDeposits)}"`,
          `"${formatCurrency(rental.totals.totalPrepayments)}"`,
          `"${formatCurrency(rental.totals.totalBalanceDue)}"`,
        ]);
        csvData.push([]);
      }
    });
    csvData.push([]);

    csvData.push(["Grand Totals"]);
    csvData.push(["", "Amounts"]);
    Object.entries(grandTotal).forEach(([key, value]) => {
      csvData.push([key, formatCurrency(value)]);
    });
    csvData.push([]);
    csvData.push([]);

    // Bed/Bath summary
    csvData.push([
      "Summary by Bed/Bath",
      "",
      "Occupancy",
      "",
      "",
      "Square Feet",
      "",
      "Market Rent",
    ]);
    const bedBathHeader = [
      "Bed/Bath",
      "No. of Units",
      "Vacant",
      "Occupied",
      "% Occupied",
      "Total SqFt",
      "Avg SqFt",
      "Total Rent",
      "Avg Rent",
      "Avg Rent/SqFt",
    ];
    csvData?.push(bedBathHeader.join(","));

    bedBathSummaryData.forEach((summary) => {
      csvData.push([
        formatValue(summary.bedBath),
        formatValue(summary.units),
        formatValue(summary.vacantUnits),
        formatValue(summary.occupiedUnits),
        formatValue(summary.occupancyRate),
        formatValue(summary.totalSqFt),
        formatValue(summary.avgSqFt),
        `"${formatCurrency(summary.totalRent)}"`,
        `"${formatCurrency(summary.avgRent)}"`,
        `"${formatCurrency(summary.avgRentPerSqFt)}"`,
      ]);
    });

    csvData.push([
      "Totals and Averages",
      formatValue(totalsAndAveragesBedBath.totalUnits),
      formatValue(totalsAndAveragesBedBath.totalVacantUnits),
      formatValue(totalsAndAveragesBedBath.totalOccupiedUnits),
      formatValue(totalsAndAveragesBedBath.avgOccupancyRate),
      formatValue(totalsAndAveragesBedBath.totalSqFt),
      formatValue(totalsAndAveragesBedBath.avgSqFt),
      `"${formatCurrency(totalsAndAveragesBedBath.totalMarketRent)}"`,
      `"${formatCurrency(totalsAndAveragesBedBath.avgMarketRent)}"`,
      `"${formatCurrency(totalsAndAveragesBedBath.avgMarketRentPerSqFt)}"`,
    ]);
    csvData.push([]);
    csvData.push([]);

    // Property Summary
    csvData.push([
      "Summary by Bed/Bath",
      "",
      "Occupancy",
      "",
      "",
      "Square Feet",
      "",
      "Market Rent",
    ]);
    const propertyHeader = [
      "Property",
      "No. of Units",
      "Vacant",
      "Occupied",
      "% Occupied",
      "Total SqFt",
      "Avg SqFt",
      "Total Rent",
      "Avg Rent",
      "Avg Rent/SqFt",
    ];
    csvData?.push(propertyHeader.join(","));

    PropertySummary.forEach((summary) => {
      csvData.push([
        formatValue(summary.property),
        formatValue(summary.units),
        formatValue(summary.vacantUnits),
        formatValue(summary.occupiedUnits),
        formatValue(summary.occupancyRate),
        formatValue(summary.totalSqFt),
        formatValue(summary.avgSqFt),
        `"${formatCurrency(summary.totalRent)}"`,
        `"${formatCurrency(summary.avgRent)}"`,
        `"${formatCurrency(summary.avgRentPerSqFt)}"`,
      ]);
    });

    // Totals and Averages for Property
    csvData.push([
      "Totals and Averages",
      formatValue(totalsAndAveragesProperty.totalUnits),
      formatValue(totalsAndAveragesProperty.totalVacantUnits),
      formatValue(totalsAndAveragesProperty.totalOccupiedUnits),
      formatValue(totalsAndAveragesProperty.avgOccupancyRate),
      formatValue(totalsAndAveragesProperty.totalSqFt),
      formatValue(totalsAndAveragesProperty.avgSqFt),
      `"${formatCurrency(totalsAndAveragesProperty.totalMarketRent)}"`,
      `"${formatCurrency(totalsAndAveragesProperty.avgMarketRent)}"`,
      `"${formatCurrency(totalsAndAveragesProperty.avgMarketRentPerSqFt)}"`,
    ]);

    const csvString = csvData?.join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `RentalRoll_report.csv`);
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Rent Roll Report
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-2 align-items-center">
          <Col lg="4" md="12" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Rental Owner
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle rentalownerdrops"
                isOpen={rentalownerdropdownopen}
                toggle={toggle3}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {getSelectedOwnersLabel()}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    key="ALL"
                    className="dropdownfontsyle"
                    onClick={() => handleRentalOwnerSelect("ALL")}
                  >
                    <span>All</span>
                  </DropdownItem>
                  {rentalsData?.map((owner) => (
                    <DropdownItem
                      key={owner?.rentalowner_id}
                      className="dropdownfontsyle"
                      onClick={() => handleRentalOwnerSelect(owner)}
                    >
                      <span>{owner?.rentalOwner_name}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col className="d-flex justify-content-end addtotnstyle mt-4">
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={fetchRentRollData}
              style={{
                height: "46px",
                fontSize: "16px",
                border: "1px solid #152B5180",
                boxShadow: "0px 4px 4px 0px #00000040",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Run
            </Button>

            {rentRollData?.rentals.length > 0 && (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  caret
                  style={{
                    marginBottom: "12px",
                    fontSize: "16px",
                    border: "1px solid #152B5180",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Exports
                </DropdownToggle>
                <DropdownMenu style={{ minWidth: "120px" }}>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("PDF")}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("XLSX")}
                  >
                    XLSX
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("CSV")}
                  >
                    CSV
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
        </Row>
        <div className="table-responsive">
          <Col>
            <ToggleButtonGroup
              color="primary"
              exclusive
              // onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton
                className="labelfontstyle fontfamilysty"
                value="Details"
                style={{
                  border: "none",
                  background: "none",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: activeButton === "Details" ? "#152B51" : "inherit",
                  borderBottom:
                    activeButton === "Details" ? "2px solid #152B51" : "none",
                  borderRadius: "0px",
                }}
                onClick={() => setActiveButton("Details")}
              >
                Details
              </ToggleButton>

              <ToggleButton
                className="labelfontstyle fontfamilysty"
                value="Summary"
                style={{
                  border: "none",
                  background: "none",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  color: activeButton === "Summary" ? "#152B51" : "inherit",
                  borderBottom:
                    activeButton === "Summary" ? "2px solid #152B51" : "none",
                  borderRadius: "0px",
                }}
                onClick={() => setActiveButton("Summary")}
              >
                Summary
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </div>

        {loader ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loader}
            />
          </div>
        ) : (
          <Table
            className="align-items-center table-flush mt-4 "
            responsive
            style={{ borderCollapse: "collapse" }}
          >
            {activeButton === "Details" && (
              <>
                <thead
                  className="fontfamilysty"
                  style={{
                    height: "45px",
                    fontSize: "14px",
                    fontWeight: "600",
                    border: 0,
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <tr
                    style={{
                      borderBottom: "2px solid rgba(50, 69, 103, 1)",
                    }}
                  >
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Unit
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Tenants
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Lease Start
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Lease End
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Bed/Bath
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Rent Cycle
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Rent Start
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Rent
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Charges
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Credits
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{
                        borderTopLeftRadius: "15px",
                        border: 0,
                      }}
                    >
                      Total
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Deposits Held
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Prepayments
                    </th>
                    <th
                      className="tablefontstyle textcolorblue"
                      scope="col"
                      style={{ border: 0 }}
                    >
                      Balance Due
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rentRollData?.rentals
                    ?.filter((item) => item?.activeLeases?.length > 0)
                    ?.map((rental) => (
                      <>
                        <tr
                          //   key={tenant?.transaction_id}
                          style={{
                            cursor: "pointer",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td
                            colSpan="9"
                            className="rentrollcolorsty"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              fontSize: "18px",
                            }}
                          >
                            {rental?.rentalAddress}
                          </td>
                        </tr>
                        {rental?.activeLeases?.map((lease) => (
                          <tr
                            className="fontfamilysty"
                            style={{
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                              lineHeight: "1.2",
                              border: 0,
                            }}
                          >
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{
                                width: "210px",
                                border: 0,
                              }}
                            >
                              <span>{lease.unit?.rental_unit || "N/A"}</span>
                              <br />
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {lease.tenants.length > 0 ? (
                                lease.tenants.map((tenant, index) =>
                                  index === 0 ? (
                                    <>
                                      {tenant.tenant_firstName +
                                        " " +
                                        tenant.tenant_lastName}
                                    </>
                                  ) : (
                                    <>
                                      ,
                                      <br />
                                      {tenant.tenant_firstName +
                                        " " +
                                        tenant.tenant_lastName}
                                    </>
                                  )
                                )
                              ) : (
                                <span>{"-"}</span>
                              )}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {moment(lease.lease_start).format(dateFormat)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {moment(lease.lease_end).format(dateFormat)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0" }}
                            >
                              {lease.unit?.bed_bath || "- / -"}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0" }}
                            >
                              {lease.rent_cycle}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0" }}
                            >
                              {moment(lease.lease_start).format(dateFormat)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor border-r-2 border-gray-400"
                              style={{
                                border: 0,
                                textAlign: "right",
                              }}
                            >
                              {formatCurrency(lease.rent_amount)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0, textAlign: "right" }}
                            >
                              {formatCurrency(lease.charge_amount)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0, textAlign: "right" }}
                            >
                              {formatCurrency(lease.credit_amount)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0, textAlign: "right" }}
                            >
                              {formatCurrency(lease.charge_total)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0", textAlign: "right" }}
                            >
                              {formatCurrency(lease.deposit_held)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0, textAlign: "right" }}
                            >
                              {formatCurrency(lease.prepayments)}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0", textAlign: "right" }}
                            >
                              {formatCurrency(lease.balance_due)}
                            </td>
                          </tr>
                        ))}
                        <tr
                          className="text tablebodyfont tabletextcolor"
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            border: "0",
                          }}
                        >
                          <td
                            className="p-3"
                            colSpan="7"
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              border: "0",
                            }}
                          >
                            Total for {rental?.rentalAddress}
                          </td>
                          <td
                            className="tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalRent)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalCharges)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalCredits)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalAmount)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalDeposits)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalPrepayments)}
                          </td>
                          <td
                            className="p-3 tablebodyfont"
                            style={{
                              fontWeight: "bold",
                              border: "0",
                              textAlign: "right",
                            }}
                          >
                            {formatCurrency(rental.totals.totalBalanceDue)}
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan="14" style={{ padding: "0px 0" }}>
                            <hr
                              style={{
                                borderTop: "2px solid #525f7f",
                                margin: "10px 0",
                              }}
                            />
                          </td>
                        </tr> */}
                      </>
                    ))}
                </tbody>
              </>
            )}

            {activeButton === "Summary" && (
              <>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{
                      border: 0,
                      fontWeight: "bold",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      color: "#525f7f",
                    }}
                  >
                    Grand totals
                  </TableCell>
                </TableRow>

                <TableContainer component={Paper} className="overflow-x-auto">
                  <Table className="w-100 border-collapse border tablebodyfont tabletextcolor">
                    <TableHead>
                      <TableRow style={{ border: "2px solid #525f7f" }}>
                        <TableCell
                          colSpan={12}
                          align="right"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                            padding: "8px",
                            color: "#525f7f",
                          }}
                        >
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        {
                          label: "Market Rent",
                          value: grandTotal.totalMarketRent,
                        },
                        { label: "Rent", value: grandTotal.totalRent },
                        {
                          label: "Recurring Charges",
                          value: grandTotal.totalCharges,
                        },
                        {
                          label: "Recurring Credits",
                          value: grandTotal.totalCredits,
                        },
                        {
                          label: "Deposits Held",
                          value: grandTotal.totalDeposits,
                        },
                        {
                          label: "Balance Due",
                          value: grandTotal.totalBalanceDue,
                          isBold: true,
                        },
                      ].map((row, index) => (
                        <TableRow
                          className="tablebodyfont "
                          key={index}
                          style={{
                            fontSize: "12px",
                            height: "30px",
                            fontWeight: row.isBold ? "bold" : "600",
                            color: "#525f7f",
                          }}
                        >
                          <TableCell
                            className="tablebodyfont "
                            colSpan={6}
                            align="left"
                            style={{
                              borderLeft: "2px solid #525f7f",
                              fontSize: "12px",
                              borderBottom: row.isBold
                                ? "2px solid #525f7f"
                                : "none",
                              color: "#525f7f",
                            }}
                          >
                            {row.label}
                          </TableCell>
                          <TableCell
                            colSpan={6}
                            align="right"
                            style={{
                              borderRight: "2px solid #525f7f",
                              fontSize: "15px",
                              borderBottom: row.isBold
                                ? "2px solid #525f7f"
                                : "none",
                              color: "#525f7f",
                            }}
                          >
                            {formatCurrency(row.value)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="overflow-x-auto" style={{ paddingTop: "20px" }}>
                  <table
                    className="w-100 border-collapse border"
                    style={{ border: "2px solid #525f7f" }}
                  >
                    <thead>
                      <tr className="bg-gray-100">
                        <th
                          colSpan="2"
                          className="text-left bgtextwhite fontfamilysty tablebodyfont tabletextcolor"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Summary by bed/bath
                        </th>
                        <th
                          colSpan="3"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Occupancy
                        </th>
                        <th
                          colSpan="2"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Square Feet
                        </th>
                        <th
                          colSpan="3"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Market Rent
                        </th>
                      </tr>
                      <tr className="tablebodyfont tabletextcolor">
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Bed/Bath
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          No. of Units
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Vacant
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Occupied
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          % Occupied
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Total
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Average
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Total
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Average
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Avg./Sq.Ft.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bedBathSummaryData.length > 0 ? (
                        bedBathSummaryData.map((item, index) => (
                          <tr key={index}>
                            <td
                              className=""
                              style={{
                                fontSize: "15px",
                                borderLeft: "2px solid #525f7f",
                              }}
                            >
                              {item.bedBath}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                              }}
                            >
                              {item.units}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.vacantUnits}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.occupiedUnits}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                                fontSize: "15px",
                              }}
                            >
                              {item.occupancyRate}%
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.totalSqFt}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                                fontSize: "15px",
                              }}
                            >
                              {item.avgSqFt}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.totalRent}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.avgRent}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                                borderRight: "2px solid #525f7f",
                              }}
                            >
                              {item.avgRentPerSqFt}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" className="border text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                      <tr
                        className="bg-gray-300"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        <td
                          style={{
                            fontSize: "15px",
                            borderLeft: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          Totals and averages
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.totalUnits || 0}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.totalVacantUnits || 0}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.totalOccupiedUnits || 0}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.avgOccupancyRate}%
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.totalSqFt}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.avgSqFt}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.totalMarketRent}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.avgMarketRent}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesBedBath.avgMarketRentPerSqFt}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="overflow-x-auto" style={{ paddingTop: "20px" }}>
                  <table
                    className="w-100 border-collapse border"
                    style={{ border: "2px solid #525f7f" }}
                  >
                    <thead>
                      <tr className="bg-gray-100">
                        <th
                          colSpan="2"
                          className="text-left bgtextwhite fontfamilysty tablebodyfont tabletextcolor"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Summary by property
                        </th>
                        <th
                          colSpan="3"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Occupancy
                        </th>
                        <th
                          colSpan="2"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Square Feet
                        </th>
                        <th
                          colSpan="3"
                          className="text-center bgtextwhite fontfamilysty background-colorsty"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Market Rent
                        </th>
                      </tr>
                      <tr className="tablebodyfont tabletextcolor">
                        <th
                          className="tablebodyfont tabletextcolor"
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Property
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          No. of Units
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Vacant
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Occupied
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          % Occupied
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Total
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Average
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Total
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                            borderRight: "2px solid white",
                          }}
                        >
                          Average
                        </th>
                        <th
                          className=""
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            border: "2px solid #525f7f",
                          }}
                        >
                          Avg./Sq.Ft.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {PropertySummary.length > 0 ? (
                        PropertySummary.map((item, index) => (
                          <tr key={index}>
                            <td
                              className=""
                              style={{
                                fontSize: "15px",
                                borderLeft: "2px solid #525f7f",
                              }}
                            >
                              {item.property}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                              }}
                            >
                              {item.units}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.vacantUnits}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.occupiedUnits}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                                fontSize: "15px",
                              }}
                            >
                              {item.occupancyRate}%
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.totalSqFt}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                borderRight: "2px solid #525f7f",
                                fontSize: "15px",
                              }}
                            >
                              {item.avgSqFt}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.totalRent}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                              }}
                            >
                              {item.avgRent}
                            </td>
                            <td
                              className=""
                              style={{
                                textAlign: "right",
                                fontSize: "15px",
                                borderRight: "2px solid #525f7f",
                              }}
                            >
                              {item.avgRentPerSqFt}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" className="border text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                      <tr
                        className="bg-gray-300"
                        style={{ fontWeight: "bold" }}
                      >
                        <td
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            borderLeft: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          Totals and averages
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                            borderRight: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.totalUnits}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.totalVacantUnits}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.totalOccupiedUnits}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.avgOccupancyRate}%
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.totalSqFt}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.avgSqFt}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.totalMarketRent}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.avgMarketRent}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            fontSize: "15px",
                            borderRight: "2px solid #525f7f",
                            borderTop: "2px solid #525f7f",
                            borderBottom: "2px solid #525f7f",
                          }}
                        >
                          {totalsAndAveragesProperty?.avgMarketRentPerSqFt}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </Table>
        )}
        <ToastContainer />
      </Container>
    </>
  );
}

export default RentRollReport;
