import {
  CardHeader,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const AccountTotalReport = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  let [transactionData, setTransactionData] = useState([]);
  let [accountData, setAccountData] = useState([]);
  let [amountgrandTotal, setAmountGrandTotal] = useState(null);
  let [reportData, setReportData] = useState([]);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  let [loader, setLoader] = useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [rentalsData, setRentalsData] = useState([]);

  let navigate = useNavigate();
  const location = useLocation();

  const [cookies, setCoockie, removeCookie] = useCookies();

  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [selectedStartDate, setSelectedStartDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    setCurrentPage(1);
  };
  const [selectedEndDate, setSelectedEndDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [isCustomDate, setIsCustomDate] = useState(false);

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  const [selectedChargeType, setSelectedChargeType] =
    useState("Select charge type");

  const fetchRentalsData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rental-owners/${accessType?.admin_id}`
        );
        if (response.status === 200) {
          const slicedData = response.data;
          setRentalsData(slicedData);
        } else {
          console.error("Invalid API response structure: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching rentals data: ", error);
      }
    }
  };

  const [selectedRentalOwnerId, setSelectedRentalOwnerId] = useState("");
  const [chargetypedropdownopen, setChargetypeDropdownOpen] = useState(false);
  const toggle5 = () => setChargetypeDropdownOpen((prevState) => !prevState);

  const getPaymentsData = async () => {
    if (accessType?.admin_id) {
      setLoader(true);
      try {
        const params = {
          start_date: selectedStartDate,
          end_date: selectedEndDate,
          rentalowner_id: selectedRentalOwnerId,
        };

        if (selectedRentalOwnerId) {
          params.rentalowner_id = selectedRentalOwnerId;
        }

        const response = await axiosInstance.get(
          `/rental_owner/accounts-report/${accessType?.admin_id}`,
          { params }
        );

        if (response.data?.data?.length > 0) {
          const data = response?.data?.data;
          //   const total = response?.data?.totals;
          //   const grandtotal = response?.data?.grand_total;
          setTransactionData(data);
          //   setAccountData(total);
          //   setAmountGrandTotal(grandtotal);
        } else {
          setTransactionData([]);
        }
      } catch (error) {
        console.error("Error fetching payments data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    fetchRentalsData();
  }, [accessType]);

  React.useEffect(() => {
    getPaymentsData();
  }, [accessType, selectedStartDate, selectedEndDate, selectedRentalOwnerId]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data?.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    const titleYPosition = 20;
    doc.setFontSize(20);
    doc.text(
      "Account Totals Report",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      `Date: ${selectedStartDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");

    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    const tableYPosition = subtitleYPosition + 8;

    const tableRows = transactionData?.flatMap((transaction) => {
      // const rentalOwnerRow = [
      //   {
      //     content: transaction?.rentalOwner_name || "N/A",
      //     cellPadding: { top: 2, bottom: 0, left: 0, right: 0 },
      //     styles: { fontStyle: "bold", fontSize: 10 },
      //   },
      // ];

      const paymentRows = transaction.payments?.flatMap((payment, index) => {
        const paymentRow = [
          {
            content: index === 0 ? transaction?.rentalOwner_name : "",
            cellPadding: {
              top: index === 0 ? 2 : 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            styles: { fontStyle: "bold", fontSize: 10 },
          },

          {
            content: payment.account,
            styles: {
              //   halign: "left",
              cellPadding: {
                top: index === 0 ? 2 : 0,
                bottom: 0,
                left: 3,
                right: 0,
              },
              fontSize: 9,
              fontStyle: "bold",
            },
          },

          {
            content: `$${
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(payment?.amount) || 0.0
            }`,
            styles: {
              halign: "right",
              fontSize: 9,
              fontStyle: "bold",
              cellPadding: {
                top: index === 0 ? 2 : 0,
                bottom: 0,
                left: 0,
                right: 0,
              },
            },
          },
        ];

        return [paymentRow];
      });

      const subtotalRow = [
        "",
        {
          content: "Sub Total",
          styles: {
            // halign: "left",
            cellPadding: { top: 0, bottom: 0, left: 3, right: 0 },
            fontSize: 9,
            fontStyle: "bold",
          },
        },
        {
          content: `$${
            new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(transaction.sub_total) || 0.0
          }`,
          styles: {
            halign: "right",
            fontSize: 9,
            fontStyle: "bold",
            cellPadding: { top: 0, bottom: 0, left: 0, right: 0 },
          },
        },
      ];

      return [
        // rentalOwnerRow,
        ...paymentRows,
        transaction?.rentalOwner_name !== "Grand Totals" ? subtotalRow : [],
      ];
    });

    doc.autoTable({
      startY: tableYPosition,
      head: [
        [
          {
            content: "Rental Owner",
            styles: {
              cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            },
          },
          {
            content: "Account",
            styles: {
              cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            },
          },
          {
            content: "Amount",
            styles: {
              halign: "right",
              cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            },
          },
        ],
      ],
      body: tableRows,
      theme: "plain",
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 10,
        fontStyle: "bold",
      },
      styles: {
        fontSize: 10,
        cellPadding: 2,
      },
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 70 },
        2: { cellWidth: 30 },
      },
    });

    const pageCount = doc.getNumberOfPages();
    const currentDate = new Date();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `${moment(currentDate).format("YYYY-MM-DD HH:ss:mm")}`,
        10,
        doc.internal.pageSize.getHeight() - 8
      );
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 8,
        { align: "right" }
      );
    }

    doc.save("Account_totals_report.pdf");
  };

  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];

    const headers = ["Rental Owner", "Account", "Amount"];

    wsData?.push(headers);

    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" }, sz: "14" },

      border: {
        top: { style: "thin", color: "000000" },
        bottom: { style: "thin", color: "000000" },
        left: { style: "thin", color: "000000" },
        right: { style: "thin", color: "000000" },
      },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "5A86D7" } },
    };

    transactionData?.forEach((transaction) => {
      wsData?.push([
        {
          v: transaction?.rentalOwner_name || "N/A",
          s: { font: { bold: true, sz: 12 } },
        },
        "",
        "",
      ]);

      // Rows for each payment
      transaction.payments?.forEach((payment) => {
        // Payment row
        wsData?.push([
          "",
          {
            v: payment.account || "",
            s: {
              font: { bold: true },
            },
          },
          {
            v: `$${
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(payment?.amount) || 0.0
            }`,
            s: {
              alignment: { horizontal: "right" },
              font: { bold: true },
            },
          },
        ]);
      });
      wsData?.push(
        transaction?.rentalOwner_name !== "Grand Totals"
          ? [
              "",
              {
                v: `Subtotal`,
                s: { font: { bold: true } },
              },
              {
                v: `$${
                  new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(transaction.sub_total) || 0.0
                }`,
                s: {
                  alignment: { horizontal: "right" },
                  font: { bold: true },
                },
              },
            ]
          : []
      );
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    for (let i = 0; i < headers?.length; i++) {
      ws[XLSX.utils.encode_cell({ r: 0, c: i })].s = headerStyle;
    }

    ws["!cols"] = headers.map(() => ({ width: 20 }));

    for (let rowIndex = 0; rowIndex < wsData?.length; rowIndex++) {
      const row = wsData[rowIndex];
      row?.forEach((cell, colIndex) => {
        if (cell?.s) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = cell?.s;
        }
        if (rowIndex > 0) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = {
            ...ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })]?.s,
          };
        }
      });
    }

    XLSX.utils.book_append_sheet(wb, ws, "Account Total Report");

    XLSX.writeFile(wb, "Account_totals_report.xlsx");
  };

  const generateCSV = () => {
    let csvData = [];

    const headers = ["Rental Owner", "Account", "Amount"];

    csvData?.push(headers.join(","));

    transactionData?.forEach((transaction) => {
      csvData?.push([transaction.rentalOwner_name || "N/A", "", ""].join(","));

      transaction.payments?.forEach((payment) => {
        csvData?.push(
          [
            "",
            payment.account || "",
            `$${payment?.amount?.toFixed(2) || 0.0}`,
          ].join(",")
        );
      });
      csvData?.push(
        transaction?.rentalOwner_name !== "Grand Totals"
          ? [
              "",
              `Subtotal`,
              `$${transaction?.sub_total?.toFixed(2) || 0.0}`,
            ].join(",")
          : []
      );
    });

    const csvString = csvData?.join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Account_totals_report.csv`);
  };

  const [rentalownerdropdownopen, setRentalownerDropdownOpen] = useState(false);
  const toggle3 = () => setRentalownerDropdownOpen((prevState) => !prevState);
  const [setrentalowner, setRentalowner] = useState("Select Rental Owner");

  // const handlerentalowner = (type) => {
  //   setRentalowner(type);
  // };

  const handleRentalOwnerSelect = (owner) => {
    setRentalowner(owner?.rentalOwner_name);
    setSelectedRentalOwnerId(owner?.rentalowner_id || "");
  };

  const [rentalownerdatedropdownopen, setRentalownerdateDropdownOpen] =
    useState(false);

  const toggle4 = () =>
    setRentalownerdateDropdownOpen((prevState) => !prevState);

  const [setrentalownerdate, setRentalownerdate] = useState("Today");

  const handlerentalownerdate = (type) => {
    setRentalownerdate(type);
    setIsCustomDate(type === "Custom Date");
    const today = new Date();
    let start, end;

    switch (type) {
      case "Today":
        start = today.toISOString().split("T")[0];
        end = today.toISOString().split("T")[0];
        break;
      case "This Week":
        start = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 1))
        ).format("YYYY-MM-DD"); // Monday of the current week
        end = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 7))
        ).format("YYYY-MM-DD"); // Sunday of the current week
        break;
      case "This Month":
        start = moment(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ).format("YYYY-MM-DD"); // First day of the month
        end = moment(
          new Date(today.getFullYear(), today.getMonth() + 1, 0)
        ).format("YYYY-MM-DD"); // Last day of the month
        break;
      case "This Year":
        start = moment(new Date(today.getFullYear(), 0, 1)).format(
          "YYYY-MM-DD"
        ); // January 1st of the current year
        end = moment(new Date(today.getFullYear(), 11, 31)).format(
          "YYYY-MM-DD"
        ); // December 31st of the current year
        break;
      case "Custom Date":
        start = "";
        end = "";
        break;
      default:
        break;
    }

    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };
  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Account Totals Report
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-2 align-items-center">
          <Col lg="4" md="12" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Rental Owner
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle rentalownerdrops"
                isOpen={rentalownerdropdownopen}
                toggle={toggle3}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {setrentalowner}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  {/* Add "All" option */}
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() =>
                      handleRentalOwnerSelect({
                        rentalOwner_name: "All",
                        rentalowner_id: null,
                      })
                    }
                  >
                    All
                  </DropdownItem>

                  {rentalsData?.map((owner) => (
                    <DropdownItem
                      key={owner?.rentalowner_id}
                      className="dropdownfontsyle"
                      onClick={() => handleRentalOwnerSelect(owner)}
                    >
                      {owner?.rentalOwner_name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl="2" lg="4" md="3" sm="4" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Date Range
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={rentalownerdatedropdownopen}
                toggle={toggle4}
                style={{ width: "100%" }}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {setrentalownerdate}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Today")}
                  >
                    Today
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Week")}
                  >
                    This Week
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Month")}
                  >
                    This Month
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Year")}
                  >
                    This Year
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Custom Date")}
                  >
                    Custom Date
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col xl="2" lg="6" md="3" sm="4" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-startdate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                From
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-startdate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={!isCustomDate}
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="6" md="3" sm="5" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-enddate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                To
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-enddate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                disabled={!isCustomDate}
              />
            </FormGroup>
          </Col>

          <Col className="d-flex justify-content-end addtotnstyle mt-4">
            {transactionData && transactionData?.length > 0 && (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  caret
                  style={{
                    marginBottom: "12px",
                    fontSize: "16px",
                    border: "1px solid #152B5180",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    color: "#fff",
                    backgroundColor: "#152B51",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Exports
                </DropdownToggle>
                <DropdownMenu style={{ minWidth: "120px" }}>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("PDF")}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("XLSX")}
                  >
                    XLSX
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("CSV")}
                  >
                    CSV
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <thead
                className="fontfamilysty"
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "none",
                  border: 0,
                }}
              >
                {/* <tr className="" style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={2}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    <span>Grand Totals</span>
                    {accountData?.length > 0
                      ? accountData.map((item) => (
                          <span
                            key={item?.account}
                            className="d-flex justify-content-between"
                          >
                            <span>{item?.account}</span>
                          </span>
                        ))
                      : "No account"}
                    <span>Total</span>
                  </td>
                  <td
                    className="tabletextcolor text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {accountData?.length > 0
                      ? accountData.map((item) => (
                          <span
                            key={item?.amount}
                            className="d-flex flex-column"
                          >
                            <span>${item?.amount?.toFixed(2)}</span>
                          </span>
                        ))
                      : "No amount"}
                    <span>{`$${amountgrandTotal || "0.00"}`}</span>
                  </td>
                </tr> */}
                {/* <tr className="" style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={2}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  ></td>
                  <td
                    className="tabletextcolor text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {accountData?.length > 0
                      ? accountData.map((item) => (
                          <span
                            key={item?.amount}
                            className="d-flex flex-column"
                          >
                            <span>${item?.amount?.toFixed(2)}</span>
                          </span>
                        ))
                      : "No amount"}
                    <span>{`$${amountgrandTotal || "0.00"}`}</span>
                  </td>
                </tr> */}

                <tr
                  style={{
                    borderBottom: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Rental Owner
                  </th>

                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Account
                  </th>

                  <th
                    className="tablefontstyle textcolorblue text-right"
                    scope="col"
                    style={{ fontWeight: "Bold", border: 0 }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {transactionData?.length > 0 ? (
                  transactionData?.map((tenant, index) => (
                    <tr
                      style={{
                        // border: "0.5px solid rgba(50, 69, 103, 1)",
                        fontSize: "12px",
                        height: "40px",
                        fontFamily: "poppins",
                        fontWeight:
                          transactionData?.length !== index + 1 ? 600 : 1000,
                      }}
                    >
                      <td
                        className="dailyreporttablefont tabletextcolor"
                        style={{
                          border: 0,
                          width: "20%",
                          fontWeight:
                            transactionData?.length === index + 1 && "Bold",
                          verticalAlign: "top",
                          fontWeight: "bold",
                        }}
                      >
                        {tenant?.rentalOwner_name}
                      </td>

                      <td
                        className="dailyreporttablefont tabletextcolor"
                        style={{
                          border: 0,
                          width: "10%",
                          verticalAlign: "top",
                        }}
                      >
                        <span className="d-flex flex-column dailyreporttablefont tabletextcolor">
                          {tenant?.payments?.length > 0
                            ? tenant.payments.map((item) => (
                                <span
                                  key={item?.account}
                                  className="d-flex justify-content-between"
                                >
                                  <span
                                    className="tabletextcolor dailyreporttablefont"
                                    style={{
                                      fontWeight:
                                        transactionData?.length === index + 1 &&
                                        "Bold",
                                    }}
                                  >
                                    {item?.account}
                                  </span>
                                </span>
                              ))
                            : "No account"}
                          {transactionData?.length !== index + 1 && (
                            <span className="d-flex justify-content-between">
                              <span
                                className="tabletextcolor dailyreporttablefont"
                                style={{ fontWeight: "Bold" }}
                              >
                                Sub Total
                              </span>
                            </span>
                          )}
                        </span>
                      </td>
                      <td
                        className="dailyreporttablefont tabletextcolor text-right"
                        style={{
                          border: 0,
                          width: "10%",
                          verticalAlign: "top",
                        }}
                      >
                        <span className="d-flex flex-column dailyreporttablefont tabletextcolor">
                          {tenant?.payments?.length > 0
                            ? tenant.payments.map((item) => (
                                <span key={item?.amount}>
                                  <span
                                    className="tabletextcolor dailyreporttablefont"
                                    style={{
                                      fontWeight:
                                        transactionData?.length === index + 1 &&
                                        "Bold",
                                    }}
                                  >
                                    {`$${
                                      new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(item?.amount) || 0.0
                                    }`}
                                  </span>
                                </span>
                              ))
                            : "No amount"}
                          {transactionData?.length !== index + 1 && (
                            <span
                              className="tabletextcolor dailyreporttablefont"
                              style={{ fontWeight: "Bold" }}
                            >
                              {`$${
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(tenant?.sub_total) || 0.0
                              }`}
                            </span>
                          )}
                        </span>
                      </td>

                      {/* <td
                        className="dailyreporttablefont tabletextcolor"
                        style={{ border: 0 }}
                      >
                        <span
                          className="d-flex justify-content-end dailyreporttablefont"
                          style={{ fontWeight: "Bold" }}
                        >
                          {`$${tenant?.sub_total?.toFixed(2) || "$0.00"}`}
                        </span>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td
                      colSpan="9"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      No Account Totals Found
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}
        </Table>
        {/* {paginatedData.length > 0 ? ( */}
        {/* <Row>
            <Col className="text-right mt-3 mb-3">
              <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                <DropdownToggle className="paginationbtnstyle" caret>
                  {itemsPerPage} 
                </DropdownToggle>
                <DropdownMenu>
                  {[10, 25, 50, 100].map((perPage) => (
                    <DropdownItem
                      key={perPage}
                      onClick={() => setItemsPerPage(perPage)} 
                    >
                      {perPage}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Button
                className="p-0"
                style={{ backgroundColor: "#d0d0d0" }}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-caret-left"
                  viewBox="0 0 16 16"
                >
                  <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>
              </Button>
              <span>
                Page {currentPage} of {totalPages}
              </span>{" "}
              <Button
                className="p-0"
                style={{ backgroundColor: "#d0d0d0" }}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-caret-right"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                </svg>
              </Button>
            </Col>
          </Row> */}

        <ToastContainer />
      </Container>
    </>
  );
};

export default AccountTotalReport;
