// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*991 style for mobile view vendortable add vendor and edit vendor*/
@media (max-width: 991px) {
    .workorderbtn {
        width: 132px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        height: 33px !important;
        padding: 3px !important;
        border-radius: 4px !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/views/source/workorders.css"],"names":[],"mappings":";AACA,mEAAmE;AACnE;IACI;QACI,uBAAuB;QACvB,0BAA0B;QAC1B,2BAA2B;QAC3B,uBAAuB;QACvB,uBAAuB;QACvB,6BAA6B;IACjC;EACF","sourcesContent":["\n/*991 style for mobile view vendortable add vendor and edit vendor*/\n@media (max-width: 991px) {\n    .workorderbtn {\n        width: 132px !important;\n        font-size: 14px !important;\n        font-weight: 600 !important;\n        height: 33px !important;\n        padding: 3px !important;\n        border-radius: 4px !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
