import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HomeIcon from "@mui/icons-material/Home";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Spinner,
} from "reactstrap";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EmailIcon from "@mui/icons-material/Email";
import ClearIcon from "@mui/icons-material/Clear";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import greenTick from "../../assets/img/icons/common/green_tick.jpg";
import { Link } from "react-router-dom";
import profilei from "../../assets/img/icons/common/profilewhite.svg";
import phone from "../../assets/img/icons/common/phone.svg";
import address from "../../assets/img/icons/common/addressi.svg";
import {
  CardContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import ApplicantForm from "./ApplicantForm";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import swal from "sweetalert";
import FileModal from "./FileModal";

const ApplicantSummary = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id, admin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prevState) => !prevState);

  const [loader, setLoader] = useState(true);
  const [applicantData, setApplicantData] = useState({});
  const [applicantLeaseData, setApplicantLeaseData] = useState({});
  const [applicantCheckListData, setApplicantCheckListData] = useState([]);
  const [applicantNotesData, setApplicantNotesData] = useState([]);
  const [isApplicantDataEmpty, setIsApplicantDataEmpty] = useState(false);
  const [sendApplicantMail, setSendApplicantMail] = useState();
  const [sendApplicantMailLoader, setSendApplicantMailLoader] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(null);
  const [applicantFileModalOpen, setApplicantFileModalOpen] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetchApplicantData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const url = `/applicant/applicant_summary/${id}`;
        const res = await axiosInstance.get(url);
        setApplicantData(res.data.data[0]);
        setApplicantLeaseData(res.data.data[0].lease_data);
        setIsApplicantDataEmpty(res.data.data[0].isApplicantDataEmpty);
        setApplicantCheckListData(res.data.data[0].applicant_checkedChecklist);
        setApplicantNotesData(res.data.data[0].applicant_NotesAndFile);
      } catch (error) {
        console.error("Error: ", error.message);
      } finally {
        setIsLoading(false); // Set loading to false when fetch is completed
      }
    }
  };

  const [applicationData, setApplicationData] = useState({});
  const fetchApplicationData = async () => {
    if (id) {
      setLoader(true);
      try {
        const url = `/applicant/applicant_details/${id}`;
        const res = await axiosInstance.get(url);
        setApplicationData(res.data.data);
      } catch (error) {
        console.error("Error: ", error.message);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchApplicantData();
  }, [id]);

  useEffect(() => {
    fetchApplicationData();
  }, [id]);

  let sendApplicantMailData = async () => {
    if (id) {
      setSendApplicantMailLoader(true);
      let responce = await axiosInstance.get(`/applicant/applicant/mail/${id}`);
      setSendApplicantMail(responce.data.data);
      if (responce.data.statusCode === 200) {
        setSendApplicantMailLoader(false);
      } else {
        setSendApplicantMailLoader(false);
      }
    }
  };

  const dropdownList = ["Approved", "Rejected"];
  const [selectedStatus, setSelectedStatus] = useState("");
  const [leaseData, setLeaseData] = useState([]);
  const [applicantDetails, setApplicantDetails] = useState({});
  const [statusLoader, setStatusLoader] = useState(false)

  const handleStatus = async (item) => {
    if (!applicantData.isMovedin) {
      setStatusLoader(true);
      try {
        const values = {
          statusUpdatedBy: "Admin",
          status: item,
          rental_id: applicantLeaseData.rental_id,
          unit_id: applicantLeaseData.unit_id,
        };
        const res = await axiosInstance.put(
          `/applicant/applicant/${id}/status`,
          values
        );

        if (res.data.statusCode === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 500,
          });
          setSelectedStatus(item);
          fetchApplicantData();
        } else {
          toast.warn(res.data.message, {
            position: "top-center",
            autoClose: 500,
          });
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }finally {
        setStatusLoader(false);
      }
    }
  };

  const [value, setValue] = useState("Summary");
  const handleChange = async (event, newValue) => {
    if (id && newValue) {
      if (newValue === "Rejected") {
        setLoader(true);
        try {
          const res = await axiosInstance.get(
            `/applicant/status_data/${id}/${newValue}`
          );
          if (res.data.statusCode === 200 && res.data.data?.length > 0) {
            setLeaseData(res.data.data);
          } else {
            setLeaseData([]);
          }
        } catch (error) {
          console.error("Error: ", error.message);
        } finally {
          setLoader(false);
          setValue(newValue);
        }
      } else if (newValue === "Approved") {
        setLoader(true);
        try {
          const res = await axiosInstance.get(
            `/applicant/status_data/${id}/${newValue}`
          );
          if (res.data.statusCode === 200 && res.data.data?.length > 0) {
            setLeaseData(res.data.data);
          } else {
            setLeaseData([]);
          }
        } catch (error) {
          console.error("Error: ", error.message);
        } finally {
          setLoader(false);
          setValue(newValue);
        }
      } else if (newValue === "Application") {
        setLoader(true);
        try {
          const url = `/applicant/applicant_details/${id}`;
          const res = await axiosInstance.get(url);
          setApplicantDetails(res.data.data);
        } catch (error) {
          console.error("Error: ", error.message);
        } finally {
          setLoader(false);
          setValue(newValue);
        }
      } else {
        setValue(newValue);
      }
    }
  };

  const [newItem, setNewItem] = useState("");

  // const handleChecklistChange = async (event, item) => {
  //   try {
  //     const updatedItems = event.target.checked
  //       ? [...applicantCheckListData, item]
  //       : applicantCheckListData.filter((checkedItem) => checkedItem !== item);

  //     const apiUrl = `/applicant/applicant/${id}`;

  //     const response = await axiosInstance.put(
  //       apiUrl,
  //       {
  //         applicant_checkedChecklist: updatedItems,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (!response.status === 200) {
  //       toast.warn("Something Wrong!", {
  //         position: "top-center",
  //         autoClose: 500,
  //       });
  //     }

  //     fetchApplicantData();
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  const handleAddItem = async () => {
    if (newItem.trim() !== "") {
      const updatedChecklistItems = applicantCheckListData
        ? [...applicantCheckListData, newItem]
        : [newItem];
      setApplicantCheckListData(updatedChecklistItems);

      const updatedApplicant = {
        ...applicantData,
        applicant_checklist: applicantData.applicant_checklist
          ? [...applicantData.applicant_checklist, newItem]
          : [newItem],
      };

      axiosInstance
        .put(`/applicant/applicant/${id}/checklist`, updatedApplicant)
        .then(() => {
          fetchApplicantData();
        })
        .catch((err) => {
          console.error(err);
        });
      setNewItem("");
    }
  };

  const handleRemoveItem = async (itemToRemove) => {
    const updatedChecklist = applicantCheckListData.filter(
      (item) => item !== itemToRemove
    );
    setApplicantCheckListData(updatedChecklist);

    const updatedApplicant = {
      ...applicantData,
      applicant_checklist: applicantData.applicant_checklist.filter(
        (item) => item !== itemToRemove
      ),
    };

    axiosInstance
      .put(`/applicant/applicant/${id}/checklist`, updatedApplicant)
      .then(() => {
        fetchApplicantData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveChecklist = async () => {
    try {
      const apiUrl = `/applicant/applicant/${id}`;

      const requestBody = {
        applicant: {
          ...applicantData,
          applicant_checkedChecklist: applicantCheckListData,
        },
      };
  
      const response = await axiosInstance.put(apiUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        toast.success("Checklist saved successfully!", {
          position: "top-center",
          autoClose: 500,
        });
      } else {
        toast.warn("Something went wrong!", {
          position: "top-center",
          autoClose: 500,
        });
      }

      fetchApplicantData();
    } catch (error) {
      console.error(error.message);
    }
  };

  const rental_history = applicationData?.rental_history;
  const emergency_contact = applicationData?.emergency_contact;
  const employment = applicationData?.employment;

  const [isChecklistVisible, setChecklistVisible] = useState(false);
  const toggleChecklist = () => {
    setChecklistVisible(!isChecklistVisible);
  };

  const [isAttachFile, setIsAttachFile] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [newFile, setNewFile] = useState({});
  const openFileInBrowser = (fileURL) => {
    if (typeof fileURL === "string") {
      window.open(`${imageGetUrl}/${fileURL}`, "_blank");
    } else {
      const url = URL.createObjectURL(fileURL);
      window.open(url, "_blank");
    }
  };

  const handleAttachFile = () => {
    setIsAttachFile(true);
    setNewNote("");
    setNewFile({});
  };

  const hadlenotesandfile = async () => {
    try {
      var image;
      if (newFile !== null) {
        try {
          const form = new FormData();
          form.append("files", newFile);

          const res = await axios.post(`${imageUrl}/images/upload`, form);

          if (res && res.data && res.data.files && res.data.files?.length > 0) {
            image = res.data.files[0].filename;
          } else {
            console.error("Unexpected response format:", res);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }

      const formData = {
        applicant_notes: newNote,
        applicant_file: image,
      };

      const url = `/applicant/applicant/note_attachment/${id}`;
      const response = await axiosInstance.put(url, formData);
      if (response.data) {
        setIsAttachFile(false);
        fetchApplicantData();
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
    }
  };

  const [moveinLoader, setMoveinLoader] = useState(false);
  const handleClearRow = async (notes) => {
    const confirmDelete = await swal({
      title: "Are you sure?",
      text: "You want to delete this note?",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    });
  
    if (confirmDelete) {
      try {
        const url = `/applicant/applicant/note_attachment/${id}/${notes._id}`;
        const res = await axiosInstance.delete(url);
  
        toast.success("Document deleted successfully", {
          position: "top-center",
          autoClose: 500,
        });
  
        fetchApplicantData();
      } catch (err) {
        console.error(err);
        toast.error("Failed to delete the document.", {
          position: "top-center",
          autoClose: 500,
        });
      }
    }
  };

  const arrayOfStatus = [
    {
      value: "Approved",
      label: "The new rental application status",
    },
    {
      value: "Rejected",
      label: "The new rental application status",
    },
    {
      value: "Lease assigned",
      label: "Applicant added to a lease",
    },
    {
      value: "New",
      label: "New applicant record created",
    },
  ];

  const handleMoveIn = () => {
    setMoveinLoader(true);
    try {
      navigate(
        admin
          ? `/${admin}/RentRollLeaseing/${applicantLeaseData?.lease_id}/${id}`
          : `/staff/staffaddrentroll/${applicantLeaseData?.lease_id}/${id}`
      );
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setMoveinLoader(false);
    }
  };
  const [showApplicantForm, setShowApplicantForm] = useState(false);

  const handleManualEntryClick = () => {
    setShowApplicantForm(true);
  };

  return (
    <>
      <Header title="ApplicantSummary" />
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Row>
          <Col className="text-left">
            <FormGroup className=" align-item-left text-left ml-2">
              <h1
                className="text-left fontstylerentr textcolorblue fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Applicant:{" "}
                {applicantData?.applicant_firstName &&
                applicantData?.applicant_lastName
                  ? `${applicantData.applicant_firstName} ${applicantData.applicant_lastName}`
                  : "Unknown"}
              </h1>

              <h4
                className="fontstylerentmodal titleecolor fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {applicantLeaseData?.rental_adress &&
                  `${applicantLeaseData?.rental_adress} ${
                    applicantLeaseData?.rental_unit
                      ? " - " + applicantLeaseData?.rental_unit
                      : ""
                  }`}
              </h4>
            </FormGroup>
          </Col>
          <Col className="text-right ">
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() =>
                navigate(
                  admin
                    ? "/" + admin + "/Applicants"
                    : "/staff/staffapplicanttable"
                )
              }
              size="small"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
        <br />

        <div className="formInput d-flex flex-direction-row">
          <Dropdown
            disabled={applicantData?.isMovedin}
            isOpen={isOpen}
            toggle={toggle}
          >
            <DropdownToggle
              className="fontstylerentr textcolorblue fontfamilysty"
              caret
              style={{
                width: "100%",
                backgroundColor: "transparent",
                border: "1px solid #152B5180",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {statusLoader ? (
                <Spinner size="sm" color="primary" />
              ) : (applicantData &&
              applicantData.applicant_status &&
              applicantData?.applicant_status[
                applicantData?.applicant_status?.length - 1
              ]?.status
                ? applicantData?.applicant_status[
                    applicantData?.applicant_status?.length - 1
                  ]?.status
                : selectedStatus
                ? selectedStatus
                : "Select")}
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }} name="rent_cycle">
              {dropdownList.map((item, index) => {
                return (
                  <DropdownItem
                    className="fontstylerentr"
                    key={index}
                    onClick={() => {
                      handleStatus(item);
                    }}
                  >
                    {item}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <LoadingButton
            className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            loading={moveinLoader}
            style={{
              marginLeft: "10px",
              fontWeight: "500",
              fontSize: "16px",
              display:
                applicantData?.applicant_status?.length === 0 ||
                (applicantData?.applicant_status &&
                  applicantData?.applicant_status[
                    applicantData?.applicant_status?.length - 1
                  ]?.status !== "Approved" &&
                  selectedStatus !== "Approved")
                  ? "none"
                  : "block",
            }}
            onClick={() => {
              handleMoveIn();
            }}
            disabled={applicantData && applicantData.isMovedin === true}
          >
            {applicantData.isMovedin === true ? "Moved in" : "Move In"}
          </LoadingButton>
        </div>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <TabContext value={value}>
              <CardHeader
                className="mt-3 headersummarybarapplicant"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  border: "0.5px solid #324567",
                }}
              >
                <Box sx={{}}>
                  <TabList
                    className="tablistsummaryapplicant"
                    style={{ border: "none" }}
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      className="tabsummarybarapplicant textcolorblue fontfamilysty"
                      label="Summary"
                      value="Summary"
                      style={{
                        textTransform: "none",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    />
                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label="Application"
                      value="Application"
                      style={{
                        fontWeight: "500",
                        textTransform: "none",

                        fontSize: "18px",
                      }}
                    />
                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label="Approved"
                      value="Approved"
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    />
                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label="Rejected"
                      value="Rejected"
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    />
                  </TabList>
                </Box>
              </CardHeader>
              {loader ? (
                <tbody className="d-flex flex-direction-column justify-content-center align-items-center">
                  <tr>
                    <div className="p-5 m-5">
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="50"
                        visible={loader}
                      />
                    </div>
                  </tr>
                </tbody>
              ) : (
                <>
                  <TabPanel value="Summary">
                    <Row>
                      <Col>
                        <Grid container>
                          <Grid item xs={12} md={9} sm={9}>
                            <div>
                              <div
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                <input
                                  className="checkboxapplicantstyle"
                                  type="checkbox"
                                  id="checked"
                                  name="CreditCheck"
                                  value="CreditCheck"
                                  style={{
                                    transform: "scale(1.5)",
                                    marginTop: "20px",
                                    fontWeight: "bold",
                                  }}
                                  // onChange={(e) => {
                                  //   const isChecked = e.target.checked;
                                  //   if (isChecked) {
                                  //     setApplicantCheckListData((prevData) => [
                                  //       ...prevData,
                                  //       "CreditCheck",
                                  //     ]);
                                  //   } else {
                                  //     setApplicantCheckListData((prevData) =>
                                  //       prevData.filter(
                                  //         (item) => item !== "CreditCheck"
                                  //       )
                                  //     );
                                  //   }
                                  //   handleChecklistChange(e, "CreditCheck");
                                  // }}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setApplicantCheckListData((prevData) =>
                                      isChecked
                                        ? [...prevData, "CreditCheck"]
                                        : prevData.filter(
                                            (item) => item !== "CreditCheck"
                                          )
                                    );
                                  }}
                                  checked={applicantCheckListData?.includes(
                                    "CreditCheck"
                                  )}
                                />{" "}
                                &nbsp; Credit and background check <br />
                                <input
                                  className="checkboxapplicantstyle "
                                  type="checkbox"
                                  id="checked"
                                  name="EmploymentVerification"
                                  value="EmploymentVerification"
                                  style={{
                                    transform: "scale(1.5)",
                                    marginTop: "20px",
                                    fontWeight: "bold",
                                  }}
                                  // onChange={(e) => {
                                  //   const isChecked = e.target.checked;
                                  //   if (isChecked) {
                                  //     setApplicantCheckListData((prevData) => [
                                  //       ...prevData,
                                  //       "EmploymentVerification",
                                  //     ]);
                                  //   } else {
                                  //     setApplicantCheckListData((prevData) =>
                                  //       prevData.filter(
                                  //         (item) =>
                                  //           item !== "EmploymentVerification"
                                  //       )
                                  //     );
                                  //   }
                                  //   handleChecklistChange(
                                  //     e,
                                  //     "EmploymentVerification"
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setApplicantCheckListData((prevData) =>
                                      isChecked
                                        ? [
                                            ...prevData,
                                            "EmploymentVerification",
                                          ]
                                        : prevData.filter(
                                            (item) =>
                                              item !== "EmploymentVerification"
                                          )
                                    );
                                  }}
                                  checked={applicantCheckListData?.includes(
                                    "EmploymentVerification"
                                  )}
                                />{" "}
                                &nbsp; Employment verification <br />
                                <input
                                  className="checkboxapplicantstyle"
                                  type="checkbox"
                                  id="checked"
                                  name="ApplicationFee"
                                  value="ApplicationFee"
                                  style={{
                                    transform: "scale(1.5)",
                                    marginTop: "20px",
                                    fontWeight: "bold",
                                  }}
                                  // onChange={(e) => {
                                  //   const isChecked = e.target.checked;
                                  //   if (isChecked) {
                                  //     setApplicantCheckListData((prevData) => [
                                  //       ...prevData,
                                  //       "ApplicationFee",
                                  //     ]);
                                  //   } else {
                                  //     setApplicantCheckListData((prevData) =>
                                  //       prevData.filter(
                                  //         (item) => item !== "ApplicationFee"
                                  //       )
                                  //     );
                                  //   }
                                  //   handleChecklistChange(e, "ApplicationFee");
                                  // }}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setApplicantCheckListData((prevData) =>
                                      isChecked
                                        ? [...prevData, "ApplicationFee"]
                                        : prevData.filter(
                                            (item) => item !== "ApplicationFee"
                                          )
                                    );
                                  }}
                                  checked={applicantCheckListData?.includes(
                                    "ApplicationFee"
                                  )}
                                />{" "}
                                &nbsp; Application fee collected <br />
                                <input
                                  className="checkboxapplicantstyle"
                                  type="checkbox"
                                  id="checked"
                                  name="IncomeVerification"
                                  value="IncomeVerification"
                                  style={{
                                    transform: "scale(1.5)",
                                    marginTop: "20px",
                                    fontWeight: "bold",
                                  }}
                                  // onChange={(e) => {
                                  //   const isChecked = e.target.checked;
                                  //   if (isChecked) {
                                  //     setApplicantCheckListData((prevData) => [
                                  //       ...prevData,
                                  //       "IncomeVerification",
                                  //     ]);
                                  //   } else {
                                  //     setApplicantCheckListData((prevData) =>
                                  //       prevData.filter(
                                  //         (item) =>
                                  //           item !== "IncomeVerification"
                                  //       )
                                  //     );
                                  //   }
                                  //   handleChecklistChange(
                                  //     e,
                                  //     "IncomeVerification"
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setApplicantCheckListData((prevData) =>
                                      isChecked
                                        ? [...prevData, "IncomeVerification"]
                                        : prevData.filter(
                                            (item) =>
                                              item !== "IncomeVerification"
                                          )
                                    );
                                  }}
                                  checked={applicantCheckListData?.includes(
                                    "IncomeVerification"
                                  )}
                                />{" "}
                                &nbsp; Income verification <br />
                                <input
                                  className="checkboxapplicantstyle"
                                  type="checkbox"
                                  id="checked"
                                  name="LandlordVerification"
                                  value="LandlordVerification"
                                  style={{
                                    transform: "scale(1.5)",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    fontWeight: "bold",
                                  }}
                                  // onChange={(e) => {
                                  //   const isChecked = e.target.checked;
                                  //   if (isChecked) {
                                  //     setApplicantCheckListData((prevData) => [
                                  //       ...prevData,
                                  //       "LandlordVerification",
                                  //     ]);
                                  //   } else {
                                  //     setApplicantCheckListData((prevData) =>
                                  //       prevData.filter(
                                  //         (item) =>
                                  //           item !== "LandlordVerification"
                                  //       )
                                  //     );
                                  //   }
                                  //   handleChecklistChange(
                                  //     e,
                                  //     "LandlordVerification"
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setApplicantCheckListData((prevData) =>
                                      isChecked
                                        ? [...prevData, "LandlordVerification"]
                                        : prevData.filter(
                                            (item) =>
                                              item !== "LandlordVerification"
                                          )
                                    );
                                  }}
                                  checked={applicantCheckListData?.includes(
                                    "LandlordVerification"
                                  )}
                                />{" "}
                                &nbsp; Landlord verification <br />
                              </div>

                              <Box display="flex" flexDirection="column">
                                {applicantData?.applicant_checklist?.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        paddingTop: "10px",
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <input
                                            className="checkboxapplicantstyle"
                                            style={{
                                              transform: "scale(1.5)",
                                              marginLeft: "10px",
                                              fontWeight: "bold",
                                            }}
                                            type="checkbox"
                                            id="checked"
                                            value={item}
                                            color="success"
                                            // onChange={(e) => {
                                            //   const isChecked =
                                            //     e.target.checked;
                                            //   if (isChecked) {
                                            //     setApplicantCheckListData(
                                            //       (prevData) => [
                                            //         ...prevData,
                                            //         item,
                                            //       ]
                                            //     );
                                            //   } else {
                                            //     setApplicantCheckListData(
                                            //       (prevData) =>
                                            //         prevData.filter(
                                            //           (item) => item !== item
                                            //         )
                                            //     );
                                            //   }
                                            //   handleChecklistChange(e, item);
                                            // }}
                                            onChange={(e) => {
                                              const isChecked = e.target.checked;
                                              setApplicantCheckListData((prevData) =>
                                                isChecked
                                                  ? [...prevData, item]
                                                  : prevData.filter((prevItem) => prevItem !== item)
                                              );
                                            }}
                                            checked={applicantCheckListData?.includes(
                                              item
                                            )}
                                          />
                                        }
                                        label={
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginLeft: "5px",
                                            }}
                                          >
                                            &nbsp;&nbsp;
                                            <span
                                              className="fontstylerentr fontfamilysty"
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {item}
                                            </span>
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() =>
                                                handleRemoveItem(item)
                                              }
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </div>
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </Box>
                              {isChecklistVisible && (
                                <div>
                                  <Box
                                    className="boxcheckwidthapp"
                                    display="flex"
                                    sx={{ width: "40%" }}
                                    flexDirection="row"
                                    alignItems="center"
                                    paddingTop="10px"
                                  >
                                    <TextField
                                      style={{
                                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                                        borderRadius: "6px",
                                      }}
                                      type="text"
                                      size="small"
                                      fullWidth
                                      value={newItem}
                                      onChange={(e) =>
                                        setNewItem(e.target.value)
                                      }
                                    />
                                    <CheckIcon
                                      className="checkboxiconappl"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                        color: "green",
                                        border: "2px solid green",
                                        borderRadius: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onClick={handleAddItem}
                                    />
                                    <CloseIcon
                                      className="checkboxiconappl"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                        color: "red",
                                        border: "2px solid red",
                                        borderRadius: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      onClick={toggleChecklist}
                                    />
                                  </Box>
                                </div>
                              )}
                              <br></br>
                              <Button
                                className="fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  border: "1px solid #152B5180",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                }}
                                onClick={toggleChecklist}
                              >
                                + Add checklist
                              </Button>

                              <Button
                                onClick={handleSaveChecklist}
                                className="fontstylerentr background-colorsty bgtextwhite fontfamilysty mt-3"
                              >
                                Save Changes
                              </Button>
                            </div>

                            {/* Attach note or file section */}
                            <div className="mt-3">
                              <div>
                                <Row
                                  className="w-100 my-3 titleecolor fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <Col
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Notes and Files
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-2">
                                {isAttachFile ? (
                                  <Card
                                    style={{
                                      position: "relative",
                                      border: "1px solid #324567",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        cursor: "pointer",
                                        fontSize: "24px",
                                      }}
                                      onClick={() => {
                                        setIsAttachFile(false);
                                      }}
                                    >
                                      &times;
                                    </span>
                                    <CardBody>
                                      <CardTitle
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        tag="h4"
                                      >
                                        Notes
                                      </CardTitle>

                                      {/* Notes */}
                                      <div>
                                        <div>
                                          <TextField
                                            style={{
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040 ",
                                              borderRadius: "6px",
                                            }}
                                            type="text"
                                            size="small"
                                            fullWidth
                                            value={newNote}
                                            onChange={(e) => {
                                              setNewNote(e.target.value);
                                            }}
                                          />
                                        </div>

                                        <label
                                          htmlFor="upload_file"
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            display: "block",
                                            marginBottom: "15px",
                                            marginTop: "20px",
                                          }}
                                        >
                                          Upload Files
                                        </label>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="file"
                                          className="form-control-file d-none"
                                          accept="file/*"
                                          name="upload_file"
                                          id="upload_file"
                                          multiple={false}
                                          onChange={(e) => {
                                            setNewFile(e.target.files[0]);
                                          }}
                                        />
                                        <label
                                          htmlFor="upload_file"
                                          className=" mr-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                          style={{
                                            borderRadius: "5px",
                                            padding: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          Choose Files
                                        </label>
                                        {newFile && (
                                          <p
                                            style={{
                                              cursor: "pointer",
                                              color: "blue",
                                            }}
                                            onClick={() => {
                                              setFileModalOpen(newFile);
                                            }}
                                          >
                                            {newFile?.name}
                                          </p>
                                        )}
                                        {fileModalOpen && (
                                          <FileModal
                                            open={fileModalOpen}
                                            setOpen={setFileModalOpen}
                                            file={newFile}
                                          />
                                        )}
                                      </div>

                                      <div className="mt-3">
                                        <Button
                                          className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                          onClick={() => {
                                            hadlenotesandfile();
                                          }}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          Save
                                        </Button>

                                        <Button
                                          className="fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                          onClick={() => setIsAttachFile(false)}
                                          style={{
                                            border: "1px solid #324567",
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    </CardBody>
                                  </Card>
                                ) : (
                                  <Button
                                    className="fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                    onClick={handleAttachFile}
                                    style={{
                                      marginTop: "3px",
                                      padding: "10px 20px",
                                      fontSize: "16px",
                                      border: "1px solid #152B5180",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                      fontWeight: "500",
                                      boxShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    Attach Note/File
                                  </Button>
                                )}
                              </div>
                            </div>

                            {applicantNotesData?.length > 0 && (
                              <>
                                <Row className="w-100 mb-3 mt-3">
                                  <Col
                                    lg="3"
                                    sm="3"
                                    md="3"
                                    xs="3"
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Note
                                  </Col>
                                  <Col
                                    lg="6"
                                    sm="6"
                                    md="6"
                                    xs="6"
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    File
                                  </Col>
                                  <Col
                                    lg="3"
                                    sm="3"
                                    md="3"
                                    xs="3"
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Clear
                                  </Col>
                                </Row>
                                {applicantNotesData?.map((data, index) => (
                                  <Row
                                    className="w-100 mt-1 fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                    key={index}
                                  >
                                    <Col
                                      lg="3"
                                      sm="3"
                                      md="3"
                                      xs="3"
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.applicant_notes && (
                                        <p
                                          className="fontstylerentr titleecolor fontfamilysty"
                                          style={{
                                            display: "flex",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {data.applicant_notes}
                                        </p>
                                      )}
                                    </Col>
                                    <Col
                                      lg="6"
                                      sm="6"
                                      md="6"
                                      xs="6"
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.applicant_file ? (
                                        <div
                                          className="fontstylerentr titleecolor fontfamilysty"
                                          style={{
                                            display: "flex",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <p
                                            className="fontstylerentr titleecolor fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                            }}
                                            onClick={() =>
                                              setApplicantFileModalOpen(index)
                                            }
                                          >
                                            <FileOpenIcon />
                                            {data.applicant_file}
                                          </p>
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </Col>
                                    <Col
                                      lg="3"
                                      sm="3"
                                      md="3"
                                      xs="3"
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <ClearIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleClearRow(data);
                                        }}
                                      >
                                        Clear
                                      </ClearIcon>
                                    </Col>
                                  </Row>
                                ))}
                                {applicantFileModalOpen !== null && (
                                  <FileModal
                                    open={true}
                                    setOpen={() =>
                                      setApplicantFileModalOpen(null)
                                    }
                                    file={
                                      applicantNotesData[applicantFileModalOpen]
                                        ?.applicant_file
                                    }
                                  />
                                )}
                              </>
                            )}

                            <>
                              <Row
                                className="w-100 my-3 mt-5 fontstylerentr titleecolor"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                              >
                                <Col
                                  className="barforsummaryrent titleecolor fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Updates
                                </Col>
                              </Row>

                              {applicantData?.applicant_status?.map(
                                (item, index) => (
                                  <Row
                                    className="w-100 mt-1 mb-5 fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      textTransform: "capitalize",
                                      fontWeight: "500",
                                    }}
                                    key={index}
                                  >
                                    <Col
                                      lg="3"
                                      sm="3"
                                      md="3"
                                      xs="3"
                                      className="labelfontstyle titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {item.status || "N/A"}
                                    </Col>
                                    <Col
                                      lg="4"
                                      sm="4"
                                      md="4"
                                      xs="4"
                                      className="labelfontstyle titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {item?.status
                                        ? arrayOfStatus.find(
                                            (x) => x.value === item.status
                                          )?.label
                                        : "N/A"}
                                    </Col>
                                    <Col
                                      lg="5"
                                      sm="5"
                                      md="5"
                                      xs="5"
                                      className="labelfontstyle titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Updated By {`${item.statusUpdatedBy}`}{" "}
                                      {item.updateAt
                                        ? `At ${moment(item.updateAt).format(
                                            `${dateFormat} HH:mm:ss`
                                          )}`
                                        : ""}
                                    </Col>
                                  </Row>
                                )
                              )}
                            </>
                          </Grid>

                          <Grid item xs={12} md={3} lg="4" xl="3">
                            <Card
                              className="backgroundwhitesty"
                              sx={{ minWidth: 275 }}
                              style={{
                                border: "1px solid #324567",
                                boxShadow: "0px 4px 4px 0px #00000040",
                              }}
                            >
                              <CardContent>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    className="fontstylerentr textcolorblue"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "700",
                                      marginRight: "10px",
                                    }}
                                    gutterBottom
                                  >
                                    {applicantData?.applicant_firstName +
                                      " " +
                                      applicantData?.applicant_lastName}
                                  </Typography>
                                </div>
                                <Typography
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  variant="caption"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Applicant
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    <HomeIcon className="titleecolor" />
                                  </Typography>
                                  <Typography
                                    className="fontstylerentmodal applicanticonone textcolorblue fontfamilysty"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginLeft: "10px",
                                      marginTop: "2px",
                                    }}
                                    gutterBottom
                                  >
                                    {applicantData?.applicant_homeNumber ||
                                      "N/A"}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    <BusinessCenterIcon className="titleecolor" />
                                  </Typography>
                                  <Typography
                                    className="fontstylerentmodal applicanticonone textcolorblue fontfamilysty"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginLeft: "10px",
                                      marginTop: "2px",
                                    }}
                                    gutterBottom
                                  >
                                    {applicantData?.applicant_businessNumber ||
                                      "N/A"}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    <PhoneAndroidIcon className="titleecolor" />
                                  </Typography>
                                  <Typography
                                    className="fontstylerentmodal applicanticonone textcolorblue fontfamilysty"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginLeft: "10px",
                                      marginTop: "2px",
                                    }}
                                    gutterBottom
                                  >
                                    {applicantData?.applicant_phoneNumber ||
                                      "N/A"}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    <EmailIcon className="titleecolor" />
                                  </Typography>
                                  <Typography
                                    className="fontstylerentmodal applicanticonone textcolorblue fontfamilysty"
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginLeft: "10px",
                                      marginTop: "2px",
                                    }}
                                    gutterBottom
                                  >
                                    {applicantData?.applicant_email || "N/A"}
                                  </Typography>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel value="Application">
                    <Row>
                      <Col>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={12} sm={12} className="p-0">
                            <Box>
                              {isApplicantDataEmpty ? (
                                <section className="">
                                  <div className="row d-flex">
                                    {showApplicantForm ? (
                                      <div className="mt-2">
                                        <ApplicantForm
                                          applyId={id}
                                          setShowApplicantForm={
                                            setShowApplicantForm
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="form-row pl-4 mt-3">
                                          <p
                                            className="labelfontstyle titleecolor fontfamilysty"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            A rental application is not
                                            associated with the applicant. A
                                            link to the online rental
                                            application can be either emailed
                                            directly to the applicant for
                                            completion or the application
                                            details can be entered manually.
                                          </p>
                                        </div>

                                        {sendApplicantMail?.applicant_emailsend_date && (
                                          <div className="d-flex align-items-center fontstylerentr">
                                            <img
                                              src={greenTick}
                                              alt="Email send image"
                                              width="30px"
                                              height="30px"
                                            />
                                            <span className="ml-2">
                                              Application emailed{" "}
                                              {
                                                sendApplicantMail?.applicant_emailsend_date
                                              }
                                            </span>
                                          </div>
                                        )}

                                        <div className="mt-4 d-flex flex-column flex-sm-row align-items-center">
                                          <button
                                            type="button"
                                            className="btn ml-sm-3 mt-3 mt-sm-0 fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                            style={{
                                              borderRadius: "4px",
                                              transition:
                                                "border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                                              fontSize: "18px",
                                              border: "1px solid #152B5180",
                                              boxShadow:
                                                "0px 4px 4px 0px #00000040",
                                            }}
                                            onClick={sendApplicantMailData}
                                            disabled={sendApplicantMailLoader}
                                          >
                                            {sendApplicantMailLoader
                                              ? "Sending..."
                                              : "Email link to online rental application"}
                                          </button>

                                          <button
                                            onClick={handleManualEntryClick}
                                            className="btn ml-sm-3 mt-3 mt-sm-0 fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                            style={{
                                              borderRadius: "4px",
                                              transition:
                                                "border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                                              fontSize: "18px",
                                              border: "1px solid #152B5180",
                                              boxShadow:
                                                "0px 4px 4px 0px #00000040",
                                            }}
                                          >
                                            Manually enter application details
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              ) : (
                                <>
                                  {showApplicantForm ? (
                                    <div className="mt-2">
                                      <ApplicantForm
                                        applyId={id}
                                        setShowApplicantForm={
                                          setShowApplicantForm
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <Card
                                        className="bg-white  mt-3"
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          border: "1px solid #324567",
                                          padding: "15px",
                                        }}
                                      >
                                        <div className="applicant-info mt-3">
                                          <div className="d-flex align-items-center mb-3">
                                            <h2
                                              className="labelfontstyle textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "600",
                                                margin: "0",
                                              }}
                                            >
                                              Applicant Information
                                            </h2>
                                            <button
                                              onClick={handleManualEntryClick}
                                              className="btn ml-sm-3 mt-3 mt-sm-0 fontstylerentr editbtnapplicantsummary textcolorblue fontfamilysty backgroundwhitesty"
                                              style={{
                                                borderRadius: "8px",
                                                transition:
                                                  "border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                border: "1px solid #152B5180",
                                              }}
                                            >
                                              Edit
                                            </button>
                                          </div>

                                          <Row className="mb-2">
                                            <Col xs={12} md={4}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Applicant Name
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {`${
                                                  applicationData?.applicant_firstName
                                                    ? applicationData?.applicant_firstName
                                                    : "N/A"
                                                } ${
                                                  applicationData?.applicant_lastName
                                                    ? applicationData?.applicant_lastName
                                                    : ""
                                                }`}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Applicant Birth Date
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {applicationData?.applicant_birthDate
                                                  ? moment(
                                                      applicationData?.applicant_birthDate
                                                    ).format(dateFormat)
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Applicant Current Address
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {`${
                                                  applicationData?.applicant_streetAddress
                                                    ? applicationData?.applicant_streetAddress +
                                                      ", "
                                                    : "N/A"
                                                } ${
                                                  applicationData?.applicant_city
                                                    ? applicationData?.applicant_city +
                                                      ", "
                                                    : ""
                                                } ${
                                                  applicationData?.applicant_state
                                                    ? applicationData?.applicant_state +
                                                      ", "
                                                    : ""
                                                } ${
                                                  applicationData?.applicant_country
                                                    ? applicationData?.applicant_country +
                                                      ", "
                                                    : ""
                                                } ${
                                                  applicationData?.applicant_postalCode
                                                    ? applicationData?.applicant_postalCode
                                                    : ""
                                                }`}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row className="mb-2">
                                            <Col xs={12} md={4}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Applicant Email
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {applicationData?.applicant_email
                                                  ? applicationData?.applicant_email
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Applicant Phone
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {applicationData?.applicant_phoneNumber
                                                  ? applicationData?.applicant_phoneNumber
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                      <Card
                                        className="bg-white  mt-3"
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          border: "1px solid #324567",
                                          padding: "15px",
                                        }}
                                      >
                                        <div className="applicant-info mt-3">
                                          <h2
                                            className="mb-3 labelfontstyle textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Rental History
                                          </h2>
                                          <Row className="mb-2">
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rental Address
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {rental_history?.rental_adress
                                                  ? `${rental_history?.rental_adress}, ${rental_history?.rental_city}, ${rental_history?.rental_state}, ${rental_history?.rental_country}, ${rental_history?.rental_postcode}`
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rental Dates
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {rental_history?.start_date
                                                  ? `${moment(
                                                      rental_history?.start_date
                                                    ).format(
                                                      dateFormat
                                                    )} to ${moment(
                                                      rental_history?.end_date
                                                    ).format(dateFormat)}`
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Monthly Rent
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {rental_history?.rent
                                                  ? rental_history?.rent
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Reason of Leaving
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {rental_history?.leaving_reason
                                                  ? rental_history?.leaving_reason
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row className="mb-2">
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rental Owner Name
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {rental_history?.rentalOwner_firstName
                                                  ? `${rental_history?.rentalOwner_firstName} ${rental_history?.rentalOwner_lastName}`
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rental Owner Phone
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {rental_history?.rentalOwner_phoneNumber
                                                  ? rental_history?.rentalOwner_phoneNumber
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rental Owner Email
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {rental_history?.rentalOwner_primaryEmail
                                                  ? rental_history?.rentalOwner_primaryEmail
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>

                                      <Card
                                        className="bg-white  mt-3"
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          border: "1px solid #324567",
                                          padding: "15px",
                                        }}
                                      >
                                        <div className="applicant-info mt-3">
                                          <h2
                                            className="mb-3 labelfontstyle textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Emergency Contact Information
                                          </h2>

                                          <Row className="mb-2">
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Emergency Contact Name
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {`${
                                                  emergency_contact?.first_name
                                                    ? emergency_contact?.first_name
                                                    : "N/A"
                                                } ${
                                                  emergency_contact?.last_name
                                                    ? emergency_contact?.last_name
                                                    : ""
                                                }`}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Emergency Contact Relationship
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {emergency_contact?.relationship
                                                  ? emergency_contact?.relationship
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Emergency Contact Email
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {emergency_contact?.email
                                                  ? emergency_contact?.email
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Emergency Contact Phone
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {emergency_contact?.phone_number
                                                  ? emergency_contact?.phone_number
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>

                                      <Card
                                        className="bg-white  mt-3"
                                        style={{
                                          boxShadow:
                                            "0px 4px 4px 0px #00000040",
                                          border: "1px solid #324567",
                                          padding: "15px",
                                        }}
                                      >
                                        <div className="applicant-info mt-3">
                                          <h2
                                            className="mb-3 labelfontstyle textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Employment
                                          </h2>

                                          <Row className="mb-2">
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Employer Name
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {employment?.name
                                                  ? employment?.name
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Employer Address
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {`${
                                                  employment?.streetAddress
                                                    ? employment?.streetAddress +
                                                      ", "
                                                    : "N/A"
                                                } ${
                                                  employment?.city
                                                    ? employment?.city + ", "
                                                    : ""
                                                } ${
                                                  employment?.state
                                                    ? employment?.state + ", "
                                                    : ""
                                                } ${
                                                  employment?.country
                                                    ? employment?.country + ", "
                                                    : ""
                                                } ${
                                                  employment?.postalCode
                                                    ? employment?.postalCode
                                                    : ""
                                                }`}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Employer Phone Number
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {employment?.employment_phoneNumber
                                                  ? employment?.employment_phoneNumber
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col xs={12} md={4} lg={3} xl={3}>
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Employer Email
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {employment?.employment_primaryEmail
                                                  ? employment?.employment_primaryEmail
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                          <Row className="mb-2">
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Position Held
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {employment?.employment_position
                                                  ? employment?.employment_position
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Supervisor Name
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {`${
                                                  employment?.supervisor_firstName
                                                    ? employment?.supervisor_firstName
                                                    : "N/A"
                                                } ${" "} ${
                                                  employment?.supervisor_lastName
                                                    ? employment?.supervisor_lastName
                                                    : ""
                                                }`}
                                              </span>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={4}
                                              lg={3}
                                              xl={3}
                                              className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                            >
                                              <span
                                                className="fontstylerentr titleecolor fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Supervisor Title
                                              </span>
                                              <br />
                                              <span
                                                className="fontstylerentr textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {employment?.supervisor_title
                                                  ? employment?.supervisor_title
                                                  : "N/A"}
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    </>
                                  )}
                                </>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel value="Approved">
                    <CardHeader className="border-0"></CardHeader>
                    <Row>
                      {loader ? (
                        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={loader}
                          />
                        </div>
                      ) : (
                        <Col>
                          <Grid container spacing={2}>
                            {leaseData &&
                              leaseData.map((tenant, index) => (
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                  <Box
                                    className="cardstyborderradius"
                                    border="1px solid #152B5180"
                                    borderRadius="4px"
                                    boxShadow="0px 4px 4px 0px #00000040"
                                    padding="16px"
                                    maxWidth="400px"
                                    margin="20px"
                                  >
                                    <Row>
                                      <Col lg="2" sm="2" md="2" xs="2">
                                        <Box
                                          width="40px"
                                          height="40px"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          backgroundColor="#152B51"
                                          borderRadius="4px"
                                          color="white"
                                          fontSize="24px"
                                        >
                                          {/* <AssignmentIndIcon /> */}
                                          <img
                                            src={profilei}
                                            width={20}
                                            height={20}
                                          />
                                        </Box>
                                      </Col>

                                      <Col lg="10" sm="10" md="10" xs="12">
                                        <div
                                          className="barforsummaryrent textcolorblue fontfamilysty"
                                          style={{
                                            height: "40px",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                          }}
                                        >
                                          {applicantData?.applicant_firstName ||
                                            "N/A"}{" "}
                                          {applicantData?.applicant_lastName ||
                                            "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            display: "flex",
                                            paddingTop: "3px",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              paddingRight: "3px",
                                              fontSize: "2px",
                                            }}
                                          >
                                            <img
                                              src={phone}
                                              width={20}
                                              height={20}
                                            />
                                          </Typography>
                                          {applicantData?.applicant_phoneNumber ||
                                            "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              paddingRight: "3px",
                                              fontSize: "7px",
                                            }}
                                          >
                                            <img
                                              src={address}
                                              width={20}
                                              height={20}
                                            />
                                          </Typography>
                                          {tenant?.rental_adress || "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr"
                                          style={{
                                            display: "flex",
                                            paddingTop: "3px",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontFamily: "Poppins",
                                            color: "#26C22C",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Approved
                                        </div>
                                      </Col>
                                    </Row>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Col>
                      )}
                    </Row>
                  </TabPanel>

                  <TabPanel value="Rejected">
                    <CardHeader className="border-0"></CardHeader>
                    <Row>
                      {loader ? (
                        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={loader}
                          />
                        </div>
                      ) : (
                        <Col>
                          <Grid container spacing={2}>
                            {leaseData &&
                              leaseData.map((tenant, index) => (
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                  <Box
                                    className="cardstyborderradius"
                                    border="1px solid #152B5180"
                                    borderRadius="4px"
                                    boxShadow="0px 4px 4px 0px #00000040"
                                    padding="16px"
                                    maxWidth="400px"
                                    margin="20px"
                                  >
                                    <Row>
                                      <Col lg="2" sm="2" md="2" xs="2">
                                        <Box
                                          width="40px"
                                          height="40px"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          backgroundColor="#152B51"
                                          borderRadius="4px"
                                          color="white"
                                          fontSize="24px"
                                        >
                                          <img
                                            src={profilei}
                                            width={20}
                                            height={20}
                                          />
                                        </Box>
                                      </Col>

                                      <Col lg="10" sm="10" md="10" xs="12">
                                        <div
                                          className="barforsummaryrent textcolorblue fontfamilysty"
                                          style={{
                                            height: "40px",
                                            fontWeight: "600",
                                            fontSize: "18px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "start",
                                          }}
                                        >
                                          {applicantData?.applicant_firstName ||
                                            "N/A"}{" "}
                                          {applicantData?.applicant_lastName ||
                                            "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            display: "flex",
                                            paddingTop: "3px",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              paddingRight: "3px",
                                              fontSize: "2px",
                                            }}
                                          >
                                            <img
                                              src={phone}
                                              width={20}
                                              height={20}
                                            />
                                          </Typography>
                                          {applicantData?.applicant_phoneNumber ||
                                            "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              paddingRight: "3px",
                                              fontSize: "7px",
                                            }}
                                          >
                                            <img
                                              src={address}
                                              width={20}
                                              height={20}
                                            />
                                          </Typography>
                                          {tenant?.rental_adress || "N/A"}
                                        </div>

                                        <div
                                          className="fontstylerentr"
                                          style={{
                                            display: "flex",
                                            paddingTop: "3px",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            fontFamily: "Poppins",
                                            color: "red",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Rejected
                                        </div>
                                      </Col>
                                    </Row>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Col>
                      )}
                    </Row>
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ApplicantSummary;
