import { responsiveFontSizes, Table, TableBody } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

const AuditLogs = () => {
  const { admin } = useParams();
  // const [cookies] = useCookies(["token"]);
  const [cookies, setCoockie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(undefined);
  const [logsData, setLogsData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchLogs = async () => {
  //     try {
  //       const data = await axiosInstance.get(
  //         `/activity/${accessType.admin_id}`
  //       );
  //       if (data.data.statusCode === 200) {
  //         setLogsData(data.data.data);
  //         setLoader(false);
  //       } else {
  //         setLoader(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setLoader(false);
  //     }
  //   };
  //   if (accessType) {
  //     fetchLogs();
  //   }
  // }, [accessType]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axiosInstance.get(
          `/activity/${accessType.admin_id}`,
          {
            params: {
              pageSize: pageSize,
              pageNumber: pageNumber - 1,
            },
          }
        );
  
        if (response.data.statusCode === 200) {
          setLogsData(response.data.data);
          const totalRecords = response.data.totalRecords;
          setTotalPages(Math.ceil(totalRecords / pageSize));
        } else {
          setLogsData([]);
          setTotalPages(1);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };
  
    if (accessType) {
      fetchLogs();
    }
  }, [accessType, pageNumber, pageSize]);
  

  const handleChangePage = (newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
    // setPageItem(+event.target.value);
  };

  return (
    <>
      <Row className="mx-1 mt-5">
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Activity
            </h2>
          </CardHeader>
        </Col>
      </Row>
      <div className="m-4 mt-5">
        {loader ? (
          <div className="text-center">Loading...</div>
        ) : logsData && logsData.length > 0 ? (
          <Table
            className="align-items-center table-flush"
            responsive
            style={{
              borderTopLeftRadius: "20px",
              borderCollapse: "collapse",
            }}
          >
            <thead
              style={{
                height: "45px",
                fontSize: "14px",
                fontFamily: "poppins",
                fontWeight: "600",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderTopLeftRadius: "20px",
              }}
            >
              <tr
                style={{
                  border: "1px solid rgba(50, 69, 103, 1)",
                  borderTopLeftRadius: "20px",
                }}
              >
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    color: "#152B51",
                    width: "20%",
                    borderTopLeftRadius: "15px",
                  }}
                >
                  Activity By
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "15%" }}
                >
                  ACTION
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "50%" }}
                >
                  Details
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    borderTopRightRadius: "15px",
                    color: "#152B51",
                    width: "20%",
                  }}
                >
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              <tr
                style={{
                  border: "none",
                  height: "20px",
                }}
              >
                <td colSpan="9"></td>
              </tr>
              {logsData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    border: "0.5px solid rgba(50, 69, 103, 1)",
                    fontSize: "12px",
                    height: "40px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                  }}
                >
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.activity_by_username} ({item.activity_by})
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.action}
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {item.activity?.description}
                    {item.reason ? (
                      <>
                        <br />
                        reason: {item.reason}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="bordertopintd tablebodyfont px-3">
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3>Activities not found!</h3>
        )}
     {logsData?.length > 0 ? (
              <Row>
                <Col className="text-right m-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageSize}
                    </DropdownToggle>
                    <DropdownMenu>
                      {[10, 25, 50, 100].map((value) => (
                        <DropdownItem
                          key={value}
                          onClick={() => {
                            handleChangeRowsPerPage({ target: { value } });
                          }}
                        >
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{
                      backgroundColor: "#d0d0d0",
                      minWidth: "20px",
                      color: "black",
                    }}
                    onClick={() => handleChangePage(pageNumber - 1)}
                    disabled={pageNumber === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span className="fontstylerentr">
                    Page {pageNumber} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{
                      backgroundColor: "#d0d0d0",
                      minWidth: "20px",
                      color: "black",
                    }}
                    onClick={() => handleChangePage(pageNumber + 1)}
                    disabled={pageNumber === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : (
              <></>
            )}
      </div>
    </>
  );
};

export default AuditLogs;
