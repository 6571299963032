import Img from "../../assets/img/theme/site-logo.png";

// pdf
import jsPDF from "jspdf";
import "jspdf-autotable";

// excel
import * as XLSX from "xlsx-js-style";

// save excels and csv
import { saveAs } from "file-saver";
import moment from "moment";

const generatePDF = (reportData, userProfile) => {
  const doc = new jsPDF();

  const img1Width = 15;
  const img1Height = 13;
  doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

  const titleYPosition = 20;
  doc.setFontSize(20);
  doc.text(
    "Rent Past Due Report",
    doc.internal.pageSize.getWidth() / 2,
    titleYPosition,
    null,
    null,
    "center"
  );

  const subtitleYPosition = titleYPosition + 10;
  doc.setFontSize(12);
  doc.text(
    `Date: ${new Date().toISOString().split("T")[0]}`,
    doc.internal.pageSize.getWidth() / 2,
    subtitleYPosition,
    null,
    null,
    "center"
  );

  const contactInfo = [
    userProfile?.company_name || "",
    userProfile?.company_address || "",
    [userProfile?.city, userProfile?.state, userProfile?.country]
      .filter(Boolean)
      .join(", "),
    userProfile?.postal_code || "",
  ]
    .filter(Boolean)
    .join("\n");
  doc.setFontSize(10);
  const contactInfoLines = contactInfo.split("\n");
  const topMargin = 20;
  const lineHeight = 4;

  contactInfoLines.forEach((line, index) => {
    doc.text(
      line,
      doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
      topMargin + index * lineHeight
    );
  });

  const tableYPosition = subtitleYPosition + 8;

  const tableRows = (
    reportData?.charges ? reportData?.charges : reportData?.payments
  )?.map((transaction) => {
    return [
      {
        content:
          (transaction.rental_data?.rental_adress || "") +
          (transaction.unit_data?.rental_unit
            ? " - " + transaction.unit_data?.rental_unit
            : ""),
        styles: {
          borderBottom: "none",
          fontSize: 9,
          cellPadding: { top: 3, bottom: 0, left: 0, right: 0 },
        },
      },
      {
        content: transaction.tenant_data?.tenant_firstName
          ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
          : "N/A",
        styles: {
          borderBottom: "none",
          fontSize: 9,
          cellPadding: { top: 3, bottom: 0, left: 3, right: 0 },
        },
      },
      {
        content: `$${
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction.total) || 0.0
        }`,
        styles: {
          borderBottom: "none",
          fontSize: 9,
          halign: "right",
          cellPadding: { top: 3, bottom: 0, left: 0, right: 0 },
        },
      },
    ];
  });

  doc.autoTable({
    startY: tableYPosition,
    head: [
      [
        {
          content: "Property",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
          },
        },
        {
          content: "Tenant",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
          },
        },
        {
          content: "Amount",
          styles: {
            halign: "right",
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
          },
        },
      ],
    ],
    headStyles: {
      fillColor: [90, 134, 213],
      textColor: [255, 255, 255],
      fontSize: 11,
      fontStyle: "bold",
    },
    body: [
      ...tableRows,
      [
        {
          content: `TOTAL: $${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(reportData.total)}`,
          colSpan: 3,
          styles: {
            halign: "right",
            fontStyle: "bold",
            fontSize: 13,
            cellPadding: { top: 5, bottom: 3, left: 0, right: 0 },
          },
        },
      ],
    ],
    theme: "plain",
    styles: {
      fontSize: 10,
    },
    columnStyles: {
      0: { cellWidth: 102.5 },
      1: { cellWidth: 50 },
      2: { cellWidth: 30 },
    },
  });

  const lineYPosition1 = doc.autoTable.previous.finalY - 10;

  if (lineYPosition1 > 10) {
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.5);
    doc.line(
      14,
      lineYPosition1,
      doc.internal.pageSize.getWidth() - 13,
      lineYPosition1
    );
  } else {
    console.error("The lines are positioned outside the page bounds.");
  }

  const pageCount = doc.getNumberOfPages();
  const currentDate = new Date();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${moment(currentDate).format("YYYY-MM-DD HH:ss:mm")}`,
      10,
      doc.internal.pageSize.getHeight() - 8
    );
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 10,
      doc.internal.pageSize.getHeight() - 8,
      { align: "right" }
    );
  }

  doc.save(`Rent_past_due_report.pdf`);
};

const generateExcel = (reportData) => {
  const wb = XLSX.utils.book_new();

  const worksheetHeader = ["Property", "Tenant", "Amount"];

  const worksheetData = [
    worksheetHeader,
    ...(reportData.charges ? reportData.charges : reportData.payments).map(
      (transaction) => {
        return [
          (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          transaction.tenant_data?.tenant_firstName
            ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
            : "N/A",
          {
            v:
              `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(transaction.total)}` || "0.00",
            s: { alignment: { horizontal: "right" } },
          },
        ];
      }
    ),
    [
      {
        v: `Total`,
        s: { font: { bold: true } },
      },
      "",
      {
        v: `$${
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(reportData.total) || "0.00"
        }`,
        s: { font: { bold: true }, alignment: { horizontal: "right" } },
      },
    ],
  ];

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);

  const wscols = worksheetHeader.map((header) => {
    return { wch: Math.max(header?.length + 5, 20) };
  });

  ws["!cols"] = wscols;

  const headerStyle = {
    font: { bold: true, color: "000000", sz: "15" },
    border: {
      top: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    },
    alignment: { horizontal: "center", vertical: "center" },
  };

  worksheetHeader.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
    ws[cellAddress].s = headerStyle;
  });

  Object.keys(ws).forEach((cellAddress) => {
    if (ws[cellAddress].s) {
      ws[cellAddress].s.alignment = {
        wrapText: true,
        vertical: "center",
        horizontal: ws[cellAddress].s.alignment?.horizontal || "left",
      };
    }
  });

  XLSX.utils.book_append_sheet(wb, ws, "Daily Transaction Report");

  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    `Rent_past_due_report.xlsx`
  );
};

const generateCSV = (reportData) => {
  let csvData = [];

  const headers = ["Property", "Tenant", "Amount"];

  csvData?.push(headers.join(","));

  (reportData.charges ? reportData.charges : reportData.payments)?.forEach(
    (payment) => {
      const propertyAddress = (
        (payment.rental_data?.rental_adress.split(",").join(" ") || "") +
        (payment.unit_data?.rental_unit
          ? " - " + payment.unit_data?.rental_unit.split(",").join(" ")
          : "")
      ).padEnd(50, " ");

      csvData?.push(
        [
          propertyAddress,
          payment.tenant_data?.tenant_firstName
            ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
            : "N/A",
          `$${Number(payment.total).toFixed(2)}`,
        ].join(",")
      );
    }
  );

  csvData?.push(
    [`Total`, "", `$${Number(reportData.total).toFixed(2)}`].join(",")
  );

  const csvString = csvData?.join("\n");

  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `Rent_past_due_report.csv`);
};

export { generatePDF, generateExcel, generateCSV };
