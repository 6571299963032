// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addinsurance .MuiDialog-paper {
    min-width: 300px !important;
    width: 700px !important;
    max-width: none !important;
    max-width: initial !important;
}`, "",{"version":3,"sources":["webpack://./src/components/RenterInsurance/RenterInsurance.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,0BAA2B;IAA3B,6BAA2B;AAC/B","sourcesContent":[".addinsurance .MuiDialog-paper {\n    min-width: 300px !important;\n    width: 700px !important;\n    max-width: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
