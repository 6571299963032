import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from "reactstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "axiosInstance";
import axios from "axios";
import { useCookies } from "react-cookie";
import { verifyToken } from "components/Functions/Functions";
import * as yup from "yup";
import zxcvbn from "zxcvbn";

// const StaffPassChange = () => {
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [showPassword, setShowPassword] = useState(false);
//   const [showPassword1, setShowPassword1] = React.useState(false);
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [accessType, setAccessType] = useState(null);

//   const [cookies, setCoockie, removeCookie] = useCookies();

//   useEffect(() => {
//     const fetchData = async () => {
//       const data = await verifyToken(navigate, location, removeCookie);
//       setAccessType(data);
//     };
//     fetchData();
//   }, []);

//   const handleSubmit = (event) => {
//     event.preventDefault(); // Prevent default form submission behavior
//     handleChangePassword();
//   };

//   const isStrongPassword = (password) => {
//     const strongPasswordRegex =
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
//     return strongPasswordRegex.test(password);
//   };

//   const handleChangePassword = async () => {
//     if (newPassword !== confirmPassword) {
//       setError("Passwords do not match");
//     } else if (!isStrongPassword(newPassword)) {
//       setError(
//         "Password must be strong. Include uppercase, lowercase, numbers, and special characters."
//       );
//     } else {
//       try {
//         setIsLoading(true);
//         const response = await axiosInstance.put(
//           `/staffmember/reset_password/${accessType?.staffmember_email}`,
//           { password: newPassword }     
//         );

//         if (response.status === 200) {
//           toast.success("Password Changed Successfully", {
//             position: "top-center",
//             autoClose: 1000,
//           });
//           setTimeout(() => {
//             navigate(`/staff/StaffdashBoard`);
//           }, 2000);
//         } else {
//           setError(response.data.message);
//           toast.error("Failed To Change Password", {
//             position: "top-center",
//             autoClose: 1000,
//           });
//         }
//       } catch (error) {
//         setError("An error occurred while changing the password");
//       } finally {
//         setIsLoading(false); // Set loading state to false after API call completes
//       }
//     }
//   };

//   return (
//     <>
//       <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
//         <Row>
//           <div className="col">
//             <CardHeader
//               className=" mt-3 mb-3 headproptypebar background-colorsty" 
//               style={{
//                 borderRadius: "10px",
//                 boxShadow: " 0px 4px 4px 0px #00000040 ",
//               }}
//             >
//               <h2
//                 className="mb-0 headproptypee bgtextwhite fontfamilysty"
//                 style={{
//                   fontWeight: "500",
//                   fontSize: "26px",
//                 }}
//               >
//                 Change Password
//               </h2>
//             </CardHeader>
//           </div>
//         </Row>
//         <div>
//           <Form
//             role="form"
//             onSubmit={handleSubmit}
//             className="ml-3"
//             style={{ width: "30%" }}
//           >
//             <FormGroup className="mt-3 mb-4">
//               <label
//                 htmlFor="inputPassword4 fontfamilysty"
//                 color="grey"
//                 style={{
//                   // marginBottom: "10px",
//                   fontWeight: "500",
//                   fontSize: "16px",
//                 }}
//               >
//                 Enter your new password
//               </label>
//               <InputGroup
//                 className="input-group-alternative"
//                 style={{
//                   boxShadow: "0px 4px 4px 0px #00000040",
//                   border: "1px solid #ced4da",
//                 }}
//               >
//                 <InputGroupAddon addonType="prepend">
//                   <InputGroupText>
//                     <i className="ni ni-lock-circle-open" />
//                   </InputGroupText>
//                 </InputGroupAddon>
//                 <Input
//                   type={showPassword ? "text" : "password"}
//                   name="password"
//                   placeholder="New Password"
//                   className="form-control "
//                   id="inputPassword4"
//                   value={newPassword}
//                   onChange={(e) => setNewPassword(e.target.value)}
//                 />
//                 <IconButton
//                   type="button"
//                   style={{ padding: "7px" }}
//                   onClick={() => setShowPassword(!showPassword)}
//                 >
//                   <VisibilityIcon />
//                 </IconButton>
//               </InputGroup>
//             </FormGroup>
//             <FormGroup className="mt-3 mb-3">
//               <label
//                 htmlFor="confirmPassword fontfamilysty"
//                 color="grey"
//                 style={{
//                   // marginBottom: "10px",
//                   fontWeight: "500",
//                   fontSize: "16px",
//                 }}
//               >
//                 Confirm new password
//               </label>
//               <InputGroup
//                 className="input-group-alternative"
//                 style={{
//                   boxShadow: "0px 4px 4px 0px #00000040",
//                   border: "1px solid #ced4da",
//                 }}
//               >
//                 <InputGroupAddon addonType="prepend">
//                   <InputGroupText>
//                     <i className="ni ni-lock-circle-open" />
//                   </InputGroupText>
//                 </InputGroupAddon>
//                 <Input
//                   type={showPassword1 ? "text" : "password"}
//                   placeholder="Confirm Password"
//                   className="form-control"
//                   id="confirmPassword"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                 />
//                 <IconButton
//                   type="button"
//                   style={{ padding: "7px" }}
//                   onClick={() => setShowPassword1(!showPassword1)}
//                 >
//                   <VisibilityIcon />
//                 </IconButton>
//               </InputGroup>
//             </FormGroup>
//             {error && <div className="text-danger">{error}</div>}
//             <br />
//             <div>
//               <Button className="background-colorsty bgtextwhite"
//                 type="submit"
//                 variant="contained"
//                 size="large"
//                 disabled={isLoading}              
//                 //onClick={handleChangePassword}
//               >
//                 {isLoading ? <CircularProgress size={24} /> : "Change Password"}
//               </Button>
//               <Button className="backgroundwhitesty textcolorblue"
//                 variant="contained"
//                 size="large"
//                 onClick={() =>  navigate(`/staff/StaffdashBoard`)}
//               >
//                 Cancel
//               </Button>
//             </div>
//           </Form>
//         </div>
//       <ToastContainer />
//       </Container>
//     </>
//   );
// };

const StaffPassChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  const passwordValidation = yup
    .string()
    .required("No password provided")
    .min(12, "Password must be at least 12 characters long")
    .max(16, "Password cannot be longer than 16 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/,
      "Must contain an uppercase letter, lowercase letter, number, and special character"
    )
    .test("zxcvbn-strength", "Password is too weak or common", (value) => {
      const result = zxcvbn(value);
      return result.score >= 3;
    })
    .test("no-sequential-or-repeating", "Avoid sequential or repeating patterns like '1234' or 'aaaa'", (value) => {
      return !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value);
    });

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    try {
      await passwordValidation.validate(newPassword);
      if (newPassword !== confirmPassword) {
        setError("Passwords do not match");
      } else {
        handleChangePassword();
      }
    } catch (validationError) {
      setError(validationError.message);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        `/staffmember/reset_password/${accessType?.staffmember_email}`,
        { password: newPassword }
      );

      if (response.status === 200) {
        toast.success("Password Changed Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => navigate(`/staff/staffdashBoard`), 2000);
      } else {
        setError(response.data.message);
        toast.error("Failed To Change Password", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      setError("An error occurred while changing the password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Row>
          <div className="col">
            <CardHeader className="mt-3 mb-3 headproptypebar" style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}>
              <h2 className="mb-0 headproptypee" style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}>Change Password</h2>
            </CardHeader>
          </div>
        </Row>
        <Form role="form" onSubmit={handleSubmit} className="ml-3" style={{ width: "30%" }}>
          <FormGroup className="mt-3 mb-4">
            <label htmlFor="inputPassword4" style={{
              fontWeight: "500", fontFamily: "Poppins", fontSize: "16px",
            }}>Enter your new password</label>
            <InputGroup className="input-group-alternative" style={{
              boxShadow: "0px 4px 4px 0px #00000040", border: "1px solid #ced4da",
            }}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
              </InputGroupAddon>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                id="inputPassword4"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <IconButton style={{ padding: "7px" }} onClick={() => setShowPassword(!showPassword)}>
                <VisibilityIcon />
              </IconButton>
            </InputGroup>
          </FormGroup>
          <FormGroup className="mt-3 mb-3">
            <label htmlFor="confirmPassword" style={{
              fontWeight: "500", fontFamily: "Poppins", fontSize: "16px",
            }}>Confirm new password</label>
            <InputGroup className="input-group-alternative" style={{
              boxShadow: "0px 4px 4px 0px #00000040", border: "1px solid #ced4da",
            }}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><i className="ni ni-lock-circle-open" /></InputGroupText>
              </InputGroupAddon>
              <Input
                type={showPassword1 ? "text" : "password"}
                placeholder="Confirm Password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <IconButton style={{ padding: "7px" }} onClick={() => setShowPassword1(!showPassword1)}>
                <VisibilityIcon />
              </IconButton>
            </InputGroup>
          </FormGroup>
          {error && <div className="text-danger">{error}</div>}
          <br />
          <div>
            <Button type="submit" variant="contained" size="large" disabled={isLoading} color="primary" style={{ backgroundColor: "#152B51" }}>
              {isLoading ? <CircularProgress size={24} /> : "Change Password"}
            </Button>
            <Button variant="contained" size="large" color="grey" onClick={() => navigate(`/staff/StaffdashBoard`)}>
              Cancel
            </Button>
          </div>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default StaffPassChange;
