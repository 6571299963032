import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import zxcvbn from "zxcvbn"
import { useCookies } from "react-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./tenantstyle.css";
import axiosInstance from "axiosInstance";
import { Checkbox } from "@mui/material";
import { verifyToken } from "components/Functions/Functions";
const Leaseing = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const { tenant_id, admin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFromDetail, setIsFromDetail] = useState(false);
  const [accessType, setAccessType] = useState(null);
  const [isEnable, setIsEnable] = useState(false);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      setIsFromDetail(true);
    }
  }, [location.state]);

  const passwordValidation = yup.string()
  .required("No password provided")
  .min(12, "Password must be at least 12 characters long")
  .max(16, "Password cannot be longer than 16 characters")
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/, 
      "Must contain an uppercase letter, lowercase letter, number, and special character"
  )
  .test("zxcvbn-strength", "Password is too weak or common", value => {
      const result = zxcvbn(value);
      return result.score >= 3;
  })
  .test("no-sequential-or-repeating", "Avoid sequential or repeating patterns like '1234' or 'aaaa'", value => {
      return !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value);
  });

  //display
  const [showPassword, setShowPassword] = useState(false);
  //loaders
  const [loader, setLoader] = useState(false);
  const [override, setOverride] = useState(null);
  const tenantFormik = useFormik({
    initialValues: {
      tenant_id: "",
      tenant_firstName: "",
      tenant_lastName: "",
      tenant_phoneNumber: "",
      tenant_alternativeNumber: "",
      tenant_email: "",
      tenant_alternativeEmail: "",
      tenant_password: "",
      tenant_birthDate: "",
      taxPayer_id: "",
      comments: "",
      override_fee: "",
      enable_override_fee: "",
      emergency_contact: {
        name: "",
        relation: "",
        email: "",
        phoneNumber: "",
      },
    },
    validationSchema: yup.object({
      tenant_firstName: yup.string().required("Required"),
      tenant_lastName: yup.string().required("Required"),
      tenant_phoneNumber: yup.string().required("Required"),
      tenant_email: yup
        .string()
        .required("Required")
        .email("Invalid email address")
        .required("Email is required"),
      tenant_alternativeEmail: yup.string().email("Invalid email address"),

      emergency_contact: yup.object().shape({
        email: yup.string().email("Invalid email address"),
      }),
      tenant_password: passwordValidation,
      // tenant_password: tenant_id
      //   ? yup
      //       .string()
      //       .min(8, "Password is too short")
      //       .matches(
      //         /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //         "Must Contain One Uppercase, One Lowercase, One Number, and one special case Character"
      //       )
      // : //.required("Required")
      //   "",
    }),
    onSubmit: (values) => {
      if (tenant_id) {
        editTenant();
      } else {
        addTenant(values);
      }
    },
  });
  const addTenant = async () => {
    const object = {
      ...tenantFormik.values,
      enable_override_fee: isEnable,
      admin_id: accessType?.admin_id,
      company_name: admin,
    };

    try {
      setLoader(true);
      const res = await axiosInstance.post(`/tenant/tenants`, object);
      if (res.data.statusCode === 200) {
        toast.success("Tenant Added successfully!", {
          position: "top-center",
          autoClose: 900,
          onClose: () => navigate(`/${admin}/TenantsTable`),
        });
      } else {
        toast.error(res.data.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  const editTenant = async () => {
    const object = {
      ...tenantFormik.values,
      enable_override_fee: isEnable,
      admin_id: accessType?.admin_id,
      company_name: admin,
    };

    try {
      setLoader(true);
      const res = await axiosInstance.put(
        `/tenant/tenants/${tenant_id}`,
        object
      );
      if (res.data.statusCode === 200) {
        toast.success("Tenant Updated Successfully!", {
          position: "top-center",
          autoClose: 900,
          onClose: () =>
            isFromDetail
              ? navigate(`/${admin}/tenantdetail/${location.state.id}`)
              : navigate(`/${admin}/TenantsTable`),
        });
      } else {
        toast.warning(res.data.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  const fetchTenantData = async () => {
    if (tenant_id) {
      try {
        const res = await axiosInstance.get(`/tenant/get_tenant/${tenant_id}`);
        if (res.data.statusCode === 200) {
          tenantFormik.setValues(res.data.data);
          setIsEnable(res.data.data.enable_override_fee);
          setOverride(res.data.data.override_fee);
        } else if (res.data.statusCode === 201) {
          tenantFormik.resetForm();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const handleCloseButtonClick = () => {
    if (isFromDetail) {
      navigate(`/${admin}/tenantdetail/${location.state.id}`);
    } else {
      navigate(`/${admin}/TenantsTable`);
    }
  };

  useEffect(() => {
    if (tenant_id) {
      fetchTenantData();
    }
  }, [tenant_id]);

  const handleChange = (e) => {
    setIsEnable(e.target.checked);
    if (e.target.checked) {
      tenantFormik.setFieldValue("override_fee", override);
    } else {
      tenantFormik.setFieldValue("override_fee", null);
    }
  };
  return (
    <>
      {/* <TenantHeader id={tenant_id} /> */}

      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {tenant_id ? "Edit Tenant" : "Add Tenant"}
            </h2>
          </CardHeader>
        </Col>
        <Form>
          <Row className="mt-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className=" mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                {/* 6002 changing the design of the page all inputs  */}

                <CardBody>
                  <div className="">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_firstName"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            First Name *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              width: "80%",
                            }}
                            id="tenant_firstName"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter first name"
                            name="tenant_firstName"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_firstName}
                          />
                          {tenantFormik.touched.tenant_firstName &&
                          tenantFormik.errors.tenant_firstName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_firstName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_lastName"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Last Name *
                          </label>
                          <br />
                          <Input
                            id="tenant_lastName"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter last name"
                            style={{
                              marginRight: "10px",
                              flex: 1,
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            name="tenant_lastName"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_lastName}
                          />
                          {tenantFormik.touched.tenant_lastName &&
                          tenantFormik.errors.tenant_lastName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_lastName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* </Row>
                  </div>

                  <div className="">
                    <Row className="mt-2"> */}
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_phoneNumber"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number*
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="tenant_phoneNumber"
                            type="text"
                            name="tenant_phoneNumber"
                            placeholder="Enter phone number"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^\d*\.?\d*$/.test(e.target.value)) {
                                tenantFormik.handleChange(e);
                              }
                            }}
                            value={tenantFormik.values.tenant_phoneNumber}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_alternativeNumber"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Work Number
                          </label>
                          <br />
                          <Input
                            id="tenant_alternativeNumber"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="Number"
                            placeholder="Enter work number"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              width: "80%",
                            }}
                            name="tenant_alternativeNumber"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_alternativeNumber}
                            // onInput={(e) => {
                            //   const inputValue = e.target.value;
                            //   const numericValue = inputValue.replace(
                            //     /\D/g,
                            //     ""
                            //   );
                            //   e.target.value = numericValue;
                            // }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="">
                    <Row className="mt-2">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_email"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Email *
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              width: "80%",
                            }}
                            id="tenant_email"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter email"
                            name="tenant_email"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_email}
                          />
                          {tenantFormik.touched.tenant_email &&
                          tenantFormik.errors.tenant_email ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_email}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_alternativeEmail"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Alternative Email
                          </label>
                          <br />
                          <Input
                            id="tenant_alternativeEmail"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter alternative email"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              width: "80%",
                            }}
                            name="tenant_alternativeEmail"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => tenantFormik.handleChange(e)}
                            value={tenantFormik.values.tenant_alternativeEmail}
                          />
                          {tenantFormik.touched.tenant_alternativeEmail &&
                          tenantFormik.errors.tenant_alternativeEmail ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_alternativeEmail}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* </Row>
                  </div>
                  <div className="">
                    <Row className="mt-2"> */}
                      <Col lg="3">
                        <FormGroup>
                          <div
                            style={{
                              flex: 1,
                              marginRight: "10px",
                            }}
                          >
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="tenant_password"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Password
                            </label>
                            <br />
                            <div style={{ display: "flex", maxWidth: "100%" }}>
                              <Input
                                id="tenant_password"
                                className="form-control-alternative inputpassword fontstylerentmodal titleecolor"
                                variant="standard"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                                style={{
                                  marginRight: "10px",
                                  flex: 1,
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                name="tenant_password"
                                onBlur={tenantFormik.handleBlur}
                                onChange={tenantFormik.handleChange}
                                value={tenantFormik.values.tenant_password}
                              />
                              <Button
                                className="passwordbtnleaseing"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                }}
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </Button>
                            </div>
                             {tenantFormik.errors &&
                            tenantFormik.errors?.tenant_password &&
                            tenantFormik.touched &&
                            tenantFormik.touched?.tenant_password ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {tenantFormik.errors.tenant_password}
                              </div>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle fontfamilysty textcolorblue"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Personal Information
                  </h6>
                  <div className="">
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd3"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Date of Birth
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "70%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd3"
                            placeholder="3000"
                            type="date"
                            name="tenant_birthDate"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_birthDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd4"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            TaxPayer ID
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd4"
                            type="text"
                            placeholder="Enter tax payer id"
                            name="taxPayer_id"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.taxPayer_id}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="">
                    <Row className="mt-2">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Comments
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              height: "90px",
                              width: "100%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-address"
                            type="textarea"
                            placeholder="Enter comments"
                            name="comments"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.comments}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Emergency Contact
                  </h6>
                  <div className="">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd5"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Contact Name
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd5"
                            type="text"
                            name="emergency_contact.name"
                            placeholder="Enter contact name"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.emergency_contact?.name}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd6"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Relationship to Tenant
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd6"
                            type="text"
                            name="emergency_contact.relation"
                            placeholder="Enter relationship to tenant"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={
                              tenantFormik.values.emergency_contact?.relation
                            }
                          />
                        </FormGroup>
                      </Col>
                      {/* </Row>
                  </div>
                  <div className="">
                    <Row className="mt-2"> */}
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd7"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            E-Mail
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd7"
                            type="text"
                            name="emergency_contact.email"
                            placeholder="Enter email"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.emergency_contact?.email}
                          />
                          {tenantFormik.touched?.emergency_contact?.email &&
                          tenantFormik.errors?.emergency_contact?.email ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors?.emergency_contact?.email}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd8"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number
                          </label>
                          <Input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd8"
                            type="text"
                            name="emergency_contact.phoneNumber"
                            placeholder="Enter phone number"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^\d*\.?\d*$/.test(e.target.value)) {
                                tenantFormik.handleChange(e);
                              }
                            }}
                            value={
                              tenantFormik.values.emergency_contact?.phoneNumber
                            }
                            // onInput={(e) => {
                            //   const inputValue = e.target.value;
                            //   const numericValue = inputValue.replace(
                            //     /\D/g,
                            //     ""
                            //   );
                            //   e.target.value = numericValue;
                            // }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 6002 end */}
          <Row className="mt-3 mb-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Override Debit Card Fee
                  </h6>
                  <div className="">
                    <Row>
                      <Col lg="4">
                        <FormGroup
                          className="checkboxbillable "
                          style={{ alignItems: "center" }}
                        >
                          <Label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="override-enable"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            <Checkbox
                              id="override-enable"
                              className="checkboxx"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                                "&.Mui-checked": {
                                  color: "#152B51",
                                },
                              }}
                              checked={isEnable === true}
                              onChange={handleChange}
                            />
                            Enable Debit Card Fee Override
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {isEnable && (
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <div style={{ position: "relative", width: "70%" }}>
                              <Input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                className="form-control-alternative  fontstylerentmodal titleecolor"
                                id="input-unitadd6"
                                type="text"
                                name="override_fee"
                                placeholder="Add fee here"
                                onBlur={tenantFormik.handleBlur}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only numeric values including decimals and within range 0 to 100
                                  if (
                                    /^\d*\.?\d*$/.test(value) &&
                                    (value === "" ||
                                      (parseFloat(value) >= 0 &&
                                        parseFloat(value) <= 100))
                                  ) {
                                    tenantFormik.handleChange(e);
                                  }
                                }}
                                value={tenantFormik.values.override_fee}
                              />
                              <span
                                className="fontstylerentmodal"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                %
                              </span>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div className=" mt-3 mb-4 btnstyle" style={{ marginLeft: "33px" }}>
              {loader ? (
                <Button
                  type="submit fontstylerentr background-colorsty bgtextwhite"
                  className="btn"
                  style={{
                    cursor: "not-allowed",
                  }}
                  disabled
                >
                  Loading...
                </Button>
              ) : tenant_id ? (
                <Button
                  type="submit"
                  className="btn bottomspacee fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: 0,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    tenantFormik.handleSubmit();
                  }}
                >
                  Update Tenant
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn bottomspacee fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: 0,
                  }}
                  disabled={!tenantFormik.isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    tenantFormik.handleSubmit();
                  }}
                >
                  Add Tenant
                </Button>
              )}
              <Button
                // color="primary"
                onClick={handleCloseButtonClick}
                className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  cursor: "pointer",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="ml-3 mt-3 validationmsgmt">
              {/* Conditional message */}
              {!tenantFormik.isValid && (
                <div
                  className="fontstylerentr validationmsgtenant"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  Please fill in all fields correctly.
                </div>
              )}
            </div>
          </Row>
        </Form>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Leaseing;
