import { Modal } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "20px",
  width: 600,
  height: "400px",

  // Media query for screens with a max-width of 400px
  "@media screen and (max-width: 400px)": {
    width: 200, // Width for screens with a max-width of 400px
  },

  // Media query for screens with a max-width of 620px
  "@media screen and (max-width: 620px)": {
    width: 300, // Width for screens with a max-width of 620px
  },

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// export const OpenImageDialog = (props) => {
//   //   const handleOpen = () => props.setOpen(true);
//   const handleClose = () => props.setOpen(false);
//   const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;

//   const isBlobURL = (url) => {
//     return url && url?.startsWith("blob:");
//   };
//   return (
//     <Modal
//       open={props.open}
//       onClose={handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <div style={style}>
//         {props.selectedImage.type === "video/mp4" ||
//         props.selectedImage.endsWith(".mp4") ? (
//           <video
//             width="100%"
//             height="100%"
//             style={{
//               maxHeight: "100%",
//               maxWidth: "100%",
//               borderRadius: "10px",
//               cursor: "pointer",
//             }}
//             controls
//           >
//             <source
//               src={
//                 !props.selectedImage?.type
//                   ? `${imageGetUrl}/${props.selectedImage}`
//                   : URL.createObjectURL(props.selectedImage)
//               }
//               type="video/mp4"
//             />
//           </video>
//         ) : (
//           <img
//             style={style}
//             src={
//               !isBlobURL(props.selectedImage)
//                 ? `${imageGetUrl}/${props.selectedImage}`
//                 : props.selectedImage
//             }
//             alt="Image"
//           />
//         )}
//         <ClearIcon
//           style={{
//             cursor: "pointer",
//             position: "absolute",
//             top: "10px",
//             right: "10px",
//             color: "black",
//           }}
//           onClick={handleClose}
//         />
//       </div>
//     </Modal>
//   );
// };

export const OpenImageDialog = (props) => {
  const [videoError, setVideoError] = useState(false);
  const handleClose = () => props.setOpen(false);
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;

  // Function to check if the file is a video
  const isVideo = (file) => {
    if (typeof file === "string") {
      return (
        file.endsWith(".mp4") || file.endsWith(".avi") || file.endsWith(".mov")
      );
    }
    return file?.type?.startsWith("video/");
  };

  // Get correct media source URL
  const getMediaSrc = () => {
    if (typeof props.selectedImage === "string") {
      return `${imageGetUrl}/${props.selectedImage}`;
    }
    return URL.createObjectURL(props.selectedImage);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>
        {isVideo(props.selectedImage) ? (
          videoError ? (
            <div
              style={{
                padding: "20px",
                position: "relative",
                textAlign: "center",
              }}
            >
              <p style={{ color: "red", fontWeight: "bold" }}>
                File format not supported!
              </p>
              <p>Please download the file to preview it.</p>
              <a
                href={getMediaSrc()}
                download={props.selectedImage.name || "unsupported_file"}
                style={{
                  display: "inline-block",
                  marginTop: "10px",
                  padding: "8px 8px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                Download File
              </a>
            </div>
          ) : (
            <video
              width="100%"
              height="100%"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              controls
              onError={() => setVideoError(true)}
            >
              <source
                src={getMediaSrc()}
                type={props.selectedImage.type || "video/mp4"}
              />
              Your browser does not support this video format.
            </video>
          )
        ) : (
          <img
            style={style}
            src={
              props.selectedImage
                ? props.selectedImage
                : `${imageGetUrl}/${props.selectedImage}`
            }
            alt="Preview"
          />
        )}
        <ClearIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "black",
          }}
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};
