// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-box {
  display: flex;
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
}
.type-box p {
  font-weight: bold;
}
.type-box .allowed-icon {
  color: green;
}
.type-box .not-allowed-icon {
  color: red;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/AdminViews/Rentals/CSS/rentals.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAGE;EACE,UAAA;EACA,kBAAA;AADJ","sourcesContent":[".type-box {\n  display: flex;\n  width: 100%;\n  max-width: 300px;\n  justify-content: space-between;\n  align-items: center;\n\n  p {\n    font-weight: bold;\n  }\n\n  .allowed-icon {\n    color: green;\n  }\n\n  .not-allowed-icon {\n    color: red;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
