import {
  Card,
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import Header from "components/Headers/Header";
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaCheck, FaTimes } from "react-icons/fa";
// import ListMapComponent from "../../components/ListMapComponent";
import { Checkbox } from "@mui/material";
import "./workorders.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const Workorder = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const [workData, setWorkData] = useState();
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageItem, setPageItem] = useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [mapDisplay, setMapDisplay] = useState(false);
  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");

  const [accessType, setAccessType] = useState();

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const handleCheckboxChange = (event) => {
    setMapDisplay(event.target.checked);
  };

  const getWorkData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/work-orders/${accessType?.admin_id}`
        );
        if (response?.data?.data) {
          const filteredData = response.data.data?.map((item) => ({
            workOrder_id: item?.workOrderData?.workOrder_id,
            work_subject: item?.workOrderData?.work_subject,
            work_category: item?.workOrderData?.work_category,
            status: item?.workOrderData?.status,
            date: item?.workOrderData?.date,
            createdAt: item?.workOrderData?.createdAt,
            updateAt: item?.workOrderData?.updatedAt,
            rental_id: item?.rentalAddress?.rental_id,
            rental_adress: item?.rentalAddress?.rental_adress,
            rental_city: item?.rentalAddress?.rental_city,
            rental_state: item?.rentalAddress?.rental_state,
            rental_country: item?.rentalAddress?.rental_country,
            rental_postcode: item?.rentalAddress?.rental_postcode,
            unit_id: item?.rentalUnit?.unit_id,
            rental_unit: item?.rentalUnit?.rental_unit,
            staffmember_id: item?.staffMember?.staffmember_id,
            staffmember_name: item?.staffMember?.staffmember_name,
            is_billable: item?.workOrderData?.is_billable,
          }));
          setWorkData(filteredData);
          setTotalPages(Math.ceil(filteredData.length / pageItem));
          // setLoader(false);
        } else {
          setWorkData([]);
          setTotalPages(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  const deleteRentals = (id) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this work order!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     axiosInstance
    //       .delete(`/work-order/delete_workorder/${id}`)
    //       .then((response) => {
    //         if (response.data.statusCode === 200) {
    //           toast.success("Work Order deleted successfully", {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //           getWorkData();
    //         } else if (response.data.statusCode === 203) {
    //           toast.warn(response.data.message, {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //         } else {
    //           toast.error(response.data.message, {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error deleting work order:", error);
    //       });
    //   } else {
    //     toast.success("Work Orer is safe!", {
    //       position: "top-center",
    //       autoClose: 1000,
    //     });
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this work order!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;

        try {
          const response = await axiosInstance.delete(
            `/work-order/delete_workorder/${id}`,
            {
              data: {
                reason: deletionReason,
              },
            }
          );

          if (response.data.statusCode === 200) {
            toast.success("Work Order deleted successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            getWorkData();
          } else if (response.data.statusCode === 203) {
            toast.warn(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          } else {
            toast.error(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error deleting work order:", error);
          toast.error("Error deleting work order", {
            position: "top-center",
          });
        }
      } else {
        toast.success("Work Order is safe!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  useEffect(() => {
    getWorkData();
  }, [accessType, pageItem]);

  const [billableFilter, setBillableFilter] = useState(false);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [search, setSearch] = React.useState(false);
  const toggle3 = () => setSearch((prevState) => !prevState);
  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (workData) {
    paginatedData = workData.slice(startIndex, endIndex);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editWorkOrder = (id) => {
    navigate(`/${admin}/addworkorder/${id}`);
  };
  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };
  const sortData = (column, order) => {
    let sortedData = [...workData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress.localeCompare(b.rental_adress)
            : b.rental_adress.localeCompare(a.rental_adress)
        );
        break;
      case "work_subject":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_subject.localeCompare(b.work_subject)
            : b.work_subject.localeCompare(a.work_subject)
        );
        break;
      case "work_category":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_category.localeCompare(b.work_category)
            : b.work_category.localeCompare(a.work_category)
        );
        break;
      case "date":
        sortedData.sort((a, b) =>
          order === "asc"
            ? new Date(a?.date) - new Date(b?.date)
            : new Date(b?.date) - new Date(a?.date)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a?.createdAt) - new Date(b?.createdAt)
            : new Date(b?.createdAt) - new Date(a?.createdAt)
        );
        break;
      default:
        break;
    }
    setWorkData(sortedData || []);
  };
  const filterRentalsBySearch = () => {
    let filteredData = [...workData];
    if (searchQuery2 && !searchQuery) {
      if (searchQuery2 === "All") {
        return workData;
      }
      if (searchQuery2 === "Over Due") {
        return workData.filter((rental) => {
          let currentDate = new Date();
          let rentalDate = new Date(rental.date);
          return rentalDate < currentDate && rental.status !== "Completed";
        });
      }
      return workData.filter((rental) => {
        const lowerCaseQuery = searchQuery2.toLowerCase();
        return rental.status.toLowerCase().includes(lowerCaseQuery);
      });
    }

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toString().toLowerCase();
      filteredData = filteredData.filter((work) => {
        return (
          (work.rental_adress &&
            work.rental_adress.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.work_subject &&
            work.work_subject.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.work_category &&
            work.work_category.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.staffmember_name &&
            work.staffmember_name.toLowerCase().includes(lowerCaseSearchQuery))
        );
      });
    }

    // Filter by billable records
    if (billableFilter) {
      filteredData = filteredData.filter((work) => work.is_billable);
    }

    // if (upArrow.length > 0) {
    //   const sortingArrows = upArrow;
    //   sortingArrows.forEach((value) => {
    //     switch (value) {
    //       case "rental_adress":
    //         filteredData.sort((a, b) =>
    //           a.rental_adress?.localeCompare(b.rental_adress)
    //         );
    //         break;
    //       case "work_subject":
    //         filteredData.sort((a, b) =>
    //           a.work_subject?.localeCompare(b.work_subject)
    //         );
    //         break;
    //       case "work_category":
    //         filteredData.sort((a, b) =>
    //           a.work_category?.localeCompare(b.work_category)
    //         );
    //         break;
    //       case "staffmember_name":
    //         filteredData.sort((a, b) =>
    //           a.staffmember_name?.localeCompare(b.staffmember_name)
    //         );
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    // }
    if (!sortedColumn) {
      // If no sorting column is specified, sort by createdAt in descending order
      filteredData?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }
    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const navigateToDetails = (workorder_id) => {
    navigate(`/${admin}/workorderdetail/${workorder_id}`, {
      state: { workorder_id: workorder_id },
    });
  };

  // const sortData = (value) => {
  //   if (!sortBy.includes(value)) {
  //     setSortBy([...sortBy, value]);
  //     setUpArrow([...upArrow, value]);
  //     filterTenantsBySearchAndPage();
  //   } else {
  //     setSortBy(sortBy.filter((sort) => sort !== value));
  //     setUpArrow(upArrow.filter((sort) => sort !== value));
  //     filterTenantsBySearchAndPage();
  //   }
  //   // setOnClickUpArrow(!onClickUpArrow);
  // };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        {/* Table */}
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button
              className="workorderbtn background-colorsty bgtextwhite"
              // color="primary"
              //  href="#rms"
              onClick={() => navigate("/" + admin + "/addworkorder")}
              size="small"
            >
              Add Work Order
            </Button>
          </Col>

          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
            <CardHeader
              className="mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Work Orders
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />

        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="d-flex ml-0 mb-3">
                  <Col lg="6">
                    <Row>
                      <FormGroup className="mr-sm-2 searchspace searchstyle">
                        <Input
                          className="filterh titleecolor"
                          // fullWidth
                          type="text"
                          placeholder="Search here..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            minWidth: "200px",
                            fontWeight: "500",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mr-sm-2">
                        <Dropdown isOpen={search} toggle={toggle3}>
                          <DropdownToggle
                            className="selecttypee typeselectprop titleecolor"
                            caret
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              maxWidth: "200px",
                              minWidth: "200px",
                              backgroundColor: "transparent",
                            }}
                          >
                            {searchQuery2
                              ? searchQuery
                                ? "Select Type"
                                : searchQuery2
                              : "Select Type"}
                          </DropdownToggle>
                          <DropdownMenu className="dropdownmeunprop">
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("New");
                                setSearchQuery("");
                              }}
                            >
                              New
                            </DropdownItem>
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("In Progress");
                                setSearchQuery("");
                              }}
                            >
                              In Progress
                            </DropdownItem>
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("On Hold");
                                setSearchQuery("");
                              }}
                            >
                              On Hold
                            </DropdownItem>
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("Completed");
                                setSearchQuery("");
                              }}
                            >
                              Completed
                            </DropdownItem>
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("Over Due");
                                setSearchQuery("");
                              }}
                            >
                              Over Due
                            </DropdownItem>
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("Closed");
                                setSearchQuery("");
                              }}
                            >
                              Closed
                            </DropdownItem>
                            {/* 6002 */}
                            <DropdownItem
                              className="searchcfiltertext"
                              onClick={() => {
                                setSearchQuery2("All");
                                setSearchQuery("");
                              }}
                            >
                              All
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </FormGroup>
                      <div
                        className="viewmapstyle viewmaptext"
                        style={{ flexDirection: "row" }}
                      >
                        <Checkbox
                          className="checkview"
                          checked={billableFilter}
                          onChange={(e) => setBillableFilter(e.target.checked)}
                          name="billable"
                          sx={{
                            "&.Mui-checked": {
                              color: "#152B51",
                            },
                          }}
                        />
                        <Label
                          className="font-view textcolorblue fontfamilysty"
                          size="small"
                          style={{
                            marginTop: "13px",
                            fontWeight: "600",
                          }}
                        >
                          Show Billable
                        </Label>
                      </div>

                      {/* <div className="viewmapstyle viewmaptext" style={{ flexDirection: "row" }}>

                        <Checkbox className="checkview"
                          checked={mapDisplay}
                          onChange={handleCheckboxChange}
                          name="map"
                          sx={{
                            '&.Mui-checked': {
                              color: '#152B51',
                            },
                          }}
                        />

                        <Label
                          className="font-view"
                          size="small"
                          style={{
                            color: "#152B51", marginTop: "13px", fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          View Map
                        </Label>
                      </div> */}
                    </Row>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                {/* {mapDisplay ? (
                  <>
                    <div
                      className="mb-3 mapdisplaystyle"
                      style={{ borderRadius: "20px", overflow: "hidden" }}
                    >
                      <ListMapComponent
                        addresses={workData.map((rental) => [
                          rental.rental_adress,
                          rental.rental_city,
                          rental.rental_state,
                          rental.rental_country,
                          rental.rental_postcode,
                        ])}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Work Order
                        {/* {sortBy.includes("work_subject") ? (
                          upArrow.includes("work_subject") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("work_subject")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("work_subject")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_subject")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property
                        {/* {sortBy.includes("rental_adress") ? (
                          upArrow.includes("rental_adress") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_adress")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Category
                        {/* {sortBy.includes("work_category") ? (
                          upArrow.includes("work_category") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("work_category")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("work_category")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("work_category")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Billable
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Assigned
                        {/* {sortBy.includes("staffmember_name") ? (
                          upArrow.includes("staffmember_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_name")}
                          />
                        )} */}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>

                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                        style={{cursor:"pointer"}}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("date")}
                        onMouseEnter={() => setHoveredColumn("date")}
                        onMouseLeave={() => setHoveredColumn("")}
                        style={{cursor:"pointer"}}
                      >
                        Due Date
                        {hoveredColumn === "date" || sortedColumn === "date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      {/* <th
                        className="tablefontstyle"
                        scope="col"
                        style={{ color: "#152B51" }}
                      >
                        Updated At
                      </th> */}
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  {workData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Workorder Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((rental) => (
                        <tr
                          key={rental.workOrder_id}
                          onClick={() => navigateToDetails(rental.workOrder_id)}
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.work_subject}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.rental_adress}{" "}
                            {rental.rental_units
                              ? " - " + rental.rental_units
                              : null}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.work_category}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.is_billable ? (
                              <FaCheck style={{ color: "#152B51" }} />
                            ) : (
                              // <FaTimes style={{ color: "#152B51" }} />
                              " "
                            )}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.staffmember_name || "-"}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.status || "-"}
                          </td>

                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(rental?.createdAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(rental?.date).format(dateFormat)}
                          </td>
                          {/* <td className="bordertopintd tablebodyfont">
                            {rental.updateAt || "-"}
                          </td> */}

                          <td className="bordertopintd tablebodyfont">
                            <div style={{ display: "flex", gap: "5px" }}>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteRentals(rental.workOrder_id);
                                }}
                              >
                                <img src={deleicon} width={20} height={20} />
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  editWorkOrder(rental.workOrder_id);
                                }}
                              >
                                <img src={editicon} width={20} height={20} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default Workorder;
