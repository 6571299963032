import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink as NavLinkRRD,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import routes from "routes";
import "./style.css";
import logoa from "../../assets/icons/websitelogo.svg";
import { styled, Tooltip, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies, Cookies } from "react-cookie";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { verifyToken } from "components/Functions/Functions";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#152b51",
    color: "#fff",
    fontSize: "16px",
    borderRadius: "4px",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#152b51",
  },
}));

const Sidebar = ({
  logo,
  isCollapse,
  toggleCollapse,
  sideOpen,
  setSideOpen,
}) => {
  const [accessType, setAccessType] = useState(null);
  const [userroleData, setUserroleData] = useState(null);
  const navigate = useNavigate();
  const [cookies, setCoockie, removeCookie] = useCookies();
  const location = useLocation();
  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const newCookies = new Cookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie);
      setAccessType(accessData);
      newCookies.set("admin_id", accessData?.admin_id, { path: "/" });
      newCookies.set("tenant_id", accessData?.tenant_id, { path: "/" });
    };

    handleCheckToken();
  }, []);
  useEffect(() => {
    const updateColors = () => {
      if (accessType && accessType?.themes) {
        const colors = accessType?.themes?.colors;
        for (const key in colors) {
          if (Object.prototype.hasOwnProperty.call(colors, key)) {
            const element = colors[key];
            document.documentElement.style.setProperty(key, element);
          }
        }
      }
    };
    updateColors();
  }, [accessType]);

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType?.admin_id);
        setUserroleData(adminData?.tenant_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const createLinks = () => {
    const filteredRoutes = routes.filter(
      (prop) =>
        (prop.name === "DashBoard" ||
          prop.name === "Profile" ||
          (userroleData?.financial_view && prop.name === "Ledger") ||
          (userroleData?.workorder_view &&
            prop.name === "Work Order" &&
            prop.path === "/tenantwork") ||
          (userroleData?.property_view && prop.name === "Property") ||
          (userroleData?.documents_view && prop.name === "Documents")) &&
        prop.layout === "/tenant"
    );

    return filteredRoutes.map((prop, key) => {
      const path = prop.layout === "/tenant" ? "/tenant" : "/tenant";
      const isActive = location.pathname === path + prop.path;

      return (
        <React.Fragment key={key}>
          <NavItem key={key} style={{ justifyContent: "center" }}>
            <CustomTooltip
              title={isCollapse && prop.name}
              arrow
              placement="right"
            >
              <div>
                <NavLink
                  to={path + prop.path}
                  tag={NavLinkRRD}
                  style={{
                    justifyContent: isCollapse && "center",
                    width: "100%",
                  }}
                  className="d-flex align-items-center"
                  onClick={() => setSideOpen(false)}
                >
                  <span
                    style={{
                      marginRight: !isCollapse && "20px",
                      marginLeft: !isCollapse && "10px",
                    }}
                  >
                    {isActive ? (
                      <img src={prop.icon2} width={isMediumScreen ? 15 : 20} />
                    ) : (
                      <img src={prop.icon} width={isMediumScreen ? 15 : 20} />
                    )}
                  </span>
                  {!isCollapse && <>{prop.name}</>}
                </NavLink>
              </div>
            </CustomTooltip>
          </NavItem>
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideOpen && !!event.target.closest(".menub")) {
        setSideOpen(true);
      } else if (sideOpen && !event.target.closest(".collapse-sidebar")) {
        setSideOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sideOpen]);

  return (
    <>
      <div
        className={
          isMediumScreen
            ? "collapse-sidebar stylemobileres"
            : !isCollapse
            ? "sidebar"
            : "sidebar-active"
        }
        style={{
          display: !sideOpen && isMediumScreen && "none",
          borderTopRightRadius: isMediumScreen && "20px",
          borderBottomRightRadius: isMediumScreen && "20px",
        }}
      >
        <Nav vertical>
          <Link
            to="/admin/tenantdashboard"
            style={{
              justifyContent: isCollapse && "center",
              padding: "15px",
            }}
          >
            {isCollapse ? (
              <img src={logo.imgSrc2} width={60} />
            ) : (
              <img src={logoa} width={!isMediumScreen ? 250 : "100%"} />
            )}
          </Link>
          {createLinks()}
        </Nav>
        <span
          className={isCollapse ? "collapse-btn-active" : "collapse-btn"}
          style={{ display: isMediumScreen && "none" }}
          onClick={(e) => {
            toggleCollapse();
          }}
        >
          {isCollapse ? (
            <FontAwesomeIcon icon={faArrowRight} />
          ) : (
            <FontAwesomeIcon icon={faArrowLeft} />
          )}
        </span>
      </div>
      {isMediumScreen && (
        <CloseIcon
          className="closebtnstylesidebar"
          style={{
            width: "8%",
            color: "#000",
            display: !sideOpen && "none",
            position: "absolute",
            top: "5px",
            left: "52%",
            zIndex: 9999,
            padding: "0",
            borderRadius: "50%",
            backgroundColor: "#fff",
          }}
          onClick={() => {
            setSideOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Sidebar;
