import Chart from "chart.js";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.js";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import VendorHeader from "components/Headers/VendorHeader";
import { RotatingLines } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, Tooltip } from "recharts";
import book from "../../assets/icons/bookpaper.svg";
import clock from "../../assets/icons/clock.svg";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import { useCookies } from "react-cookie";

const ComparisonGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={Object.keys(data).map((month) => ({
          month,
          "Active Plans": data[month].activePlans,
          "Inactive Plans": data[month].inactivePlans,
        }))}
      >
        <Tooltip />
        <Bar dataKey="Active Plans" fill="#152B51" barSize={20} />
        <Bar dataKey="Inactive Plans" fill="#5A86D5" barSize={20} />
        <XAxis dataKey="month" axisLine={false} tickLine={false} />
      </BarChart>
    </ResponsiveContainer>
  );
};

const ComparisonChartContainer = () => {
  const data = {
    Jan: { activePlans: 50, inactivePlans: 30 },
    Feb: { activePlans: 60, inactivePlans: 25 },
    Mar: { activePlans: 70, inactivePlans: 35 },
    Apr: { activePlans: 55, inactivePlans: 40 },
    May: { activePlans: 45, inactivePlans: 20 },
    Jun: { activePlans: 65, inactivePlans: 30 },
    Jul: { activePlans: 75, inactivePlans: 35 },
    Aug: { activePlans: 50, inactivePlans: 30 },
    Sep: { activePlans: 70, inactivePlans: 25 },
    Oct: { activePlans: 60, inactivePlans: 35 },
    Nov: { activePlans: 55, inactivePlans: 45 },
    Dec: { activePlans: 45, inactivePlans: 20 },
  };

  return (
    <div style={{ maxWidth: "100%", overflowX: "auto" }}>
      <ComparisonGraph data={data} />
    </div>
  );
};

const VendorDashBoard = () => {
  let [loader, setLoader] = React.useState(true);

  let navigate = useNavigate();
  let location = useLocation();
  const [cookies, setCoockie, removeCookie] = useCookies();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const [accessType, setAccessType] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, "", removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [newWorkOrders, setNewWorkOrders] = useState([]);
  const [overdueWorkOrders, setOverdueWorkOrders] = useState([]);

  useEffect(() => {
    const fetchWorkOrders = async () => {
      if (accessType?.vendor_id && accessType?.admin_id) {
        try {
          const response = await axiosInstance.get(
            `/vendor/dashboard_workorder/${accessType?.vendor_id}/${accessType?.admin_id}`
          );
          if (response.status === 200) {
            const { data } = response.data;
            setNewWorkOrders(data.new_workorder);
            setOverdueWorkOrders(data.overdue_workorder);
          } else {
            console.error("Failed to fetch work orders");
          }
        } catch (error) {
          console.error("Error fetching work orders:", error);
        } finally {
          setLoader(false);
        }
      }
    };
    fetchWorkOrders();
  }, [accessType]);

  const [selectedYearPlan, setSelectedYearPlan] = useState("This Year");

  const [dropdownOpenPlan, setdropdownOpenPlan] = useState(false);

  const togglePlan = () => setdropdownOpenPlan((prevState) => !prevState);

  const handleChangePlan = (year) => {
    setSelectedYearPlan(year);
  };

  return (
    <>
      <VendorHeader prop={"My Dashboard"} />

      <Container className="vendorcontainerdash" fluid>
        <div>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <Row
                xs="1"
                sm="2"
                md="2"
                lg="3"
                xl={"5"}
                className="px-3 pt-3 pb-1 custom-rowvendor"
              >
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Poppins",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                      backgroundColor: "#152B51",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() =>
                      navigate("/vendor/vendorworktable?status=New")
                    }
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5 flex-column"
                    >
                      <Row style={{ flex: 1.5 }}>
                        <Col>
                          <span
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              fontSize: "20px",
                              padding: "15px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={book} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {newWorkOrders?.length.toString().padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            New Work Orders
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <label
                    className="d-flex justify-content-center mt-3 vendorbtnstyleviewall"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "#152B51",
                      height: "39px",
                      width: "94%",
                      marginLeft: "10px",
                      borderRadius: "6px",
                      padding: "8px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                    }}
                    onClick={() =>
                      navigate("/vendor/vendorworktable?status=New")
                    }
                  >
                    <span
                      className="fontstylerentr"
                      style={{
                        borderBottom: "2px solid #D7E0FF",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      View All
                    </span>
                  </label>
                </Col>
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Poppins",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                      backgroundColor: "#5A86D5",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() =>
                      navigate("/vendor/vendorworktable?status=Over Due")
                    }
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5"
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              fontSize: "20px",
                              padding: "15px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={clock} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {overdueWorkOrders?.length
                              .toString()
                              .padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Overdue Work Orders
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <label
                    className="d-flex justify-content-center mt-3 vendorbtnstyleviewall"
                    style={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "#5A86D5",
                      height: "39px",
                      width: "94%",
                      marginLeft: "10px",
                      borderRadius: "6px",
                      padding: "8px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                    }}
                    onClick={() =>
                      navigate("/vendor/vendorworktable?status=Over Due")
                    }
                  >
                    <span
                      className="fontstylerentr"
                      style={{
                        borderBottom: "2px solid #D7E0FF",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      View All{" "}
                    </span>
                  </label>
                </Col>
              </Row>

              <Row style={{ fontFamily: "poppins" }}>
                <Col xs={12} lg={10} sm={12} md={10}>
                  <div className="d-flex mt-3 vendordashboardcard">
                    <div
                      className="mr-auto p-2 labelfontstyle"
                      style={{
                        fontSize: "24px",
                        fontWeight: "700",
                        fontFamily: "Manrope",
                        color: "#030303",
                      }}
                    >
                      Statistics
                    </div>
                    <div className="d-flex" style={{ marginTop: "11px" }}>
                      <div
                        className="px-1 py-0 d-flex align-items-center"
                        style={{
                          backgroundColor: "#CEE9FF",
                          borderRadius: "5px",
                          width: "155px",
                          height: "34px",
                        }}
                      >
                        <i
                          className="fa-solid fa-square mx-2"
                          style={{ color: "#152B51" }}
                        ></i>
                        <span
                          className="fontstylerentmodal"
                          style={{
                            color: "#1C1C1E",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          New Work Orders
                        </span>
                      </div>
                      <div
                        className="px-1 py-0 d-flex align-items-center ml-5"
                        style={{
                          backgroundColor: "#CEE9FF",
                          borderRadius: "5px",
                          width: "182px",
                          height: "34px",
                        }}
                      >
                        <i
                          className="fa-solid fa-square mx-2 "
                          style={{ color: "#5A86D5" }}
                        ></i>
                        <span
                          className="fontstylerentmodal"
                          style={{
                            color: "#1C1C1E",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          Overdue Work Orders
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3 mb-3 mx-1">
                <Col
                  xs={12}
                  lg={11}
                  xl={10}
                  sm={12}
                  md={12}
                  style={{
                    border: "0.5px Solid #A8A9AC",
                    borderRadius: "20px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                  }}
                >
                  <Col className="d-flex justify-content-end mt-3 mb-3">
                    <Dropdown
                      isOpen={dropdownOpenPlan}
                      toggle={togglePlan}
                      style={{
                        borderRadius: "16px",
                      }}
                    >
                      <DropdownToggle
                        caret
                        style={{
                          backgroundColor: "#152B51",
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        {selectedYearPlan ? selectedYearPlan : "Select Year"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{
                          backgroundColor: "#152B51",
                          left: "0",
                          transform: "translateX(-100%)",
                          minWidth: "50%",
                        }}
                      >
                        <DropdownItem
                          onClick={() => handleChangePlan("This Year")}
                          style={{
                            color: "#fff",
                            backgroundColor: "transparent",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#5A86D5")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                          }
                        >
                          This Year
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleChangePlan("Previous Year")}
                          style={{
                            color: "#fff",
                            backgroundColor: "transparent",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#5A86D5")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                          }
                        >
                          Previous Year
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>

                  <ComparisonChartContainer />
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default VendorDashBoard;
