import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Input,
  Col,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components
import TenantsHeader from "components/Headers/TenantsHeader";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const TenantWork = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");

  useEffect(() => {
    if (status === "Over Due") {
      setSearchQuery2("Over Due");
    } else if (status === "New") {
      setSearchQuery2("New");
    }
  }, [status]);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  const [workData, setWorkData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [search, setSearch] = React.useState(false);
  const toggle3 = () => setSearch((prevState) => !prevState);
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  const [userroleData, setUserroleData] = useState(null);
  const location = useLocation();

  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getRentalData = async () => {
    if (accessType?.tenant_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/tenant_work/${accessType?.tenant_id}`
        );
        setWorkData(response.data.data.reverse());
        setTotalPages(Math.ceil(response.data?.data?.length / pageItem));
      } catch (error) {
        console.error("Error fetching work order data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getRentalData();
  }, [accessType, pageItem]);

  const navigateToDetails = (tenantId) => {
    navigate(`/tenant/Tworkorderdetail/${tenantId}`);
  };

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };
  const sortData = (column, order) => {
    let sortedData = [...workData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress.localeCompare(b.rental_adress)
            : b.rental_adress.localeCompare(a.rental_adress)
        );
        break;
      case "work_subject":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_subject.localeCompare(b.work_subject)
            : b.work_subject.localeCompare(a.work_subject)
        );
        break;
      case "work_category":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_category.localeCompare(b.work_category)
            : b.work_category.localeCompare(a.work_category)
        );
        break;
      case "date":
        sortedData.sort((a, b) =>
          order === "asc"
            ? new Date(a?.date) - new Date(b?.date)
            : new Date(b?.date) - new Date(a?.date)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a?.createdAt) - new Date(b?.createdAt)
            : new Date(b?.createdAt) - new Date(a?.createdAt)
        );
        break;
      default:
        break;
    }
    setWorkData(sortedData || []);
  };

  const filterRentalsBySearch = () => {
    let filteredData = [...workData];

    if (searchQuery2) {
      if (searchQuery2 === "All") {
        filteredData = workData;
      } else if (searchQuery2 === "Over Due") {
        filteredData = workData.filter((rental) => {
          let currentDate = new Date();
          let rentalDate = new Date(rental.date);
          return rentalDate < currentDate && rental.status !== "Completed";
        });
      } else {
        filteredData = workData.filter((rental) => {
          const lowerCaseQuery = searchQuery2?.toLowerCase();
          return rental.status?.toLowerCase().includes(lowerCaseQuery);
        });
      }
    }

    if (searchQuery) {
      filteredData = filteredData.filter((rental) => {
        const lowerCaseQuery = searchQuery?.toLowerCase();
        const isUnitAddress = (rental.rental_adress + " " + rental.unit_no)
          ?.toLowerCase()
          .includes(lowerCaseQuery);
        const hasStaffMemberName =
          rental.staffmember_name &&
          rental.staffmember_name?.toLowerCase().includes(lowerCaseQuery);
        return (
          rental.work_subject?.toLowerCase().includes(lowerCaseQuery) ||
          rental.work_category?.toLowerCase().includes(lowerCaseQuery) ||
          rental.status?.toLowerCase().includes(lowerCaseQuery) ||
          isUnitAddress ||
          hasStaffMemberName ||
          rental.priority?.toLowerCase().includes(lowerCaseQuery)
        );
      });
    }

    if (!sortedColumn) {
      filteredData?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.tenant_permission);
      }
    };
    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);
  return (
    <>
      <TenantsHeader />
      <Container className="stylecontainer" fluid>
     
        <Row>
          {userroleData?.workorder_add && (
            <Col className="text-right">
              <Button
                className="fontstylerentr background-colorsty bgtextwhite"
                
                onClick={() => navigate("/tenant/taddwork")}
                size="small"
              >
                Add Work Order
              </Button>
            </Col>
          )}
        </Row>
        <CardHeader
          className=" mt-3 mb-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Work Orders
          </h2>
        </CardHeader>

        <Row>
          <div className="col">
          
            <CardHeader className="border-0 p-0 mt-3 mb-3">
              <Row className="d-flex mx-2">
               
                <FormGroup className="mr-sm-2 searchspace searchstyle">
                  <Input
                    className="filterh"
                    fullWidth
                    type="text"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                      minWidth: "200px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </FormGroup>
                <FormGroup className="mr-sm-2">
                  <Dropdown isOpen={search} toggle={toggle3}>
                    <DropdownToggle
                      className="selecttypee typeselectprop titleecolor"
                      caret
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                        maxWidth: "200px",
                        minWidth: "200px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {searchQuery2 ? searchQuery2 : "Select Status"}
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmeunprop">
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("New");
                        }}
                      >
                        New
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("In Progress");
                        }}
                      >
                        In Progress
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("On Hold");
                        }}
                      >
                        On Hold
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Completed");
                        }}
                      >
                        Completed
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Over Due");
                        }}
                      >
                        Over Due
                      </DropdownItem>
                      {/* 6002 */}
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("Closed");
                        }}
                      >
                        Closed
                      </DropdownItem>
                      <DropdownItem
                        className="searchcfiltertext"
                        onClick={() => {
                          setSearchQuery2("All");
                        }}
                      >
                        All
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </FormGroup>
                {/* </Col> */}
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-flush"
              responsive
              style={{ borderCollapse: "collapse" }}
            >
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loader}
                  />
                </div>
              ) : (
                <>
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Work Order
                       
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property
                       
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Category
                       
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Assigned
                       
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        onClick={() => handleSort("date")}
                        onMouseEnter={() => setHoveredColumn("date")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Due Date
                        {hoveredColumn === "date" || sortedColumn === "date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      style={{
                        border: "none",
                      }}
                    >
                      <td colSpan="9"></td>
                    </tr>
                    {filterTenantsBySearchAndPage()?.length > 0 ? (
                      filterTenantsBySearchAndPage()?.map((rental) => (
                        <>
                          <tr
                            key={rental?.workOrder_id}
                            onClick={() =>
                              navigateToDetails(rental?.workOrder_id)
                            }
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.work_subject}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.rental_adress}{" "}
                              {rental?.rental_unit
                                ? " - " + rental?.rental_unit
                                : null}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.work_category}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.staffmember_name || "-"}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.status || "-"}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {moment(rental?.createdAt).format(
                                `${dateFormat} HH:mm:ss`
                              )}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {moment(rental?.date).format(dateFormat)}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr
                        style={{
                          border: "2px solid rgba(50, 69, 103, 1)",
                        }}
                      >
                        <td
                          colSpan={7}
                          className="text-center bordertopintd tablebodyfont"
                        >
                          No data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </Table>
            {workData?.length > 0 ? (
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageItem}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(10);
                          setCurrentPage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(25);
                          setCurrentPage(1);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(50);
                          setCurrentPage(1);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(100);
                          setCurrentPage(1);
                        }}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : null}

            {/* </Card> */}
          </div>
        </Row>

        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default TenantWork;
