// Add NMI tokenization script with tokenization key
// export const addTokenizationScript = (tokenizationKey) => {
//   if (!tokenizationKey) {
//     return Promise.reject(new Error("Tokenization key is required"));
//   }

//   // Check if the script is already loaded
//   const existingScript = document.querySelector(
//     `script[src="https://hms.transactiongateway.com/token/Collect.js"]`
//   );

//   if (existingScript) {
//     return Promise.resolve();
//   }

//   return new Promise((resolve, reject) => {
//     const script = document.createElement("script");
//     script.src = "https://hms.transactiongateway.com/token/Collect.js";
//     script.dataset.tokenizationKey = tokenizationKey;
//     script.dataset.theme = "material";

//     script.onerror = (error) => {
//       console.error("Error loading Collect.js:", error);
//       return reject(new Error("Failed to load the tokenization script"));
//     };

//     script.onload = () => {
//       console.log("Collect.js script loaded.");
//       resolve();
//     };

//     try {
//       document.body.appendChild(script);
//     } catch (error) {
//       return;
//     }
//   });
// };
export const addTokenizationScript = (tokenizationKey) => {
  if (!tokenizationKey) {
    return Promise.reject(new Error("Tokenization key is required"));
  }

  const existingTokenizationScript = document.querySelector(
    `script[src="https://hms.transactiongateway.com/token/Collect.js"]`
  );

  if (existingTokenizationScript) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const tokenizationScript = document.createElement("script");
      tokenizationScript.src = "https://hms.transactiongateway.com/token/Collect.js";
      tokenizationScript.dataset.tokenizationKey = tokenizationKey;
      tokenizationScript.dataset.theme = "material";

      tokenizationScript.onerror = (error) => {
      console.error("Error loading Collect.js:", error);
          reject(new Error("Failed to load the tokenization script"));
    };

    tokenizationScript.onload = () => {
      console.log("Collect.js script loaded.");
          // Intercept and prevent duplicate Apple Pay SDK injection
          const originalAppendChild = document.head.appendChild;

          document.head.appendChild = function (element) {
              if (
                  element.tagName === "SCRIPT" &&
                  element.src.includes("apple-pay-sdk.js") &&
                  customElements.get('apple-spinner')
              ) {
                  console.warn("Prevented duplicate Apple Pay SDK script injection.");
                  return element; 
              }
              return originalAppendChild.call(document.head, element);
          };
      resolve();
    };

      document.body.appendChild(tokenizationScript);
  });
};

// Remove NMI tokenization script after logout
const removeTokenizationScript = () => {
  const existingScript = document.querySelector(
    `script[src="https://hms.transactiongateway.com/token/Collect.js"]`
  );

  if (existingScript) existingScript.remove();
};

// Logout function
export const handleLogout = (cookies, removeCookie) => {
  try {
    localStorage.clear();
    Object.keys(cookies).forEach((cookieName) => {
      removeCookie(cookieName, { path: "/" });
    });
    removeTokenizationScript();
    console.log("Logout successfully");
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

// Currency formatter ($0.00)
export const currencyFormatter = (amount) => {
  if (!amount) return 0.0;
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const strongPassword = (value) => {
  if (!value) return true;

  // Check if password length is more than 8
  if (value.length < 12) {
    return "Password must be more than 12.";
  }

  // Check for uppercase letters
  if (!/[A-Z]/.test(value)) {
    return "Password must contain at least one uppercase letter.";
  }

  // Check for lowercase letters
  if (!/[a-z]/.test(value)) {
    return "Password must contain at least one lowercase letter.";
  }

  // Check for numbers
  if (!/[0-9]/.test(value)) {
    return "Password must contain at least one number.";
  }

  // Check for special characters
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    return "Password must contain at least one special character.";
  }

  // Check for common words or simple patterns (example: "password123")
  const commonWords = ["password", "1234", "qwerty", "letmein", "welcome"];
  if (commonWords.some((word) => value.toLowerCase().includes(word))) {
    return "Password contains common or easily guessable words.";
  }

  // Check for sequential or repeating patterns
  if (/(\d)\1{2,}|([a-zA-Z])\2{2,}|123|abc/i.test(value)) {
    return "Password contains repeating or sequential patterns.";
  }

  // Avoid personal information (basic check against obvious words like "name", "birthday")
  if (/(name|birthday|address|phone|email)/i.test(value)) {
    return "Password contains personal information.";
  }

  return true;
};
