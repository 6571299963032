import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { useCookies } from "react-cookie";
import Header from "components/Headers/Header.js";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

function Purchaseplan() {
  const { admin } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [purchase, setPurchase] = useState(null);
  const [pastPurchase, setPastPurchase] = useState([]);
  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState({});

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  useEffect(() => {
    if (accessType?.admin_id) {
      axiosInstance
        .get(`/purchase/plan-purchase/${accessType?.admin_id}`)
        .then((response) => {
          setPurchase(response.data.data);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching purchase data:", error);
        });
    }
  }, [accessType]);

  useEffect(() => {
    if (accessType?.admin_id) {
      axiosInstance
        .get(`/purchase/past_plans/${accessType?.admin_id}`)
        .then((response) => {
          setPastPurchase(response.data.data.reverse());
        })
        .catch((error) => {
          console.error("Error fetching purchase data:", error);
        });
    }
  }, [accessType]);

  const updateSubscription = async (planId) => {
    navigate(`/${admin}/planUpgrade`);
  };

  const cancelSubscription = async () => {
    try {
      swal(
        "Warning!",
        "Are you sure you want to cancel plan subscription?",
        "warning",
        {
          buttons: ["No", "Yes"],
        }
      ).then(async (buttons) => {
        if (buttons === true) {
          const response = await axiosInstance.post(
            `/nmipayment/custom-delete-subscription`,
            {
              subscription_id: purchase?.subscription_id,
              admin_id: accessType?.superadmin_id,
              adminId: accessType?.admin_id,
            }
          );
          const resDelete = await axiosInstance.delete(
            `/purchase/purchase/${purchase.purchase_id}`,
            {
              data: {
                admin_id: accessType?.admin_id,
              },
            }
          );
          if (resDelete.status === 200 && response.status === 200) {
            toast.success("Plan Cancelled Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            setTimeout(() => {
              navigate("/plans");
            }, 2000);
          }
        }
      });
    } catch (error) {
      alert("Error");
      console.error("Error cancelling subscription:", error);
    }
  };

  const [allPlan, setAllPlan] = useState(false);

  return (
    <>
      <Header />
      <Container fluid="md" className="mt-3 ">
        <Row>
          <Col md={12}>
            <Card
              className=" mb-4"
              style={{
                border: "1px solid #324567",
                borderRadius: "7px",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <CardHeader className=" text-white " style={{ border: "0" }}>
                <div className="text-left">
                  <h3
                    className="mb-0 fontstylerentr textcolorblue fontfamilysty"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Current Subscription Details
                  </h3>
                </div>
                <div className="text-right mt-2">
                  <Button
                    className="fontstylerentmodal plandetailstyle"
                    // color="success"
                    onClick={(e) => {
                      e.preventDefault();
                      updateSubscription();
                    }}
                    style={{
                      backgroundColor: "#26C22C",
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Plan Upgrade
                  </Button>
                  <Button
                    className="ml-2 fontstylerentmodal"
                    onClick={(e) => {
                      e.preventDefault();
                      cancelSubscription();
                    }}
                    style={{
                      backgroundColor: "#FF0000",
                      color: "white",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Cancel Subscription
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {/* <Row className="mb-4">
                  <Col md={2} className="text-muted">
                    Plan Name:
                  </Col>
                  <Col md={3}>{purchase?.plan_detail?.plan_name}</Col>
                  <Col md={2} className="text-muted">
                    Purchase Date:
                  </Col>
                  <Col md={3}>{purchase?.purchase_date}</Col>
                </Row>
                <Row className="mb-3">
                  <Col md={2} className="text-muted">
                    Plan Price:
                  </Col>
                  <Col md={3}>{purchase?.plan_amount}</Col>
                  <Col md={2} className="text-muted">
                    Billing Period:
                  </Col>
                  <Col md={3}>{purchase?.plan_detail?.billing_interval}</Col>
                </Row> */}
                <Row className="mb-3">
                  <Col lg={5}>
                    <Table responsive bordered style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th
                            className="fontstylerentmodal titleecolor fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              border: "0.5px solid #324567",
                            }}
                          >
                            Plan name
                          </th>
                          <th
                            className="fontstylerentmodal textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {purchase?.plan_detail?.plan_name}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            className="fontstylerentmodal titleecolor fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {" "}
                            Plan Price
                          </td>
                          <td
                            className="fontstylerentmodal textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {purchase?.plan_amount}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col lg={5}>
                    <Table
                      className="cardpurchaseplanwidth"
                      responsive
                      bordered
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="fontstylerentmodal titleecolor fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {" "}
                            Purchase Date
                          </th>
                          <th
                            className="fontstylerentmodal textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {purchase?.purchase_date}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            className="fontstylerentmodal titleecolor fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              textTransform: "uppercase",
                              border: "0.5px solid #324567",
                            }}
                          >
                            Billing Period
                          </td>
                          <td
                            className="fontstylerentmodal textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              border: "0.5px solid #324567",
                            }}
                          >
                            {purchase?.plan_detail?.billing_interval}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Card
              className=" mb-4"
              style={{
                border: "1px solid #324567",
                borderRadius: "7px",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <CardHeader className=" text-white" style={{ border: "0" }}>
                <h3
                  className="mb-0 d-flex justify-content-start fontstylerentr textcolorblue fontfamilysty"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Past Subscriptions
                </h3>
              </CardHeader>
              <CardBody>
                <Table responsive bordered className="mb-5">
                  <thead>
                    <tr>
                      <th
                        className="fontstylerentmodal textcolorblue fontfamilysty"
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0.5px solid #324567",
                        }}
                      >
                        Plan name
                      </th>
                      <th
                        className="fontstylerentmodal textcolorblue fontfamilysty"
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0.5px solid #324567",
                        }}
                      >
                        Status
                      </th>
                      <th
                        className="fontstylerentmodal textcolorblue fontfamilysty"
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0.5px solid #324567",
                        }}
                      >
                        Start date
                      </th>
                      <th
                        className="fontstylerentmodal textcolorblue fontfamilysty"
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          border: "0.5px solid #324567",
                        }}
                      >
                        End date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastPurchase &&
                      pastPurchase
                        .slice(0, allPlan ? pastPurchase.length : 4)
                        .map((item, index) => (
                          <tr key={index}>
                            <td
                              className="fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: item.is_active
                                  ? "#26C22c"
                                  : "rgb(73 81 96)",
                                border: "0.5px solid #324567",
                              }}
                            >
                              {item.plan_name}
                            </td>
                            <td
                              className="fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: item.is_active
                                  ? "#26C22c"
                                  : "rgb(73 81 96)",
                                border: "0.5px solid #324567",
                              }}
                            >
                              {item.is_active ? "Active" : "Inactive"}
                            </td>
                            <td
                              className="fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: item.is_active
                                  ? "#26C22c"
                                  : "rgb(73 81 96)",
                                border: "0.5px solid #324567",
                              }}
                            >
                              {moment(item.purchase_date).format("MM-DD-YYYY")}
                            </td>
                            <td
                              className="fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: item.is_active
                                  ? "#26C22c"
                                  : "rgb(73 81 96)",
                                border: "0.5px solid #324567",
                              }}
                            >
                              {moment(item.expiration_date).format(
                                "MM-DD-YYYY"
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
                <div className="text-center mt-3 mb-2">
                  <Button
                    size="small"
                    className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                    onClick={() => setAllPlan(!allPlan)}
                  >
                    View {allPlan ? "Less" : "All"} Subscriptions
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default Purchaseplan;
