import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom/dist";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik/dist";
import * as Yup from "yup";

import { verifyToken } from "components/Functions/Functions";
import Dropdown from "components/ReusableComponents/Dropdown";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import axiosInstance from "axiosInstance";
import TemplateEditor from "./TemplateEditor";
import { ListItemText, MenuItem, Select } from "@mui/material";

const AddTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();

  const [cookies, setCoockie, removeCookie] = useCookies();

  const [loader, setLoader] = useState(true);
  const [btnLoader, setbtnLoader] = useState(false);
  const [accessType, setAccessType] = useState(undefined);

  const mails = [
    { title: "Reset password", detail: "" },
    {
      title: "Invitation",
      detail: "On tenant, vendor and staff-member creation",
    },
    {
      title: "Property assign",
      detail: "Assign staff-member to property",
    },
    { title: "Lease creation", detail: "" },
    { title: "Lease end Reminder", detail: "" },
    { title: "Work-order creation", detail: "" },
    { title: "Work-order updation", detail: "" },
    { title: "Payment reciept", detail: "" },
    { title: "Payment refund", detail: "" },
    { title: "LateFee reminder", detail: "" },
    { title: "Applicant application", detail: "Application detail" },
    { title: "Express Payment", detail: "" },
  ];

  const types = ["E-mail", "SMS"];

  const templateFormik = useFormik({
    initialValues: {
      name: "",
      subject: "",
      body: "",
      type: "E-mail",
      mail_type: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required!"),
      subject: Yup.string().required("Subject is required!"),
      body: Yup.string().required("Body is required!"),
      type: Yup.string().required("Type is required!"),
      mail_type: Yup.string().required("Mail type is required!"),
    }),
    onSubmit: async (values) => {
      setbtnLoader(true);
      try {
        if (!location?.state?.template_id) {
          var response = await axiosInstance.post("/templates", {
            ...values,
            admin_id: accessType.admin_id,
          });
        } else {
          response = await axiosInstance.put(
            `/templates/${location.state?.template_id}`,
            {
              ...values,
              admin_id: accessType.admin_id,
            }
          );
        }

        if (
          response.data.statusCode === 201 ||
          response.data.statusCode === 200
        ) {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 800,
          });

          setTimeout(() => {
            navigate(admin ? `/${admin}/templates` : `/staff/templates`);
          }, 1000);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        console.error("Error: ", error);
      }
      setbtnLoader(false);
    },
  });

  const fetchData = async () => {
    const data = await verifyToken(navigate, location, removeCookie, admin);
    if (data) {
      setAccessType(data);
    }
    if (location.state?.template_id) {
      try {
        const response = await axiosInstance.get(
          `/templates/get/${location.state.template_id}`
        );

        if (response.data.statusCode === 200) {
          templateFormik.setValues(response.data.template);
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container className="stylecontainer pt-5" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button
              className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                navigate(admin ? `/${admin}/templates` : `/staff/templates`);
              }}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {location?.state?.template_id ? "Update" : "Add New"} Template
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <div className="mt-5">
          {loader ? (
            <Loader />
          ) : (
            <Row className="">
              <Col className="order-xl-1" xl="12">
                <Card
                  className="mx-2 mb-3"
                  style={{
                    borderRadius: "10px",
                    border: "1px solid #324567",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <CardBody>
                    <Form onSubmit={templateFormik.handleSubmit}>
                      <Row>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="template-name"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Name
                            </label>
                            <Input
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="template-name"
                              placeholder="Name"
                              type="text"
                              name="name"
                              autoComplete="off"
                              onBlur={templateFormik.handleBlur}
                              onChange={templateFormik.handleChange}
                              value={templateFormik.values.name}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="template-subject"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Subject
                            </label>
                            <Input
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="template-subject"
                              placeholder="Subject"
                              type="text"
                              name="subject"
                              autoComplete="off"
                              onBlur={templateFormik.handleBlur}
                              onChange={templateFormik.handleChange}
                              value={templateFormik.values.subject}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        {templateFormik.values.type === "E-mail" ? (
                          <Col lg="4" xl="3" md="5" sm="5" xs="12">
                            <FormGroup className="d-flex flex-column">
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty mb-2"
                                htmlFor="email-type"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Event Type
                              </label>

                              <Select
                                labelId="user-select-label"
                                id="user-select"
                                value={templateFormik.values.mail_type}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    templateFormik.setFieldValue(
                                      "mail_type",
                                      e.target.value
                                    );
                                  }
                                }}
                                displayEmpty
                                renderValue={(selected) => {
                                  return selected || "Select Event";
                                }}
                              >
                                {mails.length > 0 &&
                                  mails?.map((item, index) => (
                                    <MenuItem key={index} value={item.title}>
                                      <ListItemText
                                        primary={item?.title || ""}
                                      />
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col lg="4" xl="3" md="5" sm="5" xs="12">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="sms-type"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                SMS Type
                              </label>
                              <Dropdown
                                emptyMsg={"Card not found!"}
                                disabled={false}
                                options={mails}
                                labelKey1="title"
                                placeholder={"Select card"}
                                valueKey="title"
                                subtitleKey="detail"
                                onSelect={(item) => {
                                  if (item) {
                                    templateFormik.setFieldValue(
                                      "sms_type",
                                      item
                                    );
                                  }
                                }}
                                required={templateFormik.errors.sms_type}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <Row className="mt-3">
                        <Col>
                          <TemplateEditor
                            data={templateFormik.values.body}
                            setData={(value) => {
                              templateFormik.setFieldValue("body", value);
                            }}
                            setLoader={setLoader}
                            isToolTip={true}
                            type={templateFormik.values.mail_type}
                          />
                        </Col>
                      </Row>

                      <div
                        style={{ display: "flex", gap: "10px" }}
                        className="mt-3"
                      >
                        {btnLoader ? (
                          <Loader />
                        ) : (
                          <BaseButton
                            id="payButton"
                            type="submit"
                            disabled={false}
                            label="Save"
                          />
                        )}
                        <BaseButton
                          onClick={() =>
                            navigate(
                              admin ? `/${admin}/templates` : `/staff/templates`
                            )
                          }
                          variant="outline"
                          label="Cancel"
                        />
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default AddTemplates;
