import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import { useCookies } from "react-cookie";
import "./property.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const Staffaddpropertytype = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id, admin } = useParams();
  const [prodropdownOpen, setproDropdownOpen] = useState(false);
  const [isMultiUnit, setIsMultiUnit] = useState(false);

  const [selectedProperty, setSelectedProperty] = useState("");

  const toggle = () => setproDropdownOpen((prevState) => !prevState);

  const navigate = useNavigate();
  const location  = useLocation();

  const handleChangecheck = (e) => {
    setIsMultiUnit(e.target.checked);
  };

  const handlePropertySelection = (value) => {
    setSelectedProperty(value);
    localStorage.setItem("property", value);
  };

  const propertyFormik = useFormik({
    initialValues: {
      property_type: "",
      propertysub_type: "",
      isMultiUnit: false,
    },
    validationSchema: yup.object({
      property_type: yup.string().required("Required"),
      propertysub_type: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [propertyType, setpropertyType] = useState(null);

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/propertytype/property/type/${id}`)
        .then((response) => {
          const propertyData = response.data.data[0];
          setpropertyType(propertyType);
          setIsMultiUnit(propertyData.is_multiunit);

          setSelectedProperty(propertyData.property_type || "Select");

          propertyFormik.setValues({
            property_type: propertyData?.property_type || "",
            propertysub_type: propertyData?.propertysub_type || "",
            isMultiUnit: propertyData?.is_multiunit,
          });
        })
        .catch((error) => {
          console.error("Error fetching property type data:", error);
        });
    }
  }, [id]);

  const [submitLoader, setSubmitLoader] = useState(false);

  async function handleSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: accessType?.admin_id,
        property_type: propertyFormik.values.property_type,
        propertysub_type: propertyFormik.values.propertysub_type,
        is_multiunit: isMultiUnit,
      };

      if (id === undefined) {
        const res = await axiosInstance.post(
          `/propertytype/property_type`,
          object
        );
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/propertytype/property_type/${id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  function handleResponse(response) {
    const successMessage = id
      ? "Property Type updated successfully"
      : "Property Type added successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        onClose: () => navigate(`/staff/staffpropertytype`),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  const handleCloseButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <AddPropertyTypeHeader /> */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {id ? "Edit Property Type" : "Add Property Type"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 mb-3 cardstyborderradius"
              style={{
                boxShadow: " 0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form onSubmit={propertyFormik.handleSubmit}>
                  <div className="">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                              // color: "rgb(73 81 96)",
                            }}
                          >
                            Property Type *
                          </label>
                          <br />
                          <Dropdown
                            className="fontstylerentmodal"
                            isOpen={prodropdownOpen}
                            toggle={toggle}
                          >
                            <DropdownToggle
                              caret
                              className=" fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                maxWidth: "200px",
                                minWidth: "200px",
                                backgroundColor: "transparent",
                                // color: "rgb(73 81 96)"
                              }}
                            >
                              {propertyFormik.values.property_type ||
                                "Property Type"}
                            </DropdownToggle>
                            <DropdownMenu className="fontstylerentmodal">
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  propertyFormik.handleChange({
                                    target: {
                                      name: "property_type",
                                      value: "Residential",
                                    },
                                  })
                                }
                              >
                                Residential
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  propertyFormik.handleChange({
                                    target: {
                                      name: "property_type",
                                      value: "Commercial",
                                    },
                                  })
                                }
                              >
                                Commercial
                              </DropdownItem>
                            </DropdownMenu>
                            {propertyFormik.touched.property_type &&
                            propertyFormik.errors.property_type ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red", marginBottom: "10px" }}
                              >
                                {propertyFormik.errors.property_type}
                              </div>
                            ) : null}
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>

                    <br />
                  </div>
                  {/* <hr className="my-4" /> */}
                  <div className="">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                              // color: "rgb(73 81 96)",
                            }}
                          >
                            Property Sub Type *
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "30%",
                              // color:"rgb(73 81 96)"
                            }}
                            className="form-control-alternative fontstylerentmodal propertysubtypeinput titleecolor"
                            id="input-protype"
                            placeholder="Townhome"
                            type="text"
                            name="propertysub_type"
                            onBlur={propertyFormik.handleBlur}
                            onChange={propertyFormik.handleChange}
                            value={propertyFormik.values.propertysub_type}
                          />
                          {propertyFormik.touched.property_type &&
                          propertyFormik.errors.property_type ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red", marginBottom: "10px" }}
                            >
                              {propertyFormik.errors.property_type}
                            </div>
                          ) : null}
                          <br></br>
                          <Checkbox
                            onChange={handleChangecheck}
                            checked={isMultiUnit}
                            style={{ color: "#152B51" }}
                          />

                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            Multi unit
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>
                  <Row className="my-row">
                    {submitLoader ? (
                      <Button
                        type="submit"
                        className="btn fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                        disabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          boxShadow: "0px 4px 4px 0px #00000040",
                          cursor: "pointer",
                        }}
                        disabled={!propertyFormik.isValid}
                      >
                        {id ? "Update Property Type" : "Add Property Type"}
                      </Button>
                    )}
                    <Button
                      // color="primary"
                      className="btn fontstylerentr fontfamilysty textcolorblue backgroundwhitesty"
                      onClick={handleCloseButtonClick}
                      size="small"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </Button>
                    {/* Conditional message */}
                    {!propertyFormik.isValid && (
                      <div
                        className="fontstylerentr"
                        style={{ color: "red", marginTop: "10px" }}
                      >
                        Please fill in all fields correctly.
                      </div>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Staffaddpropertytype;
