import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import axiosInstance from "axiosInstance";

const Forgetmail = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [logindata, setLoginData] = useState(null);

  let navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState("");
  const toggleCard = (e, value) => {
    if (selected === value) {
      setSelected("");
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    // Extract the token from the URL
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    // Decode the token to get the email
    const decodedEmail = decodeURIComponent(token);
    // Set the email state
    setEmail(decodedEmail);
  }, [location.search]);

  const [mail, setMail] = useState("");

  const handleSendMail = async () => {
    setIsLoading(true);
    try {
      const data = {
        tenant_email: mail,
        role: selected.role,
        admin_id: selected.admin_id,
      };
      const res = await axiosInstance.post(`/admin/resetpasswordmail`, data);
      if (res.status === 200) {
        toast.success("Mail Sent Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate(`/auth/login`);
        }, 2000);
      } else {
        throw new Error("Email sending failed");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending email:", error);
      setIsLoading(false);
    }
  };

  const handleClickSubmit = async () => {
    try {
      const response = await axios.post(`${baseUrl}/auth/check_role`, {
        email: mail,
      });
      if (response.data.statusCode === 200) {
        setLoginData(response.data.data);
      } else {
        toast.error("Invalid email or role", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }
  };

  useEffect(() => {
    if (logindata && logindata.length === 1) {
      const userRoleData = logindata[0];
      switch (userRoleData.role) {
        case "super_admin":
          setSelected({
            role: userRoleData.role,
            superadmin_id: userRoleData.superadmin_id,
          });
          break;
        case "admin":
          setSelected({
            role: userRoleData.role,
            admin_id: userRoleData.admin_id,
            company_name: userRoleData.company_name,
          });
          break;
        case "tenant":
          setSelected({
            role: userRoleData.role,
            tenant_id: userRoleData.tenant_id,
            admin_id: userRoleData.admin_id,
            company_name: userRoleData.company_name,
          });
          break;
        case "staffmember":
          setSelected({
            role: userRoleData.role,
            staffmember_id: userRoleData.staffmember_id,
            admin_id: userRoleData.admin_id,
            company_name: userRoleData.company_name,
          });
          break;
        case "vendor":
          setSelected({
            role: userRoleData.role,
            vendor_id: userRoleData.vendor_id,
            admin_id: userRoleData.admin_id,
            company_name: userRoleData.company_name,
          });
          break;
        default:
          console.warn("Role not recognized:", userRoleData.role);
      }

      handleSendMail();
    }
  }, [logindata]);

  return (
    <Col lg="6" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-4 py-lg-4">
          {/* <div className="forms"> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontSize: "18px", marginBottom: "20px" }}>
              Forget Your Password?
            </h3>
          </div>
          <div className="text-left mb-4">
            <p
              className="titleecolor"
              style={{ fontSize: "12px", fontFamily: "Poppins" }}
            >
              Enter your email address below, and we'll send you a link to reset
              your password.
            </p>
          </div>

          <Form
            role="form"
            onSubmit={(e) => {
              e.preventDefault();
              if (isSubmit) {
                if (logindata?.length === 1) {
                  handleSendMail();
                } else {
                  if (selected) {
                    handleSendMail();
                  }
                }
              } else {
                handleClickSubmit();
                setisSubmit(true);
              }
            }}
          >
            <FormGroup>
              <Label
                for="mail"
                className="titleecolor d-flex justify-content-start"
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Email Address
              </Label>
              <InputGroup
                className="input-group-alternative"
                style={{ border: "1px solid black" }}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  placeholder="Email Address"
                  className="form-control"
                  id="mail"
                  value={mail}
                  onChange={(e) => {
                    setMail(e.target.value);
                    setisSubmit(false);
                  }}
                  required
                />
              </InputGroup>
            </FormGroup>

            {isSubmit ? (
              <>
                {logindata &&
                logindata?.length > 1 &&
                logindata[0].role !== "super_admin" ? (
                  <Row>
                    {logindata?.map((company) => (
                      <Col lg="6" className="mt-2">
                        <div
                          className={`cardstyle ${
                            selected && selected === company ? "selected" : ""
                          }`}
                          onClick={(e) => toggleCard(e, company)}
                        >
                          <div className="d-flex justify-content-start">
                            <div
                              className={`radio-button ${
                                selected && selected === company
                                  ? "checked"
                                  : ""
                              }`}
                            >
                              {selected && selected === company && (
                                <span className="checkmark">✔</span>
                              )}
                            </div>
                            <div className="card-content">
                              <div
                                className="card-subtitlestyle"
                                style={{
                                  wordBreak: "break-word",
                                  fontSize: "14px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {company.company_name}
                              </div>
                              <div
                                className="card-titlestyle mt-1"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {company.role}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : null}
              </>
            ) : null}

            <div className="text-left mt-3">
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isLoading}
                style={{ backgroundColor: "#152B51", color: "#fff" }}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : isSubmit ? (
                  "Submit"
                ) : (
                  "Check User"
                )}
              </Button>
              <Button
                variant="contained"
                size="large"
                color="grey"
                onClick={() => navigate(`/auth/login`)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ToastContainer />
    </Col>
  );
};

export default Forgetmail;
