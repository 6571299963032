// import React from 'react'
import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { Grid, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { strongPassword } from "plugins/helpers";
const useStyles = makeStyles(() => ({
  button: {
    marginTop: "8px",
    marginRight: "8px",
  },
}));

const steps = ["About You", "Customize Trial", "Final"];

const TrialLogin = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cookies, setCookie] = useCookies(["token"]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    termsAndConditions: false,
  });

  const navigate = useNavigate();
  const handleNext = (id) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "termsAndConditions" ? checked : value,
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    const object = {
      first_name: loginformik.values.firstName,
      last_name: loginformik.values.lastName,
      email: loginformik.values.businessEmail,
      company_name: loginformik.values.componyName,
      phone_number: loginformik.values.phoneNumber,
      password: loginformik.values.password,
    };

    try {
      const response = await axios.post(`${baseUrl}/admin/register`, object);

      if (response.data.statusCode === 200) {
        toast.success("Logged in Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        const logindata = {
          email: response.data.data.email,
          password: loginformik.values.password,
          admin_id: response.data.data.admin_id,
          role: "admin",
          is_web: true,
        };
        const adminRes = await axios.post(`${baseUrl}/auth/login`, logindata);
        const res = adminRes.data;
        setTimeout(() => {
          setCookie("token", adminRes.data.token, { path: "/" });
          setCookie("ID", adminRes.data.id, { path: "/" });
          navigate(`/${res.company_name}/index`);
        }, 500);
      } else if (response.data.statusCode === 401) {
        toast.error("Email already exist in system", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (response.data.statusCode === 402) {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const step0ValidationSchema = yup.object({
    firstName: yup.string().required("Please enter first name"),
    lastName: yup.string().required("Please enter last name"),
    businessEmail: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .required("Please enter email address"),
  });
  const passwordValidation = yup.string()
  .required("Password is required.")
  .test("strong-password", "Invalid password", (value, context) => {
    const result = strongPassword(value);
    return result === true ? true : context.createError({ message: result });
  });

  const step1ValidationSchema = yup.object({
    componyName: yup.string().required("Please enter company name"),
    phoneNumber: yup
    .string()
    .required("Please enter phone number")
    .matches(
      /^\(\d{3}\) \d{3}-\d{4}$/,
      "Phone number must be in the format (xxx) xxx-xxxx"
    ),
    password: passwordValidation,

    confpassword: yup
      .string()
      .required("please confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const loginformik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessEmail: "",
      componyName: "",
      phoneNumber: "",
      password: "",
      confpassword: "",
      termsAndConditions: false,
    },
    validationSchema:  activeStep === 0 ? step0ValidationSchema : step1ValidationSchema,

    onSubmit: () => {
      if (activeStep === 0) {
        setActiveStep(1);
        loginformik.setTouched({});
      } else if (activeStep === 1) {
        handleSubmit();
      } else if (activeStep === 2) {
        setActiveStep(3);
      }
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const handlePhoneChange = (e) => {
    if (loginformik.values.phoneNumber?.length > e.target.value?.length) {
      loginformik.setFieldValue("phoneNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      loginformik.setFieldValue("phoneNumber", formattedValue);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-4 py-lg-4">
            <Box className="text-center text-muted mb-4">
              <small>Sign up for your free trial account</small>
            </Box>
            <Box>
              <Box>
                {activeStep === 0 && (
                  <Box>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            First Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-firname"
                            placeholder="First Name *"
                            type="text"
                            name="firstName"
                             autoComplete="off"
                            value={loginformik.values.firstName}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                loginformik.setFieldValue(
                                  "firstName",
                                  e.target.value
                                );
                              }
                            }}
                            onBlur={loginformik.handleBlur}
                          />
                          {loginformik.touched.firstName &&
                            loginformik.errors.firstName && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.firstName}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Last Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Last Name *"
                            type="text"
                            name="lastName"
                             autoComplete="off"
                            value={loginformik.values.lastName}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                loginformik.setFieldValue(
                                  "lastName",
                                  e.target.value
                                );
                              }
                            }}
                            onBlur={loginformik.handleBlur}
                          />
                          {loginformik.touched.lastName &&
                            loginformik.errors.lastName && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.lastName}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Business Email *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-businessEmail"
                            placeholder="Business Email *"
                            type="email"
                            name="businessEmail"
                             autoComplete="off"
                            value={loginformik.values.businessEmail}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                            onBlur={loginformik.handleBlur}
                          />
                          {loginformik.touched.businessEmail &&
                            loginformik.errors.businessEmail && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.businessEmail}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="text-center">
                          <Button
                            variant="contained"
                            sx={{
                              mt: 2,
                              mb: 2,
                              width: "100%",
                              backgroundColor: "#152B51",
                              color: "white",
                            }}
                            onClick={() => {
                              loginformik.handleSubmit(activeStep);
                            }}
                            className={classes.button}
                          >
                            Create Your Free Trial
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            First Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-firname"
                            placeholder="First Name"
                            type="text"
                             autoComplete="off"
                            name="firstName"
                            value={loginformik.values.firstName}
                            disabled
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Last Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                             autoComplete="off"
                            value={loginformik.values.lastName}
                            disabled
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Business Email *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-businessEmail"
                            placeholder="Business Email"
                            type="email"
                            name="businessEmail"
                             autoComplete="off"
                            value={loginformik.values.businessEmail}
                            disabled
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Company Name *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-companyName"
                            placeholder="Company Name"
                            type="text"
                            name="componyName"
                             autoComplete="off"
                            value={loginformik.values.componyName}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                            onBlur={loginformik.handleBlur}
                          />
                          {loginformik.touched.componyName &&
                            loginformik.errors.componyName && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.componyName}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Phone Number *
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-number"
                            placeholder="Phone Number"
                            type="text"
                            name="phoneNumber"
                            autoComplete="off"
                            value={loginformik.values.phoneNumber}
                            onChange={handlePhoneChange}
                            onBlur={loginformik.handleBlur}
                          />
                          {loginformik.touched.phoneNumber &&
                            loginformik.errors.phoneNumber && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.phoneNumber}
                              </Box>
                            )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Tooltip
                            title={
                              loginformik.touched.password &&
                              loginformik.errors.password ? (
                                <Box style={{ fontSize: "12px" }}>
                                  <ul
                                    style={{ paddingLeft: "20px", margin: 0 }}
                                  >
                                    <li>
                                      Password must be at least 8 characters
                                      long.
                                    </li>
                                    <li>
                                      At least one uppercase letter (A-Z).
                                    </li>
                                    <li>
                                      At least one lowercase letter (a-z).
                                    </li>
                                    <li>At least one number (0-9).</li>
                                    <li>
                                      At least one special character (e.g., @,
                                      #, $, etc.).
                                    </li>
                                    <li>
                                      No continuous alphabetical characters
                                      (e.g., abcd) or continuous numerical
                                      characters (e.g., 1234).
                                    </li>
                                    <li>
                                      Avoid strictly sequential patterns (e.g.,
                                      1234, abcd, 888, ddd or ###).
                                      <li>
                                        Don't use birthdays, names, addresses,
                                        or other personal information.
                                      </li>{" "}
                                    </li>
                                  </ul>
                                </Box>
                              ) : (
                                ""
                              )
                            }
                            placement="bottom"
                            arrow
                          >
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                              htmlFor="input-unitadd"
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                            >
                              Password *
                            </label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                id="standard-adornment-password"
                                autoComplete="new-password"
                                name="password"
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                value={loginformik.values.password}
                                onChange={loginformik.handleChange}
                                onBlur={loginformik.handleBlur}
                              />
                              <IconButton
                                type="button"
                                style={{ padding: "7px", paddingLeft: "10px",
                                  backgroundColor: "#fff",
                                  zIndex: 10, }}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {<VisibilityIcon />}
                              </IconButton>
                            </InputGroup>
                            {loginformik.touched.password &&
                              loginformik.errors.password && (
                                <Box
                                  className="error-message text-left requiredstylefont"
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "12px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                  }}
                                >
                                  {loginformik.errors.password}
                                </Box>
                              )}
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "left",
                            }}
                          >
                            Confirm password *
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              id="standard-adornment-password"
                              autoComplete="new-password"
                              name="confpassword"
                              placeholder="Confirm Password"
                              type={showConfPassword ? "text" : "password"}
                              value={loginformik.values.confpassword}
                              onChange={loginformik.handleChange}
                              onBlur={loginformik.handleBlur} // Add onBlur event handler
                            />

                            <IconButton
                              type="button"
                              style={{ padding: "7px", paddingLeft: "10px",
                                backgroundColor: "#fff",
                                zIndex: 10, }}
                              onClick={() =>
                                setShowConfPassword(!showConfPassword)
                              }
                            >
                              {<VisibilityIcon />}
                            </IconButton>
                          </InputGroup>
                          {loginformik.touched.confpassword &&
                            loginformik.errors.confpassword && (
                              <Box
                                className="error-message text-left requiredstylefont"
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                }}
                              >
                                {loginformik.errors.confpassword}
                              </Box>
                            )}
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Checkbox
                          id="checked"
                          name="termsAndConditions"
                          checked={loginformik.values.termsAndConditions}
                          onChange={(e) => {
                            const { checked } = e.target;
                            loginformik.setFieldValue(
                              "termsAndConditions",
                              checked
                            );
                          }}
                        />
                        <span
                          style={{ fontSize: "14px", fontFamily: "Poppins" }}
                        >
                          I have read and accept CloudRentalManager terms and
                          conditions
                        </span>
                        {loginformik.touched.termsAndConditions &&
                          loginformik.errors.termsAndConditions && (
                            <Box
                              className="error-message text-left requiredstylefont"
                              style={{
                                color: "red",
                                marginTop: "5px",
                                fontSize: "12px",
                                fontFamily: "Poppins",
                                fontWeight: "500",
                              }}
                            >
                              {loginformik.errors.termsAndConditions}
                            </Box>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="text-center">
                          <Button
                            variant="contained"
                            sx={{
                              mt: 2,
                              mb: 2,
                              width: "100%",
                              backgroundColor: "#152B51",
                              color: "white",
                            }}
                            onClick={() => {
                              loginformik.handleSubmit();
                            }}
                            className={classes.button}
                          >
                            Create Your Free Trial
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  
                  </Box>
                )}
              </Box>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                sx={{ textAlign: "center" }}
              >
                <DialogTitle>
                  <Box className="text-center">
                    <CheckCircleIcon sx={{ color: "green", fontSize: 50 }} />
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Box className="text-center">
                    <p className="text-center">
                      <strong>Your trial account is being ready!</strong>
                    </p>
                  </Box>
                  <Box
                    className="text-center"
                    style={{ display: "inline-block", width: "60%" }}
                  >
                    <p>
                      Feel free to access the trial account. Once you sign up,
                      we'll start you with a fresh account
                    </p>
                  </Box>
                  <Box className="text-center">
                    {loader ? (
                      <Button disabled variant="contained" color="primary">
                        Loading ...
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setActiveStep(2);
                          handleSubmit();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Get Started
                      </Button>
                    )}
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                size="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "70%",
                }}
              >
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <StepLabel />
                    </Box>
                    <Box>
                      <Typography fontSize={9}>{label}</Typography>
                    </Box>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </CardBody>
        </Card>
        <ToastContainer />
      </Col>
    </>
  );
};

export default TrialLogin;
