import Chart from "chart.js";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import { useCookies } from "react-cookie";
import { chartOptions, parseOptions } from "../../variables/charts";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import StaffHeader from "components/Headers/StaffHeader";
import Work from "../../assets/icons/Work Light.svg";
import ArrowRight from "../../assets/icons/ArrowRight.svg";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import Tenants from "../../assets/icons/AdminDashboard/tenant-icon.svg";
import Applicants from "../../assets/icons/AdminDashboard/applicant-icon.svg";
import Vendors from "../../assets/icons/AdminDashboard/vendor-icon.svg";
import WorkOrders from "../../assets/icons/AdminDashboard/workorder-icon.svg";
import { verifyToken } from "components/Functions/Functions";
import DailyCronPayments from "views/DailyCronPayments";

const StaffDashBoard = (props) => {
  const [circularData, setCircularData] = useState({
    datasets: [
      {
        data: [0, 0], 
        backgroundColor: ["#152B51", "#5A86D5"],
        weight: 20,
      },
    ],
  });

  let navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCoockie, removeCookie] = useCookies();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  let [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [propertycount, setpropertycount] = useState();
  const fetchPropertyCount = async () => {
    if (accessType?.staffmember_id && accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/staffmember/count/${accessType?.staffmember_id}/${accessType?.admin_id}`
        );
        setpropertycount(res.data);
      } catch (error) {
        console.error("Error: ", error.message);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchPropertyCount();
  }, [accessType]);

  const [newWorkOrders, setNewWorkOrders] = useState([]);
  const [overdueWorkOrders, setOverdueWorkOrders] = useState([]);
  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    rotation: 1 * Math.PI,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.labels[tooltipItem.index] || "";
          const value = data.datasets[0].data[tooltipItem.index];
          return `${label}: ${value}%`;
        },
      },
    },
  };

  // useEffect(() => {
  //   const fetchWorkOrders = async () => {
  //     if (accessType?.staffmember_id && accessType?.admin_id) {
  //       try {
  //         const [newResponse, overdueResponse] = await Promise.all([
  //           axiosInstance.get(
  //             `/staffmember/dashboard_workorder/${accessType?.staffmember_id}/${accessType?.admin_id}`
  //           ),
  //         ]);
  //         setNewWorkOrders(newResponse.data.data.new_workorder);
  //         setOverdueWorkOrders(newResponse.data.data.overdue_workorder);
  //       } catch (error) {
  //         console.error("Error fetching work orders:", error);
  //       } finally {
  //         setLoader(false);
  //       }
  //     }
  //   };
  //   fetchWorkOrders();
  // }, [accessType]);
  
  useEffect(() => {
    const fetchWorkOrders = async () => {
      if (accessType?.staffmember_id && accessType?.admin_id) {
        try {
          const response = await axiosInstance.get(
            `/staffmember/dashboard_workorder/${accessType?.staffmember_id}/${accessType?.admin_id}`
          );

          const newOrders = response.data.data.new_workorder || [];
          const overdueOrders = response.data.data.overdue_workorder || [];

          setNewWorkOrders(newOrders);
          setOverdueWorkOrders(overdueOrders);

          const total = newOrders.length + overdueOrders.length;
          const newWorkOrderPercentage = total > 0 ? (newOrders.length / total) * 100 : 0;
          const overdueWorkOrderPercentage = total > 0 ? (overdueOrders.length / total) * 100 : 0;

          setCircularData({
          
            datasets: [
              {
                data: [newWorkOrderPercentage.toFixed(2), overdueWorkOrderPercentage.toFixed(2)],
                backgroundColor: ["#152B51", "#5A86D5"],
                weight: 20,
              },
            ],
          });
        } catch (error) {
          console.error("Error fetching work orders:", error);
        }finally {
          setLoader(false);
        }
      }
    };

    fetchWorkOrders();
  }, [accessType]);

  const [userroleData, setUserroleData] = useState(null);
  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);
  return (
    <>
      <StaffHeader prop="My Dashboard" />
      <Container className="" fluid>
        {loader ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loader}
            />
          </div>
        ) : (
          <Row>
            <Col className="mt-3" xl="12">
              <Row
                xs="1"
                sm="2"
                md="2"
                lg="3"
                xl={"5"}
                className="px-3 pt-3 pb-1"
              >
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Properties",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                      backgroundColor: "rgba(21, 43, 81, 1)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      userroleData?.property_view &&
                        navigate("/staff/staffrentals");
                    }}
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5 flex-column"
                    >
                      <Row style={{ flex: 1.5 }}>
                        <Col>
                          <span
                            style={{
                              borderRadius: "50%",
                              fontSize: "16px",
                              padding: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={Work} height={25} width={25} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {propertycount?.property_staffMember
                              .toString()
                              .padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Properties{" "}
                            <img src={ArrowRight} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Manrope",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                      backgroundColor: "rgba(40, 60, 95, 1)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      userroleData?.tenant_view &&
                        navigate("/staff/stafftenanttable");
                    }}
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5"
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              fontSize: "16px",
                              padding: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={Tenants} height={25} width={25} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {propertycount?.tenant_staffMember
                              .toString()
                              .padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Tenants{" "}
                            <img src={ArrowRight} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Manrope",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                      backgroundColor: "rgba(50, 75, 119, 1)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      userroleData?.applicant_view &&
                        navigate("/staff/staffapplicanttable");
                    }}
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5"
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              fontSize: "16px",
                              padding: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={Applicants} height={25} width={25} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {propertycount?.applicant_staffMember
                              .toString()
                              .padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Applicants {""}
                            <img src={ArrowRight} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Manrope",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                      backgroundColor: "rgba(60, 89, 142, 1)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      userroleData?.vendor_view &&
                        navigate("/staff/staffvendor");
                    }}
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5"
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              fontSize: "16px",
                              padding: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={Vendors} height={25} width={25} />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {propertycount?.vendor_staffMember
                              .toString()
                              .padStart(2, "0")}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Vendors {""}
                            <img src={ArrowRight} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  className="py-3"
                  style={{
                    marginBottom: "20px",
                    fontFamily: "Manrope",
                    color: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "235px",
                      margin: "auto 5px",
                      borderRadius: "20px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                      backgroundColor: "rgba(90, 134, 213, 1)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      userroleData?.workorder_view &&
                        navigate("/staff/staffworktable");
                    }}
                  >
                    <CardBody
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="py-5"
                    >
                      <Row>
                        <Col>
                          <span
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              fontSize: "16px",
                              padding: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                            }}
                          >
                            <img src={WorkOrders} height={25} width={25} />
                          </span>
                        </Col>
                      </Row>
                      {/* 6002 workorder count fix */}
                      <Row>
                        <Col
                          lg={12}
                          className="d-flex justify-content-start pt-5"
                        >
                          <span
                            className="dashboardfontst"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {propertycount?.workorder_staffMember
                              ?.toString()
                              ?.padStart(2, "0") || "00"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col lg={12}>
                          <span
                            className="dashboardfonttitle"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Work Orders {""}
                            <img src={ArrowRight} height={20} width={20} />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <Row className="staffdashcards">
                    <Col
                      style={{
                        fontFamily: "Poppins",
                        color: "#fff",
                      }}
                    >
                      <Card
                        style={{
                          cursor: "pointer",
                          height: "253px",
                          fontWeight: "600",
                          margin: "20px",
                          borderRadius: "20px",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardHeader
                          className="d-flex justify-content-center barforsummaryrent"
                          style={{
                            backgroundColor: "#152B51",
                            borderRadius: "20px 20px 0 0",
                            fontSize: "20px",
                          }}
                        >
                          New Work Order
                        </CardHeader>
                        <CardBody
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                          className="py-5"
                        >
                          <Row>
                            <Col
                              lg={12}
                              className="d-flex justify-content-center "
                            >
                              <span
                                className="fonteightteen"
                                style={{
                                  height: "auto",
                                  fontWeight: "600",
                                  fontSize: "24px",
                                  color: "#5A86D5",
                                }}
                              >
                                Total:{" "}
                                {newWorkOrders?.length
                                  .toString()
                                  .padStart(2, "0")}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              lg={12}
                              className="d-flex justify-content-center mt-3"
                            >
                              <span
                                className="d-flex justify-content-center labelfontstyle"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                  backgroundColor: "#152B51",
                                  width: "100px",
                                  height: "30",
                                  borderRadius: "6px",
                                  padding: "6px",
                                }}
                                onClick={() => {
                                  userroleData?.workorder_view &&
                                    navigate(
                                      "/staff/staffworktable?status=New"
                                    );
                                }}
                              >
                                View All
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col
                      style={{
                        fontFamily: "Poppins",
                        color: "#fff",
                      }}
                    >
                      <Card
                        style={{
                          cursor: "pointer",
                          height: "253px",
                          margin: "20px",
                          borderRadius: "20px",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 4px 4px 0",
                          backgroundColor: "#fff",
                          display: "flex",
                          flexDirection: "column",
                          fontWeight: "600",
                        }}
                      >
                        <CardHeader
                          className="d-flex justify-content-center barforsummaryrent"
                          style={{
                            backgroundColor: "#5A86D5",
                            borderRadius: "20px 20px 0 0",
                            fontSize: "20px",
                          }}
                        >
                          Overdue Work Order
                        </CardHeader>
                        <CardBody
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                          className="py-5 "
                        >
                          <Row>
                            <Col
                              lg={12}
                              className="d-flex justify-content-center "
                            >
                              <span
                                className="fonteightteen"
                                style={{
                                  width: "auto",
                                  height: "auto",
                                  fontWeight: "600",
                                  fontSize: "24px",
                                  // padding: "20px",
                                  color: "#5A86D5",
                                  // boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                                }}
                              >
                                Total:{" "}
                                {overdueWorkOrders?.length
                                  .toString()
                                  .padStart(2, "0")}
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              lg={12}
                              className="d-flex justify-content-center mt-3"
                            >
                              <span
                                className="d-flex justify-content-center labelfontstyle"
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                  backgroundColor: "#5A86D5",
                                  width: "100px",
                                  height: "30",
                                  borderRadius: "6px",
                                  padding: "6px",
                                }}
                                onClick={() => {
                                  userroleData?.workorder_view &&
                                    navigate(
                                      "/staff/staffworktable?status=Over Due"
                                    );
                                }}
                              >
                                View All
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3" xl="3">
                  <div
                    className="labelfontstyle"
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      width: "300px",
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#525459",
                      fontFamily: "Manrope",
                    }}
                  >
                    {" "}
                    Analytic
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      position: "relative",
                      width: "300px",
                    }}
                  >
                    <Doughnut
                      data={circularData}
                      options={options}
                      width={250}
                      height={250}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "Secondary",
                        }}
                      >
                        Total Work Orders
                      </span>
                      <br />
                      <span
                        className="labelfontstyle"
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#030303",
                        }}
                      >
                        {propertycount?.workorder_staffMember
                          .toString()
                          .padStart(2, "0")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col
                  className="mx-5 mb-3 "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Col className="staffdashmlstyle">
                    <Row className="mb-5" style={{ marginTop: "60px" }}>
                      <i
                        className="fa-solid fa-square mx-2"
                        style={{ color: "#152B51", fontSize: "20px" }}
                      ></i>
                      <span
                        className="fontstylerentr"
                        style={{
                          color: "#1C1C1E",
                          fontSize: "16px",
                          fontFamily: "Poppins",
                        }}
                      >
                        New Work Orders
                      </span>
                    </Row>
                    <Row>
                      <i
                        className="fa-solid fa-square mx-2"
                        style={{ color: "#5A86D5", fontSize: "20px" }}
                      ></i>
                      <span
                        className="fontstylerentr"
                        style={{
                          color: "#1C1C1E",
                          fontSize: "16px",
                          fontFamily: "Poppins",
                        }}
                      >
                        Overdue Work Orders
                      </span>
                    </Row>
                  </Col>
                </Col>
              </Row>
              <Row>
                <DailyCronPayments adminId={accessType?.admin_id} />
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default StaffDashBoard;
