import React, { useEffect, useState } from "react";
import { CardContent, Typography } from "@mui/material";
import { Row, Col, Card, CardBody } from "reactstrap";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import deleicon from "../../assets/img/icons/common/delete.svg";
import axiosInstance from "axiosInstance";
import AddCardForm from "../../components/Payments/AddCardForm";
import { addCardDetails } from "plugins/ApiHandler";
import swal from "sweetalert";

function TenantCreditCardForm(props) {
  const { tenantId, closeModal, admin_id, tenantName } = props;
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [customervault, setCustomervault] = useState([]);
  const [cardDetalis, setCardDetails] = useState([]);
  const [isBilling, setIsBilling] = useState(false);

  // const getCreditCard = async () => {
  //   if (tenantId) {
  //     try {
  //       const response = await axiosInstance.get(
  //         `/creditcard/getCreditCards/${tenantId}`
  //       );
  //       setCustomervault(response.data);
  //       getMultipleCustomerVault(response.data.customer_vault_id);

  //       const hasCustomerVaultId = "customer_vault_id" in response.data;
  //       if (hasCustomerVaultId) {
  //         setIsBilling(true);
  //       } else {
  //         setIsBilling(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching credit card details:", error);
  //       setIsBilling(false);
  //     }
  //   }
  // };
  // const getMultipleCustomerVault = async (customerVaultIds) => {
  //   try {
  //     setPaymentLoader(true);
  //     if (customerVaultIds?.length === 0) {
  //       setCardDetails([]);
  //       return;
  //     }

  //     const response = await axiosInstance.post(
  //       `/nmipayment/get-billing-customer-vault`,
  //       {
  //         customer_vault_id: customerVaultIds,
  //         admin_id: admin_id,
  //       }
  //     );

  //     if (Object.keys(response.data.data).length === 0) {
  //       setCardDetails([]);
  //       setPaymentLoader(false);
  //       return;
  //     }
  //     // Check if customer.billing is an array
  //     const billingData = response.data?.data?.customer?.billing;
  //     if (billingData && Array.isArray(billingData)) {
  //       const extractedData = billingData.map((item) => ({
  //         billing_id: item["@attributes"].id,
  //         first_name: item.first_name,
  //         last_name: item.last_name,
  //         cc_number: item.cc_number,
  //         cc_exp: item.cc_exp,
  //         cc_type: item.cc_type,
  //         cc_bin: item.cc_bin,
  //         customer_vault_id: item.customer_vault_id,
  //       }));

  //       for (const card of extractedData) {
  //         const options = {
  //           method: "POST",
  //           url: `https://bin-ip-checker.p.rapidapi.com/?bin=${card.cc_bin}`,

  //           headers: {
  //             "content-type": "application/json",
  //             "X-RapidAPI-Key":
  //               "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
  //             "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
  //           },
  //         };

  //         try {
  //           const response = await axios.request(options);
  //           card.binType = response.data.BIN.type;
  //         } catch (error) {
  //           console.error(error, "error in bin check");
  //         }
  //       }

  //       setPaymentLoader(false);
  //       setCardDetails(extractedData);
  //     } else if (billingData) {
  //       // If there's only one record, create an array with a single item
  //       const extractedData = [
  //         {
  //           billing_id: billingData["@attributes"].id,
  //           first_name: billingData.first_name,
  //           last_name: billingData.last_name,
  //           cc_number: billingData.cc_number,
  //           cc_exp: billingData.cc_exp,
  //           cc_type: billingData.cc_type,
  //           cc_bin: billingData.cc_bin,
  //           customer_vault_id: billingData.customer_vault_id,
  //         },
  //       ];

  //       for (const card of extractedData) {
  //         const options = {
  //           method: "POST",
  //           url: `https://bin-ip-checker.p.rapidapi.com/?bin=${card.cc_bin}`,

  //           headers: {
  //             "content-type": "application/json",
  //             "X-RapidAPI-Key":
  //               "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
  //             "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
  //           },
  //         };

  //         try {
  //           const response = await axios.request(options);
  //           card.binType = response.data.BIN.type;
  //         } catch (error) {
  //           console.error(error, "error in bin check");
  //         }
  //       }

  //       setPaymentLoader(false);
  //       setCardDetails(extractedData);
  //     } else {

  //       console.error(
  //         "Invalid response structure - billingData is not an array or is undefined/null"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching multiple customer vault records:", error);
  //     throw error;
  //   }
  // };
  const getCreditCard = async () => {
    if (!tenantId) return;
  
    try {
      const response = await axiosInstance.get(`/creditcard/getCreditCards/${tenantId}`);
      setCustomervault(response.data);
  
      // Create a map of billing_id to card_type from card_detail array
      const cardTypeMap = {};
      if (Array.isArray(response.data.card_detail)) {
        response.data.card_detail.forEach(card => {
          if (card.billing_id) {
            cardTypeMap[card.billing_id] = card.card_type;
          }
        });
      }
  
      await getMultipleCustomerVault(response.data.customer_vault_id, cardTypeMap);
      setIsBilling("customer_vault_id" in response.data);
  
    } catch (error) {
      console.error("Error fetching credit card details:", error);
      setIsBilling(false);
    }
  };
  
  const getMultipleCustomerVault = async (customerVaultIds, cardTypeMap) => {
    try {
      setPaymentLoader(true);
  
      if (!customerVaultIds || customerVaultIds.length === 0) {
        setCardDetails([]);
        setPaymentLoader(false);
        return;
      }
  
      const response = await axiosInstance.post(
        `/nmipayment/get-billing-customer-vault`,
        {
          customer_vault_id: customerVaultIds,
          admin_id: admin_id,
        }
      );
  
      const billingData = response.data?.data?.customer?.billing;
  
      if (billingData && Array.isArray(billingData)) {
        const extractedData = billingData.map((item) => ({
          billing_id: item["@attributes"].id,
          first_name: item.first_name,
          last_name: item.last_name,
          cc_number: item.cc_number,
          cc_exp: item.cc_exp,
          cc_type: item.cc_type,
          cc_bin: item.cc_bin,
          customer_vault_id: item.customer_vault_id,
          card_type: cardTypeMap[item["@attributes"].id] || "Unknown"
        }));

        setCardDetails(extractedData);
      } else if (billingData) {
        const singleCard = {
          billing_id: billingData["@attributes"].id,
          first_name: billingData.first_name,
          last_name: billingData.last_name,
          cc_number: billingData.cc_number,
          cc_exp: billingData.cc_exp,
          cc_type: billingData.cc_type,
          cc_bin: billingData.cc_bin,
          customer_vault_id: billingData.customer_vault_id,
          card_type: cardTypeMap[billingData["@attributes"].id] || "Unknown"
        };

        setCardDetails([singleCard]);
      } else {
        console.error("Invalid response structure - billingData is not an array or is undefined/null");
        setCardDetails([]);
      }
    } catch (error) {
      console.error("Error fetching multiple customer vault records:", error);
    } finally {
      setPaymentLoader(false);
    }
  };
  
  useEffect(() => {
    getCreditCard();
  }, [tenantId, admin_id]);

  useEffect(() => {
    const fetchCustomerVault = async () => {
      const customerVaultIds =
        customervault?.card_detail?.map((card) => card.billing_id) || [];
  
      if (customerVaultIds.length > 0) {
        await getMultipleCustomerVault(customerVaultIds);
      }
    };
  
    fetchCustomerVault();
  }, [customervault]);

  const formatExpirationDate = (dateString) => {
    if (dateString.length !== 4) return dateString;
    const month = dateString.slice(0, 2);
    const year = dateString.slice(2, 4);
    return `${month}/${year}`;
  };

  
const handleDeleteCard = async (customerVaultId, billingId) => {
  try {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this card!",
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: {
          text: "Delete",
          value: true,
          visible: true,
          className: "btn-danger",
        },
        confirm: {
          text: "Cancel",
          value: false,
          visible: true,
          className: "btn-secondary",
        },
      },
      dangerMode: true,
    });

    if (willDelete) {
      let deleteRecordResponse, deleteNMIResponse;

      if (customervault.card_detail.length > 1) {
        [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
          axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`, {data:{tenant_id:tenantId}}),
          axiosInstance.post(`/nmipayment/delete-customer-billing`, {
            customer_vault_id: customerVaultId,
            billing_id: billingId,
            admin_id: admin_id,
            tenant_id: tenantName,
          }),
        ]);
      } else {
        [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
          axiosInstance.delete(
            `/creditcard/deleteCardVault/${customerVaultId}`, {data:{tenant_id:tenantId}}
          ),
          axiosInstance.post(`/nmipayment/delete-customer-vault`, {
            customer_vault_id: customerVaultId,
            admin_id: admin_id,
            tenant_id: tenantName,
          }),
        ]);
      }

      // Check the responses
      if (
        deleteRecordResponse.status === 200 &&
        deleteNMIResponse.status === 200
      ) {
        toast.success("Card deleted successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        getCreditCard();
      } else {
        toast.error("Card deletion failed", {
          position: "top-center",
          autoClose: 1000,
        });
        console.error(
          "Delete card failed:",
          deleteRecordResponse.statusText,
          deleteNMIResponse.statusText
        );
      }
    } else {
      // User canceled the action
      toast.success("Card deletion canceled", {
        position: "top-center",
        autoClose: 500,
      });
    }
  } catch (error) {
    console.error("Error deleting card:", error.message);
    toast.error("Error deleting card", {
      position: "top-center",
      autoClose: 1000,
    });
  }
};

  const addCardInfo = async (data) => {
    data.tenant_id = tenantId;
    data.admin_id = admin_id;
    try {
      await addCardDetails(data);
      props.closeModal();
      getCreditCard();
      toast.success("Card details added successfully",{
        position: "top-center",
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}>
      <Row>
        {/* Formik Section */}
        <Col xs="12" sm="6">
          <AddCardForm
            preFillData={props.preFillData}
            addCardInfo={addCardInfo}
            onHide={closeModal}
          />
        </Col>
        {/* Card Details Section */}
        <Col xs="12" sm="6">
          <Card
            className="mt-1 creditcardrent backgroundwhitesty "
            style={{
              maxWidth: "500px",
              borderRadius: "6px",
              boxShadow: " 0px 4px 4px 0px #00000040",
              border: "0.5px solid #324567",
              height: "530px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <CardContent>
              <Typography
                className="dropdownfontsyle textcolorblue fontfamilysty"
                sx={{
                  fontSize: 18,
                  fontWeight: "500",
                  textTransform: "capitalize",
                  marginRight: "10px",
                }}
              >
                Cards
              </Typography>
              {paymentLoader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={paymentLoader}
                  />
                </div>
              ) : cardDetalis && cardDetalis?.length > 0 ? (
                <>
                  {cardDetalis.map((item, index) => (
                    <div className="row d-flex align-items-center justify-content-around">
                      <Card
                        className="col-9"
                        style={{
                          boxShadow: "0px 4px 4px 0px #00000040 ",
                          borderRadius: "10px",
                          width: "75%",
                          height: "auto",
                          background: (() => {
                            if (
                              item.cc_type.toLowerCase() === "mastercard" ||
                              item.cc_type.toLowerCase() === "discover"
                            ) {
                              return "linear-gradient(103.24deg, #121E2E 0.63%, #3A6194 99.37%)";
                            } else if (
                              item.cc_type.toLowerCase() === "visa" ||
                              item.cc_type.toLowerCase() === "jcb"
                            ) {
                              return "linear-gradient(103.66deg, #000000 1.66%, #666666 98.66%)";
                            } else {
                              return "linear-gradient(103.23deg, #949BA5 1.73%, #393B3F 99.21%)";
                            }
                          })(),
                          marginTop: "15px",
                        }}
                      >
                        <CardBody className="creditcardbodysty">
                          <Row className="d-flex justify-content-space-between">
                            <Col className="d-flex justify-content-start">
                              <img
                                className="creditcardimagestyle"
                                src={`https://logo.clearbit.com/${item.cc_type
                                  .replace(/[-\s]/g, "")
                                  .toLowerCase()}.com`}
                                alt={`${item.cc_type} Logo`}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {item.card_type} CARD
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col>
                              <Typography
                                className="fontstylerentmodal"
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "white",

                                  textTransform: "capitalize",
                                  marginRight: "10px",
                                }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {item.cc_number.replace(/(.{4})/g, "$1 ")}
                              </Typography>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-space-between mt-3">
                            <Col
                              className="d-flex justify-content-start fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              Card Holder
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              Valid Thru
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-space-between">
                            <Col
                              className="d-flex justify-content-start fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {item.first_name + " " + item.last_name}
                            </Col>
                            <Col
                              className="d-flex justify-content-end fontstylerentmodal"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {formatExpirationDate(item.cc_exp)}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <img
                        className="creditdeleterent"
                        src={deleicon}
                        width={30}
                        height={30}
                        onClick={() =>
                          handleDeleteCard(
                            item.customer_vault_id,
                            item.billing_id
                          )
                        }
                        style={{
                          cursor: "pointer",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No cards added.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}

export default TenantCreditCardForm;
