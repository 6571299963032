import React, { useState, useEffect } from "react";
import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCookies } from "react-cookie";
import Header from "components/Headers/Header";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./vendor.css";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { verifyToken } from "components/Functions/Functions";

const StaffVendortable = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location  = useLocation();
  const { admin } = useParams();
  const [vendorData, setVendorData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [userroleData, setUserroleData] = useState(null);

  useEffect(() => {
    getVendorData();
  }, [pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (vendorData) {
    paginatedData = vendorData.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getVendorData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/vendor/vendors/${accessType?.admin_id}`
        );
        setLoader(false);
        if (response.data.statusCode === 200) {
          setVendorData(response.data.data);
          setTotalPages(Math.ceil(response.data.data.length / pageItem));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    getVendorData();
    getVendorsLimit();
  }, [accessType, pageItem]);

  const deleteVendor = async (id) => {
    // Show a confirmation dialog to the user
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this vendor!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
    //     try {
    //       const response = await axiosInstance.delete(
    //         `/vendor/delete_vendor/${id}`
    //       );

    //       if (response.data.statusCode === 200) {
    //         toast.success("Vendor Deleted Successfully!", {
    //           position: "top-center",
    //           autoClose: 1000,
    //         });
    //         getVendorsLimit();
    //         getVendorData();
    //       } else if (response.data.statusCode === 201) {
    //         toast.warning("Vendor already assigned to workorder!", {
    //           position: "top-center",
    //           autoClose: 1000,
    //         });
    //         getVendorData();
    //       } else {
    //         toast.error(response.data.message, {
    //           position: "top-center",
    //           autoClose: 1000,
    //         });
    //       }
    //     } catch (error) {
    //       console.error("Error deleting vendor:", error);
    //     }
    //   } else {
    //     toast.success("Vendor is safe", {
    //       position: "top-center",
    //     });
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this vendor!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            console.log("Input Value:", reason);
  
            const deleteButton = document.querySelector(".swal-button--confirm");
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;
  
        try {
          const response = await axiosInstance.delete(`/vendor/delete_vendor/${id}`, {
            data: {
              reason: deletionReason,
            },
          });
  
          if (response.data.statusCode === 200) {
            toast.success("Vendor Deleted Successfully!", {
              position: "top-center",
              autoClose: 1000,
            });
            getVendorsLimit(); 
            getVendorData(); 
          } else if (response.data.statusCode === 201) {
            toast.warning("Vendor already assigned to workorder!", {
              position: "top-center",
              autoClose: 1000,
            });
            getVendorData();
          } else {
            toast.error(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error deleting vendor:", error);
          toast.error("Error deleting vendor", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        toast.success("Vendor is safe", {
          position: "top-center",
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const editVendor = (id) => {
    navigate(`/staff/staffaddvendor/${id}`);
  };

  const filterTenantsBySearch = () => {
    let filteredData = vendorData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toString().toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        const phoneNumberString = tenant.vendor_phoneNumber?.toString();
        const isMatch =
          (tenant.vendor_name &&
            tenant.vendor_name.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (tenant.vendor_email &&
            tenant.vendor_email.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (phoneNumberString &&
            phoneNumberString.includes(lowerCaseSearchQuery));
        return isMatch;
      });
    }

    if (upArrow.length > 0) {
      upArrow.forEach((value) => {
        switch (value) {
          case "vendor_name":
            filteredData.sort((a, b) =>
              a.vendor_name?.localeCompare(b.vendor_name)
            );
            break;
          case "vendor_email":
            filteredData.sort((a, b) =>
              a.vendor_email?.localeCompare(b.vendor_email)
            );
            break;
          case "vendor_phoneNumber":
            filteredData.sort(
              (a, b) => a.vendor_phoneNumber - b.vendor_phoneNumber
            );
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    if (upArrow.length === 0) {
      upArrow.forEach((value) => {
        switch (value) {
          case "vendor_name":
            filteredData.sort((a, b) =>
              b.vendor_name?.localeCompare(a.vendor_name)
            );
            break;
          case "vendor_email":
            filteredData.sort((a, b) =>
              b.vendor_email?.localeCompare(a.vendor_email)
            );
            break;
          case "vendor_phoneNumber":
            filteredData.sort(
              (a, b) => b.vendor_phoneNumber - a.vendor_phoneNumber
            );
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };
  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
    // setOnClickUpArrow(!onClickUpArrow);
  };

  useEffect(() => {
    // setLoader(false);
    // filterRentalsBySearch();
    getVendorData();
  }, [upArrow, sortBy]);

  const [countRes, setCountRes] = useState("");

  const getVendorsLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/vendor/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  return (
    <>
      <Header />
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          {userroleData?.vendor_add && (
            <Col xs="12" lg="12" sm="12" className="text-right">
              {/* <Button
              color="primary"
              //  href="#rms"
              onClick={() => navigate("/" + admin + "/addvendor")}
              size="sm"
              style={{ background: "white", color: "blue" }}
            >
              Add Vendor
            </Button> */}
              <Button
                className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
                onClick={() => {
                  if (countRes.statusCode === 201) {
                    swal(
                      "Plan Limitation",
                      "The limit for adding vendor according to the plan has been reached.",
                      "warning"
                    );
                  } else {
                    navigate("/staff/staffaddvendor");
                  }
                }}
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Vendor
              </Button>
            </Col>
          )}

          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Vendor
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />

        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh"
                        fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle rentaladded fontfamilysty"
                        style={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.vendorCount}
                        </b>{" "}
                        {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.vendorCountLimit}
                        </b>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Name
                        {sortBy.includes("vendor_name") ? (
                          upArrow.includes("vendor_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("vendor_name")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("vendor_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("vendor_name")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Phone Number
                        {sortBy.includes("vendor_phoneNumber") ? (
                          upArrow.includes("vendor_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("vendor_phoneNumber")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("vendor_phoneNumber")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("vendor_phoneNumber")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Mail ID
                        {sortBy.includes("vendor_email") ? (
                          upArrow.includes("vendor_email") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("vendor_email")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("vendor_email")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("vendor_email")}
                          />
                        )}
                      </th>
                      {(userroleData?.vendor_edit ||
                        userroleData?.vendor_delete) && (
                        <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{ borderTopRightRadius: "15px" }}
                        >
                          ACTION
                        </th>
                      )}
                    </tr>
                  </thead>
                  {vendorData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="4">No Vendors Added</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((vendor) => (
                        <tr
                          key={vendor.vendor_id}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {vendor.vendor_name}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {vendor.vendor_phoneNumber}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {vendor.vendor_email}
                          </td>
                          {(userroleData?.vendor_edit ||
                            userroleData?.vendor_delete) && (
                            <td className="bordertopintd tablebodyfont">
                              <div style={{ display: "flex", gap: "5px" }}>
                                {userroleData?.vendor_delete && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      vendor.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteVendor(vendor.vendor_id)
                                    }
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {userroleData?.vendor_edit && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      vendor.admin_id === "is_trial"
                                        ? freetrialData()
                                        : editVendor(vendor.vendor_id)
                                    }
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffVendortable;
