import axios from "axios";
import { Cookies } from "react-cookie";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token;
};

const getId = () => {
  const cookies = new Cookies();
  const id = cookies.get("ID");
  return id;
};

axiosInstance.interceptors.request.use(async (config) => {
  const token = await getToken();
  const id = await getId();

  if (token && id) {
    config.headers["authorization"] = `CRM ${token}`;
    config.headers["id"] = `CRM ${id}`;
  }
  return config;
});

export default axiosInstance;
