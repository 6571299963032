import {
  Button,
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header from "components/Headers/Header";
import React, { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import moment from "moment";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import "./property.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const PropertyType = () => {
  const { admin } = useParams();
  let [propertyData, setPropertyData] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setSearch((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");
  const [permissions, setPermissions] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const getPropertyData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/propertytype/property_type/${accessType?.admin_id}`
        );
        setLoader(false);
        setPropertyData(response.data.data);
        // setTotalPages(Math.ceil(response.data.data.length / pageItem));
      } catch (error) {
        console.error("Error fetching property data:", error);
      }
    }
  };

  const deleteProperty = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            if (reason.trim() === "") {
              deleteButton.disabled = true;
            } else {
              deleteButton.disabled = false;
            }
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then((value) => {
      if (value) {
        axiosInstance
          .delete(`/propertytype/property_type/${id}`, {
            data: {
              reason: value,
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Property Type Deleted Successfully!", {
                position: "top-center",
                autoClose: 500,
              });
              getPropertyData();
            } else if (response.data.statusCode === 201) {
              toast.warning(
                "Property Type already assigned. Deletion not allowed.",
                {
                  position: "top-center",
                  autoClose: 500,
                }
              );
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 500,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting property:", error);
          });
      } else {
        toast.success("Property is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  useEffect(() => {
    getPropertyData();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (propertyData) {
    paginatedData = propertyData?.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editPropertyType = (id) => {
    navigate(
      admin
        ? `/${admin}/AddPropertyType/${id}`
        : `/staff/staffaddpropertytype/${id}`
    );
  };

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...propertyData];
    switch (column) {
      case "propertysub_type":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.propertysub_type?.localeCompare(b.propertysub_type)
            : b.propertysub_type?.localeCompare(a.propertysub_type)
        );
        break;
      case "property_type":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.property_type?.localeCompare(b.property_type)
            : b.property_type?.localeCompare(a.property_type)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      default:
        break;
    }
    setPropertyData(sortedData || []);
  };

  const filterPropertyBySearch = () => {
    let filteredData = propertyData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((property) => {
        const isPropertyTypeMatch = property?.property_type
          ?.toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isPropertySubTypeMatch = property?.propertysub_type
          ?.toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isCreatedAtMatch = property?.createdAt
          ?.toLowerCase()
          .includes(lowerCaseSearchQuery);
        const isUpdatedAtpeMatch = property?.updatedAt
          ?.toLowerCase()
          .includes(lowerCaseSearchQuery);
        return isPropertyTypeMatch || isPropertySubTypeMatch || isCreatedAtMatch || isUpdatedAtpeMatch;
      });
    }

    if (searchQuery2) {
      if (searchQuery2 === "All") {
        return filteredData;
      }
      const lowerCaseSearchQuery = searchQuery2.toLowerCase();
      filteredData = filteredData.filter((property) => {
        const isPropertyTypeMatch = property.property_type
          .toLowerCase()
          .includes(lowerCaseSearchQuery);
        return isPropertyTypeMatch;
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterPropertyBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

;

  useEffect(() => {
  
    getPropertyData();
  }, [upArrow, sortBy]);

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  return (
    <>
      <Header />
    
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          {(admin || permissions?.propertytype_add) && (
            <Col xs="12" lg="12" sm="12" className="text-right mt-3">
              <Button
                className="btnaddproptype background-colorsty bgtextwhite fontfamilysty"
              
                onClick={() =>
                  navigate(
                    admin
                      ? "/" + admin + "/AddPropertyType"
                      : "/staff/staffaddpropertytype"
                  )
                }
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Property Type
              </Button>
            </Col>
          )}

          <Col xs="12" lg="12" sm="12">
          
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Property Type
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
               
                <Row className="d-flex ml-0 mb-3">
                  <FormGroup
                    className="mr-sm-2  searchstyle"
                    style={{ paddingRight: "10px" }}
                  >
                    <Input
                      className="filterh titleecolor"
                      
                      type="text"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        minWidth: "200px",
                        fontWeight: "500",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                       
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mr-sm-2">
                    <Dropdown isOpen={search} toggle={toggle3}>
                      <DropdownToggle
                        className="selecttypee typeselectprop titleecolor"
                        caret
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                          maxWidth: "200px",
                          minWidth: "200px",
                          backgroundColor: "transparent",
                        }}
                      >
                        {searchQuery2 ? searchQuery2 : "Select Type"}
                      </DropdownToggle>
                      <DropdownMenu className="dropdownmeunprop">
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Residential");
                          }}
                        >
                          Residential
                        </DropdownItem>
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Commercial");
                          }}
                        >
                          Commercial
                        </DropdownItem>
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("All");
                          }}
                        >
                          All
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          cursor: "pointer",
                          width: "25%",
                        }}
                        onClick={() => handleSort("property_type")}
                        onMouseEnter={() => setHoveredColumn("property_type")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Main Type
                        {hoveredColumn === "property_type" ||
                        sortedColumn === "property_type" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer", width: "25%" }}
                        onClick={() => handleSort("propertysub_type")}
                        onMouseEnter={() =>
                          setHoveredColumn("propertysub_type")
                        }
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Sub Type
                        {hoveredColumn === "propertysub_type" ||
                        sortedColumn === "propertysub_type" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer", width: "20%" }}
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ width: "20%" }}
                      >
                        Updated At
                      </th>
                      {(admin ||
                        permissions?.propertytype_edit ||
                        permissions?.propertytype_delete) && (
                        <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{ width: "10%" }}
                        >
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  {propertyData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="5" style={{ fontSize: "15px" }}>
                          No Property Types Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((property) => (
                        <tr
                          key={property._id}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {property.property_type}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {property.propertysub_type}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(property?.createdAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(property?.updatedAt).format(dateFormat)}
                          </td>
                          {(admin ||
                            permissions?.propertytype_edit ||
                            permissions?.propertytype_delete) && (
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              <div style={{ display: "flex", gap: "5px" }}>
                                {(admin ||
                                  permissions?.propertytype_delete) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      property.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteProperty(property.property_id)
                                    }
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}

                                {(admin || permissions?.propertytype_edit) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      property.admin_id === "is_trial"
                                        ? freetrialData()
                                        : editPropertyType(property.property_id)
                                    }
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>

                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {Math.ceil(filterPropertyBySearch().length / pageItem)}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filterPropertyBySearch().length / pageItem)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default PropertyType;
