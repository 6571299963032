// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation, useParams } from "react-router-dom";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   FormGroup,
//   Form,
//   Input,
//   InputGroupAddon,
//   InputGroupText,
//   InputGroup,
//   Row,
//   Col,
// } from "reactstrap";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { IconButton } from "@mui/material";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import CircularProgress from "@material-ui/core/CircularProgress";
// import axiosInstance from "axiosInstance";
// import axios from "axios";

// const Changepassword = () => {
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   const {role} = useParams();
//   const [email, setEmail] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showPassword, setShowPassword] = React.useState(false);
//   const [showPassword1, setShowPassword1] = React.useState(false);
//   const [error, setError] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [tokenExpired, setTokenExpired] = useState(false);
//   let navigate = useNavigate();
//   const location = useLocation();
//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const token = searchParams.get("token");

//   // Make a request to check the token's expiration status
//     axiosInstance.get(`/admin/check_token_status/${token}`)
//     .then((response) => {
//       const data = response.data;
//       setIsLoading(false);

//       if (data.expired) {
//         setTokenExpired(true);
//       } else {
//         setEmail(token);
//       }
//     })
//     .catch((error) => {
//       console.error("Error checking token status:", error);
//       setIsLoading(false);
//     });
//   })

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   const handleChangePassword = async () => {
//     if (newPassword !== confirmPassword) {
//       setError("Passwords do not match");
//     } else if (!isStrongPassword(newPassword)) {
//       setError(
//         "Password must be strong. Include uppercase, lowercase, numbers, and special characters."
//       );
//     } else {
//       try {
//         setIsLoading(true);
//         const response = await axios.put(
//           `${baseUrl}/admin/reset_passwords/${email}`,
//           { password: newPassword, role: role },
//           {
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           }
//         );

//         if (response.status === 200) {
//           toast.success('Password Changed Successfully', {
//             position: 'top-center',
//             autoClose: 1000
//           });
//           setTimeout(() => {
//             navigate(response.data.url);
//           }, 1000);
//         } else {
//           setError(response.data.message);
//           toast.error('Failed To Change Password', {
//             position: 'top-center',
//             autoClose: 1000
//           });
//         }
//       } catch (error) {
//         setError("An error occurred while changing the password");
//       } finally {
//         setIsLoading(false); // Set loading state to false after API call completes
//       }
//     }
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault(); // Prevent default form submission behavior
//     handleChangePassword();
//   };

//   const isStrongPassword = (password) => {
//     const strongPasswordRegex =
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
//     return strongPasswordRegex.test(password);
//   };

  // return (
  //   <Col lg="5" md="7">
  //     <Card className="bg-secondary shadow border-0">
  //       <CardBody className="px-lg-5 py-lg-5">

  //         {tokenExpired ? (
  //           <div className="text-danger">
  //             The password reset link has expired. Please request a new one.
  //           </div>
  //         ) : (
  //           <div>
  //             <div className="text-center text-muted mb-4">
  //               <big>Change Password</big>
  //             </div>

  //             <Form role="form" onSubmit={handleSubmit}>
  //               {/* <FormGroup className="mb-3">
  //               <InputGroup className="input-group-alternative">
  //                 <InputGroupAddon addonType="prepend">
  //                   <InputGroupText>
  //                     <i className="ni ni-email-83" />
  //                   </InputGroupText>
  //                 </InputGroupAddon>
  //               <Input
  //                 type="email"
  //                 placeholder="Email"
  //                 className="form-control"
  //                 id="inputmail"
  //                 // value={Mail}
  //                 onChange={(e) => setEmail(e.target.value)}
  //               />
  //               </InputGroup>
  //             </FormGroup> */}
  //               <FormGroup>
  //                 <InputGroup className="input-group-alternative">
  //                   <InputGroupAddon addonType="prepend">
  //                     <InputGroupText>
  //                       <i className="ni ni-lock-circle-open" />
  //                     </InputGroupText>
  //                   </InputGroupAddon>
  //                   <Input
  //                     type={showPassword ? "text" : "password"}
  //                     name="password"
  //                     placeholder="New Password"
  //                     className="form-control"
  //                     id="inputPassword4"
  //                     value={newPassword}
  //                     onChange={(e) => setNewPassword(e.target.value)}
  //                   />
  //                   <IconButton
  //                     type="button"
  //                     style={{ padding: "7px" }}
  //                     onClick={() => setShowPassword(!showPassword)}
  //                   >
  //                     <VisibilityIcon />
  //                   </IconButton>
  //                 </InputGroup>
  //               </FormGroup>
  //               <FormGroup>
  //                 <InputGroup className="input-group-alternative">
  //                   <InputGroupAddon addonType="prepend">
  //                     <InputGroupText>
  //                       <i className="ni ni-lock-circle-open" />
  //                     </InputGroupText>
  //                   </InputGroupAddon>
  //                   <Input
  //                     type={showPassword1 ? "text" : "password"}
  //                     placeholder="Confirm Password"
  //                     className="form-control"
  //                     id="confirmPassword"
  //                     value={confirmPassword}
  //                     onChange={(e) => setConfirmPassword(e.target.value)}
  //                   />
  //                   <IconButton
  //                     type="button"
  //                     style={{ padding: "7px" }}
  //                     onClick={() => setShowPassword1(!showPassword1)}
  //                   >
  //                     <VisibilityIcon />
  //                   </IconButton>
  //                 </InputGroup>
  //               </FormGroup>
  //               {error && <div className="text-danger">{error}</div>}
  //               <br />
  //               <div className="text-center">
  //                 <Button
  //                   type="submit"
  //                   variant="contained"
  //                   size="large"
  //                   disabled={isLoading}
  //                   style={{backgroundColor:"#152B51",color:"#fff"}}
  //                 //onClick={handleChangePassword}
  //                 >
  //                   {isLoading ? <CircularProgress size={24} /> : "Change Password"}
  //                 </Button>
  //                 <Button
  //                   variant="contained"
  //                   size="large"
  //                   color="grey"
  //                   onClick={() => navigate(`/auth/login`)}
  //                 >
  //                   Cancel
  //                 </Button>
  //               </div>
  //             </Form>
  //           </div>
  //         )}
  //       </CardBody>
  //     </Card>
  //     <ToastContainer />
  //   </Col>
  // );
// };

// export default Changepassword;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Changepassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { role } = useParams();
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    axios
      .get(`${baseUrl}/admin/check_token_status/${token}`)
      .then((response) => {
        const data = response.data;
        if (data.expired) {
          setTokenExpired(true);
        } else {
          setEmail(token);
        }
      })
      .catch((error) => console.error("Error checking token status:", error));
  }, [baseUrl, location.search]);

  const passwordValidationSchema = yup.object({
    newPassword: yup
      .string()
      .required("No password provided")
      .min(12, "Password must be at least 12 characters long")
      .max(16, "Password cannot be longer than 16 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/,
        "Must contain an uppercase letter, lowercase letter, number, and special character"
      )
      .test("zxcvbn-strength", "Password is too weak or common", (value) => {
        const result = zxcvbn(value);
        return result.score >= 3;
      })
      .test(
        "no-sequential-or-repeating",
        "Avoid sequential or repeating patterns like '1234' or 'aaaa'",
        (value) => !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value)
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm your password"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const response = await axios.put(
          `${baseUrl}/admin/reset_passwords/${email}`,
          { password: formik.values.newPassword, role: role },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          toast.success("Password Changed Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate(response.data.url);
          }, 1000);
        } else {
          toast.error("Failed To Change Password", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        setErrors({
          newPassword: "An error occurred while changing the password",
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          {tokenExpired ? (
            <div className="text-danger">
              The password reset link has expired. Please request a new one.
            </div>
          ) : (
            <div>
              <div className="text-center text-muted mb-4">
                <big>Change Password</big>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                      className="form-control"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <IconButton
                      type="button"
                      style={{ padding: "7px" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </InputGroup>
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="text-danger">
                      {formik.errors.newPassword}
                    </div>
                  )}
                </FormGroup>

                <FormGroup className="mb-4">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type={showPassword1 ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="form-control"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <IconButton
                      type="button"
                      style={{ padding: "7px" }}
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </InputGroup>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="text-danger">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </FormGroup>

                <div className="text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={formik.isSubmitting}
                    style={{ backgroundColor: "#152B51", color: "#fff" }}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="grey"
                    onClick={() => navigate(`/auth/login`)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer />
    </Col>
  );
};

export default Changepassword;
