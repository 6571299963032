import { Spinner } from "reactstrap";

const Loader = ({ color }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner
        style={{ margin: "auto", color: color || "var(--bg-blue-color)" }}
      />
    </div>
  );
};

export default Loader;
