import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import { updateCardTransactionType } from "plugins/ApiHandler";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { GetCardTransactionType } from "plugins/ApiHandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CardTransactionTypeSettings = ({ rentalOwnerId, formData, setFormData, handleChange }) => {
  const [getCardTypesLoading, setGetCardTypesLoading] = useState(false);
  const [getCardTypeError, setGetCardTypesError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (rentalOwnerId) {
        try {
          setGetCardTypesLoading(true);
          setGetCardTypesError("");

          const response = await GetCardTransactionType(rentalOwnerId);
          const data = response.data;

          // Use setFormData to update state in parent
          setFormData((prevData) => ({
            ...prevData,
            creditCardAccepted: data.creditCardAccepted,
            debitCardAccepted: data.debitCardAccepted,
          }));
        } catch (error) {
          setGetCardTypesError(error);
        } finally {
          setGetCardTypesLoading(false);
        }
      }
    };

    fetchData();
  }, [rentalOwnerId, setFormData]); // Add setFormData to the dependency array

  return (
    <>
      {getCardTypesLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </div>
      ) : (
        <div style={{ padding: "15px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <h3 className="mb-0 labelfontstyle textcolorblue fontfamilysty" style={{ fontSize: "18px", fontWeight: "600" }}>
            Card Transaction Type Management
          </h3>
          <p className="textcolorblue fontfamilysty">Select the type of card you wish to accept</p>
          <FormGroup>
            <FormControlLabel
              className="textcolorblue fontfamilysty"
              control={
                <Checkbox
                  style={{ color: "#152B51" }}
                  checked={formData.creditCardAccepted}
                  onChange={handleChange}
                  name="creditCardAccepted"
                />
              }
              label="Credit Card"
            />
            <FormControlLabel
              className="textcolorblue fontfamilysty"
              control={
                <Checkbox
                  style={{ color: "#152B51" }}
                  checked={formData.debitCardAccepted}
                  onChange={handleChange}
                  name="debitCardAccepted"
                />
              }
              label="Debit Card"
            />
            {getCardTypeError && <p style={{ textAlign: "center", color: "red", margin: "10px 0px" }}>{getCardTypeError}</p>}
          </FormGroup>
        </div>
      )}
    </>
  );
};

export default CardTransactionTypeSettings;
