import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { verifyToken } from "components/Functions/Functions";
import Loader from "components/ReusableComponents/Loader";
import axiosInstance from "axiosInstance";
import deleicon from "../../assets/img/icons/common/delete.svg";
import swal from "sweetalert";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";

const Emails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(undefined);
  const [emails, setEmails] = useState([]);
  const [clickedObject, setClickedObject] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      if (data) {
        setAccessType(data);
      }
    };

    fetchData();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterOptions, setFilterOptions] = useState();

  const fetchEmailsData = async () => {
    if (accessType?.admin_id) {
      try {
        setLoader(true);
        const queryParams = new URLSearchParams({
          page: currentPage,
          limit: itemsPerPage,
          sortField,
          sortOrder,
          search: filterOptions || "",
        }).toString();

        const response = await axiosInstance.get(
          `/email-logs/${accessType.admin_id}?${queryParams}`
        );

        if (response.data.statusCode === 200) {
          setEmails(response.data.emails);
          setTotalPages(response.data.totalPages);
        } else if (response.data.statusCode === 204) {
          setEmails([]);
        }

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error: ", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchEmailsData();
  }, [
    currentPage,
    itemsPerPage,
    sortField,
    sortOrder,
    filterOptions,
    accessType,
  ]);

  const [openemailrecipients, setOpenEmailrecipients] = useState(false);
  const handleClose = () => {
    setOpenEmailrecipients(false);
  };

  const handleMoveOutClick = (item) => {
    setClickedObject(item);
    setOpenEmailrecipients(true);
  };

  const deleteEmails = async (email_id) => {
    if (email_id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this e-mail history!",
        icon: "warning",
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter reason for deletion",
            type: "text",
            id: "delete-reason",
            oninput: (e) => {
              const reason = e.target.value;
              console.log("Input Value:", reason);

              const deleteButton = document.querySelector(
                ".swal-button--confirm"
              );
              deleteButton.disabled = reason.trim() === "";
            },
          },
        },
        buttons: {
          cancel: "Cancel",
          confirm: {
            text: "Delete",
            closeModal: true,
            value: true,
            className: "swal-button--danger",
          },
        },
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const deletionReason = document.getElementById("delete-reason").value;
          console.log("Confirmed Deletion Reason:", deletionReason);

          try {
            const response = await axiosInstance.delete(
              `/email-logs/${email_id}`,
              {
                params: {
                  company_name: admin,
                },
                data: {
                  reason: deletionReason,
                },
              }
            );

            if (response.data.statusCode === 200) {
              toast.success(response.data.message, {
                position: "top-center",
                autoClose: 800,
              });
              fetchEmailsData();
            }
          } catch (error) {
            console.error("Error: ", error);
          }
        } else {
          toast.success("E-mail history is safe!", {
            position: "top-center",
            autoClose: 800,
          });
        }
      });

      setTimeout(() => {
        const deleteButton = document.querySelector(".swal-button--confirm");
        if (deleteButton) {
          deleteButton.disabled = true;
        }
      }, 0);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = () => {
    if (searchQuery) {
      return clickedObject?.to?.filter((item) =>
        item?.includes(searchQuery?.toLowerCase())
      );
    } else {
      return clickedObject?.to;
    }
  };
  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button
              className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                navigate(admin ? `/${admin}/send-email` : `/staff/send-email`);
              }}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Send Emails
            </Button>
          </Col>
        </Row>
        <Modal isOpen={openemailrecipients} toggle={handleClose}>
          <ModalHeader>
            <div
              className="labelfontstyle textcolorblue fontfamilysty d-flex"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                gap: "10px",
              }}
            >
              <i className="fa-solid fa-user-group" />
              Email Recipients
            </div>
          </ModalHeader>
          <ModalBody style={{ width: "100%", maxWidth: "500px" }}>
            <div>
              <div
                style={{
                  maxHeight: "400px",
                  overflow: "hidden",
                }}
              >
                <Input
                  className="fontstylerentr titleecolor"
                  type="text"
                  placeholder="Search recipients"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontWeight: "600",
                  }}
                />
                <div
                  className="labelfontstyle textcolorblue fontfamilysty"
                  style={{
                    padding: "15px",
                    border: "1px solid #ddd",
                    fontWeight: "500",
                  }}
                >
                  {filteredData()?.map((elem) => (
                    <div className="d-flex justify-content-between">
                      <div>{elem}</div>
                      {clickedObject.accepted.includes(elem) &&
                      clickedObject.opens.some(
                        (item) => item.opened_by === elem
                      ) ? (
                        <div>
                          {/* <i className="fa-solid fa-check-double" /> */}
                          <DoneAllIcon color="success" />
                        </div>
                      ) : clickedObject.accepted.includes(elem) ? (
                        <div>
                          <CheckIcon color="success" />
                          {/* <i className="fa-solid fa-check" /> */}
                        </div>
                      ) : (
                        <div>
                          <CloseIcon color="error" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <br />
              </div>
              <br />
              <div
                className="ml-2 labelfontstyle textcolorblue fontfamilysty"
                style={{ fontWeight: "500" }}
              >
                Total Recipients : {clickedObject?.to?.length}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Emails
              </h2>
            </CardHeader>
          </Col>
        </Row>

        <div className="mt-5">
          {loader ? (
            <Loader />
          ) : (
            <>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  borderTopLeftRadius: "20px",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  style={{
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <tr
                    style={{
                      border: "2px solid rgba(50, 69, 103, 1)",
                      borderTopLeftRadius: "20px",
                    }}
                  >
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        color: "#152B51",
                        width: "20%",
                        borderTopLeftRadius: "20px",
                      }}
                    >
                      Subject
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{ color: "#152B51", width: "20%" }}
                    >
                      Reply to
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{ color: "#152B51", width: "40%" }}
                    >
                      Body
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        borderTopRightRadius: "15px",
                        color: "#152B51",
                        width: "20%",
                      }}
                    >
                      Send date
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        borderTopRightRadius: "15px",
                        color: "#152B51",
                        width: "20%",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody style={{ overflow: "auto" }}>
                  <tr
                    style={{
                      border: "none",
                      height: "20px",
                    }}
                  >
                    <td colSpan="9" className="border-none"></td>
                  </tr>

                  {emails?.length > 0 ? (
                    emails.map((item, index) => {
                      const olElement = document.createElement("div");
                      olElement.innerHTML = item.body;
                      const extractedText = olElement.textContent
                        .trim()
                        .substring(0, 40);
                      return (
                        <tr
                          key={index}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont px-3">
                            {item.subject}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            {item.from}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            {extractedText.length > 39
                              ? `${extractedText.substring(0, 39)}...`
                              : extractedText}
                          </td>
                          <td
                            className="bordertopintd tablebodyfont px-3"
                            // style={{ color: "green" }}
                          >
                            {moment(item?.createdAt).format(
                              `${dateFormat} HH:mm:ss`
                            )}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            <div style={{ display: "flex", gap: "5px" }}>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteEmails(item.email_id)}
                              >
                                <img src={deleicon} width={20} height={20} />
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleMoveOutClick(item)}
                              >
                                <i className="fa-solid fa-user-group " />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr
                      style={{
                        border: "none",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <td
                        colSpan="9"
                        className="text-center"
                        style={{ border: 0 }}
                      >
                        Emails not available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {itemsPerPage} {/* Displaying selected items per page */}
                    </DropdownToggle>
                    <DropdownMenu>
                      {[10, 25, 50, 100].map((perPage) => (
                        <DropdownItem
                          key={perPage}
                          onClick={() => setItemsPerPage(perPage)} // Update itemsPerPage on click
                        >
                          {perPage}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Emails;
