import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Container } from "@mui/material";
import interceptor from "plugins/axios";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "components/ReusableComponents/Loader";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddInsuranceDialog } from "./AddInsuranceDialog";
import UpdateInsuranceDialog from "./UpdateInsuranceDialog";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--bg-blue-color)",
    color: "var(--text-white-color)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const RenterInsurance = () => {
  const { lease_id, admin } = useParams();
  const [open, setOpen] = useState(false);

  const [getPoliciesLoading, setGetPoliciesLoading] = useState(false);
  const [getPoliciesError, setGetPoliciesError] = useState(false);
  const [deletePlicyLoading, setDeletePolicyLoading] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [insuranceId, setInsuranceId] = useState("");

  const getRenterInsurances = async () => {
    try {
      setPolicies([]);
      setGetPoliciesLoading(true);
      setGetPoliciesError("");

      const response = await interceptor.get(
        `renter-insurance/policies/${lease_id}`
      );
      const data = response.data.data.map((el) => {
        return {
          ...el,
          effective_date: moment(el.effective_date).format("MM-DD-YYYY"),
          expiration_date: moment(el.expiration_date).format("MM-DD-YYYY"),
        };
      });

      setPolicies(data);
    } catch (error) {
      setGetPoliciesError(error);
    } finally {
      setGetPoliciesLoading(false);
    }
  };

  // Function to fetch list of tenant
  const fetchTenant = async () => {
    if (lease_id) {
      try {
        const res = await interceptor.get(`/leases/lease_tenant/${lease_id}`);
        if (res.data.statusCode === 200) {
          setTenantList(res.data.data.tenants);
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };

  useEffect(() => {
    getRenterInsurances();
    fetchTenant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClick = (id) => {
    setInsuranceId(id);
    setOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setDeletePolicyLoading(true);

      const response = await interceptor.delete(
        `renter-insurance/delete-policy/${insuranceId}`
      );
      console.log(response.data.data);
      setPolicies((prev) => {
        return prev.filter((el) => el.renters_insurance_id !== insuranceId);
      });
      setOpen(false);
    } catch (error) {
      toast.error(error);
    } finally {
      setDeletePolicyLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setOpen(false);
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <AddInsuranceDialog
          refetch={getRenterInsurances}
          tenantList={tenantList}
        />
      </Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Insurance Company</StyledTableCell>
              <StyledTableCell>Effective Date</StyledTableCell>
              <StyledTableCell>Expiration Date</StyledTableCell>
              <StyledTableCell>Tenants</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getPoliciesLoading ? (
              // Show Loader
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : policies.length > 0 ? (
              // Render Rows
              policies.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.insurance_company}</StyledTableCell>
                  <StyledTableCell>{row.effective_date}</StyledTableCell>
                  <StyledTableCell>{row.expiration_date}</StyledTableCell>
                  <StyledTableCell>
                    {row.tenant_details.map((tenant, i) => (
                      <span>{`${tenant.tenant_firstName} ${
                        tenant.tenant_lastName
                      } ${
                        i !== row.tenant_details.length - 1 ? ", " : ""
                      }`}</span>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap",
                        gap: "5px",
                      }}
                    >
                      <Link
                        to={`/${admin}/rent-roll-details/${row.renters_insurance_id}/renter-insurane-details`}
                      >
                        <IconButton aria-label="toggle visibility">
                          <VisibilityIcon />
                        </IconButton>
                      </Link>
                      <UpdateInsuranceDialog
                        id={row.renters_insurance_id}
                        tenantList={tenantList}
                        refetch={getRenterInsurances}
                      />
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() =>
                          handleDeleteClick(row.renters_insurance_id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : getPoliciesError ? (
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  {getPoliciesError}
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  No renters insurance policies found for this lease
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <WarningAmberIcon color="error" fontSize="" /> Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the insurance? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={deletePlicyLoading}
            onClick={handleCancelDelete}
            color="primary"
          >
            Cancel
          </Button>
          {deletePlicyLoading ? (
            <LoadingButton loading></LoadingButton>
          ) : (
            <Button onClick={handleConfirmDelete} color="error" autoFocus>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RenterInsurance;
