import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCookies } from "react-cookie";
import "./Radiobutton.css";
import { verifyToken } from "components/Functions/Functions";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [logindata, setLoginData] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await verifyToken(navigate, location, removeCookie);
    };
    fetchData();
  }, []);

  const [selected, setSelected] = useState("");
  const toggleCard = (e, value) => {
    if (selected === value) {
      setSelected("");
      return;
    }
    setSelected(value);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        email: values.email,
        password: values.password,
        user_id: selected.user_id,
        admin_id: selected.admin_id,
        role: selected.role,
        is_web: true,
      };

      const response = await axios.post(`${baseUrl}/auth/login`, payload);

      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.statusCode === 200) {
          toast.success(responseData.message, {
            position: "top-center",
            autoClose: 500,
          });

          setTimeout(() => {
            setCookie("token", responseData.token, { path: "/" });
            setCookie("ID", responseData.id, { path: "/" });

            switch (selected.role) {
              case "admin":
                navigate(`/${selected?.company_name}/dashboard`);
                break;
              case "super_admin":
                navigate(`/superadmin/dashboard`);
                break;
              case "tenant":
                navigate("/tenant/tenantdashboard");
                break;
              case "vendor":
                navigate("/vendor/vendordashboard");
                break;
              case "staffmember":
                navigate("/staff/staffdashboard");
                break;
              default:
                toast.error("Unknown role, unable to navigate", {
                  position: "top-center",
                });
                break;
            }
          }, 1000);
        } else if (responseData.statusCode === 201) {
          toast.error("User doesn't exist!", {
            position: "top-center",
            autoclose: 2000,
          });
        } else if (responseData.statusCode === 202) {
          toast.error("Invalid Email or Password. Please try again.", {
            position: "top-center",
            autoclose: 2000,
          });
        } else if (responseData.statusCode === 203) {
          toast.warning("Inactive user, Please contact your company", {
            position: "top-center",
            autoclose: 2000,
          });
        } else if (responseData.statusCode === 204) {
          toast.error("Inactive user. Please contact support.", {
            position: "top-center",
            autoclose: 2000,
          });
        } else {
          toast.error("Unknown response status. Please contact support.", {
            position: "top-center",
            autoclose: 2000,
          });
        }
      } else {
        toast.error("Login failed. Please check your credentials.", {
          autoClose: 2000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-center",
        autoclose: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickSubmit = async () => {
    try {
      const response = await axios.post(`${baseUrl}/auth/check_role`, {
        email: loginFormik.values.email,
      });

      if (response.data.statusCode === 200) {
        setLoginData(response.data.data);
        loginFormik.setFieldValue("password", "")
        setSelected("")
        setisSubmit(true);
      } else if (response.data.statusCode === 201) {
        toast.error("Email not found", {
          autoClose: 1000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }
  };

  let loginFormik = useFormik({
    initialValues: {
      email: localStorage.getItem("rememberedEmail") || "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Email is required"),
      password: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (logindata && logindata?.length === 1) {
      if (logindata[0].role === "super_admin") {
        setSelected({
          role: logindata[0].role,
          user_id: logindata[0].user_id,
          superadmin_id: logindata[0].superadmin_id,
        });
      } else if (logindata[0].role === "admin") {
        setSelected({
          role: logindata[0].role,
          user_id: logindata[0].user_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "tenant") {
        setSelected({
          role: logindata[0].role,
          user_id: logindata[0].user_id,
          // tenant_id: logindata[0].tenant_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "staffmember") {
        setSelected({
          role: logindata[0].role,
          user_id: logindata[0].user_id,
          // staffmember_id: logindata[0].staffmember_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      } else if (logindata[0].role === "vendor") {
        setSelected({
          role: logindata[0].role,
          user_id: logindata[0].user_id,
          // vendor_id: logindata[0].vendor_id,
          admin_id: logindata[0].admin_id,
          company_name: logindata[0].company_name,
        });
      }
    }
  }, [logindata]);

  const handleInputChange = (e) => {
    loginFormik.handleChange(e);
    setisSubmit(false);
  };

  return (
    <>
      <Col lg="6" md="7" sm="8">
        <Card
          className="bg-secondary shadow border-0"
          onSubmit={(e) =>
            loginFormik.handleSubmit(e, loginFormik.values.rememberMe)
          }
        >
          <CardBody className="px-lg-5 py-lg-5">
            <Box className="text-center  mb-4">
              <h4 className="titleecolor" style={{ fontFamily: "Poppins" }}>
                Sign in with your credentials
              </h4>
            </Box>
            <Form onSubmit={loginFormik.handleSubmit}>
              <FormGroup className="mb-3 d-flex flex-column">
                <label
                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                  htmlFor="input-unitadd"
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  Email *
                </label>
                <Box style={{ position: "relative", width: "100%" }}>
                  <EmailIcon
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "12px",
                      transform: "translateY(-50%)",
                      color: "#aaa",
                      pointerEvents: "none",
                    }}
                    aria-label="email icon"
                  />
                  <Input
                    style={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      borderRadius: "6px",
                      width: "100%",
                      paddingLeft: "40px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="off"
                    onChange={handleInputChange}
                    onBlur={loginFormik.handleBlur}
                    value={loginFormik.values.email}
                  />
                </Box>

                {loginFormik.touched.email && loginFormik.errors.email ? (
                  <Typography
                    variant="caption"
                    style={{ color: "red", textalign: "left" }}
                  >
                    {loginFormik.errors.email}
                  </Typography>
                ) : null}
              </FormGroup>

              {isSubmit ? (
                <>
                  <FormGroup className="mb-3 d-flex flex-column">
                    <label
                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                      htmlFor="input-unitadd"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      Password *
                    </label>
                    <InputGroup
                      className="input-group-alternative"
                      style={{
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderRadius: "6px",
                        width: "100%",
                        // alignItems: "center"
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="fa-solid fa-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="standard-adornment-password"
                        autoComplete="new-password"
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        onBlur={loginFormik.handleBlur}
                        onChange={loginFormik.handleChange}
                        value={loginFormik.values.password}
                      />

                      <InputGroupAddon
                        type="button"
                        style={{ padding: "7px", paddingLeft: "10px",
                          backgroundColor: "#fff",
                          zIndex: 10, }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputGroupAddon>
                    </InputGroup>
                    {loginFormik.touched.password &&
                    loginFormik.errors.password ? (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {loginFormik.errors.password}
                      </Typography>
                    ) : null}
                  </FormGroup>

                  {logindata && logindata?.length > 1 ? (
                    <Row>
                      {logindata?.map((company) => (
                        <Col lg="6" className="mt-2">
                          <Box
                            className={`cardstyle ${
                              selected && selected === company ? "selected" : ""
                            }`}
                            onClick={(e) => toggleCard(e, company)}
                          >
                            <Box className="d-flex justify-content-start">
                              <Box
                                className={`radio-button ${
                                  selected && selected === company
                                    ? "checked"
                                    : ""
                                }`}
                              >
                                {selected && selected === company && (
                                  <span className="checkmark">✔</span>
                                )}
                              </Box>
                              <Box className="card-content">
                                <Box
                                  className="card-subtitlestyle"
                                  style={{
                                    wordBreak: "break-word",
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                 {company.company_name.length > 15
                                    ? company.company_name.substring(0, 15) + "..."
                                    : company.company_name}
                                </Box>
                                <Box
                                  className="card-titlestyle mt-1"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    fontFamily: "Poppins",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {company.userName.length > 15
                                    ? company.userName.substring(0, 15) + "..."
                                    : company.userName}
                                </Box>
                                <Box
                                  className="card-titlestyle mt-1"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {company.role}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                  <Box className="text-center mt-3">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={isLoading || !selected}
                      style={{ color: "#fff", backgroundColor: "#152B51" }}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Login"}
                    </Button>
                  </Box>
                </>
              ) : (
                <Box className="text-center mb-3">
                  <Button
                    variant="contained"
                    size="large"
                    disabled={
                      isLoading ||
                      !!loginFormik.errors.email ||
                      !loginFormik.values.email ||
                      !loginFormik.touched.email
                    }
                    style={{ color: "#fff", backgroundColor: "#152B51" }}
                    onClick={() => {
                      handleClickSubmit();
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Box>
              )}
              <Box>
                <Row className="mt-3">
                  <Col xs="6" className="d-flex justify-content-start">
                    <span
                      className="mt-2"
                      onClick={() => navigate(`/auth/forgetpassword`)}
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                        color: "#152B51",
                        fontWeight: "600",
                      }}
                    >
                      Forgot password?
                    </span>
                  </Col>
                </Row>
              </Box>
              <Box className="d-flex justify-content-start mt-2">
                <Row>
                  <Col>
                    <span
                      className=""
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Don't have an account?
                    </span>
                    <a
                      style={{
                        color: "#152B51",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/trial/trial-login`)}
                    >
                      {" "}
                      Sign up
                    </a>
                  </Col>
                </Row>
                <br />
              </Box>
              <br />
            </Form>
          </CardBody>
        </Card>
      </Col>
      <ToastContainer />
    </>
  );
};

export default Login;
