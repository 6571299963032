import React, { useEffect, useState } from "react";
const { Modal, ModalBody } = require("reactstrap");

const FileModal = ({ open, setOpen, file }) => {
  const cdnUrl = process.env.REACT_APP_IMAGE_GET_URL;

  const fileURL =
    file instanceof File ? URL.createObjectURL(file) : `${cdnUrl}/${file}`;

  const fileType =
    file?.type ||
    (typeof file === "string" ? `application/${file?.split(".").pop()}` : "");

  const [isPDF, setIsPDF] = useState(false);

  useEffect(() => {

    if (fileType === "application/pdf" || fileURL?.match(/\.pdf$/i)) {
      setIsPDF(true);
    } else {
      setIsPDF(false);
    }

    return () => {
      if (file instanceof File) {
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [file, fileURL, fileType]);


  const renderContent = () => {
    if (!fileURL) return <p>No file to display.</p>;
  
    if (fileType?.includes("image/") || fileURL?.match(/\.(jpg|jpeg|png)$/i)) {
      return <img src={fileURL} width={"100%"} alt="Image" />;
    } else if (isPDF) {
      return (
        <object
          data={fileURL}
          type="application/pdf"
          style={{ width: "100%", height: "80vh" }}
        >
          <p>
            Your browser does not support PDFs.{" "}
            <a href={fileURL}>Download the PDF</a>.
          </p>
        </object>
      );
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileURL?.match(/\.(doc|docx)$/i)
    ) {
      // Use Microsoft Office Online Viewer to open .doc/.docx in a new tab
      const officeViewerURL = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        fileURL
      )}`;
      window.open(officeViewerURL, "_blank", "noopener,noreferrer");
      setOpen(false); // Close the modal
      return null; // Prevent rendering anything in the modal
    } else {
      return (
        <div>
          <p>Unable to display this file type.</p>
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            Open file
          </a>
        </div>
      );
    }
  };  

  return (
    <Modal
      isOpen={open}
      toggle={() => setOpen(!open)}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ModalBody className="p-0 m-0">{renderContent()}</ModalBody>
    </Modal>
  );
};

export default FileModal;
