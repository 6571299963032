import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/global.scss";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Tenant from "layouts/Tenant";
import Agent from "layouts/Agent";
import Staff from "layouts/Staff";
import Vendor from "layouts/Vendor";
import Trial from "layouts/Trial";
import Plan from "layouts/Plan";

// ==========================  Super Admin ===================================================
import SuperAdmin from "../src/components/superadmin/layouts/SuperAdmin";
import NotFound from "views/source/404NotFound";
import AuthCheckAdmin from "views/source/AuthCheckAdmin";
import ApplicantForm from "views/source/ApplicantForm";
import Plans from "views/source/Plans";
import Planpurches from "views/source/Planpurches";
import ExpressPayment from "views/source/ExpressPayment";

import { store } from "./Stores/store";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

const GlobalDebug = (function () {
  /**
   * Toggles debugging output for the `console` object.
   *
   * @param {boolean} debugOn - If `true`, enables console logging. If `false`, disables console logging.
   * @param {boolean} suppressAll - If `true`, disables all console methods including `info`, `warn`, and `error`. Defaults to `false`.
   */
  return function (debugOn, suppressAll = false) {
    const noop = () => {}; // No-op function for disabling console methods.

    if (!debugOn) {
      // Disable general logging
      console.log = noop;

      if (suppressAll) {
        // Disable all other console methods if suppressAll is true
        console.info = noop;
        console.warn = noop;
        console.error = noop;
        console.debug = noop;
      }
    }
  };
})();

// Enable or disable debug based on environment
if (process.env.NODE_ENV === "production") {
  GlobalDebug(false, true); // Suppress logs in production
} else {
  GlobalDebug(true); // Enable logs in development
}

root.render(
  <Provider store={store}>
    <Suspense fallback={<p>loading..</p>}>
      <BrowserRouter>
        <div style={{ backgroundColor: "#fff" }}>
          <ToastContainer
            closeOnClick={true} // Allow toasts to close on click
            pauseOnFocusLoss={false} // Prevent pausing when the page loses focus
            draggable={false} // Optional: prevent dragging of toasts
            hideProgressBar={false} // Optional: display a progress bar
          />
          <Routes>
            <Route path="/:admin/*" element={<AdminLayout />} />
            <Route
              path="/:admin/applicant-form/:id"
              element={<ApplicantForm />}
            />
            <Route
              path="/staff/applicant-form/:id"
              element={<ApplicantForm />}
            />
            <Route
              path="/"
              element={<Navigate to="/authentication" replace />}
            />
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/tenant/*" element={<Tenant />} />
            <Route path="/agent/*" element={<Agent />} />
            <Route path="/staff/*" element={<Staff />} />
            <Route path="/vendor/*" element={<Vendor />} />
            <Route path="/trial/*" element={<Trial />} />
            <Route path="/plan/*" element={<Plan />} />
            <Route path="/superadmin/*" element={<SuperAdmin />} />
            <Route path="/:admin/404" element={<NotFound />} />
            <Route path="/authentication" element={<AuthCheckAdmin />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/payment/:token" element={<ExpressPayment />} />
            <Route path="/plan/Planpurchases" element={<Planpurches />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  </Provider>
);

console.error = () => {};
console.warn = () => {};
