import React, { useEffect, useState } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getCardType,
  getCardDetails,
  getRentalOwnerCardTypeSettings,
  getCardLogo,
  getCustomerVaultId,
  addRecurringCards,
  getRecurringCards,
} from "plugins/ApiHandler";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, ListItemText, MenuItem, Select } from "@mui/material";
import axiosInstance from "axiosInstance";

const RecurringTenants = ({
  isOpen,
  toggle,
  tenantsData,
  admin_id,
  fetchTenantData,
  amount,
  openmodal,
  setSelectedTenantToAddCard,
}) => {
  const [loader, setLoader] = useState(true);
  const [tenantData, setTenantData] = useState([]);
  const [selectedReccuringCards, setSelectedReccuringCards] = useState([]);

  const handleSetCardDetails = async (item, tenant_id, leaseId) => {
    try {
      const cardType = await getCardType(item.cc_bin, item.cc_type);
      const response = await getRentalOwnerCardTypeSettings(tenant_id, leaseId);
      const cardLogo = await getCardLogo(item.cc_type);

      const { creditCardAccepted, debitCardAccepted } = response.data;

      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      ) {
        console.warn("Card type not accepted");
        return;
      }

      return {
        billing_id: item["@attributes"].id,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
        card_type: cardType,
        card_logo: cardLogo,
      };
    } catch (error) {
      console.error(error, "error in bin check");
      return {};
    }
  };

  useEffect(() => {
    const fetchTenantData = async () => {
      setLoader(true);
      if (tenantsData.length > 0 && isOpen) {
        try {
          const recurrings = [];
          const tenants = [];
          const promises = tenantsData.map(async (tenant) => {
            const tenant_id = tenant.tenant_id;

            try {
              const customerData = await getCustomerVaultId(tenant_id);
              const customer_vault_id = customerData.customer_vault_id;

              const cardDetails = await getCardDetails({
                customer_vault_id,
                admin_id,
              });

              const cards = [];
              const billingData = cardDetails?.data?.customer;

              if (!billingData) {
                tenants.push({ ...tenant, cards });
                return;
              }

              if (Array.isArray(billingData.billing)) {
                const extractedData = await Promise.all(
                  billingData.billing.map((item) =>
                    handleSetCardDetails(item, tenant_id, tenant.lease_id)
                  )
                );
                cards.push(...extractedData.filter((item) => item));
              } else if (billingData) {
                const extractedData = await handleSetCardDetails(
                  billingData.billing,
                  tenant_id,
                  tenant.lease_id
                );
                if (extractedData) cards.push(extractedData);
              }

              if (!cards.length) {
                recurrings.push({
                  tenant_id: tenant.tenant_id,
                  lease_id: tenant.lease_id,
                  customer_vault_id,
                  date: "",
                  recurrings: [{ billing_id: "", amount: "", card_type: "" }],
                });
                tenants.push({ ...tenant, cards });
                return;
              }

              tenants.push({ ...tenant, cards });

              const recurringData = await getRecurringCards({
                tenant_id: tenant.tenant_id,
                lease_id: tenant.lease_id,
              });

              if (recurringData && recurringData?.data) {
                recurrings.push(recurringData?.data);
              } else {
                recurrings.push({
                  tenant_id: tenant.tenant_id,
                  lease_id: tenant.lease_id,
                  customer_vault_id,
                  date: "",
                  recurrings: [{ billing_id: "", amount: "", card_type: "" }],
                });
              }
            } catch (tenantError) {
              recurrings.push({
                tenant_id: tenant.tenant_id,
                lease_id: tenant.lease_id,
                customer_vault_id: "",
                date: "",
                recurrings: [{ billing_id: "", amount: "", card_type: "" }],
              });
              tenants.push({ ...tenant, cards: [] });
              console.error(
                `Error processing tenant ${tenant_id}: `,
                tenantError
              );
            }
          });

          await Promise.all(promises);

          setTenantData(tenants);
          setSelectedReccuringCards(recurrings);
        } catch (error) {
          console.error("Error: ", error);
        } finally {
          setLoader(false);
        }
      } else {
        setTenantData([]);
        setLoader(false);
      }
    };

    fetchTenantData();
  }, [tenantsData, isOpen]);

  const handleSelection = (selectedCard, tenant, recurringIndex) => {
    const updatedRecurringCards = selectedReccuringCards.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        const updatedRecurrings = t.recurrings.map((recurring, index) => {
          if (index === recurringIndex) {
            return {
              ...recurring,
              billing_id: selectedCard.billing_id,
              card_type: selectedCard.card_type,
            };
          }
          return recurring;
        });
        return { ...t, recurrings: updatedRecurrings };
      }
      return t;
    });

    setSelectedReccuringCards(updatedRecurringCards);
  };

  const addRow = (tenant) => {
    const updatedRecurringCards = selectedReccuringCards.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        return {
          ...t,
          recurrings: [
            ...t.recurrings,
            { billing_id: "", amount: "", card_type: "" },
          ],
        };
      }
      return t;
    });
    setSelectedReccuringCards(updatedRecurringCards);
  };

  const removeRow = (tenant, index) => {
    const updatedRecurringCards = selectedReccuringCards.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        const updatedRecurrings = t.recurrings.filter((_, i) => i !== index);
        return { ...t, recurrings: updatedRecurrings };
      }
      return t;
    });
    setSelectedReccuringCards(updatedRecurringCards);
  };

  const TenantDropDowns = ({ tenant, value, recurringIndex }) => {
    const card = tenant?.cards?.find((item) => item?.billing_id === value);

    const handleCreditCardOpen = (tenant) => {
      setSelectedTenantToAddCard(tenant);
      toggle();
      openmodal();
    };

    return (
      <>
        <Select
          labelId="user-select-label"
          id="user-select"
          value={card}
          onChange={(e) =>
            handleSelection(e.target.value, tenant, recurringIndex)
          }
          displayEmpty
          renderValue={(selected) => {
            return selected?.cc_number || "Select Card";
          }}
          style={{ minWidth: "150px" }}
        >
          <MenuItem className="background-colorsty bgtextwhite" onClick={() => handleCreditCardOpen(tenant)}>
            Add Card
          </MenuItem>
          {tenant?.cards?.length > 0 ? (
            tenant?.cards?.map((item) => (
              <MenuItem
                key={item.billing_id}
                value={item}
                className="d-flex"
                style={{ gap: "10px" }}
              >
                <div className="d-flex">
                  <img src={item?.card_logo} width={30} />
                </div>
                <div className="d-flex flex-column w-100">
                  <ListItemText
                    primary={`${item?.cc_number || ""}`}
                    primaryTypographyProps={{ style: { fontSize: "12px" } }}
                  />
                  <div className="d-flex justify-content-between">
                    <ListItemText
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                      primary={`${item?.card_type || ""}`}
                    />
                    <ListItemText
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                      primary={`${item?.cc_exp || ""}`}
                    />
                  </div>
                </div>
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">No card added</MenuItem>
          )}
        </Select>
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      if (
        selectedReccuringCards.every((tenant) =>
          tenant?.recurrings?.every((item) =>
            Object.entries(item).every(([key, value]) => value)
          )
        )
      ) {
        await addRecurringCards(selectedReccuringCards);
        fetchTenantData();
        toggle();
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const dueAmount =
    Number(amount || 0) -
    selectedReccuringCards.reduce(
      (acc1, tenant) =>
        acc1 +
        Number(
          tenant?.recurrings?.reduce(
            (acc2, item) => acc2 + Number(item?.amount),
            0
          )
        ),
      0
    );

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "600px" }}>
      <ModalHeader>
        <h2>Configure Recurring Payment</h2>
        <div
          className="mt-2 textcolorblue"
          style={{ fontSize: "16px", fontWeight: 400 }}
        >
          <span>Total Rent Amount: ${Number(amount || 0).toFixed(2)}</span>
          <span>
            <br />
            Remaining Balance: ${Number(dueAmount || 0).toFixed(2)}
          </span>
        </div>
      </ModalHeader>
      <Divider />
      <ModalBody>
        {loader ? (
          <Loader />
        ) : (
          tenantData?.map((tenant, index) => {
            const selectedTenant = selectedReccuringCards.find(
              (t) => tenant.tenant_id === t.tenant_id
            );

            return (
              <div className="d-flex flex-column w-100 mt-1" key={index}>
                <div className="d-flex justify-content-between mt-2">
                  <div
                    className="d-flex align-items-start textcolorblue w-100"
                    style={{ fontSize: "18px", fontWeight: 500 }}
                  >
                    <span className="pr-3">
                      {tenant?.tenant_firstName} {tenant?.tenant_lastName}
                    </span>
                  </div>
                </div>

                {selectedTenant?.recurrings?.map((recurring, i) => (
                  <div
                    className="d-flex align-items-center textcolorblue w-100 mt-3"
                    style={{ gap: "20px" }}
                    key={i}
                  >
                    <TenantDropDowns
                      tenant={tenant}
                      value={recurring?.billing_id}
                      recurringIndex={i} // Pass the recurring index for card-specific updates
                    />

                    <Select
                      labelId="user-select-label"
                      id="user-select"
                      value={recurring?.date}
                      style={{ minWidth: "200px" }}
                      onChange={(e) => {
                        const updatedRecurrings = [
                          ...selectedTenant.recurrings,
                        ];
                        updatedRecurrings[i].date = e.target.value;
                        setSelectedReccuringCards((prev) =>
                          prev.map((t) =>
                            t.tenant_id === tenant.tenant_id
                              ? { ...t, recurrings: updatedRecurrings }
                              : t
                          )
                        );
                      }}
                      displayEmpty
                      renderValue={(selected) =>
                        selected || "Select Day of Month"
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 150,
                          },
                        },
                      }}
                    >
                      {Array.from({ length: 28 }, (_, i) => i + 1).map(
                        (number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <Input
                      className="form-control-alternative fontstylerentmodal titleecolor w-100"
                      placeholder="$0.00"
                      type="text"
                      style={{
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderRadius: "6px",
                        width: "50px",
                        fontWeight: "600",
                      }}
                      value={recurring?.amount}
                      onChange={(e) => {
                        if (/^\d*\.?\d*$/.test(e.target.value)) {
                          const updatedRecurrings = [
                            ...selectedTenant.recurrings,
                          ];
                          updatedRecurrings[i].amount = e.target.value;
                          setSelectedReccuringCards((prev) =>
                            prev.map((t) =>
                              t.tenant_id === tenant.tenant_id
                                ? { ...t, recurrings: updatedRecurrings }
                                : t
                            )
                          );
                        }
                      }}
                    />
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => removeRow(tenant, i)}
                    />
                  </div>
                ))}

                <div
                  className="mt-2 mb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => addRow(tenant)}
                >
                  <AddIcon color="success" /> Add Row
                </div>
              </div>
            );
          })
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", gap: "10px" }}>
          <BaseButton onClick={toggle} variant="outline" label="Cancel" />
          <BaseButton
            onClick={async () => {
              try {
                const response = await axiosInstance.put(
                  `/recurring-cards/disable-cards/${tenantData[0].lease_id}`
                );
                if (response.data.statusCode === 200) {
                  await fetchTenantData();
                  toggle();
                } else {
                  await fetchTenantData();
                }
              } catch (error) {
                console.error("Error: ", error);
              }
            }}
            label="Disable"
          />
          <BaseButton
            id="payButton"
            type="submit"
            label="Save"
            onClick={handleSubmit}
            disabled={
              !selectedReccuringCards.every((tenant) =>
                tenant?.recurrings?.every((item) =>
                  Object.entries(item).every(([key, value]) => value)
                )
              ) || dueAmount !== 0
            }
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RecurringTenants;
