// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crm-card {
  background: #fff;
  border: 0.5px solid rgb(50, 69, 103);
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.crm-input-label {
  font-size: 16px !important;
  color: #090e24 !important;
  font-weight: bold !important;
}
.crm-input-label .required {
  color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/global.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,+CAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAMA;EACE,0BAAA;EACA,yBAAA;EACA,4BAAA;AAHF;AAKE;EACE,qBAAA;AAHJ","sourcesContent":["// Global Classes\n// These classes can be use in any file without importing it.\n\n.crm-card {\n  background: #fff;\n  border: 0.5px solid rgb(50, 69, 103);\n  padding: 1rem;\n  margin-bottom: 2rem;\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  // gap: 20px;\n}\n\n.crm-input-label {\n  font-size: 16px !important;\n  color: #090e24 !important;\n  font-weight: bold !important;\n\n  .required {\n    color: red !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
