import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaTimes } from "react-icons/fa";
import { CardContent } from "@mui/material";
import { Col, Row, Card } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../components/Payments/Form.scss";
import { Cookies, useCookies } from "react-cookie";
import { addCardDetails } from "../../../plugins/ApiHandler";
import Loader from "components/ReusableComponents/Loader";
import CardTemplate from "components/Payments/CardTemplate";
import AddCardForm from "../../../components/Payments/AddCardForm";

const AddCardDetailsForm = (props) => {
  const [prefillData, setPrefillData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (props.accessType) {
        try {
          const data = props.accessType;
          setPrefillData({
            first_name: data.tenant_firstName,
            last_name: data.tenant_lastName,
            email: data.tenant_email,
            phone: data.tenant_phoneNumber,
          });
        } catch (error) {}
      }
    };

    fetchData();
  }, [props]);

  const addCardInfo = async (data) => {
    const cookies = new Cookies();
    const tenant_id = props?.tenant_id;
    const admin_id = props?.admin_id;

    data.tenant_id = tenant_id;
    data.admin_id = admin_id;
    try {
      await addCardDetails(data);
      props.onHide();
      props.getCardsData(tenant_id, admin_id);
      toast.success("Card details added successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        keyboard
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="text-lg">Add a new card</h4>
          </Modal.Title>
          <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col xs="12" md="6">
                {!props.scriptGenerating && !props.scriptError && (
                  <AddCardForm
                    preFillData={{
                      ...prefillData,
                      address1: props.rentalAddress,
                    }}
                    addCardInfo={addCardInfo}
                    onHide={props.onHide}
                  />
                )}
              </Col>
              {/* Card Details Section */}
              <Col xs="12" md="6">
                <Card style={{ maxWidth: "500px" }}>
                  <CardContent>
                    <h4 className="text-lg">Cards</h4>

                    {/* Card Details */}
                    {props.getCardLoading ? (
                      <div className="d-flex justify-content-center align-items-center p-5 m-5">
                        <Loader />
                      </div>
                    ) : props.getCardError ? (
                      <p>{props.getCardError}</p>
                    ) : props.cardDetalis && props.cardDetalis?.length > 0 ? (
                      <>
                        {props.cardDetalis.map((card, index) => (
                          <div
                            className="row d-flex align-items-center justify-content-around"
                            key={index}
                          >
                            <CardTemplate card={card} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No cards found</p>
                    )}
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddCardDetailsForm;
