import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownItem,
  Table,
  Dropdown,
  DropdownToggle,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Modal,
  Input,
} from "reactstrap";
import BaseButton from "components/ReusableComponents/BaseButton";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "axiosInstance";
import moment from "moment";
import { toast } from "react-toastify";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import swal from "sweetalert";

function LeaseNote(props) {
  const lease_id = props.leaseId;
  const admin_id = props.adminId;
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [noteType, setNoteType] = useState("");
  const [notedropdownOpen, setNotedropdownOpen] = useState(false);
  const toggle2 = () => setNotedropdownOpen((prevState) => !prevState);
  const [selectedNoteType, setSelectedNoteType] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [NoteTypeDropdownOpen, setNoteTypeDropdownOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editNoteId, setEditNoteId] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;

  const [loader, setLoader] = useState(false);
  const NoteTypeDropDownToggle = () =>
    setNoteTypeDropdownOpen((prevState) => !prevState);

  const noteTypeList = [
    "General",
    "Reminder",
    "Legal",
    "Lease Payment",
  ];

  const handleClickOpen = () => {
    setShowAddModal(true);
  };

  const handleCloseNewNote = () => {
    setShowAddModal(false);
    setNoteType();
    NotesFormik.resetForm();
  };

  const fetchNotes = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axiosInstance.get(`/lease-notes/${lease_id}`);
      if (response.data.statusCode === 200) {
        const data = response.data.data.reverse();
        setNotes(data);
        const totalCount = data.length;
        setTotalPages(Math.ceil(totalCount / rowsPerPage));
  
        const slicedNotes = data.slice(startIndex, endIndex) || [];
        setNotes(slicedNotes);
      } else {
        setNotes([]);
        setError("No lease notes found");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
      setError("Failed to fetch lease notes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [lease_id , page, rowsPerPage]);

  const handleOpenEditNote = (item) => {
    setShowAddModal(true);
    setEditNoteId(item.note_id);
    NotesFormik.setValues({
      note_type: item.note_type,
      content: item.content,
    });
  };

  const NotesFormik = useFormik({
    initialValues: {
      note_type: "",
      content: "",
    },
    validationSchema: yup.object({
      note_type: yup.string().required("please select a note type"),
      content: yup.string().trim().required("Content is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (loader) return;
      setLoader(true);
      try {
        const payload = {
          lease_id: lease_id,
          admin_id: admin_id,
          note_type: values.note_type,
          content: values.content,
        };
        let response;
        if (editNoteId) {
          response = await axiosInstance.put(
            `/lease-notes/update_note/${editNoteId}`,
            payload
          );
        } else {
          response = await axiosInstance.post("/lease-notes/add_note", payload);
        }
        if (response.status === 200) {
          toast.success("Note added successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          resetForm();
          setEditNoteId();
          await fetchNotes();
          fetchNotes();
        }
      } catch (error) {
        console.error("Error adding note:", error);
        toast.error("Failed to add note", {
          position: "top-center",
          autoClose: 1000,
        });
      } finally {
        setLoader(false);
      }
      if (fetchNotes) fetchNotes();
      handleCloseNewNote();
    },
  });

  const handleDelete = async (note_id) => {
    console.log(note_id);
    swal({
      title: "Are you sure?",
      text: "You want to delete this note?",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: false,
          className: "swal-button--danger",
        },
        cancel: {
          text: "Cancel",
          visible: true,
        },
      },
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axiosInstance.delete(`/lease-notes/delete_note/${note_id}`);
          toast.success("Note deleted successfully");
          fetchNotes();
          swal.close();
        } catch (error) {
          console.error("Error deleting note:", error);
          toast.error("Failed to delete note");
        }
      }
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <Row>
        <Col className="d-flex">
          <Button
            className="ml-auto fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            size="small"
            onClick={handleClickOpen}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 4px 0px #00000040",
              borderRadius: "4px",
              border: "0",
              marginRight: "20px",
            }}
          >
            {isEditing ? "Edit Note" : "Add Note"}
          </Button>
        </Col>
      </Row>
      <div className="px-4 mt-4">
           <Row>
          <Table
            className="align-items-center table-flush table w-100 "
            responsive
            style={{
              borderTopLeftRadius: "20px",
              borderCollapse: "collapse",
            }}
          >
            <thead
              style={{
                height: "45px",
                fontSize: "14px",
                fontFamily: "poppins",
                fontWeight: "600",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderTopLeftRadius: "20px",
              }}
            >
              <tr
                style={{
                  border: "2px solid rgba(50, 69, 103, 1)",
                  borderTopLeftRadius: "20px",
                }}
              >
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    color: "#152B51",
                    width: "15%",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Date
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "60%" }}
                >
                  Content
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{ color: "#152B51", width: "20%" }}
                >
                  Note Type
                </th>
                <th
                  className="tablefontstyle px-3"
                  scope="col"
                  style={{
                    borderTopRightRadius: "15px",
                    color: "#152B51",
                    width: "10%",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody style={{ overflow: "auto" }}>
              <tr
                style={{
                  border: "none",
                  height: "20px",
                }}
              >
                <td colSpan="4" className="border-none"></td>
              </tr>
              {notes.length > 0 ? (
                notes.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        border: "0.5px solid rgba(50, 69, 103, 1)",
                        fontSize: "12px",
                        height: "40px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      <td className="bordertopintd tablebodyfont px-3">
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                      </td>
                      {/* <td className="bordertopintd tablebodyfont px-3">
                      {item.content}
                    </td> */}
                      <td className="bordertopintd tablebodyfont px-3">
                        {item.content.split(" ").reduce((acc, word, index) => {
                          if (index > 0 && index % 12 === 0) {
                            acc.push(<br key={index} />);
                          }
                          acc.push(word + " ");
                          return acc;
                        }, [])}
                      </td>

                      <td className="bordertopintd tablebodyfont px-3">
                        {item.note_type}
                      </td>
                      <td className="bordertopintd tablebodyfont px-3">
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenEditNote(item)}
                          >
                            <img src={editicon} width={20} height={20} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(item.note_id)}
                            >
                              <img
                                src={deleicon}
                                width={20}
                                height={20}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="textcolorblue "
                    colSpan="4"
                    style={{
                      textAlign: "center",
                      border: "0.5px solid #324567",
                      fontSize: "16px",
                      fontFamily: "600",
                    }}
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          </Row>
      </div>
      <Modal
        isOpen={showAddModal}
        style={{ borderRadius: "15px", minxWidth: "600px", maxWidth: "600px", height:"445px" }}
        toggle={() => setShowAddModal(!showAddModal)}
        className="modal-dialog-scrollable"
      >
        <ModalHeader className=" text-white">
          <strong
            className="barforsummaryrent textcolorblue fontfamilysty"
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {editNoteId ? "Edit Note" : "Add Note"}
          </strong>
        </ModalHeader>
        <ModalBody>
          <div
            className="labelfontstyle textcolorblue fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            <Row className="mt-3">
              <Col
                className="textcolorblue fontfamilysty"
                lg={12}
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Notes Type <span className=" text-red">*</span>
              </Col>
            </Row>
            <Row>
              <Col
                className="titleecolor fontfamilysty"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                lg={12}
              >
                <FormGroup>
                  <Dropdown
                    className="dropdownfontsyle"
                    isOpen={NoteTypeDropdownOpen}
                    toggle={NoteTypeDropDownToggle}
                  >
                    <DropdownToggle
                      caret
                      className="leasetypee dropdownfontsyle titleecolor"
                      style={{
                        width: "100%",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",

                        backgroundColor: "transparent",
                      }}
                    >
                      {NotesFormik.values.note_type || "Select Note Type"}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </DropdownToggle>
                    <DropdownMenu
                    style={{ maxHeight: "none", overflow: "visible" }}
                    className="dropdownfontsyle"
                    modifiers={{
                      preventOverflow: { enabled: false },
                      hide: { enabled: false },
                      computeStyles: {
                        enabled: true,
                        fn: (data) => {
                          data.styles.top = "100%"; 
                          data.styles.transform = "none"; 
                          return data;
                        },
                      },
                    }}
                  >
                      {noteTypeList.map((item, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            className="dropdownfontsyle"
                            onClick={() => {
                              setNoteType(item);
                              NotesFormik.setFieldValue("note_type", item);
                            }}
                          >
                            {item}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  {NotesFormik.touched.note_type &&
                  NotesFormik.errors.note_type ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {" "}
                      {NotesFormik.errors.note_type}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                className="titleecolor fontfamilysty"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                lg={12}
              >
                <Row className="mt-3">
                  <Col
                    className="textcolorblue fontfamilysty"
                    lg={12}
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Content <span className=" text-red">*</span>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    style={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                    id="input-reserve"
                    type="textarea"
                    placeholder="Content"
                    name="content"
                    value={NotesFormik.values.content}
                    onChange={NotesFormik.handleChange}
                    onBlur={NotesFormik.handleBlur}
                  />
                  {NotesFormik.touched.content && NotesFormik.errors.content ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {NotesFormik.errors.content}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <BaseButton
            onClick={handleCloseNewNote}
            variant="outline"
            label="Cancel"
          />
          {loader ? (
            <Button
              type="submit fontstylerentr background-colorsty bgtextwhite"
              className="btn"
              style={{ cursor: "not-allowed" }}
              disabled
            >
              Loading...
            </Button>
          ) : (
            <BaseButton
              label={editNoteId ? "Update" : "Add"}
              onClick={() => NotesFormik.handleSubmit()}
            />
          )}
        </ModalFooter>
      </Modal>

      <Row>
        <Col className="text-right mb-2" style={{  marginRight: "20px" }}>
          <Dropdown isOpen={notedropdownOpen} toggle={toggle2}>
            <DropdownToggle className="paginationbtnstyle" caret>
              {rowsPerPage}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(10);
                  setPage(1);
                }}
              >
                10
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(25);
                  setPage(1);
                }}
              >
                25
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(50);
                  setPage(1);
                }}
              >
                50
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setRowsPerPage(100);
                  setPage(1);
                }}
              >
                100
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button
            className="p-0"
            style={{}}
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-caret-left"
              viewBox="0 0 16 16"
            >
              <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
            </svg>
          </Button>
          <span>
            Page {page} of {totalPages}
          </span>{" "}
          <Button
            className="p-0"
            style={{}}
            onClick={() => handleChangePage(page + 1)}
            disabled={page === totalPages}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-caret-right"
              viewBox="0 0 16 16"
            >
              <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
            </svg>
          </Button>{" "}
        </Col>
      </Row>
    </div>
  );
}

export default LeaseNote;
