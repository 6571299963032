import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SearchDropdown = ({
  options,
  value,
  onChange,
  filterOptions,
  handleBlur,
  getOptionLabel,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      disablePortal
      open={open}
      size="small"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      filterOptions={filterOptions}
      className=" fontstylerentmodal "
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          onBlur={handleBlur}
          sx={{
            // width: "250px",
            height: "43px",

            "& .MuiOutlinedInput-root": {
              height: "43px",
              borderRadius: "6px",

              "& fieldset": {
                borderColor: "#ced4da",
                borderRadius: "6px",
                width: "100%",
                backgroundColor: "transparent",
                boxShadow: "0px 4px 4px 0px #00000040",
              },
              "& input": {
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 500,
                color: "rgb(73, 81, 96)",
              },
              "&:hover fieldset": {
                borderColor: "#ced4da",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#ced4da",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ced4da",
              },
            },
            "& .MuiAutocomplete-inputRoot": {
              "& .MuiAutocomplete-input": {
                color: "rgb(73, 81, 96)",
                fontSize: "15px",
              },
            },
            "& .MuiInputLabel-root": {
              color: "rgb(73, 81, 96)",
            },
            "& input::placeholder": {
              color: "rgb(73, 81, 96)",
              opacity: 1, 
            },
          }}
        />
      )}
    />
  );
};

export default SearchDropdown;
