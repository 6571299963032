import React from "react";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Paper, Typography, Box, CircularProgress, } from "@mui/material";
import moment from "moment";

const PoliciesTable = ({ policies, insuranceLoader, totalPolicies, insurancePage, insuranceRowsPerPage, setInsurancePage, setInsuranceRowsPerPage, }) => {
  const handleChangeInsurancePage = (event, newPage) => {
    setInsurancePage(newPage);
  };

  const handleChangeInsuranceRowsPerPage = (event) => {
    setInsuranceRowsPerPage(parseInt(event.target.value, 10));
    setInsurancePage(0);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "0 0 20px 20px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Table>
        <TableHead>
          <TableRow style={{ heght: "50px" }}>
            <TableCell>
              <span
                className="labelfontstyle titleecolor dashtable-header"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Tenant Name
              </span>
            </TableCell>
            <TableCell>
              <span
                className="labelfontstyle titleecolor dashtable-header"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Rental Address
              </span>
            </TableCell>
            <TableCell>
              <span
                className="labelfontstyle titleecolor dashtable-header"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Expiration Date
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {insuranceLoader ? (
            <TableRow style={{ height: "50px" }}>
              <TableCell colSpan={3} align="center">
                <Box display="flex" justifyContent="center">
                  <CircularProgress size={30} style={{ color: "#152B51" }} />
                </Box>
              </TableCell>
            </TableRow>
          ) : policies.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Typography>No policies are expiring within 90 days.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            policies.map((policy) => (
              <TableRow key={policy.policy_id} style={{ height: "50px" }}>
                <TableCell>{policy.tenant_name}</TableCell>
                <TableCell>{policy.rental_address}</TableCell>
                <TableCell>
                  {moment(policy.expiration_date).format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalPolicies}
        page={insurancePage}
        onPageChange={handleChangeInsurancePage}
        rowsPerPage={insuranceRowsPerPage}
        onRowsPerPageChange={handleChangeInsuranceRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          "& p": { margin: "0" },
          "& div": { margin: "0" },
        }}
      />
    </TableContainer>
  );
};

export default PoliciesTable;
