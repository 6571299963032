import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Grid } from "@mui/material";
import Header from "components/Headers/Header";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./RentRollDetail.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const RentRoll = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [tenantsData, setTenantsData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [sortedColumn, setSortedColumn] = useState("end_date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("end_date");
  const [permissions, setPermissions] = useState(null);

  const navigateToRentRollDetails = (tenantId) => {
    navigate(
      admin
        ? `/${admin}/rentrolldetail/${tenantId}`
        : `/staff/staffrentrolldetail/${tenantId}`
    );
  };

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const fetchData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/leases/leases/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          const data = response.data.data;

          data.forEach((item) => {
            const sortedTenantNames = item.tenantNames
              .split(", ")
              .sort((a, b) => a.split(" ")[0].localeCompare(b.split(" ")[0]))
              .join(", ");
            item.tenantNames = sortedTenantNames;
          });

          const reversedData = data.slice().reverse();
          setTenantsData(reversedData);
          setTotalPages(Math.ceil(reversedData.length / pageItem));
          setLoader(false);
        } else {
          setLoader(false);
          return;
        }
      } catch (error) {
        setLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    getLeaseLimit();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (tenantsData) {
    paginatedData = tenantsData.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...tenantsData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress?.localeCompare(b.rental_adress)
            : b.rental_adress?.localeCompare(a.rental_adress)
        );
        break;
      case "end_date":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.end_date?.localeCompare(b.end_date)
            : b.end_date?.localeCompare(a.end_date)
        );
        break;
      case "remainingDays":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.remainingDays - b.remainingDays
            : b.remainingDays - a.remainingDays
        );
      case "totalBalance":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.totalBalance - b.totalBalance
            : b.totalBalance - a.totalBalance
        );
        break;
      default:
        break;
    }
    setTenantsData(sortedData || []);
  };

  const filterRentRollsBySearch = () => {
    let filteredData = tenantsData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        const tenantNames = tenant.tenantNames || "";
        return (
          tenant?.rental_adress?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.start_date?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.end_date?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.totalBalance
            ?.toString()
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          tenantNames?.toLowerCase()?.includes(lowerCaseSearchQuery) ||
          tenant?.rent_cycle?.toLowerCase()?.includes(lowerCaseSearchQuery) ||
          tenant?.remainingDays
            ?.toString()
            ?.toLowerCase()
            ?.includes(lowerCaseSearchQuery) ||
          tenant?.amount
            ?.toString()
            ?.toLowerCase()
            ?.includes(lowerCaseSearchQuery)
        );
      });
    }
    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentRollsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const deleteTenant = (lease_id, end_date) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (currentDate >= end_date) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this lease!",
        icon: "warning",
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter reason for deletion",
            type: "text",
            id: "delete-reason",
            oninput: (e) => {
              const reason = e.target.value;
              console.log("Input Value:", reason);

              const deleteButton = document.querySelector(
                ".swal-button--confirm"
              );
              deleteButton.disabled = reason.trim() === "";
            },
          },
        },
        buttons: {
          confirm: {
            text: "Delete",
            closeModal: true,
            value: true,
            className: "swal-button--danger",
          },
          cancel: "Cancel",
        },
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const deletionReason = document.getElementById("delete-reason").value;
          console.log("Confirmed Deletion Reason:", deletionReason);

          try {
            const res = await axiosInstance.delete(
              `/leases/leases/${lease_id}`,
              {
                params: {
                  company_name: admin,
                },
                data: {
                  reason: deletionReason,
                },
              }
            );

            if (res.data.statusCode === 200) {
              toast.success("Lease Deleted Successfully", {
                position: "top-center",
                autoClose: 1000,
              });
              fetchData();
              getLeaseLimit();
            } else {
              toast.warning(res.data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          } catch (error) {
            console.error("Error deleting lease:", error);
            toast.error(error.message, {
              position: "top-center",
            });
          }
        } else {
          toast.success("Lease is safe :)", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      });
      setTimeout(() => {
        const deleteButton = document.querySelector(".swal-button--confirm");
        if (deleteButton) {
          deleteButton.disabled = true;
        }
      }, 0);
    } else {
      toast.warning("You Cannot delete active lease!", {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const editLeasing = (id) => {
    navigate(
      admin
        ? `/${admin}/RentRollLeaseing/${id}`
        : `/staff/staffaddrentroll/${id}`
    );
  };

  const displayTenantNames = (tenantNames) => {
    if (!Array.isArray(tenantNames)) {
      tenantNames = tenantNames ? tenantNames.split(",") : [];
    }

    const pairs = [];
    for (let i = 0; i < tenantNames.length; i += 2) {
      pairs.push(
        <span
          key={i}
          className=" "
          style={{ fontFamily: "Poppins", fontSize: "13px", fontWeight: "500" }}
        >
          {tenantNames[i]}
          {tenantNames[i + 1] ? `, ${tenantNames[i + 1]}` : ""}
          <br />
        </span>
      );
    }
    return pairs;
  };

  const [countRes, setCountRes] = useState("");
  const getLeaseLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/leases/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  return (
    <>
      <Header />
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          {(admin || permissions?.lease_add) && (
            <Col xs="12" lg="12" sm="12" className="text-right">
              <Button
                className="rentrollbtns background-colorsty bgtextwhite fontfamilysty"
                onClick={() => {
                  // if (countRes.statusCode === 201) {
                  //   swal(
                  //     "Plan Limitation",
                  //     "The limit for adding lease according to the plan has been reached.",
                  //     "warning"
                  //   );
                  // } else {
                    navigate(
                      admin
                        ? "/" + admin + "/RentRollLeaseing"
                        : "/staff/staffaddrentroll"
                    );
                  // }
                }}
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Lease
              </Button>
            </Col>
          )}
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Rent Roll
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Row className="mb-3">
                  <Col xs="6" sm="6" lg="2">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh titleecolor"
                       
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle rentaladded fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.leaseCount}
                        </b>{" "}
                        {/* {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.leaseCountLimit}
                        </b> */}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("rental_adress")}
                        onMouseEnter={() => setHoveredColumn("rental_adress")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Lease
                        {hoveredColumn === "rental_adress" ||
                        sortedColumn === "rental_adress" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer", width: "15%" }}
                        onClick={() => handleSort("end_date")}
                        onMouseEnter={() => setHoveredColumn("end_date")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Lease End
                        {hoveredColumn === "end_date" ||
                        sortedColumn === "end_date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Rent Cycle
                      </th>
                      <th
                        className="tablefontstyle textcolorblue text-center"
                        scope="col"
                        style={{ cursor: "pointer", width: "15%" }}
                        onClick={() => handleSort("remainingDays")}
                        onMouseEnter={() => setHoveredColumn("remainingDays")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Remaining Days
                        {hoveredColumn === "remainingDays" ||
                        sortedColumn === "remainingDays" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue text-right"
                        scope="col"
                        style={{ cursor: "pointer", width: "15%" }}
                        onClick={() => handleSort("totalBalance")}
                        onMouseEnter={() => setHoveredColumn("totalBalance")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Current Balance
                        {hoveredColumn === "totalBalance" ||
                        sortedColumn === "totalBalance" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue text-right"
                        scope="col"
                      >
                        Rent
                      </th>
                      {(admin ||
                        permissions?.lease_edit ||
                        permissions?.lease_delete) && (
                        <th
                          className="tablefontstyle textcolorblue text-center"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                          }}
                        >
                          ACTION
                        </th>
                      )}
                    </tr>
                  </thead>
                  {tenantsData.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="10" style={{ fontSize: "15px" }}>
                          No Lease Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((tenant) => (
                        <tr
                          key={tenant.lease_id}
                          onClick={() =>
                            navigateToRentRollDetails(tenant.lease_id)
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            <span className="tablebodyfont rentrollcolorsty">
                              {tenant.rental_adress}{" "}
                              {tenant.rental_unit
                                ? " - " + tenant.rental_unit
                                : null}{" "}
                            </span>
                            <br />
                            {displayTenantNames(tenant.tenantNames)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(tenant?.end_date).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {tenant?.rent_cycle}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor text-center">
                            {tenant?.remainingDays} Days
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor text-right">
                            {tenant?.totalBalance >= 0
                              ? `$${Number(tenant?.totalBalance || 0).toFixed(
                                  2
                                )}`
                              : `-$${Number(
                                  Math.abs(tenant?.totalBalance) || 0
                                ).toFixed(2)}`}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor text-right">
                            {`$${tenant?.amount.toFixed(2) || "$0.00"}`}
                          </td>
                          {(admin ||
                            permissions?.lease_edit ||
                            permissions?.lease_delete) && (
                            <td className="bordertopintd tablebodyfont">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  justifyContent: "center",
                                }}
                              >
                                {(admin || permissions?.lease_delete) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      tenant.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteTenant(
                                            tenant.lease_id,
                                            tenant.end_date
                                          );
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {(admin || permissions?.lease_edit) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      tenant.admin_id === "is_trial"
                                        ? freetrialData()
                                        : editLeasing(tenant.lease_id);
                                    }}
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                        // end
                      ))}
                    </tbody>
                  )}
                </Table>

                {paginatedData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </>
  );
};

export default RentRoll;
