import React, { useState, useRef, useEffect } from "react";
import "./CSS/Dropdown.scss";
import { CircularProgress } from "@mui/material";
import { GoChevronDown } from "react-icons/go";

const Dropdown = ({
  options = [],
  placeholder = "Select an option",
  onSelect,
  valueKey = "value",
  labelKey1 = "label",
  labelKey2 = "",
  subtitleKey = "subtitle",
  style = { minWidth: "250px", width: "fit-content" },
  prependIcon = null,
  appendIcon = null,
  loading = false,
  disabled = false,
  emptyMsg,
  label,
  required,
  showStar,
  id,
  oldOption = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (oldOption) {
      setSelectedOption(oldOption);
    }
  }, [oldOption]);

  const handleSelect = (option, event, index) => {
    event.stopPropagation();
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option, index);
    }
  };

  const toggleDropdown = () => {
    if (!loading) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isSelected = (option) => {
    if (typeof option === "object" && selectedOption) {
      return option[valueKey] === selectedOption[valueKey];
    }
    return option === selectedOption;
  };

  const renderOption = (option, index) => {
    let label, subtitle;

    if (typeof option === "object" && option !== null) {
      label = option[labelKey1]
        ? `${option[labelKey1]}${
            option[labelKey2] ? ` - ${option[labelKey2]}` : ""
          }`
        : option[valueKey];
      subtitle = option[subtitleKey] || "";
    } else {
      label = String(option);
    }

    return (
      <li
        key={index}
        className={`dropdown-item ${isSelected(option) ? "selected" : ""}`}
        onClick={(event) => handleSelect(option, event, index)}
      >
        <div className="dropdown-item-label">{label}</div>
        {subtitle && <div className="dropdown-item-subtitle">{subtitle}</div>}
      </li>
    );
  };

  useEffect(() => {
    if (options.length === 1) {
      setSelectedOption(options[0]);
      onSelect(options[0], 0);
    }
  }, [options]);

  const labelStyles = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    margin: "5px 0px",
  };

  return (
    <div>
      {label && (
        <label htmlFor={id} style={labelStyles}>
          {label}{" "}
          {(required || showStar) && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <button
        disabled={disabled}
        type="button"
        className={`dropdown-container ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
        ref={dropdownRef}
        style={{
          ...style,
          background: "none",
          border: "none",
          padding: "0px",
          ...{ cursor: disabled ? "not-allowed" : "pointer" },
        }}
      >
        <div className="dropdown-label">
          {prependIcon && <span className="prepend-icon">{prependIcon}</span>}
          {selectedOption
            ? typeof selectedOption === "object"
              ? `${selectedOption[labelKey1]}${
                  labelKey2 && selectedOption[labelKey2]
                    ? ` - ${selectedOption[labelKey2]}`
                    : ""
                }`
              : selectedOption
            : placeholder}
          {appendIcon && <span className="append-icon">{appendIcon}</span>}
        </div>
        <div className="dropdown-trigger">
          {loading ? (
            <CircularProgress size="17px" color="grey" />
          ) : (
            <GoChevronDown
              style={{
                fontSize: "22px",
                transition: "all 0.2s ease",
                transform: `rotate(${isOpen ? "180" : "0"}deg)`,
              }}
            />
          )}
        </div>
        {isOpen && (
          <ul className="dropdown-menu">
            {options.length > 0 ? (
              options.map((option, index) => renderOption(option, index))
            ) : (
              <li className="dropdown-item no-data">
                {emptyMsg || "No Options Available"}
              </li>
            )}
          </ul>
        )}
      </button>
    </div>
  );
};

export default Dropdown;
