import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Input,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import Header from "components/Headers/Header";
import moment from "moment";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./properties.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
// import ListMapComponent from "../../components/ListMapComponent";

const PropertiesTables = () => {
  const { admin } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [rentalsData, setRentalsData] = useState([]);
  const [search, setSearch] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const toggle3 = () => setSearch((prevState) => !prevState);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [mapDisplay, setMapDisplay] = useState(false);

  const navigateToPropDetails = (rental_id) => {
    const propDetailsURL = `/${admin}/PropDetails/${rental_id}`;
    navigate(
      admin ? propDetailsURL : `/staff/staffpropertydetail/${rental_id}`
    );
  };

  const [permissions, setPermissions] = useState(null);
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const getRentalsData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rentals/${accessType?.admin_id}`
        );
        setRentalsData(response.data.data || []);
        // setTotalPages(Math.ceil(response.data.data?.length / pageItem));
        getRentalsLimit();
      } catch (error) {
        console.error("Error fetching rental data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  const [countRes, setCountRes] = useState("");
  const getRentalsLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  const deleteRentals = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this rental property!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;

        // Make the delete request
        axiosInstance
          .delete(`/rentals/rental/${id}`, {
            params: {
              company_name: admin,
            },
            data: {
              reason: deletionReason,
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Property deleted successfully!", {
                position: "top-center",
                autoClose: 500,
              });
              getRentalsData();
              getRentalsLimit();
            } else if (response.data.statusCode === 201) {
              toast.warning("Property already assigned to Tenant!", {
                position: "top-center",
                autoClose: 500,
              });
              getRentalsData();
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 500,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting rental property:", error);
          });
      } else {
        toast.success("Property is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  useEffect(() => {
    getRentalsData();
    getRentalsLimit();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (rentalsData) {
    paginatedData = rentalsData.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editProperty = (rental_id) => {
    navigate(
      admin
        ? `/${admin}/rentals/${rental_id}`
        : `/staff/staffpropertyadd/${rental_id}`
    );
  };

  const filterRentalsBySearch = () => {
    let filteredData = rentalsData;
    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((Rental) => {
        const address = `${Rental?.rental_adress} ${Rental?.rental_city}`;
        return (
          Rental?.rental_adress?.toLowerCase().includes(lowerCaseSearchQuery) ||
          Rental?.property_type_data?.property_type
            .toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.property_type_data?.propertysub_type
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_city?.toLowerCase()?.includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data?.rentalOwner_name
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data?.rentalOwner_companyName
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data?.rentalOwner_primaryEmail
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.rental_owner_data?.rentalOwner_phoneNumber
            ?.toString()
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          Rental?.createdAt?.toLowerCase().includes(lowerCaseSearchQuery) ||
          Rental?.updatedAt?.toLowerCase().includes(lowerCaseSearchQuery) ||
          address?.toLowerCase()?.includes(lowerCaseSearchQuery)
        );
      });
    }
    if (searchQuery2) {
      if (searchQuery2 === "All") {
        filteredData = filteredData;
      } else {
        const lowerCaseSearchQuery = searchQuery2.toLowerCase();
        filteredData = filteredData.filter((property) => {
          const isPropertyTypeMatch =
            property?.property_type_data?.property_type
              .toLowerCase()
              .includes(lowerCaseSearchQuery);
          const isPropertySubTypeMatch =
            property?.property_type_data?.property_type
              .toLowerCase()
              .includes(lowerCaseSearchQuery);
          return isPropertyTypeMatch || isPropertySubTypeMatch;
        });
      }
    }
    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "rental_adress":
            filteredData.sort((a, b) => {
              const comparison = a.rental_adress?.localeCompare(
                b.rental_adress
              );
              return upArrow.includes("rental_adress")
                ? comparison
                : -comparison;
            });
            break;
          case "type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.property_type?.localeCompare(
                  b.property_type_data.property_type
                );
              return upArrow.includes("type") ? comparison : -comparison;
            });
            break;
          case "property_type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.propertysub_type?.localeCompare(
                  b.property_type_data.propertysub_type
                );
              return upArrow.includes("property_type")
                ? comparison
                : -comparison;
            });
            break;
          case "rental_city":
            filteredData.sort((a, b) => {
              const comparison = a.rental_city?.localeCompare(b.rental_city);
              return upArrow.includes("rental_city") ? comparison : -comparison;
            });
            break;
          case "rentalOwner_name":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_name?.localeCompare(
                  b.rental_owner_data.rentalOwner_name
                );
              return upArrow.includes("rentalOwner_name")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_companyName":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_companyName?.localeCompare(
                  b.rental_owner_data.rentalOwner_companyName
                );
              return upArrow.includes("rentalOwner_companyName")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_primaryEmail":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_primaryEmail?.localeCompare(
                  b.rental_owner_data.rentalOwner_primaryEmail
                );
              return upArrow.includes("rentalOwner_primaryEmail")
                ? comparison
                : -comparison;
            });
            break;
          case "rentalOwner_phoneNumber":
            filteredData.sort((a, b) => {
              const phoneA =
                a.rental_owner_data?.rentalOwner_phoneNumber?.toString() || "";
              const phoneB =
                b.rental_owner_data?.rentalOwner_phoneNumber?.toString() || "";

              const comparison = phoneA.localeCompare(phoneB);

              return upArrow.includes("rentalOwner_phoneNumber")
                ? comparison
                : -comparison;
            });
            break;
          case "createdAt":
            filteredData.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              const comparison = dateA - dateB;
              return upArrow.includes("createdAt") ? comparison : -comparison;
            });

            break;
          case "updatedAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.updatedAt) - new Date(b.updatedAt);
              return upArrow.includes("updatedAt") ? comparison : -comparison;
            });
            break;
          case "-rental_adress":
            filteredData.sort((a, b) => {
              const comparison = a.rental_adress?.localeCompare(
                b.rental_adress
              );
              return upArrow.includes("-rental_adress")
                ? -comparison
                : comparison;
            });
            break;
          case "-type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.property_type?.localeCompare(
                  b.property_type_data.property_type
                );
              return upArrow.includes("-type") ? -comparison : comparison;
            });
            break;
          case "-property_type":
            filteredData.sort((a, b) => {
              const comparison =
                a.property_type_data.propertysub_type?.localeCompare(
                  b.property_type_data.propertysub_type
                );
              return upArrow.includes("-property_type")
                ? -comparison
                : comparison;
            });
            break;
          case "-rental_city":
            filteredData.sort((a, b) => {
              const comparison = a.rental_city?.localeCompare(b.rental_city);
              return upArrow.includes("-rental_city")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_name":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_name?.localeCompare(
                  b.rental_owner_data.rentalOwner_name
                );
              return upArrow.includes("-rentalOwner_name")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_companyName":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_companyName?.localeCompare(
                  b.rental_owner_data.rentalOwner_companyName
                );
              return upArrow.includes("-rentalOwner_companyName")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_primaryEmail":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_primaryEmail?.localeCompare(
                  b.rental_owner_data.rentalOwner_primaryEmail
                );
              return upArrow.includes("-rentalOwner_primaryEmail")
                ? -comparison
                : comparison;
            });
            break;
          case "-rentalOwner_phoneNumber":
            filteredData.sort((a, b) => {
              const comparison =
                a.rental_owner_data.rentalOwner_phoneNumber -
                b.rental_owner_data.rentalOwner_phoneNumber;
              return upArrow.includes("-rentalOwner_phoneNumber")
                ? -comparison
                : comparison;
            });
            break;
          case "-createdAt":
            filteredData.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              const comparison = dateA - dateB;
              return upArrow.includes("-createdAt") ? -comparison : comparison;
            });

            break;
          case "-updatedAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.updatedAt) - new Date(b.updatedAt);
              return upArrow.includes("-updatedAt") ? -comparison : comparison;
            });
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterRentalsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    const isSorting = sortBy.includes(value);

    if (!isSorting) {
      // If not sorting, add the value to both arrays in ascending order
      setSortBy([value]);
      setUpArrow([value]);
    } else {
      // If already sorting, toggle the direction in upArrow array
      const index = upArrow.indexOf(value);
      const newUpArrow = index !== -1 ? [] : [value];

      // If sorting in descending order, add a hyphen to the value
      if (newUpArrow.length === 0) {
        newUpArrow[0] = `-${value}`;
      }

      setUpArrow(newUpArrow);
    }

    filterRentalsBySearchAndPage();
  };

  useEffect(() => {
    getRentalsData();
  }, [upArrow, sortBy]);

  const handleCheckboxChange = (event) => {
    setMapDisplay(event.target.checked);
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  return (
    <>
      <Header />
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          {(admin || permissions?.property_add) && (
            <Col xs="12" lg="12" sm="12" className="text-right">
              <Button
                className="btnaddstaff background-colorsty bgtextwhite fontfamilysty"
                onClick={() => {
                  // if (countRes.statusCode === 201) {
                  //   swal(
                  //     "Plan Limitation",
                  //     "The limit for adding properties according to the plan has been reached.",
                  //     "warning"
                  //   );
                  // } else {
                  navigate(
                    admin ? "/" + admin + "/rentals" : "/staff/staffpropertyadd"
                  );
                  // }
                }}
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Property
              </Button>
            </Col>
          )}
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className="mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Properties
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Row className="d-flex ml-0 mb-3 propertiesaddtotalsty">
                  <FormGroup
                    className="mr-sm-2 searchspace searchstyle"
                    style={{ paddingRight: "10px" }}
                  >
                    <Input
                      className="filterh titleecolor"
                      type="text"
                      placeholder="Search here..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                        minWidth: "200px",
                        fontWeight: "500",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                        border: "1px solid #ced4da",
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mr-sm-2">
                    <Dropdown isOpen={search} toggle={toggle3}>
                      <DropdownToggle
                        className="selecttypee typeselectprop titleecolor"
                        caret
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                          maxWidth: "200px",
                          minWidth: "200px",
                          backgroundColor: "transparent",
                        }}
                      >
                        {searchQuery2 ? searchQuery2 : "Select Type"}
                      </DropdownToggle>
                      <DropdownMenu className="dropdownmeunprop">
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Residential");
                          }}
                        >
                          Residential
                        </DropdownItem>
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("Commercial");
                          }}
                        >
                          Commercial
                        </DropdownItem>
                        <DropdownItem
                          className="searchcfiltertext"
                          onClick={() => {
                            setSearchQuery2("All");
                          }}
                        >
                          All
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Col className="d-flex justify-content-end addtotnstyle ">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle fontaddstyle"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.rentalCount}
                        </b>{" "}
                        {/* {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.propertyCountLimit}
                        </b> */}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>

                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{
                    borderCollapse: "collapse",
                  }}
                >
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Property{" "}
                        {sortBy.includes("rental_adress") ? (
                          upArrow.includes("rental_adress") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_adress")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property Types{" "}
                        {sortBy.includes("type") ? (
                          upArrow.includes("type") ? (
                            <ArrowDropUpIcon onClick={() => sortData("type")} />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("type")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon onClick={() => sortData("type")} />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property Sub Type{" "}
                        {sortBy.includes("property_type") ? (
                          upArrow.includes("property_type") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("property_type")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("property_type")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("property_type")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Rental Company Name{" "}
                        {sortBy.includes("rentalOwner_companyName") ? (
                          upArrow.includes("rentalOwner_companyName") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("rentalOwner_companyName")
                              }
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("rentalOwner_companyName")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rentalOwner_companyName")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Tenants
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Locality{" "}
                        {sortBy.includes("rental_city") ? (
                          upArrow.includes("rental_city") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_city")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_city")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_city")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue">
                        Last Updated{" "}
                        {sortBy.includes("updatedAt") ? (
                          upArrow.includes("updatedAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("updatedAt")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("updatedAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("updatedAt")}
                          />
                        )}
                      </th>

                      {(admin ||
                        permissions?.property_edit ||
                        permissions?.property_delete) && (
                        <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                          }}
                        >
                          ACTION
                        </th>
                      )}
                    </tr>
                  </thead>
                  {rentalsData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Properties Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterRentalsBySearchAndPage()?.map((Rental) => (
                        <tr
                          key={Rental.rental_id}
                          onClick={() =>
                            navigateToPropDetails(Rental.rental_id)
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental.rental_adress}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental?.property_type_data?.property_type}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental?.property_type_data?.propertysub_type}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental?.rental_owner_data?.rentalOwner_companyName}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental.tenants_data.length > 0 ? (
                              Rental.tenants_data.map((tenant, index) =>
                                index === 0 ? (
                                  <>
                                    {tenant.tenant_firstName +
                                      " " +
                                      tenant.tenant_lastName}
                                  </>
                                ) : (
                                  <>,
                                    <br />
                                    {tenant.tenant_firstName +
                                      " " +
                                      tenant.tenant_lastName}
                                  </>
                                )
                              )
                            ) : (
                              <span>{"-"}</span>
                            )}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">{`${Rental.rental_city}`}</td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {Rental?.updatedAt
                              ? moment(Rental.updatedAt).format(dateFormat)
                              : "-"}
                          </td>
                          {(admin ||
                            permissions?.property_edit ||
                            permissions?.property_delete) && (
                            <td className="bordertopintd tablebodyfont">
                              <div style={{ display: "flex", gap: "5px" }}>
                                {(admin || permissions?.property_delete) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      Rental?.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteRentals(
                                            Rental?.rental_id,
                                            Rental
                                          );
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {(admin || permissions?.property_edit) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      Rental?.admin_id === "is_trial"
                                        ? freetrialData()
                                        : editProperty(Rental?.rental_id);
                                    }}
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>

                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of{" "}
                        {Math.ceil(filterRentalsBySearch().length / pageItem)}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(filterRentalsBySearch().length / pageItem)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default PropertiesTables;
