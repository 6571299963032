import { FormGroup, Toolbar, Typography } from "@mui/material";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";
import { Button, Col, Container, Input, Row, Form } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function ChangeSecurityKey() {
  const [paymentLoader, setPaymentLoader] = React.useState(false);
  const [nmiKey, setNmiKey] = React.useState(null);
  const [key_id, setKeyId] = React.useState(null);
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [responseText, setResponseText] = React.useState(null);
  const [testLoader, setTestLoader] = React.useState(false);
  const [accessType, setAccessType] = React.useState();
  const [superAdminId, setSuperAdminId] = React.useState();
  const navigate = useNavigate();

  const [cookies] = useCookies(["token"]);
  React.useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/superadmin_token_check`,
          {
            token: cookies.token,
          }
        );
        setAccessType(response.data);
        setSuperAdminId(response.data.superadmin_id);
        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const nmikeyFormik = useFormik({
    initialValues: {
      security_key: "",
      public_key: "",
      admin_id: "",
      cc_number: "",
      cc_exp: "",
    },
    validationSchema: yup.object({
      security_key: yup.string().required("Required"),
      cc_number: yup.number().required("Required"),
      cc_exp: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleNmiKeySubmit(values);
    },
  });
  React.useEffect(() => {
    // let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/nmi-keys/superadmin-nmi-keys/${superAdminId}`)
      .then((response) => {
        const Data = response.data.data;
        setNmiKey(Data);
        setKeyId(Data.key_id);
        nmikeyFormik.setValues({
          security_key: Data.security_key || "",
          public_key: Data.public_key || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [superAdminId]);

  async function handleNmiKeySubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        role: "super_admin",
        admin_id: accessType.superadmin_id,
        security_key: values.security_key,
        public_key: values.public_key,
      };

      if (!key_id) {
        const res = await axiosInstance.post(`/nmi-keys/nmi-keys`, object);
        if (res.data.statusCode === 200) {
          toast.success("Security Key Added", {
            position: "top-center",
            autoClose: 800,
          });
          setTimeout(() => {
            navigate(`/superadmin/dashboard`);
          }, 2000);
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/nmi-keys/nmi-keys/${key_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Security Key Updated", {
            position: "top-center",
            autoClose: 800,
          });
          setTimeout(() => {
            navigate(`/superadmin/dashboard`);
          }, 2000);
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } 
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  async function handleTestKeyClick(values) {
    setTestLoader(true);
    try {
      const object = {
        first_name: accessType.first_name,
        last_name: accessType.last_name,
        email: accessType.email,
        security_key: values.security_key,
        cc_number: values.cc_number,
        cc_exp: values.cc_exp,
      };

      const res = await axiosInstance.post(`/nmipayment/test_sale`, {
        paymentDetails: object,
      });

      if (res.data.statusCode === 100) {
        // toast.success("Account Linked Successfully", {
        //   position: "top-center",
        //   autoClose: 700,
        //   onClose:()=> navigate('/superadmin/admin')
        // });
        setResponseText(res.data.message);
      } else if (res.data.statusCode === 200) {
        // toast.error("Something went wrong", {
        //   position: "top-center",
        //   autoClose: 1000,
        // });
        setResponseText(res.data.message);
      }

      // setResponseText(res.data.message);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setTestLoader(false);
    }
  }

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Toolbar
          className="border-top border-bottom adminbarsuperadmin"
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            color: "white", // Set the font color to white
            borderRadius: "10px",
            marginBottom: "18px",
            backgroundColor: "#152B51",
            border: "none",
          }}
        >
          <Typography
            className="barforsummaryrent"
            sx={{
              flex: "1 1 100%",
              color: "#fff",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "24px",
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Change Security Key
          </Typography>
        </Toolbar>
        <Form
          role="form"
          // onSubmit={handleSubmit}
          className="ml-3"
          style={{ width: "50%" }}
        >
          <div className="ml-3">
            {/* chnage start 8484 */}
            <Row className="mt-3">
              <Col lg="9">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr"
                    htmlFor="input-unitadd"
                    style={{
                      fontWeight: "600",
                      fontStyle: "14px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Public Key
                  </label>
                  <Input
                    style={{
                      fontWeight: "500",
                      fontStyle: "12px",
                      fontFamily: "Poppins",
                    }}
                    className="form-control-alternative fontstylerentmodal"
                    id="input-unitadd"
                    placeholder="Enter Public Key"
                    type="text"
                    name="public_key"
                    onBlur={nmikeyFormik.handleBlur}
                    onChange={nmikeyFormik.handleChange}
                    value={nmikeyFormik.values.public_key}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg="9">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr"
                    htmlFor="input-unitadd"
                    style={{
                      fontWeight: "600",
                      fontStyle: "14px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Security Key
                  </label>
                  <Input
                    style={{
                      fontWeight: "500",
                      fontStyle: "12px",
                      fontFamily: "Poppins",
                    }}
                    className="form-control-alternative fontstylerentmodal"
                    id="input-unitadd"
                    placeholder="Enter Security Key"
                    type="text"
                    name="security_key"
                    onBlur={nmikeyFormik.handleBlur}
                    onChange={nmikeyFormik.handleChange}
                    value={nmikeyFormik.values.security_key}
                  />
                  <div className="requiredstylefont" style={{ color: "red" }}>

                    {nmikeyFormik.touched.security_key && nmikeyFormik.errors.security_key && "Required"}
                  </div>
                </FormGroup>
              </Col>
            </Row>
              {/* chnage start 8484 */}
            <Row className="mt-4">
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr"
                    htmlFor="input-unitadd"
                    style={{
                      fontWeight: "600",
                      fontStyle: "14px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Test NMI Account
                  </label>
                  <p
                    className="fontstylerentmodal"
                    style={{
                      fontWeight: "500",
                      fontStyle: "12px",
                      fontFamily: "Poppins",
                      color: "#979797",
                    }}
                  >
                    To test whether the account system has been successfully
                    linked to the NMI account or not, please click on the button
                    that will initiate a $1.00 transaction to this security
                    key's account.
                  </p>
                  <Row className="mt-4">
                    <Col lg="5">
                      <FormGroup>
                        <label
                          style={{
                            fontWeight: "600",
                            fontStyle: "14px",
                            fontFamily: "Poppins",
                          }}
                          className="form-control-label fontstylerentr"
                          htmlFor="input-unitadd"
                        >
                          Card Number
                        </label>
                        <Input
                          style={{
                            fontWeight: "500",
                            fontStyle: "12px",
                            fontFamily: "Poppins",
                          }}
                          className="form-control-alternative fontstylerentmodal"
                          id="input-unitadd"
                          placeholder="Card Number"
                          type="number"
                          name="cc_number"
                          onBlur={nmikeyFormik.handleBlur}
                          onChange={nmikeyFormik.handleChange}
                          value={nmikeyFormik.values.cc_number}
                        />
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {nmikeyFormik.touched.cc_number &&
                            nmikeyFormik.errors.cc_number &&
                            "Required"}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          style={{
                            fontWeight: "600",
                            fontStyle: "14px",
                            fontFamily: "Poppins",
                          }}
                          className="form-control-label fontstylerentr"
                          htmlFor="input-unitadd"
                        >
                          Expiration Date
                        </label>
                        <Input
                          style={{
                            fontWeight: "500",
                            fontStyle: "12px",
                            fontFamily: "Poppins",
                          }}
                          className="form-control-alternative fontstylerentmodal"
                          id="input-unitadd"
                          placeholder="MM/YYYY"
                          type="text"
                          name="cc_exp"
                          onBlur={nmikeyFormik.handleBlur}
                          onChange={nmikeyFormik.handleChange}
                          value={nmikeyFormik.values.cc_exp}
                        />
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {nmikeyFormik.touched.cc_exp &&
                            nmikeyFormik.errors.cc_exp &&
                            "Required"}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      {testLoader ? (
                        <Button
                          style={{
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                          type="submit"
                          // color="warning"
                          className="btn btn-warning fontstylerentr"
                          disabled
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          // color="warning"
                          className="btn bg-warning fontstylerentr"
                          type="submit"
                          style={{
                            textTransform: "none",

                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                          disabled={
                            !nmikeyFormik.values.cc_number ||
                            !nmikeyFormik.values.cc_exp
                          }
                          onClick={(e) => {
                            handleTestKeyClick(nmikeyFormik.values);
                            e.preventDefault();
                          }}
                        >
                          Test Transaction
                        </Button>
                      )}
                    </Col>
                    <Col lg="12" className="mt-3">
                      <label
                        style={{
                          fontWeight: "500",
                          fontStyle: "12px",
                          fontFamily: "Poppins",
                          color: "#979797",
                        }}
                      >
                        {responseText}
                      </label>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="ml-3 mt-4">
            {paymentLoader ? (
              <Button
                disabled
                type="submit"
                className="fontstylerentr"
                style={{
                  backgroundColor: "#152B51",
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                }}
              >
                Loading
              </Button>
            ) : (
              <>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: nmikeyFormik.values.security_key
                      ? "#152B51"
                      : "#d6e1f5",
                    color: "white",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                  className="mr-2 fontstylerentr"
                  type="submit"
                  onClick={(e) => {
                    handleNmiKeySubmit(nmikeyFormik.values);
                    e.preventDefault();
                  }}
                  disabled={!nmikeyFormik.values.security_key}
                >
                  Save
                </Button>
              </>
            )}
            <Button
              className="fontstylerentr"
              style={{
                textTransform: "none",
                
                backgroundColor: "white",
                color: "#152B51",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
              onClick={() => navigate(`/superadmin/dashboard`)}
            >
              Cancel
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </Container>
    </>
  );
}
