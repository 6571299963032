// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 991 */
@media (max-width: 991px) {

    .applicantbtns {
      width: 144px !important;
      font-size: 13px !important;
      font-weight: 600 !important;
      height: 33px !important;
      padding: 3px !important;
      border-radius: 4px !important;
      }
}

`, "",{"version":3,"sources":["webpack://./src/views/source/applicants.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;;IAEI;MACE,uBAAuB;MACvB,0BAA0B;MAC1B,2BAA2B;MAC3B,uBAAuB;MACvB,uBAAuB;MACvB,6BAA6B;MAC7B;AACN","sourcesContent":["/* 991 */\n@media (max-width: 991px) {\n\n    .applicantbtns {\n      width: 144px !important;\n      font-size: 13px !important;\n      font-weight: 600 !important;\n      height: 33px !important;\n      padding: 3px !important;\n      border-radius: 4px !important;\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
