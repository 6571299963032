import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom/dist";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik/dist";
import * as Yup from "yup";

import { verifyToken } from "components/Functions/Functions";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import axiosInstance from "axiosInstance";
import TemplateEditor from "./TemplateEditor";
import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";

const eventTypes = {
  tenant: [
    {
      title: "Invitation",
      detail: "On tenant, vendor and staff-member creation",
    },
    { title: "Lease creation", detail: "" },
    { title: "Lease end Reminder", detail: "" },
    { title: "Payment reciept", detail: "" },
    { title: "Payment refund", detail: "" },
    { title: "LateFee reminder", detail: "" },
    { title: "Express Payment", detail: "" },
  ],
  lease: [
    {
      title: "Property assign",
      detail: "Assign staff-member to property",
    },
    { title: "Lease creation", detail: "" },
    { title: "Lease end Reminder", detail: "" },
    { title: "Payment reciept", detail: "" },
    { title: "Payment refund", detail: "" },
    { title: "LateFee reminder", detail: "" },
    { title: "Express Payment", detail: "" },
  ],
};

const Sendmails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();

  const [cookies, setCoockie, removeCookie] = useCookies();

  const [loader, setLoader] = useState(true);
  const [btnLoader, setbtnLoader] = useState(false);
  const [accessType, setAccessType] = useState(undefined);
  const [tenantsData, setTenantsData] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      if (data) {
        setAccessType(data);
      }

      if (location.state?.email_id) {
        try {
          const response = await axiosInstance.get(
            `/emails/get/${location.state.email_id}`
          );
          if (response.data.statusCode === 200) {
            emailFormik.setValues(response.data.email);
          }
        } catch (error) {
          console.error("Error fetching email: ", error);
        }
      }

      if (location.state?.selectedTenants) {
        setSelectedTenants(location.state?.selectedTenants);
      }

      if (data?.admin_id) {
        try {
          const response = await axiosInstance.get(
            `/tenant/lease-tenant/${data?.admin_id}`
          );
          if (response.data.statusCode) {
            if (location.state?.selectedTenants) {
              setTenantsData(
                response.data.data.filter((item) =>
                  location.state?.selectedTenants?.includes(item?.tenant_id)
                )
              );
            } else {
              setTenantsData(response.data.data);
            }
          }
        } catch (error) {
          console.error("Error fetching tenants data: ", error);
        }
      }

      setLoader(false);
    };

    fetchData();
  }, [location.state?.email_id, location.state?.selectedTenants]);

  const emailFormik = useFormik({
    initialValues: {
      subject: "",
      body: "",
      attachment: "",
      mail_type: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required!"),
      body: Yup.string().required("Body is required!"),
    }),
    onSubmit: async (values) => {
      setbtnLoader(true);
      try {
        var response = await axiosInstance.post("/email-logs", {
          ...values,
          admin_id: accessType.admin_id,
          lease_id: location.state?.lease_id || null,
          tenant_id: selectedTenants[0] || null,
          tenants: selectedTenants,
        });

        if (response.data.statusCode === 200) {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 800,
          });

          setTimeout(() => {
            navigate(admin ? `/${admin}/emails` : `/staff/emails`);
          }, 1000);
        } else {
          toast.error(response.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        console.error("Error: ", error);
      }
      setbtnLoader(false);
    },
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("select-all")) {
      if (selectedTenants.length === tenantsData.length) {
        setSelectedTenants([]);
      } else {
        setSelectedTenants(tenantsData.map((tenant) => tenant.tenant_id));
      }
    } else {
      setSelectedTenants(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleSelectAll = () => {
    if (selectedTenants.length === tenantsData.length) {
      setSelectedTenants([]);
    } else {
      setSelectedTenants(tenantsData.map((tenant) => tenant.tenant_id));
    }
  };

  const fetchTemplate = async () => {
    if (emailFormik.values.mail_type) {
      try {
        const response = await axiosInstance.get(
          `/templates/get/${accessType.admin_id}/${emailFormik.values.mail_type}`
        );

        if (response.data.statusCode === 200) {
          emailFormik.setFieldValue("subject", response.data.template.subject);
          emailFormik.setFieldValue("body", response.data.template.body);
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, [emailFormik.values.mail_type]);

  return (
    <>
      <Container
        className="stylecontainer pt-5"
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button
              className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                navigate(admin ? `/${admin}/emails` : `/staff/emails`);
              }}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Send New E-mail
              </h2>
            </CardHeader>
          </Col>
        </Row>

        {loader ? (
          <Loader />
        ) : (
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-2 mb-3"
                style={{
                  borderRadius: "10px",
                  border: "1px solid #324567",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <CardBody>
                  <Form onSubmit={emailFormik.handleSubmit}>
                    <Row className="mt-3">
                      <Col lg="4" xl="3" md="5" sm="5" xs="12">
                        <FormGroup className="d-flex flex-column">
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_id"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Tenants
                          </label>
                          <Select
                            labelId="user-select-label"
                            id="user-select"
                            multiple
                            value={selectedTenants}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return "Select tenants";
                              }
                              return selected
                                ?.map((id) => {
                                  const user = tenantsData.find(
                                    (user) => user.tenant_id === id
                                  );
                                  return user
                                    ? `${user?.tenant_firstName || ""} ${
                                        user?.tenant_lastName || ""
                                      }`
                                    : "";
                                })
                                .join(", ");
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                  width: "200px",
                                },
                              },
                            }}
                          >
                            <MenuItem
                              key="select-all"
                              value="select-all"
                              onClick={handleSelectAll}
                            >
                              <Checkbox
                                checked={
                                  selectedTenants.length === tenantsData.length
                                }
                              />
                              <ListItemText primary="Select All" />
                            </MenuItem>

                            {tenantsData.length > 0 &&
                              tenantsData.map((item) => (
                                <MenuItem
                                  key={item.tenant_id}
                                  value={item.tenant_id}
                                >
                                  <Checkbox
                                    checked={
                                      selectedTenants.indexOf(item.tenant_id) >
                                      -1
                                    }
                                  />
                                  <ListItemText
                                    primary={`${item?.tenant_firstName || ""} ${
                                      item?.tenant_lastName || ""
                                    }`}
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormGroup>
                      </Col>
                      <Col lg="4" xl="3" md="5" sm="5" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="template-subject"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Subject
                          </label>
                          <Input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="template-subject"
                            placeholder="Subject"
                            type="text"
                            name="subject"
                            autoComplete="off"
                            onBlur={emailFormik.handleBlur}
                            onChange={emailFormik.handleChange}
                            value={emailFormik.values.subject}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!location.state?.lease_id ? (
                      <Row className="mt-3">
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <FormGroup className="d-flex flex-column">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty mb-2"
                              htmlFor="email-type"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Event Type
                            </label>

                            <Select
                              labelId="user-select-label"
                              id="user-select"
                              value={emailFormik.values.mail_type}
                              onChange={(e) => {
                                if (e.target.value) {
                                  emailFormik.setFieldValue(
                                    "mail_type",
                                    e.target.value
                                  );
                                } else {
                                  emailFormik.setFieldValue("mail_type", "");
                                }
                              }}
                              displayEmpty
                              renderValue={(selected) => {
                                return selected || "Select Event";
                              }}
                            >
                              {eventTypes?.tenant.length > 0 &&
                                eventTypes?.tenant?.map((item, index) => (
                                  <MenuItem key={index} value={item.title}>
                                    <ListItemText primary={item?.title || ""} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mt-3">
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <FormGroup className="d-flex flex-column">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty mb-2"
                              htmlFor="email-type"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Event Type
                            </label>

                            <Select
                              labelId="user-select-label"
                              id="user-select"
                              value={emailFormik.values.mail_type}
                              onChange={(e) => {
                                if (e.target.value) {
                                  emailFormik.setFieldValue(
                                    "mail_type",
                                    e.target.value
                                  );
                                } else {
                                  emailFormik.setFieldValue("mail_type", "");
                                }
                              }}
                              displayEmpty
                              renderValue={(selected) => {
                                return selected || "Select Event";
                              }}
                            >
                              {eventTypes?.lease.length > 0 &&
                                eventTypes?.lease?.map((item, index) => (
                                  <MenuItem key={index} value={item.title}>
                                    <ListItemText primary={item?.title || ""} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <Row className="mt-3">
                      <Col>
                        <TemplateEditor
                          data={emailFormik.values.body}
                          setData={(value) => {
                            emailFormik.setFieldValue("body", value);
                          }}
                          setLoader={setLoader}
                          isToolTip={true}
                          type={emailFormik.values.mail_type}
                        />
                      </Col>
                    </Row>

                    <div
                      style={{ display: "flex", gap: "10px" }}
                      className="mt-3"
                    >
                      {btnLoader ? (
                        <Loader />
                      ) : (
                        <BaseButton
                          id="payButton"
                          type="submit"
                          disabled={false}
                          label="Send"
                        />
                      )}
                      <BaseButton
                        onClick={() =>
                          navigate(admin ? `/${admin}/emails` : `/staff/emails`)
                        }
                        variant="outline"
                        label="Cancel"
                      />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <ToastContainer />
    </>
  );
};

export default Sendmails;
