import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Media,
  FormGroup,
  Row,
  Col,
  Button,
  InputGroup,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useCookies } from "react-cookie";
import notify from "../../assets/icons/notify.svg";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axiosInstance from "axiosInstance";
import Notificationmodal from "../Notificationmodal";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { faSearch, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import { verifyToken } from "components/Functions/Functions";
import Loader from "components/ReusableComponents/Loader";
import { handleLogout } from "plugins/helpers";

const StaffNavbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [currentPlan, setCurrentPlan] = useState("");
  const [userroleData, setUserroleData] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
      if (data) {
        try {
          const res = await axiosInstance.get(
            `/purchase/plan-purchase/${data.admin_id}`
          );
          if (res.data.data) {
            setCurrentPlan(res.data.data.plan_detail.plan_name);
          }
        } catch (error) {
          console.log("Error: ", error);
        } finally {
          setLoader(false);
        }
      }
    };
    fetchData();
  }, []);

  const Logout = () => {
    handleLogout(cookies, removeCookie);
  };

  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [selectedProp, setSelectedProp] = useState("Select");

  const handlePropertySelect = (property) => {
    setSelectedProp(property);
  };

  const [staffMemberNotification, setStaffMemberNotification] = useState([]);
  const staffmemberNotificationData = async () => {
    if (accessType?.staffmember_id) {
      try {
        const response = await axiosInstance.get(
          `/notification/staff/${accessType?.staffmember_id}`
        );
        if (response.status === 200) {
          const data = response.data.data;
          if (Array.isArray(data)) {
            const unreadNotifications = data.filter(
              (notification) => !notification.is_staffmember
            );
            setStaffMemberNotification(data);
            setNotificationData(unreadNotifications);
            setNotificationCount(unreadNotifications.length);
          }
        } else {
          console.error("Response status is not 200");
        }
      } catch (error) {
        console.warn("Error:", error);
        // Handle the error, display a message to the user, or take other appropriate action.
      }
    }
  };

  useEffect(() => {
    staffmemberNotificationData();
  }, [accessType?.staffmember_id]);

  const readStaffmemberNotification = async (notification_id) => {
    try {
      const response = await axiosInstance.put(
        `/notification/staff_notification/${notification_id}`
      );
      if (response.status === 200) {
        staffmemberNotificationData();
        // Process the data as needed
      } else {
        console.error("Response status is not 200");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const fetchTenants = async (adminId, query) => {
    try {
      const response = await axiosInstance.get(
        `/tenant/tenantsearch/${adminId}`,
        {
          params: { search: query },
        }
      );
      return Array.isArray(response.data.data) ? response.data.data : [];
    } catch (error) {
      console.error("Error fetching tenants:", error);
      return [];
    }
  };

  const handleSearch = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }
    if (query.length >= 3) {
      const tenants = await fetchTenants(accessType?.admin_id, query);
      setSearchResults(Array.isArray(tenants) ? tenants : []);
      setShowDropdown(true);
    } else {
      setSearchResults([]);
      setShowDropdown(false);
    }
  };

  const handleInputChange = (newValue) => {
    setSearchQuery(newValue);
    handleSearch(newValue);
  };

  const customOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps}>
      <div
        className="d-flex justify-content-between"
        style={{ margin: "15px" }}
      >
        {userroleData?.tenant_view && (
          <div>
            <FontAwesomeIcon
              icon={faCircleUser}
              style={{ width: "18px", height: "18px" }}
            />{" "}
            &nbsp;
            <Link
              style={{
                fontWeight: "800",
                color: "#5A86D5",
                fontFamily: "Poppins",
              }}
              to={"/staff/stafftenantdetail/" + data.tenant_id}
              onClick={(e) => {}}
            >
              {data.tenant_firstName} {data.tenant_lastName}
            </Link>
          </div>
        )}

        <div>
          {userroleData?.lease_view && (
            <>
              {data?.leaseDetails?.lease_id && (
                <span
                  className="lease-link"
                  onClick={(e) => {
                    navigate(
                      `/staff/staffrentrolldetail/${data?.leaseDetails?.lease_id}`,
                      { state: "financial" }
                    );
                  }}
                >
                  Lease Ledger
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );

  const customDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faSearch} />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Navbar
        className="navbar-top navbar-dark px-md-5 px-3"
        id="navbar-main"
        style={{
          backgroundColor: isMediumScreen && "rgba(249, 250, 253, 1)",
          boxShadow: isMediumScreen && "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
        }}
      >
        {!userroleData?.lease_view && !userroleData?.tenant_view ? (
          <div className="h4 d-none d-lg-inline-block">
            Hello {accessType?.staffmember_name}, Welcome Back!
          </div>
        ) : (
          <InputGroup style={{ width: "400px" }} className="d-none d-lg-flex">
            <div style={{ width: "400px" }}>
              <Select
                value={null}
                inputValue={searchQuery}
                onInputChange={(newValue) => handleInputChange(newValue)}
                options={searchResults}
                getOptionLabel={(tenant) =>
                  `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
                }
                noOptionsMessage={() => null}
                getOptionValue={(tenant) => tenant.tenant_id}
                components={{
                  DropdownIndicator: customDropdownIndicator,
                  Option: customOption,
                }}
                placeholder="Search tenants..."
                onFocus={() => setShowDropdown(true)}
                onBlur={() => setShowDropdown(false)}
                isClearable
              />
            </div>
          </InputGroup>
        )}

        <Form className="navbar-search navbar-search-dark form-inline mr-5 mt-3 d-none d-lg-flex ml-lg-auto">
          <FormGroup
            className="mb-1 mr-3"
            onClick={toggleSidebar}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {staffMemberNotification?.length === 0 ? (
              <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
            ) : (
              <img src={notify} width={30} height={30} />
            )}
          </FormGroup>
        </Form>

        <Nav className="align-items-center d-none d-lg-flex" navbar>
          <Drawer
            className="adminnavv"
            anchor="right"
            open={isSidebarOpen}
            onClose={toggleSidebar}
          >
            <div
              role="presentation"
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
            >
              <List
                className="notificationtextbar"
                style={{ width: "350px", border: "none" }}
              >
                <Divider />
                <div>
                  <h2
                    className="fonteightteen background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      marginLeft: "15px",
                      borderRadius: "10px, 0px, 0px, 10px",
                      fontWeight: "600",
                      marginTop: "-9px",
                      padding: "18px",
                      marginLeft: "0",
                    }}
                  >
                    Notifications
                    <i
                      style={{ marginLeft: "150px", cursor: "pointer" }}
                      className="fa-solid fa-xmark"
                      onClick={toggleSidebar}
                    />
                  </h2>
                </div>
                {staffMemberNotification?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    No Notification
                  </div>
                ) : (
                  <>
                    {staffMemberNotification?.map((data) => {
                      const notificationTitle =
                        data?.notification_title || "No Title Available";
                      const notificationDetails =
                        data?.notification_detail || "No Details Available";
                      const notificationTime = new Date(
                        data?.createdAt
                      ).toLocaleString();

                      return (
                        <div key={data._id}>
                          <ListItem onClick={() => handlePropertySelect(data)}>
                            <div>
                              <h4
                                className="barforsummaryrent textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "600",
                                }}
                              >
                                {notificationTitle}
                              </h4>
                              <p
                                className="notificationtitle titleecolor fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                {notificationDetails}
                              </p>
                              <Row>
                                <Col lg="8">
                                  <p
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {notificationTime}
                                  </p>
                                </Col>
                                <Col>
                                  <Button
                                    className="fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                                    variant="contained"
                                    style={{
                                      textTransform: "none",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      readStaffmemberNotification(
                                        data?.notification_id
                                      );
                                      if (data.is_workorder) {
                                        navigate(
                                          `/staff/staffworkdetails/${data?.notification_type?.workorder_id}`
                                        );
                                      } else if (data.is_rental) {
                                        navigate(
                                          `/staff/staffpropertydetail/${data?.rental_id}`
                                        );
                                      }
                                    }}
                                  >
                                    View
                                  </Button>
                                </Col>
                              </Row>
                            </div>

                            {/* <ListItemText
                          primary={notificationTitle}
                          secondary={notificationTime}
                        />
                        <ListItemText
                          primary={notificationDetails}
                          secondary="Notification Details"
                        /> */}
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    })}
                  </>
                )}
              </List>
              <Divider />
              {/* Other sidebar content goes here */}
            </div>
          </Drawer>
        </Nav>

        <Nav className="align-items-center d-none d-lg-flex" navbar>
          <UncontrolledDropdown
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
            }}
          >
            <DropdownToggle
              className="px-4"
              style={{
                border: "none",
                background: "rgba(54, 159, 255, 0.1)",
                boxShadow: "none",
              }}
            >
              <Media className="align-items-center">
                <span
                  className="d-flex justify-content-center align-items-center background-colorsty bgtextwhite
 fontfamilysty"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "12px",
                  }}
                >
                  {loader ? (
                    <Loader color="#ffffff" />
                  ) : (
                    `${accessType?.staffmember_name
                      ?.split(" ")
                      .map((word) => word.charAt(0))
                      .join("")}`
                  )}
                </span>
                <Media className="ml-3 d-none d-lg-flex flex-column mx-1">
                  <span
                    className="mb-0 font-weight-bold text-dark"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Manrope",
                    }}
                  >
                    {accessType?.staffmember_name || ""}
                  </span>
                  <span
                    className="mb-0 font-weight-bold"
                    style={{
                      fontSize: "12px",
                      fontFamily: "Manrope",
                    }}
                  >
                    Staff Member
                  </span>
                </Media>
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  <i className="fa-solid fa-angle-down"></i>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow w-100" right>
              <DropdownItem className="noti-title w-100" header tag="div">
                <h6
                  className="text-overflow m-0"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  Welcome
                </h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/staff/PasswordChange"
                tag={Link}
              >
                <i className="ni ni-key-25" />
                <span>Change Password</span>
              </DropdownItem>
              {userroleData?.setting_view && (
                <>
                  {currentPlan !== "Free Plan" && (
                    <DropdownItem
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        marginLeft: "35px",
                      }}
                      className="text-overflow m-0"
                      onClick={() => navigate("/staff/staffsetting")}
                    >
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                  )}
                </>
              )}

              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/auth/login"
                onClick={() => {
                  Logout();
                }}
                tag={Link}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <div className="d-flex justify-content-center d-lg-none align-items-center">
          <FormGroup
            className="mb-1 menub"
            onClick={props.toggleOpenSidebar}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <MenuIcon className="menub" style={{ width: 40, height: 40 }} />
          </FormGroup>
          <FormGroup
            className="mb-1 ml-3"
            onClick={() => navigate(`/vendor/VendordashBoard`)}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <img src={props.logo} width={50} height={40} />
          </FormGroup>
        </div>
        <div className="d-flex justify-content-center d-lg-none align-items-center">
          <FormGroup
            className="mb-1"
            onClick={handleClick}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {notificationCount === 0 ? (
              <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
            ) : (
              <img src={notify} width={30} height={30} />
            )}
          </FormGroup>
          <UncontrolledDropdown
            style={{ border: "none", background: "none", boxShadow: "none" }}
          >
            <DropdownToggle
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              className="m-0 p-0 ml-3"
            >
              <Media className="align-items-center">
                <span
                  className="d-flex justify-content-center align-items-center background-colorsty bgtextwhite
fontfamilysty"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "12px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {/* {`${accessType?.staffmember_name
                    ?.slice(0, 1)
                    .toUpperCase()}${accessType?.staffmember_name
                      ?.slice(1, 2)
                      .toUpperCase()}`} */}
                  {loader ? (
                    <Loader color="#ffffff" />
                  ) : (
                    `${accessType?.staffmember_name
                      ?.split(" ")
                      .map((word) => word.charAt(0))
                      .join("")}`
                  )}
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title w-100" header tag="div">
                <h6
                  className="text-overflow m-0"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  Welcome
                </h6>
              </DropdownItem>
              {userroleData?.setting_view && (
                <>
                  {currentPlan !== "Free Plan" && (
                    <DropdownItem
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        marginLeft: "35px",
                      }}
                      className="text-overflow m-0"
                      onClick={() => navigate("/staff/staffsetting")}
                    >
                      <i className="ni ni-settings-gear-65" />
                      <span>Settings</span>
                    </DropdownItem>
                  )}
                </>
              )}
              <DropdownItem divider />
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/auth/login"
                onClick={() => {
                  Logout();
                }}
                tag={Link}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "400px", borderRadius: "20px" }}
        >
          <ModalHeader
            toggle={closeModal}
            className=" ml-2 mr-3 mt-2 p-0 pl-3 pr-3 pt-3 textcolorblue fontfamilysty"
            // style={{
            //   borderRadius: "6px",
            //   height: "45px",
            //   boxShadow: " 0px 4px 4px 0px #00000040 ",
            //   padding: "10px 7px 1px 21px",
            //   color: "white",
            // }}
          >
            <h5
              style={{
                fontSize: "18px",
                fontweight: "500",
              }}
            >
              Notification
            </h5>
          </ModalHeader>
          <ModalBody>
            <Notificationmodal
              notifications={staffMemberNotification}
              handlePropertySelect={handlePropertySelect}
              readNotification={readStaffmemberNotification}
              role={`staffmember`}
              closeModal={closeModal}
            />
          </ModalBody>
        </Modal>
      </Navbar>
    </>
  );
};

export default StaffNavbar;
