import Img from "../../assets/img/theme/site-logo.png";

// pdf
import jsPDF from "jspdf";
import "jspdf-autotable";

// excel
import * as XLSX from "xlsx-js-style";

// save excels and csv
import { saveAs } from "file-saver";
import moment from "moment";

const generatePDF = (reportData, userProfile) => {
  const doc = new jsPDF();

  const img1Width = 15;
  const img1Height = 13;
  doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

  const titleYPosition = 10 + 10;
  doc.setFontSize(20);
  doc.text(
    "Payment Exception Report",
    doc.internal.pageSize.getWidth() / 2,
    titleYPosition,
    null,
    null,
    "center"
  );

  const subtitleYPosition = titleYPosition + 10;
  doc.setFontSize(12);
  doc.text(
    `Date: ${new Date().toISOString().split("T")[0]}`,
    doc.internal.pageSize.getWidth() / 2,
    subtitleYPosition,
    null,
    null,
    "center"
  );

  const contactInfo = [
    userProfile?.company_name || "",
    userProfile?.company_address || "",
    [userProfile?.city, userProfile?.state, userProfile?.country]
      .filter(Boolean)
      .join(", "),
    userProfile?.postal_code || "",
  ]
    .filter(Boolean)
    .join("\n");
  doc.setFontSize(10);
  const contactInfoLines = contactInfo.split("\n");
  const topMargin = 20;
  const lineHeight = 4;

  contactInfoLines.forEach((line, index) => {
    doc.text(
      line,
      doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
      topMargin + index * lineHeight
    );
  });

  const tableYPosition = subtitleYPosition + 20;

  const tableRows = reportData?.map((transaction) => {
    return [
      {
        content:
          (transaction.rental_data?.rental_adress || "") +
          (transaction.unit_data?.rental_unit
            ? " - " + transaction.unit_data?.rental_unit
            : ""),
        styles: { borderBottom: "none", fontStyle: "bold", fontSize: 9 },
      },
      {
        content: transaction?.payment_type || "N/A",
        styles: { borderBottom: "none", fontStyle: "bold", fontSize: 9 },
      },

      {
        content:
          transaction?.entry[0]?.date || "N/A"
            ? moment(transaction?.entry[0]?.date).format("YYYY-MM-DD")
            : "-",
        styles: { borderBottom: "none", fontStyle: "bold", fontSize: 9 },
      },
      {
        content: transaction?.check_number || "N/A",
        styles: { borderBottom: "none", fontStyle: "bold", fontSize: 9 },
      },
      {
        content:
          `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction.total_amount)}` || "0.00",
        styles: {
          borderBottom: "none",
          fontStyle: "bold",
          halign: "right",
          fontSize: 9,
        },
      },
    ];
  });

  const tableEntry = reportData?.map((transaction) => {
    var nestedDetails = (transaction?.entry || [])
      .map((entry) => entry?.account)
      .join("\n");

    var nestedDetailsAmount = (transaction?.entry || [])
      ?.map((entry) => {
        const amount =
          entry?.amount !== undefined && entry?.amount !== null
            ? entry?.amount
            : "$0.00";
        return `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount)}`;
      })
      .join("\n");

    const detailsRow = [
      {
        content: `${nestedDetails}`,
        colSpan: 4,
        styles: {
          halign: "left",
          cellPadding: { top: 1, bottom: 5, left: 15, right: 0 },
          borderTop: 0,
          fontSize: 9,
        },
        border: 0,
      },
      {
        content: `${nestedDetailsAmount}`,
        styles: {
          halign: "right",
          cellPadding: { top: 1, bottom: 5, left: 0, right: 2 },
          borderTop: 0,
          fontSize: 9,
        },
        border: 0,
      },
    ];

    return detailsRow;
  });

  const result = [];
  const maxLength = Math.max(tableRows?.length, tableEntry?.length);

  for (let i = 0; i < maxLength; i++) {
    if (tableRows[i] !== undefined) result.push(tableRows[i]);
    if (tableEntry[i] !== undefined) result.push(tableEntry[i]);
  }

  const totalAmount = reportData.reduce((sum, t) => sum + t.total_amount, 0);

  const totalRow = [
    {
      content: `TOTAL: $${
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalAmount) || "0.00"
      }`,
      colSpan: 5,
      styles: {
        halign: "right",
        fontStyle: "bold",
        fontSize: 13,
      },
    },
  ];

  result.push(totalRow);

  doc.autoTable({
    startY: tableYPosition,
    head: [
      [
        {
          content: "Property",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Payment Type",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Date",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Check Number",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Total",
          styles: {
            halign: "right",
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
      ],
    ],
    headStyles: {
      fillColor: [90, 134, 213],
      textColor: [255, 255, 255],
      fontSize: 11,
      fontStyle: "bold",
    },
    body: result,
    theme: "plain",
    styles: { fontSize: 10, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 35 },
      2: { cellWidth: 30 },
      3: { cellWidth: 35 },
      4: { cellWidth: 35 },
    },
  });

  const lineYPosition1 = doc.autoTable.previous.finalY - 10;

  // Ensure the line positions are valid
  if (lineYPosition1 > 10) {
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.5);
    doc.line(
      15,
      lineYPosition1,
      doc.internal.pageSize.getWidth() - 13,
      lineYPosition1
    );
  } else {
    console.error("The lines are positioned outside the page bounds.");
  }

  const pageCount = doc.getNumberOfPages();
  const currentDate = new Date();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${moment(currentDate).format("YYYY-MM-DD HH:ss:mm")}`,
      10,
      doc.internal.pageSize.getHeight() - 8
    );
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 10,
      doc.internal.pageSize.getHeight() - 8,
      { align: "right" }
    );
  }
  doc.save(`Payment_exception_report.pdf`);
};

const generateExcel = (reportData, userProfile) => {
  const wb = XLSX.utils.book_new();

  const uniqueAccounts = new Set();
  reportData.forEach((transaction) => {
    transaction.entry?.forEach((entry) => {
      uniqueAccounts.add(entry.account);
    });
  });

  const accountHeaders = Array.from(uniqueAccounts);

  const worksheetHeader = [
    "Property",
    "Payment Type",
    "Date",
    "Check Number",
    ...accountHeaders,
    "Total",
  ];

  const worksheetData = [
    worksheetHeader,
    ...reportData
      .filter((transaction) => transaction.is_delete === false)
      .map((transaction) => {
        const accountAmounts = accountHeaders.map((account) => {
          const entry = transaction.entry?.find((e) => e.account === account);
          return entry
            ? `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry?.amount)}`
            : "0";
        });

        return [
          (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          transaction?.payment_type || "N/A",
          transaction?.entry[0]?.date || "N/A"
            ? moment(transaction?.entry[0]?.date).format("YYYY-MM-DD")
            : "-",
          transaction?.check_number || "N/A",
          ...accountAmounts,
          {
            v:
              `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(transaction.total_amount)}` || "0.00",
            s: { alignment: { horizontal: "right" } },
          },
        ];
      }),
  ];

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);

  const wscols = worksheetHeader.map((header) => {
    return { wch: Math.max(header?.length + 5, 20) };
  });
  ws["!cols"] = wscols;

  const headerStyle = {
    font: { bold: true, color: { rgb: "FFFFFF" }, sz: "14" },

    border: {
      top: { style: "thin", color: "000000" },
      bottom: { style: "thin", color: "000000" },
      left: { style: "thin", color: "000000" },
      right: { style: "thin", color: "000000" },
    },
    alignment: { horizontal: "center" },
    fill: { fgColor: { rgb: "5A86D7" } },
  };

  worksheetHeader.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
    ws[cellAddress].s = headerStyle;
  });

  Object.keys(ws).forEach((cellAddress) => {
    if (ws[cellAddress].s) {
      ws[cellAddress].s.alignment = {
        wrapText: true,
        vertical: "center",
        horizontal: ws[cellAddress].s.alignment?.horizontal || "left",
      };
    }
  });

  reportData.forEach((_, rowIndex) => {
    accountHeaders.forEach((_, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({
        r: rowIndex + 1,
        c: worksheetHeader.indexOf(accountHeaders[colIndex]),
      });
      if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
      ws[cellAddress].s = {
        alignment: {
          horizontal: "right",
          vertical: "center",
          wrapText: true,
        },
      };
    });

    const totalAmountCellAddress = XLSX.utils.encode_cell({
      r: rowIndex + 1,
      c: worksheetHeader?.length - 1,
    });
    if (!ws[totalAmountCellAddress]) ws[totalAmountCellAddress] = { t: "s" };
    ws[totalAmountCellAddress].s = {
      alignment: {
        horizontal: "right",
        vertical: "center",
        wrapText: true,
      },
    };
  });

  XLSX.utils.book_append_sheet(wb, ws, "Payment Exception Report");

  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    `Payment_exception_report.xlsx`
  );
};

const generateCSV = (reportData, userProfile) => {
  const uniqueAccounts = new Set();
  reportData.forEach((transaction) => {
    transaction.entry?.forEach((entry) => {
      uniqueAccounts.add(entry.account);
    });
  });

  const accountHeaders = Array.from(uniqueAccounts);

  const csvHeader = [
    "Property",
    "Payment Type",
    "Date",
    "Check Number",
    ...accountHeaders,
    "Total",
  ];

  const formatCSVCell = (cell) => {
    if (typeof cell === "string") {
      return `"${cell.replace(/"/g, '""')}"`;
    }
    return cell === null || cell === undefined ? "" : cell.toString();
  };

  const csvData = [
    csvHeader.map(formatCSVCell).join(","),
    ...reportData
      .filter((transaction) => transaction.is_delete === false)
      .map((transaction) => {
        const accountAmounts = accountHeaders.map((account) => {
          const entry = transaction?.entry?.find((e) => e.account === account);
          return entry ? `$${entry.amount}` : "-";
        });

        return [
          (transaction.rental_data?.rental_adress || "") +
            (transaction.unit_data?.rental_unit
              ? " - " + transaction.unit_data?.rental_unit
              : ""),
          transaction?.payment_type || "N/A",
          transaction?.entry[0]?.date || "N/A"
            ? moment(transaction?.entry[0]?.date).format("YYYY-MM-DD")
            : "-",
          transaction?.check_number || "N/A",
          ...accountAmounts,
          `$${transaction?.total_amount?.toFixed(2) || 0.0}`,
        ]
          .map(formatCSVCell)
          .join(",");
      }),
  ].join("\n");

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `Payment_exception_report.csv`);
};

export { generatePDF, generateExcel, generateCSV };
