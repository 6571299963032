import axiosInstance from "axiosInstance";
import { Cookies } from "react-cookie";

const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token;
};

const getId = () => {
  const cookies = new Cookies();
  const id = cookies.get("ID");
  return id;
};

const verifyToken = async (navigate, location, removeCookie, companyName) => {
  try {
    const token = await getToken();
    const id = await getId();

    if (!token || !id) {
      navigate("/auth/login");
    }

    const response = await axiosInstance.post("/auth", { is_web: true });

    if (response.data?.role) {
      const layout = location.pathname.split("/")[1];
      if (layout === response.data?.role) {
        return response.data;
      } else if (response.data?.role === "admin") {
        if (companyName === response.data.company_name) {
          return response.data;
        } else {
          navigate(`/${response.data.company_name}/index`);
          return;
        }
      } else if (response.data?.role === "super_admin") {
        if (layout === "superadmin") {
          return response.data;
        } else {
          navigate(`/superadmin/dashboard`);
          return;
        }
      } else {
        if (response.data?.role) {
          navigate(`/${response.data.role}`);
          return;
        } else {
          removeCookie("token", { path: "/" });
          removeCookie("ID", { path: "/" });
          navigate("/auth/login");
          return;
        }
      }
    } else {
      removeCookie("token", { path: "/" });
      removeCookie("ID", { path: "/" });
      navigate("/auth/login");
      return;
    }
  } catch (error) {
    console.error("Error: ", error.message);
    removeCookie("token", { path: "/" });
    removeCookie("ID", { path: "/" });
    navigate("/auth/login");
    return;
  }
};

export { verifyToken };
