import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Card, Row, Col } from "reactstrap";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "axiosInstance";
import moment from "moment";
import { error } from "pdf-lib";
import * as yup from "yup";
import { useFormik, Form, Field, ErrorMessage } from "formik";
import "./applicants.css";
import siteLogo from "../../assets/icons/site-logo-1 7.png";
import Swal from "sweetalert2";

const ApplicantForm = ({ applyId, setShowApplicantForm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, admin } = useParams();

  useEffect(() => {
    const sidenavMain = document.getElementById("sidenav-main");
    const headerElement = document.querySelector(".header");
    if (sidenavMain) {
      sidenavMain.style.display = "none";
    }
    if (headerElement) {
      headerElement.style.display = "none";
    }
    return () => {
      if (sidenavMain) {
        sidenavMain.style.display = "block";
      }
      if (headerElement) {
        headerElement.style.display = "block";
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/applicant/applicant_details/${id}`
          );
          const data = response.data.data || {};

          applicantFormik.setValues({
            applicant_id: data.applicant_id || "",
            admin_id: data.admin_id || "",
            applicant_firstName: data.applicant_firstName || "",
            applicant_lastName: data.applicant_lastName || "",
            applicant_email: data.applicant_email || "",
            applicant_phoneNumber: data.applicant_phoneNumber || "",
            applicant_birthDate: data.applicant_birthDate || "",
            applicant_streetAddress: data.applicant_streetAddress || "",
            applicant_city: data.applicant_city || "",
            applicant_state: data.applicant_state || "",
            applicant_country: data.applicant_country || "",
            applicant_postalCode: data.applicant_postalCode || "",
            agreeBy: data.agreeBy || "",
            agreeto: data.agreeto || "",
            emergency_contact: {
              first_name: data.emergency_contact?.first_name || "",
              last_name: data.emergency_contact?.last_name || "",
              relationship: data.emergency_contact?.relationship || "",
              email: data.emergency_contact?.email || "",
              phone_number: data.emergency_contact?.phone_number || "",
            },
            rental_history: {
              rental_adress: data.rental_history?.rental_adress || "",
              rental_city: data.rental_history?.rental_city || "",
              rental_state: data.rental_history?.rental_state || "",
              rental_country: data.rental_history?.rental_country || "",
              rental_postcode: data.rental_history?.rental_postcode || "",
              start_date: data.rental_history?.start_date || "",
              end_date: data.rental_history?.end_date || "",
              rent: data.rental_history?.rent || "",
              leaving_reason: data.rental_history?.leaving_reason || "",
              rentalOwner_firstName:
                data.rental_history?.rentalOwner_firstName || "",
              rentalOwner_lastName:
                data.rental_history?.rentalOwner_lastName || "",
              rentalOwner_primaryEmail:
                data.rental_history?.rentalOwner_primaryEmail || "",
              rentalOwner_phoneNumber:
                data.rental_history?.rentalOwner_phoneNumber || "",
            },
            employment: {
              name: data.employment?.name || "",
              streetAddress: data.employment?.streetAddress || "",
              city: data.employment?.city || "",
              state: data.employment?.state || "",
              country: data.employment?.country || "",
              postalCode: data.employment?.postalCode || "",
              employment_primaryEmail:
                data.employment?.employment_primaryEmail || "",
              employment_phoneNumber:
                data.employment?.employment_phoneNumber || "",
              employment_position: data.employment?.employment_position || "",
              supervisor_firstName: data.employment?.supervisor_firstName || "",
              supervisor_lastName: data.employment?.supervisor_lastName || "",
              supervisor_title: data.employment?.supervisor_title || "",
            },
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [id]);

  const [formData, setFormData] = useState({
    applicant_id: "",
    admin_id: "",

    applicant_firstName: "",
    applicant_lastName: "",
    applicant_email: "",
    applicant_phoneNumber: "",
    applicant_birthDate: "",
    applicant_streetAddress: "",
    applicant_city: "",
    applicant_state: "",
    applicant_country: "",
    applicant_postalCode: "",
    agreeBy: "",
    agreeto: "",

    emergency_contact: {
      first_name: "",
      last_name: "",
      relationship: "",
      email: "",
      phone_number: "",
    },

    rental_history: {
      rental_adress: "",
      rental_city: "",
      rental_state: "",
      rental_country: "",
      rental_postcode: "",
      start_date: "",
      end_date: "",
      rent: "",
      leaving_reason: "",
      rentalOwner_firstName: "",
      rentalOwner_lastName: "",
      rentalOwner_primaryEmail: "",
      rentalOwner_phoneNumber: "",
    },

    employment: {
      name: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      employment_primaryEmail: "",
      employment_phoneNumber: "",
      employment_position: "",
      supervisor_firstName: "",
      supervisor_lastName: "",
      supervisor_title: "",
    },
  });


  const [formErrors, setFormErrors] = useState();

  const validateForm = () => {
    let errors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  };

  const handleSubmit = async (values, { setSubmitting, setErrors, setTouched }) => {
    setSubmitting(true);

    try {
      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        if (typeof values[key] === "string") {
          acc[key] = values[key].trim();
        } else if (typeof values[key] === "object" && values[key] !== null) {
          acc[key] = Object.keys(values[key]).reduce((subAcc, subKey) => {
            subAcc[subKey] = typeof values[key][subKey] === "string" ? values[key][subKey].trim() : values[key][subKey];
            return subAcc;
          }, {});
        } else {
          acc[key] = values[key];
        }
        return acc;
      }, {});
  
      await applicantFormik.setValues(trimmedValues);
  
      // Validate form
      const errors = await applicantFormik.validateForm(trimmedValues);
      await setTouched(
        Object.keys(trimmedValues).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
  
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }
  
      const object = { ...trimmedValues, applicant_id: id || applyId };
      const url = `/applicant/application/${id || applyId}`;
  
      const response = await axiosInstance.post(url, object);
  
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          if (isEmailLinkOpened) {
            Swal.fire({
              title: "Thank You!",
              text: "Your applicant data has been successfully added.",
              icon: "success",
              showConfirmButton: false,
              allowOutsideClick: false,
            });
          } else {
            navigate(admin ? `/${admin}/applicants/` : "/staff/staffapplicanttable");
          }
        }, 1000);
      } else {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("An error occurred while submitting the form.", {
        position: "top-center",
      });
    } finally {
      setSubmitting(false);
    }
  };
  
  const applicantFormik = useFormik({
    initialValues: {
      applicant_id: "",
      admin_id: "",
      applicant_firstName: "",
      applicant_lastName: "",
      applicant_email: "",
      applicant_phoneNumber: "",
      applicant_birthDate: "",
      applicant_city: "",
      applicant_state: "",
      applicant_country: "",
      applicant_postalCode: "",
      agreeBy: "",
      agreeto: "",

      emergency_contact: {
        email: "",
        phone_number: "",
      },
      rental_history: {
        rental_adress: "",
        rental_city: "",
        rental_state: "",
        rental_country: "",
        rentalOwner_primaryEmail: "",
        rentalOwner_phoneNumber: "",
      },
      employment: {
        city: "",
        state: "",
        country: "",
        employment_primaryEmail: "",
        employment_phoneNumber: "",
      },
    },

    validationSchema: yup.object({
      applicant_firstName: yup.string().trim().required("First name is required"),
      applicant_lastName: yup.string().trim().required("Last name is required"),
      applicant_email: yup
        .string().trim()
        .required("Required")
        .email("Invalid email address")
        .required("Email is required"),
      applicant_phoneNumber: yup
        .string().trim()
        .required("Phone number is required")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      applicant_birthDate: yup
        .date()
        .required("Birth date is required")
        .nullable(),
        agreeBy: yup.string().trim().required("Agreed by is required"), 
        agreeto: yup.boolean()
        .required("Please confirm that you agree to the terms and conditions.")
        .oneOf([true], "You must agree to the terms and conditions to proceed."), 

      emergency_contact: yup.object({
        email: yup
          .string().trim()
          .email("Invalid email address")
          .test(
            "not-same-as-applicant-email",
          " emergency email cannot be the same as the applicant email",
            function (value) {
              const primaryEmail = this.options.context.applicant_email;
              if (!value && !primaryEmail) {
                return true;
              }
              return value !== primaryEmail;
            }
          ),
        phone_number: yup
          .string().trim()
          .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            "Phone number must be in the format (xxx) xxx-xxxx"
          )
          .test(
            "not-same-as-applicant-phone",
            "emergency phone number cannot be the same as the applicant phone number",
            function (value) {
              const primaryEmail = this.options.context.applicant_phoneNumber;
              if (!value && !primaryEmail) {
                return true;
              }
              return value !== primaryEmail;
            }
          ),
      }),

      rental_history: yup.object({
        rentalOwner_primaryEmail: yup
          .string().trim()
          .email("Invalid email address")
          .test(
            "not-same-as-applicant-email",
            "rentalowner email cannot be the same as the applicant email",
            function (value) {
              const primaryEmail = this.options.context.applicant_email;
              if (!value && !primaryEmail) {
                return true;
              }
              return value !== primaryEmail;
            }
          ),
        rentalOwner_phoneNumber: yup
          .string().trim()
          .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            "Phone number must be in the format (xxx) xxx-xxxx"
          )
          .test(
            "not-same-as-applicant-phone",
            " rentalowner phone number cannot be the same as the applicant phone number",
            function (value) {
              const primaryEmail = this.options.context.applicant_phoneNumber;
              if (!value && !primaryEmail) {
                return true;
              }
              return value !== primaryEmail;
            }
          ),
      }),

      employment: yup.object({
        city: yup.string().trim(),
        state: yup.string().trim(),
        country: yup.string().trim(),
        employment_primaryEmail: yup
          .string().trim()
          .email("Invalid email address")
          .test(
            "not-same-as-applicant-email",
            " employment email cannot be the same as the applicant email",
            function (value) {
              return value !== this.options.context.applicant_email;
            }
          ),
        employment_phoneNumber: yup
          .string().trim()
          .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            "Phone number must be in the format (xxx) xxx-xxxx"
          )
          .test(
            "not-same-as-applicant-phone",
            "employer number cannot be the same as the applicant phone number",
            function (value) {
              return value !== this.options.context.applicant_phoneNumber;
            }
          ),
      }),
    }),
    handleChange: (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    },
    onSubmit: handleSubmit, 
  });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!validateForm()) {
  //     return;
  //   }

  //   try {
  //     const object = { ...formData, applicant_id: id || applyId };
  //     const url = `/applicant/application/${id || applyId}`;
  //     const response = await axiosInstance.post(url, object);
  //     if (response.status === 200) {
  //       toast.success(response.data.message, {
  //         position: "top-center",
  //       });
  //       navigate(
  //         admin ? "/" + admin + "/Applicants/" : "/staff/staffapplicanttable"
  //       );
  //     } else {
  //       toast.error(response.data.message, {
  //         position: "top-center",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error updating data:", error);
  //   }
  // };
 
  const handleApplicantChange = (e) => {
    const { name, value } = e.target;
    const [mainField, nestedField] = name.split(".");
    if (nestedField) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [mainField]: {
          ...prevFormData[mainField],
          [nestedField]: value,
        },
      }));
      validateForm();
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };
  const isEmailLinkOpened = location.pathname?.includes("applicant-form");
  return (
    <>
      <Container
        fluid
        style={{
          marginTop: isEmailLinkOpened ? "3rem" : "0",
          paddingLeft: "5% ",
          paddingRight: "2%",
        }}
      >
        <form onSubmit={applicantFormik.handleSubmit}>
          <Box>
            <section
              style={{
                backgroundColor: "white",
                zIndex: 10000,
                borderRadius: 15,
              }}
            >
              {isEmailLinkOpened && (
                <div
                  className="d-flex mb-3"
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                   
                  <div style={{}}>
                    <img src={siteLogo} height={50} width={50}  alt="Company Logo" />
                  </div>
                  <div className=" textcolorblue fontfamilysty" style={{ flexGrow: 1, textAlign: "right" }}>
                    <span
                      style={{
                        fontSize: "22px",
                        paddingRight: "12px",
                        fontWeight: "bold",
                        display: "block",
                      }}
                    >
                      {admin ||  ""}
                    </span>
                  </div>
                </div>
              )}

              <div
                style={{
                  marginRight: "auto",
                  msOverflowStyle: "none",
                  paddingRight: "10px",
                }}
              >
                {!location.pathname?.includes("applicant-form") && (
                  <div className="d-flex justify-content-between my-3">
                    <span
                      className="labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "26px",
                        fontWeight: "500",
                      }}
                    >
                      Enter Applicant Details
                    </span>
                    <button
                      className="close-btn ml-2 background-colorsty bgtextwhite"
                      onClick={() => setShowApplicantForm(false)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "24px",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        width: "35px",
                        height: "35px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}
                <form style={{ width: "100%" }}>
                  <div>
                    <h2
                      className="labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Applicant information
                    </h2>
                    <Card
                      className="cardstyborderradius"
                      style={{
                        border: "1px solid #324567",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        padding: "0px 23px",
                      }}
                    >
                      <div className="form-row  mt-4">
                      <Col lg="4" xl="3" md="5" sm="5" xs="12" className="applicantmarginbottomstyle">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            First name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter first name "
                            name="applicant_firstName"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_firstName}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                          {applicantFormik.touched.applicant_firstName &&
                          applicantFormik.errors.applicant_firstName ? (
                            <div style={{ color: "red" }}>
                              {applicantFormik.errors.applicant_firstName}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Last name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter last name..."
                            name="applicant_lastName"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_lastName}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                          {applicantFormik.touched.applicant_lastName &&
                          applicantFormik.errors.applicant_lastName ? (
                            <div style={{ color: "red" }}>
                              {applicantFormik.errors.applicant_lastName}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12" className="applicantmarginbottomstyle">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Email
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="email"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Applicant email..."
                            name="applicant_email"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_email}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                          {applicantFormik.touched.applicant_email &&
                          applicantFormik.errors.applicant_email ? (
                            <div style={{ color: "red" }}>
                              {applicantFormik.errors.applicant_email}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Phone Number
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Applicant phone number..."
                            name="applicant_phoneNumber"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_phoneNumber}
                            onChange={(e) => {
                              if (
                                applicantFormik.values.applicant_phoneNumber
                                  ?.length > e.target.value?.length
                              ) {
                                applicantFormik.setFieldValue(
                                  "applicant_phoneNumber",
                                  e.target.value
                                );
                              } else {
                                const formattedValue = formatPhoneNumber(
                                  e.target.value
                                );
                                applicantFormik.setFieldValue(
                                  "applicant_phoneNumber",
                                  formattedValue
                                );
                              }
                            }}
                            onBlur={applicantFormik.handleBlur}
                          />
                          {applicantFormik.touched.applicant_phoneNumber &&
                          applicantFormik.errors.applicant_phoneNumber ? (
                            <div style={{ color: "red" }}>
                              {applicantFormik.errors.applicant_phoneNumber}
                            </div>
                          ) : null}
                        </Col>
                      </div>
                      <div className="form-row mt-3 mb-3">
                      <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            htmlFor="applicantHomePhone"
                            className="fontstylerentr titleecolor fontfamilysty"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Applicant birth date
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="date"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="birthdate"
                            name="applicant_birthDate"
                            value={applicantFormik.values.applicant_birthDate}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                            max={moment().format("YYYY-MM-DD")}
                          />
                          {applicantFormik.touched.applicant_birthDate &&
                          applicantFormik.errors.applicant_birthDate
                          ? (
                            <div style={{ color: "red" }}>
                              {applicantFormik.errors.applicant_birthDate}
                            </div>
                          ) : null}
                        </Col>
                      </div>
                    </Card>
                    <div className="mt-4">
                      <h2
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Applicant Street Address
                      </h2>
                    </div>
                    <Card
                      className="cardstyborderradius"
                      style={{
                        border: "1px solid #324567",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        padding: "0px 23px",
                      }}
                    >
                      <div className="form-row mt-4">
                        <div className="col-lg-3">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Street Address..."
                            autoComplete="off"
                            name="applicant_streetAddress"
                            onBlur={applicantFormik.handleBlur}
                            value={applicantFormik.values?.applicant_streetAddress}
                            onChange={applicantFormik.handleChange}
                          />
                        </div>
                        <div className="col-lg-3 applicantmarginbottomstyle">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="City"
                            name="applicant_city"
                            autoComplete="off"
                            onBlur={applicantFormik.handleBlur}
                            value={applicantFormik.values.applicant_city}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "applicant_city",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 ">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="State"
                            name="applicant_state"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_state}
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "applicant_state",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 applicantmarginbottomstyle">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Country"
                            name="applicant_country"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_country}
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "applicant_country",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row mt-3">
                        <div className="col-lg-3 mb-3">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Zip"
                            name="applicant_postalCode"
                            autoComplete="off"
                            value={applicantFormik.values.applicant_postalCode}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                        </div>
                      </div>
                    </Card>
                    <h2
                      className="mt-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Emergency contact
                    </h2>
                    <Card
                      className="cardstyborderradius"
                      style={{
                        border: "1px solid #324567",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        padding: "0px 23px",
                      }}
                    >
                      <div>
                        <div className="form-row mt-4">
                          <div className="col-lg-3 form-group applicantmarginbottomstyle">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="firstName"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              First name
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter first name..."
                              name="emergency_contact.first_name"
                              autoComplete="off"
                              value={applicantFormik?.values?.emergency_contact?.first_name}
                              onChange={applicantFormik.handleChange}
                              onBlur={applicantFormik.handleBlur}
                            />
                          </div>

                          <div className="col-lg-3 form-group">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="lastName"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Last name
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter last name..."
                              name="emergency_contact.last_name"
                              autoComplete="off"
                              value={applicantFormik?.values?.emergency_contact?.last_name}
                              onChange={applicantFormik.handleChange}
                              onBlur={applicantFormik.handleBlur}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Relationship
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter relationship"
                              name="emergency_contact.relationship"
                              autoComplete="off"
                              value={applicantFormik?.values?.emergency_contact?.relationship}
                              onChange={applicantFormik.handleChange}
                              onBlur={applicantFormik.handleBlur}
                            />
                          </div>
                          <div className="col-lg-3 applicantmarginbottomstyle">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Email
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="email"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="emergency_contact.email"
                              value={
                                applicantFormik.values.emergency_contact.email
                              }
                              onChange={applicantFormik.handleChange}
                              onBlur={applicantFormik.handleBlur}
                            />
                            {applicantFormik.touched.emergency_contact?.email &&
                            applicantFormik.errors.emergency_contact?.email ? (
                              <div style={{ color: "red" }}>
                                {applicantFormik.errors.emergency_contact.email}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div>
                          <div className="form-row mt-3">
                            <div className="col-lg-3 mb-3">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="applicantHomePhone"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Phone number
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Enter phone number"
                                autoComplete="off"
                                name="emergency_contact.phone_number"
                                value={
                                  applicantFormik.values.emergency_contact
                                    .phone_number
                                }
                                onBlur={applicantFormik.handleBlur}
                                onChange={(e) => {
                                  if (
                                    applicantFormik.values.emergency_contact
                                      .phone_number?.length >
                                    e.target.value?.length
                                  ) {
                                    applicantFormik.setFieldValue(
                                      "emergency_contact.phone_number",
                                      e.target.value
                                    );
                                  } else {
                                    const formattedValue = formatPhoneNumber(
                                      e.target.value
                                    );
                                    applicantFormik.setFieldValue(
                                      "emergency_contact.phone_number",
                                      formattedValue
                                    );
                                  }
                                }}
                              />
                              {applicantFormik.touched.emergency_contact
                                ?.phone_number &&
                              applicantFormik.errors.emergency_contact
                                ?.phone_number ? (
                                <div style={{ color: "red" }}>
                                  {
                                    applicantFormik.errors.emergency_contact
                                      .phone_number
                                  }
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <h2
                      className="mt-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Rental history
                    </h2>
                    <Card
                      className="cardstyborderradius"
                      style={{
                        border: "1px solid #324567",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        padding: "20px 23px",
                      }}
                    >
                      <div className="form-row mt-4">
                        <div className="col-lg-3">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Street Address..."
                            name="rental_history.rental_adress"
                            autoComplete="off"
                            value={applicantFormik?.values?.rental_history?.rental_adress}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                        </div>
                        <div className="col-lg-3 applicantmarginbottomstyle">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="City"
                            autoComplete="off"
                            name="rental_history.rental_city"
                            value={
                              applicantFormik?.values?.rental_history?.rental_city
                            }
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "rental_history.rental_city",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="State"
                            name="rental_history.rental_state"
                            autoComplete="off"
                            value={
                              applicantFormik?.values?.rental_history?.rental_state
                            }
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "rental_history.rental_state",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-3 applicantmarginbottomstyle">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="country"
                            name="rental_history.rental_country"
                            autoComplete="off"
                            value={
                              applicantFormik?.values?.rental_history?.rental_country
                            }
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "rental_history.rental_country",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row mt-3">
                        <div className="col-lg-3">
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Zip"
                            name="rental_history.rental_postcode"
                            value={applicantFormik?.values?.rental_history?.rental_postcode}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="form-row mt-3">
                      <Col lg="4" xl="3" md="5" sm="5" xs="12" className="mb-3">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="firstName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Start date
                          </label>
                          <input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="date"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter start date"
                            id="firstName"
                            name="rental_history.start_date"
                            value={applicantFormik?.values?.rental_history?.start_date}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                            max={
                              moment(applicantFormik?.values?.rental_history?.end_date).format(
                                "YYYY-MM-DD"
                              ) || null
                            }
                          />
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="Enddate"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            End date
                          </label>
                          <input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040",
                              borderRadius: "6px",
                            }}
                            type="date"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter end date"
                            id="lastName"
                            name="rental_history.end_date"
                            value={applicantFormik?.values?.rental_history?.end_date}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                            min={
                              moment(
                                applicantFormik?.values?.rental_history?.start_date
                              ).format("YYYY-MM-DD") || null
                            }
                          />
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="emergencyContactRelationship"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Monthly rent
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="number"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder=" Enter monthly rent"
                            autoComplete="off"
                            name="rental_history.rent"
                            value={applicantFormik?.values?.rental_history?.rent}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                        </Col>
                        <Col lg="4" xl="3" md="5" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="emergencyContactRelationship"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Reason for leaving
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter reason for leaving"
                            name="rental_history.leaving_reason"
                            autoComplete="off"
                            value={applicantFormik?.values?.rental_history?.leaving_reason}
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                        </Col>
                      </div>
                    </Card>

                    <div className="mt-4">
                      <h2
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Rental owner information
                      </h2>
                    </div>
                    <Card
                      className="cardstyborderradius"
                      style={{
                        border: "1px solid #324567",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        padding: "20px 20px",
                      }}
                    >
                      <div className="form-row mt-3">
                      <Col lg="4" xl="3" md="4" sm="4" xs="12" className="applicantmarginbottomstyle mb-3">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="firstName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Rental owner name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter rental owner name "
                            autoComplete="off"
                            name="rental_history.rentalOwner_firstName"
                            value={
                              applicantFormik?.values?.rental_history?.rentalOwner_firstName
                            }
                            onBlur={applicantFormik.handleBlur}
                            onChange={applicantFormik.handleChange}
                          />
                        </Col>
                        <Col lg="4" xl="3" md="4" sm="4" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="lastName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Last name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter last name"
                            autoComplete="off"
                            name="rental_history.rentalOwner_lastName"
                            onBlur={applicantFormik.handleBlur}
                            value={
                              applicantFormik?.values?.rental_history?.rentalOwner_lastName
                            }
                            onChange={applicantFormik.handleChange}
                          />
                        </Col>
                        <Col lg="4" xl="3" md="4" sm="4" xs="12" className="applicantmarginbottomstyle">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="emergencyContactRelationship"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Rental owner email
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="email"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter rental owner email"
                            autoComplete="off"
                            name="rental_history.rentalOwner_primaryEmail"
                            value={
                              applicantFormik.values?.rental_history?.rentalOwner_primaryEmail
                            }
                            onChange={applicantFormik.handleChange}
                            onBlur={applicantFormik.handleBlur}
                          />
                          {applicantFormik.touched.rental_history
                            ?.rentalOwner_primaryEmail &&
                          applicantFormik.errors.rental_history
                            ?.rentalOwner_primaryEmail ? (
                            <div style={{ color: "red" }}>
                              {
                                applicantFormik.errors.rental_history
                                  .rentalOwner_primaryEmail
                              }
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="4" xl="3" md="4" sm="5" xs="12">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="firstName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Rental owner number
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter phone number "
                            autoComplete="off"
                            name="rental_history.rentalOwner_phoneNumber"
                            value={
                              applicantFormik.values?.rental_history?.rentalOwner_phoneNumber
                            }
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (
                                applicantFormik.values.rental_history
                                  .rentalOwner_phoneNumber?.length >
                                e.target.value?.length
                              ) {
                                applicantFormik.setFieldValue(
                                  "rental_history.rentalOwner_phoneNumber",
                                  e.target.value
                                );
                              } else {
                                const formattedValue = formatPhoneNumber(
                                  e.target.value
                                );
                                applicantFormik.setFieldValue(
                                  "rental_history.rentalOwner_phoneNumber",
                                  formattedValue
                                );
                              }
                            }}
                          />
                          {applicantFormik.touched.rental_history
                            ?.rentalOwner_phoneNumber &&
                          applicantFormik.errors.rental_history
                            ?.rentalOwner_phoneNumber ? (
                            <div style={{ color: "red" }}>
                              {
                                applicantFormik.errors.rental_history
                                  .rentalOwner_phoneNumber
                              }
                            </div>
                          ) : null}
                        </Col>
                      </div>
                    </Card>
                    <div className="mt-4">
                      <h2
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Employment
                      </h2>
                      <div>
                        <Card
                          className="mb-3 cardstyborderradius"
                          style={{
                            border: "1px solid #324567",
                            boxShadow: "0px 4px 4px 0px #00000040",
                            padding: "20px 20px",
                          }}
                        >
                          <div className="form-row mt-4">
                            <div className="col-lg-3">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="emergencyContactRelationship"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Employer name
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Enter employer name"
                                name="employment.name"
                                autoComplete="off"
                                value={applicantFormik?.values?.employment?.name}
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-row mt-3">
                          <Col lg="4" xl="3" md="5" sm="5" xs="12">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="emergencyContactRelationship"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Employer address
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Street Address..."
                                name="employment.streetAddress"
                                autoComplete="off"
                                value={applicantFormik?.values?.employment?.streetAddress}
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12" className="applicantmarginbottomstyle"
                              style={{ marginTop: "30px" }}
                            >
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="City"
                                name="employment.city"
                                value={applicantFormik.values?.employment?.city}
                                onChange={(e) => {
                                  if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    applicantFormik.setFieldValue(
                                      "employment.city",
                                      e.target.value
                                    );
                                  }
                                }}
                                onBlur={applicantFormik.handleBlur}
                              />
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12"
                              style={{ marginTop: "30px" }}
                            >
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="State"
                                name="employment.state"
                                autoComplete="off"
                                value={applicantFormik.values?.employment?.state}
                                onChange={(e) => {
                                  if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    applicantFormik.setFieldValue(
                                      "employment.state",
                                      e.target.value
                                    );
                                  }
                                }}
                                onBlur={applicantFormik.handleBlur}
                              />
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12" className="applicantmarginbottomstyle"
                              style={{ marginTop: "30px" }}
                            >
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Country"
                                name="employment.country"
                                autoComplete="off"
                                value={
                                  applicantFormik.values?.employment?.country
                                }
                                onChange={(e) => {
                                  if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    applicantFormik.setFieldValue(
                                      "employment.country",
                                      e.target.value
                                    );
                                  }
                                }}
                                onBlur={applicantFormik.handleBlur}
                              />
                            </Col>
                          </div>
                          <div className="form-row mt-3">
                          <Col lg="4" xl="3" md="5" sm="5" xs="12">
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Zip"
                                autoComplete="off"
                                name="employment.postalCode"
                                value={applicantFormik?.values?.employment?.postalCode}
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                          </div>
                          <div className="form-row mt-3">
                          <Col lg="4" xl="3" md="6" sm="6" xs="12" className="applicantmarginbottomstyle mb-3">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="EmployerEmail"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Employer email
                              </label>
                              <input
                                id="EmployerEmail"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="email"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Employer email..."
                                autoComplete="off"
                                name="employment.employment_primaryEmail"
                                value={
                                  applicantFormik.values.employment?.employment_primaryEmail
                                }
                                onChange={applicantFormik.handleChange}
                                onBlur={applicantFormik.handleBlur}
                              />
                              {applicantFormik.touched.employment
                                ?.employment_primaryEmail &&
                              applicantFormik.errors.employment
                                ?.employment_primaryEmail ? (
                                <div style={{ color: "red" }}>
                                  {
                                    applicantFormik.errors.employment
                                      .employment_primaryEmail
                                  }
                                </div>
                              ) : null}
                            </Col>
                            <Col lg="4" xl="3" md="3" sm="3" xs="12">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="firstName"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Employer number
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Employer phone number..."
                                autoComplete="off"
                                name="employment.employment_phoneNumber"
                                value={
                                  applicantFormik.values?.employment?.employment_phoneNumber
                                }
                                onChange={(e) => {
                                  if (
                                    applicantFormik.values.employment
                                      .employment_phoneNumber?.length >
                                    e.target.value?.length
                                  ) {
                                    applicantFormik.setFieldValue(
                                      "employment.employment_phoneNumber",
                                      e.target.value
                                    );
                                  } else {
                                    const formattedValue = formatPhoneNumber(
                                      e.target.value
                                    );
                                    applicantFormik.setFieldValue(
                                      "employment.employment_phoneNumber",
                                      formattedValue
                                    );
                                  }
                                }}
                                onBlur={applicantFormik.handleBlur}
                              />
                              {applicantFormik.touched.employment
                                ?.employment_phoneNumber &&
                              applicantFormik.errors.employment
                                ?.employment_phoneNumber ? (
                                <div style={{ color: "red" }}>
                                  {
                                    applicantFormik.errors.employment
                                      .employment_phoneNumber
                                  }
                                </div>
                              ) : null}
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="emergencyContactRelationship"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Position held
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Position held..."
                                name="employment.employment_position"
                                autoComplete="off"
                                value={
                                  applicantFormik?.values?.employment?.employment_position
                                }
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12" className="applicantmarginbottomstyle">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="firstName"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Supervisor first name
                              </label>
                              <input
                                type="text"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Supervisor first name..."
                                autoComplete="off"
                                name="employment.supervisor_firstName"
                                value={
                                  applicantFormik?.values?.employment?.supervisor_firstName
                                }
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                          </div>
                          <div className="form-row mt-3">
                          <Col lg="4" xl="3" md="5" sm="5" xs="12">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="lastName"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Supervisor last name
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Supervisor last name..."
                                name="employment.supervisor_lastName"
                                autoComplete="off"
                                value={
                                  applicantFormik?.values?.employment?.supervisor_lastName
                                }
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                            <Col lg="4" xl="3" md="5" sm="5" xs="12">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                htmlFor="emergencyContactRelationship"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Supervisor title
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="email"
                                className="form-control fontstylerentmodal titleecolor"
                                placeholder="Supervisor title..."
                                autoComplete="off"
                                name="employment.supervisor_title"
                                value={applicantFormik?.values?.employment?.supervisor_title}
                                onBlur={applicantFormik.handleBlur}
                                onChange={applicantFormik.handleChange}
                              />
                            </Col>
                          </div>
                        </Card>
                      </div>
                      <div className="mt-4">
                        <h2
                          className="barforsummaryrent textcolorblue fontfamilysty"
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Terms and conditions
                        </h2>

                        <div>
                          <div className="form-row mt-3 pl-2">
                            <p
                              className="labelfontstyle titleecolor fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#979797",
                              }}
                            >
                              I understand that this is a routine application to
                              establish credit, character, employment, and
                              rental history. I also understand that this is NOT
                              an agreement to rent and that all applications
                              must be approved. I authorize verification of
                              references given. I declare that the statements
                              above are true and correct, and I agree that the
                              Rental owner may terminate my agreement entered
                              into in reliance on any misstatement made above.
                            </p>
                          </div>

                          <div className="form-row mt-4 pl-4">
                            <div className="col-md-12">
                              <input
                                 className="form-check-input"
                                 type="checkbox"
                                 id="checked"
                                 name="agreeto"
                                 value={applicantFormik.values.agreeto}
                                onChange={applicantFormik.handleChange}
                                onBlur={applicantFormik.handleBlur}
                              />
                              <label
                                className="form-check-label fontstylerentr titleecolor fontfamilysty"
                                for="defaultCheck1"
                                style={{
                                  fontSize: "16px", 
                                  fontWeight: "400",
                                }}
                              >
                                Agreed to Terms and Conditions*
                              </label>
                              {applicantFormik.touched.agreeto &&
                              applicantFormik.errors.agreeto ? (
                                <div style={{ color: "red" }}>
                                  {applicantFormik.errors.agreeto}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="form-row mt-4">
                            <div className="col">
                              <label
                                className="fontstylerentr titleecolor fontfamilysty"
                                chtmlFor="emergencyContactRelationship"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                Agreed by
                              </label>
                              <input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                type="text"
                                className="form-control fontstylerentmodal"
                                placeholder="Enter your full name to signify agreement."
                                autoComplete="off"
                                name="agreeBy"
                                value={applicantFormik.values?.agreeBy}
                                onChange={applicantFormik.handleChange}
                                onBlur={applicantFormik.handleBlur}
                              />

                              {applicantFormik.touched.agreeBy &&
                              applicantFormik.errors.agreeBy ? (
                                <div style={{ color: "red" }}>
                                  {applicantFormik.errors.agreeBy}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-row mt-4 pl-2">
                            <p
                              className="labelfontstyle titleecolor fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "400",
                              }}
                            >
                              By submitting this application, (1) I am giving
                              permission to run a background check on me, which
                              may include obtaining my credit report from a
                              consumer reporting agency, and (2) agreeing to the{" "}
                              <span
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                }}
                              >
                                {" "}
                                Privacy Policy{" "}
                              </span>{" "}
                              and{" "}
                              <span
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                }}
                              >
                                {" "}
                                Terms of Service.{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col lg={8} sm={8} md={8} xl={8} xs={12}>
                        <button
                          type="button"
                          className="btn mb-3 mb-sm-0 mr-sm-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                          style={{
                            borderRadius: "6px",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          onClick={applicantFormik.handleSubmit} 
                        >
                          Save Application
                        </button>
                      </Col>
                    </Row>
                    <br />
                  </div>
                  {/* 5150 end  */}
                </form>
              </div>
            </section>
          </Box>
        </form>
        <ToastContainer />
      </Container>
    </>
  );
};

export default ApplicantForm;
