import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { verifyToken } from "components/Functions/Functions";
import Loader from "components/ReusableComponents/Loader";
import axiosInstance from "axiosInstance";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";

const Emaillogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(undefined);
  const [emails, setEmails] = useState([]);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      if (data) {
        setAccessType(data);
      }
    };

    fetchData();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterOptions, setFilterOptions] = useState();

  const fetchEmailsData = async () => {
    if (accessType?.admin_id) {
      try {
        setLoader(true);
        const queryParams = new URLSearchParams({
          page: currentPage,
          limit: itemsPerPage,
          sortField,
          sortOrder,
          search: filterOptions || "",
        }).toString();

        const response = await axiosInstance.get(
          `/email-logs/logs/${accessType.admin_id}?${queryParams}`
        );

        if (response.data.statusCode === 200) {
          setEmails(response.data.emails);
          setTotalPages(response.data.totalPages);
        }

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error: ", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchEmailsData();
  }, [
    currentPage,
    itemsPerPage,
    sortField,
    sortOrder,
    filterOptions,
    accessType,
  ]);

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  const [clickedObject, setClickedObject] = useState({});
  const [openemailrecipients, setOpenEmailrecipients] = useState(false);
  const handleClose = () => {
    setOpenEmailrecipients(false);
  };

  const handleMoveOutClick = (item) => {
    setClickedObject(item);
    setOpenEmailrecipients(true);
  };


  return (
    <>
      <Container className="stylecontainer pt-5" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Email Logs
              </h2>
            </CardHeader>
          </Col>
        </Row>

        <Modal isOpen={openemailrecipients} toggle={handleClose}>
          <ModalHeader>
            <div
              className="labelfontstyle textcolorblue fontfamilysty d-flex"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                gap: "10px",
              }}
            >
              Email Logs Details
            </div>
          </ModalHeader>
          <ModalBody style={{ width: "100%", maxWidth: "700px" }}>
            <div
              className="d-flex justify-content-between labelfontstyle"
              style={{ border: "1px solid #152B51", padding: "10px" }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                Open Status:{" "}
              </span>
              {clickedObject?.isOpened && clickedObject?.isAccepted ? (
                <DoneAllIcon color="success" />
              ) : clickedObject.isAccepted ? (
                <CheckIcon color="success" />
              ) : (
                <CloseIcon color="error" />
              )}
            </div>
            <div
              className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
              style={{
                border: "1px solid #152B51",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                Subject:
              </span>{" "}
              {clickedObject?.subject}
            </div>
            <div
              className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
              style={{
                border: "1px solid #152B51",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                Recipient Email:
              </span>
              {clickedObject?.email}
            </div>
            <div
              className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
              style={{
                border: "1px solid #152B51",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                From Email:
              </span>
              {clickedObject?.from}
            </div>
            <div
              className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
              style={{
                border: "1px solid #152B51",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                Created Time:
              </span>
              {moment(clickedObject?.createdAt).format(
                `${dateFormat} HH:mm:ss`
              )}
            </div>
            <div
              className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
              style={{
                border: "1px solid #152B51",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              <span
                className="d-flex justify-content-between textcolorblue fontfamilysty labelfontstyle"
                style={{
                  fontWeight: "500",
                }}
              >
                Open Time:
              </span>
              {clickedObject.openedAt ? (
                <>
                  {moment(new Date(Number(clickedObject?.openedAt))).format(
                    `${dateFormat} HH:mm:ss`
                  )}
                </>
              ) : (
                "Not Opened"
              )}
            </div>
          </ModalBody>
        </Modal>

        <div className="mt-5">
          {loader ? (
            <Loader />
          ) : (
            <>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  borderTopLeftRadius: "20px",
                  borderCollapse: "collapse",
                }}
              >
                <thead
                  style={{
                    height: "45px",
                    fontSize: "14px",
                    fontFamily: "poppins",
                    fontWeight: "600",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <tr
                    style={{
                      border: "2px solid rgba(50, 69, 103, 1)",
                      borderTopLeftRadius: "20px",
                    }}
                  >
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{ color: "#152B51", width: "20%" }}
                    >
                      Subject
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        color: "#152B51",
                        width: "20%",
                        borderTopLeftRadius: "20px",
                      }}
                    >
                      Recipient Email
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        color: "#152B51",
                        width: "20%",
                        borderTopLeftRadius: "20px",
                      }}
                    >
                      From Email
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        borderTopRightRadius: "15px",
                        color: "#152B51",
                        width: "20%",
                      }}
                    >
                      Created At
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        borderTopRightRadius: "15px",
                        color: "#152B51",
                        width: "20%",
                      }}
                    >
                      Opened
                    </th>
                    <th
                      className="tablefontstyle px-3"
                      scope="col"
                      style={{
                        borderTopRightRadius: "15px",
                        color: "#152B51",
                        width: "20%",
                      }}
                    >
                      Open Status
                    </th>
                  </tr>
                </thead>

                <tbody style={{ overflow: "auto" }}>
                  <tr
                    style={{
                      border: "none",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <td colSpan="9" className="border-none"></td>
                  </tr>

                  {emails?.length > 0 ? (
                    emails.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => handleMoveOutClick(item)}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont px-3">
                            {item?.subject}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            {item?.email || "No Email"}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            {item?.from || "No Email"}
                          </td>
                          <td className="bordertopintd tablebodyfont px-3">
                            {moment(item?.createdAt).format(
                              `${dateFormat} HH:mm:ss`
                            )}
                          </td>
                          <td
                            className="bordertopintd tablebodyfont px-3"
                           
                          >
                            {item.openedAt ? (
                              <>
                                {moment(
                                  new Date(Number(item?.openedAt))
                                ).format(`${dateFormat} HH:mm:ss`)}
                              </>
                            ) : (
                              "Not Opened"
                            )}
                          </td>

                          <td className="bordertopintd tablebodyfont px-3">
                            {item?.isOpened && item?.isAccepted ? (
                              <DoneAllIcon color="success" />
                            ) : item.isAccepted ? (
                              <CheckIcon color="success" />
                            ) : (
                              <CloseIcon color="error" />
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr
                      style={{
                        border: "none",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <td
                        colSpan="9"
                        className="text-center"
                        style={{ border: 0 }}
                      >
                        No email logs available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {itemsPerPage}
                    </DropdownToggle>
                    <DropdownMenu>
                      {[10, 25, 50, 100].map((perPage) => (
                        <DropdownItem
                          key={perPage}
                          onClick={() => setItemsPerPage(perPage)} 
                          >
                          {perPage}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Emaillogs;
