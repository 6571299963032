import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import "../../assets/css/argon-dashboard-react.min.css";
import "../../assets/css/argon-dashboard-react.css";
import { useCookies } from "react-cookie";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import { verifyToken } from "components/Functions/Functions";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { strongPassword } from "plugins/helpers";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  const [loader, setLoader] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [currentPlan, setCurrentPlan] = useState("");
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [originalPassword, setOriginalPassword] = useState();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile({
          first_name: res.data.data?.first_name || "",
          last_name: res.data.data?.last_name || "",
          email: res.data.data?.email || "",
          company_name: res.data.data?.company_name || "",
          company_address: res.data.data?.company_address || "",
          createdAt: res.data.data?.createdAt || "",
          city: res.data.data?.city || "",
          state: res.data.data?.state || "",
          country: res.data.data?.country || "",
          postal_code: res.data.data?.postal_code || "",
          phone_number: res.data.data?.phone_number || "",
          password: res.data.data?.password || "",
          confirm_password: res.data.data?.password || "",
          createdAt: res.data.data?.createdAt || "",
        });
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [accessType]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
      if (data) {
        try {
          const res = await axiosInstance.get(
            `/purchase/plan-purchase/${data.admin_id}`
          );
          if (res.data.data) {
            setCurrentPlan(res.data.data.plan_detail.plan_name);
          }
        } catch (error) {
          console.log("Error: ", error);
        } finally {
          setLoader(false);
        }
      }
    };
    fetchData();
  }, []);

  const passwordValidation = yup.string()
  .required("Password is required.")
  .test("strong-password", "Invalid password", (value, context) => {
    const result = strongPassword(value);
    return result === true ? true : context.createError({ message: result });
  });

  const validationSchema = yup.object({
    first_name: yup.string().trim().required("Please enter first name"),
    last_name: yup.string().trim().required("Please enter last name"),
    email: yup
      .string().trim()
      .required("Please enter email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    phone_number: yup
      .string().trim()
      .required("Please enter phone number")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (xxx) xxx-xxxx"
      ),
    company_name: yup.string().trim().required("Please enter company name"),
    password: passwordValidation,

    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords does not match")
      .required("Confirm Password is required"),
  });

  const userFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      company_address: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      phone_number: "",
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (userProfile) {
      userFormik.setValues(userProfile);
      setOriginalPassword(userProfile.password);
    }
  }, [userProfile]);

  const [btnLoader, setBtnLoader] = useState(false);

  async function handleSubmit(values) {
    setBtnLoader(true);
    try {
      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] = typeof values[key] === "string" ? values[key].trim() : values[key];
        return acc;
      }, {});
  
      await userFormik.setValues(trimmedValues);
  
      const errors = await userFormik.validateForm(trimmedValues);
  
      await userFormik.setTouched(
        Object.keys(trimmedValues).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
  
      if (Object.keys(errors).length > 0) {
        userFormik.setErrors(errors);
        setBtnLoader(false);
        return;
      }
  
      const hasChanges = JSON.stringify(trimmedValues) !== JSON.stringify(userProfile);
  
      if (hasChanges) {
        if (trimmedValues.password === trimmedValues.confirm_password) {
          const res = await axiosInstance.put(
            `/admin/admin_edit/${accessType.admin_id}`,
            trimmedValues
          );
          if (res.data.statusCode === 200) {
            toast.success("Profile updated successfully", {
              position: "top-center",
              autoClose: 900,
            });
            setTimeout(() => {
              navigate(`/${admin}/`);
            }, 1900);
          } else {
            toast.error(res.data.message, {
              position: "top-center",
            });
          }
          getUserProfile();
        } else {
          toast.error("Passwords do not match", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      } else {
        toast.success("Profile updated successfully", {
          position: "top-center",
          autoClose: 900,
        });
        setTimeout(() => {
          navigate(`/${admin}/`);
        }, 1900);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error: ", error.message);
    } finally {
      setBtnLoader(false);
    }
  }

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const handlePhoneChange = (e) => {
    if (userFormik.values.phone_number?.length > e.target.value?.length) {
      userFormik.setFieldValue("phone_number", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      userFormik.setFieldValue("phone_number", formattedValue);
    }
  };

  return (
    <>
      <Container className="" fluid style={{ marginTop: "4rem" }}>
        {loader ? (
          <Row>
            <Col>
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <div
                className="card"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <div
                      className="profilecirclewidth background-colorsty bgtextwhite fontfamilysty"
                      style={{
                        borderRadius: "50%",
                        padding: "20px",
                        width: "90px",
                        fontSize: "25px",
                        fontWeight: "600",
                      }}
                    >
                      {`${userProfile?.first_name
                        ?.slice(0, 1)
                        .toUpperCase()}${userProfile?.last_name
                        ?.slice(0, 1)
                        .toUpperCase()}`}
                    </div>

                    <div className="mt-3">
                      <h4
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        {userProfile?.first_name}&nbsp;{userProfile?.last_name}
                      </h4>
                      <p
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {userProfile?.email}
                      </p>
                      <p
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {userProfile?.phone_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card mt-3"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <div className="card-body">
                  <div
                    className="d-flex align-items-center text-center titleecolor fontfamilysty"
                    style={{ fontWeight: "500" }}
                  >
                    Account level : &nbsp;
                    <span
                      className="textcolorblue fontfamilysty"
                      style={{ fontWeight: "600" }}
                    >
                      {currentPlan != "Free Plan" ? "Paid" : "Free"}
                    </span>
                  </div>
                  <div className="mt-3">
                    {currentPlan === "Free Plan" && (
                      <>
                        <Button
                          style={{
                            fontSize: "14px",
                            marginLeft: "35px",
                            color: "white",
                          }}
                          className="text-overflow m-0 background-colorsty"
                          onClick={() => {
                            window.open(
                              "https://www.hostmerchantservices.com/signup?leadsource=CloudRentalManager",
                              "_blank"
                            );
                          }}
                        >
                          Upgrade Account
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card
                className="mb-3"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardHeader
                  className=" mx-4 mt-3 text-white border-0 profilecardheaderstyle background-colorsty"
                  style={{
                    borderRadius: "6px",
                    height: "45px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    padding: "6px 7px 1px 21px",
                    color: "white",
                  }}
                >
                  <span
                    className=" align-items-center barforsummaryrent bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "22px",
                    }}
                  >
                    My Account
                  </span>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={userFormik.handleSubmit}>
                    <h6
                      className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="first_name"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              First Name *
                            </label>
                            <Input
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.first_name}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  userFormik.setFieldValue(
                                    "first_name",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.first_name &&
                                userFormik.errors.first_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.first_name &&
                            userFormik.errors.first_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.first_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="last_name"
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              Last Name *
                            </label>
                            <Input
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.last_name}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  userFormik.setFieldValue(
                                    "last_name",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.last_name &&
                                userFormik.errors.last_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.last_name &&
                            userFormik.errors.last_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.last_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="email"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Email Address *
                            </label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email Address"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.email}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              readOnly
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.email &&
                                userFormik.errors.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              disabled
                            />
                            {userFormik.touched.email &&
                            userFormik.errors.email ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.email}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="phone_number"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Phone Number *
                            </label>
                            <Input
                              id="phone_number"
                              name="phone_number"
                              placeholder="Phone Number"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.phone_number}
                              onChange={handlePhoneChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.phone_number &&
                                userFormik.errors.phone_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.phone_number &&
                            userFormik.errors.phone_number ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.phone_number}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="company_name"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Name *
                            </label>
                            <Input
                              id="company_name"
                              name="company_name"
                              placeholder="Company Name"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.company_name}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.company_name &&
                                userFormik.errors.company_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.company_name &&
                            userFormik.errors.company_name ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.company_name}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-username"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Created Date
                            </label>
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              defaultValue={userProfile?.createdAt}
                              id="input-last-name"
                              placeholder="Created Date"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty  m-0"
                              htmlFor="company_name"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Password *
                              <Tooltip
                                title={
                                  <Box style={{ fontSize: "12px" }}>
                                    <ul
                                      style={{ paddingLeft: "20px", margin: 0 }}
                                    >
                                      <li>
                                        At least one uppercase letter (A-Z).
                                      </li>
                                      <li>
                                        At least one lowercase letter (a-z).
                                      </li>
                                      <li>At least one number (0-9).</li>
                                      <li>
                                        At least one special character (e.g., @,
                                        #, $, etc.).
                                      </li>
                                      <li>
                                        Password must be at least 12 characters
                                        long.
                                      </li>
                                      <li>
                                        No continuous alphabetical characters
                                        (e.g., abcd) or continuous numerical
                                        characters (e.g., 1234).
                                      </li>
                                      <li>
                                        Avoid strictly sequential patterns
                                        (e.g., Ak12345678!).
                                      </li>
                                      <li>
                                        Don't use birthdays, names, addresses,
                                        or other personal information.
                                      </li>
                                    </ul>
                                  </Box>
                                }
                                placement="bottom"
                                arrow
                              >
                                <IconButton>
                                  <Info
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      color: "#152B51",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </label>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <Input
                                id="password"
                                name="password"
                                placeholder="Password"
                                autoComplete="off"
                                type={showPassword ? "text" : "password"}
                                value={userFormik?.values?.password}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                style={{
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "6px",
                                }}
                                className={`form-control-alternative fontstylerentmodal titleecolor ${
                                  userFormik.touched.password &&
                                  userFormik.errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "10px",
                                  backgroundColor: "#fff",
                                  zIndex: 10,
                                }}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon
                                    style={{ color: "#152B51" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "#152B51" }}
                                  />
                                )}
                              </div>
                            </div>
                            {userFormik.touched &&
                            userFormik.touched.password &&
                            userFormik.errors &&
                            userFormik.errors.password ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {userFormik.errors.password}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="company_name"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Confirm Password *
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <Input
                                id="confirm_password"
                                name="confirm_password"
                                placeholder="Confirm Password"
                                autoComplete="off"
                                type={showPassword1 ? "text" : "password"}
                                value={userFormik?.values?.confirm_password}
                                onChange={userFormik.handleChange}
                                onBlur={userFormik.handleBlur}
                                style={{
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "6px",
                                }}
                                className={`form-control-alternative fontstylerentmodal titleecolor ${
                                  userFormik.touched.confirm_password &&
                                  userFormik.errors.confirm_password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "10px",
                                  backgroundColor: "#fff",
                                  zIndex: 10,
                                }}
                                onClick={() => setShowPassword1(!showPassword1)}
                              >
                                {showPassword1 ? (
                                  <VisibilityOffIcon
                                    style={{ color: "#152B51" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "#152B51" }}
                                  />
                                )}
                              </div>
                            </div>
                            {userFormik.touched.confirm_password &&
                            userFormik.errors.confirm_password ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {userFormik.errors.confirm_password}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="company_address"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Company Address
                            </label>
                            <Input
                              id="company_address"
                              name="company_address"
                              placeholder="Company Address"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.company_address}
                              onChange={userFormik.handleChange}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.company_address &&
                                userFormik.errors.company_address
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.company_address &&
                            userFormik.errors.company_address ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.company_address}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="postal_code"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Zip Code
                            </label>
                            <Input
                              id="postal_code"
                              name="postal_code"
                              placeholder="Zip Code"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.postal_code}
                              onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                const truncatedValue = sanitizedValue.slice(
                                  0,
                                  10
                                );
                                userFormik.setFieldValue(
                                  "postal_code",
                                  truncatedValue
                                );
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              onInput={(e) => {
                                const inputValue = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                e.target.value = inputValue.slice(0, 10);
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.postal_code &&
                                userFormik.errors.postal_code
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.postal_code &&
                            userFormik.errors.postal_code ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.postal_code}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="city"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              City
                            </label>
                            <Input
                              id="city"
                              name="city"
                              placeholder="City"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.city}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  userFormik.setFieldValue(
                                    "city",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.city &&
                                userFormik.errors.city
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.city &&
                            userFormik.errors.city ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.city}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="state"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              State
                            </label>
                            <Input
                              id="state"
                              name="state"
                              placeholder="State"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.state}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  userFormik.setFieldValue(
                                    "state",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.state &&
                                userFormik.errors.state
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.state &&
                            userFormik.errors.state ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.state}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4" className="mt-3">
                          <FormGroup>
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="country"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Country
                            </label>
                            <Input
                              id="country"
                              name="country"
                              placeholder="Country"
                              type="text"
                              autoComplete="off"
                              value={userFormik.values.country}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  userFormik.setFieldValue(
                                    "country",
                                    e.target.value
                                  );
                                }
                              }}
                              onBlur={userFormik.handleBlur}
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                borderRadius: "6px",
                              }}
                              className={`form-control-alternative fontstylerentmodal titleecolor ${
                                userFormik.touched.country &&
                                userFormik.errors.country
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {userFormik.touched.country &&
                            userFormik.errors.country ? (
                              <div className="invalid-feedback">
                                {userFormik.errors.country}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3">
                          {btnLoader ? (
                            <Button
                              type="submit"
                              className="btn btnwidthworkorder fontstylerentr background-colorsty bgtextwhite"
                              style={{
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              Loading...
                            </Button>
                          ) : (
                            <Button
                              className="text-white fontstylerentr background-colorsty"
                              type="submit"
                              // disabled={!userFormik.isValid}
                            >
                              Update
                            </Button>
                          )}
                          <Button
                            className="fontstylerentr profilebtnmtstyle background-colorsty bgtextwhite"
                            onClick={() => navigate(`/${admin}/`)}
                            size="small"
                          >
                            Back
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <ToastContainer />
    </>
  );
};

export default Profile;
