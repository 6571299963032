import "./AddPaymentByTenant.scss";
import AddCardDetailsForm from "./AddCardDetailsForm";
import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import {
  getCardDetails,
  getCardLogo,
  getCardType,
  getCurrentBalance,
  getCustomerVaultId,
  getRentalOwnerCardTypeSettings,
  getTokenizationKey,
  makePayment,
} from "../../../plugins/ApiHandler";
import BaseInput from "components/ReusableComponents/BaseInput";
import Loader from "components/ReusableComponents/Loader";
import BaseButton from "components/ReusableComponents/BaseButton";
import { addTokenizationScript } from "plugins/helpers";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { currencyFormatter } from "plugins/helpers";
import axiosInstance from "axiosInstance";
import Dropdown from "components/ReusableComponents/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import { Input } from "reactstrap";

const AddPaymentByTenant = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modalShow, setModalShow] = useState(false);
  const [cardDetalis, setCardDetails] = useState([]);
  const [getCardLoading, setGetCardLoading] = useState(false);
  const [getCardError, setGetcardError] = useState("");
  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");
  const [dueAmountDetails, setDueAmountDetails] = useState({});
  const [selectedCardData, setSelectedCardData] = useState({});

  const [partialAmount, setPartialAmount] = useState(0);
  const [dueAmountLoading, setDueAmountLoading] = useState(false);
  const [dueAmountError, setDueAmountError] = useState("");
  const [paymentAmountType, setPaymentAmountType] = useState("full");

  const [propertyDropdownData, setPropertyDropdownData] = useState([]);
  const [leaseId, setLeaseId] = useState("");
  const [accessType, setAccessType] = useState(undefined);
  const [rentalAddress, setRentalAddress] = useState("");
  const [rentAmount, setRentAmount] = useState(0);
  const [leaseSelected, setLeaseSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
      if (data) {
        try {
          const res = await axiosInstance.get(
            `/purchase/plan-purchase/${data.admin_id}`
          );
          if (res.data.data && res.data.data.plan_detail) {
            setCurrentPlan(res.data.data.plan_detail.plan_name);
          }
        } catch (error) {
          console.log("Error: ", error);
        }
      }
    };
    fetchData();
  }, []);

  const getNmiKey = async (tenant_id) => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(tenant_id);
      await addTokenizationScript(keyResponse.publicKey);
    } catch (error) {
      setScriptError(
        "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  const getTenantDueAmount = async (leaseId) => {
    const tenant_id = accessType?.tenant_id;
    if (tenant_id && leaseId) {
      try {
        setDueAmountLoading(true);
        setDueAmountError("");

        const data = await getCurrentBalance(tenant_id, leaseId);
        setDueAmountDetails(data);
      } catch (error) {
        if (typeof error === "string") setDueAmountError(error);
        else setDueAmountError(JSON.stringify(error));
      } finally {
        setDueAmountLoading(false);
      }
    } else {
      setDueAmountLoading(false);
    }
  };

  const hanleRadioSelection = (event) => {
    setPaymentAmountType(event.target.value);
  };

  const getCardsData = async () => {
    if (!leaseId) return;
    try {
      setGetCardLoading(true);
      setGetcardError("");

      const customerData = await getCustomerVaultId(accessType?.tenant_id);
      const customer_vault_id = customerData.customer_vault_id;
      const cardDetails = await getCardDetails({
        customer_vault_id,
        admin_id: accessType?.admin_id,
      });

      const billingData = cardDetails?.data?.customer;

      const cardTypeMap = {};
    if (Array.isArray(customerData?.card_detail)) {
      customerData.card_detail.forEach((card) => {
        cardTypeMap[card.billing_id] = card.card_type;
      });
    }

    if (Array.isArray(billingData.billing)) {
      const extractedData = await Promise.all(
        billingData.billing.map((item) =>
          handleSetCardDetails(item, cardTypeMap[item["@attributes"].id] || "Unknown")
        )
      );

      // Filter out any undefined or null values (which would be returned if card type is not accepted)
      const filteredData = extractedData.filter((item) => item);

      setCardDetails(filteredData);

      if (filteredData.length === 1 && filteredData[0].allowPayment) {
        setSelectedCardData(filteredData[0]);
      }
    } else if (billingData?.billing) {
      // Handle single record
      const extractedData = await handleSetCardDetails(
        billingData.billing,
        cardTypeMap[billingData.billing["@attributes"].id] || "Unknown"
      );

        if (extractedData) {
          // Only set the value if it's not undefined
          setCardDetails([extractedData]);

          if (extractedData.allowPayment) {
            setSelectedCardData(extractedData);
          }
        }
      } else {
        console.error(
          "Invalid response structure - customer.billing is not an array"
        );
        setCardDetails([]);
      }
    } catch (error) {
      if (typeof error === "string") setGetcardError(error);
      else setGetcardError(JSON.stringify(error));
    } finally {
      setGetCardLoading(false);
    }
  };

  const handleSetCardDetails = async (item, cardType) => {
    try {
      // const cardType = await getCardType(item.cc_bin, item.cc_type);
      // const cardType = item.card_type;
      const response = await getRentalOwnerCardTypeSettings(
        accessType?.tenant_id,
        leaseId
      );
      const cardLogo = await getCardLogo(item.cc_type);
      const { creditCardAccepted, debitCardAccepted } = response.data;

      // Check if the card type is accepted
      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      )
        item.allowPayment = false;
      else item.allowPayment = true;

      return {
        allowPayment: item.allowPayment,
        billing_id: item["@attributes"].id,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
        card_type: cardType,
        card_logo: cardLogo,
      };
    } catch (error) {
      console.error(error, "error in bin check");
      return {};
    }
  };

  useEffect(() => {
    if (accessType?.tenant_id) getNmiKey(accessType?.tenant_id);
  }, [accessType?.tenant_id]);

  useEffect(() => {
    if (accessType?.tenant_id && accessType?.admin_id && leaseId) {
      getCardsData();
    }
  }, [accessType?.tenant_id, accessType?.admin_id, leaseId]);

  const handleCardSelection = (event, data) => {
    if (event.target.checked) {
      setSelectedCardData(data);
    } else {
      setSelectedCardData({});
    }
  };

  const calculateSurcharge = (amount, cardType, dueDetails) => {
    let applicableSurchargePercentage =
      cardType === "CREDIT"
        ? dueDetails?.surcharge?.surcharge_percent
        : cardType === "DEBIT"
        ? dueDetails?.enable_override_fee && dueDetails.override_fee !== undefined && dueDetails.override_fee !== null
          ? dueDetails.override_fee
          : dueDetails?.surcharge?.surcharge_percent_debit
        : dueDetails?.surcharge?.surcharge_percent_debit;

    const surchargeAmount = (Number(amount) * Number(applicableSurchargePercentage)) / 100;

    return { surchargeAmount, applicableSurchargePercentage };
  };

  useEffect(() => {
    const setSurcharge = () => {
      if (selectedCardData?.billing_id) {
        const amount =
          paymentAmountType === "partial"
            ? partialAmount
            : paymentAmountType === "rent"
            ? Number(rentAmount || 0)
            : dueAmountDetails?.total_due_amount > 0
            ? Number(dueAmountDetails?.total_due_amount || 0).toFixed(2)
            : "0";

        const { surchargeAmount, applicableSurchargePercentage } = calculateSurcharge(
          amount,
          selectedCardData.card_type,
          dueAmountDetails
        );

        setDueAmountDetails((values) => ({
          ...values,
          surcargeAmount: surchargeAmount,
          surchargePercentage: applicableSurchargePercentage,
        }));
      } else {
        setDueAmountDetails((values) => ({
          ...values,
          surcargeAmount: null,
          surchargePercentage: null,
        }));
      }
    };

    setSurcharge();
  }, [partialAmount, paymentAmountType, selectedCardData]);

  const handleSubmit = async () => {
    const totalAmount = Number(
      paymentAmountType === "partial"
        ? partialAmount
        : paymentAmountType === "rent"
        ? Number(rentAmount || 0)
        : dueAmountDetails?.total_due_amount || 0
    );
    if (!selectedCardData?.billing_id || totalAmount <= 0) {
      return;
    }
    const object = {
      admin_id: dueAmountDetails.admin_id,
      lease_id: dueAmountDetails.lease_id,
      tenant_firstName: dueAmountDetails?.tenant?.tenant_firstName,
      tenant_lastName: dueAmountDetails?.tenant?.tenant_lastName,
      tenant_email: dueAmountDetails?.tenant?.tenant_email,
      customer_vault_id: selectedCardData?.customer_vault_id,
      billing_id: selectedCardData?.billing_id,
      surcharge: dueAmountDetails?.surcargeAmount,
      total_amount: Number(
        paymentAmountType === "partial"
          ? partialAmount
          : paymentAmountType === "rent"
          ? Number(rentAmount || 0)
          : dueAmountDetails?.total_due_amount || 0
      ),
      tenant_id: dueAmountDetails?.tenant_id,
      processor_id: dueAmountDetails?.processorId,
      rentalAddress: selectedProperty,
      paymentAmountType,
      date: selectedDate,
      entry: [
        {
          // entry_id: item?.entry_id,
          account: paymentAmountType === "rent" ? "Rent Income" : "Payment",
          // balance: item.balance,
          amount: totalAmount,
          memo: paymentAmountType === "rent" ? "Rent Income" : "Payment",
          date: selectedDate,
          charge_type: paymentAmountType === "rent" ? "Rent" : "Payment",
        },
      ],
    };
    setDueAmountLoading(true);
    setPartialAmount(0);
    await makePayment(object, navigate);
  };

  const [getLeasesLoading, setGetLeasesLoading] = useState(false);
  const [getLeasesError, setGetLeasesError] = useState("");

  const fetchLedger = async () => {
    if (accessType?.tenant_id) {
      const tenant_id = accessType.tenant_id;

      if (tenant_id) {
        try {
          setGetLeasesLoading(true);
          setGetLeasesError("");

          const response = await axiosInstance.get(
            `/leases/get_leases/${tenant_id}`
          );
          setPropertyDropdownData(response.data.data.leases);
          setRentalAddress(response.data.data.rentalAddress);
        } catch (error) {
          setGetLeasesError(
            error instanceof Error ? error.message : "An unknown error occurred"
          );
          console.error("Error fetching tenant details:", error);
        } finally {
          setGetLeasesLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchLedger();
  }, [accessType]);

  const handlePartialAmountChange = (e) => {
    const value = e.target.value;

    if (!leaseSelected) {
      setErrorMessage(
        "Please select a lease before entering a partial amount."
      );
      setPartialAmount(0);
      return;
    }

    if (
      /^\d*\.?\d*$/.test(value) &&
      value <= dueAmountDetails?.total_due_amount
    ) {
      setPartialAmount(value);
      setErrorMessage("");
    }
  };

  const handleSelection = (property) => {
    setSelectedProperty(property.rental_adress);
    setLeaseSelected(property.lease_id !== "");
    setLeaseId(property.lease_id);
    getTenantDueAmount(property.lease_id);
    setRentAmount(property.rent);
    setSelectedCardData({});
  };

  return (
    <div className="add-payment-page">
      <div className="add-payment-page--card-details card">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mr-5"
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <label
              className="crm-input-label"
              style={{ textTransform: "uppercase" }}
            >
              Leases <span className="required">*</span>
            </label>
            <Dropdown
              loading={getLeasesLoading}
              emptyMsg={getLeasesError}
              disabled={dueAmountLoading || getCardLoading}
              options={propertyDropdownData}
              labelKey1="rental_adress"
              labelKey2="status"
              placeholder="Select a lease"
              valueKey="lease_id"
              onSelect={handleSelection}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <label
              className="crm-input-label"
              style={{ textTransform: "uppercase" }}
            >
              Date <span className="required">*</span>
            </label>
            <Input
              style={{
                boxShadow: "none",
                borderRadius: "6px",
                border: "1px solid rgb(204, 204, 204)",
              }}
              className="form-control-alternative fontstylerentmodal titleecolor"
              id="input-unitadd"
              placeholder="3000"
              type="date"
              name="date"
              // onBlur={generalledgerFormik.handleBlur}
              onChange={(e) => setSelectedDate(e.target.value)}
              value={selectedDate}
              min={moment().format("YYYY-MM-DD")}
            />
          </div>
        </div>

        <p className="heading">Payment Card Details</p>
        {getCardLoading ? (
          <Loader />
        ) : getCardError ? (
          <p>{getCardError}</p>
        ) : cardDetalis.length === 0 ? (
          <p>
            No Card Found. If you have not selected any lease, please select it
            first.
          </p>
        ) : (
          cardDetalis.map((el) => (
            <div className="payment-card">
              <Tooltip
                arrow
                title={
                  el.allowPayment
                    ? ""
                    : `${el.card_type} card types not accepted by rental owner`
                }
              >
                <span>
                  <Checkbox
                    disabled={!el.allowPayment}
                    style={{
                      color: "#152B51",
                      opacity: el.allowPayment ? "1" : "0.4",
                    }}
                    onChange={(e) => handleCardSelection(e, el)}
                    checked={el.billing_id === selectedCardData.billing_id}
                  />
                </span>
              </Tooltip>
              <div className="card-inner">
                <p className="label">Card Number</p>
                <p className="label">Card Type</p>
                <p className="value">{el.cc_number}</p>
                <p className="value">{el.card_type}</p>
              </div>
              {el.card_logo && (
                <img
                  src={el.card_logo}
                  style={{ width: "30px", height: "fit-content" }}
                />
              )}
            </div>
          ))
        )}

        {scriptGenerating ? (
          <p>NMI card fields loading...</p>
        ) : scriptError ? (
          <p style={{ color: "red" }}>{scriptError}</p>
        ) : (
          <>
            {!scriptGenerating && !scriptError ? (
              <div>
                <AddCardDetailsForm
                  rentalAddress={rentalAddress}
                  getCardsData={getCardsData}
                  onHide={() => setModalShow(false)}
                  show={modalShow}
                  scriptGenerating={scriptGenerating}
                  getCardLoading={getCardLoading}
                  getCardError={getCardError}
                  scriptError={scriptError}
                  cardDetalis={cardDetalis}
                  tenant_id={accessType?.tenant_id}
                  admin_id={accessType?.admin_id}
                  accessType={accessType}
                />
                <BaseButton
                  onClick={() => setModalShow(true)}
                  label="Add a New Card"
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="add-payment-page--functions card">
        {dueAmountLoading ? (
          <Loader />
        ) : dueAmountError ? (
          <p style={{ textAlign: "center" }}>{dueAmountError}</p>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div className="current-balance">
                <p className="heading">Current Balance</p>
                <p className="amount">
                  {dueAmountDetails?.total_due_amount > 0
                    ? `$${Number(
                        dueAmountDetails?.total_due_amount || 0
                      ).toFixed(2)}`
                    : dueAmountDetails?.total_due_amount < 0
                    ? `- $${Math.abs(
                        dueAmountDetails?.total_due_amount || 0
                      ).toFixed(2)}`
                    : `$0.00`}
                </p>
              </div>
              <div className="current-balance mr-5">
                <p className="heading">Rent Amount</p>
                <p className="amount">${Number(rentAmount || 0).toFixed(2)}</p>
              </div>
            </div>
            <div className="choose-balance">
              <p className="heading">Choose Payment Amount</p>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={paymentAmountType}
                name="radio-buttons-group"
                onChange={hanleRadioSelection}
              >
                <FormControlLabel
                  value="full"
                  control={<Radio style={{ color: "#152B51" }} />}
                  label={`Pay Full Amount (${currencyFormatter(
                    dueAmountDetails?.total_due_amount > 0
                      ? Number(dueAmountDetails?.total_due_amount || 0).toFixed(
                          2
                        )
                      : "0"
                  )})`}
                />
                <FormControlLabel
                  value="partial"
                  control={<Radio style={{ color: "#152B51" }} />}
                  label={`Pay Partial Amount`}
                />
                <FormControlLabel
                  value="rent"
                  control={<Radio style={{ color: "#152B51" }} />}
                  label={`Pay Rent Amount (${currencyFormatter(rentAmount)})`}
                />
              </RadioGroup>
            </div>
            {paymentAmountType === "partial" && (
              <div>
                <BaseInput
                  placeholder="Enter Partial Amount"
                  onChange={handlePartialAmountChange}
                  value={partialAmount}
                />
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}{" "}
                {/* Show error message */}
              </div>
            )}
            <div>
              <div className="amount-selection">
                <p style={{ fontWeight: "bold" }}>Payment: </p>
                <label htmlFor="paymentAmount">
                  $
                  {Number(
                    paymentAmountType === "partial"
                      ? partialAmount
                      : paymentAmountType === "rent"
                      ? Number(rentAmount || 0)
                      : dueAmountDetails?.total_due_amount > 0
                      ? Number(dueAmountDetails?.total_due_amount || 0).toFixed(
                          2
                        )
                      : "0"
                  ).toFixed(2)}
                </label>
              </div>
              <div className="amount-selection">
              <p style={{ fontWeight: "bold" }}>
                Surcharge  {" "}
                {dueAmountDetails.surchargePercentage 
                  ? `(${dueAmountDetails.surchargePercentage}%)` 
                  : ""} :
              </p>
                <label htmlFor="halfAmount">
                  ${Number(dueAmountDetails?.surcargeAmount || 0).toFixed(2)}
                </label>
              </div>
              <div className="amount-selection">
                <p style={{ fontWeight: "bold" }}>Amount to pay: </p>
                <label htmlFor="halfAmount">
                  $
                  {Number(
                    Number(
                      paymentAmountType === "partial"
                        ? partialAmount
                        : paymentAmountType === "rent"
                        ? Number(rentAmount || 0)
                        : dueAmountDetails?.total_due_amount > 0
                        ? Number(
                            dueAmountDetails?.total_due_amount || 0
                          ).toFixed(2)
                        : "0"
                    ) + Number(dueAmountDetails?.surcargeAmount || 0)
                  ).toFixed(2)}
                </label>
              </div>
            </div>
            <BaseButton 
              label="Make a Payment"
              onClick={handleSubmit}
              disabled={Object.keys(selectedCardData).length === 0} 
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AddPaymentByTenant;
