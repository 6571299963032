import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom/dist";
import Work from "../assets/icons/AdminDashboard/Properti-icon.svg";
import "./source/dash.css";
import { Circles } from "react-loader-spinner";
import "../views/source/mobiledesign.css";
import { Pie, Line } from "react-chartjs-2";
import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip } from "recharts";
import ArrowRight from "../assets/icons/AdminDashboard/rightarrow-icon.svg";
import Tenants from "../assets/icons/AdminDashboard/tenant-icon.svg";
import Applicants from "../assets/icons/AdminDashboard/applicant-icon.svg";
import Vendors from "../assets/icons/AdminDashboard/vendor-icon.svg";
import WorkOrders from "../assets/icons/AdminDashboard/workorder-icon.svg";
import { useParams } from "react-router-dom";
import Header from "components/Headers/Header.js";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import { verifyToken } from "../components/Functions/Functions";

const Index = () => {
  const { admin } = useParams();

  let navigate = useNavigate();
  let location = useLocation();

  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [loader, setLoader] = useState(true);
  const [currentYearData, setCurrentYearData] = useState([]);
  const [lastYearData, setLastYearData] = useState([]);
  const [barData, setBarData] = useState([]);

  const transformData = (currentYearData, lastYearData) => {
    const months = Array.from({ length: 12 }, (_, i) => ({
      month: i + 1,
      currentYear: 0,
      lastYear: 0,
    }));

    currentYearData.forEach(({ month, totalAmount }) => {
      months[month - 1].currentYear = totalAmount;
    });

    lastYearData.forEach(({ month, totalAmount }) => {
      months[month - 1].lastYear = totalAmount;
    });

    return months.map(({ month, currentYear, lastYear }) => ({
      month: new Date(0, month - 1).toLocaleString("en", { month: "short" }),
      currentYear,
      lastYear,
    }));
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { month, currentYear, lastYear } = payload[0].payload;

      const showCurrentYear = currentYear > 0;
      const showLastYear = lastYear > 0;

      if (showCurrentYear || showLastYear) {
        return (
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            <b>{month}</b>
            <br />
            {showLastYear && `Last Year : $${lastYear.toFixed(2)} `}
            {showLastYear && <br />}
            {showCurrentYear && `Current Year : $${currentYear.toFixed(2)}`}
          </div>
        );
      }
    }

    return null;
  };

  const ComparisonGraph = ({ data, poll1, poll2, style }) => {
    const [activeBar, setActiveBar] = useState(false);

    return (
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={data}>
          <XAxis
            dataKey="month"
            axisLine={{ stroke: "transparent" }}
            tickLine={{ stroke: "transparent" }}
            tick={{ fontFamily: "Roboto", fontSize: 14 }}
          />
          <Tooltip
            content={activeBar ? <CustomTooltip /> : ""}
            cursor={{ fill: "rgba(255, 255, 255, 0.5)" }}
            contentStyle={{
              backgroundColor: "#fff",
              border: "none",
              borderRadius: "8px",
            }}
            labelStyle={{ fontFamily: "Roboto", fontSize: 14 }}
            active={activeBar}
          />
          <Bar
            dataKey="lastYear"
            fill={poll2}
            name="Last Year"
            barSize={25}
            style={style}
            onMouseEnter={() => setActiveBar(true)}
            onMouseLeave={() => setActiveBar(false)}
          />
          <Bar
            dataKey="currentYear"
            fill={poll1}
            name="Current Year"
            barSize={25}
            style={style}
            onMouseEnter={() => setActiveBar(true)}
            onMouseLeave={() => setActiveBar(false)}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const ComparisonChartContainer = ({ poll1, poll2 }) => {
    return (
      <ComparisonGraph
        data={barData}
        poll1={poll1}
        poll2={poll2}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const [data, setData] = useState({
    tenants: 0,
    rentals: 0,
    Vendors: 0,
    Applicants: 0,
    WorkOrders: 0,
  });

  const [paymentData, setPaymentData] = useState({});
  const [graphData, setGraphData] = useState({ data: [] });

  useEffect(() => {
    const fetchAllData = async () => {
      if (accessType?.admin_id) {
        try {
          setLoader(true);

          const [response, responseBar, responsePayment, responseGraph] =
            await Promise.all([
              axiosInstance.get(`/admin/counts/${accessType?.admin_id}`),
              axiosInstance.get(
                `/payment/monthly-summary/${accessType?.admin_id}`
              ),
              axiosInstance.get(
                `/payment/admin_balance/${accessType?.admin_id}`
              ),
              axiosInstance.get(
                `/rentals/occupied_properties/${accessType?.admin_id}`
              ),
            ]);

          setData({
            tenants: response.data.tenantCount,
            rentals: response.data.rentalCount,
            Vendors: response.data.vendorCount,
            Applicants: response.data.applicantCount,
            WorkOrders: response.data.workOrderCount,
          });

          if (responseBar.data.statusCode === 200) {
            setCurrentYearData(responseBar.data.data.currentYear);
            setLastYearData(responseBar.data.data.lastYear);
          }

          setPaymentData(responsePayment.data.data);
          setGraphData(responseGraph.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoader(false);
        }
      }
    };

    fetchAllData();
  }, [accessType]);

  useEffect(() => {
    if (currentYearData.length || lastYearData.length) {
      const transformedData = transformData(currentYearData, lastYearData);
      setBarData(transformedData);
    }
  }, [currentYearData, lastYearData]);

  const isDataEmpty = (data) => {
    return Object.values(data).every((value) => value === 0);
  };

  const originalLabels = graphData.data.map((item) => item.month);
  const originalData = graphData.data.map((item) => item.occupiedPercentage);
  // Data and options for the chart
  const data1 = {
    labels: originalLabels,
    datasets: [
      {
        label: "Property",
        data: originalData,
        fill: true,
        backgroundColor: "rgb(168 193 239 / 89%)",
        borderColor: "rgb(90, 134, 213)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
      },
    },
    hover: {
      mode: "nearest",
      intersect: true, // Ensures the cursor interaction focuses on points
      onHover: (event, chartElement) => {
        const canvas = event.native?.target || event.target;
        if (chartElement.length) {
          canvas.style.cursor = "pointer"; // Set cursor to pointer when hovering over a point
        } else {
          canvas.style.cursor = "default"; // Revert to default when not hovering over a point
        }
      },
    },
  };

  return (
    <div>
      <Header />

      <Container className="" fluid>
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <Circles
                  height="50"
                  width="50"
                  color="#1171ef"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={loader}
                />
              </div>
            ) : (
              <Col className="order-xl-1" xl="12">
                <Row
                  xs="1"
                  sm="2"
                  md="2"
                  lg="3"
                  xl={"5"}
                  className="px-3 pt-3 pb-1"
                >
                  <Col
                    className="py-3"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Properties",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        height: "235px",
                        margin: "auto 5px",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                        backgroundColor: "rgba(21, 43, 81, 1)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => navigate("/" + admin + "/propertiesTable")}
                    >
                      <CardBody
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="py-5 flex-column"
                      >
                        <Row style={{ flex: 1.5 }}>
                          <Col>
                            <span
                              style={{
                                borderRadius: "50%",
                                fontSize: "16px",
                                padding: "20px",
                                boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                              }}
                            >
                              <img src={Work} height={25} width={25} />
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-start pt-5"
                          >
                            <span
                              className="dashboardfontst"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              {data.rentals?.toString()?.padStart(2, "0") ||
                                "00"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-3">
                          <Col lg={12}>
                            <span
                              className="dashboardfonttitle"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Properties{" "}
                              <img src={ArrowRight} height={20} width={20} />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    className="py-3"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Manrope",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        height: "235px",
                        margin: "auto 5px",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                        backgroundColor: "rgba(40, 60, 95, 1)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => navigate("/" + admin + "/TenantsTable")}
                    >
                      <CardBody
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="py-5"
                      >
                        <Row>
                          <Col>
                            <span
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                fontSize: "16px",
                                padding: "20px",
                                boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                              }}
                            >
                              <img src={Tenants} height={25} width={25} />
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-start pt-5"
                          >
                            <span
                              className="dashboardfontst"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              {data.tenants?.toString()?.padStart(2, "0") ||
                                "00"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-3">
                          <Col lg={12}>
                            <span
                              className="dashboardfonttitle"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Tenants{" "}
                              <img src={ArrowRight} height={20} width={20} />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    className="py-3"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Manrope",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        height: "235px",
                        margin: "auto 5px",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                        backgroundColor: "rgba(50, 75, 119, 1)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => navigate("/" + admin + "/Applicants")}
                    >
                      <CardBody
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="py-5"
                      >
                        <Row>
                          <Col>
                            <span
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                fontSize: "16px",
                                padding: "20px",
                                boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                              }}
                            >
                              <img src={Applicants} height={25} width={25} />
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-start pt-5"
                          >
                            <span
                              className="dashboardfontst"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              {data.Applicants?.toString()?.padStart(2, "0") ||
                                "00"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-3">
                          <Col lg={12}>
                            <span
                              className="dashboardfonttitle"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Applicants {""}
                              <img src={ArrowRight} height={20} width={20} />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    className="py-3"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Manrope",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        height: "235px",
                        margin: "auto 5px",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                        backgroundColor: "rgba(60, 89, 142, 1)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => navigate("/" + admin + "/vendor")}
                    >
                      <CardBody
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="py-5"
                      >
                        <Row>
                          <Col>
                            <span
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                fontSize: "16px",
                                padding: "20px",
                                boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                              }}
                            >
                              <img src={Vendors} height={25} width={25} />
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-start pt-5"
                          >
                            <span
                              className="dashboardfontst"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              {data.Vendors?.toString()?.padStart(2, "0") ||
                                "00"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-3">
                          <Col lg={12}>
                            <span
                              className="dashboardfonttitle"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Vendors {""}
                              <img src={ArrowRight} height={20} width={20} />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    className="py-3"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Manrope",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card
                      style={{
                        cursor: "pointer",
                        height: "235px",
                        margin: "auto 5px",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                        backgroundColor: "rgba(90, 134, 213, 1)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      onClick={() => navigate("/" + admin + "/Workorder")}
                    >
                      <CardBody
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                        className="py-5"
                      >
                        <Row>
                          <Col>
                            <span
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                fontSize: "16px",
                                padding: "20px",
                                boxShadow: "rgba(0, 0, 0, 0.75) 0 4px 4px 0",
                              }}
                            >
                              <img src={WorkOrders} height={25} width={25} />
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-start pt-5"
                          >
                            <span
                              className="dashboardfontst"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              {data.WorkOrders?.toString()?.padStart(2, "0") ||
                                "00"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="pt-3">
                          <Col lg={12}>
                            <span
                              className="dashboardfonttitle"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Work Orders {""}
                              <img src={ArrowRight} height={20} width={20} />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="">
                  <Col xs={12}>
                    <Row className="pl-2">
                      <Col className="pt-3" xs={12} md={6} lg={6} xl={4}>
                        <Col>
                          <Card
                            className="second-cards dashcardsheight"
                            style={{
                              height: "135px",
                              borderRadius: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row>
                              <Col lg={12}>
                                <span
                                  style={{
                                    height: "50px",
                                    backgroundColor: "#324B77",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                                    fontSize: "16px",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  Rent Due
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CardBody
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                  className="py-1 mt-3"
                                >
                                  <div
                                    className="dashboardcardss"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      // padding: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/${admin}/due-rent-report`, {
                                          state: {
                                            type: "Charges",
                                            month: "Current Month",
                                            title: "Rent Due Report",
                                          },
                                        });
                                      }}
                                    >
                                      <span
                                        className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Current Month
                                      </span>

                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                          fontSize: "20px",
                                          color: "rgba(90, 134, 213, 1)",
                                        }}
                                        className="d-flex justify-content-center align-items-center px-4 dashboardfontst"
                                      >
                                        {paymentData?.currentMonthRentDue &&
                                          paymentData.currentMonthRentDue.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                      </span>
                                    </div>
                                    <div
                                      className="lastmonthcards"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/${admin}/due-rent-report`, {
                                          state: {
                                            type: "Charges",
                                            month: "Last Month",
                                            title: "Rent Due Report",
                                          },
                                        });
                                      }}
                                    >
                                      <span
                                        className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Last Month
                                      </span>

                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                          fontSize: "20px",
                                          color: "rgba(90, 134, 213, 1)",
                                        }}
                                        className="d-flex justify-content-center align-items-center px-3 dashboardfontst"
                                      >
                                        {paymentData?.lastMonthRentDue &&
                                          paymentData.lastMonthRentDue.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Col>

                      {/* Repeat similar structure for other cards */}

                      <Col className="pt-3" xs={12} md={6} lg={6} xl={4}>
                        <Col>
                          <Card
                            className="second-cards dashcardsheight"
                            style={{
                              height: "135px",
                              borderRadius: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row>
                              <Col lg={12}>
                                <span
                                  style={{
                                    height: "50px",
                                    backgroundColor: "#324B77",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                                    fontSize: "16px",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  Rent Paid
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CardBody
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                  className="py-1 mt-3"
                                >
                                  <div
                                    className="dashboardcardss"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      // padding: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/${admin}/due-rent-report`, {
                                          state: {
                                            type: "Payments",
                                            month: "Current Month",
                                            title: "Rent Paid Report",
                                          },
                                        });
                                      }}
                                    >
                                      <span
                                        className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Current Month
                                      </span>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                          fontSize: "20px",
                                          color: "rgba(90, 134, 213, 1)",
                                        }}
                                        className="d-flex justify-content-center align-items-center px-4 barforsummaryrent"
                                      >
                                        {paymentData?.currentMonthRentPaid &&
                                          paymentData.currentMonthRentPaid.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                      </span>
                                    </div>
                                    <div
                                      className="lastmonthcards"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        navigate(`/${admin}/due-rent-report`, {
                                          state: {
                                            type: "Payments",
                                            month: "Last Month",
                                            title: "Rent Paid Report"
                                          },
                                        });
                                      }}
                                    >
                                      <span
                                        className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Last Month
                                      </span>
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                          fontSize: "20px",
                                          color: "rgba(90, 134, 213, 1)",
                                        }}
                                        className="d-flex justify-content-center align-items-center px-3 barforsummaryrent"
                                      >
                                        {paymentData?.lastMonthRentPaid &&
                                          paymentData.lastMonthRentPaid.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Col>

                      <Col className="pt-3" xs={12} md={6} lg={6} xl={4}>
                        <Col>
                          <Card
                            className="second-cards dashcardsheight"
                            style={{
                              height: "135px",
                              borderRadius: "20px",
                              boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row>
                              <Col lg={12}>
                                <span
                                  style={{
                                    height: "50px",
                                    backgroundColor: "#324B77",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  Rent Past Due
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CardBody
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                  }}
                                  className="py-1"
                                  onClick={() => {
                                    navigate(`/${admin}/due-rent-report`, {
                                      state: {
                                        type: "Charges",
                                        month: "All",
                                        title: "Rent Past Due Report"
                                      },
                                    });
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "Poppins",
                                      fontWeight: "600",
                                      fontSize: "20px",
                                      color: "rgba(90, 134, 213, 1)",
                                    }}
                                    className="d-flex justify-content-center align-items-center py-4 barforsummaryrent rentpastduetext"
                                  >
                                    {paymentData?.totalRentPastDue &&
                                      paymentData.totalRentPastDue.toLocaleString(
                                        "en-US",
                                        { style: "currency", currency: "USD" }
                                      )}
                                  </span>
                                </CardBody>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {isDataEmpty(data) ? (
                  <></>
                ) : (
                  <Row className="w-100 my-5">
                    <Col className="pt-3" xs={12} sm={12} md={12} lg={5} xl={5}>
                      <Col
                        style={{
                          marginBottom: "20px",
                          fontFamily: "Manrope",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          borderRadius: "20px",
                        }}
                        className="mx-3"
                      >
                        <Card
                          className="circlegraph"
                          style={{
                            // cursor: "pointer",
                            width: "100%", // Responsive width
                            height: "auto",
                            display: "flex",
                            flexDirection: "Row",
                            backgroundColor: "transparent",
                            border: "none",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="text-center"
                            style={{ width: "100%", padding: "10px" }}
                          >
                            <p
                              className="d-flex justify-content-start"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              Property Summary
                            </p>
                            <div style={{ width: "100%", height: "290px" }}>
                              <Line data={data1} options={options} />
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {graphData.data &&
                                `${
                                  graphData.data[graphData.data.length - 1]
                                    ?.leases
                                }`}{" "}
                              of{" "}
                              {graphData.data &&
                                `${
                                  graphData.data[graphData.data.length - 1]
                                    ?.rentals
                                }`}{" "}
                              units currently{" "}
                              <span style={{ color: "rgb(90, 134, 213)" }}>
                                occupied
                              </span>{" "}
                              -{" "}
                              {graphData.data &&
                                `${
                                  graphData.data[graphData.data.length - 1]
                                    ?.occupiedPercentage
                                }`}
                              %
                            </p>
                          </div>
                        </Card>
                      </Col>
                    </Col>
                    <Col xl={7} sm={12} lg={7} md={12}>
                      <Row
                        className="w-100 my-3 p-2  mx-3"
                        style={{
                          alignItems: "center",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          height: "390px",
                          borderRadius: "20px",
                        }}
                      >
                        <Col
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                          className="pl-4 text-left"
                        >
                          Total Revenue
                        </Col>

                        <ComparisonChartContainer
                          poll1={"rgba(50, 75, 119, 1)"}
                          poll2={"rgba(80, 119, 190, 1)"}
                        />

                        <Col
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          }}
                          className="pl-4 text-center"
                        >
                          <span
                            style={{ fontFamily: "Poppins", fontSize: "15px" }}
                            className="px-3"
                          >
                            <i
                              className="fa-solid fa-circle px-1"
                              style={{ color: "rgba(80, 119, 190, 1)" }}
                            ></i>
                            Last Year
                          </span>
                          <span
                            style={{ fontFamily: "Poppins", fontSize: "15px" }}
                            className="px-3"
                          >
                            <i
                              className="fa-solid fa-circle px-1"
                              style={{ color: "rgba(50, 75, 119, 1)" }}
                            ></i>
                            Current Year
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
