// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 1680 */
@media (max-width: 1700px) {}

/* 1500 */
@media (max-width: 1680px) {}

/* 1440 */
@media (max-width: 1449px) {}

/* 1280 */
@media (max-width: 1439px) {}

/* 992 */
@media (max-width: 1279px) {}

/* 768 */
@media (max-width: 991px) {}

/* 576 */
@media (max-width: 767px) {}

/* 525 */
@media (max-width: 575px) {}

/* 480 */
@media (max-width: 524px) {}

/* 425 */
@media (max-width: 479px) {}

/* 375 */
@media (max-width: 424px) {}

/* 320 */
@media (max-width: 374px) {}

/* 280 */
@media (max-width: 359px) {
    .headfont{
        font-size: 17px !important;
    }

}

/* 309 */
@media (max-width: 409px) {

}
/* 280 */
@media (max-width: 320px) {
    .bottomspace{
        margin-bottom: 15px;
    }
    
}

/* 280 */
@media (max-width: 991px) {
    .spacetop{
        margin-top: 15px !important;
    }

}
/* 992 */
@media (max-width: 1646px) {
  .inputstyle{
    width: 100% !important;
  }
}

/* 280 */
@media (max-width: 319px) {}
`, "",{"version":3,"sources":["webpack://./src/Styles/rentalowner.css"],"names":[],"mappings":"AAAA,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,QAAQ;AACR,4BAA4B;;AAE5B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR;IACI;QACI,0BAA0B;IAC9B;;AAEJ;;AAEA,QAAQ;AACR;;AAEA;AACA,QAAQ;AACR;IACI;QACI,mBAAmB;IACvB;;AAEJ;;AAEA,QAAQ;AACR;IACI;QACI,2BAA2B;IAC/B;;AAEJ;AACA,QAAQ;AACR;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA,QAAQ;AACR,2BAA2B","sourcesContent":["/* 1680 */\n@media (max-width: 1700px) {}\n\n/* 1500 */\n@media (max-width: 1680px) {}\n\n/* 1440 */\n@media (max-width: 1449px) {}\n\n/* 1280 */\n@media (max-width: 1439px) {}\n\n/* 992 */\n@media (max-width: 1279px) {}\n\n/* 768 */\n@media (max-width: 991px) {}\n\n/* 576 */\n@media (max-width: 767px) {}\n\n/* 525 */\n@media (max-width: 575px) {}\n\n/* 480 */\n@media (max-width: 524px) {}\n\n/* 425 */\n@media (max-width: 479px) {}\n\n/* 375 */\n@media (max-width: 424px) {}\n\n/* 320 */\n@media (max-width: 374px) {}\n\n/* 280 */\n@media (max-width: 359px) {\n    .headfont{\n        font-size: 17px !important;\n    }\n\n}\n\n/* 309 */\n@media (max-width: 409px) {\n\n}\n/* 280 */\n@media (max-width: 320px) {\n    .bottomspace{\n        margin-bottom: 15px;\n    }\n    \n}\n\n/* 280 */\n@media (max-width: 991px) {\n    .spacetop{\n        margin-top: 15px !important;\n    }\n\n}\n/* 992 */\n@media (max-width: 1646px) {\n  .inputstyle{\n    width: 100% !important;\n  }\n}\n\n/* 280 */\n@media (max-width: 319px) {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
