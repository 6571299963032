import axios from "axios";
import moment from "moment";
import interceptor from "plugins/axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

// Get customer vault ID
export const getCustomerVaultId = async (tenant_id) => {
  try {
    const response = await interceptor.get(
      `creditcard/getCreditCards/${tenant_id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get card details
export const getCardDetails = async (payload) => {
  try {
    const response = await interceptor.post(
      `nmipayment/get-billing-customer-vault`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get tokenization key to generate CollectJS script
export const getTokenizationKey = async (tenant_id) => {
  try {
    const response = await interceptor.get(
      `tenant/nmi_public_key/${tenant_id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// get current balance of tenant
export const getCurrentBalance = async (tenant_id, lease_id) => {
  try {
    const response = await interceptor.get(
      `charge/tenant_due_amount/${tenant_id}/${lease_id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// add card details by tenant
export const addCardDetails = async (payload) => {
  try {
    const response = await interceptor.post(
      `nmipayment/tenant/add-tenant-payment`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get card type CREDIT/DEBIT
export const getCardLogo = async (cc_type) => {
  try {
    const res = await axios.get(
      `https://logo.clearbit.com/${cc_type.toLowerCase().replace(" ", "")}.com`
    );
    return res.config.url || "";
  } catch (error) {
    return "";
  }
};

// Get card logo by card bin
export const getCardType = async (cc_bin, cc_type) => {
  const options = {
    method: "POST",
    url: `https://bin-ip-checker.p.rapidapi.com/?bin=${cc_bin}`,

    headers: {
      "content-type": "application/json",
      "X-RapidAPI-Key": "1bd772d3c3msh11c1022dee1c2aep1557bajsn0ac41ea04ef7",
      "X-RapidAPI-Host": "bin-ip-checker.p.rapidapi.com",
    },
  };

  try {
    const response = await axios.request(options);
    return response.data.BIN.type || cc_type;
  } catch (error) {
    return cc_type;
  }
};

export const makePayment = async (payload) => {
  try {
    if (!payload.total_amount || payload.total_amount === 0) {
      return;
    }

    const postObject = {
      admin_id: payload?.admin_id,
      first_name: payload.tenant_firstName,
      last_name: payload.tenant_lastName,
      email_name: payload.tenant_email,
      customer_vault_id: payload.customer_vault_id,
      billing_id: payload.billing_id,
      surcharge: Number(payload.surcharge),
      amount: payload.total_amount,
      tenantId: payload.tenant_id,
      date: moment().format("YYYY-MM-DD"),
      processor_id: payload.processorId,
    };

    const nmiResponse = await interceptor.post(`nmipayment/sale`, {
      paymentDetails: postObject,
    });

    if (nmiResponse.data.statusCode === 100) {
      const paymentObject = {
        admin_id: payload?.admin_id,
        tenant_id: payload.tenant_id,
        lease_id: payload.lease_id,
        surcharge: payload.surcharge,
        payment_type: "Card",
        customer_vault_id: payload.customer_vault_id,
        billing_id: payload.billing_id,
        response: "SUCCESS",
        total_amount: payload.total_amount,
        type: "Payment",
        transaction_id: nmiResponse.data.data.transactionid,
        notificationTime: moment().format("YYYY-MM-DD HH:mm:ss")
      };

      const paymentResponse = await interceptor.post(
        `payment/tenant-payment`,
        paymentObject
      );
      if (paymentResponse.data.statusCode === 200) {
        toast(paymentResponse.data.message, {
          position: "top-center",
        });
      }
    }else{
      swal({
        title: "Payment Failed!",
        text: `${nmiResponse.data.message}`,
        icon: "warning",
        button: "OK",
      });
    }
  } catch (error) {
    throw error;
  }
};

// Update Card transaction type (credit/debit)
export const updateCardTransactionType = async (payload) => {
  try {
    const response = await interceptor.post(
      `payment/rental_owner/setting`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update Card transaction type (credit/debit)
export const GetCardTransactionType = async (id) => {
  try {
    const response = await interceptor.get(
      `payment/rental_owner/setting/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update Card transaction type (credit/debit)
export const getRentalOwnerCardTypeSettings = async (tenantId, lease_id) => {
  try {
    const response = await interceptor.get(
      `tenant/payment_settings/${tenantId}/${lease_id}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add recurring cards
export const addRecurringCards = async (data) => {
  try {
    const response = await interceptor.post(`recurring-cards/add-cards`, data);

    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};

// Get recurring cards
export const getRecurringCards = async (data) => {
  try {
    const response = await interceptor.post(`recurring-cards/get-cards`, data);

    return response.data;
  } catch (error) {
    if (typeof error === "string") throw error;
    else if (Object.keys(error).length) throw JSON.stringify(error);
    else throw "Network problem";
  }
};
