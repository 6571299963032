import { CircularProgress } from "@mui/material";

const { Button } = require("reactstrap");

const BaseButton = ({
  label,
  onClick,
  variant,
  type,
  id,
  disabled,
  styles,
  loading,
}) => {
  const isOutline = variant === "outline";

  const loadingStyles = {
    opacity: "0.7",
    color: "var(--bg-blue-color)",
  };

  const ButtonStyles = {
    background: isOutline ? "none" : "var(--bg-blue-color)",
    color: isOutline ? "#000" : "var(--text-white-color)",
    fontWeight: "500",
    width: "fit-content",
    border: isOutline ? "1px solid var(--bg-blue-color)" : "",
    boxShadow: "none",
    opacity: disabled ? "0.7" : "1",
    cursor: disabled ? "not-allowed" : "pointer",
    marginRight: "0px",
    ...styles,
    ...(loading && loadingStyles),
  };

  const loaderStyles = {
    position: "absolute",
    inset: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div
      style={{
        position: "relative",
        width: "fit-content",
        ...(loading && { cursor: "progress" }),
      }}
    >
      <Button
        onClick={onClick}
        id={id}
        style={ButtonStyles}
        variant={variant}
        type={type || "button"}
        disabled={disabled || loading}
      >
        {label}
      </Button>
      {loading && (
        <div style={loaderStyles}>
          <CircularProgress size="20px" style={{ color: "white" }} />
        </div>
      )}
    </div>
  );
};

export default BaseButton;
