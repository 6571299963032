// import React from 'react'
import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import { Card, CardBody, Col, Input, InputGroup } from "reactstrap";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";

const useStyles = makeStyles(() => ({
  button: {
    marginTop: "8px",
    marginRight: "8px",
  },
}));

const steps = ["About You", "Customize Trial", "Final"];

const TrialLogin = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [cookies, setCookie] = useCookies(["token"]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    termsAndConditions: false,
  });

  const navigate = useNavigate();
  const handleNext = (id) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "termsAndConditions" ? checked : value,
    }));
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    const object = {
      first_name: loginformik.values.firstName,
      last_name: loginformik.values.lastName,
      email: loginformik.values.businessEmail,
      company_name: loginformik.values.componyName,
      phone_number: loginformik.values.phoneNumber,
      password: loginformik.values.password,
    };

    try {
      const response = await axios.post(`${baseUrl}/admin/register`, object);

      if (response.data.statusCode === 200) {
        toast.success("Logged in Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        const logindata = {
          email: response.data.data.email,
          password: loginformik.values.password,
          admin_id: response.data.data.admin_id,
          role: "admin",
          is_web: true,
        };
        const adminRes = await axios.post(`${baseUrl}/auth/login`, logindata);
        const res = adminRes.data;
        setTimeout(() => {
          setCookie("token", adminRes.data.token, { path: "/" });
          setCookie("ID", adminRes.data.id, { path: "/" });
          navigate(`/${res.company_name}/index`);
        }, 500);
      } else if (response.data.statusCode === 401) {
        toast.error("Email already exist in system", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (response.data.statusCode === 402) {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const step0ValidationSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    businessEmail: yup
      .string()
      .email("Invalid email format")
      .required("Required"),
  });

  const passwordValidation = yup
    .string()
    .required("No password provided")
    .min(12, "Password must be at least 12 characters long")
    .max(16, "Password cannot be longer than 16 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/,
      "Must contain an uppercase letter, lowercase letter, number, and special character"
    )
    .test("zxcvbn-strength", "Password is too weak or common", (value) => {
      const result = zxcvbn(value);
      return result.score >= 3;
    })
    .test(
      "no-sequential-or-repeating",
      "Avoid sequential or repeating patterns like '1234' or 'aaaa'",
      (value) => {
        return !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value);
      }
    );

  const step1ValidationSchema = yup.object({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    businessEmail: yup
      .string()
      .email("Invalid email format")
      .required("Required"),
    componyName: yup.string().required("Required"),
    phoneNumber: yup.number().required("Required"),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
    password: passwordValidation,
    // password: yup
    //   .string()
    //   .required("No Password Provided")
    //   .min(8, "Password is too short")
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must contain one uppercase, one lowercase, one number, and one special character"
    //   ),
    confpassword: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const loginformik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessEmail: "",
      componyName: "",
      phoneNumber: "",
      password: "",
      confpassword: "",
      termsAndConditions: false,
    },
    validationSchema:
      activeStep === 0 ? step0ValidationSchema : step1ValidationSchema,
    onSubmit: () => {
      if (activeStep === 0) {
        setActiveStep(1);
      } else if (activeStep === 1) {
        // setActiveStep(3);
        handleSubmit();
        // setOpenDialog(true);
      } else if (activeStep === 2) {
        setActiveStep(3);
      }
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  return (
    <>
      <Col lg="5" md="7">
        <Card
          className="bg-secondary shadow border-0"
          //   onSubmit={loginFormik.handleSubmit}
        >
          <CardBody className="px-lg-4 py-lg-4">
            <div className="text-center text-muted mb-4">
              <small>Sign up for your free trial account</small>
            </div>
            <div>
              <div>
                {activeStep === 0 && (
                  <div>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-firname"
                            placeholder="First Name *"
                            type="text"
                            name="firstName"
                            value={loginformik.values.firstName}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                            required
                          />
                          {loginformik.touched.firstName &&
                            loginformik.errors.firstName && (
                              <div
                                className="error-message"
                                style={{ color: "red", marginTop: "5px" }}
                              >
                                {loginformik.errors.firstName}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Last Name *"
                            type="text"
                            name="lastName"
                            value={loginformik.values.lastName}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                            required
                          />
                          {loginformik.touched.lastName &&
                            loginformik.errors.lastName && (
                              <div
                                className="error-message"
                                style={{ color: "red", marginTop: "5px" }}
                              >
                                {loginformik.errors.lastName}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-businessEmail"
                            placeholder="Business Email *"
                            type="email"
                            name="businessEmail"
                            value={loginformik.values.businessEmail}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                            required
                          />
                          {loginformik.touched.businessEmail &&
                            loginformik.errors.businessEmail && (
                              <div
                                className="error-message"
                                style={{ color: "red", marginTop: "5px" }}
                              >
                                {loginformik.errors.businessEmail}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="text-center">
                          <Button
                            variant="contained"
                            sx={{
                              mt: 2,
                              mb: 2,
                              width: "100%",
                              backgroundColor: "#152B51",
                              color: "white",
                            }}
                            onClick={() => {
                              loginformik.handleSubmit(activeStep);
                            }}
                            className={classes.button}
                          >
                            Create Your Free Trial
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {activeStep === 1 && (
                  <div>
                    <Grid container>
                      <Grid item xs={6}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-firname"
                            placeholder="First Name"
                            type="text"
                            name="firstName"
                            value={loginformik.values.firstName}
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-lastname"
                            placeholder="Last Name"
                            type="text"
                            name="lastName"
                            value={loginformik.values.lastName}
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-businessEmail"
                            placeholder="Business Email"
                            type="email"
                            name="businessEmail"
                            value={loginformik.values.businessEmail}
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-companyName"
                            placeholder="Company Name"
                            type="text"
                            name="componyName"
                            value={loginformik.values.componyName}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                          />
                          {loginformik.touched.componyName &&
                            loginformik.errors.componyName && (
                              <div className="error-message text-danger">
                                {loginformik.errors.componyName}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <Input
                            className="form-control-alternative"
                            id="input-number"
                            placeholder="Phone Number"
                            type="number"
                            name="phoneNumber"
                            value={loginformik.values.phoneNumber}
                            onChange={(e) => {
                              loginformik.handleChange(e);
                            }}
                          />
                          {loginformik.touched.phoneNumber &&
                            loginformik.errors.phoneNumber && (
                              <div className="error-message text-danger">
                                {loginformik.errors.phoneNumber}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <InputGroup className="input-group-alternative">
                            <Input
                              id="standard-adornment-password"
                              autoComplete="new-password"
                              name="password"
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              value={loginformik.values.password}
                              onChange={loginformik.handleChange}
                              onBlur={loginformik.handleBlur}
                              // className={
                              //   loginformik.touched.password && loginformik.errors.password
                              //     ? "error"
                              //     : ""
                              // }
                            />

                            <IconButton
                              type="button"
                              style={{ padding: "7px" }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {<VisibilityIcon />}
                            </IconButton>
                          </InputGroup>
                          {loginformik.touched.password &&
                            loginformik.errors.password && (
                              <div className="error-message text-danger">
                                {loginformik.errors.password}
                              </div>
                            )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          className="formInput"
                          style={{ margin: "10px 10px" }}
                        >
                          <InputGroup className="input-group-alternative">
                            <Input
                              id="standard-adornment-password"
                              autoComplete="new-password"
                              name="confpassword"
                              placeholder="Confirm Password"
                              type={showConfPassword ? "text" : "password"}
                              value={loginformik.values.confpassword}
                              onChange={loginformik.handleChange}
                              onBlur={loginformik.handleBlur} // Add onBlur event handler
                              // className={
                              //   loginformik.touched.confpassword && loginformik.errors.confpassword
                              //     ? "error" // Apply error class if field is touched and has error
                              //     : ""
                              // }
                            />

                            <IconButton
                              type="button"
                              style={{ padding: "7px" }}
                              onClick={() =>
                                setShowConfPassword(!showConfPassword)
                              }
                            >
                              {<VisibilityIcon />}
                            </IconButton>
                          </InputGroup>
                          {loginformik.touched.confpassword &&
                            loginformik.errors.confpassword && (
                              <div className="error-message text-danger">
                                {loginformik.errors.confpassword}
                              </div>
                            )}
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <Checkbox
                          id="checked"
                          name="termsAndConditions"
                          checked={loginformik.values.termsAndConditions}
                          onChange={(e) => {
                            const { checked } = e.target;
                            loginformik.setFieldValue(
                              "termsAndConditions",
                              checked
                            );
                          }}
                        />
                        I have read and accept CloudRentalManager terms and
                        conditions
                        {loginformik.touched.termsAndConditions &&
                          loginformik.errors.termsAndConditions && (
                            <div
                              className="error-message"
                              style={{ color: "red", marginTop: "5px" }}
                            >
                              {loginformik.errors.termsAndConditions}
                            </div>
                          )}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="text-center">
                          <Button
                            variant="contained"
                            sx={{
                              mt: 2,
                              mb: 2,
                              width: "100%",
                              backgroundColor: "#152B51",
                              color: "white",
                            }}
                            onClick={() => {
                              loginformik.handleSubmit();
                            }}
                            className={classes.button}
                          >
                            Create Your Free Trial
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                    {/* Add the other 8 fields here */}
                  </div>
                )}
              </div>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                sx={{ textAlign: "center" }}
              >
                <DialogTitle>
                  <div className="text-center">
                    <CheckCircleIcon sx={{ color: "green", fontSize: 50 }} />
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div className="text-center">
                    <p className="text-center">
                      <strong>Your trial account is being ready!</strong>
                    </p>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "inline-block", width: "60%" }}
                  >
                    <p>
                      Feel free to access the trial account. Once you sign up,
                      we'll start you with a fresh account
                    </p>
                  </div>
                  <div className="text-center">
                    {loader ? (
                      <Button disabled variant="contained" color="primary">
                        Loading ...
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setActiveStep(2);
                          handleSubmit();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Get Started
                      </Button>
                    )}
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                size="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "70%",
                }}
              >
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <StepLabel />
                    </div>
                    <div>
                      <Typography fontSize={9}>{label}</Typography>
                    </div>
                  </Step>
                ))}
              </Stepper>
            </div>
          </CardBody>
        </Card>
        <ToastContainer />
      </Col>
    </>
  );
};

export default TrialLogin;
