import {
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import { RotatingLines } from "react-loader-spinner";
import WestIcon from "@mui/icons-material/West";
import Header from "components/Headers/Header";
import * as React from "react";
import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./applicants.css";
import axiosInstance from "axiosInstance";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const Applicants = () => {
  const { admin } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [permissions, setPermissions] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const location = useLocation();

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [rentalsData, setRentalsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedTenants, setSelectedTenants] = useState([]);

  const [propertyData, setPropertyData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [userdropdownOpen, setuserDropdownOpen] = React.useState(false);
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedPropertyId, setselectedPropertyId] = useState("");
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const handleSearch = (e) => {
    setSearchQuery1(e.target.value);
  };
  const toggle9 = () => {
    setuserDropdownOpen((prevState) => !prevState);
  };

  const toggle10 = () => {
    setUnitDropdownOpen((prevState) => !prevState);
  };

  const fetchUnitsByProperty = async (propertyType) => {
    try {
      const response = await axiosInstance.get(
        `/propertyunit/rentals_property/${propertyType}`
      );
      const units = response.data?.data || [];
      return units;
    } catch (error) {
      console.error("Error fetching units:", error);
      return [];
    }
  };

  const fetchUnitData = async (rental_id) => {
    if (rental_id) {
      try {
        const res = await axiosInstance.get(
          `/unit/rental_unit_dropdown/${rental_id}`
        );
        if (res.data.statusCode === 200) {
          const filteredData = res.data.data.filter(
            (item) => item.rental_unit !== ""
          );
          if (filteredData.length === 0) {
            applicantFormik.setFieldValue("unit_id", res.data.data[0].unit_id);
          }
          setUnitData(filteredData);
        } else if (res.data.statusCode === 201) {
          setUnitData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const handlePropertyTypeSelect = async (property) => {
    if (!property) {
      setSelectedPropertyType("");
      setselectedPropertyId("");
      applicantFormik.setFieldValue("rental_adress", "");
      setSelectedUnit("");
      setUnitData([]);
      return;
    }

    setSelectedPropertyType(property.rental_adress);
    setselectedPropertyId(property.rental_id);
    applicantFormik.setFieldValue("rental_adress", property.rental_adress);
    setSelectedUnit("");

    try {
      const units = await fetchUnitsByProperty(property.rental_adress);
      setUnitData(units);
      fetchUnitData(property.rental_id);
    } catch (error) {
      console.error("Error handling selected property:", error);
    }
  };

  const [unitId, setUnitId] = useState(null);
  const handleUnitSelect = (selectedUnit) => {
    setSelectedUnit(selectedUnit.rental_unit);
    setUnitId(selectedUnit.unit_id);
    applicantFormik.setFieldValue("rental_unit", selectedUnit.rental_unit);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = (applicant) => {
    setSelectedApplicant(applicant);
    openModal();
  };

  const handleSelectedApplicantChange = (e) => {
    const { name, value } = e.target;
    setSelectedApplicant((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getTableData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/applicant/applicant/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          setTotalPages(Math.ceil(response.data.data.length / pageItem));
          setRentalsData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    getTableData();
    getApplicatsLimit();
  }, [accessType, pageItem, upArrow, sortBy]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (rentalsData) {
    paginatedData = rentalsData.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showRentalOwnerTable, setshowRentalOwnerTable] = useState(false);
  const [checkedCheckbox, setCheckedCheckbox] = useState();
  const [rentalownerData, setRentalownerData] = useState([]);

  const handleChange = () => {
    setshowRentalOwnerTable(!showRentalOwnerTable);
  };

  const handleCheckboxChange = (event, tenantInfo, mobileNumber) => {
    console.warn(tenantInfo, "tenantInfo");
    if (checkedCheckbox === mobileNumber) {
      setCheckedCheckbox(null);
    } else {
      setCheckedCheckbox(mobileNumber);
    }

    if (event.target.checked) {
      setSelectedTenants([tenantInfo, ...selectedTenants]);
      applicantFormik.setValues({
        applicant_firstName: tenantInfo.applicant_firstName,
        applicant_id: tenantInfo.applicant_id,
        applicant_lastName: tenantInfo.applicant_lastName || "",
        applicant_email: tenantInfo.applicant_email || "",
        tenant_mobileNumber: tenantInfo.applicant_phoneNumber || "",
        tenant_homeNumber: tenantInfo.applicant_homeNumber || "",
        tenant_workNumber: tenantInfo.applicant_telephoneNumber || "",
        tenant_businessNumber: tenantInfo.applicant_businessNumber || "",
      });
      setshowRentalOwnerTable(false);
    } else {
      setSelectedTenants(
        selectedTenants.filter((tenant) => tenant !== tenantInfo)
      );
      applicantFormik.setValues({
        applicant_firstName: "",
        applicant_lastName: "",
        applicant_email: "",
        applicant_phoneNumber: "",
        applicant_homeNumber: "",
        applicant_workNumber: "",
      });
    }
  };

  let navigate = useNavigate();

  const applicantFormik = useFormik({
    initialValues: {
      applicant_firstName: "",
      applicant_lastName: "",
      applicant_email: "",
      tenant_mobileNumber: "",
      tenant_homeNumber: "",
      tenant_workNumber: "",
      tenant_businessNumber: "",
      tenant_faxPhoneNumber: "",
      rental_adress: "",
      rental_unit: "",
      statusUpdatedBy: "",
    },
    validationSchema: yup.object({
      applicant_firstName: yup.string().required("Please enter first name"),
      applicant_lastName: yup.string().required("Please enter last name"),
      applicant_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      tenant_mobileNumber:yup
      .string()
      .required("Please enter phone number")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "mobile number must be in the format (xxx) xxx-xxxx"
      ),
      tenant_homeNumber:yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "home number must be in the format (xxx) xxx-xxxx"
      ),
      tenant_businessNumber:yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "business number must be in the format (xxx) xxx-xxxx"
      ),
      tenant_workNumber:yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "work number must be in the format (xxx) xxx-xxxx"
      ),
      rental_adress: yup.string().required("Please select property"),
    }),
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  const handleFormSubmit = (values, action) => {
    setBtnLoader(true);
    try {
      const requestBody = {
        applicant: {
          applicant_id: values.applicant_id,
          applicant_firstName: values.applicant_firstName,
          applicant_lastName: values.applicant_lastName,
          applicant_email: values.applicant_email,
          applicant_phoneNumber: values.tenant_mobileNumber,
          applicant_homeNumber: values.tenant_homeNumber,
          applicant_businessNumber: values.tenant_businessNumber,
          applicant_telephoneNumber: values.tenant_faxPhoneNumber,
          admin_id: accessType?.admin_id,
        },
        lease: {
          rental_id: selectedPropertyId,
          unit_id: unitId || "",
          admin_id: accessType?.admin_id,
        },
      };

      axiosInstance
        .post(`/applicant/applicant`, requestBody)
        .then((response) => {
          if (response.data.statusCode === 200) {
            closeModal();
            applicantFormik.resetForm();
            toast.success("Applicant Added Successfully", {
              position: "top-center",
              autoClose: 500,
            });
            navigate(
              admin
                ? `/${admin}/Applicants/${response.data.data.data.applicant_id}`
                : `/staff/staffapplicantsummary/${response.data.data.data.applicant_id}`
            );
            setSelectedPropertyType("");
            applicantFormik.setFieldValue("rental_adress", "");
          } else {
            swal(
              "Failed!",
              "Applicant number already exists in system",
              "warning"
            );
          }
        })
        .catch((error) => {
          console.error("Error creating applicant:", error);
          swal(
            "Error!",
            "An error occurred while creating the applicant",
            "error"
          );
        })
        .finally(() => {
          setBtnLoader(false); 
        });
    } catch (error) {
      console.error("Error in submit applicant:", error);
      setBtnLoader(false);
      swal("Error!", "An error occurred while creating the applicant", "error");
    }
  };

  //get data apis
  const fetchPropertyData = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/rentals/rentals/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          setPropertyData(res.data.data);
        } else if (res.data.statusCode === 201) {
          setPropertyData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [accessType]);

  const fetchExistingPropetiesData = async () => {
    try {
      const response = await axiosInstance.get(
        `/applicant/applicant/${accessType?.admin_id}`
      );
      const data = response.data;

      if (data.statusCode === 200) {
        setRentalownerData(data.data);
      } else {
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchExistingPropetiesData();
  }, [accessType]);

  const getApplicantData = () => {
    axiosInstance
      .get(`/applicant/applicant_lease/${accessType?.admin_id}`)
      .then((response) => {
        setRentalsData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getApplicantData();
  }, [accessType, isModalOpen]);

  const deleteApplicant = (applicant_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this applicant!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            console.log("Input Value:", reason);

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;

        axiosInstance
          .delete(`/applicant/applicant/${applicant_id}`, {
            data: {
              reason: deletionReason,
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Applicant Deleted Successfully!", {
                position: "top-center",
                autoClose: 1000,
              });
              setTimeout(() => {
                getApplicantData();
                getApplicatsLimit();
              }, 500);
            } else if (response.data.statusCode === 201) {
              toast.warn(response.data.message, {
                position: "top-center",
              });
            } else {
              toast.error(response.data.message, {
                position: "top-center",
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting Applicant:", error);
            toast.error(error.message, {
              position: "top-center",
            });
          });
      } else {
        toast.success("Applicant is safe :)", {
          position: "top-center",
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const filterApplicantsBySearch = () => {
    let filteredData = rentalsData;

    if (searchQuery) {
      filteredData = filteredData.filter((applicant) => {
        const fullName = `${applicant.applicant_firstName} ${applicant.applicant_lastName}`;
        return (
          fullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          applicant?.rental_data?.rental_adress
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          applicant?.applicant_status?.status
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          applicant?.applicant_email
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          applicant?.applicant_phoneNumber
            ?.toString()
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          applicant?.createdAt
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          applicant?.updatedAt
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });
    }

    if (upArrow.length > 0) {
      upArrow.forEach((sort) => {
        switch (sort) {
          case "rental_adress":
            filteredData.sort((a, b) =>
              a.rentalData.rental_adress?.localeCompare(
                b.unitData.rental_adress
              )
            );
            break;
          case "applicant_lastName":
            filteredData.sort((a, b) =>
              a.applicant_lastName?.localeCompare(b.applicant_lastName)
            );
            break;
          case "applicant_firstName":
            filteredData.sort((a, b) =>
              a.applicant_firstName?.localeCompare(b.applicant_firstName)
            );
            break;
          case "applicant_phoneNumber":
            filteredData.sort(
              (a, b) => a.applicant_phoneNumber - b.applicant_phoneNumber
            );
            break;
          case "applicant_email":
            filteredData.sort((a, b) =>
              a.applicant_email?.localeCompare(b.applicant_email)
            );
            break;
          case "start_date":
            filteredData.sort(
              (a, b) => new Date(a.start_date) - new Date(b.start_date)
            );
            break;
          case "createdAt":
            filteredData.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterApplicantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };
  const [countRes, setCountRes] = useState("");

  const getApplicatsLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/applicant/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  useEffect(() => {
    getApplicatsLimit();
  }, [rentalsData]);

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };


  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            {(admin || permissions?.applicant_add) && (
              <Button
                className="applicantbtns background-colorsty bgtextwhite fontfamilysty"
                onClick={() => {
                  // if (countRes.statusCode === 201) {
                  //   swal(
                  //     "Plan Limitation",
                  //     "The limit for adding applicants according to the plan has been reached.",
                  //     "warning"
                  //   );
                  // } else {
                    setSelectedApplicant(null);
                    openModal();
                  // }
                }}
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Add Applicant
              </Button>
            )}
          </Col>

          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Applicants
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
            
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh titleecolor"
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle rentaladded fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.applicantCount}
                        </b>{" "}
                        {/* {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.applicantCountLimit}
                        </b> */}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        FirstName
                        {sortBy.includes("applicant_firstName") ? (
                          upArrow.includes("applicant_firstName") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("applicant_firstName")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("applicant_firstName")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("applicant_firstName")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        LastName
                        {sortBy.includes("applicant_lastName") ? (
                          upArrow.includes("applicant_lastName") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("applicant_lastName")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("applicant_lastName")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("applicant_lastName")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Email
                        {sortBy.includes("applicant_email") ? (
                          upArrow.includes("applicant_email") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("applicant_email")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("applicant_email")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("applicant_email")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Phone Number
                        {sortBy.includes("applicant_phoneNumber") ? (
                          upArrow.includes("applicant_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("applicant_phoneNumber")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("applicant_phoneNumber")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("applicant_phoneNumber")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Property
                        {sortBy.includes("rental_adress") ? (
                          upArrow.includes("rental_adress") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_adress")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_adress")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Created At
                        {sortBy.includes("createdAt") ? (
                          upArrow.includes("createdAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("createdAt")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Updated At
                      </th>
                      {(admin ||
                        permissions?.applicant_edit ||
                        permissions?.applicant_delete) && (
                        <th
                          className="tablefontstyle textcolorblue"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                          }}
                        >
                          Actions
                        </th>
                      )}
                    </tr>
                  </thead>
                  {rentalsData.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Applicants Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((applicant) => (
                        <tr
                          key={applicant.applicant_id}
                          onClick={() =>
                            navigate(
                              admin
                                ? `/${admin}/Applicants/${applicant.applicant_id}`
                                : `/staff/staffapplicantsummary/${applicant.applicant_id}`
                            )
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.applicant_firstName}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.applicant_lastName}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.applicant_email}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.applicant_phoneNumber}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.rentalData?.rental_adress}{" "}
                            {applicant?.unitData &&
                            applicant?.unitData?.rental_unit
                              ? " - " + applicant?.unitData?.rental_unit
                              : null}
                          </td>

                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.applicant_status?.length > 0
                              ? applicant.applicant_status[
                                  applicant.applicant_status.length - 1
                                ].status
                              : "Undecided"}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(applicant?.createdAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {applicant?.updatedAt
                              ? moment(applicant?.updatedAt).format(dateFormat)
                              : " "}
                          </td>
                          {(admin ||
                            permissions?.applicant_edit ||
                            permissions?.applicant_add) && (
                            <td className="bordertopintd tablebodyfont">
                              <div style={{ display: "flex", gap: "5px" }}>
                                {(admin || permissions?.applicant_delete) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      applicant.admin_id === "is_trial"
                                        ? freetrialData()
                                        : deleteApplicant(
                                            applicant.applicant_id
                                          );
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                                {(admin || permissions?.applicant_edit) && (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      applicant.admin_id === "is_trial"
                                        ? freetrialData()
                                        : handleEdit(applicant);
                                    }}
                                  >
                                    <img
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <Modal isOpen={isModalOpen} toggle={closeModal}>
          <Form onSubmit={applicantFormik.handleSubmit}>
            <ModalHeader className="">
              <span
                className="labelfontstyle textcolorblue fontfamilysty"
                style={{
                  fontSize: "18px",
                  fontweight: "500",
                }}
              >
                {selectedApplicant ? "Edit Applicant" : "Add Applicant"}
              </span>

            </ModalHeader>

            <ModalBody>
              {selectedApplicant ? (
       
                <div>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-property"
                          style={{
                            fontSize: "16px",
                            fontweight: "500",
                          }}
                        >
                          First Name *
                        </label>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          id="applicant_firstName"
                          placeholder="Firstname"
                          name="applicant_firstName"
                          value={selectedApplicant.applicant_firstName}
                          onChange={(e) => {
                            if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                              selectedApplicant.setFieldValue(
                                "applicant_firstName",
                                e.target.value
                              );
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-property"
                          style={{
                            fontSize: "16px",
                            fontweight: "500",
                          }}
                        >
                          Last Name *
                        </label>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          id="applicant_lastName"
                          placeholder="Lastname"
                          name="applicant_lastName"
                          value={selectedApplicant.applicant_lastName}
                          onChange={handleSelectedApplicantChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="">
                    <label
                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                      htmlFor="input-property"
                      style={{
                        fontSize: "16px",
                        fontweight: "500",
                      }}
                    >
                      Email *
                    </label>
                    <InputGroup
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="fontstylerentmodal titleecolor"
                        type="text"
                        id="applicant_email"
                        placeholder="Enter Email"
                        name="applicant_email"
                        value={selectedApplicant.applicant_email}
                        onChange={handleSelectedApplicantChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3 mt-3">
                    <label
                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                      htmlFor="input-property"
                      style={{
                        fontSize: "16px",
                        fontweight: "500",
                      }}
                    >
                      Mobile Number *
                    </label>
                    <InputGroup
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="fas fa-mobile-alt"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="fontstylerentmodal titleecolor"
                        type="tel" 
                        id="tenant_mobileNumber"
                        name="applicant_phoneNumber"
                        placeholder="Enter Phone Number"
                        value={selectedApplicant.applicant_phoneNumber}
                        onChange={handleSelectedApplicantChange}
                    
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="fas fa-home"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="fontstylerentmodal titleecolor"
                        type="tel"
                        id="tenant_homeNumber"
                        placeholder="Enter Home Number"
                        name="applicant_homeNumber"
                        value={selectedApplicant.applicant_homeNumber}
                        onChange={handleSelectedApplicantChange}
                      
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mt-3 mb-3">
                    <InputGroup
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="fas fa-fax"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="fontstylerentmodal titleecolor"
                        id="tenant_businessNumber"
                        type="tel"
                        placeholder="Enter Business Number"
                        name="applicant_businessNumber"
                        value={selectedApplicant.applicant_businessNumber}
                        onChange={handleSelectedApplicantChange}
                       
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                      }}
                    >
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i className="fas fa-fax"></i>
                        </span>
                      </InputGroupAddon>
                      <Input
                        className="fontstylerentmodal titleecolor"
                        type="tel"
                        id="tenant_workNumber"
                        placeholder="Enter Telephone Number"
                        name="applicant_telephoneNumber"
                        value={selectedApplicant.applicant_telephoneNumber}
                        onChange={handleSelectedApplicantChange}
                       
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
              ) : (
                !showRentalOwnerTable && (
                  <div>
                    <div>
                     
                    </div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              fontSize: "16px",
                              fontweight: "500",
                            }}
                          >
                            First Name *
                          </label>
                          <Input
                            className="fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            id="applicant_firstName"
                            placeholder="First name "
                            name="applicant_firstName"
                            autoComplete="off"
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "applicant_firstName",
                                  e.target.value
                                );
                              }
                            }}
                            value={applicantFormik.values.applicant_firstName}
                          />
                          {applicantFormik.touched.applicant_firstName &&
                          applicantFormik.errors.applicant_firstName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red", marginBottom: "10px" }}
                            >
                              {applicantFormik.errors.applicant_firstName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              fontSize: "16px",
                              fontweight: "500",
                            }}
                          >
                            Last Name *
                          </label>
                          <Input
                            className="fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            id="applicant_lastName"
                            placeholder="Last name "
                            name="applicant_lastName"
                            autoComplete="off"
                            onBlur={applicantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                applicantFormik.setFieldValue(
                                  "applicant_lastName",
                                  e.target.value
                                );
                              }
                            }}
                            value={applicantFormik.values.applicant_lastName}
                          />
                          {applicantFormik.touched.applicant_lastName &&
                          applicantFormik.errors.applicant_lastName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red", marginBottom: "10px" }}
                            >
                              {applicantFormik.errors.applicant_lastName}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup className="mt-2">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-property"
                        style={{
                          fontSize: "16px",
                          fontweight: "500",
                        }}
                      >
                        Email *
                      </label>
                      <InputGroup
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="fas fa-envelope"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          type="text"
                          id="applicant_email"
                          placeholder="Enter email"
                          name="applicant_email"
                          autoComplete="off"
                          value={applicantFormik.values.applicant_email}
                          onBlur={applicantFormik.handleBlur}
                          onChange={applicantFormik.handleChange}
                        />
                      </InputGroup>
                      {applicantFormik.touched.applicant_email &&
                      applicantFormik.errors.applicant_email ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red", marginBottom: "10px" }}
                        >
                          {applicantFormik.errors.applicant_email}
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-property"
                        style={{
                          fontSize: "16px",
                          fontweight: "500",
                        }}
                      >
                        Mobile Number *
                      </label>
                      <InputGroup
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="fas fa-mobile-alt"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          type="tel" // Use type "tel" for mobile numbers
                          id="tenant_mobileNumber"
                          placeholder="Enter mobile number"
                          name="tenant_mobileNumber"
                          autoComplete="off"
                          onBlur={applicantFormik.handleBlur}
                          onChange={(e) => {
                            if (
                              applicantFormik.values.tenant_mobileNumber?.length > e.target.value?.length
                            ) {
                              applicantFormik.setFieldValue("tenant_mobileNumber", e.target.value);
                            } else {
                              const formattedValue = formatPhoneNumber(e.target.value);
                              applicantFormik.setFieldValue("tenant_mobileNumber", formattedValue);
                            }
                          }}
                          value={applicantFormik.values.tenant_mobileNumber}
                         
                        />
                      </InputGroup>
                      {applicantFormik.touched.tenant_mobileNumber &&
                      applicantFormik.errors.tenant_mobileNumber ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red", marginBottom: "10px" }}
                        >
                          {applicantFormik.errors.tenant_mobileNumber}
                        </div>
                      ) : null}
                    </FormGroup>

                    <FormGroup className="mt-3 mb-3">
                      <InputGroup
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="fas fa-home"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          type="text"
                          id="tenant_homeNumber"
                          placeholder="Enter home number"
                          autoComplete="off"
                          value={applicantFormik.values.tenant_homeNumber}
                          onBlur={applicantFormik.handleBlur}
                          onChange={(e) => {
                            if (
                              applicantFormik.values.tenant_homeNumber?.length > e.target.value?.length
                            ) {
                              applicantFormik.setFieldValue("tenant_homeNumber", e.target.value);
                            } else {
                              const formattedValue = formatPhoneNumber(e.target.value);
                              applicantFormik.setFieldValue("tenant_homeNumber", formattedValue);
                            }
                          }}
                         
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <InputGroup
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="fas fa-fax"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          id="tenant_businessNumber"
                          type="text"
                          placeholder="Enter business number"
                          autoComplete="off"
                          value={applicantFormik.values.tenant_businessNumber}
                          onBlur={applicantFormik.handleBlur}
                          onChange={(e) => {
                            if (
                              applicantFormik.values.tenant_businessNumber?.length > e.target.value?.length
                            ) {
                              applicantFormik.setFieldValue("tenant_businessNumber", e.target.value);
                            } else {
                              const formattedValue = formatPhoneNumber(e.target.value);
                              applicantFormik.setFieldValue("tenant_businessNumber", formattedValue);
                            }
                          }}
                         
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <InputGroup
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <span className="input-group-text">
                            <i className="fas fa-fax"></i>
                          </span>
                        </InputGroupAddon>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          type="text"
                          id="tenant_workNumber"
                          placeholder="Enter telephone number"
                          autoComplete="off"
                          value={applicantFormik.values.tenant_workNumber}
                          onBlur={applicantFormik.handleBlur}
                          onChange={(e) => {
                            if (
                              applicantFormik.values.tenant_workNumber?.length > e.target.value?.length
                            ) {
                              applicantFormik.setFieldValue("tenant_workNumber", e.target.value);
                            } else {
                              const formattedValue = formatPhoneNumber(e.target.value);
                              applicantFormik.setFieldValue("tenant_workNumber", formattedValue);
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-property"
                        style={{
                          fontSize: "16px",
                          fontweight: "500",
                        }}
                      >
                        Property *
                      </label>
                      <FormGroup style={{ marginRight: "15px" }}>
                        <SearchDropdown
                          options={propertyData}
                          value={
                            propertyData.find(
                              (p) => p.rental_adress === selectedPropertyType
                            ) || null
                          }
                          onChange={(event, value) => {
                            handlePropertyTypeSelect(value);
                          }}
                          placeholder="Select Property"
                          getOptionLabel={(option) =>
                            option.rental_adress || ""
                          }
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.rental_adress
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                        />
                      </FormGroup>
                    </FormGroup>
                    {unitData.length != 0 && (
                      <FormGroup className="mt-3">
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unit"
                          style={{
                            fontSize: "16px",
                            fontweight: "500",
                          }}
                        >
                          Unit *
                        </label>
                        <FormGroup>
                          <Dropdown
                            className="fontstylerentmodal"
                            isOpen={unitDropdownOpen}
                            toggle={toggle10}
                          >
                            <DropdownToggle
                              className="fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "100%",
                                marginRight: "15px",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                backgroundColor: "transparent",
                              }}
                            >
                              {selectedUnit ? selectedUnit : "Select Unit"}
                            </DropdownToggle>
                            <DropdownMenu className="fontstylerentmodal">
                              {unitData.length > 0 ? (
                                unitData.map((unit, index) => (
                                  <DropdownItem
                                    className="fontstylerentmodal"
                                    key={index}
                                    onClick={() => handleUnitSelect(unit)}
                                  >
                                    {unit.rental_unit}
                                  </DropdownItem>
                                ))
                              ) : (
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  disabled
                                >
                                  No units available
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                            {applicantFormik.errors &&
                            applicantFormik.errors?.rental_unit &&
                            applicantFormik.touched &&
                            applicantFormik.touched?.rental_unit &&
                            applicantFormik.values.rental_unit === "" ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {applicantFormik.errors.rental_unit}
                              </div>
                            ) : null}
                          </Dropdown>
                        </FormGroup>
                      </FormGroup>
                    )}
                  </div>
                )
              )}
              {showRentalOwnerTable && (
                <div
                  style={{
                    maxHeight: "400px",
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <Label
                      className="textcolorblue"
                      onClick={() =>
                        setshowRentalOwnerTable(!showRentalOwnerTable)
                      }
                      style={{ fontWeight: "bold " }}
                    >
                      <WestIcon />
                      Back
                    </Label>
                  </div>
                  <Input
                    type="text"
                    placeholder="Search by first and last name"
                    value={searchQuery1}
                    onChange={handleSearch}
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <div
                    style={{
                      maxHeight: "calc(400px - 40px)",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "15px",
                            }}
                          >
                            Applicant Name
                          </th>
                          <th
                            style={{
                              padding: "15px",
                            }}
                          >
                            Select
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(rentalownerData) &&
                          rentalownerData
                            .filter((tenant) => {
                              const fullName = `${tenant?.applicant_firstName} ${tenant?.applicant_lastName}`;
                              return fullName
                                .toLowerCase()
                                .includes(searchQuery1.toLowerCase());
                            })
                            .map((tenant, index) => (
                              <tr
                                key={index}
                                style={{
                                  border: "1px solid #ddd",
                                }}
                              >
                                <td
                                  style={{
                                    paddingLeft: "15px",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <pre>
                                    {tenant?.applicant_firstName}&nbsp;
                                    {tenant?.applicant_lastName}
                                    {`(${tenant?.applicant_phoneNumber})`}
                                  </pre>
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "15px",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <Checkbox
                                    type="checkbox"
                                    name="tenant"
                                    id={tenant.applicant_phoneNumber}
                                    checked={
                                      tenant.applicant_phoneNumber ===
                                      checkedCheckbox
                                    }
                                    onChange={(event) => {
                                      setCheckedCheckbox(
                                        tenant.applicant_phoneNumber
                                      );
                                      const tenantInfo = {
                                        applicant_phoneNumber:
                                          tenant.applicant_phoneNumber,
                                        applicant_firstName:
                                          tenant.applicant_firstName,
                                        applicant_lastName:
                                          tenant.applicant_lastName,
                                        applicant_homeNumber:
                                          tenant.applicant_homeNumber,
                                        applicant_email: tenant.applicant_email,
                                        applicant_businessNumber:
                                          tenant.applicant_businessNumber,
                                        applicant_telephoneNumber:
                                          tenant.applicant_telephoneNumber,
                                      };
                                      handleCheckboxChange(
                                        event,
                                        tenantInfo,
                                        tenant.applicant_phoneNumber
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              )}
            </ModalBody>

            <ModalFooter className="d-flex justify-content-start">
              {btnLoader ? (
                <Button
                  type="submit"
                  className="btn fontstylerentr background-colorsty bgtextwhite"
                  style={{
                    cursor: "not-allowed",
                  }}
                  disabled
                >
                  Loading...
                </Button>
              ) : (
                <>
                  {selectedApplicant ? (
                    <Button
                      className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                      onClick={() => {
                        setBtnLoader(true);
                      }}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      Update Applicant
                    </Button>
                  ) : (
                    <Button
                      className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                      type="submit"
                      disabled={!applicantFormik.isValid}
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      Add Applicant
                    </Button>
                  )}
                </>
              )}
              <Button
                className="fontstylerentr backgroundwhitesty textcolorblue"
                onClick={() => {
                  closeModal();
                  applicantFormik.resetForm();
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Cancel
              </Button>
              {!applicantFormik.isValid && (
                <div
                  className="fontstylerentr"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  Please fill in all fields correctly.
                </div>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <ToastContainer />
      </Container>
    </>
  );
};

export default Applicants;
