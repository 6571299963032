import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./vendor.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import { Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

const AddVendor = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { vendor_id, admin } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [vendorData, setVendorData] = useState(null);

  const passwordValidation = yup
      .string()
      .min(12, "Password must be at least 12 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .notOneOf(
        [/1234/, /abcd/, /qwerty/, /password/, /\d{4}/, /[a-z]{4}/],
        'Avoid sequential or repeating patterns like "1234", "abcd", etc.'
      )
      .required("Password is required");
  const validationSchema = yup.object({
    vendor_password: yup
      .string()
      .min(8, "Password is too short")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password is required"),

    vendor_email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  // Initialize formik form
  const VendorFormik = useFormik({
    initialValues: {
      admin_id: "",
      vendor_name: vendorData?.vendor_name || "",
      vendor_phoneNumber: vendorData?.vendor_phoneNumber || "",
      vendor_email: vendorData?.vendor_email || "",
      vendor_password: vendorData?.vendor_password || "",
    },
    validationSchema: yup.object({
      vendor_name: yup.string().required("Please enter name"),
      vendor_phoneNumber: yup
        .string()
        .required("Please enter phone number")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      vendor_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      vendor_password: yup.string()
      .min(12, 'Password must be at least 12 characters long')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
      .notOneOf([/1234/, /abcd/, /qwerty/, /password/, /\d{4}/, /[a-z]{4}/], 'Password cannot contain sequential patterns like "1234", "abcd", etc.')
      .required('Password is required')
    }),
    onSubmit: handleSubmit,
  });

  // State to hold vendor data fetched from the API
  const [isLoading, setIsLoading] = useState(true);
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      if (!admin && !accessData.permissions?.vendor_add) {
        navigate(admin ? `/${admin}/index` : "/staff/StaffDashboard");
      }
    };

    handleCheckToken();
  }, []);

  // Fetch vendor data if editing an existing vendor
  useEffect(() => {
    if (vendor_id) {
      axiosInstance
        .get(`/vendor/get_vendor/${vendor_id}`)
        .then((response) => {
          const vendorData = response.data.data;
          setVendorData(vendorData);
          setIsLoading(false);

          // Initialize the form with fetched data
          VendorFormik.setValues({
            vendor_name: vendorData?.vendor_name || "",
            vendor_phoneNumber: vendorData?.vendor_phoneNumber || "",
            vendor_email: vendorData?.vendor_email || "",
            vendor_password: vendorData?.vendor_password || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching vendor data:", error);
          setIsLoading(false);
        });
    }
  }, [vendor_id]);

  // Handle form submission
  async function handleSubmit(values) {
    setSubmitLoader(true);
    values.admin_id = accessType?.admin_id;
    try {
      if (vendor_id === undefined) {
        const res = await axiosInstance.post(`/vendor/vendor`, values);
        handleResponse(res);
      } else {
        const editUrl = `/vendor/update_vendor/${vendor_id}`;
        const res = await axiosInstance.put(editUrl, values);
        handleResponse(res);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  function handleResponse(response) {
    const successMessage = vendor_id
      ? "Vendor Updated Successfully"
      : "Vendor Added Successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        onClose: () =>
          navigate(admin ? `/${admin}/vendor` : `/staff/staffvendor`),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  // Handle cancel button click
  const handleCloseButtonClick = () => {
    navigate(admin ? `/${admin}/vendor` : `/staff/staffvendor`);
  };

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const handlePhoneChange = (e) => {
    if (
      VendorFormik.values.vendor_phoneNumber?.length > e.target.value?.length
    ) {
      VendorFormik.setFieldValue("vendor_phoneNumber", e.target.value);
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      VendorFormik.setFieldValue("vendor_phoneNumber", formattedValue);
    }
  };

  return (
    <>
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {vendor_id ? "Edit Vendor" : "Add Vendor"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="bg-white shadow mt-3 mx-3 mb-3 cardstyborderradius"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                border: "1px solid #324567",
              }}
            >
              <CardBody>
                <Form onSubmit={VendorFormik.handleSubmit}>
                  <div className="">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-staffmember-name"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Vendor Name *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "100%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-staffmember-name"
                            placeholder="Enter vendor name "
                            type="text"
                            name="vendor_name"
                            autoComplete="off"
                            onBlur={VendorFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                VendorFormik.setFieldValue(
                                  "vendor_name",
                                  e.target.value
                                );
                              }
                            }}
                            value={VendorFormik.values.vendor_name}
                          />
                          {VendorFormik.touched.vendor_name &&
                          VendorFormik.errors.vendor_name ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_name}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="staffmember_phoneNumber"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "100%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="staffmember_phoneNumber"
                            placeholder="Enter phone number"
                            type="text"
                            name="vendor_phoneNumber"
                            autoComplete="off"
                            onBlur={VendorFormik.handleBlur}
                            onChange={handlePhoneChange}
                            value={VendorFormik.values.vendor_phoneNumber}
                          />
                          {VendorFormik.touched.vendor_phoneNumber &&
                          VendorFormik.errors.vendor_phoneNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="">
                    <Row className="mt-2">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="staffmember_email"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Email *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "100%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="staffmember_email"
                            placeholder="Enter email"
                            type="text"
                            name="vendor_email"
                            autoComplete="off"
                            onBlur={VendorFormik.handleBlur}
                            onChange={VendorFormik.handleChange}
                            value={VendorFormik.values.vendor_email}
                          />
                          {VendorFormik.touched.vendor_email &&
                          VendorFormik.errors.vendor_email ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_email}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty m-0"
                            htmlFor="staffmember_password"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Password *
                            <Tooltip
                              title={
                                <Box style={{ fontSize: "12px" }}>
                                  <ul
                                    style={{ paddingLeft: "20px", margin: 0 }}
                                  >
                                    <li>
                                      At least one uppercase letter (A-Z).
                                    </li>
                                    <li>
                                      At least one lowercase letter (a-z).
                                    </li>
                                    <li>At least one number (0-9).</li>
                                    <li>
                                      At least one special character (e.g., @,
                                      #, $, etc.).
                                    </li>
                                    <li>
                                      Password must be at least 12 characters
                                      long.
                                    </li>
                                    <li>
                                      No continuous alphabetical characters
                                      (e.g., abcd) or continuous numerical
                                      characters (e.g., 1234).
                                    </li>
                                    <li>
                                      Avoid strictly sequential patterns (e.g.,
                                      Ak12345678!).
                                    </li>
                                    <li>
                                      Don't use birthdays, names, addresses, or
                                      other personal information.
                                    </li>
                                  </ul>
                                </Box>
                              }
                              placement="bottom"
                              arrow
                            >
                              <IconButton>
                                <Info
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    color: "#152B51",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "6px",
                              width: "100%",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <Input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                paddingRight: "40px",
                              }}
                              className="form-control-alternative fontstylerentmodal titleecolor"
                              id="staffmember_password"
                              placeholder="Enter password"
                              name="vendor_password"
                              autoComplete="off"
                              type={showPassword ? "text" : "password"}
                              onBlur={VendorFormik.handleBlur}
                              onChange={VendorFormik.handleChange}
                              value={VendorFormik.values.vendor_password}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "10px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon
                                  style={{ color: "#152B51" }}
                                />
                              ) : (
                                <VisibilityIcon style={{ color: "#152B51" }} />
                              )}
                            </div>
                          </div>
                          {VendorFormik.touched.vendor_password &&
                          VendorFormik.errors.vendor_password ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {VendorFormik.errors.vendor_password}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <Row className="my-row mt-3">
                    {submitLoader ? (
                      <Button
                        type="submit"
                        className="btn fontstylerentr background-colorsty bgtextwhite"
                        disabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        disabled={!VendorFormik.isValid}
                        onClick={() => {
                          VendorFormik.handleSubmit();
                        }}
                      >
                        {vendor_id ? "Update Vendor" : "Add Vendor"}
                      </Button>
                    )}

                    <Button
                      // color="primary"
                      className="btn fontstylerentr backgroundwhitesty textcolorblue"
                      onClick={handleCloseButtonClick}
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
};

export default AddVendor;
