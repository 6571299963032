import Index from "views/Index";
import Profile from "views/source/Profile.js";
import Rentals from "views/source/Rentals.js";
import PropertiesTable from "views/source/PropertiesTable";
import Leaseing from "views/source/Leaseing.js";
import TenantsTable from "views/source/TenantsTable.js";
import PropertyType from "views/source/PropertyType";
import AddPropertyType from "views/source/AddPropertyType";
import AddStaffMember from "views/source/AddStaffMember";
import StaffMember from "views/source/StaffMember";
import Rentalowner from "views/AdminViews/Rentals/ManageRentalOnwer";
import Login from "views/source/Login";
import RentalownerTable from "views/source/RentalownerTable";
import Listings from "views/source/Listings";
import Applicants from "views/source/Applicants";
import RentRoll from "views/source/RentRoll";
import PlanUpgrade from "views/source/PlanUpgrade";
import Plans from "views/source/Plans";
import Planpurches from "views/source/Planpurches";
import TenantDetailPage from "views/source/TenantDetailPage";
import RentRollDetail from "views/source/RentRollDetail";
import PropDetails from "views/source/PropDetails";
import RentRollLeaseing from "views/source/RentRollLeaseing";
import VendorDashBoard from "views/source/VendorDashBoard";
import VendorProfile from "views/source/VendorProfile";
import Workorder from "views/source/Workorder";
import AddWorkorder from "views/source/AddWorkorder";
import VendorWorkTable from "views/source/VendorWorkTable";
import VendoeWorkTable from "views/source/VendorWorkTable";
import Vendor from "views/source/Vendor";
import AddVendor from "views/source/AddVendor";
import WorkOrderDetails from "views/source/WorkOrderDetails";
import VendorWorkDetail from "views/source/VendorWorkDetail";
import VendorAddWork from "views/source/VendorAddWork";
import ApplicantSummary from "views/source/ApplicantSummary";
import AddPayment from "views/source/AddPayment";
import AddCharge from "views/source/AddCharge";
import Changepassword from "views/source/Changepassword";
import Forgetmail from "views/source/forgetmail";
import DemoPayment from "views/source/DemoPayment";
import SurchargeForm from "views/source/Surcharge";
import SurchargeTable from "views/source/SurchargeTable";
import Settings from "views/AdminViews/Settings/Settings";
import Purchaseplan from "views/source/purchaseplandetail";
import Reports from "views/source/Reports";
import UpcomingRenewal from "views/source/UpcomingRenewal";
import AuditLogs from "views/source/AuditLogs";
import Templates from "views/source/Templates";
import AddTemplates from "views/source/AddTemplate";
import Emails from "views/source/Emails";
import Emaillogs from "views/source/Emaillogs";
import DueRentReport from "views/Dashboard-Reports/Report";

// ==========================  Super Admin ===================================================

import ResetPassword from "views/source/Resetpassword";
import CreatePassword from "views/source/CreatePassword";

//  ====================== admin sidebar icons ======================
import Dashboard from "./assets/icons/AdminSidebar/dashboard.svg";
import Dashboard2 from "./assets/icons/AdminSidebar/dashboard2.svg";
import Property from "./assets/icons/AdminSidebar/Property.svg";
import Property2 from "./assets/icons/AdminSidebar/Property2.svg";
import Staffmember from "./assets/icons/AdminSidebar/Staffmember.svg";
import Staffmember2 from "./assets/icons/AdminSidebar/Staffmember2.svg";

import Admin from "./assets/icons/Admin.svg";
import admin2 from "./assets/icons/admin2.svg";
import Work from "./assets/icons/Work.svg";
import Work2 from "./assets/icons/Work Light.svg";
import Key from "./assets/icons/AdminSidebar/Key.svg";
import Thumb from "./assets/icons/AdminSidebar/Thumb.svg";
import Maintenance from "./assets/icons/AdminSidebar/Maintenance.svg";
import Key1 from "./assets/icons/AdminSidebar/Key2.svg";
import Thumb1 from "./assets/icons/AdminSidebar/thumbs.svg";
import Maintenance1 from "./assets/icons/AdminSidebar/Maintenancess.svg";
import Building from "./assets/icons/AdminSidebar/Building.svg";
import Building1 from "./assets/icons/AdminSidebar/Building1.svg";
import Owner from "./assets/icons/AdminSidebar/owner.svg";
import Owner1 from "./assets/icons/AdminSidebar/owner1.svg";
import User from "./assets/icons/AdminSidebar/User.svg";
import User1 from "./assets/icons/AdminSidebar/User1.svg";
import Lease from "./assets/icons/AdminSidebar/Lease.svg";
import Lease1 from "./assets/icons/AdminSidebar/Lease1.svg";
import Applicant from "./assets/icons/AdminSidebar/Applicant.svg";
import Applicant1 from "./assets/icons/AdminSidebar/Applicant1.svg";
import Vendors from "./assets/icons/AdminSidebar/Vendor.svg";
import Vendors1 from "./assets/icons/AdminSidebar/Vendor1.svg";
import Works from "./assets/icons/AdminSidebar/Work.svg";
import Works1 from "./assets/icons/AdminSidebar/Work1.svg";
import Report from "./assets/icons/AdminSidebar/Report.svg";
import Report1 from "./assets/icons/AdminSidebar/Report1.svg";
import RentalOwnerDetail from "views/AdminViews/Rentals/RentalOwnerDetail";
import Upcomingicon from "./assets/icons/AdminSidebar/upcoming renewal.svg";
import Upcomingicon1 from "./assets/icons/AdminSidebar/upcoming white.svg";
import Communication from "./assets/icons/AdminSidebar/Communication.svg";
import Communication1 from "./assets/icons/AdminSidebar/Communication1.svg";
import Sendemail from "./assets/icons/AdminSidebar/Send Email.svg";
import Sendemail1 from "./assets/icons/AdminSidebar/Send Email1.svg";
import Emaillog from "./assets/icons/AdminSidebar/Email Logs.svg";
import Emaillog1 from "./assets/icons/AdminSidebar/Email Logs1.svg";
import Template from "./assets/icons/AdminSidebar/Templates.svg";
import Template1 from "./assets/icons/AdminSidebar/Templates1.svg";
import AccountTotalReport from "views/source/AccountTotalReport";
import PaymentReport from "views/Payment-Exception-Report/Report";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <Index />,
    layout: "/admin",
    is_display: true,
  },
  {
    path: "/PropertyType",
    name: "Property Type",
    icon: Property,
    icon2: Property2,
    component: <PropertyType />,
    layout: "/admin",
    is_display: true,
  },
  {
    path: "/StaffMember",
    name: "Staff Member",
    icon: Staffmember,
    icon2: Staffmember2,
    component: <StaffMember />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Rentals",
    icon: Key,
    icon2: Key1,
    layout: "/admin",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        path: "/propertiesTable",
        name: "Properties",
        icon: Building,
        icon2: Building1,
        component: <PropertiesTable />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Rental Owner",
        path: "/RentalownerTable",
        icon: Owner,
        icon2: Owner1,
        component: <RentalownerTable />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Tenants",
        path: "/TenantsTable",
        icon: User,
        icon2: User1,
        component: <TenantsTable />,
        layout: "/admin",
        is_display: true,
      },
    ],
  },
  {
    name: "Leasing",
    icon: Thumb,
    icon2: Thumb1,
    layout: "/admin",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Rent Roll",
        path: "/Rentroll",
        icon: Lease,
        icon2: Lease1,
        component: <RentRoll />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Applicants",
        path: "/Applicants",
        icon: Applicant,
        icon2: Applicant1,
        component: <Applicants />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Upcoming Renewal",
        path: "/upcomingrenewal",
        icon: Upcomingicon,
        icon2: Upcomingicon1,
        component: <UpcomingRenewal />,
        layout: "/admin",
        is_display: true,
      },
    ],
  },
  {
    name: "Maintenance",
    icon: Maintenance,
    icon2: Maintenance1,
    layout: "/admin",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Vendor",
        path: "/vendor",
        icon: Vendors,
        icon2: Vendors1,
        component: <Vendor />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Work Order",
        path: "/Workorder",
        icon: Works,
        icon2: Works1,
        component: <Workorder />,
        layout: "/admin",
        is_display: true,
      },
    ],
  },
  {
    name: "Communication",
    icon: Communication,
    icon2: Communication1,
    layout: "/admin",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Send E-mail",
        path: "/emails",
        icon: Sendemail,
        icon2: Sendemail1,
        component: <Emails />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "E-mail Logs",
        path: "/email-logs",
        icon: Emaillog,
        icon2: Emaillog1,
        component: <Emaillogs />,
        layout: "/admin",
        is_display: true,
      },
      {
        name: "Templates",
        path: "/templates",
        icon: Template,
        icon2: Template1,
        component: <Templates />,
        layout: "/admin",
        is_display: true,
      },
    ],
  },
  {
    path: "/Reports",
    name: "Reports",
    icon: Report,
    icon2: Report1,
    component: <Reports />,
    layout: "/admin",
    is_display: true,
  },
  {
    path: "/activities",
    name: "Audit Logs",
    component: <AuditLogs />,
    layout: "/admin",
    is_display: false,
  },
  {
    name: "Add Template",
    path: "/add-template",
    component: <AddTemplates />,
    layout: "/admin",
    is_display: false,
  },
  {
    path: "/AddPropertyType",
    name: "Add Property",
    component: <AddPropertyType />,
    layout: "/admin",
  },
  {
    path: "/AddPropertyType/:id",
    name: "Add Property",
    component: <AddPropertyType />,
    layout: "/admin",
  },
  {
    path: "/AddStaffMember",
    name: "Add Staff Member",
    component: <AddStaffMember />,
    layout: "/admin",
  },
  {
    path: "/AddStaffMember/:id",
    name: "Add Staff Member",
    component: <AddStaffMember />,
    layout: "/admin",
  },
  {
    path: "/rentals",
    name: "Add Property",
    component: <Rentals />,
    layout: "/admin",
  },
  {
    path: "/rentals/:rental_id",
    name: "Add Property",
    component: <Rentals />,
    layout: "/admin",
  },
  {
    path: "/PropDetails/:rental_id",
    name: "Prop Details",
    component: <PropDetails />,
    layout: "/admin",
  },
  {
    path: "/Rentalowner",
    component: <Rentalowner />,
    layout: "/admin",
  },
  {
    path: "/Rentalowner/:id",
    component: <Rentalowner />,
    layout: "/admin",
  },
  {
    path: "/rentalownerdetail/:id",
    name: "Rental owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/admin",
  },
  {
    path: "/Leaseing",
    name: "Leaseing",
    component: <Leaseing />,
    layout: "/admin",
  },
  {
    path: "/Leaseing/:tenant_id",
    name: "Leaseing",
    component: <Leaseing />,
    layout: "/admin",
  },
  {
    path: "/tenantdetail/:id",
    name: "Tenant Detail",
    component: <TenantDetailPage />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing",
    name: "Rent Roll Leaseing",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing?data",
    name: "Rent Roll Leaseing",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing/:lease_id/:applicant_id",
    name: "Rent Roll Leaseing",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing/:lease_id",
    name: "Rent Roll Leaseing",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/rentrolldetail/:lease_id",
    name: "Rent Roll Detail",
    component: <RentRollDetail />,
    layout: "/admin",
  },
  {
    path: "/AddPayment/:lease_id",
    component: <AddPayment />,
    layout: "/admin",
  },
  {
    path: "/AddPayment/:lease_id/:payment_id",
    component: <AddPayment />,
    layout: "/admin",
  },
  {
    path: "/AddCharge/:lease_id",
    component: <AddCharge />,
    layout: "/admin",
  },
  {
    path: "/Applicants/:id",
    name: "applicants",
    component: <ApplicantSummary />,
    layout: "/admin",
  },
  {
    path: "/addvendor",
    component: <AddVendor />,
    layout: "/admin",
  },
  {
    path: "/addvendor/:vendor_id",
    component: <AddVendor />,
    layout: "/admin",
  },
  {
    path: "/addworkorder",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    name: "Account Totals Report",
    path: "/Staffaccounttotalsreport",
    component: <AccountTotalReport />,
    layout: "/staff",
  },
  {
    path: "/addworkorder/:id",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    path: "/addworkorder/addtask/:rental_id",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    path: "/workorderdetail/:workorder_id",
    name: "Work Order Detail",
    component: <WorkOrderDetails />,
    layout: "/admin",
  },

  {
    path: "/Plans",
    component: <Plans />,
    layout: "/admin",
  },
  {
    path: "/Purchaseplandetail",
    component: <Purchaseplan />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-yellow",
    component: <Settings />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/:admin/:roll/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/changepassword",
    name: "Change Password",
    component: <Changepassword />,
    layout: "/auth",
  },
  {
    path: "/forgetpassword",
    name: "Forget Password",
    component: <Forgetmail />,
    layout: "/auth",
  },
  {
    path: "/Resetpassword",
    name: "Reset password",
    component: <ResetPassword />,
    layout: "/auth",
  },
  {
    path: "/createpassword",
    name: "Create Password",
    component: <CreatePassword />,
    layout: "/auth",
  },
  {
    path: "/Payment",
    name: "Payment",
    icon: "ni ni-home-3 text-orange",
    component: <DemoPayment />,
    layout: "/admin",
  },
  {
    path: "/Listings",
    name: "listings",
    icon: "ni ni-home-3 text-orange",
    component: <Listings />,
    layout: "/admin",
  },
  {
    path: "/add_surcharge",
    component: <SurchargeForm />,
    layout: "/admin",
  },
  {
    path: "/add_surcharge/:surcharge_id",
    component: <SurchargeForm />,
    layout: "/admin",
  },
  {
    path: "/surcharge",
    component: <SurchargeTable />,
    layout: "/admin",
  },
  {
    path: "/Planpurchases",
    name: "Purchase Plan",
    component: <Planpurches />,
    layout: "/admin",
  },
  {
    path: "/planUpgrade",
    name: "Plan Upgrade",
    component: <PlanUpgrade />,
    layout: "/admin",
  },
  {
    path: "/due-rent-report",
    name: "Reports",
    // icon: Report,
    // icon2: Report1,
    component: <DueRentReport />,
    layout: "/admin",
  },
  {
    path: "/payment-exception-report",
    name: "Reports",
    // icon: Report,
    // icon2: Report1,
    component: <PaymentReport />,
    layout: "/admin",
  },
  {
    path: "/VendordashBoard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <VendorDashBoard />,
    layout: "/vendor",
  },
  {
    path: "/vendorprofile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <VendorProfile />,
    layout: "/vendor",
  },
  {
    path: "/vendorworktable",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <VendorWorkTable />,
    layout: "/vendor",
  },
  {
    path: "/vendorworktable?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <VendoeWorkTable />,
    layout: "/vendor",
  },
  {
    path: "/vendorworkdetail/:workorder_id",
    name: "Work Order",
    icon: "ni ni-badge text-green",
    component: <VendorWorkDetail />,
    layout: "/vendor",
  },
  {
    path: "/vendoraddwork/:id",
    component: <VendorAddWork />,
    layout: "/vendor",
  },
];

export default routes;
