// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* media  */

/* 1680 */
@media (max-width: 1700px) {

}

/* 1500 */
@media (max-width: 1680px) {

}

/* 1440 */
@media (max-width: 1449px) {

}

/* 1280 */
@media (max-width: 1439px) {

}

/* 992 */
@media (max-width: 1279px) {

}

/* 768 */
@media (max-width: 991px) {
    .second-cards{
        width: 100% !important;
    }
}

/* 576 */
@media (max-width: 767px) {

}

/* 525 */
@media (max-width: 575px) {

}

/* 480 */
@media (max-width: 524px) {

}

/* 425 */
@media (max-width: 479px) {

}

/* 375 */
@media (max-width: 424px) {

}

/* 320 */
@media (max-width: 374px) {

}

/* 280 */
@media (max-width: 319px) {

}

`, "",{"version":3,"sources":["webpack://./src/views/source/dash.css"],"names":[],"mappings":";;AAEA,WAAW;;AAEX,SAAS;AACT;;AAEA;;AAEA,SAAS;AACT;;AAEA;;AAEA,SAAS;AACT;;AAEA;;AAEA,SAAS;AACT;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA;;AAEA,QAAQ;AACR;;AAEA","sourcesContent":["\n\n/* media  */\n\n/* 1680 */\n@media (max-width: 1700px) {\n\n}\n\n/* 1500 */\n@media (max-width: 1680px) {\n\n}\n\n/* 1440 */\n@media (max-width: 1449px) {\n\n}\n\n/* 1280 */\n@media (max-width: 1439px) {\n\n}\n\n/* 992 */\n@media (max-width: 1279px) {\n\n}\n\n/* 768 */\n@media (max-width: 991px) {\n    .second-cards{\n        width: 100% !important;\n    }\n}\n\n/* 576 */\n@media (max-width: 767px) {\n\n}\n\n/* 525 */\n@media (max-width: 575px) {\n\n}\n\n/* 480 */\n@media (max-width: 524px) {\n\n}\n\n/* 425 */\n@media (max-width: 479px) {\n\n}\n\n/* 375 */\n@media (max-width: 424px) {\n\n}\n\n/* 320 */\n@media (max-width: 374px) {\n\n}\n\n/* 280 */\n@media (max-width: 319px) {\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
