// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 1680 */
@media (max-width: 1700px) {}

/* 1500 */
@media (max-width: 1680px) {}

/* 1440 */
@media (max-width: 1449px) {}

/* 1280 */
@media (max-width: 1439px) {}

/* 992 */
@media (max-width: 1279px) {}

/* 768 */
@media (max-width: 991px) {}

/* 576 */
@media (max-width: 767px) {}

/* 525 */
@media (max-width: 575px) {}

/* 480 */
@media (max-width: 524px) {}

/* 425 */
@media (max-width: 479px) {}

/* 375 */
@media (max-width: 424px) {}

/* 320 */
@media (max-width: 374px) {}

/* 280 */
@media (max-width: 371px) {
    .headerstylee{
        flex-direction: column-reverse;
    }
    .backbtnmb{
        margin-bottom: 15px !important;
    }
}

/* 991 */
@media (max-width: 991px) {
    .rentaltablebtn{
        width: 142px !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        height: 33px !important;
        padding: 8px !important;
        border-radius: 4px !important;
    }


}
@media (max-width: 320px) {
    .rentaladded{
        font-size: 12px !important;
    }
}

@media (max-width: 290px) {
    .rentaladded{
        font-size: 11px !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Styles/rentalownerdetails.css"],"names":[],"mappings":"AAAA,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,SAAS;AACT,4BAA4B;;AAE5B,QAAQ;AACR,4BAA4B;;AAE5B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR,2BAA2B;;AAE3B,QAAQ;AACR;IACI;QACI,8BAA8B;IAClC;IACA;QACI,8BAA8B;IAClC;AACJ;;AAEA,QAAQ;AACR;IACI;QACI,uBAAuB;QACvB,0BAA0B;QAC1B,2BAA2B;QAC3B,uBAAuB;QACvB,uBAAuB;QACvB,6BAA6B;IACjC;;;AAGJ;AACA;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["/* 1680 */\n@media (max-width: 1700px) {}\n\n/* 1500 */\n@media (max-width: 1680px) {}\n\n/* 1440 */\n@media (max-width: 1449px) {}\n\n/* 1280 */\n@media (max-width: 1439px) {}\n\n/* 992 */\n@media (max-width: 1279px) {}\n\n/* 768 */\n@media (max-width: 991px) {}\n\n/* 576 */\n@media (max-width: 767px) {}\n\n/* 525 */\n@media (max-width: 575px) {}\n\n/* 480 */\n@media (max-width: 524px) {}\n\n/* 425 */\n@media (max-width: 479px) {}\n\n/* 375 */\n@media (max-width: 424px) {}\n\n/* 320 */\n@media (max-width: 374px) {}\n\n/* 280 */\n@media (max-width: 371px) {\n    .headerstylee{\n        flex-direction: column-reverse;\n    }\n    .backbtnmb{\n        margin-bottom: 15px !important;\n    }\n}\n\n/* 991 */\n@media (max-width: 991px) {\n    .rentaltablebtn{\n        width: 142px !important;\n        font-size: 13px !important;\n        font-weight: 600 !important;\n        height: 33px !important;\n        padding: 8px !important;\n        border-radius: 4px !important;\n    }\n\n\n}\n@media (max-width: 320px) {\n    .rentaladded{\n        font-size: 12px !important;\n    }\n}\n\n@media (max-width: 290px) {\n    .rentaladded{\n        font-size: 11px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
