import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Header from "components/Headers/Header";
import profile from "../../assets/img/icons/common/profile1.svg";
import {
  Card,
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import MapComponent from "../../components/MapComponent";
import { Checkbox } from "@mui/material";
import axiosInstance from "axiosInstance";
import { TabList } from "@mui/lab";
import { Tab } from "react-bootstrap";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import { OpenImageDialog } from "components/OpenImageDialog";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";

const WorkOrderDetails = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { workorder_id, admin } = useParams();
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [outstandDetails, setoutstandDetails] = useState({});
  const [workOrderStatus, setWorkOrderStatus] = useState([]);

  const [showTenantTable, setShowTenantTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeButton, setActiveButton] = useState("Summary");
  const [updateButton, setUpdateButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState(null);
  const [tenantdownOpen, settenantdownOpen] = useState(false);
  const [tenantsDetails, setTenantsDetails] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState("Select here...");
  const [selectedTenant, setSelectedTenant] = useState("Select here...");
  const [tenantId, setTenantId] = useState();
  const [isFromPropety, setIsFromPropety] = useState(false);
  const [isFromWorkTable, setIsFromWorkTable] = useState(false);
  const toggle8 = () => settenantdownOpen((prevState) => !prevState);

  const [isBillable, setIsBillable] = useState(); // Ensure isBillable starts as false
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.rental_id) {
      setIsFromPropety(true);
    } else if (location?.state?.workorder_id) {
      setIsFromWorkTable(true);
    }
  }, [location?.state]);

  let navigate = useNavigate();

  const handleUpdateButtonClick = () => {
    setUpdateButton(true);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    updateWorkorderFormik.resetForm();
    setSelectedStatus("");
    setSelecteduser("");
    setOpenDialog(false);
  };

  const handlebackbutton = () => {
    if (isFromPropety) {
      navigate(`/${admin}/PropDetails/${location.state.rental_id}`, {
        state: { tab: "Work Order" },
      });
    } else if (isFromWorkTable) {
      navigate(`/${admin}/Workorder`);
    } else {
      navigate(`/${admin}/Workorder`);
    }
  };

  const getOutstandData = async () => {
    if (workorder_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/workorder_details/${workorder_id}`
        );
        setoutstandDetails(response?.data?.data);
        if (
          response?.data?.data?.workorder_updates &&
          response.data.data.workorder_updates.length > 0
        ) {
          const reversedUpdates = [
            ...response?.data?.data?.workorder_updates,
          ].reverse();

          const latestUpdate = reversedUpdates[0];

          setWorkOrderStatus(reversedUpdates);
          setIsBillable(response.data.data?.is_billable);
          setSelectedTenant(
            response.data.data?.tenant_data
              ? response.data.data?.tenant_data?.tenant_firstName +
                  " " +
                  response.data.data?.tenant_data?.tenant_lastName
              : "Select"
          );
          setTenantId(response.data.data?.tenant_id);
          setSelectedCharge(response.data.data?.work_charge_to);
          // setSelectedStatus(latestUpdate?.status);
          // setSelecteduser(latestUpdate?.staffmember_name);

          // updateWorkorderFormik.setValues({
          //   status: response?.data?.data?.status,
          //   staffmember_name: latestUpdate?.staffmember_name,
          //   date: response?.data?.data?.date,
          //   assigned_to: response?.data?.data.staff_data?.staffmember_name,
          //   message: response?.data?.data?.message
          //     ? response?.data?.data?.message
          //     : "",
          //   statusUpdatedBy: latestUpdate?.statusUpdatedBy
          //     ? latestUpdate?.statusUpdatedBy
          //     : "Admin",
          // });
        } else {
          console.log("No updates found in workorder_updates");
        }
        setLoading(false);
        setImageDetails(response?.data?.data?.workOrder_images);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setError(error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getOutstandData();
    axiosInstance
      .get(`/staffmember/staff_member/${accessType?.admin_id}`)
      .then((response) => {
        const data = response.data;
        if (data.statusCode === 200) {
          setstaffData(data.data);
        } else {
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Network error:",
          error.response ? error.response.data : error
        );
      });
  }, [accessType?.admin_id]);

  const SmallSummaryCard = ({ label, value, textTruncate }) => {
    return (
      <div className="small-summary-card p-3">
        <h6
          className="text-uppercase titleecolor mb-0 fontstylerentr fontfamilysty fontweightstyle"
          style={{ fontSize: "14px" }}
        >
          {label}
        </h6>
        <span
          className={`font-weight-bold fontstylerentr textcolorblue fontfamilysty
          ${textTruncate ? "text-truncate" : ""}`}
          style={{
            fontSize: "16px",
          }}
        >
          {value}
        </span>
      </div>
    );
  };
  const detailstyle = {
    fontSize: "15px",
    color: "#525f7f",
    fontWeight: 600,
  };

  const updateWorkorderFormik = useFormik({
    initialValues: {
      status: "",
      staffmember_name: "",
      date: "",
      staffmember_id: "",
      workOrderUpdate_images: [],
      message: "",
      statusUpdatedBy: "",
    },
    onSubmit: (values) => {
      // updateValues()
      // updateWorkorder(values);
    },
  });

  //Update Dialog image update
  const [workOrderImage, setWorkOrderImage] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileData = (e, type) => {
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...e.target.files,
    ]);

    const newFiles = [
      ...workOrderImage,
      ...Array.from(e.target.files).map((file) => URL.createObjectURL(file)),
    ];

    setWorkOrderImage(newFiles);
  };

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const clearSelectedPhoto = (index, name) => {
    if (name === "propertyres_image") {
      const filteredImage = workOrderImage.filter((item, i) => i !== index);
      const filteredImage2 = selectedFiles.filter((item, i) => i !== index);
      setSelectedFiles(filteredImage2);
      setWorkOrderImage(filteredImage);
    }
  };

  const updateValues = async () => {
    let image = [];

    if (selectedFiles) {
      try {
        const uploadPromises = selectedFiles.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data?.files &&
                res.data?.files.length > 0
              ) {
                fileItem = res.data?.files[0].filename;
                image[i] = res.data?.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            image[i] = fileItem;
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    handleDialogClose();
    const formattedDate = updateWorkorderFormik.values.date
      ? new Date(updateWorkorderFormik.values.date).toISOString().split("T")[0]
      : "";
    const workOrderData = {
      workOrder: {
        date: formattedDate,
        staffmember_name: updateWorkorderFormik.values.staffmember_name,
        staffmember_id: updateWorkorderFormik.values.staffmember_id,
        message: updateWorkorderFormik.values.message,
        status: selectedStatus,
        statusUpdatedBy: "Admin",
        workOrderUpdate_images: image || "",
      },
    };

    await axiosInstance
      .put(`/work-order/work-order/${workorder_id}`, workOrderData)
      .then((res) => {
        getOutstandData();
        setSelectedFiles([]);
        setWorkOrderImage([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => {
    setShowTenantTable(!showTenantTable);
  };
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [statusdropdownOpen, setstatusdropdownOpen] = React.useState(false);
  const [userdropdownOpen, setuserdropdownOpen] = React.useState(false);
  const [selecteduser, setSelecteduser] = useState("Select");
  const [staffData, setstaffData] = useState([]);

  const toggle5 = () => setuserdropdownOpen((prevState) => !prevState);

  const toggle6 = () => setstatusdropdownOpen((prevState) => !prevState);

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    // WorkFormik.values.status = status;
  };

  const handleStaffSelect = (staffName, staffId) => {
    setSelecteduser(staffName);
    updateWorkorderFormik.setValues((prevValues) => ({
      ...prevValues,
      staffmember_name: staffName,
      staffmember_id: staffId,
    }));
  };

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  const [imagedetails, setImageDetails] = useState([]);

  const getPropertyData = async (rental_id, unit_id) => {
    if (rental_id && unit_id) {
      setTenantsDetails([]);
      try {
        const response = await axiosInstance.get(
          `/leases/get_tenants/${rental_id}/${unit_id}`
        );
        setTenantsDetails(response?.data?.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  const getTenantData = async () => {
    if (
      selectedCharge === "Tenant" &&
      outstandDetails?.rental_id &&
      outstandDetails?.unit_id
    ) {
      const rental_id = outstandDetails?.rental_id;
      const unit_id = outstandDetails?.unit_id;
      getPropertyData(rental_id, unit_id);
    } else {
      setTenantsDetails([]);
    }
  };

  useEffect(() => {
    getTenantData();
  }, [isBillable]);

  const tableHeaderStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "left",
  };

  const tableCellStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const tableFooterStyle = {
    border: "1px solid #ccc",
    padding: "8px",
    textAlign: "left",
  };

  const total = () => {
    let total = 0;
    outstandDetails?.partsandcharge_data?.map((item) => {
      total = total + item.amount;
    });
    return total;
  };

  const isBlobURL = (url) => {
    return url.startsWith("blob:");
  };

  const editWorkOrderNavigate = (id) => {
    if (isFromPropety) {
      navigate(`/${admin}/addworkorder/${id}`, {
        state: { Property: "Property" },
      });
    } else if (isFromWorkTable) {
      navigate(`/${admin}/addworkorder/${id}`, {
        state: { Workorder: "Workorder" },
      });
    } else {
      navigate(`/${admin}/addworkorder/${id}`);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          <Col className="text-right">
            <Button
              // color="primary"
              className="mr-2 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() => handlebackbutton()}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              Back
            </Button>
          </Col>

          {/* <Col xs="12" sm="6">
            <FormGroup className="">
              <h1 style={{ color: "white" }}>Work Order Details</h1>
            </FormGroup>
          </Col> */}
          <Col xs="12" lg="12" sm="12">
            <FormGroup className="">
              {/* <h1 style={{ color: "red" }}>Work Order Details</h1> */}
            </FormGroup>
            <CardHeader
              className=" mt-3 mx-2 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Work Order Details
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {/* <Card className="shadow"> */}
            <CardHeader
              className=" border-0 d-flex justify-content-between align-items-center "
              style={{ padding: "8px", marginBottom: "10px" }}
            >
              <ToggleButtonGroup
                color="primary"
                // value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  className="labelfontstyle fontfamilysty"
                  value="Summary"
                  style={{
                    border: "none",
                    background: "none",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: activeButton === "Summary" ? "#152B51" : "inherit",
                    borderBottom:
                      activeButton === "Summary"
                        ? "0.5px solid #324567"
                        : "none",
                    borderRadius: "0px",
                  }}
                  // onMouseEnter={() => handleMouseEnter("Summary")}
                  // onMouseLeave={handleMouseLeave}
                  onClick={() => setActiveButton("Summary")}
                >
                  Summary
                </ToggleButton>

                <ToggleButton
                  className="labelfontstyle fontfamilysty"
                  value="Task"
                  style={{
                    border: "none",
                    background: "none",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    color: activeButton === "Task" ? "#152B51" : "inherit",
                    borderBottom:
                      activeButton === "Task" ? "0.5px solid #324567" : "none",
                    borderRadius: "0px",
                  }}
                  // onMouseEnter={() => handleMouseEnter("Task")}
                  // onMouseLeave={handleMouseLeave}
                  onClick={() => setActiveButton("Task")}
                >
                  Task
                </ToggleButton>
              </ToggleButtonGroup>
              <div className="ml-auto ">
                <Button
                  // color="primary"
                  className="ml-4 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  onClick={(e) => {
                    e.stopPropagation();
                    editWorkOrderNavigate(outstandDetails?.workOrder_id);
                  }}
                  size="small"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Edit
                </Button>
              </div>
            </CardHeader>
            <div className="table-responsive">
              {activeButton === "Summary" && (
                <div
                  className="container-fluid"
                  style={{
                    marginRight: "auto",
                    // overflowY: "scroll",
                    // overflowX: "hidden",
                    // scrollbarWidth: "thin",
                    // msOverflowStyle: "none",
                    // maxHeight: "100vh",
                    // paddingRight: "20px"
                  }}
                >
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      {loading ? (
                        <div>Loading Work Order details...</div>
                      ) : error ? (
                        <div>Error: {error.message}</div>
                      ) : outstandDetails?.workOrder_id ? (
                        <>
                          <Box
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            // margin="20px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              marginBottom="20px"
                            >
                              <Box
                                className="background-colorsty"
                                width="40px"
                                height="40px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="8px"
                                color="white"
                                fontSize="24px"
                                marginRight="16px"
                              >
                                {/* <AssignmentOutlinedIcon /> */}
                                <FormatListBulletedIcon />
                              </Box>
                              <Box flex="1">
                                <h2 className="text text-lg fontstylerentr textcolorblue fontfamilysty">
                                  {outstandDetails?.work_subject || "N/A"}
                                </h2>
                                <span className="fontstylerentr titleecolor fontfamilysty fontweightstyle">
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection={{ xs: "column", md: "row" }}
                              alignItems="stretch"
                            >
                              {/* Left side */}
                              <Box
                                flex="1"
                                className={{ xs: "col-12", md: "col-7" }}
                                marginBottom={{ xs: "20px", md: "0" }}
                              >
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkdescription"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Description
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                    style={{
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails?.work_performed || "N/A"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkpermission"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty fontweightstyle"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Permission to enter
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                    style={{
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails?.entry_allowed
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </FormGroup>
                                <FormGroup
                                  className="boxworkorderdetailsty boxworkvendor"
                                  style={{
                                    padding: "15px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <label
                                    className="form-control-label fontstylerentr boxworkorderdetailsty textcolorblue fontfamilysty fontweightstyle"
                                    htmlFor="input-property"
                                    style={{
                                      marginBottom: "10px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Vendor Notes
                                  </label>
                                  <span
                                    className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                    style={{
                                      fontSize: "14px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {outstandDetails?.vendor_notes || "N/A"}
                                  </span>
                                </FormGroup>
                              </Box>

                              {/* Right side */}

                              <Box
                                flex="1"
                                className="d-flex flex-column justify-content-end"
                              >
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Status"
                                      value={outstandDetails?.status || "N/A"}
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Due Date"
                                      value={
                                        outstandDetails?.date
                                          ? moment(
                                              outstandDetails?.date
                                            ).format(dateFormat)
                                          : "N/A"
                                      }
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  className="smcardworkorderstyle"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    margin: "15px auto",
                                    width: "60%",
                                  }}
                                >
                                  <Col style={{ padding: "0 8px" }}>
                                    <SmallSummaryCard
                                      label="Assignees"
                                      value={
                                        outstandDetails?.staff_data
                                          ?.staffmember_name || "N/A"
                                      }
                                      textTruncate
                                    />
                                  </Col>
                                </Row>
                              </Box>
                            </Box>
                          </Box>
                          {outstandDetails?.partsandcharge_data?.length > 0 &&
                          outstandDetails?.partsandcharge_data ? (
                            <Box
                              border="0.5px solid #737791"
                              borderRadius="10px"
                              padding="16px"
                              // maxWidth="960px"
                              boxShadow=" 0px 4px 4px 0px #00000040"
                              marginTop="20px"
                              style={{
                                marginRight: "auto",
                                overflowX: "auto",
                              }} // Center the box horizontally
                            >
                              <h2
                                className="text text-lg fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Parts and Labor
                              </h2>
                              <Box overflowX="auto">
                                <table
                                  style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                  }}
                                >
                                  <thead>
                                    <tr
                                      className="fontstylerentr tableefontworkorder textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <th style={tableHeaderStyle}>QTY</th>
                                      <th style={tableHeaderStyle}>ACCOUNT</th>
                                      <th style={tableHeaderStyle}>
                                        DESCRIPTION
                                      </th>
                                      <th style={tableHeaderStyle}>PRICE</th>
                                      <th style={tableHeaderStyle}>AMOUNT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* Add your table rows dynamically here */}
                                    {outstandDetails?.partsandcharge_data?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.parts_quantity}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.account}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={tableCellStyle}
                                          >
                                            {item.description}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.parts_price}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tableefontworkorder"
                                            style={{
                                              ...tableCellStyle,
                                              textAlign: "right",
                                            }}
                                          >
                                            ${item.amount}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td
                                        className="fontstylerentr tableefontworkorder textcolorblue fontfamilysty"
                                        colSpan="4"
                                        style={{
                                          ...tableFooterStyle,
                                          fontWeight: "400",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Total
                                      </td>
                                      <td
                                        className="fontstylerentmodal tableefontworkorder textcolorblue fontfamilysty"
                                        style={{
                                          ...tableFooterStyle,
                                          textAlign: "right",
                                          fontWeight: "400",
                                          fontSize: "14px",
                                        }}
                                      >
                                        ${total()}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </Box>
                            </Box>
                          ) : null}

                          <Grid
                            className="mb-3"
                            container
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            padding="16px"
                            // maxWidth="960px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            marginTop="20px"
                            style={{
                              marginRight: "auto",
                              overflowX: "auto",
                              maxHeight: "500px",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                              // overflowY: workOrderStatus.length > 6 ? "scroll" : "hidden",
                            }}
                          >
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                              <h2
                                className="text-lg fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Updates
                              </h2>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                              <Button
                                className="fontstylerentr titleecolor"
                                size="small"
                                onClick={handleUpdateButtonClick}
                                style={{
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  backgroundColor: "transparent",
                                  padding: "6px",
                                }}
                              >
                                Update
                              </Button>
                            </Grid>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {outstandDetails?.workorder_updates &&
                                outstandDetails.workorder_updates.length > 0 &&
                                workOrderStatus.map((item, index) => (
                                  <Grid item xs={12} key={index}>
                                    {" "}
                                    <Box
                                      padding="12px"
                                      maxWidth="940px"
                                      style={{
                                        // marginLeft: "auto",
                                        marginRight: "auto",
                                        overflowX: "auto",
                                      }} // Center the box horizontally
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{ fontWeight: "600" }}
                                        >
                                          {item.statusUpdatedBy}
                                          {item.createdAt &&
                                          (!item.updatedAt ||
                                            item.createdAt === item.updatedAt)
                                            ? " created this work order"
                                            : " updated this work order"}
                                          <span
                                            className="fontstylerentr textcolorblue fontfamilysty"
                                            style={{ fontSize: "13px" }}
                                          >
                                            &nbsp;(
                                            {item?.updatedAt
                                              ? `${moment(
                                                  item?.updatedAt
                                                ).format(
                                                  `${dateFormat} HH:mm:ss`
                                                )}`
                                              : `${moment(
                                                  item?.createdAt
                                                ).format(
                                                  `${dateFormat} HH:mm:ss`
                                                )}`}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                      <hr
                                        style={{
                                          marginTop: "0px",
                                          marginBottom: "0px",
                                        }}
                                      />
                                      <Grid container>
                                        {(item.status && item.status.trim()) ||
                                        (item.date && item.date.trim()) ||
                                        (item.staffmember_name &&
                                          item.staffmember_name.trim()) ||
                                        (item.message &&
                                          item.message.trim()) ? (
                                          <>
                                            {item.status && (
                                              <Grid
                                                className="fontstylerentr titleecolor fontfamilysty fontweightstyle"
                                                item
                                                xs={4}
                                                style={{ fontSize: "14px" }}
                                              >
                                                Status: {item.status}
                                              </Grid>
                                            )}
                                            {item.date && (
                                              <Grid
                                                className="fontstylerentr titleecolor fontfamilysty fontweightstyle"
                                                item
                                                xs={4}
                                                style={{ fontSize: "14px" }}
                                              >
                                                Due Date:{" "}
                                                {moment(item?.date).format(
                                                  dateFormat
                                                )}
                                              </Grid>
                                            )}
                                            {item.staffmember_name && (
                                              <Grid
                                                className="fontstylerentr titleecolor fontfamilysty fontweightstyle"
                                                item
                                                xs={4}
                                                style={{ fontSize: "14px" }}
                                              >
                                                Assigned To:{" "}
                                                {item.staffmember_name}
                                              </Grid>
                                            )}
                                            <Grid container>
                                              {item.message && (
                                                <Grid
                                                  className="fontstylerentr titleecolor fontfamilysty fontweightstyle"
                                                  item
                                                  xs={4}
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  Message: {item.message}
                                                </Grid>
                                              )}
                                            </Grid>
                                            <Grid container>
                                              {item?.workOrderUpdate_images
                                                ?.length > 0 && (
                                                <>
                                                  {item?.workOrderUpdate_images.map(
                                                    (item) => (
                                                      <Grid
                                                        className="fontstylerentr titleecolor fontfamilysty fontweightstyle my-2"
                                                        item
                                                        xs={1}
                                                        style={{
                                                          fontSize: "14px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setSelectedImage(
                                                            item
                                                          );
                                                          setOpen(true);
                                                        }}
                                                      >
                                                        <img
                                                          src={`https://saas.cloudrentalmanager.com/api/images/get-file/${item}`}
                                                          width="100%"
                                                        />
                                                      </Grid>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </Grid>
                                          </>
                                        ) : (
                                          <Grid
                                            className="fontstylerentr titleecolor fontfamilysty fontweightstyle"
                                            style={{ fontSize: "14px" }}
                                            item
                                          >
                                            Work Order Is Created
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Box>
                                  </Grid>
                                ))}
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <div className="fontstylerentr">No details found.</div>
                      )}
                    </Col>
                    {open && (
                      <OpenImageDialog
                        open={open}
                        setOpen={setOpen}
                        selectedImage={selectedImage}
                      />
                    )}
                    <Col lg="4" md="12" sm="12" xs="12">
                      {outstandDetails?.workOrder_id ? (
                        <>
                          <Box
                            className="mx-3 boxworkorderstylee"
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            maxWidth="100%"
                            // margin="20px"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                minWidth: "100%",
                                padding: "16px 16px 5px 16px",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                color: "#5e72e4",
                              }}
                            >
                              <span
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Billing Information
                              </span>
                            </Box>
                            <Box
                              borderBottom="1px solid #ccc"
                              style={{
                                display: "flex",
                                alignItems: "start",
                                flexDirection: "column",
                                justifyContent: "start",
                                minWidth: "100%",
                                padding: "16px 16px 5px 16px",
                              }}
                            >
                              <Row>
                                <Col>
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 28,
                                      },
                                      "&.Mui-checked": {
                                        color: "#152B51",
                                      },
                                    }}
                                    checked={
                                      isBillable === true ||
                                      isBillable === undefined
                                    }
                                    // onChange={handleChangeBill}
                                  />
                                  <span
                                    className="labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Billable to Tenant
                                  </span>{" "}
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col>
                                  {isBillable === true &&
                                  tenantsDetails.length > 0 ? (
                                    <>
                                      <Col lg="4">
                                        <FormGroup>
                                          <label
                                            className="form-control-label"
                                            htmlFor="input-desg"
                                            style={{
                                              // marginBottom: "10px",
                                              fontWeight: "500",
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                              color: "#8A95A8",
                                            }}
                                          >
                                            Tenant
                                          </label>
                                          <br />
                                          <Dropdown
                                            isOpen={tenantdownOpen}
                                            toggle={toggle8}
                                          >
                                            <DropdownToggle
                                              caret
                                              style={{
                                                width: "100%",
                                                boxShadow:
                                                  "0px 4px 4px 0px #00000040",
                                                border: "1px solid #ced4da",
                                                backgroundColor: "transparent",
                                                color: "#A7A7A7",
                                              }}
                                            >
                                              {selectedTenant}
                                            </DropdownToggle>
                                            <DropdownMenu
                                              style={{ width: "100%" }}
                                            >
                                              {tenantsDetails?.map((item) => (
                                                <DropdownItem
                                                  key={item?.tenant_id}
                                                  onClick={() => {
                                                    setSelectedTenant(
                                                      item?.tenant_firstName +
                                                        " " +
                                                        item?.tenant_lastName
                                                    );
                                                    setTenantId(
                                                      item?.tenant_id
                                                    );
                                                  }}
                                                >
                                                  {item?.tenant_firstName +
                                                    " " +
                                                    item?.tenant_lastName}
                                                </DropdownItem>
                                              ))}
                                            </DropdownMenu>
                                          </Dropdown>
                                        </FormGroup>
                                      </Col>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row> */}
                            </Box>
                          </Box>
                          <Box
                            className="boxcontactstyle"
                            // border="1px solid #ccc"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            maxWidth="100%"
                            margin="20px"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "16px",
                                textAlign: "left",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                color: "#5e72e4",
                              }}
                            >
                              <span
                                className="text labelfontstyle textcolorblue fontfamilysty fontweightstyle"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Contacts
                              </span>
                            </Box>
                            <Box
                              borderBottom="1px solid #ccc"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "100%",
                                padding: "16px 16px 5px 16px",
                              }}
                            >
                              <Box width="16px" marginRight="10px">
                                {/* SVG icon */}
                                <img src={profile} height={20} width={20} />
                              </Box>
                              <Box
                                width="100%"
                                style={{ minWidth: "100%", padding: "0 16px" }}
                              >
                                <span
                                  className="fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  Vendor
                                </span>{" "}
                                <br />
                                <span
                                  className="fontstylerentmodal fontfamilysty fontweightstyle"
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {outstandDetails?.vendor_data?.vendor_name ||
                                    "N/A"}
                                </span>
                              </Box>
                            </Box>
                            {outstandDetails?.tenant_data &&
                            typeof outstandDetails?.tenant_data === "object" ? (
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  minWidth: "100%",
                                  padding: "16px 16px 5px 16px",
                                }}
                              >
                                <Box width="16px" marginRight="10px">
                                  <img src={profile} height={20} width={20} />
                                </Box>
                                <Box
                                  width="100%"
                                  style={{
                                    minWidth: "100%",
                                    padding: "0 16px",
                                  }}
                                >
                                  <span
                                    className="fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Tenant
                                  </span>{" "}
                                  <br />
                                  <span
                                    className="fontstylerentmodal fontfamilysty  fontweightstyle"
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    {outstandDetails?.tenant_data
                                      .tenant_firstName ? (
                                      <>
                                        {
                                          outstandDetails?.tenant_data
                                            .tenant_firstName
                                        }{" "}
                                        {
                                          outstandDetails?.tenant_data
                                            .tenant_lastName
                                        }
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        </>
                      ) : null}
                      {outstandDetails ? (
                        <>
                          <Box
                            className="boxcontactstyle"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            margin="20px"
                            display="flex"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                            flexDirection="column"
                            alignItems="center" // Center content horizontally
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "16px",
                                textAlign: "left",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                color: "#5e72e4",
                              }}
                            >
                              <span
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Property
                              </span>
                            </Box>
                            {outstandDetails?.property_data?.rental_image ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  width="100%"
                                  style={{
                                    minWidth: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    className="workorderimgwidthstyle"
                                    src={`${imageGetUrl}/${outstandDetails?.property_data.rental_image}`}
                                    alt="property"
                                    style={{
                                      maxWidth: "80%",
                                      maxHeight: "200px",
                                      borderRadius: "8px",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                </Box>
                              </Box>
                            ) : null}
                            <Box
                              style={{
                                width: "100%",
                                padding: "16px 16px 5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span className="labelfontstyle fontweightstyle titleecolor fontfamilysty">
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}
                                  {outstandDetails?.property_data
                                    ?.rental_units ? (
                                    " (" +
                                    outstandDetails?.property_data
                                      ?.rental_units +
                                    ")"
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span className="labelfontstyle fontweightstyle titleecolor fontfamilysty">
                                  {outstandDetails?.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  {updateButton && (
                    <Form onSubmit={updateWorkorderFormik.handleSubmit}>
                      <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                        fullWidth
                        maxWidth="xs"
                      >
                        <DialogTitle
                          className="barforsummaryrent textcolorblue fontfamilysty"
                          style={{
                            fontWeight: "500",
                            fontSize: "20px",
                          }}
                        >
                          Update Dialog
                        </DialogTitle>
                        <DialogContent>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-desg"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Status
                                </label>
                                <FormGroup>
                                  <Dropdown
                                    className="fontstylerentmodal"
                                    isOpen={statusdropdownOpen}
                                    toggle={toggle6}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selectedStatus
                                        ? selectedStatus
                                        : "Select"}
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("New")
                                        }
                                      >
                                        New
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("In Progress")
                                        }
                                      >
                                        In Progress
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("On Hold")
                                        }
                                      >
                                        On Hold
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          handleStatusSelect("Completed")
                                        }
                                      >
                                        Completed
                                      </DropdownItem>
                                    </DropdownMenu>
                                    {/* {WorkFormik.errors &&
                                WorkFormik.errors?.status &&
                                WorkFormik.touched &&
                                WorkFormik.touched?.status &&
                                WorkFormik.values.status === "" ? (
                                                        <div className="requiredstylefont" style={{ color: "red" }}>

                                  {WorkFormik.errors.status}
                                </div>
                              ) : null} */}
                                  </Dropdown>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} className="">
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unitadd"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Due Date
                                </label>
                                <Input
                                  className="form-control-alternative fontstylerentr titleecolor"
                                  style={{
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                  }}
                                  id="input-unitadd"
                                  type="date"
                                  name="date"
                                  value={updateWorkorderFormik.values.date}
                                  onChange={updateWorkorderFormik.handleChange}
                                  onBlur={updateWorkorderFormik.handleBlur}
                                />
                                {/* {WorkFormik.touched.due_date &&
                            WorkFormik.errors.due_date ? (
                                                    <div className="requiredstylefont" style={{ color: "red" }}>

                              {WorkFormik.errors.due_date}
                            </div>
                          ) : null} */}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col xs={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-desg"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Assigned To
                                </label>
                                <FormGroup>
                                  <Dropdown
                                    className="fontstylerentmodal"
                                    isOpen={userdropdownOpen}
                                    toggle={toggle5}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selecteduser ? selecteduser : "Select"}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        header
                                        style={{ color: "#152B51" }}
                                      >
                                        Staff
                                      </DropdownItem>
                                      {staffData.map((user) => (
                                        <DropdownItem
                                          key={user._id}
                                          onClick={() =>
                                            handleStaffSelect(
                                              user.staffmember_name,
                                              user.staffmember_id
                                            )
                                          }
                                        >
                                          {user.staffmember_name}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                    {/* {WorkFormik.errors &&
                                WorkFormik.errors?.staffmember_name &&
                                WorkFormik.touched &&
                                WorkFormik.touched?.staffmember_name &&
                                WorkFormik.values.staffmember_name === "" ? (
                                                        <div className="requiredstylefont" style={{ color: "red" }}>

                                  {WorkFormik.errors.staffmember_name}
                                </div>
                              ) : null} */}
                                  </Dropdown>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col>
                              <FormGroup
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unitadd"
                                  style={{
                                    // marginBottom: "10px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Photo
                                </label>
                                <span
                                  className="textcolorblue"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  <input
                                    type="file"
                                    className="form-control-file d-none"
                                    accept="image/*"
                                    multiple
                                    id={`workOrderUpdate_images`}
                                    name={`workOrderUpdate_images`}
                                    onChange={(e) => fileData(e)}
                                  />
                                  <label
                                    className="d-flex justify-content-center fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                                    htmlFor={`workOrderUpdate_images`}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      borderRadius: "6px",
                                      padding: "6px",
                                      width: "100px",
                                      boxShadow: " 0px 4px 4px 0px #00000040",
                                    }}
                                  >
                                    {/* <b style={{ fontSize: "20px" }}>+</b> Add */}
                                    Upload
                                  </label>
                                </span>
                              </FormGroup>
                              <FormGroup
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  paddingLeft: "10px",
                                }}
                              >
                                <div
                                  className=" d-flex"
                                  style={{
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    overflow: "auto",
                                  }}
                                >
                                  <div className="d-flex">
                                    {workOrderImage &&
                                      workOrderImage.length > 0 &&
                                      workOrderImage.map((unitImg, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            position: "relative",
                                            width: "100px",
                                            height: "100px",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginRight: "20px",
                                          }}
                                        >
                                          <img
                                            src={
                                              !isBlobURL(unitImg)
                                                ? `${imageGetUrl}/${unitImg}`
                                                : unitImg
                                            }
                                            alt=""
                                            style={{
                                              width: "150px",
                                              height: "150px",
                                              maxHeight: "100%",
                                              maxWidth: "100%",
                                              borderRadius: "10px",
                                            }}
                                            onClick={() => {
                                              setSelectedImage(unitImg);
                                              setOpen(true);
                                            }}
                                          />
                                          <ClearIcon
                                            style={{
                                              cursor: "pointer",
                                              alignSelf: "flex-start",
                                              position: "absolute",
                                              top: "-6px",
                                              right: "-12px",
                                            }}
                                            onClick={() =>
                                              clearSelectedPhoto(
                                                index,
                                                "propertyres_image"
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                  </div>
                                  {open && (
                                    <OpenImageDialog
                                      open={open}
                                      setOpen={setOpen}
                                      selectedImage={selectedImage}
                                    />
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col xs={12}>
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unitadd"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Message
                                </label>
                                <Input
                                  className="form-control-alternative fontstylerentr titleecolor"
                                  style={{
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                  }}
                                  id="input-unitadd"
                                  type="textarea"
                                  name="message"
                                  value={updateWorkorderFormik.values.message}
                                  onChange={updateWorkorderFormik.handleChange}
                                  onBlur={updateWorkorderFormik.handleBlur}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </DialogContent>
                        <DialogActions className="d-flex justify-content-start mx-3 mb-3">
                          <Button
                            className="fontstylerentr background-colorsty bgtextwhite"
                            size="small"
                            onClick={updateValues}
                          >
                            Save
                          </Button>
                          <Button
                            className="fontstylerentr textcolorblue"
                            onClick={handleDialogClose}
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Form>
                  )}
                </div>
              )}

              {activeButton === "Task" && (
                <div className="container-fluid">
                  <Row className="mb-4">
                    <Col lg="8" md="12">
                      <Box
                        border="0.5px solid #737791"
                        borderRadius="10px"
                        padding="16px"
                        // maxWidth="960px"
                        // margin={"20px"}
                        boxShadow=" 0px 4px 4px 0px #00000040"
                      >
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col
                            lg="8"
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <Box
                              className="background-colorsty "
                              width="40px"
                              height="40px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="8px"
                              color="white"
                              fontSize="24px"
                            >
                              <FormatListBulletedIcon />
                            </Box>
                            <Col>
                              <h2
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  marginBottom: 0,
                                }}
                              >
                                {outstandDetails?.work_subject || "N/A"}
                              </h2>

                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails.property_data?.rental_adress ||
                                  "N/A"}
                                {outstandDetails?.unit_data?.rental_unit ? (
                                  " (" +
                                  outstandDetails?.unit_data?.rental_unit +
                                  ")"
                                ) : (
                                  <></>
                                )}
                              </span>
                            </Col>
                          </Col>

                          <Col lg="2" className="text-end">
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {/* <span
                                style={{
                                  border: "2px solid",
                                  borderColor:
                                    outstandDetails.priority === "High"
                                      ? "red"
                                      : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                          ? "#FFD700"
                                          : "inherit",
                                  borderRadius: "8px",
                                  padding: "4px",
                                  fontSize: "15px",
                                  color:
                                    outstandDetails.priority === "High"
                                      ? "red"
                                      : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                          ? "#FFD700"
                                          : "inherit",
                                }}
                              >
                                &nbsp;{outstandDetails?.priority}&nbsp;
                              </span> */}
                              {outstandDetails?.priority && (
                                <span
                                  className="fontstylerentr"
                                  style={{
                                    border: "2px solid",
                                    borderColor:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                    borderRadius: "8px",
                                    padding: "4px",
                                    fontSize: "15px",
                                    color:
                                      outstandDetails.priority === "High"
                                        ? "red"
                                        : outstandDetails.priority === "Normal"
                                        ? "green"
                                        : outstandDetails.priority === "Low"
                                        ? "#FFD700"
                                        : "inherit",
                                  }}
                                >
                                  &nbsp;{outstandDetails?.priority}&nbsp;
                                </span>
                              )}
                            </span>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Description
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.work_performed || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Status
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.status || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>

                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Due Date
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.date
                                  ? moment(outstandDetails?.date).format(
                                      dateFormat
                                    )
                                  : "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Assignees
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.staff_data
                                  ?.staffmember_name || "N/A"}
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr textcolorblue fontfamilysty fontweightstyle"
                                htmlFor="input-property"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Permission to enter
                              </label>
                              <br />
                              <span
                                className="fontstylerentr fontfamilysty titleecolor fontweightstyle"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                {outstandDetails?.entry_allowed ? "Yes" : "No"}{" "}
                              </span>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Box>
                    </Col>

                    <Col lg="4" md="12">
                      {/* <Box
                      border="1px solid #ccc"
                      borderRadius="8px"
                      padding="16px"
                      maxWidth="1000px"
                      margin={"20px"}
                    > */}

                      {outstandDetails ? (
                        <>
                          <Box
                            className="boxcontactstyle"
                            border="0.5px solid #737791"
                            borderRadius="10px"
                            maxWidth="100%"
                            // margin="20px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            boxShadow=" 0px 4px 4px 0px #00000040"
                          >
                            <Box
                              className="align-item-center text-center boxinnertextworkorder"
                              borderBottom="1px solid #ccc"
                              style={{
                                width: "100%",
                                padding: "10px",
                                textAlign: "left",
                                color: "#152B51",
                                boxShadow: "0px 4px 4px 0px #00000040",
                                marginBottom: "10px",
                              }}
                            >
                              <span
                                className="text labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Images
                              </span>
                            </Box>
                            {outstandDetails?.workOrder_images &&
                            outstandDetails?.workOrder_images.length > 0 ? (
                              <Box
                                style={{
                                  width: "100%",
                                  padding: "16px",
                                  marginTop: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                  fontFamily: "poppins",
                                }}
                              >
                                {outstandDetails?.workOrder_images.map(
                                  (imageUrl, index) => (
                                    <Box
                                      key={index}
                                      width="48%"
                                      style={{
                                        minWidth: "48%",
                                        margin: "1%",
                                      }}
                                    >
                                      <img
                                        src={
                                          !isBlobURL(imageUrl)
                                            ? `${imageGetUrl}/${imageUrl}`
                                            : imageUrl
                                        }
                                        alt={`property ${index}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          borderRadius: "8px",
                                          border: "1px solid #ccc",
                                        }}
                                      />
                                    </Box>
                                  )
                                )}
                              </Box>
                            ) : (
                              <div className="mt-3 labelfontstyle">
                                No Images Attached
                              </div>
                            )}
                            <br />
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle titleecolor fontfamilysty fontweightstyle"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_adress || "N/A"}{" "}
                                  {/* {{5757-start}} */}
                                  {outstandDetails?.unit_data?.rental_unit && (
                                    <>
                                      (
                                      {outstandDetails?.unit_data
                                        ?.rental_unit || ""}
                                      )
                                    </>
                                  )}
                                  {/* {{5757-end}} */}
                                </span>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                width: "100%",
                                padding: "5px 16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width="100%"
                                style={{
                                  minWidth: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  className="labelfontstyle titleecolor fontfamilysty fontweightstyle"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {outstandDetails?.property_data
                                    ?.rental_city ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_city
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_state ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_state
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_country ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_country
                                      }
                                      ,
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {outstandDetails?.property_data
                                    ?.rental_postcode ? (
                                    <>
                                      {
                                        outstandDetails?.property_data
                                          ?.rental_postcode
                                      }
                                      .
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>No Details Found</>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <br />
            {/* </Card> */}
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default WorkOrderDetails;
