import React, { useEffect, useState } from "react";
import { Box, Checkbox, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  Collapse,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as yup from "yup";
import zxcvbn from "zxcvbn";

const InputGroup = ({
  label,
  type = "text",
  name = "name",
  placeholder = "Enter Name",
  error = "",
  ...props
}) => {
  return (
    <FormGroup className="d-flex flex-column w-100">
      {label && (
        <label
          className="form-control-label fontstylerentr titleecolor fontfamilysty"
          htmlFor="input-unitadd"
          style={{
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {label}
        </label>
      )}

      <Input
        style={{
          boxShadow: "0px 4px 4px 0px #00000040 ",
          borderRadius: "6px",
          width: "100%",
        }}
        className="form-control-alternative fontstylerentmodal titleecolor"
        placeholder={placeholder}
        type={type}
        name={name}
        {...props}
      />
      {error}
    </FormGroup>
  );
};

const TenantCosignerModel = ({
  open = true,
  onclose,
  admin_id,
  initialValues = {
    tenant_id: "",
    tenant_firstName: "",
    tenant_lastName: "",
    tenant_phoneNumber: "",
    tenant_alternativeNumber: "",
    tenant_email: "",
    tenant_alternativeEmail: "",
    tenant_password: "",
    tenant_birthDate: "",
    taxPayer_id: "",
    comments: "",
    percentage: "",
    emergency_contact: {
      name: "",
      relation: "",
      email: "",
      phoneNumber: "",
    },
  },
}) => {
  const toggleButtons = ["Tenant", "Cosigner"];

  const [selectedTab, setSelectedTab] = useState("Tenant");
  const [tenantData, setTenantData] = useState([]);
  const [chooseExisting, setChooseExisting] = useState(false);
  const [collapseData, setCollapseData] = useState({
    number: false,
    email: false,
    information: false,
    emergency: false,
  });

  const fetchTenantData = async () => {
    if (admin_id) {
      try {
        const res = await axiosInstance.get(`/tenant/tenants/${admin_id}`);
        if (res.data.statusCode === 200) {
          setTenantData(res.data?.data?.tenants);
        } else if (res.data.statusCode === 201) {
          setTenantData([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchTenantData();
  }, [admin_id]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      tenant_firstName: yup.string().required("Please enter first name"),
      tenant_lastName: yup.string().required("Please enter last name"),
      tenant_phoneNumber: yup
        .string()
        .required("Please enter phone number")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      tenant_email: yup
        .string()
        .email("Invalid email address")
        .required("Please enter email address"),
      tenant_password: yup
        .string()
        .nullable()
        .notRequired()
        .min(12, "Password must be at least 12 characters long")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])/,
          "Must contain an uppercase letter, lowercase letter, number, and special character"
        )
        .test("zxcvbn-strength", "Password is too weak or common", (value) => {
          if (!value) return true;
          const result = zxcvbn(value);
          return result.score >= 3;
        })
        .test(
          "no-sequential-or-repeating",
          "Avoid sequential or repeating patterns like '1234' or 'aaaa'",
          (value) => {
            if (!value) return true;
            return !/(\d)\1\1|\d{3,}|[A-Za-z]{3,}/.test(value);
          }
        ),
    }),
  });

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  return (
    <Modal
      isOpen={open}
      toggle={onclose}
      className="modal-dialog-scrollable"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader>Add Tenant or Cosigner</ModalHeader>
      <ModalBody>
        <ToggleButtonGroup
          value={selectedTab}
          exclusive
          onChange={(e) => {
            setSelectedTab(e.target.value);
          }}
          aria-label="Platform"
          style={{ width: "100%" }}
        >
          {toggleButtons.map((item, index) => (
            <ToggleButton
              key={index}
              className="fontstylerentr textcolorblue fontfamilysty"
              value={item}
              style={{
                width: "15rem",
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {selectedTab === "Tenant" ? (
          <Box>
            <label
              htmlFor="existing-tenant"
              className="form-control-label fontstylerentr titleecolor fontfamilysty"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "8px",
              }}
            >
              <Checkbox
                id="existing-tenant"
                checked={chooseExisting}
                onChange={(e) => setChooseExisting(e.target.checked)}
              />
              Choose an existing Tenant
            </label>

            {chooseExisting ? (
              <Box>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          padding: "15px",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Tenant Name
                      </th>
                      <th
                        className="labelfontstyle textcolorblue fontfamilysty"
                        style={{
                          padding: "15px",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Select
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(tenantData) &&
                      tenantData
                        .filter((tenant) => {
                          const fullName = `${tenant.tenant_firstName} ${tenant.tenant_lastName}`;
                          return fullName;
                        })
                        .map((tenant, index) => (
                          <tr
                            key={index}
                            style={{
                              border: "1px solid #ddd",
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "15px",
                                paddingTop: "15px",
                              }}
                            >
                              <pre
                                className="fontstylerentr fontfamilysty"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                }}
                              >
                                {tenant.tenant_firstName}{" "}
                                {tenant.tenant_lastName} (
                                {tenant.tenant_phoneNumber})
                              </pre>
                            </td>
                            <td
                              className="tenantmodalrentroll"
                              style={{
                                paddingLeft: "15px",
                                paddingTop: "15px",
                              }}
                            >
                              <Checkbox
                                type="checkbox"
                                name="tenant"
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#152B51",
                                  },
                                }}
                                id={tenant.tenant_phoneNumber}
                                // checked={
                                //   Array.isArray(checkedTenantIds) &&
                                //   checkedTenantIds.includes(tenant.tenant_id)
                                // }
                                // onChange={(event) =>
                                //   handleCheckboxChange(event, tenant)
                                // }
                              />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </Box>
            ) : (
              <Box className="TenantDetail">
                <span
                  className="labelfontstyle background-colorsty bgtextwhite"
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                    borderRadius: "4px",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  &nbsp; Contact information
                </span>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <InputGroup
                      label="First Name *"
                      placeholder="Enter first name"
                      name="tenant_firstName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.tenant_firstName}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <InputGroup
                      label="Last Name *"
                      placeholder="Enter last name"
                      name="tenant_lastName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.tenant_lastName}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <InputGroup
                    label="Phone Number *"
                    placeholder="Enter phone number"
                    name="tenant_phoneNumber"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (
                        formik.values.tenant_phoneNumber?.length >
                        e.target.value?.length
                      ) {
                        formik.setFieldValue(
                          "tenant_phoneNumber",
                          e.target.value
                        );
                      } else {
                        const formattedValue = formatPhoneNumber(
                          e.target.value
                        );
                        formik.setFieldValue(
                          "tenant_phoneNumber",
                          formattedValue
                        );
                      }
                    }}
                    value={formik.values.tenant_phoneNumber}
                  />
                </div>

                <div className="mt-3">
                  {collapseData.number && (
                    <InputGroup
                      label="Work Number"
                      placeholder="Enter work number"
                      name="tenant_alternativeNumber"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (
                          formik.values.tenant_alternativeNumber?.length >
                          e.target.value?.length
                        ) {
                          formik.setFieldValue(
                            "tenant_alternativeNumber",
                            e.target.value
                          );
                        } else {
                          const formattedValue = formatPhoneNumber(
                            e.target.value
                          );
                          formik.setFieldValue(
                            "tenant_alternativeNumber",
                            formattedValue
                          );
                        }
                      }}
                      value={formik.values.tenant_alternativeNumber}
                    />
                  )}
                  {collapseData.number ? (
                    <span
                      className="fontstylerentr"
                      style={{
                        color: "#26C22C",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCollapseData((pre) => {
                          return {
                            ...pre,
                            number: false,
                          };
                        });
                        formik.setFieldValue("tenant_alternativeNumber", "");
                      }}
                    >
                      <b
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        -{" "}
                      </b>
                      Remove alternative phone
                    </span>
                  ) : (
                    <span
                      className="fontstylerentr"
                      style={{
                        color: "#26C22C",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseData((pre) => {
                          return {
                            ...pre,
                            number: true,
                          };
                        })
                      }
                    >
                      <b
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        +{" "}
                      </b>
                      Add alternative phone
                    </span>
                  )}
                </div>

                <div className="mt-3">
                  <InputGroup
                    label="Email *"
                    placeholder="Enter email"
                    name="tenant_email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.tenant_email}
                  />
                </div>

                <div className="mt-3">
                  {collapseData.email && (
                    <InputGroup
                      label="Alternative Email"
                      placeholder="Enter alternative email"
                      name="tenant_alternativeEmail"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.tenant_alternativeEmail}
                    />
                  )}
                  {collapseData.email ? (
                    <span
                      className="fontstylerentr"
                      style={{
                        color: "#26C22C",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCollapseData((pre) => {
                          return {
                            ...pre,
                            email: false,
                          };
                        });
                        formik.setFieldValue("tenant_alternativeEmail", "");
                      }}
                    >
                      <b
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        -{" "}
                      </b>
                      Remove alternative email
                    </span>
                  ) : (
                    <span
                      className="fontstylerentr"
                      style={{
                        color: "#26C22C",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCollapseData((pre) => {
                          return {
                            ...pre,
                            email: true,
                          };
                        })
                      }
                    >
                      <b
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        +{" "}
                      </b>
                      Add alternative email
                    </span>
                  )}
                </div>

                <div className="mt-3">
                  <InputGroup
                    label="Password"
                    placeholder="Enter password"
                    name="tenant_password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.tenant_password}
                  />
                </div>

                <div className="mt-3">
                  <span
                    className="labelfontstyle background-colorsty bgtextwhite"
                    onClick={() =>
                      setCollapseData((pre) => {
                        return {
                          ...pre,
                          information: !collapseData.information,
                        };
                      })
                    }
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      borderRadius: "4px",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <b>&nbsp; + </b>&nbsp; Personal information
                  </span>

                  <Collapse isOpen={collapseData.information} className="mt-3">
                    <div style={{ display: "flex", gap: "12px" }}>
                      <InputGroup
                        label="Date of Birth"
                        placeholder="Enter password"
                        type="date"
                        name="tenant_birthDate"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.tenant_birthDate}
                      />

                      <InputGroup
                        label="TaxPayer ID"
                        placeholder="Enter tax payer ID"
                        name="taxPayer_id"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.taxPayer_id}
                      />
                    </div>

                    <div className="mt-3">
                      <InputGroup
                        label="Comments"
                        placeholder="Enter comments"
                        name="comments"
                        type="textarea"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.comments}
                      />
                    </div>
                  </Collapse>
                </div>

                <div className="mt-3">
                  <span
                    className="labelfontstyle background-colorsty bgtextwhite"
                    onClick={() =>
                      setCollapseData((pre) => {
                        return {
                          ...pre,
                          emergency: !collapseData.emergency,
                        };
                      })
                    }
                    style={{
                      marginBottom: "1rem",
                      display: "flex",
                      borderRadius: "4px",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <b>&nbsp; + </b>&nbsp; Emergency Contact
                  </span>

                  <Collapse isOpen={collapseData.emergency} className="mt-3">
                    <div style={{ display: "flex", gap: "12px" }}>
                      <InputGroup
                        label="Contact Name"
                        placeholder="Enter contact name"
                        type="text"
                        name="emergency_contact.name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.emergency_contact?.name}
                      />

                      <InputGroup
                        label="Relationship to Tenant"
                        placeholder="Relationship to tenant"
                        name="emergency_contact.relation"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.emergency_contact?.relation}
                      />
                    </div>

                    <div
                      style={{ display: "flex", gap: "12px" }}
                      className="mt-3"
                    >
                      <InputGroup
                        label="Email"
                        placeholder="Enter email"
                        type="text"
                        name="emergency_contact.email"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (
                            formik.values.emergency_contact?.email?.length >
                            e.target.value?.length
                          ) {
                            formik.setFieldValue(
                              "emergency_contact.email",
                              e.target.value
                            );
                          } else {
                            const formattedValue = formatPhoneNumber(
                              e.target.value
                            );
                            formik.setFieldValue(
                              "emergency_contact.email",
                              formattedValue
                            );
                          }
                        }}
                        value={formik.values.emergency_contact?.email}
                      />

                      <InputGroup
                        label="Phone Number"
                        placeholder="Enter phone number"
                        name="emergency_contact.phoneNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.emergency_contact?.phoneNumber}
                      />
                    </div>
                  </Collapse>
                </div>
              </Box>
            )}
          </Box>
        ) : (
          <Box></Box>
        )}
      </ModalBody>
    </Modal>
  );
};

export default TenantCosignerModel;
