import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import interceptor from "plugins/axios";

const initialState = {
  status: "idle", // 'loading', 'succeeded', 'failed'
  error: null,
};

// Create a thunk for checking the tenant token
export const checkTenantToken = createAsyncThunk(
  "currentUser/checkTenantToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await interceptor.post(`/tenant/token_check`, { token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a thunk to get lease details for admin
export const getLeasDetailsForAdmin = createAsyncThunk(
  "currentUser/checkTenantToken",
  async (id, { rejectWithValue }) => {
    try {
      const response = await interceptor.get(`/tenant/leases/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const currentUserSlice = createSlice({
  name: "currentUserSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(checkTenantToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkTenantToken.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(checkTenantToken.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload; // Capture error message
      });
  },
});

export default currentUserSlice.reducer;
