// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardstyle {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* margin: 10px; */
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s, border-color 0.3s;
    width: 100%;
    height: 80px;
  }
  
  .cardstyle.selected {
    background-color: #152B51;
    border-color: #152B51;
    color: white;
  }
  
  .radio-button {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .radio-button.checked {
    border-color: white;
    background-color: white;
  }
  
  .checkmark {
    color: #152B51;
    font-size: 16px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: start; 
    text-align: left;
  }
  
  .card-titlestyle,
  .card-subtitlestyle {
    margin: 0;
  }
  
  .card-subtitlestyle {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto; 
    text-align: left; 
    font-size: 14px;
    line-height: 1.2; 
  }
  `, "",{"version":3,"sources":["webpack://./src/views/source/Radiobutton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,uBAAuB;IACvB,oDAAoD;IACpD,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;;IAEE,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,yBAAyB;IACzB,qBAAa;YAAb,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".cardstyle {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    /* margin: 10px; */\n    cursor: pointer;\n    background-color: white;\n    transition: background-color 0.3s, border-color 0.3s;\n    width: 100%;\n    height: 80px;\n  }\n  \n  .cardstyle.selected {\n    background-color: #152B51;\n    border-color: #152B51;\n    color: white;\n  }\n  \n  .radio-button {\n    margin-right: 10px;\n    width: 24px;\n    height: 24px;\n    border-radius: 50%;\n    border: 2px solid #ccc;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .radio-button.checked {\n    border-color: white;\n    background-color: white;\n  }\n  \n  .checkmark {\n    color: #152B51;\n    font-size: 16px;\n  }\n  \n  .card-content {\n    display: flex;\n    flex-direction: column;\n    align-items: start; \n    text-align: left;\n  }\n  \n  .card-titlestyle,\n  .card-subtitlestyle {\n    margin: 0;\n  }\n  \n  .card-subtitlestyle {\n    word-wrap: break-word;\n    overflow-wrap: break-word;\n    hyphens: auto; \n    text-align: left; \n    font-size: 14px;\n    line-height: 1.2; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
