import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  Button,
  FormGroup,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Form,
  Input,
} from "reactstrap";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";
import EventIcon from '@mui/icons-material/Event';
import ReplyIcon from '@mui/icons-material/Reply';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import swal from "sweetalert";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as yup from "yup";

const CronPaymentTable = ({
  payments,
  paymentLoader,
  totalPayments,
  paymentPage,
  paymentRowsPerPage,
  setPaymentPage,
  setPaymentRowsPerPage,
  fetchPayments,
  adminId,
}) => {
  const { admin } = useParams();
  const [ResponseData, setResponseData] = useState("");
  const [isRefundOpen, setIsRefundOpen] = useState(false);
  const [refund, setRefund] = useState(false);

  const closeRefund = () => {
    setIsRefundOpen(false);
  };
  // Handle page change
  const handleChangePaymentPage = (event, newPage) => {
    setPaymentPage(newPage);
  };

  const handlePaymentAcknowledge = (payment) => {
    swal({
      title: "Payment Acknowledgement",
      text: "Are you sure you want to acknowledge this payment as Failed?",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Confirm",
          closeModal: false,
          disabled: true,
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (willToggle) => {
      if (willToggle) {
        try {
          const response = await axiosInstance.put(
            `/payment/payment_acknowledge/${payment._id}`,
            { failure_acknowledged: true }
          );
          if (response.data.statusCode === 200) {
            swal("Success", "Failed Payment Acknoledge Successfully!", "success"
            );
            fetchPayments(paymentPage, paymentRowsPerPage, adminId);
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        } catch (error) {
          swal(
            "Error",
            // "Error occure while acknoledging failed Payment.",
            `${error.response.data.message}`,
            "error"
          );
        }
      }
    });
  };

  const handlePaymentRetry = (payment) => {
    swal({
      title: "Payment Retry",
      text: "Retry this payment now?",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          closeModal: false,
          disabled: true,
        },
        cancel: "No",
      },
      dangerMode: true,
    }).then(async (willToggle) => {
      if (willToggle) {
        try {
          const response = await axiosInstance.put(`/payment/payment_retry/${payment._id}`);
          if (response.data.statusCode === 200) {
            swal("Success", "Payment retry processed successfully!", "success");
            fetchPayments(paymentPage, paymentRowsPerPage, adminId);
          } else {
            swal("Error", "Something went wrong!", "error");
          }
        } catch (error) {
          swal("Error", "Payment retry failed during processing.", "error");
        }
      }
    });
  };

  const handlePaymentReSchedule = (payment) => {
    swal({
      title: "Payment ReSchedule",
      text: "Please select a date to Schedule the payment. The date must be tomorrow or later:",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          type: "date",
          min: new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0],
          id: "retryPaymentDate",
          placeholder: "Select Date",
        },
      },
      buttons: {
        confirm: {
          text: "Confirm",
          closeModal: false,
          disabled: true, 
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (willConfirm) => {
      if (!willConfirm) return;
      const selectedDate = document.getElementById("retryPaymentDate").value;
      if (!selectedDate) {
        swal("Error", "Please select a date before confirming.", "error");
        return;
      }
  
      try {
        const response = await axiosInstance.put(
          `/payment/payment_reschedule/${payment._id}`,
          {
            retryDate: selectedDate,
          }
        );
  
        if (response.data.statusCode === 200) {
          swal("Success", "Payment retry scheduled successfully!", "success");
          fetchPayments(paymentPage, paymentRowsPerPage, adminId);
        } else {
          swal("Error", "Something went wrong!", "error");
        }
      } catch (error) {
        console.error("Error not renewing:", error);
        swal("Error", "Failed to retry payment. Please try again later.", "error");
      }
    });

    setTimeout(() => {
      const dateInput = document.getElementById("retryPaymentDate");
      const confirmButton = document.querySelector(".swal-button--confirm");

      confirmButton.disabled = true;
  
      dateInput.addEventListener("input", () => {
        confirmButton.disabled = !dateInput.value;
      });
    }, 100);
  };
  
  const handlePaymentVoid = (payment) => {
    swal({
      title: "Are you sure you want to void this payment?",
      text: "A void can be issued on this payment until it is settled",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for void",
          type: "text",
          id: "void-reason",
        },
      },
      buttons: {
        confirm: {
          text: "Void",
          closeModal: false,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (value) => {
      if (!value) {
        swal("Void Cancelled", "", "info");
        return;
      }
  
      const voidReason = document.getElementById("void-reason")?.value.trim();
      if (!voidReason) {
        swal("Error", "Void reason is required!", "error");
        return;
      }
  
      try {
        const response = await axiosInstance.post(`/nmipayment/void-payment/${payment._id}`, {
          voidDetails: { admin_id: adminId, void_reason: voidReason },
        });
  
        if (response.data.statusCode === 200) {
          swal("Success", "Payment successfully voided.", "success");
          fetchPayments(paymentPage, paymentRowsPerPage, adminId);
        } else {
          swal("Error", `Failed to process void: ${response.data.message}`, "error");
        }
      } catch (error) {
        swal("Error", "An unexpected error occurred. Please try again.", "error");
      }
    });
  
    // Disable the confirm button until the input is not empty
    setTimeout(() => {
      const inputField = document.getElementById("void-reason");
      const confirmButton = document.querySelector(".swal-button--confirm");
  
      if (inputField && confirmButton) {
        confirmButton.disabled = true;
  
        inputField.addEventListener("input", () => {
          confirmButton.disabled = inputField.value.trim() === "";
        });
      }
    }, 100);
  };
  
  const generalledgerFormik = useFormik({
    initialValues: {
      payment_id: "",
      date: "",
      total_amount: "",
      payments_memo: "",
      customer_vault_id: "",
      billing_id: "",
      transaction_id: "",
      surcharge: "",
      payments: [
        {
          entry_id: "",
          account: "",
          amount: "",
          balance: "",
          charge_type: "",
        },
      ],
      payments_attachment: [],
    },
    validationSchema: yup.object({
      date: yup.string().required("Required"),
      total_amount: yup.string().required("Required"),
      payments: yup.array().of(
        yup.object().shape({
          account: yup.string().required("Required"),
          amount: yup
            .number()
            .required("Required")
            .min(1, "Amount must be greater than zero.")
            .test(
              "is-less-than-balance",
              "Amount must be less than or equal to balance",
              function (value) {
                if (value && this.parent.balance) {
                  const balance = this.parent.balance;
                  return value <= balance;
                }
              }
            ),
        })
      ),
    }),
    onSubmit: (values) => {},
  });

  const fetchData = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`/payment/payment/${id}`);

        if (response.data.statusCode === 200) {
          setResponseData(response.data.data);
          generalledgerFormik.setValues({
            date: response.data.data[0].entry[0].date,
            amount: response.data.data[0].total_amount,
            payment_type: response.data.data[0].payment_type,
            customer_vault_id: response.data.data[0].customer_vault_id,
            billing_id: response.data.data[0].billing_id,
            charges_attachment: response.data.data[0].charges_attachment,

            entry: [
              {
                account: response.data.data[0].account || "",
                amount: response.data.data[0].amount || "",
              },
            ],
          });
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  const handlePaymentRefund = async () => {
    try {
      const { payment_id, payment_type, transaction_id, customer_vault_id, billing_id, tenant_id, lease_id, type, entry } = ResponseData[0];
      const tenantData = ResponseData?.tenant_data || {};
  
      const commonData = {
        admin_id: adminId,
        transactionId: transaction_id,
        amount: generalledgerFormik.values.amount,
        payment_type,
        total_amount: generalledgerFormik.values.amount,
        tenant_firstName: tenantData?.tenant_firstName || "",
        tenant_lastName: tenantData?.tenant_lastName || "",
        tenantName: `${tenantData?.tenant_firstName || ""} ${tenantData?.tenant_lastName || ""}`.trim(),
        tenant_id,
        lease_id,
        email_name: tenantData?.tenant_email || "",
        type,
        entry: entry.map((item) => ({
          amount: item.amount,
          account: item.account,
          date: generalledgerFormik.values.date,
          memo: generalledgerFormik.values.memo,
        })),
        ...(payment_type === "Card" && {
          customer_vault_id,
          billing_id,
        }),
      };
  
      let response;
  
      if (payment_type === "Card" || payment_type === "ACH") {
        response = await axiosInstance.post(`/nmipayment/new-refund`, { refundDetails: commonData });
      } else if (payment_type === "Cash" || payment_type === "Check") {
        response = await axiosInstance.post(`/nmipayment/manual-refund/${payment_id}`, { refundDetails: commonData });
      } else {
        swal("Warning", "Refund is only available for Card, Cash, or Check payments.", "warning");
        return;
      }
  
      if (response.data.status === 200 || response.data.statusCode === 200) {
        swal("Success", "Refund Done Successfully", "success");
        fetchPayments(paymentPage, paymentRowsPerPage, adminId);
        closeRefund();
      } else if (response.data.status === 201) {
        swal("Warning", response.data.data?.error, "warning");
      } else {
        swal("Error", "Failed to process refund.", "error");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An unexpected error occurred.";
      swal("Error", errorMessage, "error");
    } finally {
      // setPaymentLoader(false);
    }
  };  

  // Handle rows per page change
  const handleChangePaymentRowsPerPage = (event) => {
    setPaymentRowsPerPage(parseInt(event.target.value, 10));
    setPaymentPage(0);
  };
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const isTabletScreen = useMediaQuery("(max-width: 960px)");

  return (
    <>
      {paymentLoader ? (
        // Show loader when insurance data is loading
        <TableContainer
          component={Paper}
          style={{
            width: isTabletScreen ? "100%" : "100%",
            marginBottom: "16px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow style={{ height: "50px" }}>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Date
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Tenant Name
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Rental Address
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Type
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Response
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Description
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Amount
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    className="dashtable-header labelfontstyle titleecolor"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    Action
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ height: "50px" }}>
                <TableCell colSpan={7} align="center">
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={30} style={{ color: "#152B51" }} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalPayments}
            page={paymentPage}
            onPageChange={handleChangePaymentPage}
            rowsPerPage={paymentRowsPerPage}
            onRowsPerPageChange={handleChangePaymentRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
              "& p": { margin: "0" },
              "& div": { margin: "0" },
            }}
          />
        </TableContainer>
      ) : (
        <>
          {payments.length === 0 ? (
            <Typography>No CronJob payment data found</Typography>
          ) : (
            <>
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: "0 0 20px 20px",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow style={{ height: "50px" }}>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Date
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Tenant Name
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Rental Address
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Type
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Response
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Description
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Amount
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className="labelfontstyle titleecolor"
                          style={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                          }}
                        >
                          Action
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.map((payment) => (
                      <TableRow
                        key={payment.lease_id}
                        style={{ height: "50px" }}
                      >
                        <TableCell>{payment.date}</TableCell>
                        <TableCell>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate( admin ?
                                `/admin/tenantdetail/${payment.tenant.tenant_id}`
                                : `/staff/stafftenantdetail/${payment.tenant.tenant_id}`
                              )
                            }
                          >
                            {" "}
                            {payment.tenant.tenant_name ?
                              isSmallScreen
                              ? payment.tenant.tenant_name.split(" ")[0]
                              : payment.tenant.tenant_name
                            : "External Source"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate( admin ? 
                                `/admin/rentrolldetail/${payment.lease_id}`
                                : `/staff/staffrentrolldetail/${payment.lease_id}`
                              )
                            }
                          >
                            {isSmallScreen
                              ? payment.rental_address.slice(0, 10) + "..."
                              : payment.rental_address}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              payment.response === "SUCCESS" ? "green" : "red",
                          }}
                        >
                          {payment.payment_type}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              payment.response === "SUCCESS" ? "green" : "red",
                          }}
                        >
                          {payment.response}
                        </TableCell>
                        <TableCell>
                          <span style={{ cursor: "pointer" }}>
                            {payment.responseText
                              ? isSmallScreen
                                ? payment?.responseText.slice(0, 10) + "..."
                                : payment?.responseText
                              : "-"}
                          </span>
                        </TableCell>
                        <TableCell style={{textAlign: "right"}}>
                          ${Number(payment.total_amount).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <div className="d-flex">
                          {payment.response === "FAILURE" ? (
                            <>
                              <Tooltip title="Acknowledge">
                                <div
                                  style={{ cursor: "pointer", marginRight: "8px" }}
                                  onClick={() => handlePaymentAcknowledge(payment)}
                                >
                                  <CheckIcon color="success" />
                                </div>
                              </Tooltip>
                              <Tooltip title="Retry Payment Now">
                                <div
                                  style={{ cursor: "pointer", marginRight: "8px" }}
                                  onClick={() => handlePaymentRetry(payment)}
                                >
                                  <ReplayIcon />
                                </div>
                              </Tooltip>
                              <Tooltip title="Reschedule Payment">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handlePaymentReSchedule(payment)}
                                >
                                  <EventIcon />
                                </div>
                              </Tooltip>
                            </>
                          ) : payment.state === "settling" ? (
                            <Tooltip title="Void">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handlePaymentVoid(payment)}
                              >
                                <MoneyOffIcon />
                              </div>
                            </Tooltip>
                          ) : payment.state === "settled" ? (
                            <Tooltip title="Refund">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  fetchData(payment._id);
                                  setIsRefundOpen(true);
                                  setRefund(true);
                                }}
                              >
                                <ReplyIcon />
                              </div>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  component="div"
                  count={totalPayments}
                  page={paymentPage}
                  onPageChange={handleChangePaymentPage}
                  rowsPerPage={paymentRowsPerPage}
                  onRowsPerPageChange={handleChangePaymentRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 16px",
                    "& p": { margin: "0" },
                    "& div": { margin: "0" },
                  }}
                />
              </TableContainer>
            </>
          )}
          <Modal
            isOpen={isRefundOpen}
            toggle={closeRefund}
            style={{ maxWidth: "500px" }}
          >
            <ModalHeader
              className=" mx-4 mt-5 text-white background-colorsty bgtextwhite"
              style={{
                borderRadius: "6px",
                height: "45px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
                padding: "10px 7px 1px 21px",
              }}
            >
              <span
                className=" align-items-center bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                Make Refund
              </span>
            </ModalHeader>

            <Form>
              <ModalBody>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label titleecolor fontfamilysty"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Date
                      </label>
                      <Input
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                        className="form-control-alternative"
                        id="input-unitadd"
                        placeholder="3000"
                        type="date"
                        name="date"
                        onBlur={generalledgerFormik.handleBlur}
                        onChange={generalledgerFormik.handleChange}
                        value={generalledgerFormik.values.date}
                      />
                      {generalledgerFormik.touched.date &&
                      generalledgerFormik.errors.date ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {generalledgerFormik.errors.date}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="8">
                    <FormGroup>
                      <label
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        className="form-control-label titleecolor fontfamilysty"
                        htmlFor="input-property"
                      >
                        Refund Amount*
                      </label>
                      <Input
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                        type="text"
                        id="amount"
                        placeholder="Enter amount"
                        name="amount"
                        onBlur={generalledgerFormik.handleBlur}
                        onWheel={(e) => e.preventDefault()}
                        onKeyDown={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const numericValue = inputValue.replace(/\D/g, "");
                          generalledgerFormik.values.amount = numericValue;
                          generalledgerFormik.handleChange({
                            target: {
                              name: "amount",
                              value: numericValue,
                            },
                          });
                        }}
                        value={generalledgerFormik.values.amount}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label titleecolor fontfamilysty"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Memo
                      </label>
                      <Input
                        style={{
                          boxShadow: " 0px 4px 4px 0px #00000040 ",
                          borderRadius: "6px",
                        }}
                        className="form-control-alternative"
                        id="input-unitadd"
                        placeholder="if left blank, will show 'Payment'"
                        type="text"
                        name="memo"
                        onBlur={generalledgerFormik.handleBlur}
                        onChange={generalledgerFormik.handleChange}
                        value={generalledgerFormik.values.memo}
                      />

                      {generalledgerFormik.touched.memo &&
                      generalledgerFormik.errors.memo ? (
                        <div
                          className="requiredstylefont"
                          style={{ color: "red" }}
                        >
                          {generalledgerFormik.errors.memo}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {paymentLoader ? (
                  <Button
                    className="background-colorsty bgtextwhite"
                    disabled
                    type="submit"
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    className="background-colorsty bgtextwhite"
                    type="submit"
                    onClick={(e) => {
                      handlePaymentRefund();
                      e.preventDefault();
                    }}
                  >
                    Make Refund
                  </Button>
                )}
                <Button
                  className="backgroundwhitesty textcolorblue"
                  onClick={closeRefund}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default CronPaymentTable;
