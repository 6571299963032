import Loader from "components/ReusableComponents/Loader";
import { useEffect, useState } from "react";
import { GetCardTransactionType } from "plugins/ApiHandler";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import "../CSS/rentals.scss";

const CardTransactionTypeSettingsView = ({ rentalOwnerId }) => {
  const [getCardTypesLoading, setGetCardTypesLoading] = useState(false);
  const [getCardTypeError, setGetCardTypesError] = useState("");
  const [cardTypeSettings, setCardTypeSettings] = useState({
    rentalOwnerId,
    creditCardAccepted: false,
    debitCardAccepted: false,
  });

  // Get card transaction type debit/credit
  useEffect(() => {
    const fetchData = async () => {
      if (rentalOwnerId) {
        try {
          setGetCardTypesLoading(true);
          setGetCardTypesError("");

          const response = await GetCardTransactionType(rentalOwnerId);
          const data = response.data;

          setCardTypeSettings({
            rentalOwnerId,
            creditCardAccepted: data.creditCardAccepted,
            debitCardAccepted: data.debitCardAccepted,
          });
        } catch (error) {
          setGetCardTypesError(error);
        } finally {
          setGetCardTypesLoading(false);
        }
      }
    };

    fetchData();

    // No cleanup function needed
  }, [rentalOwnerId]);

  return (
    <>
      {getCardTypesLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : getCardTypeError ? (
        <p
          style={{
            textAlign: "center",
            color: "red",
            margin: "10px 0px",
          }}
        >
          {getCardTypeError}
        </p>
      ) : (
        <>
          <div
            style={{
              paddingLeft: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h3
              className="mb-0 labelfontstyle textcolorblue fontfamilysty"
              style={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Card Transaction Type Settings
            </h3>
            <p className="textcolorblue fontfamilysty">
              Allowed card types for rental transactions
            </p>
            <div className="type-box">
              <p>Credit Card</p>
              <span>
                {cardTypeSettings.creditCardAccepted ? (
                  <TiTick size={30} className="allowed-icon" />
                ) : (
                  <ImCross className="not-allowed-icon" />
                )}
              </span>
            </div>
            <div className="type-box">
              <p>Debit Card</p>
              {cardTypeSettings.debitCardAccepted ? (
                <TiTick size={30} className="allowed-icon" />
              ) : (
                <ImCross className="not-allowed-icon" />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardTransactionTypeSettingsView;
