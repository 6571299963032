import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import { Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { strongPassword } from "plugins/helpers";

function CreditCardForm(props) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { closeModal, admin_id } = props;
  const { id, admin } = useParams();
  const navigate = useNavigate();

  const passwordValidation = yup.string()
  .required("Password is required.")
  .test("strong-password", "Invalid password", (value, context) => {
    const result = strongPassword(value);
    return result === true ? true : context.createError({ message: result });
  });

  const StaffMemberFormik = useFormik({
    initialValues: {
      staffmember_name: "",
      staffmember_designation: "",
      staffmember_phoneNumber: "",
      staffmember_email: "",
      staffmember_password: "",
    },
    validationSchema: yup.object({
      staffmember_name: yup.string().required("Name is Required"),
      staffmember_designation: yup.string().required("Designation Required"),
      staffmember_email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      staffmember_phoneNumber: yup
        .string()
        .required("Please enter phone number")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      staffmember_password: passwordValidation,
    }),
    onSubmit: async (values) => {
      const object = {
        admin_id: admin_id,
        staffmember_name: values.staffmember_name,
        staffmember_designation: values.staffmember_designation,
        staffmember_phoneNumber: values.staffmember_phoneNumber,
        staffmember_email: values.staffmember_email,
        staffmember_password: values.staffmember_password,
      };
      try {
        if (id === undefined) {
          const res = await axiosInstance.post(
            `/staffmember/staff_member`,
            object
          );
          if (res.data.statusCode === 200) {
            handleResponse(res);
          } else if (res.data.statusCode === 201) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
            });
          }
        } else {
          const editUrl = `/staffmember/staff_member/${id}`;
          const res = await axiosInstance.put(editUrl, object);
          if (res.data.statusCode === 200) {
            toast.success("Staff Member added successfully", {
              position: "top-center",
              autoClose: 1000,
            });
          } else if (res.data.statusCode === 400) {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
            });
          }
        }
        closeModal();
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Response Data:", error.response.data);
        }
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  async function handleSubmit(values) {
    const object = {
      admin_id: admin_id,
      staffmember_name: values.staffmember_name,
      staffmember_designation: values.staffmember_designation,
      staffmember_phoneNumber: values.staffmember_phoneNumber,
      staffmember_email: values.staffmember_email,
      staffmember_password: values.staffmember_password,
    };
    try {
      if (id === undefined) {
        const res = await axiosInstance.post(
          `/staffmember/staff_member`,
          object
        );
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/staffmember/staff_member/${id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          handleResponse(res);
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }
  }

  function handleResponse(response) {
    const successMessage = id
      ? "Staff Member updated successfully"
      : "Staff Member added successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  const paymentSchema = yup.object({
    card_number: yup.number(),
    exp_date: yup
      .string()
      .matches(/^(0[1-9]|1[0-2])\/[0-9]{4}$/, "Invalid date format (MM/YYYY)")
      .required("Required"),
  });

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const handlePhoneChange = (e) => {
    if (
      StaffMemberFormik.values.staffmember_phoneNumber?.length >
      e.target.value?.length
    ) {
      StaffMemberFormik.setFieldValue(
        "staffmember_phoneNumber",
        e.target.value
      );
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      StaffMemberFormik.setFieldValue(
        "staffmember_phoneNumber",
        formattedValue
      );
    }
  };

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ card_number: "", exp_date: "" }}
        validationSchema={paymentSchema}
        onSubmit={(values, { resetForm }) => {
          if (paymentSchema.isValid) {
            handleSubmit(values);
            resetForm();
          }
        }}
      >
        <Form onSubmit={StaffMemberFormik.handleSubmit}>
          <div className="">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr titleecolor"
                    htmlFor="input-member"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontweight: "500",
                    }}
                  >
                    Staff Member Name *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="input-staffmember-name"
                    placeholder="Enter staff member name"
                    type="text"
                    name="staffmember_name"
                    autoComplete="off"
                    //name="nput-staffmember-name"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={(e) => {
                      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                        StaffMemberFormik.setFieldValue(
                          "staffmember_name",
                          e.target.value
                        );
                      }
                    }}
                    value={StaffMemberFormik.values.staffmember_name.trim()}
                    onKeyDown={preventEnterKey}
                    //required
                  />
                  {StaffMemberFormik.touched.staffmember_name &&
                  StaffMemberFormik.errors.staffmember_name ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {StaffMemberFormik.errors.staffmember_name}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="">
            <Row className="mt-2">
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr titleecolor"
                    htmlFor="input-desg"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontweight: "500",
                    }}
                  >
                    Designation
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="input-staffmember-desg"
                    placeholder="Enter Designation"
                    type="text"
                    name="staffmember_designation"
                              autoComplete="off"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={StaffMemberFormik.handleChange}
                    value={StaffMemberFormik.values.staffmember_designation.trim()}
                    onKeyDown={preventEnterKey}
                  />
                  {StaffMemberFormik.touched.staffmember_designation &&
                  StaffMemberFormik.errors.staffmember_designation ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {StaffMemberFormik.errors.staffmember_designation}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr titleecolor"
                    htmlFor="input-desg"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontweight: "500",
                    }}
                  >
                    Phone Number *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="staffmember_phoneNumber"
                    placeholder="Enter phone number"
                    type="text"
                    name="staffmember_phoneNumber"
                              autoComplete="off"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={handlePhoneChange}
                    value={StaffMemberFormik.values.staffmember_phoneNumber}
                    onKeyDown={preventEnterKey}
                  />
                  {StaffMemberFormik.touched.staffmember_phoneNumber &&
                  StaffMemberFormik.errors.staffmember_phoneNumber ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {StaffMemberFormik.errors.staffmember_phoneNumber}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="">
            <Row className="mt-2">
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr titleecolor"
                    htmlFor="input-desg"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontweight: "500",
                    }}
                  >
                    Email *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative fontstylerentmodal titleecolor"
                    id="staffmember_email"
                    placeholder="Enter email"
                    type="email"
                    name="staffmember_email"
                              autoComplete="off"
                    onBlur={StaffMemberFormik.handleBlur}
                    onChange={StaffMemberFormik.handleChange}
                    value={StaffMemberFormik.values.staffmember_email.toLowerCase()}
                    onKeyDown={preventEnterKey}
                    //required
                  />
                  {StaffMemberFormik.touched.staffmember_email &&
                  StaffMemberFormik.errors.staffmember_email ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {StaffMemberFormik.errors.staffmember_email}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label fontstylerentr titleecolor d-flex align-items-center m-0"
                    htmlFor="input-desg"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontweight: "500",
                    }}
                  >
                    Password *
                    <Tooltip
                      title={
                        <Box style={{ fontSize: "12px" }}>
                          <ul style={{ paddingLeft: "20px", margin: 0 }}>
                            <li>At least one uppercase letter (A-Z).</li>
                            <li>At least one lowercase letter (a-z).</li>
                            <li>At least one number (0-9).</li>
                            <li>
                              At least one special character (e.g., @, #, $,
                              etc.).
                            </li>
                            <li>
                              Password must be at least 12 characters long.
                            </li>
                            <li>
                              No continuous alphabetical characters (e.g., abcd)
                              or continuous numerical characters (e.g., 1234).
                            </li>
                            <li>
                              Avoid strictly sequential patterns (e.g.,
                              Ak12345678!).
                            </li>
                            <li>
                              Don't use birthdays, names, addresses, or other
                              personal information.
                            </li>
                          </ul>
                        </Box>
                      }
                      placement="bottom"
                      arrow
                    >
                      <IconButton>
                        <Info
                          style={{
                            height: "20px",
                            width: "20px",
                            color: "#152B51",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                        paddingRight: "40px",
                      }}
                      className="form-control-alternative fontstylerentmodal titleecolor"
                      id="staffmember_password"
                      placeholder="Password"
                      name="staffmember_password"
                      autoComplete="new-password"
                      type={showPassword ? "text" : "password"}
                      onBlur={StaffMemberFormik.handleBlur}
                      onChange={StaffMemberFormik.handleChange}
                      value={StaffMemberFormik.values.staffmember_password}
                      onKeyDown={preventEnterKey}
                      //required
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                        backgroundColor: "#fff",
                        zIndex: 10,
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon style={{ color: "#152B51" }} />
                      ) : (
                        <VisibilityIcon style={{ color: "#152B51" }} />
                      )}
                    </div>
                  </div>
                  {StaffMemberFormik.touched.staffmember_password &&
                  StaffMemberFormik.errors.staffmember_password ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {StaffMemberFormik.errors.staffmember_password}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row className="mt-3 ml-0 staffrentalsbtn">
            <Button
              type="submit"
              className="btn fontstylerentr "
              style={{
                background: "#152B51",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                cursor: "pointer",
              }}
              disabled={!StaffMemberFormik.isValid}
            >
              {id ? "Update Staff Member" : "Add Staff Member"}
            </Button>
            <Button
              className="btn fontstylerentr "
              onClick={closeModal}
              size="small"
              style={{
                background: "white",
                color: "#152B51",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
          </Row>
          {/* <Row>
            <Button
              type="submit"
              className="btn btn-primary ml-4"
              style={{ background: "green", color: "white" }}
            >
              {id ? "Update Property Type" : "Add Property Type"}
            </Button>
          </Row> */}
        </Form>
      </Formik>
      <ToastContainer />
    </div>
  );
}

export default CreditCardForm;
