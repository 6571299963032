import {
  CardHeader,
  Container,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const VendorProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [vendorDetails, setVendorDetails] = useState({});
  let [loader, setLoader] = React.useState(true);

  const navigate = useNavigate();
  const location  = useLocation();

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getVendorData = async () => {
    if (accessType?.vendor_id) {
      try {
        const response = await axiosInstance.get(
          `/vendor/get_vendor/${accessType.vendor_id}`
        );
        setVendorDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }finally {
        setLoader(false);
      }
    } 
  };
  useEffect(() => {
    getVendorData();
  }, [accessType]);

  return (
    <>
      {/* <VendorHeader /> */}
      <Container className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem", }}
      >
        <CardHeader
          className=" mt-3 mb-3 mx-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Personal Details
          </h2>
        </CardHeader>
        <div>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>

              <div className="my-table mx-3"  style={{ overflowX: "auto" }}>
                <table className="data" style={{ width: "100%" }}>
                  <tr>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">Name</th>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">Phone Number</th>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">Email</th>
                  </tr>
                  <tr>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{vendorDetails.vendor_name}</td>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{vendorDetails.vendor_phoneNumber}</td>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">{vendorDetails.vendor_email}</td>
                  </tr>

                </table>
              </div>
            </>
          )}
        </div>
        {/* <Row>
          <div className="col mb-5" style={{}}>
          <Row
              className="mx-3 py-0 mt-3"
              style={{
                border: ".5px solid rgba(50, 69, 103, 1)",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                height: "45px",
                alignItems: "center",
                borderBottom: "0px",
                color: "#152B51",
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "#152B51",
              }}
            >
              <Col className="fontstylerentr tablevendorheadtext"
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Name
              </Col>

              <Col className="fontstylerentr tablevendorheadtext" 
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Phone Number
              </Col>
              <Col className="fontstylerentr tablevendorheadtext"
                style={{
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Email
              </Col>
            </Row>
            <Row
              className="mx-3 py-0"
              style={{
                border: ".5px solid rgba(50, 69, 103, 1)",
                borderBottomLeftRadius: "12px",
                borderBottomRightRadius: "12px",
                height: "45px",
                alignItems: "center",
                color: "#152B51",
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "12px",
                color: "#152B51",
                boxShadow: " 0px 4px 4px 0px #00000040",
              }}
            >
              <Col className="fontstylerentmodal tablebodyvendortext"
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {vendorDetails.vendor_name}
              </Col>

              <Col className="fontstylerentmodal tablebodyvendortext"
                style={{
                  borderRight: ".5px solid rgba(50, 69, 103, 1)",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {vendorDetails.vendor_phoneNumber}
              </Col>
              <Col className="fontstylerentmodal tablebodyvendortext"
                style={{
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {vendorDetails.vendor_email}
              </Col>
            </Row>
          </div>
        </Row> */}
        {/* <Table className="align-items-center table-flush mx-3" responsive style={{ borderCollapse: "collapse" }}>
          <thead className="" style={{
            height: "45px",
            fontSize: "14px",
            fontFamily: "poppins",
            fontWeight: "600",
            boxShadow: " 0px 4px 4px 0px #00000040",
          }}>
            <tr style={{
              border: "2px solid rgba(50, 69, 103, 1)",
            }}>

              <th className="tablefontstyle" scope="col" style={{
                borderTopLeftRadius: "15px",

                color: "#152B51"
              }}>
                Name

              </th>
              <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>
                Phone Number

              </th>
              <th className="tablefontstyle" scope="col" style={{ color: "#152B51" }}>
                Email

              </th>

            </tr>
          </thead>

          <tbody>
            <tr style={{
              border: "none",
            }}>
              <td colSpan="9"></td>
            </tr>

            <tr style={{
              border: "0.5px solid rgba(50, 69, 103, 1)",
              fontSize: "12px",
              height: "40px",
              fontFamily: "poppins",
              fontWeight: "600",
            }}>
              <td className="bordertopintd tablebodyfont">{vendorDetails.vendor_name}</td>
              <td className="bordertopintd tablebodyfont">{vendorDetails.vendor_phoneNumber}</td>
              <td className="bordertopintd tablebodyfont">
              {vendorDetails.vendor_email}
              </td>


            </tr>

          </tbody>

        </Table> */}
      </Container>
    </>
  );
};
export default VendorProfile;
