// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bordertopintd {
  border-top: 0.5px solid rgba(50, 69, 103, 1) !important;
}
/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
}

/* 1280 */
@media (max-width: 1439px) {
}

/* 992 */
@media (max-width: 1279px) {
}

/* 768 */
@media (max-width: 991px) {
}

/* 576 */
@media (max-width: 767px) {
}

/* 525 */
@media (max-width: 575px) {
}

/* 480 */
@media (max-width: 524px) {
}

/* 425 */
@media (max-width: 479px) {
}

/* 375 */
@media (max-width: 424px) {
}

/* 320 */
@media (max-width: 374px) {
}

/* 280 */
@media (max-width: 319px) {
}

/* 1646 */
@media (max-width: 1646px) {
  .inputstylee {
    width: 100% !important;
  }
}

/* 342*/
@media (max-width: 342px) {
  .cancelbtnvendor {
    margin-top: 15px !important;
  }
}

/*991 style for mobile view vendortable add vendor and edit vendor*/
@media (max-width: 991px) {
  .vendorbtnstyle {
    width: 100px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 33px !important;
    padding: 3px !important;
    border-radius: 4px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/source/vendor.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;AACzD;AACA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,SAAS;AACT;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,QAAQ;AACR;AACA;;AAEA,SAAS;AACT;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA,OAAO;AACP;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA,mEAAmE;AACnE;EACE;IACE,uBAAuB;IACvB,0BAA0B;IAC1B,2BAA2B;IAC3B,uBAAuB;IACvB,uBAAuB;IACvB,6BAA6B;EAC/B;AACF","sourcesContent":[".bordertopintd {\n  border-top: 0.5px solid rgba(50, 69, 103, 1) !important;\n}\n/* 1680 */\n@media (max-width: 1700px) {\n}\n\n/* 1500 */\n@media (max-width: 1680px) {\n}\n\n/* 1440 */\n@media (max-width: 1449px) {\n}\n\n/* 1280 */\n@media (max-width: 1439px) {\n}\n\n/* 992 */\n@media (max-width: 1279px) {\n}\n\n/* 768 */\n@media (max-width: 991px) {\n}\n\n/* 576 */\n@media (max-width: 767px) {\n}\n\n/* 525 */\n@media (max-width: 575px) {\n}\n\n/* 480 */\n@media (max-width: 524px) {\n}\n\n/* 425 */\n@media (max-width: 479px) {\n}\n\n/* 375 */\n@media (max-width: 424px) {\n}\n\n/* 320 */\n@media (max-width: 374px) {\n}\n\n/* 280 */\n@media (max-width: 319px) {\n}\n\n/* 1646 */\n@media (max-width: 1646px) {\n  .inputstylee {\n    width: 100% !important;\n  }\n}\n\n/* 342*/\n@media (max-width: 342px) {\n  .cancelbtnvendor {\n    margin-top: 15px !important;\n  }\n}\n\n/*991 style for mobile view vendortable add vendor and edit vendor*/\n@media (max-width: 991px) {\n  .vendorbtnstyle {\n    width: 100px !important;\n    font-size: 13px !important;\n    font-weight: 600 !important;\n    height: 33px !important;\n    padding: 3px !important;\n    border-radius: 4px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
