import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Col, Row } from "reactstrap";
import axiosInstance from "axiosInstance";
import LeaseTable from "../components/LeaseTable";

const ExpiringLeaseComponent = ({ adminId }) => {
      const [leases, setLeases] = useState([]);
      const [leaseLoader, setLeaseLoader] = useState(false);
      const [totalLeases, setTotalLeases] = useState(0);
      const [leasePage, setLeasePage] = useState(0);
      const [leaseRowsPerPage, setLeaseRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchLeases  = async (page, limit, admin_id) => {
        setLeaseLoader(true);
      try {
        page = parseInt(page)+1;
        const response = await axiosInstance.get(`/leases/expiring_leases/${admin_id}`, {
          params: { page, limit, sort: "asc" },
        });
        setLeases(response.data.data || []);
        setTotalLeases(response.data.metadata.total || 0);
      } catch (error) {
        console.error("Error fetching policies:", error);
      } finally {
        setLeaseLoader(false);
      }
    };

    if (adminId) {
        fetchLeases(leasePage, leaseRowsPerPage, adminId);
    }
  }, [leasePage, leaseRowsPerPage, adminId]);
  return (
    <div style={{ width: "100%" }}>
      {/* <Row>
        <Col className="mx-3"> */}
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center dashtable-title"
                style={{
                  height: "50px",
                  backgroundColor: "#324B77",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                Leases Expiring in the next 60 days
              </span>
            </Typography>
            {leases.length === 0 ? (
              <Typography>No leases are expiring within 60 days.</Typography>
            ) : (
              <LeaseTable
                leases={leases}
                leaseLoader={leaseLoader}
                totalLeases={totalLeases}
                leasePage={leasePage}
                leaseRowsPerPage={leaseRowsPerPage}
                setLeasePage={setLeasePage}
                setLeaseRowsPerPage={setLeaseRowsPerPage}
              />
            )}
          </CardContent>
        {/* </Col>
      </Row> */}
    </div>
  );
};

export default ExpiringLeaseComponent;
