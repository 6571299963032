import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Input,
  Col,
  DropdownToggle,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import TenantsHeader from "components/Headers/TenantsHeader";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import swal from "sweetalert";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import PolicyDialog from "../../components/PolicyDialog";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const DocInsurance = () => {
  let navigate = useNavigate();
  const [workData, setWorkData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);

  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  const [userroleData, setUserroleData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [TenantInsuranceId, setTenantInsuranceId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getRentalData = async () => {
    if (accessType?.tenant_id) {
      try {
        const response = await axiosInstance.get(
          `/tenantinsurance/docinsurance/${accessType?.tenant_id}`
        );
        setWorkData(response.data.data);
        setTotalPages(Math.ceil(response.data?.data?.length / pageItem));
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getRentalData();
  }, [accessType, pageItem, upArrow, sortBy]);

  const navigateToDetails = (tenantId) => {
    navigate(`/tenant/tenant/get/${tenantId}`);
  };

  const filterRentalsBySearch = () => {
    let filteredData = workData;

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        return (
          tenant?.Provider?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.policy_id
            ?.toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          tenant?.LiabilityCoverage?.toString()
            .toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          tenant?.status?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.EffectiveDate?.toLowerCase().includes(lowerCaseSearchQuery) ||
          tenant?.ExpirationDate?.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;
      sortingArrows.forEach((value) => {
        switch (value) {
          case "Provider":
            filteredData.sort((a, b) => {
              const comparison = a.Provider?.localeCompare(b.Provider);
              return upArrow.includes("Provider") ? comparison : -comparison;
            });
            break;
          case "policy_id":
            filteredData.sort((a, b) =>
              a.policy_id?.localeCompare(b.policy_id)
            );
            break;
          case "LiabilityCoverage":
            filteredData.sort(
              (a, b) => a.LiabilityCoverage - b.LiabilityCoverage
            );
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.tenant_permission);
      }
    };
    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const handleAdd = async () => {
    const activeInsurance = workData.find((item) => item.status === "ACTIVE");

    if (activeInsurance) {
      swal({
        title: "Are you sure?",
        text: `If you add new renters insurance, the older one with active Policy ID ${activeInsurance.policy_id} will get expired!`,
        icon: "warning",
        buttons: ["Yes", "Cancel"],
        dangerMode: true,
      }).then(async (willAdd) => {
        if (!willAdd) {
          setIsModalOpen(true);
          setTenantInsuranceId(null);
        } else {
          toast.success("Renters Insurance is safe!", {
            position: "top-center",
            autoClose: 800,
          });
        }
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    getRentalData();
    setTenantInsuranceId(null);
  };

  const [deletionReason, setDeletionReason] = useState("");

  const handlePolicyDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Insurance!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            setDeletionReason(reason);

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            if (reason.trim() === "") {
              deleteButton.disabled = true;
            } else {
              deleteButton.disabled = false;
            }
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        try {
          const apiUrl = `/tenantinsurance/tenantinsurance/${id}`;
          const response = await axiosInstance.delete(apiUrl, {
            data: {
              reason: value,
            },
          });

          if (response.data.statusCode === 200) {
            toast.success("Insurance Deleted successfully!", {
              position: "top-center",
              autoClose: 2000,
              onClose: closeModal,
            });
            getRentalData();
          } else {
            toast.error(response.data.message, {
              position: "top-center",
            });
          }
        } catch (error) {
          toast.error("Error deleting insurance", {
            position: "top-center",
          });
          console.error("Error:", error.message);
        }
      } else {
        toast.success("Insurance is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  return (
    <>
      <TenantsHeader />

      <Container className="stylecontainer" fluid>
        <Row>
          {userroleData?.workorder_add && (
            <Col className="text-right">
              <Button
                className="fontstylerentr background-colorsty bgtextwhite"
                color="primary"
                onClick={() => handleAdd()}
                size="small"
              >
                Add Insurance
              </Button>
            </Col>
          )}
        </Row>
        <CardHeader
          className=" mt-3 mb-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Insurance
          </h2>
        </CardHeader>

        <Row>
          <div className="col">
            <CardHeader className="border-0 p-0 mt-3 mb-3">
              <Row className="d-flex mx-2">
                <FormGroup className="mr-sm-2 searchspace searchstyle">
                  <Input
                    className="filterh"
                    fullWidth
                    type="text"
                    placeholder="Search here..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                      minWidth: "200px",
                      border: "1px solid #ced4da",
                    }}
                  />
                </FormGroup>
              </Row>
            </CardHeader>

            <Table
              className="align-items-center table-flush"
              responsive
              style={{ borderCollapse: "collapse" }}
            >
              {loader ? (
                <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={loader}
                  />
                </div>
              ) : (
                <>
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Insurance Company
                        {sortBy.includes("Provider") ? (
                          upArrow.includes("Provider") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("Provider")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("Provider")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("Provider")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Policy Id
                        {sortBy.includes("policy_id") ? (
                          upArrow.includes("policy_id") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("policy_id")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("policy_id")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("policy_id")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Liability Coverage
                        {sortBy.includes("LiabilityCoverage") ? (
                          upArrow.includes("LiabilityCoverage") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("LiabilityCoverage")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("LiabilityCoverage")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("LiabilityCoverage")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Effective Date
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                        }}
                      >
                        Expiration Date
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                        }}
                      >
                        ACTIONS
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      style={{
                        border: "none",
                      }}
                    >
                      <td colSpan="9"></td>
                    </tr>
                    {filterTenantsBySearchAndPage()?.length > 0 ? (
                      filterTenantsBySearchAndPage()?.map((rental) => (
                        <>
                          <tr
                            key={rental?.workOrder_id}
                        
                            style={{
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.Provider}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.policy_id}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.LiabilityCoverage}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {rental?.status || "-"}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {moment(rental?.EffectiveDate).format(dateFormat)}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {moment(rental?.ExpirationDate).format(
                                dateFormat
                              )}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              <div style={{ display: "flex", gap: "5px" }}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handlePolicyDelete(
                                      rental.TenantInsurance_id
                                    );
                                  }}
                                >
                                  <img src={deleicon} width={20} height={20} />
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setTenantInsuranceId(
                                      rental.TenantInsurance_id
                                    );
                                  }}
                                >
                                  <img src={editicon} width={20} height={20} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr
                        style={{
                          border: "2px solid rgba(50, 69, 103, 1)",
                        }}
                      >
                        <td
                          colSpan={7}
                          className="text-center bordertopintd tablebodyfont"
                        >
                          No data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </Table>
            {workData?.length > 0 ? (
              <Row>
                <Col className="text-right mt-3 mb-3">
                  <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                    <DropdownToggle className="paginationbtnstyle" caret>
                      {pageItem}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(10);
                          setCurrentPage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(25);
                          setCurrentPage(1);
                        }}
                      >
                        25
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(50);
                          setCurrentPage(1);
                        }}
                      >
                        50
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setPageItem(100);
                          setCurrentPage(1);
                        }}
                      >
                        100
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-left"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg>
                  </Button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>{" "}
                  <Button
                    className="p-0"
                    style={{ backgroundColor: "#d0d0d0" }}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-caret-right"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                    </svg>
                  </Button>{" "}
                </Col>
              </Row>
            ) : null}
          </div>
        </Row>

        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "600px", borderRadius: "20px" }}
        >
          <ModalHeader
            className=" mx-3 mt-5 text-white headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "6px",
              height: "45px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              padding: "10px 7px 1px 21px",
              color: "white",
            }}
          >
            <span
              className=" align-items-center headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              Add Insurance Policy
            </span>
          </ModalHeader>
          <ModalBody>
            <PolicyDialog
              id={accessType?.tenant_id}
              closeModal={closeModal}
              TenantInsuranceId={TenantInsuranceId}
              admin_id={accessType?.admin_id}
            />
          </ModalBody>
        </Modal>
        <ToastContainer />
      </Container>
    </>
  );
};

export default DocInsurance;
