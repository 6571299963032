import React, { useEffect, useRef, useState } from "react";
import {
  useLocation,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import routes from "routes.js";
import { useMediaQuery } from "@mui/material";

const Admin = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const { admin } = useParams();

  const [isCollapse, setIsCollapse] = useState(false);
  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        location?.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const [sideOpen, setSideOpen] = useState(false);
  const toggleOpenSidebar = () => {
    setSideOpen(!sideOpen);
  };

  return (
    <div style={{ backgroundColor: "#fff", height: "100%" }}>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: "/" + admin + "/index",
          imgSrc: require("../assets/icons/websitelogo.svg"),
          imgSrc2: require("../assets/icons/site-logo-1 7.png"),
          imgAlt: "...",
        }}
        isCollapse={isCollapse}
        setIsCollapse={setIsCollapse}
        toggleCollapse={toggleCollapse}
        sideOpen={sideOpen}
        setSideOpen={setSideOpen}
      />
      <div
        className={
          isMediumScreen
            ? "collapse-content"
            : !isCollapse
            ? `content`
            : `content-active`
        }
        ref={mainContent}
        style={{ backgroundColor: sideOpen && "rgba(21, 43, 81, 0.16)" }}
      >
        <AdminNavbar
          {...props}
          brandText={getBrandText()}
          logo={require("../assets/icons/site-logo-1 7.png")}
          toggleOpenSidebar={toggleOpenSidebar}
          isCollapse={isCollapse}
        />
        <div className="pt-5">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={<Navigate to={`/${admin}/index`} replace />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;
