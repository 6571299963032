import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table, Container, Row, Card, CardHeader } from "reactstrap";
import { Col, Button } from "reactstrap";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import fone from "../../assets/img/icons/common/property_bg.png";

const TenantPropertyDetail = () => {
  const { lease_id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [propertyDetails, setPropertyDetails] = useState([]);
  const getRentalData = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(
          `/leases/lease_summary/${lease_id}`
        );
        setPropertyDetails(res.data.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getRentalData();
  }, [lease_id]);

  return (
    <>
      {/* <TenantsHeader /> */}
      <Container
        className="tenantcontainerstyle"
        fluid
        style={{ marginTop: "4rem" }}
      >
        {loading ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loading}
            />
          </div>
        ) : (
          <>
            <Row>
              <Col className="text-right mr-1">
                <Button
                  className="fontstylerentr background-colorsty bgtextwhite"
                  
                  onClick={() => navigate("/tenant/tenantproperty")}
                  size="small"
                  style={{
                    background: "#152B51",
                    color: "white",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
            <div>
              <CardHeader
                className=" mt-3 headproptypebar background-colorsty"
                style={{
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                }}
              >
                <h3 className="mb-0 text-left headproptypee bgtextwhite">
                  Property Details
                </h3>
              </CardHeader>
            </div>
            <br />
            {/* Table */}
            <Row>
              <div className="col">
                <h3 className="mb-2 my-0 mx-2 labelfontstyle textcolorblue fontfamilysty">
                  Summary
                </h3>
                <div className="">
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="align-items-center table-flush"
                          responsive
                          style={{ width: "100%" }}
                        >
                          <div className="w-100">
                            <Card
                              className="p-4 mb-4"
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                border: "0.5px solid #152B51",
                                borderRadius: "10px",
                              }}
                            >
                              <Row
                                className="w-100"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  color: "#3B2F2F",
                                  fontWeight: "600",
                                }}
                              >
                                <Col
                                  className="mb-2 labelfontstyle"
                                  style={{
                                    color: "#152B51",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Property Details
                                </Col>
                              </Row>

                              <Row>
                                <Col md={10} lg={10}>
                                  <div style={{ overflowX: "auto" }}>
                                    <table
                                      className="table table-bordered"
                                      style={{ width: "100%" }}
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            className="fontstylerentr tablefontstyle textcolorblue"
                                            scope="col"
                                            style={{
                                              border: "0.5px solid #324567",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Property Details
                                          </th>
                                          <th
                                            className="fontstylerentr tablefontstyle textcolorblue"
                                            scope="col"
                                            style={{
                                              border: "0.5px solid #324567",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Address
                                          </th>
                                          <th
                                            className="fontstylerentr tablefontstyle textcolorblue"
                                            scope="col"
                                            style={{
                                              border: "0.5px solid #324567",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                            }}
                                          >
                                            City
                                          </th>
                                          <th
                                            className="fontstylerentr tablefontstyle textcolorblue"
                                            scope="col"
                                            style={{
                                              border: "0.5px solid #324567",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Country
                                          </th>
                                          <th
                                            className="fontstylerentr tablefontstyle textcolorblue"
                                            scope="col"
                                            style={{
                                              border: "0.5px solid #324567",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Post code
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            className="fontstylerentmodal tablebodyfont tabletextcolor"
                                            scope="row"
                                            style={{
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {propertyDetails?.propertysub_type}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tablebodyfont tabletextcolor"
                                            style={{
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {propertyDetails?.rental_adress}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tablebodyfont tabletextcolor"
                                            style={{
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {propertyDetails?.rental_city}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tablebodyfont tabletextcolor"
                                            style={{
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {propertyDetails?.rental_country}
                                          </td>
                                          <td
                                            className="fontstylerentmodal tablebodyfont tabletextcolor"
                                            style={{
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {propertyDetails?.rental_postcode}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Col>
                                <Col
                                  md={2}
                                  lg={2}
                                  className="tenantimagestylee"
                                >
                                  <div>
                                    <img
                                    
                                      src={
                                        propertyDetails?.rental_image
                                          ? `${imageGetUrl}/${propertyDetails?.rental_image}`
                                          : fone
                                      }
                                      alt="Property Details"
                                      style={{
                                        width: "100%",
                                        height: "6.4rem",
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                            <Card
                              className="p-4 mb-4"
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                border: "0.5px solid #152B51",
                                borderRadius: "10px",
                              }}
                            >
                              <Row
                                className="w-100"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  color: "#3B2F2F",
                                  fontWeight: "600",
                                 
                                }}
                              >
                                <Col className="mb-2 labelfontstyle textcolorblue fontfamilysty">
                                  Rental Owner Details
                                </Col>
                              </Row>
                              <Row
                                className="mb-1 m-0 p-0"
                                style={{ fontSize: "12px", color: "#000" }}
                              >
                                <div
                                  style={{ overflowX: "auto", width: "100%" }}
                                >
                                  <table className="table table-bordered textcolorblue fontfamilysty">
                                    <thead>
                                      <tr>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Contact Name
                                        </th>

                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Company name
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Email
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Phone no
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          scope="row"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rentalOwner_name}
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {
                                            propertyDetails?.rentalOwner_companyName
                                          }
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {
                                            propertyDetails?.rentalOwner_primaryEmail
                                          }
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {
                                            propertyDetails?.rentalOwner_phoneNumber
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Row>
                            </Card>

                            <Card
                              className="p-4 mb-4"
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                border: "0.5px solid #152B51",
                                borderRadius: "10px",
                              }}
                            >
                              <Row
                                className="w-100"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  color: "#3B2F2F",
                                  fontWeight: "600",
                                 
                                }}
                              >
                                <Col className="mb-2 labelfontstyle textcolorblue fontfamilysty">
                                  Staff Details
                                </Col>
                              </Row>
                              <Row
                                className="mb-1 m-0 p-0"
                                style={{ fontSize: "12px", color: "#000" }}
                              >
                                <table className="table table-bordered textcolorblue fontfamilysty">
                                  <thead>
                                    <tr>
                                      <th
                                        className="fontstylerentr tablefontstyle textcolorblue"
                                        scope="col"
                                        style={{
                                          border: "0.5px solid #324567",
                                          fontSize: "18px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Staff member
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        className="fontstylerentmodal tablebodyfont tabletextcolor"
                                        scope="row"
                                        style={{
                                          border: "0.5px solid #324567",
                                          borderRight: "0.5px solid #324567",
                                        }}
                                      >
                                        {propertyDetails?.staffmember_name ||
                                          "No staff member assigned"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Row>
                            </Card>
                            <Card
                              className="p-4"
                              style={{
                                boxShadow: "0px 4px 4px 0px #00000040",
                                border: "0.5px solid #152B51",
                                borderRadius: "10px",
                              }}
                            >
                              <Row
                                className="w-100"
                                style={{
                                  fontSize: "18px",
                                  textTransform: "capitalize",
                                  color: "#3B2F2F",
                                  fontWeight: "600",
                               
                                }}
                              >
                                <Col className="mb-2 labelfontstyle textcolorblue fontfamilysty">
                                  Unit Details
                                </Col>
                              </Row>
                              <Row
                                className="mb-1 m-0 p-0"
                                style={{ fontSize: "12px", color: "#000" }}
                              >
                                <div
                                  style={{ overflowX: "auto", width: "100%" }}
                                >
                                  <table className="table table-bordered textcolorblue fontfamilysty">
                                    <thead>
                                      <tr>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Unit Address
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Bed
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Bath
                                        </th>
                                        <th
                                          className="fontstylerentr tablefontstyle textcolorblue"
                                          scope="col"
                                          style={{
                                            border: "0.5px solid #324567",
                                            textTransform: "capitalize",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Square Fit
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                     
                                      >
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          scope="row"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rental_unit ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rental_unit_adress ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rental_bed || "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rental_bath ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal tablebodyfont tabletextcolor"
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {propertyDetails?.rental_sqft ||
                                            "N/A"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Row>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </Row>
            <br />
          </>
        )}
      </Container>
    </>
  );
};

export default TenantPropertyDetail;
