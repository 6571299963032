import axiosInstance from "axiosInstance";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import AddPaymentByTenant from "views/TenantViews/PaymentViews/AddPaymentByTenant";

const ExpressPayment = () => {
  const { token } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [loader, setLoader] = useState(true);
  const [tokenData, setTokenData] = useState(undefined);

  useEffect(() => {
    const paymentData = async () => {
      if (token) {
        try {
          const res = await axiosInstance.get(`/leases/payment_data/${token}`);
          if (res.data.status) {
            setTokenData(res.data.data);
            setCookie("admin_id", res.data.data.admin_id, { path: "/" });
            setCookie("tenant_id", res.data.data.tenant_id, { path: "/" });
            setCookie("ID", res.data.data.tenant_id, { path: "/" });
            setCookie("token", res.data.token, { path: "/" });
          } else {
            removeCookie("admin_id", { path: "/" });
            removeCookie("tenant_id", { path: "/" });
            removeCookie("ID", { path: "/" });
            removeCookie("token", { path: "/" });
          }
        } catch (error) {
          console.error(error.message);
          removeCookie("admin_id", { path: "/" });
          removeCookie("tenant_id", { path: "/" });
          removeCookie("ID", { path: "/" });
          removeCookie("token", { path: "/" });
        } finally {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    };
    paymentData();
  }, [token]);

  return (
    <>
      {loader ? (
        <>Loading...</>
      ) : !tokenData ? (
        <div>
          <h5>Payment Period Expired.</h5>
        </div>
      ) : (
        <>
          <div className="mx-5 mt-3">
            <h6 style={{ fontSize: "18px" }}>
              Tenant:{" "}
              {(tokenData?.tenant?.tenant_firstName || "") +
                " " +
                (tokenData?.tenant?.tenant_lastName || "")}
            </h6>
          </div>
          <AddPaymentByTenant />
        </>
      )}
    </>
  );
};

export default ExpressPayment;
