import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardContent, Checkbox, Tooltip, Typography } from "@mui/material";
import swal from "sweetalert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import "jspdf-autotable";
import "jspdf-autotable";
import { useCookies } from "react-cookie";
import moment from "moment";
import axiosInstance from "axiosInstance";
import { Dialog, DialogTitle } from "@mui/material";
import AccountDialog from "components/AccountDialog";
import AddTenantCreditCardByAdmin from "views/AdminViews/AddTenantCreditCardByAdmin";
import { getTokenizationKey, getCardLogo } from "plugins/ApiHandler";
import { addTokenizationScript } from "plugins/helpers";
import { getCardType } from "plugins/ApiHandler";
import { getRentalOwnerCardTypeSettings } from "plugins/ApiHandler";
import { verifyToken } from "components/Functions/Functions";

function AddPayment() {
  const [accessType, setAccessType] = useState(null);
  const [currentPlan, setCurrentPlan] = useState("");
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [isFrom, setIsFrom] = useState();
  const { lease_id, admin, payment_id } = useParams();
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [tenantId, setTenantId] = useState("");
  const [tenantData, setTenantData] = useState([]);
  const [processorId, setProcessorId] = useState("");
  const [rentalId, setRentalId] = useState("");
  const [selectedTenant, setSelectedTenant] = useState({});
  const [loader, setLoader] = useState(false);
  const [recdropdownOpen, setrecDropdownOpen] = useState(false);
  const [typedropdownOpen, setTypeDropdownOpen] = useState(false);
  const [accountdropdownOpen, setAccountDropdownOpen] = useState(false);
  const [achdropdownOpen, setACHDropdownOpen] = useState(false);
  const [selectedRec, setSelectedRec] = useState("Select Resident");
  const [addAnotherPayment, setAddAnotherPayment] = useState(false);
  const [isEnable, setIsEnable] = useState(false);

  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");

  const getNmiKey = async () => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(tenantId);
      await addTokenizationScript(keyResponse.publicKey);
    } catch (error) {
      setScriptError(
        error ||
          "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  useEffect(() => {
    if (tenantId) getNmiKey();
  }, [tenantId]);

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axiosInstance.post(`/admin/token_check`, {
  //         token: cookies.token,
  //       });
  //       if (response) {
  //         const res = await axiosInstance.get(
  //           `/purchase/plan-purchase/${response.data.admin_id}`
  //         );
  //         setCurrentPlan(res.data.data.plan_detail.plan_name);
  //       }
  //       setAccessType(response.data);

  //       if ("Admin Not Found" === response.data.message) {
  //         navigate("/auth/login");
  //       }
  //     } catch (error) {
  //       console.error("Error checking token:", error);
  //       // Handle error here, maybe redirect to login page or show an error message
  //     }
  //   };

  //   if (cookies.token) {
  //     checkToken();
  //   } else {
  //     navigate("/auth/login");
  //   }
  // }, [cookies.token, navigate]);

  useEffect(() => {
    if (location?.state?.tab) {
      setIsFrom(location?.state?.tab);
    }
  }, [location.state]);

  const toggle2 = () => setrecDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setAccountDropdownOpen((prevState) => !prevState);
  const toggle4 = () => setACHDropdownOpen((prevState) => !prevState);
  const toggle5 = () => setTypeDropdownOpen((prevState) => !prevState);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedACHAccount, setSelectedACHAccount] = useState("");

  //NMI Payment Section
  const [modalShow, setModalShow] = useState(false);
  const [customervault, setCustomervault] = useState([]);
  const [cardDetalis, setCardDetails] = useState([]);
  const [isBilling, setIsBilling] = useState(false);
  const [vaultId, setVaultId] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const [selectedBintype, setSelectedBintype] = useState(null);
  const [totalAmount1, setTotalAmount1] = useState();
  const [surchargePercentage, setSurchargePercentage] = useState(0);
  const [debitSurchargePercentage, setDebitSurchargePercentage] = useState("");
  const [ACHSurchargePercentage, setACHSurchargePercentage] = useState("");
  const [ACHflatfee, setACHflatfee] = useState("");

  // Fetch surcharge from the API
  const fetchData = async () => {
    let adminid = accessType?.admin_id;
    const url = `/surcharge/surcharge/getadmin/${adminid}`;
    axiosInstance
      .get(url)
      .then((response) => {
        // const Data = response.data.data[0];
        setSurchargePercentage(response.data.data[0].surcharge_percent);
        setDebitSurchargePercentage(
          response.data.data[0].surcharge_percent_debit
        );
        setACHSurchargePercentage(response.data.data[0].surcharge_percent_ACH);
        setACHflatfee(response.data.data[0].surcharge_flat_ACH);
      })
      .catch((error) => {
        console.error("Error fetching surcharge data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [accessType?.admin_id]);

  const [preFillData, setPreFillData] = useState({});
  const handleRecieverSelection = (tenant) => {
    setPreFillData({
      first_name: tenant.tenant_firstName,
      last_name: tenant.tenant_lastName,
      email: tenant.tenant_email,
      phone: tenant.tenant_phoneNumber,
    });
    // setProperty(property);
    setSelectedTenant(tenant);
    setSelectedRec(`${tenant.tenant_firstName} ${tenant.tenant_lastName}`);
    setTenantId(tenant.tenant_id);
  };

  const closeModal = () => {
    setModalShow(false);
    getCreditCard();
  };

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const generalledgerFormik = useFormik(
    !payment_id
      ? {
          initialValues: {
            payment_id: "",
            date: getCurrentDate(),
            total_amount: "",
            payments_memo: "",
            payment_type: "",
            check_number: "",
            customer_vault_id: "",
            billing_id: "",
            transaction_id: "",
            surcharge: "",
            checkaba: "",
            checkname: "",
            checkaccount: "",
            account_holder_type: "",
            account_type: "",
            reference: "",
            payments: [
              {
                entry_id: "",
                account: "",
                amount: "",
                balance: "",
                charge_type: "",
              },
            ],
            payments_attachment: [],
          },
          // {{5757-start}}
          validationSchema: yup.object({
            date: yup.string().required("Required"),
            total_amount: yup.string().required("Required"),
            payments: yup
              .array()
              .of(
                yup.object().shape({
                  account: yup.string().required("Required"),
                  amount: yup
                    .number()
                    .required("Required")
                    .min(0, "Amount must be greater than zero.")
                    .test(
                      "is-less-than-balance",
                      "Amount must be less than or equal to balance",
                      function (value) {
                        if (value && this.parent.balance) {
                          const balance = this.parent.balance;
                          return value <= balance;
                        }
                        return true;
                      }
                    ),
                })
              )
              .test(
                "unique-accounts",
                "Each account must be unique",
                function (payments) {
                  if (!payments) return true;
                  const accounts = payments.map((p) => p.account);
                  return accounts.length === new Set(accounts).size;
                }
              ),
          }),
          // {{5757-end}}
          onSubmit: (values) => {
            if (
              Number(generalledgerFormik.values.total_amount).toFixed(2) ===
              Number(total).toFixed(2)
            ) {
              handleSubmit(values);
            }
          },
        }
      : {
          initialValues: {
            payment_id: "",
            date: getCurrentDate(),
            total_amount: "",
            payments_memo: "",
            check_number: "",
            customer_vault_id: "",
            billing_id: "",
            transaction_id: "",
            surcharge: "",
            reference: "",
            payments: [
              {
                entry_id: "",
                account: "",
                amount: "",
                balance: "",
                charge_type: "",
              },
            ],
            payments_attachment: [],
          },

          validationSchema: yup.object({
            date: yup.string().required("Required"),
            total_amount: yup.string().required("Required"),
            payments: yup.array().of(
              yup.object().shape({
                account: yup.string().required("Required"),
                amount: yup
                  .number()
                  .required("Required")
                  .min(1, "Amount must be greater than zero."),
              })
            ),
          }),
          onSubmit: (values) => {
            if (
              Number(generalledgerFormik.values.total_amount).toFixed(2) ===
              Number(total).toFixed(2)
            ) {
              handleSubmit(values);
            }
          },
        }
  );

  const handleCreditCardSelection = (selectedCard) => {
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      //customer_vault_id: selectedCard.customer_vault_id,
      billing_id: selectedCard.billing_id,
    });
    setSelectedCreditCard(selectedCard.billing_id);
  };

  const getCreditCard = async () => {
    if (tenantId) {
      try {
        const response = await axiosInstance.get(
          `/creditcard/getCreditCards/${tenantId}`
        );
        setCustomervault(response.data);
        setVaultId(response.data.customer_vault_id);
        getMultipleCustomerVault(response.data.customer_vault_id);
        const hasCustomerVaultId = response.data.some(
          (card) => card.customer_vault_id
        );
        if (hasCustomerVaultId) {
          setIsBilling(true);
        } else {
          setIsBilling(false);
        }
      } catch (error) {
        console.error("Error fetching credit card details:", error);
        setIsBilling(false);
      }
    }
  };

  const getMultipleCustomerVault = async (customerVaultIds) => {
    try {
      setPaymentLoader(true);

      if (customerVaultIds.length === 0) {
        setCardDetails([]);
        setPaymentLoader(false);
        return;
      }

      const response = await axiosInstance.post(
        `/nmipayment/get-billing-customer-vault`,
        {
          customer_vault_id: customerVaultIds,
          admin_id: accessType?.admin_id,
        }
      );

      const billingData = response.data.data.customer.billing;

      if (Array.isArray(billingData)) {
        const extractedData = await Promise.all(
          billingData.map((item) => handleSetCardDetails(item))
        );

        // Filter out any undefined or null values (which would be returned if card type is not accepted)
        const filteredData = extractedData.filter((item) => item);

        setCardDetails(filteredData);
      } else if (billingData) {
        // Handle single record
        const extractedData = await handleSetCardDetails(billingData);

        if (extractedData) {
          // Only set the value if it's not undefined
          setCardDetails([extractedData]);
        }
      } else {
        console.error(
          "Invalid response structure - customer.billing is not an array"
        );
        setCardDetails([]);
      }
    } catch (error) {
      console.error("Error fetching multiple customer vault records:", error);
    } finally {
      setPaymentLoader(false);
    }
  };

  const handleSetCardDetails = async (item) => {
    try {
      const cardType = await getCardType(item.cc_bin, item.cc_type);
      const response = await getRentalOwnerCardTypeSettings(tenantId, lease_id);
      const cardLogo = await getCardLogo(item.cc_type);
      const { creditCardAccepted, debitCardAccepted } = response.data;

      // Check if the card type is accepted
      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      )
        item.allowPayment = false;
      else item.allowPayment = true;

      // If the card type is accepted, return the card details object
      return {
        allowPayment: item.allowPayment,
        billing_id: item["@attributes"].id,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
        card_type: cardType,
        card_logo: cardLogo,
      };
    } catch (error) {
      console.error(error, "error in bin check");
      return {};
    }
  };

  useEffect(() => {
    getCreditCard();
  }, [tenantId]);

  useEffect(() => {
    // Extract customer_vault_id values from cardDetails
    const customerVaultIds =
      customervault?.card_detail?.map((card) => card.billing_id) || [];

    if (customerVaultIds.length > 0) {
      // Call the API to get multiple customer vault records
      getMultipleCustomerVault(customerVaultIds);
    }
  }, [customervault]);

  const [errorDisplay, setErrorDisplay] = useState(false);

  useEffect(() => {
    if (errorDisplay) {
      if (
        !generalledgerFormik.errors ||
        generalledgerFormik?.values.payments.length !== 0 ||
        selectedRec ||
        selectedPaymentMethod
      ) {
        setErrorDisplay(false);
      }
    }
  }, [generalledgerFormik?.values, selectedRec, selectedPaymentMethod]);

  const fetchPaymentData = async () => {
    if (payment_id) {
      try {
        const response = await axiosInstance.get(
          `/payment/payment/${payment_id}`
        );

        if (response.data.statusCode === 200) {
          const responseData = response.data.data[0];

          generalledgerFormik.setValues({
            ...generalledgerFormik.values,
            payment_id: responseData.payment_id,
            check_number: responseData.check_number,
            payment_type: responseData.payment_type,
            date: responseData.entry[0].date,
            total_amount: responseData.total_amount,
            payments_memo: responseData.entry[0].memo,
            customer_vault_id: responseData.customer_vault_id,
            billing_id: responseData.billing_id,
            transaction_id: responseData.transaction_id,
            surcharge: responseData.surcharge,
            payments: responseData.entry.map(
              (entry) =>
                ({
                  entry_id: entry.entry_id,
                  account: entry.account,
                  amount: entry.amount,
                  balance: entry.amount,
                  memo: responseData.memo,
                  charge_type: entry.charge_type,
                } || [
                  {
                    entry_id: "",
                    account: "",
                    amount: "",
                    balance: "",
                    charge_type: "",
                  },
                ])
            ),
            payments_attachment: responseData.payment_attachment,
          });
          setSelectedRec(
            `${response.data.data.tenant_data.tenant_firstName} ${response.data.data.tenant_data.tenant_lastName}`
          );
          setSelectedPaymentMethod(responseData.payment_type);
          setSelectedCreditCard(responseData.billing_id);
          setVaultId(responseData.customer_vault_id);
          setTenantId(responseData.tenant_id);
          getCreditCard();
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  // Calculate total amount after surcharge
  const calculateTotalAmount = () => {
    const amount = parseFloat(generalledgerFormik.values.total_amount) || 0;
    let totalAmount = amount;
    let surchargeAmount;

    // Check if the payment method is ACH
    if (selectedPaymentMethod === "ACH") {
      // Check if either surcharge_percent_ACH or surcharge_flat_ACH is present
      if (ACHSurchargePercentage && ACHflatfee) {
        surchargeAmount =
          parseFloat((amount * ACHSurchargePercentage) / 100) +
          parseFloat(ACHflatfee);
        totalAmount += surchargeAmount;
        generalledgerFormik.setFieldValue(
          "surcharge",
          surchargeAmount.toFixed(2)
        );
      } else if (ACHSurchargePercentage) {
        surchargeAmount = parseFloat((amount * ACHSurchargePercentage) / 100);
        totalAmount += surchargeAmount;
        generalledgerFormik.setFieldValue(
          "surcharge",
          surchargeAmount.toFixed(2)
        );
      } else if (ACHflatfee) {
        totalAmount += parseFloat(ACHflatfee);
      }
    } else if (selectedPaymentMethod === "Card") {
      // Calculate surcharge based on the selected card type (Credit or Debit)
      let percent =
        selectedBintype === "CREDIT"
          ? surchargePercentage
          : selectedBintype === "DEBIT"
          ? selectedTenant.enable_override_fee
            ? selectedTenant.override_fee
            : debitSurchargePercentage
          : 0;
      // Default to 0 if payment method is not ACH, Credit, or Debit

      surchargeAmount = (amount * percent) / 100;
      generalledgerFormik.setFieldValue(
        "surcharge",
        surchargeAmount.toFixed(2)
      );
      totalAmount += surchargeAmount;
    }

    setTotalAmount1(totalAmount);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [generalledgerFormik?.values, surchargePercentage, selectedPaymentMethod]);

  const editPayment = async (values) => {
    setLoader(true);
    const fileUrl = [];
    if (file) {
      try {
        const uploadPromises = file?.map(async (fileItem, i) => {
          if (fileItem.upload_file instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem.upload_file);

              const res = await axios.post(`${imageUrl}/images/upload`, form);
              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileUrl.push(res.data.files[0].filename);
                fileItem.upload_file = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem.upload_file);
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      payment_id: payment_id,
      company_name: admin,
      admin_id: accessType?.admin_id,
      tenant_id: tenantData?.tenant_id,
      lease_id: lease_id,
      billing_id: values.billing_id,
      customer_vault_id: values.customer_vault_id,
      check_number: values.check_number,

      entry: values.payments?.map((item) => {
        const data = {
          entry_id: item.entry_id,
          balance: Number(item.amount),
          account: item.account,
          amount: Number(item.amount),
          memo: values.payments_memo || "payment",
          date: values.date,
          charge_type: item.charge_type,
        };
        return data;
      }),

      total_amount: total,
      uploaded_file: fileUrl,
    };

    try {
      const res = await axiosInstance.put(
        `/payment/payment/${payment_id}`,
        object
      );
      if (res.data.statusCode === 200) {
        toast.success("Payment Updated Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          if (isFrom === "Financial") {
            navigate(`/${admin}/rentrolldetail/${lease_id}`, {
              state: { tab: "Financial" },
            });
          } else if (isFrom === "Tenant") {
            navigate(`/${admin}/rentrolldetail/${lease_id}`, {
              state: { tab: "Tenant" },
            });
          } else {
            navigate(`/${admin}/rentrolldetail/${lease_id}`);
          }
        }, 2000);
      } else {
        toast.warning(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error: ", error.message);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoader(true);

    const fileUrl = [];
    if (file) {
      try {
        const uploadPromises = file?.map(async (fileItem, i) => {
          if (fileItem.upload_file instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem.upload_file);

              const res = await axios.post(`${imageUrl}/images/upload`, form);
              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileUrl.push(res.data.files[0].filename);
                fileItem.upload_file = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            fileUrl.push(fileItem.upload_file);
          }
        });
        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }
    let nmiResponse;
    let ACHresponse;
    const financialDate = new Date(values.date);
    const currentDate = new Date();

    // Convert dates to UTC format strings
    const formatDateToUTCString = (date) => {
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // Months are zero-based, so add 1
      const day = date.getUTCDate();

      // Pad single-digit month and day with leading zeroes
      const formattedMonth = month < 10 ? "0" + month : month;
      const formattedDay = day < 10 ? "0" + day : day;

      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    const financialDateUTCString = formatDateToUTCString(financialDate);
    const currentDateUTCString = formatDateToUTCString(currentDate);

    try {
      if (selectedPaymentMethod === "Card") {
        // if (processorId) {
        const creditCardDetails = cardDetalis.find(
          (card) => card.billing_id === selectedCreditCard
        );

        if (creditCardDetails) {
          values.customer_vault_id = vaultId;
          values.billing_id = selectedCreditCard;
        } else {
          console.error(
            "Credit card details not found for selected card:",
            selectedCreditCard
          );
        }
        if (financialDateUTCString <= currentDateUTCString) {
          // First, make the call to baseUrl/nmipayment/sale
          const url = `/nmipayment/sale`;
          const postObject = {
            admin_id: accessType?.admin_id,
            first_name: selectedTenant.tenant_firstName,
            last_name: selectedTenant.tenant_lastName,
            email_name: selectedTenant.tenant_email,
            customer_vault_id: values.customer_vault_id,
            billing_id: values.billing_id,
            surcharge: values.surcharge,
            amount: values.total_amount,
            tenantId: tenantId,
            tenantName: selectedRec,
            date: values.date,
            address1: "",
            processor_id: processorId,
          };
          const response = await axiosInstance.post(url, {
            paymentDetails: postObject,
          });
          // Check response status and handle accordingly
          if (response.data && response.data.statusCode === 100) {
            nmiResponse = response.data.data;
          } else {
            swal({
              title: "Payment Failed!",
              text: `${response.data.message}`,
              icon: "warning",
              button: "OK",
            });
            console.error("Unexpected response format:", response.data.data);

            return; // Exit function early if there's an error
          }
        } else {
          const paymentObject = {
            company_name: admin,
            admin_id: accessType?.admin_id,
            tenant_id: tenantId,
            lease_id: lease_id,
            surcharge: values.surcharge,
            payment_type: selectedPaymentMethod,
            customer_vault_id: values.customer_vault_id,
            billing_id: values.billing_id,
            response: "PENDING",
            entry: values.payments?.map((item) => ({
              entry_id: item?.entry_id,
              account: item.account,
              balance: item.balance,
              amount: Number(item.amount),
              memo: values.payments_memo || "payment",
              date: values.date,
              charge_type: item.charge_type,
            })),
            total_amount: total,
            reference: values.reference,
            is_leaseAdded: false,
            uploaded_file: fileUrl,
          };
          const paymentResponse = await axiosInstance.post(
            `/payment/payment`,
            paymentObject
          );

          if (paymentResponse.data.statusCode === 200) {
            toast.success("Future Payment Scheduled", {
              position: "top-center",
              autoClose: 1000,
            });
            setTimeout(() => {
              if (addAnotherPayment) {
                generalledgerFormik.resetForm();
                setAddAnotherPayment(false);
                setIsEnable(false);
                setSelectedPaymentMethod("");
                fetchchargeData();
              } else {
                if (isFrom === "Financial") {
                  navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                    state: { tab: "Financial" },
                  });
                } else if (isFrom === "Tenant") {
                  navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                    state: { tab: "Tenant" },
                  });
                } else {
                  navigate(`/${admin}/rentrolldetail/${lease_id}`);
                }
              }
            }, 2000);
          } else {
            swal({
              title: "Payment Failed!",
              text: `${paymentResponse.data.message}`,
              icon: "warning",
              button: "OK",
            });
          }
        }
      } else if (
        selectedPaymentMethod === "Check" ||
        selectedPaymentMethod === "Cash" ||
        selectedPaymentMethod === "Manual" ||
        selectedPaymentMethod === "Money Order" ||
        selectedPaymentMethod === "Cashier's Check"
      ) {
        const paymentObject = {
          company_name: admin,
          admin_id: accessType?.admin_id,
          tenant_id: tenantId,
          lease_id: lease_id,
          check_number: values.check_number,
          payment_type: selectedPaymentMethod,
          entry: values.payments?.map((item) => ({
            entry_id: item?.entry_id,
            account: item.account,
            balance: item.balance,
            amount: Number(item.amount),
            memo: values.payments_memo || "payment",
            date: values.date,
            charge_type: item.charge_type,
          })),
          total_amount: total,
          reference: values.reference,
          is_leaseAdded: false,
          uploaded_file: fileUrl,
          response: "SUCCESS",
        };
        const paymentResponse = await axiosInstance.post(
          `/payment/payment`,
          paymentObject
        );

        if (paymentResponse.data.statusCode === 200) {
          toast.success("Payment Done Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            if (addAnotherPayment) {
              generalledgerFormik.resetForm();
              setAddAnotherPayment(false);
              setIsEnable(false);
              setSelectedPaymentMethod("");
              fetchchargeData();
            } else {
              if (isFrom === "Financial") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Financial" },
                });
              } else if (isFrom === "Tenant") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Tenant" },
                });
              } else {
                navigate(`/${admin}/rentrolldetail/${lease_id}`);
              }
            }
          }, 2000);
        } else {
          // toast.warning(paymentResponse.data.message, {
          //   position: "top-center",
          //   autoClose: 1000,
          // });
          swal({
            title: "Payment Failed!",
            text: `${paymentResponse.data.message}`,
            icon: "warning",
            button: "OK",
          });
        }
      } else if (selectedPaymentMethod === "ACH") {
        // if (processorId) {
        const paymentObject = {
          admin_id: accessType?.admin_id,
          first_name: selectedTenant.tenant_firstName,
          last_name: selectedTenant.tenant_lastName,
          email_name: selectedTenant.tenant_email,
          surcharge: values.surcharge,
          amount: values.total_amount,
          tenantId: tenantId,
          date: values.date,
          address1: "",
          checkname: values.checkname,
          checkaba: values.checkaba,
          checkaccount: values.checkaccount,
          account_holder_type: selectedACHAccount?.toLowerCase(),
          account_type: selectedAccountType?.toLowerCase(),
          is_leaseAdded: false,
          uploaded_file: fileUrl,
          response: "SUCCESS",
          processor_id: processorId,
          tenantName: selectedRec,
        };
        const paymentResponse = await axiosInstance.post(
          `/nmipayment/ACH_sale`,
          { paymentDetails: paymentObject }
        );

        if (paymentResponse.data && paymentResponse.data.statusCode === 100) {
          ACHresponse = paymentResponse.data.data;
        } else {
          swal({
            title: "Payment Failed!",
            text: `${paymentResponse.data.message}`,
            icon: "warning",
            button: "OK",
          });
          console.error(
            "Unexpected response format:",
            paymentResponse.data.data
          );
          return;
        }
      }

      if (ACHresponse) {
        const paymentObject = {
          company_name: admin,
          admin_id: accessType?.admin_id,
          tenant_id: tenantId,
          lease_id: lease_id,
          surcharge: values.surcharge,
          payment_type: selectedPaymentMethod,
          // customer_vault_id: values.customer_vault_id,
          // billing_id: values.billing_id,
          entry: values.payments?.map((item) => ({
            entry_id: item?.entry_id,
            account: item.account,
            balance: item.balance,
            amount: Number(item.amount),
            memo: values.payments_memo || "payment",
            date: values.date,
            charge_type: item.charge_type,
          })),
          total_amount: total,
          is_leaseAdded: false,
          uploaded_file: fileUrl,
          reference: values.reference,
          // Include relevant transaction details from the first API call
          transaction_id: ACHresponse.transactionid,
          response: ACHresponse.responsetext,
        };

        // Make the second API call to baseUrl/payment/payment
        const paymentResponse = await axiosInstance.post(
          `/payment/payment`,
          paymentObject
        );

        // Handle response from the second API call
        if (paymentResponse.data.statusCode === 200) {
          toast.success("Payment Done Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            if (addAnotherPayment) {
              generalledgerFormik.resetForm();
              setAddAnotherPayment(false);
              setIsEnable(false);
              setSelectedPaymentMethod("");
              fetchchargeData();
            } else {
              if (isFrom === "Financial") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Financial" },
                });
              } else if (isFrom === "Tenant") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Tenant" },
                });
              } else {
                navigate(`/${admin}/rentrolldetail/${lease_id}`);
              }
            }
          }, 2000);
        } else {
          // toast.warning(paymentResponse.data.message, {
          //   position: "top-center",
          //   autoClose: 1000,
          // });
          swal({
            title: "Payment Failed!",
            text: `${paymentResponse.data.message}`,
            icon: "warning",
            button: "OK",
          });
        }
      }

      if (nmiResponse) {
        const paymentObject = {
          company_name: admin,
          admin_id: accessType?.admin_id,
          tenant_id: tenantId,
          lease_id: lease_id,
          surcharge: values.surcharge,
          payment_type: selectedPaymentMethod,
          customer_vault_id: values.customer_vault_id,
          billing_id: values.billing_id,
          entry: values.payments?.map((item) => ({
            entry_id: item?.entry_id,
            account: item.account,
            balance: item.balance,
            amount: Number(item.amount),
            memo: values.payments_memo || "payment",
            date: values.date,
            charge_type: item.charge_type,
          })),
          total_amount: total,
          reference: values.reference,
          is_leaseAdded: false,
          uploaded_file: fileUrl,
          // Include relevant transaction details from the first API call
          transaction_id: nmiResponse.transactionid,
          response: nmiResponse.responsetext,
        };
        // Make the second API call to baseUrl/payment/payment
        const paymentResponse = await axiosInstance.post(
          `/payment/payment`,
          paymentObject
        );
        // Handle response from the second API call
        if (paymentResponse.data.statusCode === 200) {
          toast.success("Payment Done Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            if (addAnotherPayment) {
              generalledgerFormik.resetForm();
              setAddAnotherPayment(false);
              setIsEnable(false);
              setSelectedPaymentMethod("");
              fetchchargeData();
            } else {
              if (isFrom === "Financial") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Financial" },
                });
              } else if (isFrom === "Tenant") {
                navigate(`/${admin}/rentrolldetail/${lease_id}`, {
                  state: { tab: "Tenant" },
                });
              } else {
                navigate(`/${admin}/rentrolldetail/${lease_id}`);
              }
            }
          }, 2000);
        } else {
          // toast.warning(paymentResponse.data.message, {
          //   position: "top-center",
          //   autoClose: 1000,
          // });
          swal({
            title: "Payment Failed!",
            text: `${paymentResponse.data.message}`,
            icon: "warning",
            button: "OK",
          });
        }
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setLoader(false);
    }
  };

  const fetchchargeData = async () => {
    if (lease_id) {
      try {
        const response = await axiosInstance.get(
          `/charge/tenant_charges/${lease_id}/${tenantId}`
        );
        const data = response.data.totalCharges
          .map((item) => {
            const myData = item.entry
              .filter(
                (element) =>
                  element.due_amount > 0 && item.tenant_id === tenantId
              )
              .map((element) => {
                const items = {
                  entry_id: element.entry_id,
                  account: element.account,
                  balance: element.due_amount,
                  amount: 0,
                  charge_type: element.charge_type,
                  dropdownOpen: false,
                };
                return items;
              });
            return myData;
          })
          .flat();

        generalledgerFormik.setValues({
          payments: data || [
            {
              entry_id: "",
              account: "",
              amount: "",
              balance: "",
              charge_type: "",
            },
          ],
          payment_id: "",
          date: getCurrentDate(),
          total_amount: 0,
          payments_memo: "",
          payments_attachment: [],
        });
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  const [selectChargeDropDown, setSelectChargetDropDown] = useState(false);

  const toggles1 = () => setSelectChargetDropDown(!selectChargeDropDown);

  const [accountTypeName, setAccountTypeName] = useState("");

  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  const AddNewAccountName = async (accountName) => {
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  const [LiabilityAccounts, setLiabilityAccounts] = useState([]);
  const [recAccounts, setRecAccounts] = useState([]);
  const [oneTimeAccounts, setoneTimeAccounts] = useState([]);

  const fetchAccounts = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/accounts/accounts/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          const filteredData1 = res.data.data.filter(
            (item) => item.charge_type === "Liability Account"
          );
          const filteredData2 = res.data.data.filter(
            (item) => item.charge_type === "Recurring Charge"
          );
          const filteredData3 = res.data.data.filter(
            (item) => item.charge_type === "One Time Charge"
          );
          setLiabilityAccounts(filteredData1);
          setRecAccounts(filteredData2);
          setoneTimeAccounts(filteredData3);
        } else if (res.data.statusCode === 201) {
          setRecAccounts();
          setoneTimeAccounts();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const fetchTenant = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(`/leases/lease_tenant/${lease_id}`);
        if (res.data.statusCode === 200) {
          setTenantData(res.data.data.tenants);
          setProcessorId(res.data.data.processor_id);
          setRentalId(res.data.data.rental_id);
        }
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [accessType?.admin_id]);

  useEffect(() => {
    if (!payment_id) {
      fetchchargeData();
    } else {
      fetchPaymentData();
    }
    fetchTenant();
  }, [lease_id, payment_id, tenantId]);

  useEffect(() => {
    if (tenantData.length === 1) {
      handleRecieverSelection(tenantData[0]);
    }
  }, [tenantData]);

  const [total, setTotal] = useState(0);
  const handleTotal = () => {
    let sum = 0;
    generalledgerFormik.values.payments?.forEach((element) => {
      sum += Number(element.amount);
    });
    setTotal(sum);
  };

  useEffect(() => {
    handleTotal();
  }, [generalledgerFormik.values.payments]);

  const toggleDropdown = (index) => {
    const updatedCharges = [...generalledgerFormik.values.payments];
    updatedCharges[index].dropdownOpen = !updatedCharges[index].dropdownOpen;
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      payments: updatedCharges || [
        {
          entry_id: "",
          account: "",
          amount: "",
          balance: "",
          charge_type: "",
        },
      ],
    });
  };

  const handleAccountSelection = (value, index, chargeType) => {
    const updatedCharges = [...generalledgerFormik.values.payments];

    if (updatedCharges[index]) {
      updatedCharges[index].account = value;
      updatedCharges[index].charge_type = chargeType;
      generalledgerFormik.setValues({
        ...generalledgerFormik.values,
        payments: updatedCharges || [
          {
            entry_id: "",
            account: "",
            amount: "",
            balance: "",
            charge_type: "",
          },
        ],
      });
    }
  };

  const handleAddRow = () => {
    const newCharges = {
      entry_id: "",
      account: "",
      amount: "",
      balance: "",
      charge_type: "",
    };
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      payments: [...generalledgerFormik.values.payments, newCharges] || [
        newCharges,
      ],
    });
  };

  const handleRemoveRow = (index) => {
    const updatedCharges = [...generalledgerFormik.values.payments];
    updatedCharges.splice(index, 1);
    generalledgerFormik.setValues({
      ...generalledgerFormik.values,
      payments: updatedCharges || [
        {
          entry_id: "",
          account: "",
          amount: "",
          balance: "",
          charge_type: "",
        },
      ],
    });
  };

  const [file, setFile] = useState([]);
  const fileData = (files) => {
    const filesArray = [...files];
    if (filesArray.length <= 10 && file.length === 0) {
      const finalArray = [];
      for (let i = 0; i < filesArray.length; i++) {
        const object = {
          upload_file: filesArray[i],
          upload_date: moment().format("YYYY-MM-DD"),
          upload_time: moment().format("HH:mm:ss"),
          upload_by: accessType?.first_name + " " + accessType?.last_name,
          file_name: filesArray[i].name,
        };
        finalArray.push(object);
      }
      setFile([...finalArray]);
    } else if (
      file.length >= 0 &&
      file.length <= 10 &&
      filesArray.length + file.length > 10
    ) {
      setFile([...file]);
    } else {
      const finalArray = [];

      for (let i = 0; i < filesArray.length; i++) {
        const object = {
          upload_file: filesArray[i],
          upload_date: moment().format("YYYY-MM-DD"),
          upload_time: moment().format("HH:mm:ss"),
          upload_by: accessType?.first_name + " " + accessType?.last_name,
          file_name: filesArray[i].name,
        };
        finalArray.push(object);
      }
      setFile([...file, ...finalArray]);
    }
  };

  const deleteFile = (index) => {
    const newFile = [...file];
    newFile.splice(index, 1);
    setFile(newFile);
    generalledgerFormik.setFieldValue("charges_attachment", newFile);
  };

  const handleOpenFile = (item) => {
    if (typeof item !== "string") {
      const url = URL.createObjectURL(item?.upload_file);
      window.open(url, "_blank");
    } else {
      window.open(`${imageGetUrl}/${item}`, "_blank");
    }
  };

  const handleCloseButtonClick = () => {
    if (isFrom === "Financial") {
      navigate(`/${admin}/rentrolldetail/${lease_id}`, {
        state: { tab: "Financial" },
      });
    } else if (isFrom === "Tenant") {
      navigate(`/${admin}/rentrolldetail/${lease_id}`, {
        state: { tab: "Tenant" },
      });
    } else {
      navigate(`/${admin}/rentrolldetail/${lease_id}`);
    }
  };

  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const handleClose = () => {
    setOpenRecurringDialog(false);
  };

  const handleClickOpenRecurring = () => {
    setOpenRecurringDialog(true);
  };

  const handleAnotherPayment = (e) => {
    setIsEnable(e.target.checked);
    if (e.target.checked) {
      setAddAnotherPayment(true);
    } else {
      setAddAnotherPayment(false);
    }
  };

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row className="mb-4">
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {payment_id ? "Edit Payment" : "Make Payment"}
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className="mx-2 mb-3"
              style={{
                borderRadius: "20px",
                border: "1px solid #324567",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <CardBody>
                <Form>
                  {/* 6002 changing the design of the page all inputs  */}
                  <Row className="">
                    <Col lg="4" xl="3" md="5" sm="5" xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          First Select Received From *
                        </label>
                        <br />
                        <Dropdown
                          className="fontstylerentmodal d-flex"
                          isOpen={recdropdownOpen}
                          toggle={toggle2}
                          disabled={payment_id}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selectedRec ? selectedRec : "Select Resident"}
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "100%",
                              maxHeight: "200px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {tenantData.map((property, index) => (
                              <DropdownItem
                                className="fontstylerentmodal"
                                key={index}
                                onClick={() =>
                                  handleRecieverSelection(property)
                                }
                              >
                                {`${property.tenant_firstName} ${property.tenant_lastName}`}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <div style={{ color: "red" }}>
                          {errorDisplay && !selectedRec && "Required"}
                        </div>
                      </FormGroup>
                    </Col>
                    {/* </Row>

                  <Row className="mt-2"> */}
                    <Col lg="4" xl="3" md="5" sm="5" xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr datesrentrolldetail titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Date *
                        </label>
                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          className="form-control-alternative fontstylerentmodal titleecolor"
                          id="input-unitadd"
                          placeholder="3000"
                          type="date"
                          name="date"
                          onBlur={generalledgerFormik.handleBlur}
                          onChange={generalledgerFormik.handleChange}
                          value={generalledgerFormik.values.date}
                        />
                        {generalledgerFormik.errors &&
                        generalledgerFormik.errors?.date &&
                        generalledgerFormik.touched &&
                        generalledgerFormik.touched?.date ? (
                          <div
                            className="requiredstylefont"
                            style={{ color: "red" }}
                          >
                            {generalledgerFormik.errors.date}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* </Row>

                  <Row className="mt-2"> */}
                    <Col lg="4" xl="3" md="5" sm="5" xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-property"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Amount *
                        </label>
                        <Input
                          className="fontstylerentmodal titleecolor"
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "100%",
                          }}
                          type="text"
                          id="amount"
                          placeholder="Enter amount"
                          name="total_amount"
                          onBlur={generalledgerFormik.handleBlur}
                          onChange={(e) => {
                            if (/^\d*\.?\d*$/.test(e.target.value)) {
                              generalledgerFormik.handleChange(e);
                            }
                          }}
                          value={generalledgerFormik.values.total_amount}
                          readOnly={payment_id}
                        />
                        {generalledgerFormik.errors &&
                        generalledgerFormik.errors?.total_amount &&
                        generalledgerFormik.touched &&
                        generalledgerFormik.touched?.total_amount ? (
                          <div
                            className="requiredstylefont"
                            style={{ color: "red" }}
                          >
                            {generalledgerFormik.errors.total_amount}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="4" xl="3" md="5" sm="5" xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-property"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Payment Method
                        </label>
                        <br />
                        <Dropdown
                          className="fontstylerentmodal d-flex"
                          isOpen={typedropdownOpen}
                          toggle={toggle5}
                          disabled={payment_id}
                        >
                          <DropdownToggle
                            className="fontstylerentmodal titleecolor"
                            caret
                            style={{
                              width: "100%",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                              border: "1px solid #ced4da",
                              backgroundColor: "transparent",
                            }}
                          >
                            {selectedPaymentMethod
                              ? selectedPaymentMethod
                              : "Select Method"}
                          </DropdownToggle>
                          <DropdownMenu
                            className="fontstylerentmodal"
                            style={{
                              width: "100%",
                              maxHeight: "200px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            {currentPlan !== "Free Plan" && (
                              <>
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() => {
                                    setSelectedPaymentMethod("Card");
                                    generalledgerFormik.setFieldValue(
                                      "payment_type",
                                      "Card"
                                    );
                                  }}
                                >
                                  Card
                                </DropdownItem>
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() => {
                                    setSelectedPaymentMethod("ACH");
                                    generalledgerFormik.setFieldValue(
                                      "payment_type",
                                      "ACH"
                                    );
                                  }}
                                >
                                  ACH
                                </DropdownItem>
                              </>
                            )}
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => {
                                setSelectedPaymentMethod("Check");
                                generalledgerFormik.setFieldValue(
                                  "payment_type",
                                  "Check"
                                );
                              }}
                            >
                              Check
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => {
                                setSelectedPaymentMethod("Cash");
                                generalledgerFormik.setFieldValue(
                                  "payment_type",
                                  "Cash"
                                );
                              }}
                            >
                              Cash
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => {
                                setSelectedPaymentMethod("Cashier's Check");
                                generalledgerFormik.setFieldValue(
                                  "payment_type",
                                  "Cashier's Check"
                                );
                              }}
                            >
                              Cashier's Check
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => {
                                setSelectedPaymentMethod("Money Order");
                                generalledgerFormik.setFieldValue(
                                  "payment_type",
                                  "Money Order"
                                );
                              }}
                            >
                              Money Order
                            </DropdownItem>
                            <DropdownItem
                              className="fontstylerentmodal"
                              onClick={() => {
                                setSelectedPaymentMethod("Manual");
                                generalledgerFormik.setFieldValue(
                                  "payment_type",
                                  "Manual"
                                );
                              }}
                            >
                              Manual
                            </DropdownItem>
                          </DropdownMenu>
                          {generalledgerFormik.errors.payment_type &&
                          generalledgerFormik.touched.payment_type ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {generalledgerFormik.errors.payment_type}
                            </div>
                          ) : null}
                        </Dropdown>
                      </FormGroup>
                      <span style={{ color: "red" }}>
                        {errorDisplay && !selectedPaymentMethod && "Required"}
                      </span>
                    </Col>

                    <Col lg="4" xl="3" md="5" sm="5" xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Memo
                        </label>
                        <Input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                            width: "100%",
                          }}
                          className="form-control-alternative fontstylerentmodal titleecolor"
                          id="input-unitadd"
                          placeholder="If left blank, will show 'Payment'"
                          type="text"
                          name="memo"
                        />
                      </FormGroup>
                    </Col>
                    {selectedPaymentMethod ? (
                      // <Row className="mt-3">
                      <Col lg="4" xl="3" md="5" sm="5" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-property"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Reference
                          </label>
                          <Input
                            className="fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "100%",
                            }}
                            type="text"
                            id="reference"
                            placeholder="Enter Reference"
                            name="reference"
                            onBlur={generalledgerFormik.handleBlur}
                            onChange={generalledgerFormik.handleChange}
                            value={generalledgerFormik.values.reference}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                  <Col sm="12">
                    <Row>
                      {!payment_id && selectedPaymentMethod === "Card" ? (
                        <>
                          {/* {refund === false ? ( */}
                          <Card
                            className="w-100 mt-3 "
                            style={{ background: "#F4F6FF" }}
                          >
                            <CardContent>
                              {/* Card Details */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  className="fontstylerentr titleecolor fontfamilysty"
                                  sx={{
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                    marginRight: "10px",
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Cards
                                </Typography>
                              </div>
                              {cardDetalis && cardDetalis.length > 0 && (
                                <Table responsive>
                                  <tbody>
                                    <tr>
                                      <th className="fontstylerentmodal">
                                        Select
                                      </th>
                                      <th className="fontstylerentmodal">
                                        Card Number
                                      </th>
                                      <th className="fontstylerentmodal">
                                        Card Type
                                      </th>
                                      <th></th>
                                    </tr>
                                    {cardDetalis.map((item, index) => (
                                      <tr
                                        key={index}
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <td>
                                          <Tooltip
                                            arrow
                                            title={
                                              item.allowPayment
                                                ? ""
                                                : `${item.card_type} card types not accepted by rental owner`
                                            }
                                          >
                                            <span
                                              style={{
                                                opacity: item.allowPayment
                                                  ? "1"
                                                  : "0.7",
                                              }}
                                            >
                                              <input
                                                disabled={!item.allowPayment}
                                                id="checked"
                                                type="checkbox"
                                                checked={
                                                  selectedCreditCard ==
                                                  item.billing_id
                                                }
                                                onChange={() => {
                                                  handleCreditCardSelection(
                                                    item
                                                  );
                                                  setSelectedBintype(
                                                    item.card_type
                                                  );
                                                }}
                                              />
                                            </span>
                                          </Tooltip>
                                        </td>
                                        <td>
                                          <Typography
                                            className="fontstylerentmodal"
                                            sx={{
                                              fontSize: 14,
                                              fontWeight: "bold",
                                              fontStyle: "italic",
                                              fontFamily: "Arial",
                                              textTransform: "capitalize",
                                              marginRight: "10px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                          >
                                            {item.cc_number}
                                          </Typography>
                                        </td>
                                        <td>
                                          <Typography
                                            className="fontstylerentmodal"
                                            sx={{
                                              fontSize: 14,
                                              marginRight: "10px",
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                          >
                                            {item.card_type}
                                            {item.card_logo && (
                                              <img
                                                src={item.card_logo}
                                                style={{
                                                  width: "30px",
                                                  height: "fit-content",
                                                }}
                                              />
                                            )}
                                          </Typography>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              )}
                              {selectedCreditCard !== null &&
                                (selectedBintype === "CREDIT" ? (
                                  <label
                                    className="form-control-label fontstylerentmodal textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                  >
                                    Credit card transactions will charge{" "}
                                    <strong style={{ color: "blue" }}>
                                      {surchargePercentage}%
                                    </strong>
                                  </label>
                                ) : (
                                  <label
                                    className="form-control-label fontstylerentmodal textcolorblue fontfamilysty"
                                    htmlFor="input-property"
                                  >
                                    Debit card transactions will charge{" "}
                                    <strong style={{ color: "blue" }}>
                                      {selectedTenant.enable_override_fee
                                        ? selectedTenant.override_fee
                                        : debitSurchargePercentage}
                                      %
                                    </strong>
                                  </label>
                                ))}

                              {/* Add Credit Card Button */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "10px",
                                }}
                              >
                                {scriptGenerating ? (
                                  <p>NMI fields loading...</p>
                                ) : scriptError ? (
                                  <p style={{ color: "red" }}>{scriptError}</p>
                                ) : (
                                  <div>
                                    <AddTenantCreditCardByAdmin
                                      preFillData={preFillData}
                                      tenantId={tenantId}
                                      closeModal={closeModal}
                                      lease_id={lease_id}
                                      admin_id={accessType?.admin_id}
                                      onHide={() => setModalShow(false)}
                                      show={modalShow}
                                      getCreditCard={getCreditCard}
                                    />
                                    <Button
                                      className="fontstylerentmodal background-colorsty bgtextwhite"
                                      onClick={() => setModalShow(true)}
                                      style={{
                                        marginRight: "10px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Add Card
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </CardContent>
                          </Card>
                        </>
                      ) : !payment_id &&
                        (selectedPaymentMethod === "Check" ||
                          selectedPaymentMethod === "Money Order" ||
                          selectedPaymentMethod === `Cashier's Check`) ? (
                        <>
                          {/* <Row className="mt-3"> */}
                          <div className="ml-3 w-100">
                            <Row className="mt-3">
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Check Number *
                                  </label>
                                  <Input
                                    className="fontstylerentmodal titleecolor"
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                      width: "70%",
                                    }}
                                    type="text"
                                    id="check_number"
                                    placeholder="Enter Check Number"
                                    name="check_number"
                                    onBlur={generalledgerFormik.handleBlur}
                                    onChange={generalledgerFormik.handleChange}
                                    value={
                                      generalledgerFormik.values.check_number
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          {/* </Row> */}
                        </>
                      ) : !payment_id && selectedPaymentMethod === `Manual` ? (
                        <>
                          {/* <Row className="mt-3"> */}
                          <div className="ml-3 w-100">
                            <Row className="mt-3">
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Check Number
                                  </label>
                                  <Input
                                    className="fontstylerentmodal titleecolor"
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                      width: "70%",
                                    }}
                                    type="text"
                                    id="check_number"
                                    placeholder="Enter Check Number"
                                    name="check_number"
                                    onBlur={generalledgerFormik.handleBlur}
                                    onChange={generalledgerFormik.handleChange}
                                    value={
                                      generalledgerFormik.values.check_number
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          {/* </Row> */}
                        </>
                      ) : !payment_id && selectedPaymentMethod === "ACH" ? (
                        <>
                          <div className="ml-3 w-100">
                            <Row className="mt-3">
                              <Col lg="3">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Bank Routing Number *
                                  </label>
                                  <Input
                                    className="fontstylerentmodal titleecolor"
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                      width: "80%",
                                    }}
                                    type="text"
                                    id="checkaba"
                                    placeholder="Enter Routing Number"
                                    name="checkaba"
                                    onBlur={generalledgerFormik.handleBlur}
                                    onChange={generalledgerFormik.handleChange}
                                    value={generalledgerFormik.values.checkaba}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup className="addpaymenttop">
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Bank Account Number *
                                  </label>
                                  <Input
                                    className="fontstylerentmodal titleecolor"
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                      width: "80%",
                                    }}
                                    type="text"
                                    id="checkaccount"
                                    placeholder="Enter Account Number"
                                    name="checkaccount"
                                    onBlur={generalledgerFormik.handleBlur}
                                    onChange={generalledgerFormik.handleChange}
                                    value={
                                      generalledgerFormik.values.checkaccount
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup className="addpaymenttop">
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Account Type *
                                  </label>
                                  <br />
                                  <Dropdown
                                    className="fontstylerentmodal d-flex"
                                    isOpen={accountdropdownOpen}
                                    toggle={toggle3}
                                    disabled={payment_id}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        width: "100%",
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",

                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selectedAccountType
                                        ? selectedAccountType
                                        : "Select Account"}
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          setSelectedAccountType("Checking")
                                        }
                                      >
                                        Checking
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          setSelectedAccountType("Savings")
                                        }
                                      >
                                        Savings
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg="4" xl="3" md="5" sm="5" xs="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Name of the ACH account *
                                  </label>
                                  <Input
                                    className="fontstylerentmodal"
                                    style={{
                                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                                      borderRadius: "6px",
                                    }}
                                    type="text"
                                    id="checkname"
                                    placeholder="Enter Account Name"
                                    name="checkname"
                                    onBlur={generalledgerFormik.handleBlur}
                                    onChange={generalledgerFormik.handleChange}
                                    value={generalledgerFormik.values.checkname}
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4" xl="3" md="5" sm="5" xs="12">
                                <FormGroup className="addpaymenttop">
                                  <label
                                    className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                    htmlFor="input-property"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Account Holder Type *
                                  </label>
                                  <br />
                                  <Dropdown
                                    className="fontstylerentmodal d-flex"
                                    isOpen={achdropdownOpen}
                                    toggle={toggle4}
                                    disabled={payment_id}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        width: "100%",
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",

                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selectedACHAccount
                                        ? selectedACHAccount
                                        : "Select Account Holder Type"}
                                    </DropdownToggle>
                                    <DropdownMenu
                                      className="fontstylerentmodal"
                                      style={{
                                        width: "100%",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                      }}
                                    >
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          setSelectedACHAccount("Business")
                                        }
                                      >
                                        Business
                                      </DropdownItem>
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        onClick={() =>
                                          setSelectedACHAccount("Personal")
                                        }
                                      >
                                        Personal
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : null}
                    </Row>
                  </Col>

                  {/* 6002 end */}
                  <Row className="mt-3">
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                          htmlFor="input-unitadd"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Apply Payment to Balances
                        </label>
                        <div className="table-responsive mt-3">
                          <Table
                            className="table table-bordered"
                            style={{
                              borderCollapse: "collapse",
                              // border: "1px solid #ddd",
                              border: "1px solid #152B51",
                              overflow: "hidden",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                            }}
                          >
                            <thead>
                              <tr
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  border: "1px solid #152B51",
                                }}
                              >
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Account
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  {" "}
                                  Amount
                                </th>
                                {!payment_id && (
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    Balance
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {generalledgerFormik.values.payments?.map(
                                  (payments, index) => (
                                    <tr
                                      key={index}
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <td
                                        style={{
                                          border: "1px solid #152B51",
                                        }}
                                      >
                                        <Dropdown
                                          className="fontstylerentmodal"
                                          isOpen={payments.dropdownOpen}
                                          toggle={() => toggleDropdown(index)}
                                        >
                                          <DropdownToggle
                                            className="fontstylerentmodal titleecolor"
                                            caret
                                            style={{
                                              width: "100%",
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040",
                                              border: "1px solid #ced4da",
                                              backgroundColor: "transparent",
                                            }}
                                          >
                                            {payments.account
                                              ? payments.account
                                              : "Select"}
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className="fontstylerentmodal"
                                            style={{
                                              zIndex: 999,
                                              maxHeight: "200px",
                                              overflowY: "auto",
                                            }}
                                          >
                                            <DropdownItem
                                              className="dropdownfontsyle"
                                              onClick={handleClickOpenRecurring}
                                              style={{
                                                backgroundColor: "#152B51",
                                                color: "#fff",
                                              }}
                                            >
                                              Add new account
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              header
                                              style={{
                                                color: "#152B51",
                                                fontFamily: "Poppins",
                                                fontSize: "12px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Liability Account
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Rent Income",
                                                  index,
                                                  "Liability Account"
                                                );
                                              }}
                                            >
                                              Rent Income
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Late Fee Income",
                                                  index,
                                                  "Liability Account"
                                                );
                                              }}
                                            >
                                              Late Fee Income
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Pre-payments",
                                                  index,
                                                  "Liability Account"
                                                );
                                              }}
                                            >
                                              Pre-payments
                                            </DropdownItem>
                                            <DropdownItem
                                              className="fontstylerentmodal"
                                              onClick={() => {
                                                handleAccountSelection(
                                                  "Security Deposit",
                                                  index,
                                                  "Liability Account"
                                                );
                                              }}
                                            >
                                              Security Deposit
                                            </DropdownItem>
                                            {LiabilityAccounts?.length > 0 ? (
                                              <>
                                                {LiabilityAccounts?.map(
                                                  (item) => (
                                                    <DropdownItem
                                                      key={item._id}
                                                      onClick={() => {
                                                        handleAccountSelection(
                                                          item.account,
                                                          index,
                                                          "Liability Account"
                                                        );
                                                      }}
                                                    >
                                                      {item.account}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {recAccounts?.length > 0 ? (
                                              <>
                                                <DropdownItem
                                                  className="fontstylerentmodal"
                                                  header
                                                  style={{
                                                    color: "#152B51",
                                                    fontFamily: "Poppins",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  Reccuring Charges
                                                </DropdownItem>
                                                {recAccounts?.map((item) => (
                                                  <DropdownItem
                                                    className="fontstylerentmodal"
                                                    key={item._id}
                                                    onClick={() => {
                                                      handleAccountSelection(
                                                        item.account,
                                                        index,
                                                        "Recurring Charge"
                                                      );
                                                    }}
                                                  >
                                                    {item.account}
                                                  </DropdownItem>
                                                ))}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {oneTimeAccounts?.length > 0 ? (
                                              <>
                                                <DropdownItem
                                                  className="fontstylerentmodal"
                                                  header
                                                  style={{
                                                    color: "#152B51",
                                                    fontFamily: "Poppins",
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                  }}
                                                >
                                                  One Time Charges
                                                </DropdownItem>
                                                {oneTimeAccounts?.map(
                                                  (item) => (
                                                    <DropdownItem
                                                      className="fontstylerentmodal"
                                                      key={item._id}
                                                      onClick={() => {
                                                        handleAccountSelection(
                                                          item.account,
                                                          index,
                                                          "One Time Charge"
                                                        );
                                                      }}
                                                    >
                                                      {item.account}
                                                    </DropdownItem>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </DropdownMenu>
                                          {generalledgerFormik.touched
                                            .payments &&
                                          generalledgerFormik.touched.payments[
                                            index
                                          ] &&
                                          generalledgerFormik.errors.payments &&
                                          generalledgerFormik.errors.payments[
                                            index
                                          ] &&
                                          generalledgerFormik.errors.payments[
                                            index
                                          ].account ? (
                                            <div
                                              className="requiredstylefont"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                generalledgerFormik.errors
                                                  .payments[index].account
                                              }
                                            </div>
                                          ) : null}
                                        </Dropdown>
                                      </td>
                                      <Dialog
                                        open={openRecurringDialog}
                                        onClose={handleClose}
                                      >
                                        <DialogTitle
                                          className="labelfontstyle textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Select Account Type
                                        </DialogTitle>

                                        <div
                                          className="pl-lg-3 modalpaddingrent"
                                          style={{
                                            height: "190px",
                                            width: "380px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginLeft: "4%",
                                              marginRight: "4%",
                                            }}
                                          >
                                            <br />
                                            <div
                                              className="formInput"
                                              // style={{ margin: "30px 10px" }}
                                            >
                                              <label
                                                className="form-control-label titleecolor fontstylerentr fontfamilystyfontfamilysty"
                                                htmlFor="input-address"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Select Account Type
                                              </label>
                                              <br />
                                              <Dropdown
                                                className="dropdownfontsyle"
                                                isOpen={selectChargeDropDown}
                                                toggle={toggles1}
                                              >
                                                <DropdownToggle
                                                  className="dropdownfontsyle titleecolor"
                                                  caret
                                                  style={{
                                                    width: "100%",
                                                    boxShadow:
                                                      " 0px 4px 4px 0px #00000040",
                                                    border: "1px solid #ced4da",
                                                    backgroundColor:
                                                      "transparent",
                                                  }}
                                                >
                                                  Select
                                                </DropdownToggle>
                                                <DropdownMenu
                                                  className="dropdownfontsyle"
                                                  style={{ width: "100%" }}
                                                  name="rent_cycle"
                                                >
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "Liability Account"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    Liability Account
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "Recurring Charge"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    Recurring Charge
                                                  </DropdownItem>
                                                  <DropdownItem
                                                    className="dropdownfontsyle"
                                                    onClick={() => {
                                                      AddNewAccountName(
                                                        "One Time Charge"
                                                      );
                                                      handleClose();
                                                    }}
                                                  >
                                                    One Time Charge
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-end">
                                              <Button
                                                className="fontstylerentr mt-4 background-colorsty bgtextwhite fontfamilysty"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={handleClose}
                                              >
                                                Cancel
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </Dialog>
                                      <AccountDialog
                                        addBankAccountDialogOpen={
                                          addBankAccountDialogOpen
                                        }
                                        setAddBankAccountDialogOpen={
                                          setAddBankAccountDialogOpen
                                        }
                                        accountTypeName={accountTypeName}
                                        adminId={accessType?.admin_id}
                                        fetchAccounts={fetchAccounts}
                                      />
                                      <td
                                        style={{
                                          border: "1px solid #152B51",
                                        }}
                                      >
                                        <Input
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-unitadd"
                                          placeholder="$0.00"
                                          style={{
                                            width: "80%",
                                            fontWeight: "bold",
                                          }}
                                          type="text"
                                          name={`payments[${index}].amount`}
                                          onBlur={
                                            generalledgerFormik.handleBlur
                                          }
                                          onChange={(e) => {
                                            if (!payments?.entry_id) {
                                              payments.balance = e.target.value;
                                            }
                                            if (
                                              /^\d*\.?\d*$/.test(e.target.value)
                                            ) {
                                              generalledgerFormik.handleChange(
                                                e
                                              );
                                            }
                                          }}
                                          onInput={(e) => {
                                            const inputValue = e.target.value;
                                            // const numericValue =
                                            //   inputValue.replace(/\D/g, "");
                                            // e.target.value = numericValue;
                                          }}
                                          value={payments.amount}
                                        />
                                        {generalledgerFormik.touched.payments &&
                                        generalledgerFormik.touched.payments[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.payments &&
                                        generalledgerFormik.errors.payments[
                                          index
                                        ] &&
                                        generalledgerFormik.errors.payments[
                                          index
                                        ].amount ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {
                                              generalledgerFormik.errors
                                                .payments[index].amount
                                            }
                                          </div>
                                        ) : null}
                                      </td>
                                      {!payment_id && (
                                        <td
                                          style={{
                                            border: "1px solid #152B51",
                                          }}
                                        >
                                          <Input
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-unitadd"
                                            placeholder="$0.00"
                                            style={{ fontWeight: "bold" }}
                                            type="number"
                                            name={`payments[${index}].balance`}
                                            onBlur={
                                              generalledgerFormik.handleBlur
                                            }
                                            onChange={
                                              generalledgerFormik.handleChange
                                            }
                                            value={
                                              payments?.entry_id
                                                ? Number(
                                                    payments.balance -
                                                      payments.amount
                                                  ).toFixed(2)
                                                : Number(
                                                    payments?.balance
                                                  ).toFixed(2)
                                            }
                                            readOnly
                                          />
                                        </td>
                                      )}
                                      <td
                                        style={{
                                          border: "1px solid #152B51",
                                        }}
                                      >
                                        <ClearIcon
                                          className="addpaymenticonremove"
                                          type="button"
                                          style={{
                                            cursor: "pointer",
                                            padding: 0,
                                          }}
                                          onClick={() => handleRemoveRow(index)}
                                        >
                                          Remove
                                        </ClearIcon>
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  <th
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    Total
                                  </th>
                                  <th
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    {total.toFixed(2)}
                                  </th>
                                </tr>
                                {/* {{5757-start}} */}
                                {typeof generalledgerFormik?.errors
                                  ?.payments === "string" &&
                                generalledgerFormik?.errors?.payments ? (
                                  <tr
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <th
                                      className="fontstylerentmodal textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        border: "1px solid #152B51",
                                      }}
                                      colSpan={2}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                      >
                                        {generalledgerFormik.errors.payments}
                                      </span>
                                    </th>
                                  </tr>
                                ) : // {{575-end}}
                                Number(
                                    generalledgerFormik.values.total_amount || 0
                                  ).toFixed(2) !== Number(total).toFixed(2) ? (
                                  <tr
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <th
                                      className="fontstylerentmodal textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        border: "1px solid #152B51",
                                      }}
                                      colSpan={2}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                      >
                                        The payment's amount must match the
                                        total applied to balance. The difference
                                        is $
                                        {Math.abs(
                                          generalledgerFormik.values
                                            .total_amount - total
                                        ).toFixed(2)}
                                      </span>
                                    </th>
                                  </tr>
                                ) : null}
                              </>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  <span style={{ color: "red" }}>
                                    {errorDisplay &&
                                      generalledgerFormik.values.payments
                                        .length === 0 &&
                                      "Add atlease one row"}
                                  </span>
                                  <Button
                                    type="button"
                                    className="btn fontstylerentmodal backgroundwhitesty textcolorblue fontfamilysty"
                                    onClick={handleAddRow}
                                    style={{
                                      border: "0.5px solid #152B51",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {!payment_id && (
                    <Row className="mt-3">
                      <Col lg="4" sm="12" md="12" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Upload Files (Maximum of 10)
                          </label>

                          <div className="d-flex">
                            <div className="file-upload-wrapper">
                              <input
                                type="file"
                                className="form-control-file d-none"
                                accept="file/*"
                                name="upload_file"
                                id="upload_file"
                                multiple
                                onChange={(e) => fileData(e.target.files)}
                              />
                              <label
                                htmlFor="upload_file"
                                className="btn fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                }}
                              >
                                Choose Files...
                              </label>
                            </div>

                            <div className="d-flex addingfileaddpay">
                              {file?.length > 0 &&
                                file?.map((file, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      position: "relative",
                                      marginLeft: "50px",
                                    }}
                                  >
                                    <p
                                      className="fontstylerentr"
                                      onClick={() =>
                                        handleOpenFile(file ? file : file)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {file && typeof file !== "string"
                                        ? file?.file_name?.substr(0, 5)
                                        : file.substr(0, 5)}
                                      {file && typeof file !== "string"
                                        ? file?.file_name?.length > 5
                                          ? "..."
                                          : null
                                        : file?.length > 5
                                        ? "..."
                                        : null}
                                    </p>
                                    <CloseIcon
                                      className="cleariconaddpayment"
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        left: "64px",
                                        top: "-2px",
                                      }}
                                      onClick={() => deleteFile(index)}
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {(selectedPaymentMethod === "Card" ||
                    selectedPaymentMethod === "ACH") &&
                    !payment_id && (
                      <>
                        <Row
                          className="mx-0 addtotalstyle"
                          style={{
                            backgroundColor: "#e0e0e0",
                            borderRadius: "6px",
                            maxWidth: "30%",
                            border: "0.5px solid #152B51",
                            boxShadow: "0px 4px 4px 0px #00000040",
                          }}
                        >
                          <Col
                            className="fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              padding: "12px",
                              fontSize: "14px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              fontWeight: "500",
                              width: "60%",
                            }}
                          >
                            Amount
                          </Col>
                          <Col
                            className="d-flex justify-content-end"
                            style={{
                              padding: "12px",
                              // borderBottom: "1px solid #bdbdbd",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            <strong
                              className="fontstylerentr textcolorblue fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              ${generalledgerFormik.values.total_amount || 0.0}
                            </strong>
                          </Col>
                        </Row>
                        <Row
                          className="mx-0 addtotalstyle"
                          style={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "6px",
                            maxWidth: "30%",
                            border: "0.5px solid #152B51",
                            boxShadow: "0px 4px 4px 0px #00000040",
                          }}
                        >
                          <Col
                            className="fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              padding: "12px",
                              fontSize: "14px",
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              fontWeight: "500",
                            }}
                          >
                            Surcharge included
                          </Col>
                          <Col
                            className="d-flex justify-content-end "
                            style={{
                              padding: "12px",
                              borderBottom: "1px solid #bdbdbd",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            <strong
                              className="fontstylerentr textcolorblue fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              ${generalledgerFormik.values.surcharge || 0.0}
                            </strong>
                          </Col>
                        </Row>
                      </>
                    )}
                  <Row
                    className="mx-0 addtotalstyle"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "6px",
                      maxWidth: "30%",
                      border: "0.5px solid #152B51",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      marginTop: payment_id && "25px",
                    }}
                  >
                    <Col
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        padding: "12px",
                        fontSize: "14px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Total Amount
                    </Col>
                    <Col
                      className="d-flex justify-content-end"
                      style={{
                        padding: "12px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <strong
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $
                        {Number(totalAmount1).toFixed(2) ||
                          generalledgerFormik.values.total_amount ||
                          0.0}
                      </strong>
                    </Col>
                  </Row>
                  <br />
                  <span style={{ color: "red" }}>
                    {errorDisplay && "Please fill all required fields"}
                  </span>
                  <Row className="mt-3">
                    <Col lg="5">
                      <FormGroup>
                        {loader ? (
                          <button
                            type="submit"
                            className="btn fontstylerentr background-colorsty "
                            style={{
                              cursor: "not-allowed",
                            }}
                            disabled
                          >
                            Loading...
                          </button>
                        ) : payment_id ? (
                          <button
                            type="submit"
                            className="btn fontstylerentr background-colorsty bgtextwhite"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              editPayment(generalledgerFormik.values);
                            }}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn fontstylerentr background-colorsty bgtextwhite"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                generalledgerFormik.values.payments.length ===
                                  0 ||
                                !selectedRec ||
                                !selectedPaymentMethod
                              ) {
                                setErrorDisplay(true);
                              } else {
                                setErrorDisplay(false);
                                generalledgerFormik.handleSubmit();
                              }
                            }}
                          >
                            Make Payment
                          </button>
                        )}

                        <Button
                          // color="primary"
                          className="btn fontstylerentr cancelbtnaddpay backgroundwhitesty textcolorblue"
                          onClick={handleCloseButtonClick}
                        >
                          Cancel
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  {!payment_id && (
                    <Row className="mt-2">
                      <Label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="override-enable"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        <Checkbox
                          id="override-enable"
                          className="checkboxx"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                            "&.Mui-checked": {
                              color: "#152B51",
                            },
                          }}
                          checked={isEnable === true}
                          onChange={handleAnotherPayment}
                        />
                        Make Another Payment
                      </Label>
                    </Row>
                  )}
                </Form>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default AddPayment;
