import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from "reactstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Box, Tooltip } from "@mui/material";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "axiosInstance";
import { useCookies } from "react-cookie";
import { verifyToken } from "components/Functions/Functions";
import { Info } from "@mui/icons-material";
import swal from "sweetalert";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { strongPassword } from "plugins/helpers";

const TenantPassChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  const passwordValidation = yup.string()
  .required("Password is required.")
  .test("strong-password", "Invalid password", (value, context) => {
    const result = strongPassword(value);
    return result === true ? true : context.createError({ message: result });
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      inputPassword4: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      currentPassword: yup
        .string()
        .trim()
        .required("Please enter current password"),
      inputPassword4: passwordValidation,
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("inputPassword4"), null], "Passwords do not match"),
    }),
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const handleChangePassword = async (values) => {
    setIsLoading(true);
    setError("");
    try {
      const response = await axiosInstance.put(
        `/tenant/reset_password/${accessType?.tenant_email}`,
        { currentPassword: values.currentPassword,
          password: values.inputPassword4,
        }
      );

      if (response.status === 200) {
        toast.success("Password Changed Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => navigate(`/tenant/tenantdashboard`), 2000);
      } else if (response.status === 203) {
        swal({
          text: response.data.message,
          icon: "warning",
        });
      } else {
        setError(response.data.message);
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred while changing the password");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Row>
          <div className="col">
            <CardHeader
              className="mt-3 mb-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="mb-0 headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Change Password
              </h2>
            </CardHeader>
          </div>
        </Row>
        <Form
          role="form"
          onSubmit={formik.handleSubmit}
          className="ml-3"
          // style={{ width: "30%" }}
        >
          <Row>
            <Col xl="4" lg="6" md="6" sm="6">
              {/* Current Password Field */}
              <FormGroup className="mt-3 mb-4">
                <label
                  className="m-0"
                  htmlFor="currentPassword"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  Current Password
                </label>
                <InputGroup
                  className="input-group-alternative"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Current Password"
                    id="currentPassword"
                    name="currentPassword"
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.currentPassword}
                  />
                  <IconButton
                    className="inputeyeicon" style={{  paddingLeft: "10px",
                      backgroundColor: "#fff",
                      zIndex: 10,}}
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    {showCurrentPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputGroup>
                {formik.touched.currentPassword &&
                formik.errors.currentPassword ? (
                  <div className="requiredstylefont" style={{ color: "red" }}>
                    {formik.errors.currentPassword}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="4" lg="6" md="6" sm="6">
              {/* New Password Field */}
              <FormGroup className="mt-3 mb-4">
                <label
                  className="m-0"
                  htmlFor="inputPassword4"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  Enter your new password
                  <Tooltip
                    title={
                      <Box
                        style={{
                          fontSize: "12px",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <ul style={{ paddingLeft: "20px", margin: 0 }}>
                          <li>At least one uppercase letter (A-Z).</li>
                          <li>At least one lowercase letter (a-z).</li>
                          <li>At least one number (0-9).</li>
                          <li>
                            At least one special character (e.g., @, #, $,
                            etc.).
                          </li>
                          <li>Password must be at least 12 characters long.</li>
                          <li>
                            No continuous alphabetical characters (e.g., abcd)
                            or continuous numerical characters (e.g., 1234).
                          </li>
                          <li>
                            Avoid strictly sequential patterns (e.g.,
                            Ak12345678!).
                          </li>
                          <li>
                            Don't use birthdays, names, addresses, or other
                            personal information.
                          </li>
                        </ul>
                      </Box>
                    }
                    placement="bottom"
                    arrow
                  >
                    <IconButton>
                      <Info
                        style={{
                          height: "20px",
                          width: "20px",
                          color: "#152B51",
                          alignItems: "center",
                          position: "relative",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </label>
                <InputGroup
                  className="input-group-alternative"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    id="inputPassword4"
                    name="inputPassword4"
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.inputPassword4}
                  />
                  <IconButton
                    className="inputeyeicon" style={{  paddingLeft: "10px",
                      backgroundColor: "#fff",
                      zIndex: 10,}}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputGroup>
                {formik.touched.inputPassword4 &&
                formik.errors.inputPassword4 ? (
                  <div className="requiredstylefont" style={{ color: "red" }}>
                    {formik.errors.inputPassword4}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="4" lg="6" md="6" sm="6">
              {/* Confirm New Password Field */}
              <FormGroup className="mt-3 mb-3">
                <label
                  htmlFor="confirmPassword"
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                  }}
                >
                  Confirm new password
                </label>
                <InputGroup
                  className="input-group-alternative"
                  style={{
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    alignItems: "center",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                  />
                  <IconButton
                    className="inputeyeicon" style={{  paddingLeft: "10px",
                      backgroundColor: "#fff",
                      zIndex: 10,}}
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputGroup>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="requiredstylefont" style={{ color: "red" }}>
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <br />
          <div>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading}
              color="primary"
              style={{ backgroundColor: "#152B51" }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Change Password"}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="grey"
              onClick={() => navigate(`/tenant/tenantdashboard`)}
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default TenantPassChange;
