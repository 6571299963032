import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "components/Headers/Header";
import "Styles/rentalownerdetails.css";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import axiosInstance from "axiosInstance";
import CardTransactionTypeSettingsView from "../../AdminViews/Rentals/Components/CardTransactionTypeSettingsView";
import Loader from "components/ReusableComponents/Loader";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const RentalOwnerDetail = () => {
  const { id } = useParams();
  const { admin } = useParams();
  const [rentalOwnerDetails, setRentalOwnerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  const location = useLocation();

  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  useEffect(() => {
    const getRentalOwnerData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/rental_owner/rentalowner_details/${id}`
          );
          setRentalOwnerDetails(response.data.data[0]);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching rental owner details:", error);
          setError(error);
          setLoading(false);
        }
      }
    };

    getRentalOwnerData();
  }, [id]);

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  const editNavigate = (id) => {
    navigate(`/${admin}/Rentalowner/${id}`, { state: { id: id } });
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  return (
    <div>
      <Header />
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Row className="headerstylee">
          <Col className="text-left">
            <FormGroup className=" align-item-left text-left ml-4">
              <h1
                className="text-left labelfontstyle textcolorblue fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {rentalOwnerDetails.rentalOwner_name}
              </h1>
              <h4
                className="fontstylerentr titleecolor fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Rentalowner
              </h4>
            </FormGroup>
          </Col>
          <Col className="text-right">
            <Button
              // color="primary"
              className="ml-3 fontstylerentr btnbackmarginrent btnrentalownerdetailst background-colorsty bgtextwhite"
              onClick={(e) => {
                e.stopPropagation();
                rentalOwnerDetails.admin_id === "is_trial"
                  ? freetrialData()
                  : editNavigate(rentalOwnerDetails.rentalowner_id);
              }}
              size="small"
            >
              Edit
            </Button>
            <Button
              className="mr-3  fontstylerentr btnbackmarginrent btnrentalownerdetailst background-colorsty bgtextwhite"
              // color="primary"
              //  href="#rms"
              onClick={() => navigate(`/${admin}/RentalownerTable`)}
              size="small"
            >
              Back
            </Button>
          </Col>
        </Row>
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Summary
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <div className="col">
            <div
              className="table-responsive"
              style={{ scrollbarWidth: "none" }}
            >
              <div className="m-0">
                <Container fluid>
                  <Row className="mb-3">
                    <Col>
                      <Table
                        className="align-items-center table-flush mt-4"
                        responsive
                        style={{ width: "100%" }}
                      >
                        {loading || error ? (
                          <tbody>
                            <tr className="crm-card">
                              {loading ? (
                                <td>
                                  <Loader />
                                </td>
                              ) : (
                                <td>Error: {error.message}</td>
                              )}
                            </tr>
                          </tbody>
                        ) : rentalOwnerDetails.rentalowner_id ? (
                          <>
                            <div className="w-100">
                              <div className="crm-card">
                                <CardHeader className="bg-white border-0">
                                  <h3
                                    className="mb-0 labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Personal Information
                                  </h3>
                                </CardHeader>
                                <CardBody>
                                  <Row className="w-100 ">
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Contact Name
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.rentalOwner_name ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Company Name
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.rentalOwner_companyName ||
                                          "N/A"}
                                      </span>
                                    </Col>

                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Street Address
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.street_address ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        City
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.city || "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        State
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.state || "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Country
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.country || "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Zipcode
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.postal_code ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </div>
                              <div className="crm-card">
                                <CardHeader className="bg-white border-0">
                                  <h3
                                    className="mb-0 labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Contact Information
                                  </h3>
                                </CardHeader>
                                <CardBody>
                                  <Row className="w-100">
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Phone Number
                                      </span>
                                      <a
                                        className="fontstylerentr"
                                        href={`tel:${rentalOwnerDetails.rentalOwner_phoneNumber}`}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          fill="currentColor"
                                          className="bi bi-telephone-outbound"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5" />
                                        </svg>{" "}
                                        {rentalOwnerDetails.rentalOwner_phoneNumber ||
                                          "N/A"}
                                      </a>
                                    </Col>
                                    <Col
                                      xs="12"
                                      sm="6"
                                      md="4"
                                      lg="4"
                                      className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                    >
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Home Number
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.rentalOwner_homeNumber ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Business Number
                                      </span>
                                      <span
                                        className="d-block mb-3 fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.rentalOwner_businessNumber ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                    <Col xs="12" sm="6" md="4" lg="4">
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Email
                                      </span>
                                      <a
                                        className="fontstylerentr"
                                        href={`mailto:${rentalOwnerDetails.rentalOwner_primaryEmail}`}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="15"
                                          height="15"
                                          fill="currentColor"
                                          className="bi bi-envelope-paper"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2zm10 4.267.470.25A1 1 0 0 1 15 5.4v.817l-1 .6zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                                        </svg>{" "}
                                        {rentalOwnerDetails.rentalOwner_primaryEmail ||
                                          "N/A"}
                                      </a>
                                    </Col>
                                    <Col
                                      xs="12"
                                      sm="6"
                                      md="4"
                                      lg="4"
                                      className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                    >
                                      <span
                                        className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Alternate Email
                                      </span>
                                      {rentalOwnerDetails?.rentalOwner_alternateEmail ? (
                                        <a
                                          className="fontstylerentr"
                                          href={`mailto:${rentalOwnerDetails.rentalOwner_alternateEmail}`}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            fill="currentColor"
                                            className="bi bi-envelope-paper"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2zm10 4.267.470.25A1 1 0 0 1 15 5.4v.817l-1 .6zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                                          </svg>{" "}
                                          {rentalOwnerDetails.rentalOwner_alternateEmail ||
                                            "N/A"}
                                        </a>
                                      ) : (
                                        <span
                                          className="d-block mb-3 fontstylerentr titleecolor fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          N/A
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </div>
                              <div className="crm-card">
                                <CardHeader className="bg-white border-0">
                                  <h3
                                    className="mb-0 labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Management Agreement Details
                                  </h3>
                                </CardHeader>
                                <CardBody>
                                  <Row className="w-100 ">
                                    <Col xs="12" sm="6" md="4" lg="3" xl="3">
                                      <span
                                        className="d-block fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Start Date
                                      </span>
                                      <span
                                        className="d-block fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails?.start_date
                                          ? moment(
                                              rentalOwnerDetails?.start_date
                                            ).format(dateFormat)
                                          : "N/A"}
                                      </span>
                                    </Col>
                                    <Col
                                      xs="12"
                                      sm="6"
                                      md="4"
                                      lg="3"
                                      xl="3"
                                      className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                    >
                                      <span
                                        className="d-block fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        End Date
                                      </span>
                                      <span
                                        className="d-block fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails?.end_date
                                          ? moment(
                                              rentalOwnerDetails?.end_date
                                            ).format(dateFormat)
                                          : "N/A"}
                                      </span>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </div>
                              <div className="crm-card">
                                <CardHeader className="bg-white border-0">
                                  <h3
                                    className="mb-0 labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    1099 - NEC Tax Filing Information
                                  </h3>
                                </CardHeader>
                                <CardBody>
                                  <Row className="w-100 ">
                                    <Col xs="12" sm="6" md="4" lg="3" xl="3">
                                      <span
                                        className="d-block fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Tax Identify Type
                                      </span>
                                      <span
                                        className="d-block fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.text_identityType ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                    <Col
                                      xs="12"
                                      sm="6"
                                      md="4"
                                      lg="3"
                                      xl="3"
                                      className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                                    >
                                      <span
                                        className="d-block fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Tax Payer Id
                                      </span>
                                      <span
                                        className="d-block fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {rentalOwnerDetails.texpayer_id ||
                                          "N/A"}
                                      </span>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </div>
                            </div>
                          </>
                        ) : (
                          <tbody>
                            <tr>
                              <td>No Rental Owner details found.</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                      <div className="crm-card">
                        <CardTransactionTypeSettingsView rentalOwnerId={id} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </div>
  );
};

export default RentalOwnerDetail;
