import React, { useEffect, useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
} from "reactstrap";
import {
  getCardType,
  getCardDetails,
  getRentalOwnerCardTypeSettings,
  getCardLogo,
  getCustomerVaultId,
  addRecurringCards,
  getRecurringCards,
} from "plugins/ApiHandler";
import BaseButton from "components/ReusableComponents/BaseButton";
import Loader from "components/ReusableComponents/Loader";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, ListItemText, MenuItem, Select } from "@mui/material";
import axiosInstance from "axiosInstance";

const RecurringTenants = ({
  isOpen,
  toggle,
  tenantsData,
  admin_id,
  fetchTenantData,
  amount,
  openmodal,
  setSelectedTenantToAddCard,
}) => {
  const [loader, setLoader] = useState(true);
  const [tenantData, setTenantData] = useState([]);
  const [selectedReccuringCards, setSelectedReccuringCards] = useState([]);
  const [isDisabling, setIsDisabling] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // const handleSetCardDetails = async (item, tenant_id, leaseId) => {
  //   try {
  //     // const cardType = await getCardType(item.cc_bin, item.cc_type);
  //     const cardType = item.cardType;
  //     const response = await getRentalOwnerCardTypeSettings(tenant_id, leaseId);
  //     const cardLogo = await getCardLogo(item.cc_type);

  //     const { creditCardAccepted, debitCardAccepted } = response.data;

  //     if (
  //       (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
  //       (cardType.toLowerCase() === "debit" && !debitCardAccepted)
  //     ) {
  //       console.warn("Card type not accepted");
  //       return;
  //     }

  //     return {
  //       billing_id: item["@attributes"].id,
  //       cc_number: item.cc_number,
  //       cc_exp: item.cc_exp,
  //       cc_type: item.cc_type,
  //       cc_bin: item.cc_bin,
  //       customer_vault_id: item.customer_vault_id,
  //       card_type: cardType,
  //       card_logo: cardLogo,
  //     };
  //   } catch (error) {
  //     console.error(error, "error in bin check");
  //     return {};
  //   }
  // };
  const handleSetCardDetails = async (item, tenant_id, leaseId) => {
    try {
      const cardType = item.cardType || "Unknown";
      const response = await getRentalOwnerCardTypeSettings(tenant_id, leaseId);
      const cardLogo = await getCardLogo(item.cc_type);
  
      const { creditCardAccepted, debitCardAccepted } = response?.data || {};

      if (
        (cardType.toLowerCase() === "credit" && !creditCardAccepted) ||
        (cardType.toLowerCase() === "debit" && !debitCardAccepted)
      ) {
        console.warn(`Card type ${cardType} not accepted`);
        return null;
      }

      const billing_id = item?.["@attributes"]?.id || "";
  
      return {
        billing_id,
        cc_number: item.cc_number || "",
        cc_exp: item.cc_exp || "",
        cc_type: item.cc_type || "",
        cc_bin: item.cc_bin || "",
        customer_vault_id: item.customer_vault_id || "",
        card_type: cardType,
        card_logo: cardLogo || "",
      };
    } catch (error) {
      console.error("Error in handleSetCardDetails:", error);
      return null;
    }
  };
  
  useEffect(() => {
    // const fetchTenantData = async () => {
    //   setLoader(true);
    //   if (tenantsData.length > 0 && isOpen) {
    //     try {
    //       const recurrings = [];
    //       const tenants = [];
    //       const promises = tenantsData.map(async (tenant) => {
    //         const tenant_id = tenant.tenant_id;

    //         try {
    //           const customerData = await getCustomerVaultId(tenant_id);
    //           const customer_vault_id = customerData.customer_vault_id;

    //           const cardDetails = await getCardDetails({
    //             customer_vault_id,
    //             admin_id,
    //           });

    //           const cards = [];
    //           const billingData = cardDetails?.data?.customer;

    //           if (!billingData) {
    //             tenants.push({ ...tenant, cards });
    //             return;
    //           }

    //           if (Array.isArray(billingData.billing)) {
    //             const extractedData = await Promise.all(
    //               billingData.billing.map((item) =>
    //                 handleSetCardDetails(item, tenant_id, tenant.lease_id)
    //               )
    //             );
    //             cards.push(...extractedData.filter((item) => item));
    //           } else if (billingData) {
    //             const extractedData = await handleSetCardDetails(
    //               billingData.billing,
    //               tenant_id,
    //               tenant.lease_id
    //             );
    //             if (extractedData) cards.push(extractedData);
    //           }

    //           if (!cards.length) {
    //             recurrings.push({
    //               tenant_id: tenant.tenant_id,
    //               lease_id: tenant.lease_id,
    //               customer_vault_id,
    //               date: "",
    //               recurrings: [
    //                 { billing_id: "", amount: "", card_type: "", account: "" },
    //               ],
    //             });
    //             tenants.push({ ...tenant, cards });
    //             return;
    //           }

    //           tenants.push({ ...tenant, cards });

    //           const recurringData = await getRecurringCards({
    //             tenant_id: tenant.tenant_id,
    //             lease_id: tenant.lease_id,
    //           });

    //           if (recurringData && recurringData?.data) {
    //             recurrings.push(recurringData?.data);
    //           } else {
    //             recurrings.push({
    //               tenant_id: tenant.tenant_id,
    //               lease_id: tenant.lease_id,
    //               customer_vault_id,
    //               date: "",
    //               recurrings: [
    //                 { billing_id: "", amount: "", card_type: "", account: "" },
    //               ],
    //             });
    //           }
    //         } catch (tenantError) {
    //           recurrings.push({
    //             tenant_id: tenant.tenant_id,
    //             lease_id: tenant.lease_id,
    //             customer_vault_id: "",
    //             date: "",
    //             recurrings: [
    //               {
    //                 billing_id: "",
    //                 amount: "",
    //                 card_type: "",
    //                 account: "",
    //                 date: "",
    //               },
    //             ],
    //           });
    //           tenants.push({ ...tenant, cards: [] });
    //           console.error(
    //             `Error processing tenant ${tenant_id}: `,
    //             tenantError
    //           );
    //         }
    //       });

    //       await Promise.all(promises);

    //       setTenantData(tenants);
    //       setSelectedReccuringCards(recurrings);
    //     } catch (error) {
    //       console.error("Error: ", error);
    //     } finally {
    //       setLoader(false);
    //     }
    //   } else {
    //     setTenantData([]);
    //     setLoader(false);
    //   }
    // };
    const fetchTenantData = async () => {
      setLoader(true);
      if (tenantsData.length === 0 || !isOpen) {
        setTenantData([]);
        setLoader(false);
        return;
      }
    
      try {
        const recurrings = [];
        const tenants = [];
    
        await Promise.all(
          tenantsData.map(async (tenant) => {
            const tenant_id = tenant.tenant_id;
            const lease_id = tenant.lease_id;
            let customer_vault_id = "";
            const cards = [];
    
            try {
              // Fetch customer data
              const customerData = await getCustomerVaultId(tenant_id);
              customer_vault_id = customerData.customer_vault_id || "";
    
              // Extract billing ID & card type mapping from customerData.card_detail
              const billingCardMap = {};
              if (Array.isArray(customerData.card_detail)) {
                customerData.card_detail.forEach((card) => {
                  billingCardMap[card.billing_id] = card.card_type;
                });
              }
    
              // Fetch card details
              const cardDetails = await getCardDetails({ customer_vault_id, admin_id });
              const billingData = cardDetails?.data?.customer?.billing;
    
              if (Array.isArray(billingData)) {
                const extractedData = await Promise.all(
                  billingData.map(async (item) => {
                    const cardInfo = await handleSetCardDetails(item, tenant_id, lease_id);
                    if (cardInfo) {
                      // Set the correct card type from the billing ID mapping
                      cardInfo.card_type = billingCardMap[item["@attributes"]?.id] || cardInfo.card_type;
                    }
                    return cardInfo;
                  })
                );
                cards.push(...extractedData.filter(Boolean)); // Remove null values
              } else if (billingData) {
                const extractedData = await handleSetCardDetails(billingData, tenant_id, lease_id);
                if (extractedData) {
                  extractedData.card_type = billingCardMap[billingData["@attributes"]?.id] || extractedData.card_type;
                  cards.push(extractedData);
                }
              }
            } catch (error) {
              console.error(`Error fetching card details for tenant ${tenant_id}:`, error);
            }
    
            // Push tenant data even if they have no cards
            tenants.push({ ...tenant, cards });
    
            // Fetch recurring cards
            try {
              const recurringData = await getRecurringCards({ tenant_id, lease_id });
    
              if (recurringData?.data) {
                recurrings.push(recurringData.data);
              } else {
                recurrings.push({
                  tenant_id,
                  lease_id,
                  customer_vault_id,
                  date: "",
                  recurrings: [{ billing_id: "", amount: "", card_type: "", account: "" }],
                });
              }
            } catch (error) {
              console.error(`Error fetching recurring data for tenant ${tenant_id}:`, error);
              recurrings.push({
                tenant_id,
                lease_id,
                customer_vault_id,
                date: "",
                recurrings: [{ billing_id: "", amount: "", card_type: "", account: "" }],
              });
            }
          })
        );
    
        setTenantData(tenants);
        setSelectedReccuringCards(recurrings);
      } catch (error) {
        console.error("Error fetching tenant data:", error);
      } finally {
        setLoader(false);
      }
    };
    
    fetchTenantData();
  }, [tenantsData, isOpen]);

  const [recAccounts, setRecAccounts] = useState([]);

  const fetchAccounts = async () => {
    if (admin_id) {
      try {
        const res = await axiosInstance.get(`/accounts/accounts/${admin_id}`);
        if (res.data.statusCode === 200) {
          const filteredData2 = res.data.data
          // .filter(
          //   (item) => item.charge_type === "Recurring Charge"
          // );
          setRecAccounts(filteredData2);
        } else if (res.data.statusCode === 201) {
          setRecAccounts();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [admin_id]);

  const handleSelection = (selectedCard, tenant, recurringIndex) => {
    const updatedRecurringCards = selectedReccuringCards?.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        const updatedRecurrings = t.recurrings.map((recurring, index) => {
          if (index === recurringIndex) {
            return {
              ...recurring,
              billing_id: selectedCard?.billing_id,
              card_type: selectedCard?.card_type,
            };
          }
          return recurring;
        });
        return { ...t, recurrings: updatedRecurrings };
      }
      return t;
    });

    setSelectedReccuringCards(updatedRecurringCards);
  };

  const addRow = (tenant) => {
    const updatedRecurringCards = selectedReccuringCards?.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        return {
          ...t,
          recurrings: [
            ...t.recurrings,
            {
              billing_id: "",
              amount: "",
              card_type: "",
              account: "",
              date: "",
            },
          ],
        };
      }
      return t;
    });
    setSelectedReccuringCards(updatedRecurringCards);
  };

  const removeRow = (tenant, index) => {
    const updatedRecurringCards = selectedReccuringCards?.map((t) => {
      if (t.tenant_id === tenant.tenant_id) {
        const updatedRecurrings = t.recurrings.filter((_, i) => i !== index);
        return { ...t, recurrings: updatedRecurrings };
      }
      return t;
    });
    setSelectedReccuringCards(updatedRecurringCards);
  };

  const TenantDropDowns = ({ tenant, value, recurringIndex }) => {
    const card = tenant?.cards?.find((item) => item?.billing_id === value);

    const handleCreditCardOpen = (tenant) => {
      setSelectedTenantToAddCard(tenant);
      toggle();
      openmodal();
    };

    return (
      <>
        <FormGroup className="d-flex flex-column">
          <label
            className="form-control-label fontstylerentr titleecolor fontfamilysty"
            htmlFor="input-unitadd"
            style={{
              fontWeight: "500",
              fontSize: "16px",
              textAlign: "left",
            }}
          >
            Choose Card *
          </label>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={card}
            onChange={(e) =>
              handleSelection(e.target.value, tenant, recurringIndex)
            }
            displayEmpty
            renderValue={(selected) => {
              return selected?.cc_number || "Select Card";
            }}
            style={{ minWidth: "150px" }}
          >
            <MenuItem
              className="background-colorsty bgtextwhite"
              onClick={() => handleCreditCardOpen(tenant)}
            >
              Add Card
            </MenuItem>
            {tenant?.cards?.length > 0 ? (
              tenant?.cards?.map((item) => (
                <MenuItem
                  key={item.billing_id}
                  value={item}
                  className="d-flex"
                  style={{ gap: "10px" }}
                >
                  <div className="d-flex">
                    <img src={item?.card_logo} width={30} />
                  </div>
                  <div className="d-flex flex-column w-100">
                    <ListItemText
                      primary={`${item?.cc_number || ""}`}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                    <div className="d-flex justify-content-between">
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: "12px" } }}
                        primary={`${item?.card_type || ""}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{ style: { fontSize: "12px" } }}
                        primary={`${item?.cc_exp || ""}`}
                      />
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">No card added</MenuItem>
            )}
          </Select>
        </FormGroup>
      </>
    );
  };

  const handleSubmit = async () => {
    try {
      if (
        selectedReccuringCards?.every((tenant) =>
          tenant?.recurrings?.every((item) =>
            Object.entries(item).every(([key, value]) =>
              key === "amount" ? value > 0 : value
            )
          )
        )
      ) {
        await addRecurringCards(selectedReccuringCards);
        fetchTenantData();
        toggle();
        setSelectedReccuringCards();
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const dueAmount =
    selectedReccuringCards?.reduce(
      (acc1, tenant) =>
        acc1 +
        Number(
          tenant?.recurrings?.reduce(
            (acc2, item) => acc2 + Number(item?.amount),
            0
          )
        ),
      0
    ) || 0;

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "830px" }}>
      <ModalHeader>
        <h2>Configure Recurring Payment</h2>
        <div
          className="mt-2 textcolorblue"
          style={{ fontSize: "16px", fontWeight: 400 }}
        >
          <span>Total Rent Amount: ${Number(amount || 0).toFixed(2)}</span>
          {/* <span>
            <br />
            Remaining Balance: ${Number(dueAmount || 0).toFixed(2)}
          </span> */}
        </div>
      </ModalHeader>
      <Divider />
      <ModalBody>
        {loader ? (
          <Loader />
        ) : (
          tenantData?.map((tenant, index) => {
            const selectedTenant = selectedReccuringCards?.find(
              (t) => tenant.tenant_id === t.tenant_id
            );

            return (
              <div className="d-flex flex-column w-100 mt-1" key={index}>
                <div className="d-flex justify-content-between mt-2">
                  <div
                    className="d-flex align-items-start textcolorblue w-100"
                    style={{ fontSize: "18px", fontWeight: 500 }}
                  >
                    <span className="pr-3">
                      {tenant?.tenant_firstName} {tenant?.tenant_lastName}
                    </span>
                  </div>
                </div>

                {selectedTenant?.recurrings?.map((recurring, i) => (
                  <div
                    className="d-flex align-items-center textcolorblue w-100 mt-3"
                    style={{ gap: "20px" }}
                    key={i}
                  >
                    <TenantDropDowns
                      tenant={tenant}
                      value={recurring?.billing_id}
                      recurringIndex={i}
                    />
                    <FormGroup className="d-flex flex-column">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty d-flex flex-column"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          // textAlign: "left",
                        }}
                      >
                        Choose Day of Month *
                      </label>

                      <Select
                        labelId="user-select-label"
                        id="user-select"
                        value={recurring?.date}
                        style={{ minWidth: "200px" }}
                        onChange={(e) => {
                          const updatedRecurrings = [
                            ...selectedTenant.recurrings,
                          ];
                          updatedRecurrings[i].date = e.target.value;
                          setSelectedReccuringCards((prev) =>
                            prev.map((t) =>
                              t.tenant_id === tenant.tenant_id
                                ? { ...t, recurrings: updatedRecurrings }
                                : t
                            )
                          );
                        }}
                        displayEmpty
                        renderValue={(selected) =>
                          selected || "Select Day of Month"
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 150,
                            },
                          },
                        }}
                      >
                        {Array.from({ length: 28 }, (_, i) => i + 1).map(
                          (number) => (
                            <MenuItem key={number} value={number}>
                              {number}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormGroup>
                    <FormGroup className="mb-3 d-flex flex-column">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        Account *
                      </label>
                      <Select
                        labelId="user-select-label"
                        id="user-select-account"
                        value={
                          recurring?.account
                            ? recurring.account
                            : (recurring.account = "Rent Income")
                        }
                        style={{ minWidth: "200px" }}
                        onChange={(e) => {
                          const updatedRecurrings = [
                            ...selectedTenant.recurrings,
                          ];
                          updatedRecurrings[i].account = e.target.value;
                          setSelectedReccuringCards((prev) =>
                            prev.map((t) =>
                              t.tenant_id === tenant.tenant_id
                                ? { ...t, recurrings: updatedRecurrings }
                                : t
                            )
                          );
                        }}
                        displayEmpty
                        renderValue={(selected) => selected || "Rent Income"}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 150,
                            },
                          },
                        }}
                      >
                        <MenuItem value={"Rent Income"}>Rent Income</MenuItem>
                        {/* {LiabilityAccounts?.map((item) => (
                        <MenuItem key={item._id} value={item.account}>
                          {item.account}
                        </MenuItem>
                      ))} */}
                        {recAccounts?.map((item) => (
                          <MenuItem key={item._id} value={item.account}>
                            {item.account}
                          </MenuItem>
                        ))}
                        {/* {oneTimeAccounts?.map((item) => (
                        <MenuItem key={item._id} value={item.account}>
                          {item.account}
                        </MenuItem>
                      ))} */}
                      </Select>
                    </FormGroup>
                    <FormGroup className="mb-3 d-flex flex-column">
                      <label
                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor="input-unitadd"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        Amount *
                      </label>
                      <Input
                        className="form-control-alternative fontstylerentmodal titleecolor w-100"
                        placeholder="$0.00"
                        type="text"
                        style={{
                          boxShadow: "0px 4px 4px 0px #00000040",
                          borderRadius: "6px",
                          width: "50px",
                          fontWeight: "600",
                        }}
                        value={recurring?.amount}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            const updatedRecurrings = [
                              ...selectedTenant.recurrings,
                            ];
                            updatedRecurrings[i].amount = e.target.value;
                            setSelectedReccuringCards((prev) =>
                              prev.map((t) =>
                                t.tenant_id === tenant.tenant_id
                                  ? { ...t, recurrings: updatedRecurrings }
                                  : t
                              )
                            );
                          }
                        }}
                      />
                    </FormGroup>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => removeRow(tenant, i)}
                    />
                  </div>
                ))}

                <div
                  className="mt-2 mb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => addRow(tenant)}
                >
                  <AddIcon color="success" /> Add Row
                </div>
              </div>
            );
          })
        )}
      </ModalBody>
      <ModalFooter>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <span>Total Amount: ${dueAmount}</span>
          <BaseButton
            onClick={() => {
              toggle();
              setSelectedReccuringCards();
            }}
            variant="outline"
            label="Cancel"
          />
          <BaseButton
            onClick={async () => {
              setIsDisabling(true);
              try {
                const response = await axiosInstance.put(
                  `/recurring-cards/disable-cards/${tenantData[0].lease_id}`
                );
                if (response.data.statusCode === 200) {
                  await fetchTenantData();
                  toggle();
                  setSelectedReccuringCards();
                } else {
                  await fetchTenantData();
                  setSelectedReccuringCards();
                }
              } catch (error) {
                console.error("Error: ", error);
              }
              finally {
                setIsDisabling(false); 
              }
            }}
            label= {isDisabling  ? "Disabling" : "Disable"}  
            disabled={isDisabling}
          />
          <BaseButton
            id="payButton"
            type="submit"
            label={isSaving ? "Saving..." : "Save"}
            disabled={
              isSaving ||
              !selectedReccuringCards?.every((tenant) =>
                tenant?.recurrings?.every((item) =>
                  Object.entries(item).every(([key, value]) =>
                    key === "amount" ? value > 0 : value
                  )
                )
              )
            }
            onClick={async () => {
              setIsSaving(true);
              try {
                await handleSubmit();
              } finally {
                setIsSaving(false);
              }
            }}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RecurringTenants;
