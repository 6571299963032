import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LeaseTable = ({
  leases,
  leaseLoader,
  totalLeases,
  leasePage,
  leaseRowsPerPage,
  setLeasePage,
  setLeaseRowsPerPage,
}) => {
  const handleChangeLeasePage = (event, newPage) => {
    setLeasePage(newPage);
  };

  const handleChangeLeaseRowsPerPage = (event) => {
    setLeaseRowsPerPage(parseInt(event.target.value, 10));
    setLeasePage(0);
  };

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const isTabletScreen = useMediaQuery("(max-width: 960px)");

  return (
    <TableContainer
      component={Paper}
      style={{
        borderRadius: "0 0 20px 20px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Table>
        <TableHead>
          <TableRow style={{ height: "50px" }}>
            <TableCell>
              <span
                className="dashtable-header titleecolor"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Rental Address
              </span>
            </TableCell>
            <TableCell>
              <span
                className="dashtable-header titleecolor"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Tenant Name
              </span>
            </TableCell>
            <TableCell>
              <span
                className="dashtable-header titleecolor"
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Expiration Date
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaseLoader ? (
            <TableRow style={{ height: "50px" }}>
              <TableCell colSpan={3}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress size={30} style={{ color: "#152B51" }} />
                </Box>
              </TableCell>
            </TableRow>
          ) : leases.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Typography>No Leases Expiring in the next 60 days</Typography>
              </TableCell>
            </TableRow>
          ) : (
            leases.map((lease) => (
              <TableRow key={lease.lease_id} style={{ height: "50px" }}>
                <TableCell>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/admin/rentrolldetail/${lease.lease_id}`)
                    }
                  >
                    {isSmallScreen
                      ? lease.rental_address.slice(0, 10) + "..."
                      : lease.rental_address}
                  </span>
                </TableCell>
                <TableCell>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/admin/tenantdetail/${lease.tenant_id}`)
                    }
                  >
                    {isSmallScreen
                      ? lease.tenant_name.split(" ")[0]
                      : lease.tenant_name}
                  </span>
                </TableCell>
                <TableCell>
                  {moment(lease.end_date).format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalLeases}
        page={leasePage}
        onPageChange={handleChangeLeasePage}
        rowsPerPage={leaseRowsPerPage}
        onRowsPerPageChange={handleChangeLeaseRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          "& p": { margin: "0" },
          "& div": { margin: "0" },
        }}
      />
    </TableContainer>
  );
};

export default LeaseTable;
