import { configureStore } from "@reduxjs/toolkit";
import currentUserSlice from "./CurrentUserSlice";
import { entitiesReducer, queriesReducer, queryMiddleware } from 'redux-query'; // Import queryMiddleware instead

export const store = configureStore({
  reducer: {
    currentUser: currentUserSlice,
    entities: entitiesReducer, // Add entities reducer for handling API responses
    queries: queriesReducer,   // Add queries reducer for query management
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(queryMiddleware), // Use queryMiddleware
});
