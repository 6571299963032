import Index from "views/Index";
import Profile from "views/source/Profile.js";
import Rentals from "views/source/Rentals.js";
import PropertiesTable from "views/source/PropertiesTable";
import Leaseing from "views/source/Leaseing.js";
import TenantsTable from "views/source/TenantsTable.js";
import PropertyType from "views/source/PropertyType";
import AddPropertyType from "views/source/AddPropertyType";
import AddStaffMember from "views/source/AddStaffMember";
import StaffMember from "views/source/StaffMember";
import Rentalowner from "views/AdminViews/Rentals/ManageRentalOnwer";
import Login from "views/source/Login";
import RentalownerTable from "views/source/RentalownerTable";
import Listings from "views/source/Listings";
import Applicants from "views/source/Applicants";
import RentRoll from "views/source/RentRoll";
import PlanUpgrade from "views/source/PlanUpgrade";
import Plans from "views/source/Plans";
import Planpurches from "views/source/Planpurches";
import OutstandingBalance from "views/source/OutstandingBalance";
import TenantDetailPage from "views/source/TenantDetailPage";
import RentRollDetail from "views/source/RentRollDetail";
import OutstandDetails from "views/source/OutstandDetails";
import PropDetails from "views/source/PropDetails";
import RentRollLeaseing from "views/source/RentRollLeaseing";
import TenantDashBoard from "views/source/TenantDashBoard";
import AddPaymentByTenant from "views/TenantViews/PaymentViews/AddPaymentByTenant";
import TenantProfile from "views/source/TenantProfile";
import TenantProperty from "views/source/TenantProperty";
import AddAgent from "views/source/AddAgent";
import Agent from "views/source/Agent";
import AgentdashBoard from "views/source/AgentdashBoard";
import StaffDashBoard from "views/source/StaffDashBoard";
import VendorDashBoard from "views/source/VendorDashBoard";
import VendorProfile from "views/source/VendorProfile";
import TenantWork from "views/source/TenantWork";
import TAddWork from "views/source/TAddWork";
import Workorder from "views/source/Workorder";
import AddWorkorder from "views/source/AddWorkorder";
import StaffProfile from "views/source/StaffProfile";
import VendorWorkTable from "views/source/VendorWorkTable";
import StaffSetting from "views/source/StaffSetting";
import VendoeWorkTable from "views/source/VendorWorkTable";
import Vendor from "views/source/Vendor";
import AddVendor from "views/source/AddVendor";
import TWorkOrderDetails from "views/source/Tworkorderdetail";
import AddGeneralLedger from "views/source/AddGeneralLedger";
import WorkOrderDetails from "views/source/WorkOrderDetails";
import VendorWorkDetail from "views/source/VendorWorkDetail";
import VendorAddWork from "views/source/VendorAddWork";
import ApplicantSummary from "views/source/ApplicantSummary";
import TenantPropertyDetail from "views/source/TenantPropertyDetail";
import Payment from "views/source/Payment";
import AddPayment from "views/source/AddPayment";
import AddCharge from "views/source/AddCharge";
import TenantFinancial from "views/source/TenantFinancial";
import Changepassword from "views/source/Changepassword";
import Forgetmail from "views/source/forgetmail";
import TrialLogin from "views/source/TrialLogin";
import DemoPayment from "views/source/DemoPayment";
import SurchargeForm from "views/source/Surcharge";
import SurchargeTable from "views/source/SurchargeTable";
import Settings from "views/AdminViews/Settings/Settings";
import TenantAddPayment from "views/source/TenantAddPayment";
import Purchaseplan from "views/source/purchaseplandetail";
import ExpiringLease from "views/source/ExpiringLease";
import Userrole from "views/source/Userrole";
import RentersInsurance from "views/source/RentersInsurance";
import DelinquentTenants from "views/source/DelinquentTenants";
import Reports from "views/source/Reports";
import OpenWorkReport from "views/source/OpenWorkReport";
import CompletedWorkReport from "views/source/CompletedWorkReport";
import TenantDocuments from "views/source/TenantDocuments";
import DocInsurance from "views/source/DocInsurance";
import Dailytransactionreport from "views/source/DailyTransactionReport";
import Rentalownerreport from "views/source/Rentalownerreport";
import UpcomingRenewal from "views/source/UpcomingRenewal";
import AccountTotalReport from "views/source/AccountTotalReport";
import DueRentReport from "views/Dashboard-Reports/Report";
import PaymentReport from "views/Payment-Exception-Report/Report";
import RecurringReport from "views/Recurring-Payment-Report/Report";
import ConvenienceFeeOverrideReport from "views/Convenience-Fee-Override-Report/Report";
import ExpiringRenterInsurancesReport from "views/source/ExpiringRenterInsurancesReport";
import RentRollReport from "views/source/RentRollReport";

// ==========================  Super Admin ===================================================

import SuperAdminDashBoard from "./components/superadmin/Dashboard/DashBoard";
import SuperAdminPlanList from "components/superadmin/Plan/PlanList";
import SuperAdminAddplan from "components/superadmin/Plan/Addplan";
import SuperAdminAdmin from "components/superadmin/Admin/Admin";
import SuperAdminPropertyType from "components/superadmin/Admin/PropertyType";
import SuperAdminStaffMember from "components/superadmin/Admin/StaffMember";
import SuperAdminProperties from "components/superadmin/Admin/Properties";
import SuperAdminRentalOwner from "components/superadmin/Admin/RentalOwner";
import SuperAdminTenat from "components/superadmin/Admin/Tenant";
import SuperAdminUnit from "components/superadmin/Admin/Unit";
import SuperAdminLease from "components/superadmin/Admin/Leasing";
import SuperAdminVendor from "components/superadmin/Admin/Vendor";
import SuperAdminEmailForm from "components/superadmin/Email/EmailForm";
import SuperAdminEmailTable from "components/superadmin/Email/EmailTable";
import ResetPassword from "views/source/Resetpassword";
import CreatePassword from "views/source/CreatePassword";

//  ====================== admin sidebar icons ======================
import Dashboard from "./assets/icons/AdminSidebar/dashboard.svg";
import Dashboard2 from "./assets/icons/AdminSidebar/dashboard2.svg";
import Property from "./assets/icons/AdminSidebar/Property.svg";
import Property2 from "./assets/icons/AdminSidebar/Property2.svg";
import Staffmember from "./assets/icons/AdminSidebar/Staffmember.svg";
import Staffmember2 from "./assets/icons/AdminSidebar/Staffmember2.svg";

import Building from "./assets/icons/AdminSidebar/Building.svg";
import Building1 from "./assets/icons/AdminSidebar/Building1.svg";
import Plan from "./assets/icons/Plans.svg";
import Plan2 from "./assets/icons/Plans2.svg";
import Admin from "./assets/icons/Admin.svg";
import admin2 from "./assets/icons/admin2.svg";
import Financial from "./assets/icons/Financial.svg";
import Financial2 from "./assets/icons/Financial2.svg";
import Work from "./assets/icons/Work.svg";
import Work2 from "./assets/icons/Work Light.svg";
import Email1 from "./assets/icons/Email1.svg";
import Email2 from "./assets/icons/Email2.svg";
import tenantdoc1 from "./assets/icons/tenantdoc1.svg";
import tenantdoc2 from "./assets/icons/tenantdoc2.svg";
import User from "./assets/icons/AdminSidebar/User.svg";
import User1 from "./assets/icons/AdminSidebar/User1.svg";
import Lease from "./assets/icons/AdminSidebar/Lease.svg";
import Lease1 from "./assets/icons/AdminSidebar/Lease1.svg";
import DocLeases from "views/source/DocLeases";
import TenantPassChange from "TenantPassChange";
import ChangeSecurityKey from "views/source/ChangeSecurityKey";
import Report from "./assets/icons/AdminSidebar/Report.svg";
import Report1 from "./assets/icons/AdminSidebar/Report1.svg";
import Applicant from "./assets/icons/AdminSidebar/Applicant.svg";
import Applicant1 from "./assets/icons/AdminSidebar/Applicant1.svg";
import Vendors from "./assets/icons/AdminSidebar/Vendor.svg";
import Vendors1 from "./assets/icons/AdminSidebar/Vendor1.svg";
import Owner from "./assets/icons/AdminSidebar/owner.svg";
import Owner1 from "./assets/icons/AdminSidebar/owner1.svg";
import Upcomingicon from "./assets/icons/AdminSidebar/upcoming renewal.svg";
import Upcomingicon1 from "./assets/icons/AdminSidebar/upcoming white.svg";

import StaffPassChange from "views/source/StaffPassChange";
import AuditLogs from "views/source/AuditLogs";
import RentalOwnerDetail from "views/AdminViews/Rentals/RentalOwnerDetail";
import Templates from "views/source/Templates";
import AddTemplates from "views/source/AddTemplate";
import Emails from "views/source/Emails";
import Sendmails from "views/source/SendMails";
import Emaillogs from "views/source/Emaillogs";
import VendorPassChange from "views/source/VendorPassChange";
import RenterInsuranceDetails from "components/RenterInsurance/RenterInsuranceDetails";
import ScheduledPayment from "views/AdminViews/Scheduled-Payments/ScheduledPayment";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/rentals",
    name: "Add Property",
    icon: "ni ni-pin-3 text-orange",
    component: <Rentals />,
    layout: "/admin",
  },
  {
    path: "/Plans",
    component: <Plans />,
    layout: "/admin",
  },
  {
    path: "/Purchaseplandetail",
    component: <Purchaseplan />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-yellow",
    component: <Settings />,
    layout: "/admin",
  },
  {
    path: "/propertiesTable",
    name: "Properties Table",
    icon: "ni ni-bullet-list-67 text-red",
    component: <PropertiesTable />,
    layout: "/admin",
  },
  {
    name: "Rental Owner Table",
    path: "/RentalownerTable",
    component: <RentalownerTable />,
    layout: "/admin",
  },
  {
    name: "Tenants Table",
    path: "/TenantsTable",
    icon: "ni ni-bullet-list-67 text-red",
    component: <TenantsTable />,
    layout: "/admin",
  },
  {
    path: "/Leaseing",
    name: "Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <Leaseing />,
    layout: "/admin",
  },
  {
    path: "/Leaseing/:tenant_id",
    name: "Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <Leaseing />,
    layout: "/admin",
  },
  {
    path: "/PropertyType",
    name: "Property Type",
    icon: Property,
    icon2: Property2,
    component: <PropertyType />,
    layout: "/admin",
  },
  {
    path: "/AddPropertyType",
    name: "Add Property",
    component: <AddPropertyType />,
    layout: "/admin",
  },
  {
    path: "/AddStaffMember",
    name: "Add Staff Member",
    component: <AddStaffMember />,
    layout: "/admin",
  },
  {
    path: "/StaffMember",
    name: "Staff Member",
    icon: Staffmember,
    icon2: Staffmember2,
    component: <StaffMember />,
    layout: "/admin",
  },
  {
    path: "/Rentalowner",
    component: <Rentalowner />,
    layout: "/admin",
  },
  {
    path: "/Rentalowner/:id",
    component: <Rentalowner />,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/changepassword",
    name: "Change Password",
    component: <Changepassword />,
    layout: "/auth",
  },
  {
    path: "/changepassword/:role",
    name: "Change Password",
    component: <Changepassword />,
    layout: "/auth",
  },
  {
    path: "/forgetpassword",
    name: "Forget Password",
    component: <Forgetmail />,
    layout: "/auth",
  },
  {
    path: "/Resetpassword",
    name: "Reset password",
    component: <ResetPassword />,
    layout: "/auth",
  },
  {
    path: "/createpassword",
    name: "Create Password",
    component: <CreatePassword />,
    layout: "/auth",
  },
  {
    path: "/RentRoll",
    name: "Rent Roll",
    icon: "ni ni-home-3 text-orange",
    component: <RentRoll />,
    layout: "/admin",
  },
  {
    path: "/Payment",
    name: "Payment",
    icon: "ni ni-home-3 text-orange",
    component: <DemoPayment />,
    layout: "/admin",
  },
  {
    name: "Templates",
    path: "/templates",
    component: <Templates />,
    layout: "/admin",
  },
  {
    name: "Add Template",
    path: "/add-template",
    component: <AddTemplates />,
    layout: "/admin",
  },
  {
    name: "Send E-mail",
    path: "/emails",
    component: <Emails />,
    layout: "/admin",
  },
  {
    name: "Send E-mails",
    path: "/send-email",
    component: <Sendmails />,
    layout: "/admin",
  },
  {
    name: "E-mail Logs",
    path: "/email-logs",
    component: <Emaillogs />,
    layout: "/admin",
  },
  {
    name: "Templates",
    path: "/templates",
    component: <Templates />,
    layout: "/staff",
  },
  {
    name: "Add Template",
    path: "/add-template",
    component: <AddTemplates />,
    layout: "/staff",
  },
  {
    name: "Send E-mail",
    path: "/emails",
    component: <Emails />,
    layout: "/staff",
  },
  {
    name: "Send E-mails",
    path: "/send-email",
    component: <Sendmails />,
    layout: "/staff",
  },
  {
    name: "E-mail Logs",
    path: "/email-logs",
    component: <Emaillogs />,
    layout: "/staff",
  },
  {
    path: "/Listings",
    name: "listings",
    icon: "ni ni-home-3 text-orange",
    component: <Listings />,
    layout: "/admin",
  },
  {
    path: "/Applicants",
    name: "Applicants",
    icon: "ni ni-home-3 text-orange",
    component: <Applicants />,
    layout: "/admin",
  },
  {
    path: "/OutstandingBalance",

    component: <OutstandingBalance />,
    layout: "/admin",
  },
  {
    path: "/tenantdetail/:id",
    name: "Tenant Detail",
    component: <TenantDetailPage />,
    layout: "/admin",
  },
  {
    path: "/rentrolldetail/:lease_id",
    name: "Rent Roll Detail",
    component: <RentRollDetail />,
    layout: "/admin",
  },
  {
    path: "/rent-roll-details/:id/renter-insurane-details",
    name: "Rent Roll Detail",
    component: <RenterInsuranceDetails />,
    layout: "/admin",
  },
  {
    path: "/rentalownerdetail/:id",
    name: "Rental owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/admin",
  },
  {
    path: "/PropDetails/:rental_id",
    name: "Prop Details",
    component: <PropDetails />,
    layout: "/admin",
  },
  {
    path: "/OutstandDetails/:id",
    name: "OutstandDetails",
    component: <OutstandDetails />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing",
    name: "Rent Roll Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing?data",
    name: "Rent Roll Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing/:lease_id/:applicant_id",
    name: "Rent Roll Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/RentRollLeaseing/:lease_id",
    name: "Rent Roll Leaseing",
    icon: "ni ni-home-3 text-orange",
    component: <RentRollLeaseing />,
    layout: "/admin",
  },
  {
    path: "/tenantdashboard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <TenantDashBoard />,
    layout: "/tenant",
  },
  {
    path: "/add-payment",
    name: "AddPaymentByTenant",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <AddPaymentByTenant />,
    layout: "/tenant",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <TenantProfile />,
    layout: "/tenant",
  },
  {
    path: "/tenantproperty",
    name: "Property",
    icon: Property,
    icon2: Property2,
    component: <TenantProperty />,
    layout: "/tenant",
  },
  {
    path: "/tenantFinancial",
    name: "Ledger",
    icon: Financial,
    icon2: Financial2,
    component: <TenantFinancial />,
    layout: "/tenant",
  },
  {
    path: "/PasswordChange",
    name: "Password Change",
    component: <TenantPassChange />,
    layout: "/tenant",
  },
  {
    path: "/Agent",
    name: "Add Agent",
    icon: "ni ni-single-02 text-blue",
    component: <Agent />,
    layout: "/admin",
  },
  {
    path: "/AddAgent",
    icon: "ni ni-single-02 text-green",
    component: <AddAgent />,
    layout: "/admin",
  },

  {
    path: "/AgentdashBoard",
    name: "DashBoard",
    icon: "ni ni-tv-2 text-primary",
    component: <AgentdashBoard />,
    layout: "/agent",
  },
  {
    path: "/StaffdashBoard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <StaffDashBoard />,
    layout: "/staff",
  },
  {
    path: "/VendordashBoard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <VendorDashBoard />,
    layout: "/vendor",
  },
  {
    path: "/vendorprofile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <VendorProfile />,
    layout: "/vendor",
  },
  {
    path: "/VendorPasswordChange",
    name: "Vendor Password Change",
    component: <VendorPassChange />,
    layout: "/vendor",
  },
  {
    path: "/tenantwork",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <TenantWork />,
    layout: "/tenant",
  },
  {
    path: "/tenantDocuments",
    name: "Documents",
    icon: tenantdoc1,
    icon2: tenantdoc2,
    component: <TenantDocuments />,
    layout: "/tenant",
  },
  {
    name: "Tenants Insurance",
    path: "/TenantsInsurance",
    component: <DocInsurance />,
    layout: "/tenant",
  },
  {
    name: "Tenants Leases",
    path: "/TenantsLeases",
    component: <DocLeases />,
    layout: "/tenant",
  },
  {
    path: "/tenantwork?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <TenantWork />,
    layout: "/tenant",
  },
  {
    path: "/taddwork",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <TAddWork />,
    layout: "/tenant",
  },
  {
    path: "/addworkorder",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    path: "/PasswordChange",
    name: "Password Change",
    component: <StaffPassChange />,
    layout: "/staff",
  },
  {
    path: "/staffprofile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <StaffProfile />,
    layout: "/staff",
  },
  {
    path: "/staffpropertytype",
    name: "Property Type",
    icon: Property,
    icon2: Property2,
    component: <PropertyType />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype",
    name: "Staff Add Property Type",
    component: <AddPropertyType />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype/:id",
    name: "Staff Add Property Type",
    component: <AddPropertyType />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner",
    name: "Staff Add Rental Owner",
    component: <Rentalowner />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner/:id",
    name: "Staff Add Rental Owner",
    component: <Rentalowner />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail",
    name: "Staff Rental Owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail/:id",
    name: "Staff Rental Owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/staff",
  },
  {
    path: "/staffrentals",
    name: "Properties",
    icon: Building,
    icon2: Building1,
    component: <PropertiesTable />,
    layout: "/staff",
  },
  {
    path: "/staffrentroll",
    name: "Rent Roll",
    icon: Lease,
    icon2: Lease1,
    component: <RentRoll />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffrentrolldetail/:lease_id",
    name: "Staffrentrolldetail",
    component: <RentRollDetail />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id/:applicant_id",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownertable",
    name: "Rental Owner",
    icon: Owner,
    icon2: Owner1,
    component: <RentalownerTable />,
    layout: "/staff",
  },
  {
    path: "/staffapplicant",
    name: "Applicants",
    icon: Applicant,
    icon2: Applicant1,
    component: <Applicants />,
    layout: "/staff",
  },

  {
    path: "/staffapplicantsummary/:id",
    name: "StaffApplicantsummary",
    component: <ApplicantSummary />,
    layout: "/staff",
  },

  {
    path: "/stafftenanttable",
    name: "Tenants",
    icon: User,
    icon2: User1,
    component: <TenantsTable />,
    layout: "/staff",
  },
  {
    path: "/stafftenantdetail/:id",
    name: "Tenant Detail",
    component: <TenantDetailPage />,
    layout: "/staff",
  },
  {
    path: "/staffedittenant/:tenant_id",
    name: "Tenant Edit",
    component: <Leaseing />,
    layout: "/staff",
  },
  {
    path: "/staffaddtenant",
    name: "Tenant Add",
    component: <Leaseing />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd",
    name: "Staff Property Add",
    component: <Rentals />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd/:rental_id",
    name: "Staff Property Edit",
    component: <Rentals />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id",
    name: "Staff Add Payment",
    component: <AddPayment />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id",
    name: "Staff Add Charge",
    component: <AddCharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id/:charge_id",
    name: "Staff Add Charge",
    component: <AddCharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id/:payment_id",
    name: "Staff Add Payment",
    component: <AddPayment />,
    layout: "/staff",
  },
  {
    path: "/staffsetting",
    name: "Staff Setting",
    component: <StaffSetting />,
    layout: "/staff",
  },
  {
    name: "Renters Insurance",
    path: "/RentersInsurance",
    component: <RentersInsurance />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Renters Expiring Insurance Report",
    path: "/ExpiringInsurances",
    component: <ExpiringRenterInsurancesReport />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Delinquent Tenants",
    path: "/DelinquentTenants",
    component: <DelinquentTenants />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Expiring Lease",
    path: "/ExpiringLeases",
    component: <ExpiringLease />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Daily Transaction Report",
    path: "/dailytransactionreport",
    component: <Dailytransactionreport />,
    layout: "/admin",
    is_display: true,
  },

  {
    name: "Open Work Orders",
    path: "/OpenWorkOrders",
    component: <OpenWorkReport />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Completed Work Orders",
    path: "/CompletedWorkOrders",
    component: <CompletedWorkReport />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Rental Owner Report",
    path: "/rentalownerreport",
    component: <Rentalownerreport />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Account Totals Report",
    path: "/accounttotalsreport",
    component: <AccountTotalReport />,
    layout: "/admin",
    is_display: true,
  },
  {
    name: "Account Totals Report",
    path: "/Staffaccounttotalsreport",
    component: <AccountTotalReport />,
    layout: "/staff",
    is_display: true,
  },
  {
    path: "/vendorworktable",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <VendorWorkTable />,
    layout: "/vendor",
  },
  {
    path: "/staffpropertydetail/:rental_id",
    name: "Property",
    component: <PropDetails />,
    layout: "/staff",
  },

  {
    path: "/staffupcomingrenewal",
    name: "Upcoming Renewal",
    icon: Upcomingicon,
    icon2: Upcomingicon1,
    component: <UpcomingRenewal />,
    layout: "/staff",
  },

  {
    name: "Scheduled Payments",
    path: "/scheduledpayments",
    component: <ScheduledPayment />,
    layout: "/admin",
  },
  {
    name: "Scheduled Payments",
    path: "/staffscheduledpayments",
    component: <ScheduledPayment />,
    layout: "/staff",
  },

  {
    name: "RentRoll Report",
    path: "/rentrollreport",
    component: <RentRollReport />,
    layout: "/admin",
  },
  {
    name: "RentRoll Report",
    path: "/staffrentrollreport",
    component: <RentRollReport />,
    layout: "/staff",
  },

  {
    name: "Applicants",
    path: "/staffapplicanttable",
    icon: Applicant,
    icon2: Applicant1,
    component: <Applicants />,
    layout: "/staff",
  },
  {
    name: "Vendor",
    path: "/staffvendor",
    icon: Vendors,
    icon2: Vendors1,
    component: <Vendor />,
    layout: "/staff",
  },
  {
    path: "/staffaddvendor",
    name: "Staff Add Vendor",
    component: <AddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffaddvendor/:vendor_id",
    name: "Staff Add Vendor",
    component: <AddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffworktable",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <Workorder />,
    layout: "/staff",
  },
  {
    path: "/staffworktable?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <Workorder />,
    layout: "/staff",
  },

  {
    path: "/StaffReports",
    name: "Reports",
    icon: Report,
    icon2: Report1,
    component: <Reports />,
    layout: "/staff",
  },
  {
    name: "Staff Renters Insurance",
    path: "/staffrentersinsurance",
    component: <RentersInsurance />,
    layout: "/staff",
  },
  {
    name: "Staff Delinquent Tenants",
    path: "/staffdelinquenttenants",
    component: <DelinquentTenants />,
    layout: "/staff",
  },
  {
    name: "Staff Expiring Lease",
    path: "/staffExpiringLeases",
    component: <ExpiringLease />,
    layout: "/staff",
  },
  {
    name: "Staff Expiring Insurance",
    path: "/Staffexpiringinsurances",
    component: <ExpiringRenterInsurancesReport />,
    layout: "/staff",
  },
  {
    name: "Staff Open Work Orders",
    path: "/staffopenworkorders",
    component: <OpenWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Completed Work Orders",
    path: "/staffcompletedworkorders",
    component: <CompletedWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Daily Transaction Report",
    path: "/staffdailytransactionreport",
    component: <Dailytransactionreport />,
    layout: "/staff",
  },
  {
    name: "Staff Rental Owner Report",
    path: "/staffrentalownerreport",
    component: <Rentalownerreport />,
    layout: "/staff",
  },
  {
    path: "/vendorworktable?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <VendoeWorkTable />,
    layout: "/vendor",
  },

  {
    path: "/addvendor",
    component: <AddVendor />,
    layout: "/admin",
  },
  {
    path: "/vendor",
    name: "Vendor",
    component: <Vendor />,
    layout: "/admin",
  },
  {
    path: "/Workorder",
    name: "Work Order",
    component: <Workorder />,
    layout: "/admin",
  },
  {
    path: "/addvendor/:vendor_id",
    component: <AddVendor />,
    layout: "/admin",
  },
  {
    path: "/addworkorder/:id",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    path: "/addworkorder/addtask/:rental_id",
    component: <AddWorkorder />,
    layout: "/admin",
  },
  {
    path: "/rentals/:rental_id",
    name: "Add Property",
    icon: "ni ni-pin-3 text-orange",
    component: <Rentals />,
    layout: "/admin",
  },
  {
    path: "/AddPropertyType/:id",
    name: "Add Property",
    component: <AddPropertyType />,
    layout: "/admin",
  },
  {
    path: "/AddStaffMember/:id",
    name: "Add Staff Member",
    component: <AddStaffMember />,
    layout: "/admin",
  },
  {
    path: "/AddAgent/:id",
    icon: "ni ni-single-02 text-green",
    component: <AddAgent />,
    layout: "/admin",
  },
  {
    path: "/AddStaffMember/:id",
    name: "Add Staff Member",
    component: <AddStaffMember />,
    layout: "/admin",
  },
  {
    path: "/AddAgent/:id",
    icon: "ni ni-single-02 text-green",
    component: <AddAgent />,
    layout: "/admin",
  },
  {
    path: "/addgeneralledger",
    component: <AddGeneralLedger />,
    layout: "/admin",
  },
  {
    path: "/tworkorderdetail/:id",
    name: "Work Order Detail",
    component: <TWorkOrderDetails />,
    layout: "/tenant",
  },
  {
    path: "/workorderdetail/:workorder_id",
    name: "Work Order Detail",
    component: <WorkOrderDetails />,
    layout: "/admin",
  },
  {
    path: "/addworkorder/:id",
    component: <AddWorkorder />,
    layout: "/staff",
  },
  {
    path: "/addworkorder",
    component: <AddWorkorder />,
    layout: "/staff",
  },
  {
    path: "/staffworkdetails/:workorder_id",
    name: "Work Order Detail",
    component: <WorkOrderDetails />,
    layout: "/staff",
  },
  {
    path: "/tworkorderdetail/:id",
    name: "Work Order Detail",
    component: <TWorkOrderDetails />,
    layout: "/tenant",
  },
  {
    path: "/vendorworkdetail/:workorder_id",
    name: "Work Order",
    icon: "ni ni-badge text-green",
    component: <VendorWorkDetail />,
    layout: "/vendor",
  },
  {
    path: "/vendoraddwork/:id",
    component: <VendorAddWork />,
    layout: "/vendor",
  },
  {
    path: "/Applicants/:id",
    name: "applicants",
    icon: "ni ni-home-3 text-orange",
    component: <ApplicantSummary />,
    layout: "/admin",
  },
  {
    path: "/tenantdetail/:tenantId/:entryIndex",
    name: "Tenant Detail",
    component: <TenantDetailPage />,
    layout: "/admin",
  },
  {
    path: "/tenantpropertydetail/:lease_id",
    name: "Tenant Property Detail",
    component: <TenantPropertyDetail />,
    layout: "/tenant",
  },

  {
    path: "/Payment",
    component: <Payment />,
    layout: "/admin",
  },
  {
    path: "/AddPayment/:lease_id",
    component: <AddPayment />,
    layout: "/admin",
  },
  {
    path: "/AddPayment/:lease_id/:payment_id",
    component: <AddPayment />,
    layout: "/admin",
  },
  {
    path: "/AddCharge/:lease_id",
    component: <AddCharge />,
    layout: "/admin",
  },
  {
    path: "/AddCharge/:lease_id/:charge_id",
    component: <AddCharge />,
    layout: "/admin",
  },
  {
    path: "/TenantPayment",
    component: <TenantAddPayment />,
    layout: "/tenant",
  },
  {
    path: "/TenantPayment/:payment_id",
    component: <TenantAddPayment />,
    layout: "/tenant",
  },
  {
    path: "/trial-login",
    component: <TrialLogin />,
    layout: "/trial",
  },
  {
    path: "/add_surcharge",
    component: <SurchargeForm />,
    layout: "/admin",
  },
  {
    path: "/add_surcharge/:surcharge_id",
    component: <SurchargeForm />,
    layout: "/admin",
  },
  {
    path: "/surcharge",
    component: <SurchargeTable />,
    layout: "/admin",
  },
  {
    path: "/Reports",
    name: "Reports",
    component: <Reports />,
    layout: "/admin",
  },
  {
    path: "/Userrole",
    name: "Userrole",
    component: <Userrole />,
    layout: "/admin",
  },
  {
    path: "/upcomingrenewal",
    name: "Upcoming Renewal",
    component: <UpcomingRenewal />,
    layout: "/admin",
  },

  // ==========================  Super Admin ===================================================

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <SuperAdminDashBoard />,
    layout: "/superadmin",
  },

  {
    path: "/changesecuritykey",
    name: "change security key",
    component: <ChangeSecurityKey />,
    layout: "/superadmin",
  },

  {
    path: "/plans",
    name: "Plans",
    icon: Plan,
    icon2: Plan2,
    component: <SuperAdminPlanList />,
    layout: "/superadmin",
  },
  {
    path: "/addplan",
    name: "Addplans",
    component: <SuperAdminAddplan />,
    layout: "/superadmin",
  },
  {
    path: "/addplan/:id",
    name: "Addplans",
    component: <SuperAdminAddplan />,
    layout: "/superadmin",
  },
  {
    path: "/Planpurchases",
    name: "Purchase Plan",
    component: <Planpurches />,
    layout: "/admin",
  },
  {
    path: "/planUpgrade",
    name: "Plan Upgrade",
    component: <PlanUpgrade />,
    layout: "/admin",
  },
  {
    path: "/companies",
    name: "Companies",
    icon: Admin,
    icon2: admin2,
    component: <SuperAdminAdmin />,
    layout: "/superadmin",
  },
  {
    path: "/propertytype/:admin_id",
    name: "Add Property Type",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminPropertyType />,
    layout: "/superadmin",
  },
  {
    path: "/staffmember/:admin_id",
    name: "Staff-Member",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminStaffMember />,
    layout: "/superadmin",
  },
  {
    path: "/properties/:admin_id",
    name: "Properties",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminProperties />,
    layout: "/superadmin",
  },
  {
    path: "/rental-owner/:admin_id",
    name: "Properties",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminRentalOwner />,
    layout: "/superadmin",
  },
  {
    path: "/tenant/:admin_id",
    name: "Tenant",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminTenat />,
    layout: "/superadmin",
  },
  {
    path: "/unit/:admin_id",
    name: "Unit",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminUnit />,
    layout: "/superadmin",
  },
  {
    path: "/lease/:admin_id",
    name: "Unit",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminLease />,
    layout: "/superadmin",
  },
  {
    path: "/vendor/:admin_id",
    name: "Vendor",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminVendor />,
    layout: "/superadmin",
  },
  {
    path: "/addemail",
    name: "Email Form",
    icon: "ni ni-pin-3 text-orange",
    component: <SuperAdminEmailForm />,
    layout: "/superadmin",
  },
  {
    path: "/email",
    name: "Mail Service",
    icon: Email1,
    icon2: Email2,
    component: <SuperAdminEmailTable />,
    layout: "/superadmin",
  },
  {
    path: "/activities",
    name: "Reports",
    component: <AuditLogs />,
    layout: "/admin",
  },
  {
    path: "/due-rent-report",
    name: "Reports",
    component: <DueRentReport />,
    layout: "/admin",
  },
  {
    path: "/paymentexceptionreport",
    name: "Payment Exception Report",
    component: <PaymentReport />,
    layout: "/admin",
  },
  {
    name: "Staff Payment Exception Report",
    path: "/staffpaymentexceptionreport",
    component: <PaymentReport />,
    layout: "/staff",
  },
  {
    path: "/recurringpaymentsconfiguration",
    name: "Recurring Payments Configuration",
    component: <RecurringReport />,
    layout: "/admin",
  },
  {
    name: "Staff Recurring Payments Configuration",
    path: "/staffrecurringpaymentsconfiguration",
    component: <RecurringReport />,
    layout: "/staff",
  },
  {
    path: "/conveniencefeeoverride",
    name: "Convenience Fee Override",
    component: <ConvenienceFeeOverrideReport />,
    layout: "/admin",
  },
  {
    name: "Staff Convenience Fee Override",
    path: "/staffconveniencefeeoverride",
    component: <ConvenienceFeeOverrideReport />,
    layout: "/staff",
  },
];

export default routes;
