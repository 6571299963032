import { jwtDecode } from "jwt-decode";
import React, { useEffect } from 'react'
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AuthCheckAdmin() {
	const [admin, setAdmin] = useState('')
	let navigate = useNavigate();

	const checkAdminExist = async () => {
		try {
			if (localStorage.getItem("token")) {
				const jwt = jwtDecode(localStorage.getItem("token"));
				if (jwt.company_name) {
					setAdmin(jwt.company_name)
					navigate(`/${jwt.company_name}/index`)
				} else {
					navigate('/auth/login')
				}
			} else {
				navigate('/auth/login')
			}

		} catch (error) {
			console.error("error in acheck admin exist", error)
		}
	}

	useEffect(() => {
		checkAdminExist()
	}, [])

	return (
		<div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>Please Wait...</div>
	)
}

export default AuthCheckAdmin