import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Media,
  FormGroup,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useCookies } from "react-cookie";
import notify from "../../assets/icons/notify.svg";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axiosInstance from "axiosInstance";
import Notificationmodal from "../Notificationmodal";
import { verifyToken } from "components/Functions/Functions";
import Loader from "components/ReusableComponents/Loader";
import { handleLogout } from "plugins/helpers";

const VendorNavbar = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();

  const [selectedProp, setSelectedProp] = useState("Select");

  const handlePropertySelect = (property) => {
    setSelectedProp(property);
  };

  const [loader, setLoader] = useState(true);
  const [accessType, setAccessType] = useState(null);
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
      setLoader(false);
    };
    fetchData();
  }, []);

  const Logout = () => {
    handleLogout(cookies, removeCookie);
  };

  const [vendorNotification, setVendorNotificationData] = useState([]);
  const vendorNotificationData = async () => {
    if (accessType?.vendor_id) {
      try {
        const response = await axiosInstance.get(
          `/notification/vendor/${accessType?.vendor_id}`
        );
        if (response.status === 200) {
          const data = response.data.data;
          setVendorNotificationData(data);
          // Process the data as needed
        } else {
          console.error("Response status is not 200");
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle the error, display a message to the user, or take other appropriate action.
      }
    }
  };

  useEffect(() => {
    vendorNotificationData();
  }, [accessType?.vendor_id]);

  const readStaffmemberNotification = async (notification_id) => {
    try {
      const response = await axiosInstance.put(
        `/notification/vendor_notification/${notification_id}`
      );
      if (response.status === 200) {
        vendorNotificationData();
      } else {
        console.error("Response status is not 200");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Navbar
        className="navbar-top navbar-dark px-md-5 px-3"
        id="navbar-main"
        style={{
          backgroundColor: isMediumScreen && "rgba(249, 250, 253, 1)",
          boxShadow: isMediumScreen && "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          className="h4 d-none d-lg-inline-block"
          style={{
            color: "rgba(82, 84, 89, 1)",
            fontFamily: "Manrope",
            fontSize: "18px",
            fontWeight: "400",
          }}
        >
          Hello {accessType?.vendor_name}, Welcome Back!
        </div>

        <Form className="navbar-search navbar-search-dark form-inline mr-5 d-none d-lg-flex ml-lg-auto">
          <FormGroup
            className="mb-0"
            onClick={toggleSidebar}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {vendorNotification?.length === 0 ? (
              <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
            ) : (
              <img src={notify} width={30} height={30} />
            )}
          </FormGroup>
        </Form>

        <Nav className="align-items-center d-none d-lg-flex" navbar>
          <Drawer
            className="adminnavv"
            anchor="right"
            open={isSidebarOpen}
            onClose={toggleSidebar}
          >
            <div
              role="presentation"
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
            >
              <List className="notificationtextbar" style={{ width: "350px" }}>
                <Divider />
                <div>
                  <h2
                    className="tenantnamefontsty"
                    style={{
                      color: "#fff",
                      // borderTopLeftRadius: "7px",
                      // borderBottomLeftRadius: "10px",
                      marginLeft: "15px",
                      backgroundColor: "#152B51",
                      borderRadius: "10px, 0px, 0px, 10px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      marginTop: "-9px",
                      padding: "18px",
                      marginLeft: "0",
                    }}
                  >
                    Notifications
                    <i
                      style={{ marginLeft: "150px", cursor: "pointer" }}
                      className="fa-solid fa-xmark"
                      onClick={toggleSidebar}
                    />
                  </h2>
                </div>
                {vendorNotification?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    No Notification
                  </div>
                ) : (
                  <>
                    {vendorNotification.map((data) => {
                      const notificationTitle =
                        data.notification_title || "No Title Available";
                      const notificationDetails =
                        data.notification_detail || "No Details Available";
                      const notificationTime = new Date(
                        data.createdAt
                      ).toLocaleString();

                      return (
                        <div key={data._id}>
                          <ListItem onClick={() => handlePropertySelect(data)}>
                            <div>
                              <h4
                                className="barforsummaryrent"
                                style={{
                                  color: "#152B51",
                                  fontSize: "20px",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                }}
                              >
                                {" "}
                                {notificationTitle}
                              </h4>
                              <p
                                className="notificationtitle titleecolor"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                }}
                              >
                                {notificationDetails}
                              </p>
                              <Row>
                                <Col lg="8">
                                  <p
                                    className="fontstylerentr"
                                    style={{
                                      color: "#152B51",
                                      fontSize: "14px",
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {notificationTime}
                                  </p>
                                </Col>
                                <Col>
                                  <Button
                                    className="fontstylerentmodal"
                                    variant="contained"
                                    // color="primary"
                                    style={{
                                      background: "#152B51",
                                      color: "white",
                                      textTransform: "none",
                                      fontSize: "12px",
                                      fontFamily: "Poppins",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      readStaffmemberNotification(
                                        data?.notification_id
                                      );
                                      navigate(
                                        `/vendor/vendorworkdetail/${data?.notification_type?.workorder_id}`
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    })}
                  </>
                )}
              </List>
              <Divider />
              {/* Other sidebar content goes here */}
            </div>
          </Drawer>
        </Nav>

        <Nav className="align-items-center d-none d-lg-flex" navbar>
          <UncontrolledDropdown
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
            }}
          >
            <DropdownToggle
              className="px-4"
              style={{
                border: "none",
                background: "rgba(54, 159, 255, 0.1)",
                boxShadow: "none",
              }}
            >
              <Media className="align-items-center">
                <span
                  className="d-flex justify-content-center align-items-center p-1"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "rgba(21, 43, 81, 1)",
                    borderRadius: "12px",
                    color: "#fff",
                  }}
                >
                  {loader ? (
                    <Loader color="#ffffff" />
                  ) : (
                    `${accessType?.vendor_name
                      ?.split(" ")
                      .map((word) => word.charAt(0))
                      .join("")}`
                  )}
                </span>
                <Media className="ml-3 d-none d-lg-flex flex-column mx-1">
                  <span
                    className="mb-0 font-weight-bold text-dark"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Manrope",
                    }}
                  >
                    {accessType?.vendor_name || ""}
                  </span>
                  <span
                    className="mb-0 font-weight-bold"
                    style={{
                      fontSize: "12px",
                      fontFamily: "Manrope",
                      color: "rgba(21, 43, 81, 1)",
                    }}
                  >
                    Vendor
                  </span>
                </Media>
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  <i className="fa-solid fa-angle-down"></i>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow w-100" right>
              <DropdownItem className="noti-title w-100" header tag="div">
                <h6
                  className="text-overflow m-0"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  Welcome
                </h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/vendor/VendorPasswordChange"
                tag={Link}
              >
                <i className="ni ni-key-25" />
                <span>Change Password</span>
              </DropdownItem>
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/auth/login"
                onClick={() => {
                  Logout();
                }}
                tag={Link}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <div className="d-flex justify-content-center d-lg-none align-items-center">
          <FormGroup
            className="mb-1 menub"
            onClick={props.toggleOpenSidebar}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <MenuIcon className="menub" style={{ width: 40, height: 40 }} />
          </FormGroup>
          <FormGroup
            className="mb-1 ml-3"
            onClick={() => navigate(`/vendor/VendordashBoard`)}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <img src={props.logo} width={50} height={40} />
          </FormGroup>
        </div>
        <div className="d-flex justify-content-center d-lg-none align-items-center">
          <FormGroup
            className="mb-1"
            onClick={handleClick}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {vendorNotification?.length === 0 ? (
              <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
            ) : (
              <img src={notify} width={30} height={30} />
            )}
          </FormGroup>
          <UncontrolledDropdown
            style={{ border: "none", background: "none", boxShadow: "none" }}
          >
            <DropdownToggle
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              className="m-0 p-0 ml-3"
            >
              <Media className="align-items-center">
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "12px",
                    background: "#152B51",
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  {/* {`${accessType?.vendor_name
                    ?.slice(0, 1)
                    .toUpperCase()}${accessType?.vendor_name
                      ?.slice(1, 2)
                      .toUpperCase()}`} */}
                  {loader ? (
                    <Loader color="#ffffff" />
                  ) : (
                    `${accessType?.vendor_name
                      ?.split(" ")
                      .map((word) => word.charAt(0))
                      .join("")}`
                  )}
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title w-100" header tag="div">
                <h6
                  className="text-overflow m-0"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  Welcome
                </h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/auth/login"
                onClick={() => {
                  Logout();
                }}
                tag={Link}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "400px", borderRadius: "20px" }}
        >
          <ModalHeader
            toggle={closeModal}
            className=" ml-2 mr-3 mt-2 p-0 pl-3 pr-3 pt-3"
            // style={{
            //   borderRadius: "6px",
            //   height: "45px",
            //   boxShadow: " 0px 4px 4px 0px #00000040 ",
            //   padding: "10px 7px 1px 21px",
            //   color: "white",
            // }}
          >
            <h5
              style={{
                fontSize: "18px",
                fontweight: "500",
                fontFamily: "Poppins",
                color: "#152B51",
              }}
            >
              Notification
            </h5>
          </ModalHeader>
          <ModalBody>
            <Notificationmodal
              notifications={vendorNotification}
              handlePropertySelect={handlePropertySelect}
              readNotification={readStaffmemberNotification}
              role={`vendor`}
              closeModal={closeModal}
            />
          </ModalBody>
        </Modal>
      </Navbar>
    </>
  );
};

export default VendorNavbar;
