import {
  Button,
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Header from "components/Headers/Header";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Staffdashboard.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const StaffMember = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { admin } = useParams();
  let [StaffMemberData, setStaffMemberData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  let [loader, setLoader] = React.useState(true);
  let navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const getStaffMemberData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/staffmember/staff_members/${accessType?.admin_id}`
        );
        setLoader(false);
        setStaffMemberData(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / pageItem));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [countRes, setCountRes] = useState("");
  const getStaffLimit = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/staffmember/limitation/${accessType?.admin_id}`
        );
        setCountRes(response.data);
      } catch (error) {
        console.error("Error fetching rental data:", error);
      }
    }
  };

  const [deletionReason, setDeletionReason] = useState("");

  // Delete selected
  const deleteStaffMember = (staff) => {
    // swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this staff member!",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     axiosInstance
    //       .delete(`/staffmember/staff_member/${staff.staffmember_id}`,{
    //         params: {
    //           company_name: admin,
    //           staffmember_email: staff.staffmember_email
    //         }
    //       })
    //       .then((response) => {
    //         if (response.data.statusCode === 200) {
    //           toast.success("Staff Member Deleted Successfully!", {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //           getStaffMemberData();
    //           getStaffLimit();
    //         } else if (response.data.statusCode === 201) {
    //           toast.warning("Staff Member already assigned to work order!", {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //           getStaffMemberData();
    //         } else if (response.data.statusCode === 202) {
    //           toast.warning("Staff Member already assigned to property", {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //           getStaffMemberData();
    //         } else {
    //           toast.error(response.data.message, {
    //             position: "top-center",
    //             autoClose: 1000,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error deleting:", error);
    //       });
    //   } else {
    //     toast.success("Staff Member is safe :)", {
    //       position: "top-center",
    //       autoClose: 1000,
    //     });
    //   }
    // });
    let deletereason = "";
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this staff member!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            setDeletionReason(reason);
            deletereason = reason;

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance
          .delete(`/staffmember/staff_member/${staff.staffmember_id}`, {
            params: {
              company_name: admin,
              staffmember_email: staff.staffmember_email,
            },
            data: {
              reason: deletereason,
            },
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              toast.success("Staff Member Deleted Successfully!", {
                position: "top-center",
                autoClose: 1000,
              });
              getStaffMemberData();
              getStaffLimit();
            } else if (response.data.statusCode === 201) {
              toast.warning("Staff Member already assigned to work order!", {
                position: "top-center",
                autoClose: 1000,
              });
              getStaffMemberData();
            } else if (response.data.statusCode === 202) {
              toast.warning("Staff Member already assigned to property", {
                position: "top-center",
                autoClose: 1000,
              });
              getStaffMemberData();
            } else {
              toast.error(response.data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            console.error("Error deleting:", error);
          });
      } else {
        toast.success("Staff Member is safe :)", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });
    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  useEffect(() => {
    getStaffMemberData();
    getStaffLimit();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (StaffMemberData) {
    paginatedData = StaffMemberData.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editStaffMember = (id) => {
    navigate(`/${admin}/AddStaffMember/${id}`);
  };

  const filterTenantsBySearch = () => {
    let filteredData = StaffMemberData;

    if (searchQuery) {
      filteredData = filteredData.filter((staff) => {
        const isNameMatch = staff.staffmember_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isDesignationMatch = staff.staffmember_designation
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isContactMatch = staff.staffmember_phoneNumber?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isMailMatch = staff.staffmember_email
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isCreatedAtMatch = staff?.createdAt
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isUpdatedAtpeMatch = staff?.updatedAt
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());

        return isNameMatch || isDesignationMatch || isMailMatch || isContactMatch || isCreatedAtMatch || isUpdatedAtpeMatch;
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow.length > 0 ? upArrow : null;

      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "staffmember_name":
            filteredData.sort((a, b) => {
              const comparison = a.staffmember_name?.localeCompare(
                b.staffmember_name
              );
              return upArrow.includes("staffmember_name")
                ? comparison
                : -comparison;
            });
            break;
          case "staffmember_designation":
            filteredData.sort((a, b) => {
              const comparison = a.staffmember_designation?.localeCompare(
                b.staffmember_designation
              );
              return upArrow.includes("staffmember_designation")
                ? comparison
                : -comparison;
            });
            break;
          case "createdAt":
            filteredData.sort((a, b) => {
              const comparison = new Date(a.createdAt) - new Date(b.createdAt);
              return upArrow.includes("createdAt") ? comparison : -comparison;
            });
            break;
            case "staffmember_phoneNumber":
              filteredData.sort((a, b) => {
                const phoneA = a.staffmember_phoneNumber || "";
                const phoneB = b.staffmember_phoneNumber || "";
                const comparison = phoneA.localeCompare(phoneB);
                return upArrow.includes("staffmember_phoneNumber") ? comparison : -comparison;
              });
              break;
          case "staffmember_email":
            filteredData.sort((a, b) => {
              const comparison = a.staffmember_email?.localeCompare(
                b.staffmember_email
              );
              return upArrow.includes("staffmember_email")
                ? comparison
                : -comparison;
            });
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
  };

  useEffect(() => {
    getStaffMemberData();
  }, [upArrow, sortBy]);

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  
    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };
  
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12" className="text-right">
            <Button
              className="btnaddstaff background-colorsty bgtextwhite fontfamilysty"
              // color="primary"
              //  href="#rms"
              onClick={() => {
                // if (countRes.statusCode === 201) {
                //   swal(
                //     "Plan Limitation",
                //     "The limit for adding staff members according to the plan has been reached.",
                //     "warning"
                //   );
                // } else {
                  navigate("/" + admin + "/AddStaffMember");
                // }
              }}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Add Staff Member
            </Button>
          </Col>
          <Col xs="12" lg="12" sm="12">
            {/* <FormGroup className="">
              <h1 style={{ color: "white" }}>Property Type</h1>
            </FormGroup> */}
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Staff Member
              </h2>
            </CardHeader>
          </Col>
        </Row>

        <br />
        {/* Table */}
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="mb-3">
                  <Col xs="4" sm="4">
                    <FormGroup className="searchstyle">
                      <Input
                        className="filterh titleecolor"
                        // fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: "0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <FormGroup className="addtotal">
                      <p
                        className="addedtotalstyle fontaddstyle fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Added :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.rentalCount}
                        </b>{" "}
                        {/* {" / "}
                        Total :{" "}
                        <b
                          className="textcolorblue"
                          style={{ fontWeight: 1000 }}
                        >
                          {countRes.staffCountLimit}
                        </b> */}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}

                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        NAME
                        {sortBy.includes("staffmember_name") ? (
                          upArrow.includes("staffmember_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_name")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        DESIGNATION
                        {sortBy.includes("staffmember_designation") ? (
                          upArrow.includes("staffmember_designation") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_designation")
                              }
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("staffmember_designation")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_designation")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Contact
                        {sortBy.includes("staffmember_phoneNumber") ? (
                          upArrow.includes("staffmember_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_phoneNumber")
                              }
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() =>
                                sortData("staffmember_phoneNumber")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_phoneNumber")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Mail Id
                        {sortBy.includes("staffmember_email") ? (
                          upArrow.includes("staffmember_email") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_email")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("staffmember_email")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_email")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Created at
                        {sortBy.includes("createdAt") ? (
                          upArrow.includes("createdAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            // tablebodyfont
                            onClick={() => sortData("createdAt")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Updated at
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  {StaffMemberData?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No StaffMembers Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((staff) => (
                        <tr
                          key={staff._id}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {staff.staffmember_name}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {staff.staffmember_designation}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {staff?.staffmember_phoneNumber
                              ? formatPhoneNumber(
                                staff.staffmember_phoneNumber
                                )
                              : ""}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {staff.staffmember_email}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(staff?.createdAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(staff?.updatedAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont">
                            <div style={{ display: "flex", gap: "5px" }}>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  staff.admin_id === "is_trial"
                                    ? freetrialData()
                                    : deleteStaffMember(staff)
                                }
                              >
                                <img src={deleicon} width={20} height={20} />
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  staff.admin_id === "is_trial"
                                    ? freetrialData()
                                    : editStaffMember(staff.staffmember_id)
                                }
                              >
                                <img src={editicon} width={20} height={20} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {/* <Row
                  className="mx-4 mt-3 d-flex align-items-center py-1"
                  style={{ borderRadius: "10px", height: "auto" }}
                >
                  <Col>
                    <Row
                      className="d-flex align-items-center"
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        height: "45px",
                        fontSize: "14px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        boxShadow: " 0px 4px 4px 0px #00000040",
                      }}
                    >
                      <Col style={{ color: "#152B51" }}>


                        Name
                        {sortBy.includes("staffmember_name") ? (
                          upArrow.includes("staffmember_name") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_name")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_name")}
                          />
                        )}
                      </Col>
                      <Col style={{ color: "#152B51" }}>


                        Designation
                        {sortBy.includes("staffmember_designation") ? (
                          upArrow.includes("staffmember_designation") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_designation")
                              }
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_designation")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_designation")}
                          />
                        )}
                      </Col>

                      <Col style={{ color: "#152B51" }}>

                        Contact
                        {sortBy.includes("staffmember_phoneNumber") ? (
                          upArrow.includes("staffmember_phoneNumber") ? (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_phoneNumber")
                              }
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() =>
                                sortData("staffmember_phoneNumber")
                              }
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_phoneNumber")}
                          />
                        )}
                      </Col>
                      <Col style={{ color: "#152B51" }}>
                        Mail Id
                        {sortBy.includes("staffmember_email") ? (
                          upArrow.includes("staffmember_email") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_email")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("staffmember_email")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("staffmember_email")}
                          />
                        )}
                      </Col>
                      <Col style={{ color: "#152B51" }}>

                        Created at
                        {sortBy.includes("createdAt") ? (
                          upArrow.includes("createdAt") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          ) : (
                            <ArrowDropUpIcon
                              onClick={() => sortData("createdAt")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("createdAt")}
                          />
                        )}
                      </Col>
                      <Col style={{ color: "#152B51" }}>
                        Updated At{" "}

                      </Col>
                      <Col style={{ color: "#152B51" }}>
                        Action{" "}

                      </Col>
                    </Row>
                    {StaffMemberData.length === 0 ? (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="8" style={{ fontSize: "15px" }}>
                            No StaffMembers Added
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <Row
                        className="mt-3"
                        style={{
                          border: "0.5px solid rgba(50, 69, 103, 1)",
                          borderBottomLeftRadius: "12px",
                          borderBottomRightRadius: "12px",
                          overflow: "hidden",
                          fontSize: "16px",
                          fontWeight: "600",
                          // lineHeight: "19.12px",
                        }}
                      >
                        <Col>
                        {filterTenantsBySearchAndPage().map((staff) => (
                            <Row
                              key={staff._id}
                              className="d-flex align-items-center"
                              // onClick={() => navigateToDetails(vendor?.workOrder_id)}

                              style={{
                                cursor: "pointer",
                                border: "0.5px solid rgba(50, 69, 103, 1)",
                                fontSize: "12px",
                                height: "40px",
                                fontFamily: "poppins",
                                fontWeight: "600",
                              }}
                            >
                              <Col style={{ color: "#152B51" }}>{staff?.staffmember_name} </Col>
                              <Col style={{ color: "#152B51" }}>{staff?.staffmember_designation}</Col>
                              <Col style={{ color: "#152B51" }}>{staff?.staffmember_phoneNumber}
                              </Col>
                              <Col style={{ color: "#152B51" }}>
                              {staff?.staffmember_email}
                              </Col>
                              <Col style={{ color: "#152B51" }}>
                              {staff?.createdAt}
                              </Col>
                              <Col style={{ color: "#152B51" }}>
                              {staff?.updatedAt ? staff?.updatedAt : "-"}
                              </Col>
                              <Col>  <div style={{ display: "flex" }}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    deleteStaffMember(staff?.staffmember_id)
                                  }
                                >
                                  <img src={deleicon} width={20} height={20} />

                                </div>
                                &nbsp; &nbsp; &nbsp;
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    editStaffMember(staff?.staffmember_id)
                                  }
                                >
                                  <img src={editicon} width={20} height={20} />

                                </div>
                              </div></Col>
                            </Row>
                          )
                          )}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row> */}
                {paginatedData.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default StaffMember;
