import React, { useEffect, useState } from "react";
import { CardContent, IconButton, Typography } from "@mui/material";
import { Col, Row, Card } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "axiosInstance";
import Loader from "components/ReusableComponents/Loader";
import { getCardLogo, getCardType } from "plugins/ApiHandler";
import {} from "plugins/ApiHandler";
import AddCardForm from "../../components/Payments/AddCardForm";
import { getCustomerVaultId } from "plugins/ApiHandler";
import { addCardDetails } from "plugins/ApiHandler";
import { FaTimes } from "react-icons/fa";
import DeleteIcon from "@mui/icons-material/Delete";
import CardTemplate from "components/Payments/CardTemplate";
import swal from 'sweetalert';
import { useDispatch } from "react-redux";
import { getLeasDetailsForAdmin } from "Stores/ApiHandler";

function AddTenantCreditCardByAdmin(props) {
  const { admin_id, tenantId, tenantName, lease_id } = props;

  const [getCardLoading, setGetCardLoading] = useState(false);
  const [getCardError, setGetcardError] = useState("");
  const [cardDetalis, setCardDetails] = useState([]);
  const [customervault, setCustomervault] = useState([]);

  const dispatch = useDispatch();

  const getCardsData = async () => {
    setGetCardLoading(true);
    setCardDetails([]);
    setGetcardError("");

    try {
      const response = await getCustomerVaultId(tenantId);
      setCustomervault(response);
      const cards = response?.card_detail;
      if (!cards || cards?.length === 0) throw "No card found";

      await getMultipleCustomerVault(response.customer_vault_id);
    } catch (error) {
      setGetcardError(error);
    } finally {
      setGetCardLoading(false);
    }
  };

  const getMultipleCustomerVault = async (customer_vault_id) => {
    setCardDetails([]);
    try {
      const response = await axiosInstance.post(
        `/nmipayment/get-billing-customer-vault`,
        { customer_vault_id, admin_id }
      );

      // Check if customer.billing is an array
      let billingArray = [];
      const billingData = response.data?.data?.customer;
      if (Array.isArray(billingData?.billing))
        billingArray = billingData?.billing;
      else billingArray.push(billingData.billing);

      const extractedData = billingArray?.map((item) => ({
        billing_id: item["@attributes"].id,
        cardholderName: `${item.first_name} ${item.last_name}`,
        first_name: item.first_name,
        last_name: item.last_name,
        cc_number: item.cc_number,
        cc_exp: item.cc_exp,
        cc_type: item.cc_type,
        cc_bin: item.cc_bin,
        customer_vault_id: item.customer_vault_id,
      }));

      for (const card of extractedData) {
        try {
          const cardType = await getCardType(card.cc_bin);
          card.card_type = cardType;
          const cardLogo = await getCardLogo(card.cc_type);
          card.card_logo = cardLogo;
        } catch (error) {
          throw error;
        }
      }
      setCardDetails(extractedData);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getCardsData();
  }, []);

  const addCardInfo = async (data) => {
    data.tenant_id = tenantId;
    data.admin_id = admin_id;
    try {
      await addCardDetails(data);
      props.onHide();
      props.getCreditCard();
      toast.success("Card details added successfully", {
        position: "top-center",
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  // const handleDeleteCard = async (customerVaultId, billingId) => {
  //   try {
  //     let deleteRecordResponse, deleteNMIResponse;

  //     if (customervault?.card_detail?.length > 1) {
  //       // If billingId is provided, call the new APIs
  //       [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
  //         axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`),
  //         axiosInstance.post(`/nmipayment/delete-customer-billing`, {
  //           customer_vault_id: customerVaultId,
  //           billing_id: billingId,
  //           admin_id: admin_id,
  //           tenant_id: tenantName,
  //         }),
  //       ]);
  //     } else {
  //       // If billingId is not provided, call the existing API
  //       [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
  //         axiosInstance.delete(
  //           `/creditcard/deleteCardVault/${customerVaultId}`
  //         ),
  //         axiosInstance.post(`/nmipayment/delete-customer-vault`, {
  //           customer_vault_id: customerVaultId,
  //           admin_id: admin_id,
  //           tenant_id: tenantName,
  //         }),
  //       ]);
  //     }

  //     // Check the responses
  //     if (
  //       deleteRecordResponse.status === 200 &&
  //       deleteNMIResponse.status === 200
  //     ) {
  //       getCardsData();
  //       toast.success("Card deleted successfully", {
  //         position: "top-center",
  //         autoClose: 500,
  //       });
  //       props.onHide();
  //     } else {
  //       toast.error("Card deletion failed", {
  //         position: "top-center",
  //         autoClose: 500,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Error deleting card", {
  //       position: "top-center",
  //       autoClose: 500,
  //     });
  //   }
  // };

  const handleDeleteCard = async (customerVaultId, billingId) => {
    try {
      // Show SweetAlert confirmation before proceeding
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this card!",
        icon: "warning",
        // buttons: true,
        buttons: {
          cancel: {
            text: "Delete",
            value: true,
            visible: true,
            className: "btn-danger",
          },
          confirm: {
            text: "Cancel",
            value: false,
            visible: true,
            className: "btn-secondary",
          },
        },
        dangerMode: true,
      });
  
      // If the user confirms, proceed with the deletion
      if (willDelete) {
        let deleteRecordResponse, deleteNMIResponse;
  
        if (customervault?.card_detail?.length > 1) {
          // If billingId is provided, call the new APIs
          [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
            axiosInstance.delete(`/creditcard/deleteCreditCard/${billingId}`,{data:{tenant_id:tenantId}}),
            axiosInstance.post(`/nmipayment/delete-customer-billing`, {
              customer_vault_id: customerVaultId,
              billing_id: billingId,
              admin_id: admin_id,
              tenant_id: tenantName,
            }),
          ]);
        } else {
          // If billingId is not provided, call the existing API
          [deleteRecordResponse, deleteNMIResponse] = await Promise.all([
            axiosInstance.delete(
              `/creditcard/deleteCardVault/${customerVaultId}`,{data:{tenant_id:tenantId}}
            ),
            axiosInstance.post(`/nmipayment/delete-customer-vault`, {
              customer_vault_id: customerVaultId,
              admin_id: admin_id,
              tenant_id: tenantName,
            }),
          ]);
        }
  
        // Check the responses
        if (
          deleteRecordResponse.status === 200 &&
          deleteNMIResponse.status === 200
        ) {
          getCardsData();
          toast.success("Card deleted successfully", {
            position: "top-center",
            autoClose: 500,
          });
          props.onHide();
        } else {
          toast.error("Card deletion failed", {
            position: "top-center",
            autoClose: 500,
          });
        }
      } else {
        // User canceled the action
        toast.success("Card deletion canceled", {
          position: "top-center",
          autoClose: 500,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error deleting card", {
        position: "top-center",
        autoClose: 500,
      });
    }
  };

  const [rentalAddress, setRentalAddress] = useState("");
  useEffect(() => {
    const getLeaseDetails = async () => {
      try {
        const response = await dispatch(
          getLeasDetailsForAdmin(lease_id)
        );
        const lease = response.payload?.data[0];
        setRentalAddress(lease?.rental_adress);
      } catch (error) {}
    };

    getLeaseDetails();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        keyboard
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="text-lg">Add a new card</h4>
          </Modal.Title>
          <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md="12" lg="6">
                {!props.scriptGenerating && !props.scriptError && (
                  <AddCardForm
                    preFillData={{
                      ...props.preFillData,
                      address1: rentalAddress,
                    }}
                    addCardInfo={addCardInfo}
                    onHide={props.onHide}
                  />
                )}
              </Col>
              {/* Card Details Section */}
              <Col md="12" lg="6">
                <Card style={{ maxWidth: "500px" }}>
                  <CardContent>
                    <Typography
                      className="dropdownfontsyle"
                      sx={{
                        fontSize: 18,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Cards
                    </Typography>

                    {/* Card Details */}
                    {getCardLoading ? (
                      <div className="d-flex justify-content-center align-items-center p-5 m-5">
                        <Loader />
                      </div>
                    ) : getCardError ? (
                      <p>
                        {typeof getCardError === "object"
                          ? getCardError.message
                          : getCardError}
                      </p>
                    ) : cardDetalis && cardDetalis?.length > 0 ? (
                      <>
                        {cardDetalis.map((card, index) => (
                          <div
                            className="row d-flex align-items-center justify-content-around"
                            key={index}
                          >
                            <CardTemplate card={card} />
                            <IconButton
                              color="error"
                              aria-label="add to shopping cart"
                              onClick={() =>
                                handleDeleteCard(
                                  card.customer_vault_id,
                                  card.billing_id
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No cards found</p>
                    )}
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default AddTenantCreditCardByAdmin;
