import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "components/Headers/Header.js";
import { useCookies } from "react-cookie";
import { verifyToken } from "components/Functions/Functions";

const Reports = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let navigate = useNavigate();
  const location  = useLocation();
  const { admin } = useParams();
  const [accessType, setAccessType] = useState(null);
  // const [cookies] = useCookies(["token"]);
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const cardStyle = (isHovered) => ({
    cursor: "pointer",
    height: "150px",
    borderRadius: "20px",
    boxShadow: isHovered
      ? "rgba(0, 0, 0, 0.35) 0px 8px 15px"
      : "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
    display: "flex",
    flexDirection: "column",
    transform: isHovered ? "scale(1.05)" : "scale(1)",
    transition: "transform 0.3s, box-shadow 0.3s",
  });

  const handleCardClick = (report) => {
    const path = report.replace(/[-\s']/g, "");
    navigate(admin ? `/${admin}/${path?.toLowerCase()}` : `/staff/Staff${path?.toLowerCase()}` );
  };

  const reportDescriptions = {
    "Renter's Insurance": "Detailed listing of all renter's insurance policies",
    "Expiring Insurance": "Renter's insurance policies expiring within the selected time period",
    "Expiring Leases":
      "Lists all leases that will end during a specified timeframe",
    "Delinquent Tenants":
      "Tenants with an outstanding ledger balance as of a specific date",
    "Open Work Orders": "Work orders not yet in a complete state",
    "Completed Work Orders": "All completed work orders",
    "Daily Transaction Report": "Listing of all transactions summarized by day",
    "Rental Owner Report": "Listing of all transactions summarized by rental owner",
    "Account Totals Report": "Transaction report summarized by account and rental owner",
    "Payment Exception Report": "Transaction list not assigned to a tenant",
    "Recurring Payments Configuration" : "All configured recurring payments by lease",
    "Convenience Fee Override" : "All leases with tenants that have a convenience fee override",
    "Rent Roll Report" : "Rent balance due by property and tenant",
  };

  return (
    <div>
      <Header />
      <Container className="pt-3" fluid>
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Reports
            </h2>
          </CardHeader>
        </Col>
        <Row className="mt-4">
          <Col className="order-xl-1" xl="12">
            <Row>
              <Col xs={12}>
                <Row className="pl-2">
                  {Object.keys(reportDescriptions).map((report, index) => (
                    <Col
                      className="pt-3"
                      xs={12}
                      md={6}
                      lg={6}
                      xl={4}
                      key={index}
                    >
                      <Col>
                        <Card
                          className="second-cards dashcardsheight"
                          style={cardStyle(hoveredCard === index)}
                          onMouseEnter={() => setHoveredCard(index)}
                          onMouseLeave={() => setHoveredCard(null)}
                          onClick={() => handleCardClick(report)}
                        >
                          <Row>
                            <Col lg={12}>
                              <span
                                style={{
                                  height: "50px",
                                  borderTopLeftRadius: "20px",
                                  borderTopRightRadius: "20px",
                                  fontWeight: "600",
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                                  fontSize: "15px",
                                }}
                                className="d-flex justify-content-center align-items-center background-colorsty bgtextwhite fontfamilysty"
                              >
                                {report}
                              </span>
                            </Col>
                          </Row>
                          <CardBody>
                            <span
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                              }}
                              className="d-flex justify-content-center align-items-center barforsummaryrent  titleecolor fontfamilysty"
                            >
                              {reportDescriptions[report]}
                            </span>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reports;
