import Img from "../../assets/img/theme/site-logo.png";

// pdf
import jsPDF from "jspdf";
import "jspdf-autotable";

// excel
import * as XLSX from "xlsx-js-style";

// save excels and csv
import { saveAs } from "file-saver";
import moment from "moment";

const generatePDF = (reportData, userProfile) => {
  const doc = new jsPDF();

  const img1Width = 15;
  const img1Height = 13;
  doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

  const titleYPosition = 10 + 10;
  doc.setFontSize(20);
  doc.text(
    "Convenience Fee Override",
    doc.internal.pageSize.getWidth() / 2,
    titleYPosition,
    null,
    null,
    "center"
  );

  const subtitleYPosition = titleYPosition + 10;
  doc.setFontSize(12);
  doc.text(
    `Date: ${new Date().toISOString().split("T")[0]}`,
    doc.internal.pageSize.getWidth() / 2,
    subtitleYPosition,
    null,
    null,
    "center"
  );

  const contactInfo = [
    userProfile?.company_name || "",
    userProfile?.company_address || "",
    [userProfile?.city, userProfile?.state, userProfile?.country]
      .filter(Boolean)
      .join(", "),
    userProfile?.postal_code || "",
  ]
    .filter(Boolean)
    .join("\n");
  doc.setFontSize(10);
  const contactInfoLines = contactInfo.split("\n");
  const topMargin = 20;
  const lineHeight = 4;

  contactInfoLines.forEach((line, index) => {
    doc.text(
      line,
      doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
      topMargin + index * lineHeight
    );
  });

  const tableYPosition = subtitleYPosition + 20;

  const tableRows = reportData?.map((transaction) => {
    const tenantNames = transaction.tenantData
      ?.map((tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`)
      .join("\n");
    const amount = transaction.tenantData
      ?.map((tenant) => `${tenant.override_fee || 0}%`)
      .join("\n");

    return [
      {
        content:
          (transaction.rentalData?.rental_adress || "") +
          (transaction.unitData?.rental_unit
            ? " - " + transaction.unitData?.rental_unit
            : ""),
        styles: { borderBottom: "none", fontStyle: "bold", fontSize: 10 },
      },
      {
        content:
          transaction?.entry?.end_date || "N/A"
            ? moment(transaction?.entry?.end_date).format("YYYY-MM-DD")
            : "-",
        styles: {
          borderBottom: "none",
          fontStyle: "bold",
          fontSize: 10,
        },
      },
      {
        content: tenantNames || "N/A",
        styles: {
          borderBottom: "none",
          fontStyle: "bold",
          fontSize: 10,
        },
      },
      {
        content: amount || "N/A",
        styles: {
          borderBottom: "none",
          fontStyle: "bold",
          fontSize: 10,
        },
      },
    ];
  });

  const result = [];
  const maxLength = Math.max(tableRows?.length);

  for (let i = 0; i < maxLength; i++) {
    if (tableRows[i] !== undefined) result.push(tableRows[i]);
  }

  doc.autoTable({
    startY: tableYPosition,
    head: [
      [
        {
          content: "Property",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Lease End Date",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Tenant",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
        {
          content: "Override percentage",
          styles: {
            cellPadding: { top: 3, bottom: 3, left: 3, right: 3 },
            fontStyle: "bold",
          },
        },
      ],
    ],
    headStyles: {
      fillColor: [90, 134, 213],
      textColor: [255, 255, 255],
      fontSize: 11,
      fontStyle: "bold",
    },
    body: result,
    theme: "plain",
    styles: { fontSize: 10, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 40 },
      2: { cellWidth: 45 },
      3: { cellWidth: 45 },
    },
  });

  const pageCount = doc.getNumberOfPages();
  const currentDate = new Date();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${moment(currentDate).format("YYYY-MM-DD HH:ss:mm")}`,
      10,
      doc.internal.pageSize.getHeight() - 8
    );
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 10,
      doc.internal.pageSize.getHeight() - 8,
      { align: "right" }
    );
  }
  doc.save(`Convenience_fee_override.pdf`);
};

const generateExcel = (reportData) => {
  const wb = XLSX.utils.book_new();

  const worksheetHeader = [
    "Property",
    "Lease End Date",
    "Tenant",
    "Override percentage",
  ];

  const worksheetData = [
    worksheetHeader,
    ...reportData.map((transaction) => {
      const tenantNames = transaction.tenantData
        ?.map(
          (tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
        )
        .join(", ");
      const amount = transaction.tenantData
        ?.map((tenant) => `${tenant.override_fee || 0}%`)
        .join("\n");

      return [
        (transaction.rentalData?.rental_adress || "") +
          (transaction.unitData?.rental_unit
            ? " - " + transaction.unitData?.rental_unit
            : ""),
        transaction?.entry?.end_date || "N/A"
          ? moment(transaction?.entry?.end_date).format("YYYY-MM-DD")
          : "-",
        tenantNames,
        amount,
      ];
    }),
  ];

  const ws = XLSX.utils.aoa_to_sheet(worksheetData);

  const wscols = worksheetHeader.map((header) => {
    return { wch: Math.max(header?.length + 5, 20) };
  });
  ws["!cols"] = wscols;

  const headerStyle = {
    font: { bold: true, color: { rgb: "FFFFFF" }, sz: "14" },

    border: {
      top: { style: "thin", color: "000000" },
      bottom: { style: "thin", color: "000000" },
      left: { style: "thin", color: "000000" },
      right: { style: "thin", color: "000000" },
    },
    alignment: { horizontal: "center" },
    fill: { fgColor: { rgb: "5A86D7" } },
  };

  worksheetHeader.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
    ws[cellAddress].s = headerStyle;
  });

  Object.keys(ws).forEach((cellAddress) => {
    if (ws[cellAddress].s) {
      ws[cellAddress].s.alignment = {
        wrapText: true,
        vertical: "center",
        horizontal: ws[cellAddress].s.alignment?.horizontal || "left",
      };
    }
  });

  reportData.forEach((_, rowIndex) => {
    const totalAmountCellAddress = XLSX.utils.encode_cell({
      r: rowIndex + 1,
      c: worksheetHeader?.length - 1,
    });
    if (!ws[totalAmountCellAddress]) ws[totalAmountCellAddress] = { t: "s" };
    ws[totalAmountCellAddress].s = {
      alignment: {
        horizontal: "right",
        vertical: "center",
        wrapText: true,
      },
    };
  });

  XLSX.utils.book_append_sheet(wb, ws, "Convenience Fee Override");

  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    `Convenience_fee_override.xlsx`
  );
};

const generateCSV = (reportData) => {
  const csvHeader = [
    "Property",
    "Lease End Date",
    "Tenant",
    "Override percentage",
  ];

  const formatCSVCell = (cell) => {
    if (typeof cell === "string") {
      return `"${cell.replace(/"/g, '""')}"`;
    }
    return cell === null || cell === undefined ? "" : cell.toString();
  };

  const csvData = [
    csvHeader.map(formatCSVCell).join(","),
    ...reportData.map((transaction) => {
      const tenantNames = transaction.tenantData
        ?.map(
          (tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
        )
        .join("\n");
      const amount = transaction.tenantData
        ?.map((tenant) => `${tenant.override_fee || 0}%`)
        .join("\n");

      return [
        (transaction.rentalData?.rental_adress || "") +
          (transaction.unitData?.rental_unit
            ? " - " + transaction.unitData?.rental_unit
            : ""),
        transaction?.entry?.end_date || "N/A"
          ? moment(transaction?.entry?.end_date).format("YYYY-MM-DD")
          : "-",
        tenantNames,
        amount,
      ]
        .map(formatCSVCell)
        .join(",");
    }),
  ].join("\n");

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, `Convenience_fee_override.csv`);
};

export { generatePDF, generateExcel, generateCSV };
