import React, { useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BaseButton from "components/ReusableComponents/BaseButton";
import BaseInput from "components/ReusableComponents/BaseInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./RenterInsurance.css";
import { Divider, IconButton } from "@mui/material";
import interceptor from "plugins/axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const AddInsuranceDialog = ({ tenantList, refetch }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const { lease_id } = useParams();
  const fileInputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [tenantListEmpty, setTenantListEmpty] = useState(false);
  const [uploadPolicyLoading, setUploadPolicyLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [formData, setFormData] = useState({
    lease_id: lease_id,
    insurance_company: "",
    insurance_company_phone_number: "",
    policy_id: "",
    effective_date: "",
    expiration_date: "",
    liability_coverage: "",
    tenants: [],
    insurance_policy_document: "",
  });

  // Functions to handle open and close dialog
  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => {
    setOpen(false);
    clearFileInput();
  };

  // Function to handle check and unchecking of tenants
  const handleCheckboxChange = (tenantId) => {
    setFormData((prev) => {
      const isChecked = prev.tenants.includes(tenantId);
      return {
        ...prev,
        tenants: isChecked
          ? prev.tenants.filter((id) => id !== tenantId)
          : [...prev.tenants, tenantId],
      };
    });
  };

  // Function to handle file select and store them into state
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) setNewFile(selectedFile);
  };

  // Function to clear input field event value
  const clearFileInput = () => {
    setNewFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Function to handle upload insurance policy
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = formData;
      setUploadPolicyLoading(true);

      if (formData.tenants.length === 0) {
        setTenantListEmpty(true);
        return;
      }

      // Uplaoding file is optional. If it is selected, upload it otherwise ignore it
      if (newFile) {
        const form = new FormData();
        form.append("files", newFile);

        const fileResp = await interceptor.post(
          `${imageUrl}/images/upload`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const fileName = fileResp.data.files[0].filename;
        data.insurance_policy_document = fileName;
      }

      await interceptor.post("renter-insurance/add-policy", data);
      toast.success("Insurance policy added successfully");
      if (refetch) refetch();
      handleCloseDialog();

      // Reset form after uploading policy
      setFormData((prev) => ({
        ...prev,
        insurance_company: "",
        insurance_company_phone_number: "",
        policy_id: "",
        effective_date: "",
        expiration_date: "",
        liability_coverage: "",
        tenants: [],
        insurance_policy_document: "",
      }));
    } catch (e) {
      let error = e;
      console.error("Error during file upload:", error);
      if (typeof error !== "string") error = JSON.stringify(error);
      toast.error(error);
    } finally {
      setUploadPolicyLoading(false);
    }
  };

  return (
    <>
      <BaseButton onClick={handleOpenDialog} label="Add New Insurance" />
      <Dialog className="addinsurance"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="manage-insurance-dialog-title"
      >
        <DialogTitle id="manage-insurance-dialog-title">
          <h2>Add New Insurance</h2>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <BaseInput
              label="Insurance Company"
              name="insurance_company"
              value={formData.insurance_company}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  insurance_company: e.target.value,
                })
              }
              required
            />
            <BaseInput
              label="Insurance Company Phone Number"
              name="insurance_company_phone_number"
              type="tel"
              value={formData.insurance_company_phone_number}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  insurance_company_phone_number: e.target.value,
                })
              }
              required
            />
            <BaseInput
              label="Policy ID"
              name="policy_id"
              value={formData.policy_id}
              onChange={(e) =>
                setFormData({ ...formData, policy_id: e.target.value })
              }
              required
            />
            <BaseInput
              label="Effective Date"
              name="effective_date"
              type="date"
              value={formData.effective_date}
              onChange={(e) =>
                setFormData({ ...formData, effective_date: e.target.value })
              }
              required
            />
            <BaseInput
              label="Expiration Date"
              name="expiration_date"
              type="date"
              value={formData.expiration_date}
              onChange={(e) =>
                setFormData({ ...formData, expiration_date: e.target.value })
              }
              required
            />
            <BaseInput
              label="Liability Coverage ($0.00)"
              name="liability_coverage"
              value={formData.liability_coverage}
              type="text"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  liability_coverage: e.target.value,
                });
              }}
              required
            />
            <div>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Covered Tenants:
                <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {tenantList.map((tenant) => (
                  <FormControlLabel
                    key={tenant.tenant_id}
                    control={
                      <Checkbox
                        checked={formData.tenants.includes(tenant.tenant_id)}
                        onChange={(e) => {
                          if (e.target.value) setTenantListEmpty(false);
                          handleCheckboxChange(tenant.tenant_id);
                        }}
                      />
                    }
                    label={`${tenant.tenant_firstName} ${tenant.tenant_lastName}`}
                  />
                ))}
              </div>
              {tenantListEmpty && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  Select at least one tenant
                </p>
              )}
            </div>
            {/* File Uploader */}
            <div className="file-uploader-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  border: "2px dotted #9d9d9d",
                  borderRadius: "5px",
                  padding: "10px",
                  position: "relative",
                }}
              >
                <label
                  htmlFor="file-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                    flex: 1,
                  }}
                >
                  <UploadFileIcon sx={{ fontSize: "40px", color: "#1976d2" }} />
                  <div>
                    {newFile?.name ? (
                      <span style={{ fontSize: "14px", color: "#1976d2" }}>
                        {newFile?.name}
                      </span>
                    ) : (
                      "Drag and drop or click to upload"
                    )}
                  </div>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="file/*"
                    name="file-input"
                    id="file-input"
                    multiple={false}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                </label>
                {newFile && (
                  <IconButton
                    onClick={clearFileInput}
                    sx={{
                      color: "#ff0000",
                      position: "absolute",
                      right: "10px",
                    }}
                    aria-label="Clear file"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </div>
            </div>
            <Divider style={{ margin: "10px 0px" }} />
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <BaseButton
                loading={uploadPolicyLoading}
                type="submit"
                label="Add Insurance"
              />
              <BaseButton
                onClick={handleCloseDialog}
                disabled={uploadPolicyLoading}
                label="Cancel"
                variant="outline"
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
