import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Table,
} from "reactstrap";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VendorHeader from "components/Headers/VendorHeader";
import ClearIcon from "@mui/icons-material/Clear";
import "react-datepicker/dist/react-datepicker.css";
import { useCookies } from "react-cookie";
import { OpenImageDialog } from "components/OpenImageDialog";
import { Grid, Typography, Radio, FormControlLabel } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const VendorAddWork = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [propdropdownOpen, setpropdropdownOpen] = React.useState(false);
  const [categorydropdownOpen, setcategorydropdownOpen] = React.useState(false);
  const [entrydropdownOpen, setentrydropdownOpen] = React.useState(false);
  const [userdropdownOpen, setuserdropdownOpen] = React.useState(false);
  const [statusdropdownOpen, setstatusdropdownOpen] = React.useState(false);
  const [unitData, setUnitData] = useState([]);
  const [selectedProp, setSelectedProp] = useState("Select");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Select");
  const [selectedVendor, setSelectedVendor] = useState("Select");
  const [selectedEntry, setSelectedEntry] = useState("Select");
  const [selecteduser, setSelecteduser] = useState("Select");
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState("Select");
  const [selectedTenant, setSelectedTenant] = useState("Select");

  const [accessType, setAccessType] = useState(null);
  const location = useLocation();
  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const toggle11 = () => {
    setUnitDropdownOpen((prevState) => !prevState);
  };
  const toggle1 = () => setpropdropdownOpen((prevState) => !prevState);
  const toggle2 = () => setcategorydropdownOpen((prevState) => !prevState);
  const toggle4 = () => setentrydropdownOpen((prevState) => !prevState);
  const toggle5 = () => setuserdropdownOpen((prevState) => !prevState);
  const toggle6 = () => setstatusdropdownOpen((prevState) => !prevState);

  const [accountData, setAccountData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [staffData, setstaffData] = useState([]);
  const [workOrderImage, setWorkOrderImage] = useState([]);
  const [formData, setFormData] = useState({
    part_qty: "",
    account_type: "",
    description: "",
    part_price: "",
    total_amount: "",
  });
  const [selectedPriority, setSelectedPriority] = useState("");

  const handlePropertySelect = async (property) => {
    setSelectedProp(property);
    WorkFormik.values.rental_adress = property;

    setSelectedUnit("");
    try {
      const units = await fetchUnitsByProperty(property.rental_adress);
      setUnitData(units);
    } catch (error) {
      console.error("Error handling selected property:", error);
    }
  };

  const fetchUnitsByProperty = async (propertyType) => {
    try {
      const response = await axiosInstance.get(
        `/unit/rental_unit_dropdown/${propertyType}`
      );
      const data = response.data;
      // Ensure that units are extracted correctly and set as an array
      const units = data?.data || [];
      return units;
    } catch (error) {
      console.error("Error fetching units:", error);
      return [];
    }
  };

  const [vid, setVid] = useState("");
  const [entriesID, setentriesID] = useState("");

  const [imagedetails, setImageDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/workorder/workorder_details/${id}`
          );
          setImageDetails(response?.data?.data?.workOrderImage);
          const vendorData = response?.data?.data;
          setWorkOrderData(vendorData);

          const formattedDueDate = vendorData?.date
            ? new Date(vendorData?.date).toISOString().split("T")[0]
            : "";

          setVid(vendorData._id);
          if (vendorData && vendorData?.entries.length > 0) {
            setentriesID(vendorData?.entries._id);
          }

          try {
            const units = await fetchUnitsByProperty(vendorData?.rental_adress);
            setUnitData(units);
          } catch (error) {
            console.log(error, "error");
          }
          setSelectedUnit(vendorData?.rental_units || "Select");
          setSelectedProp(vendorData?.rental_adress || "Select");
          setSelectedCategory(vendorData?.work_category || "Select");
          setSelectedVendor(vendorData?.vendor_name || "Select");
          setSelectedEntry(vendorData?.entry_allowed || "Select");
          setSelecteduser(vendorData?.staffmember_name || "Select");
          setSelectedStatus(vendorData?.status || "Select");
          setSelectedPriority(vendorData?.priority || "Select");
          setSelectedAccount(vendorData?.account_type || "Select");
          setWorkOrderImage(vendorData?.workOrderImage || []);

          const entriesData = vendorData?.entries || [];
          WorkFormik.setValues({
            work_subject: vendorData?.work_subject || "",
            rental_units: vendorData?.rental_units || "",
            invoice_number: vendorData?.invoice_number || "",
            work_charge: vendorData?.work_charge || "",
            detail: vendorData?.detail || "",
            entry_contact: vendorData?.entry_contact || "",
            work_performed: vendorData?.work_performed || "",
            vendor_note: vendorData?.vendor_note || "",
            date: formattedDueDate,
            final_total_amount: vendorData?.final_total_amount || "",
            entries: entriesData?.map((entry) => ({
              part_qty: entry.part_qty || "",
              account_type: entry.account_type || "Select",
              description: entry.description || "",
              part_price: entry.part_price || "",
              total_amount: entry.total_amount || "",
              dropdownOpen: false,
            })),
          });
        } catch (error) {
          console.error("Error fetching vendor data:", error);
        }
      }
    };

    fetchData();
  }, [id]);

  const handleUnitSelect = (selectedUnit, unitId) => {
    setSelectedUnit(selectedUnit);
    WorkFormik.values.rental_units = selectedUnit;
    WorkFormik.setFieldValue("unit_id", unitId);
  };

  const handleCategorySelection = (value) => {
    setSelectedCategory(value);
    setcategorydropdownOpen(true);
  };

  const handleVendorSelect = (value) => {
    setSelectedVendor(value);
  };

  const handleEntrySelect = (value) => {
    setSelectedEntry(value);
  };

  const handleStaffSelect = (staff) => {
    setSelecteduser(staff);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    WorkFormik.setFieldValue("status", status);
  };

  const handlePriorityChange = (event) => {
    setSelectedPriority(event.target.value);
    WorkFormik.setFieldValue("priority", event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  let cookie_id = localStorage.getItem("Vendor ID");

  // const [vendorname, setVendorname] = useState("");

  // const getVendorDetails = async () => {
  //   if (cookie_id) {
  //     try {
  //       const response = await axiosInstance.get(
  //         `/vendor/vendor_summary/${cookie_id}`
  //       );
  //       setVendorname(response.data.data.vendor_name);

  //     } catch (error) {
  //       console.error("Error fetching vendor details:", error);

  //     }
  //   }
  // };

  let navigate = useNavigate();
  const handleCloseButtonClick = () => {
    navigate(`/vendor/vendorworkdetail/${id}`);
  };

  const handleAddRow = () => {
    const newEntry = {
      part_qty: "",
      account_type: "",
      description: "",
      part_price: "",

      dropdownOpen: false,
    };
    if (WorkFormik.values.entries) {
      WorkFormik.setValues({
        ...WorkFormik.values,
        entries: [...WorkFormik.values.entries, newEntry],
      });
    }
  };

  const handleRemoveRow = (index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries.splice(index, 1); // Remove the entry at the specified index
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const toggleDropdown = (index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].dropdownOpen = !updatedEntries[index].dropdownOpen;
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const [selectedAccount, setSelectedAccount] = useState("");
  const [outstandDetails, setoutstandDetails] = useState({});
  const getOutstandData = async () => {
    if (id) {
      try {
        const response = await axiosInstance.get(
          `/workorder/workorder_details/${id}`
        );
        setoutstandDetails(response.data.data);

        setSelectedStatus(response.data.data.status);
        setSelecteduser(response.data.data.staffmember_name);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  const handleAccountSelection = (value, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    if (updatedEntries[index]) {
      updatedEntries[index].account_type = value;
      WorkFormik.setValues({
        ...WorkFormik.values,
        entries: updatedEntries,
      });
    } else {
      console.error(`Entry at index ${index} is undefined.`);
    }
  };

  const [workOrderData, setWorkOrderData] = useState(null);

  useEffect(() => {
    // getVendorDetails();
    getOutstandData();

    // axiosInstance
    //   .get(`/addaccount/find_accountname`)
    //   .then((response) => {
    //     if (response.data.statusCode === 200) {
    //       setAccountData(response.data.data);
    //     } else {
    //       console.error("Error:", response.data.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Network error:", error);
    //   });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/work-order/workorder_details/${id}`
          );
          const {
            partsandcharge_data,
            property_data,
            unit_data,
            staff_data,
            vendor_data,
            tenant_data,
          } = response.data.data;
          setWorkOrderData(response.data.data);

          const formattedDueDate = response?.data?.data?.date
            ? new Date(response?.data?.data?.date).toISOString().split("T")[0]
            : "";

          setVid(response?.data?.data?.workOrder_id);

          try {
            const units = await fetchUnitsByProperty(property_data?.rental_id);
            setUnitData(units);
          } catch (error) {
            console.error(error, "error");
          }

          setSelectedUnit(unit_data?.rental_unit || "Select");
          setSelectedProp(property_data?.rental_adress || "Select");
          setSelectedCategory(response?.data?.data?.work_category || "Select");
          setSelectedVendor(vendor_data?.vendor_name || "Select");
          setSelectedCharge(response?.data?.data?.work_charge_to || "Select");
          setSelectedEntry(
            response?.data?.data?.entry_allowed === true
              ? "Yes"
              : "No" || "Select"
          );
          setSelecteduser(staff_data?.staffmember_name || "Select");
          setSelectedStatus(response?.data.data.status || "Select");
          setSelectedPriority(response?.data?.data?.priority || "Select");
          setWorkOrderImage(response?.data?.data?.workOrder_images || []);
          setSelectedFiles(response?.data?.data?.workOrder_images || []);
          setSelectedTenant(
            tenant_data?.tenant_firstName +
              " " +
              tenant_data?.tenant_lastName || "Select"
          );

          WorkFormik.setValues({
            invoice_number: response?.data?.data?.invoice_number || "",
            work_charge_to: response?.data?.data?.work_charge_to || "",
            detail: response?.data?.data?.detail || "",
            entry_contact: response.data.data?.entry_contact || "",
            final_total_amount: response?.data?.data?.final_total_amount || "",

            work_subject: response?.data?.data?.work_subject || "",
            rental_adress: property_data?.rental_adress || "",
            rental_unit: unit_data?.rental_unit || "",
            rental_id: property_data?.rental_id || "",
            work_category: response?.data.data?.work_category || "",
            vendor_name: vendor_data?.vendor_name || "",
            vendor_id: vendor_data?.vendor_id || "",
            unit_id: unit_data?.unit_id || "",
            tenant_id: tenant_data?.tenant_id || "",
            tenant_name:
              tenant_data?.tenant_firstName +
                " " +
                tenant_data?.tenant_lastName || "",
            invoice_number: "",
            work_charge: response?.data?.data?.work_charge_to || "",
            entry_allowed:
              response?.data?.data?.entry_allowed === true ? "Yes" : "No",
            detail: "",
            entry_contact: "",
            work_performed: response?.data?.data?.work_performed || "",
            vendor_note: response?.data?.data?.vendor_notes || "",
            staffmember_name: staff_data?.staffmember_name || "",
            staffmember_id: staff_data?.staffmember_id || "",
            status: response?.data?.data?.status || "",
            date: formattedDueDate || "",
            priority: response?.data?.data?.priority || "",
            workOrderImage: response?.data?.data?.workOrder_images || "",
            final_total_amount: "",
            statusUpdatedBy: accessType?.vendor_name + "(Vendor)",
            entries: partsandcharge_data?.map((part) => ({
              part_qty: part?.parts_quantity || "",
              parts_id: part?.parts_id || "",
              account_type: part?.account || "Select",
              description: part?.description || "",
              charge_type: "Workorder Charge",
              part_price: part?.parts_price || "",
              total_amount: part?.amount || "",
              dropdownOpen: false,
            })),
          });
        } catch (error) {
          console.error("Error fetching vendor data:", error);
        }
      }
    };

    fetchData();
  }, [id]);

  const { v4: uuidv4 } = require("uuid");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values) => {
    setLoader(true);
    let image = [];

    if (selectedFiles) {
      try {
        const uploadPromises = selectedFiles.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileItem = res.data.files[0].filename;
                image[i] = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            image[i] = fileItem;
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      workOrder: {
        rental_id: WorkFormik.values.rental_id || "",
        unit_id: WorkFormik.values.unit_id || "",
        vendor_id: WorkFormik.values.vendor_id || "",
        tenant_id: WorkFormik.values.tenant_id || "",
        staffmember_id: WorkFormik.values.staffmember_id || "",
        work_subject: WorkFormik.values.work_subject || "",
        work_category: WorkFormik.values.work_category || "",
        entry_allowed: WorkFormik.values.entry_allowed === "Yes" ? true : false,
        work_performed: WorkFormik.values.work_performed || "",
        workOrder_images: image || "",
        vendor_notes: WorkFormik.values.vendor_note || "",
        priority: WorkFormik.values.priority || "",
        work_charge_to: WorkFormik.values.work_charge || "",
        status: WorkFormik.values.status || "",
        date: WorkFormik.values.date || "",
      },
      parts: WorkFormik.values.entries.map((item) => {
        return {
          parts_quantity: item.part_qty || 0,
          account: item.account_type || "",
          description: item.description || "",
          charge_type: "Workorder Charge",
          parts_price: item.part_price || 0,
          amount: item.total_amount || 0,
        };
      }),
      notificationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      const res = await axiosInstance.post(`/work-order/work-order`, object);
      if (res.status === 200) {
        if (res.data.statusCode === 200) {
          toast.success("Work Order Added Successfully.", {
            position: "top-center",
            autoClose: 1000,
          });
          navigate(`/vendor/vendorworkdetail/${id}`);
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
    setLoader(false);
  };
  function handleResponse(response) {
    if (response.data.statusCode === 200) {
      navigate("/vendor/vendorworktable");

      toast.success(
        id
          ? "Workeorder  Updated successfully"
          : "Workeorder  Added successfully",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
    } else {
      // Show an error toast
      toast.error(response.data.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }
  const WorkFormik = useFormik({
    initialValues: {
      work_subject: "",
      rental_adress: "",
      rental_units: "",
      work_category: "",
      vendor: "",
      invoice_number: "",
      work_charge: "",
      entry_allowed: "",
      detail: "",
      entry_contact: "",
      work_performed: "",
      vendor_note: "",
      staffmember_name: "",
      status: "",
      date: "",
      priority: "",
      final_total_amount: "",
      workOrderImage: [],

      entries: [
        {
          part_qty: "",
          account_type: "",
          description: "",
          part_price: "",
          total_amount: "",
          dropdownOpen: false,
        },
      ],
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const clearSelectedPhoto = (image) => {
    const filteredImage = workOrderImage.filter((item) => {
      return item !== image;
    });
    setWorkOrderImage([...filteredImage]);

    WorkFormik.setFieldValue("workOrderImage", filteredImage);
  };

  // React.useEffect(() => {
  //   // Make an HTTP GET request to your Express API endpoint
  //   axiosInstance
  //     .get(`/rentals/allproperty`)
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.statusCode === 200) {
  //         setPropertyData(data.data);
  //       } else {
  //         // Handle error
  //         console.error("Error:", data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle network error
  //       console.error("Network error:", error);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   // Make an HTTP GET request to your Express API endpoint
  //   axiosInstance
  //     .get(`/addstaffmember/find_staffmember`)
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.statusCode === 200) {
  //         setstaffData(data.data);
  //       } else {
  //         // Handle error
  //         console.error("Error:", data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle network error
  //       console.error("Network error:", error);
  //     });
  // }, []);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileData = (e, type) => {
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...e.target.files,
    ]);

    const newFiles = [
      ...workOrderImage,
      ...Array.from(e.target.files).map((file) => URL.createObjectURL(file)),
    ];

    setWorkOrderImage(newFiles);
  };
  const editworkorder = async (vid) => {
    setLoader(true);
    let image = [];

    if (selectedFiles) {
      try {
        const uploadPromises = selectedFiles.map(async (fileItem, i) => {
          if (fileItem instanceof File) {
            try {
              const form = new FormData();
              form.append("files", fileItem);

              const res = await axios.post(`${imageUrl}/images/upload`, form);

              if (
                res &&
                res.data &&
                res.data.files &&
                res.data.files.length > 0
              ) {
                fileItem = res.data.files[0].filename;
                image[i] = res.data.files[0].filename;
              } else {
                console.error("Unexpected response format:", res);
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          } else {
            image[i] = fileItem;
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        console.error("Error processing file uploads:", error);
      }
    }

    const object = {
      workOrder: {
        admin_id: accessType?.admin_id || "",
        workOrder_id: id,
        rental_id: WorkFormik.values.rental_id || "",
        unit_id: WorkFormik.values.unit_id || "",
        vendor_id: WorkFormik.values.vendor_id || "",
        tenant_id: WorkFormik.values.tenant_id || "",
        staffmember_id: WorkFormik.values.staffmember_id || "",
        staffmember_name: WorkFormik.values.staffmember_name || "",
        work_subject: WorkFormik.values.work_subject || "",
        work_category: WorkFormik.values.work_category || "",
        entry_allowed: WorkFormik.values.entry_allowed === "Yes" ? true : false,
        work_performed: WorkFormik.values.work_performed || "",
        workOrder_images: image || "",
        vendor_notes: WorkFormik.values.vendor_note || "",
        priority: WorkFormik.values.priority || "",
        work_charge_to: WorkFormik.values.work_charge || "",
        status: WorkFormik.values.status || "",
        date: WorkFormik.values.date || "",

        statusUpdatedBy: accessType?.vendor_name + "(Vendor)",
        is_vendor: true,
      },
      parts: WorkFormik.values.entries.map((item) => {
        return {
          parts_quantity: item.part_qty || 0,
          parts_id: item.parts_id,
          account: item.account_type || "",
          description: item.description || "",
          workOrder_id: id,
          charge_type: "Workorder Charge",
          parts_price: item.part_price || 0,
          amount: item.total_amount || 0,
        };
      }),
      notificationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const res = await axiosInstance.put(
        `/work-order/work-order/${id}`,
        object
      );
      if (res.status === 200) {
        navigate(`/vendor/vendorworkdetail/${id}`);
      }
    } catch (error) {}
    setLoader(false);
  };

  const handleQuantityChange = (e, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].part_qty = e.target.value;
    const quantity = parseFloat(e.target.value);
    const price = parseFloat(updatedEntries[index].part_price);
    updatedEntries[index].total_amount =
      isNaN(quantity) || isNaN(price) ? "" : (quantity * price).toFixed(2);
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };

  const handlePriceChange = (e, index) => {
    const updatedEntries = [...WorkFormik.values.entries];
    updatedEntries[index].part_price = e.target.value;
    const quantity = parseFloat(updatedEntries[index].part_qty);
    const price = parseFloat(e.target.value);
    updatedEntries[index].total_amount =
      isNaN(quantity) || isNaN(price) ? "" : (quantity * price).toFixed(2);
    WorkFormik.setValues({
      ...WorkFormik.values,
      entries: updatedEntries,
    });
  };
  // Calculate the total
  let final_total_amount = 0;
  WorkFormik.values.entries.forEach((entries) => {
    if (entries.total_amount) {
      final_total_amount += parseFloat(entries.total_amount);
    }
  });
  // useEffect(() => {
  //   const fetchVendorNames = async () => {
  //     if (id) {
  //       try {
  //         const response = await axiosInstance.get(
  //           `/workorder/workorder/vendor/${id}`
  //         );
  //         const data = response.data;
  //         setSelectedVendor(data.vendor_name);
  //       } catch (error) {
  //         console.error("Error fetching vendor names:", error);
  //       }
  //     }
  //   };

  //   fetchVendorNames();
  // }, []);

  return (
    <>
      <Container
        className=" containerremovespace stylecontainer"
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3  headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {id ? "Edit Work Order" : "New Work Order"}
            </h2>
          </CardHeader>
        </Col>
        <Form onSubmit={WorkFormik.handleSubmit}>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card
                className="bg-white shadow mt-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <div className="">
                    <Row>
                      <Col lg="4" md="4" sm="4" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Subject *
                          </label>
                          <br />
                          <Input
                            style={{
                              borderRadius: "6px",
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-work-subject"
                            placeholder="Add Subject"
                            type="text"
                            name="work_subject"
                            readOnly
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_subject}
                          />
                          {WorkFormik.touched.work_subject &&
                          WorkFormik.errors.work_subject ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.work_subject}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        {imagedetails > 0 ? (
                          <FormGroup
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label
                              className="form-control-label fontstylerentr fontfamilysty"
                              htmlFor="input-unitadd"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Photo
                            </label>
                          </FormGroup>
                        ) : null}
                      </Col>
                    </Row>
                    <FormGroup
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingLeft: "10px",
                      }}
                    >
                      <div
                        className="mt-3 d-flex"
                        style={{
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {workOrderImage.map((image, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              width: "100px",
                              height: "100px",
                              margin: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={
                                !image.startsWith("blob:")
                                  ? `${imageGetUrl}/${image}`
                                  : URL.createObjectURL(image)
                              }
                              alt=""
                              style={{
                                width: "100px",
                                height: "100px",
                                maxHeight: "100%",
                                maxWidth: "100%",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                setSelectedImage(image);
                                setOpen(true);
                              }}
                            />
                          </div>
                        ))}
                        {open && (
                          <OpenImageDialog
                            open={open}
                            setOpen={setOpen}
                            selectedImage={selectedImage}
                          />
                        )}
                      </div>
                    </FormGroup>
                  </div>
                  <div className="">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Property
                          </label>
                          <br />
                          <FormGroup>
                            <Dropdown
                              className="fontstylerentmodal"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                              }}
                              isOpen={propdropdownOpen}
                              toggle={toggle1}
                              disabled={true}
                            >
                              <DropdownToggle
                                className="fontstylerentmodal titleecolor"
                                caret
                                style={{
                                  width: "100%",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedProp
                                  ? selectedProp
                                  : "Select a property..."}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </DropdownToggle>
                              <DropdownMenu
                                className="fontstylerentmodal"
                                style={{
                                  width: "max-content",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                {propertyData.map((property) => (
                                  <DropdownItem
                                    className="fontstylerentmodal"
                                    key={property._id}
                                    onClick={() =>
                                      handlePropertySelect(
                                        property.rental_adress
                                      )
                                    }
                                  >
                                    {property.rental_adress}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        <Row>
                          {selectedProp &&
                            unitData &&
                            unitData[0] &&
                            unitData[0].rental_units && (
                              <FormGroup>
                                <label
                                  className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                  htmlFor="input-unit"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  Unit *
                                </label>
                                <br />
                                <FormGroup style={{ marginLeft: "15px" }}>
                                  <Dropdown
                                    className="fontstylerentmodal"
                                    isOpen={unitDropdownOpen}
                                    toggle={toggle11}
                                    disabled={true}
                                  >
                                    <DropdownToggle
                                      className="fontstylerentmodal titleecolor"
                                      caret
                                      style={{
                                        width: "100%",
                                        boxShadow: " 0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",

                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {selectedUnit
                                        ? selectedUnit
                                        : "Select Unit"}
                                    </DropdownToggle>
                                    <DropdownMenu className="fontstylerentmodal">
                                      {unitData.length > 0 ? (
                                        unitData.map((unit) => (
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            key={unit._id}
                                            onClick={() =>
                                              handleUnitSelect(
                                                unit.rental_units,
                                                unit._id
                                              )
                                            }
                                          >
                                            {unit.rental_units}
                                          </DropdownItem>
                                        ))
                                      ) : (
                                        <DropdownItem disabled>
                                          No units available
                                        </DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                </FormGroup>
                              </FormGroup>
                            )}
                        </Row>
                      </Col>
                    </Row>

                    <br />
                  </div>
                  <div className="">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Category
                          </label>
                          <br />
                          <Dropdown
                            className="fontstylerentmodal"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                            }}
                            isOpen={categorydropdownOpen}
                            toggle={toggle2}
                            disabled={true}
                          >
                            <DropdownToggle
                              className=" fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "100%",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",

                                backgroundColor: "transparent",
                              }}
                            >
                              {selectedCategory} &nbsp;&nbsp;&nbsp;&nbsp;
                            </DropdownToggle>
                            <DropdownMenu
                              className=" fontstylerentmodal"
                              style={{ width: "100%" }}
                            >
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Complaint")
                                }
                              >
                                Complaint
                              </DropdownItem>
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection(
                                    "Contribution Request"
                                  )
                                }
                              >
                                Contribution Request
                              </DropdownItem>
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Feedback/Suggestion")
                                }
                              >
                                Feedback/Suggestion
                              </DropdownItem>
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("General Inquiry")
                                }
                              >
                                General Inquiry
                              </DropdownItem>
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Maintenance Request")
                                }
                              >
                                Maintenance Request
                              </DropdownItem>
                              <DropdownItem
                                className=" fontstylerentmodal"
                                onClick={() => handleCategorySelection("Other")}
                              >
                                Other
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>

                  <div className="">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Entry Allowed
                          </label>
                          <br />
                          <Dropdown
                            className="fontstylerentmodal"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                            }}
                            isOpen={entrydropdownOpen}
                            toggle={toggle4}
                            disabled={true}
                          >
                            <DropdownToggle
                              className="fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "100%",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                backgroundColor: "transparent",
                              }}
                            >
                              {selectedEntry} &nbsp;&nbsp;&nbsp;&nbsp;
                            </DropdownToggle>
                            <DropdownMenu
                              className="fontstylerentmodal"
                              style={{ width: "100%" }}
                            >
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => handleEntrySelect("Yes")}
                              >
                                Yes
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => handleEntrySelect("No")}
                              >
                                No
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Assigned To *
                          </label>
                          <br />
                          <FormGroup>
                            <Dropdown
                              className="fontstylerentmodal"
                              isOpen={userdropdownOpen}
                              toggle={toggle5}
                              disabled={true}
                            >
                              <DropdownToggle
                                className="fontstylerentmodal titleecolor"
                                caret
                                style={{
                                  width: "100%",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {selecteduser ? selecteduser : "Select"}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </DropdownToggle>
                              <DropdownMenu
                                className="fontstylerentmodal"
                                style={{
                                  width: "100%",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  header
                                  style={{ color: "blue" }}
                                >
                                  Staff
                                </DropdownItem>
                                {staffData.map((user) => (
                                  <DropdownItem
                                    className="fontstylerentmodal"
                                    key={user._id}
                                    onClick={() =>
                                      handleStaffSelect(user.staffmember_name)
                                    }
                                  >
                                    {user.staffmember_name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>

                  <div className="">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Work To Be Performed
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-name"
                            placeholder="Enter here..."
                            type="textarea"
                            name="work_performed"
                            readOnly
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              // Update the state or Formik values with the new input value
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_performed}
                          />
                          {WorkFormik.touched.work_performed &&
                          WorkFormik.errors.work_performed ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.work_performed}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card
                className="bg-white shadow mt-3 mb-3 mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <div className="">
                    <label
                      className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                      htmlFor="input-desg"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Parts and Labor
                    </label>
                    <Col lg="12">
                      <FormGroup>
                        <div className="table-responsive">
                          <Table
                            className="table table-bordered"
                            responsive
                            style={{
                              borderCollapse: "collapse",
                              border: "1px solid #152B51",
                              overflow: "hidden",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                            }}
                          >
                            <thead
                              className="textcolorblue fontfamilysty"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              <tr
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  border: "1px solid #152B51",
                                }}
                              >
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Qty
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Account
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Price
                                </th>
                                <th
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {WorkFormik.values.entries?.map(
                                (entry, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      border: "1px solid #152B51",
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal inputqtyworkorder"
                                        id="input-unitadd"
                                        placeholder="Quantity"
                                        type="text"
                                        name={`entries[${index}].part_qty`}
                                        onChange={(e) =>
                                          handleQuantityChange(e, index)
                                        }
                                        value={entry.part_qty}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .part_qty ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .part_qty
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Dropdown
                                        className="fontstylerentmodal"
                                        isOpen={entry.dropdownOpen}
                                        toggle={() => toggleDropdown(index)}
                                      >
                                        <DropdownToggle
                                          className="fontstylerentmodal"
                                          caret
                                          style={{ width: "100%" }}
                                        >
                                          {entry.account_type || "Select"}{" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="fontstylerentmodal"
                                          style={{
                                            width: "100%",
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Advertising",
                                                index
                                              )
                                            }
                                          >
                                            Advertising
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Association Fees",
                                                index
                                              )
                                            }
                                          >
                                            Association Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Auto and Travel",
                                                index
                                              )
                                            }
                                          >
                                            Auto and Travel
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Bank Fees",
                                                index
                                              )
                                            }
                                          >
                                            Bank Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Cleaning and Maintenance",
                                                index
                                              )
                                            }
                                          >
                                            Cleaning and Maintenance
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Commissions",
                                                index
                                              )
                                            }
                                          >
                                            Commissions
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Depreciation Expense",
                                                index
                                              )
                                            }
                                          >
                                            Depreciation Expense
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Insurance",
                                                index
                                              )
                                            }
                                          >
                                            Insurance
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Legal and Professional Fees",
                                                index
                                              )
                                            }
                                          >
                                            Legal and Professional Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Licenses and Permits",
                                                index
                                              )
                                            }
                                          >
                                            Licenses and Permits
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Management Fees",
                                                index
                                              )
                                            }
                                          >
                                            Management Fees
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Mortgage Interest",
                                                index
                                              )
                                            }
                                          >
                                            Mortgage Interest
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Other Expenses",
                                                index
                                              )
                                            }
                                          >
                                            Other Expenses
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Other Interest Expenses",
                                                index
                                              )
                                            }
                                          >
                                            Other Interest Expenses
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Postage and Delivery",
                                                index
                                              )
                                            }
                                          >
                                            Postage and Delivery
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Repairs",
                                                index
                                              )
                                            }
                                          >
                                            Repairs
                                          </DropdownItem>
                                          <DropdownItem
                                            className="fontstylerentmodal"
                                            onClick={() =>
                                              handleAccountSelection(
                                                "Insurance",
                                                index
                                              )
                                            }
                                          >
                                            Other Expenses
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal"
                                        id="input-unitadd"
                                        placeholder="Description"
                                        type="text"
                                        name={`entries[${index}].description`}
                                        onBlur={WorkFormik.handleBlur}
                                        onChange={WorkFormik.handleChange}
                                        value={entry.description}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .description ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .description
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal inputpriceworkorder"
                                        id="input-unitadd"
                                        placeholder="Price"
                                        type="text"
                                        name={`entries[${index}].part_price`}
                                        onChange={(e) =>
                                          handlePriceChange(e, index)
                                        }
                                        value={entry.part_price}
                                        onInput={(e) => {
                                          const inputValue = e.target.value;
                                          const numericValue =
                                            inputValue.replace(/\D/g, "");
                                          e.target.value = numericValue;
                                        }}
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .part_price ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .part_price
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <Input
                                        className="form-control-alternative fontstylerentmodal inputpriceworkorder"
                                        id="input-unitadd"
                                        placeholder="Total"
                                        type="number"
                                        name={`entries[${index}].total_amount`}
                                        onBlur={WorkFormik.handleBlur}
                                        onChange={WorkFormik.handleChange}
                                        value={entry.total_amount}
                                        disabled // Disable the input
                                      />
                                      {WorkFormik.touched.entries &&
                                      WorkFormik.touched.entries[index] &&
                                      WorkFormik.errors.entries &&
                                      WorkFormik.errors.entries[index] &&
                                      WorkFormik.errors.entries[index]
                                        .total_amount ? (
                                        <div
                                          className="requiredstylefont"
                                          style={{ color: "red" }}
                                        >
                                          {
                                            WorkFormik.errors.entries[index]
                                              .total_amount
                                          }
                                        </div>
                                      ) : null}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #152B51",
                                      }}
                                    >
                                      <ClearIcon
                                        className="addpaymenticonremove"
                                        type="button"
                                        style={{
                                          cursor: "pointer",
                                          padding: 0,
                                        }}
                                        onClick={() => handleRemoveRow(index)}
                                      >
                                        Remove
                                      </ClearIcon>
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr
                                style={{
                                  border: "1px solid #152B51",
                                }}
                              >
                                <th
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  Total
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                ></th>
                                <th
                                  className="fontstylerentmodal textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  {final_total_amount.toFixed(2)}
                                </th>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{
                                    border: "1px solid #152B51",
                                  }}
                                >
                                  <Button
                                    type="button"
                                    className="btn fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                                    onClick={handleAddRow}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </FormGroup>
                    </Col>
                  </div>
                  <br />
                  <div className="">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Vendor Notes
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-name"
                            placeholder="Enter here..."
                            type="textarea"
                            name="vendor_note"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              // Update the state or Formik values with the new input value
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.vendor_note}
                          />
                          {WorkFormik.touched.vendor_note &&
                          WorkFormik.errors.vendor_note ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.vendor_note}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        className="fontstylerentr titleecolor"
                        variant="subtitle1"
                        style={{ fontWeight: 500 }}
                      >
                        Priority
                      </Typography>
                      <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item xs={12} sm={3} lg="3" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "High"}
                                onChange={handlePriorityChange}
                                value="High"
                                name="priority"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                High
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "High"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "High"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} lg="4" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "Normal"}
                                onChange={handlePriorityChange}
                                value="Normal"
                                name="priority"
                                id="checked"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                Normal
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "Normal"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "Normal"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} lg="4" md="2">
                          <FormControlLabel
                            className="addworkordermuilable"
                            control={
                              <Radio
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                checked={selectedPriority === "Low"}
                                onChange={handlePriorityChange}
                                value="Low"
                                name="priority"
                              />
                            }
                            label={
                              <Typography
                                sx={{ fontWeight: 600, fontSize: "15px" }}
                              >
                                Low
                              </Typography>
                            }
                            sx={{
                              color:
                                selectedPriority === "Low"
                                  ? "#152B51"
                                  : "#67758e",
                              fontSize: "14px",
                              "& .Mui-checked": {
                                color:
                                  selectedPriority === "Low"
                                    ? "#152B51"
                                    : "#67758e",
                              },
                            }}
                            id="checked"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <br />
                  <div className="">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Status
                          </label>
                          <br />
                          <FormGroup>
                            <Dropdown
                              className="fontstylerentmodal"
                              isOpen={statusdropdownOpen}
                              toggle={toggle6}
                            >
                              <DropdownToggle
                                className="fontstylerentmodal titleecolor"
                                caret
                                style={{
                                  width: "100%",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  border: "1px solid #ced4da",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {selectedStatus ? selectedStatus : "Select"}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </DropdownToggle>
                              <DropdownMenu
                                className="fontstylerentmodal"
                                style={{
                                  width: "100%",
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                }}
                              >
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() => handleStatusSelect("New")}
                                >
                                  New
                                </DropdownItem>
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() =>
                                    handleStatusSelect("In Progress")
                                  }
                                >
                                  In Progress
                                </DropdownItem>
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() => handleStatusSelect("On Hold")}
                                >
                                  On Hold
                                </DropdownItem>
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() =>
                                    handleStatusSelect("Completed")
                                  }
                                >
                                  Completed
                                </DropdownItem>
                                {/* 6002 */}
                                <DropdownItem
                                  className="fontstylerentmodal"
                                  onClick={() => handleStatusSelect("Closed")}
                                >
                                  Closed
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Due Date
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative duedatestyle fontstylerentmodal titleecolor"
                            id="input-unitadd"
                            type="date"
                            name="due_date"
                            onBlur={WorkFormik.handleBlur}
                            onChange={WorkFormik.handleChange}
                            value={WorkFormik.values.due_date}
                            readOnly
                          />
                          {WorkFormik.touched.due_date &&
                          WorkFormik.errors.due_date ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.due_date}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>

                    <br />
                  </div>
                </CardBody>
              </Card>

              <div
                className=" ml-3 btnstyleworkorder"
                style={{ marginTop: "28px", marginBottom: "20px" }}
              >
                {loader ? (
                  <button
                    type="submit"
                    className="btn btnwidthworkorder fontstylerentr background-colorsty bgtextwhite"
                    style={{
                      cursor: "not-allowed",
                    }}
                    disabled
                  >
                    Loading...
                  </button>
                ) : id ? (
                  <button
                    type="submit"
                    className="btn btnwidthworkorder  fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      editworkorder(vid);
                    }}
                  >
                    Update Work Order
                  </button>
                ) : (
                  <button
                    type="submit"
                    onSubmit={{ handleSubmit }}
                    className="btn btnwidthworkorder fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                  >
                    Add Work Order
                  </button>
                )}
                <button
                  color="primary"
                  className="btn btnbottomworkorder btnwidthworkorder  fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                  onClick={handleCloseButtonClick}
                  size="sm"
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>

        <ToastContainer />
      </Container>
    </>
  );
};

export default VendorAddWork;
