import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, CardHeader } from "reactstrap";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyToken } from "components/Functions/Functions";

function PlanUpgrade() {
  const { admin } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [loader, setLoader] = React.useState(true);

  const [accessType, setAccessType] = useState({});

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const [purchase, setPurchase] = useState({});
  useEffect(() => {
    if (accessType?.admin_id) {
      axiosInstance
        .get(`/purchase/plan-purchase/${accessType?.admin_id}`)
        .then((response) => {
          setPurchase(response.data.data);
          setLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching purchase data:", error);
        });
    }
  }, [accessType]);

  useEffect(() => {
    axiosInstance
      .get(`/plans/plans`)
      .then((response) => {
        setPlans(response.data.data.reverse());
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  const FeaturesList = ({ features }) => {
    const [showAllFeatures, setShowAllFeatures] = useState(false);
    const displayFeatures = showAllFeatures ? features : features.slice(0, 4);

    return (
      <>
        <ul>
          {displayFeatures.map((feature, index) => (
            <li key={index}>{feature.features}</li>
          ))}
        </ul>
        {!showAllFeatures && features.length > 4 && (
          <Button
            type="button"
            className="btn btn-link"
            onClick={() => setShowAllFeatures(true)}
          >
            Read More
          </Button>
        )}
      </>
    );
  };

  const upgradePlan = async (plan) => {
    try {
      const data = {
        plan_id: plan.plan_id,
        purchase_id: purchase.purchase_id,
        admin_id: accessType?.admin_id,
      };
      const res = await axiosInstance.put(`/purchase/purchase`, data);
      if (res.status === 200) {
        toast.success("Plan updated successfully!", {
          position: "top-center",
          autoClose: 1000,
          onClose: () => {
            setTimeout(() => {
              navigate(`/${admin}/index`);
              window.location.reload();
            }, 500);
          },
        });
      } else {
        toast.error("Failed to update the plan. Please try again.", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("An error occurred while updating the plan.", {
        position: "top-center",
        autoClose: 2000,
      });
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container fluid className="" id="home" style={{ marginTop: "4rem" }}>
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className="mt-3 background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Premium Plans
            </h2>
          </CardHeader>
        </Col>
        {loader ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loader}
            />
          </div>
        ) : (
          <Row className="justify-content-center mx-3">
            {plans.map((plan) => (
              <>
                {plan?.plan_name !== "Free Plan" &&
                  plan?.plan_name !== purchase?.plan_detail?.plan_name && (
                    <Col
                      key={plan._id}
                      xs={12}
                      md={3}
                      lg={3}
                      className="my-5 d-flex flex-column interactive-card mx-3"
                      style={{
                        borderTopRightRadius: "30px",
                      }}
                    >
                      <div
                        className="card h-100 premium-cards"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 5px",
                          borderRadius: "20px",
                          border: " 1px solid #152B51",
                        }}
                      >
                        <div
                          className="card-header background-colorsty bgtextwhite "
                          style={{
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                          }}
                        >
                          <b style={{ color: "white" }}>
                            <i
                              className="fa-solid fa-calendar"
                              style={{ marginRight: "10px", fontSize: "20px" }}
                            ></i>
                            {plan?.plan_name}
                          </b>
                        </div>
                        <div className="card-body">
                          <div
                            className="card-body textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                            }}
                          >
                            <b
                              className="mb-5"
                              // style={{ color: "#11cdef", fontSize: "20px" }}
                            >
                              $ {plan.plan_price}
                              <span
                                className="card-title"
                                // style={{ color: "#11cdef", fontSize: "13px" }}
                              >
                                /{plan?.billing_interval}
                              </span>
                            </b>
                          </div>
                          <FeaturesList features={plan?.features} />
                          <br />
                        </div>
                        <div
                          className="text-center"
                          style={{ alignItems: "center", marginBottom: "10px" }}
                        >
                          <Button
                            type="button"
                            className="btn btn-secondary first-button background-colorsty bgtextwhite fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                            onClick={() => upgradePlan(plan)}
                          >
                            Upgrade
                          </Button>
                        </div>

                        <div
                          className="card-footer  text-center"
                          style={{
                            borderBottomLeftRadius: "20px",
                            borderBottomRightRadius: "20px",
                            border: "none",
                            overflow: "hidden",
                          }}
                        >
                          <a
                            className="textcolorblue fontfamilysty"
                            href="#"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Terms apply.
                          </a>
                        </div>
                      </div>
                    </Col>
                  )}
              </>
            ))}
          </Row>
        )}
        <ToastContainer />
      </Container>
    </>
  );
}

export default PlanUpgrade;
