import { CardHeader, Container } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const VendorProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [vendorDetails, setVendorDetails] = useState({});
  let [loader, setLoader] = React.useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getVendorData = async () => {
    if (accessType?.vendor_id) {
      try {
        const response = await axiosInstance.get(
          `/vendor/get_vendor/${accessType.vendor_id}`
        );
        setVendorDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    getVendorData();
  }, [accessType]);

  return (
    <>
      {/* <VendorHeader /> */}
      <Container className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem", }}
      >
        <CardHeader
          className=" mt-3 mb-3 mx-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
        >
          <h2
            className="mb-0 headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Personal Details
          </h2>
        </CardHeader>
        <div>
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <div className="my-table mx-3" style={{ overflowX: "auto" }}>
                <table className="data" style={{ width: "100%" }}>
                  <tr>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">
                      Name
                    </th>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">
                      Phone Number
                    </th>
                    <th className="tablefontstyle tablevendorheadtext textcolorblue">
                      Email
                    </th>
                  </tr>
                  <tr>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">
                      {vendorDetails.vendor_name}
                    </td>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">
                      {vendorDetails.vendor_phoneNumber}
                    </td>
                    <td className="bordertopintd tablebodyfont tablebodyvendortext tabletextcolor">
                      {vendorDetails.vendor_email}
                    </td>
                  </tr>
                </table>
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  );
};
export default VendorProfile;
