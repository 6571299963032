import StaffDashBoard from "views/source/StaffDashBoard";
import StaffProfile from "views/source/StaffProfile";
import StaffSetting from "views/source/StaffSetting";

//  ====================== admin sidebar icons ======================
import Dashboard from "./assets/icons/AdminSidebar/dashboard.svg";
import Dashboard2 from "./assets/icons/AdminSidebar/dashboard2.svg";
import Property from "./assets/icons/AdminSidebar/Property.svg";
import Property2 from "./assets/icons/AdminSidebar/Property2.svg";

import Admin from "./assets/icons/Admin.svg";
import admin2 from "./assets/icons/admin2.svg";
import Work from "./assets/icons/Work.svg";
import Work2 from "./assets/icons/Work Light.svg";
import Key from "./assets/icons/AdminSidebar/Key.svg";
import Thumb from "./assets/icons/AdminSidebar/Thumb.svg";
import Maintenance from "./assets/icons/AdminSidebar/Maintenance.svg";
import Key1 from "./assets/icons/AdminSidebar/Key2.svg";
import Thumb1 from "./assets/icons/AdminSidebar/thumbs.svg";
import Maintenance1 from "./assets/icons/AdminSidebar/Maintenancess.svg";
import Building from "./assets/icons/AdminSidebar/Building.svg";
import Building1 from "./assets/icons/AdminSidebar/Building1.svg";
import Owner from "./assets/icons/AdminSidebar/owner.svg";
import Owner1 from "./assets/icons/AdminSidebar/owner1.svg";
import User from "./assets/icons/AdminSidebar/User.svg";
import User1 from "./assets/icons/AdminSidebar/User1.svg";
import Lease from "./assets/icons/AdminSidebar/Lease.svg";
import Lease1 from "./assets/icons/AdminSidebar/Lease1.svg";
import Applicant from "./assets/icons/AdminSidebar/Applicant.svg";
import Applicant1 from "./assets/icons/AdminSidebar/Applicant1.svg";
import Vendors from "./assets/icons/AdminSidebar/Vendor.svg";
import Vendors1 from "./assets/icons/AdminSidebar/Vendor1.svg";
import Works from "./assets/icons/AdminSidebar/Work.svg";
import Works1 from "./assets/icons/AdminSidebar/Work1.svg";
import Report from "./assets/icons/AdminSidebar/Report.svg";
import Report1 from "./assets/icons/AdminSidebar/Report1.svg";

import Upcomingicon from "./assets/icons/AdminSidebar/upcoming renewal.svg";
import Upcomingicon1 from "./assets/icons/AdminSidebar/upcoming white.svg";
import Communication from "./assets/icons/AdminSidebar/Communication.svg";
import Communication1 from "./assets/icons/AdminSidebar/Communication1.svg";
import Sendemail from "./assets/icons/AdminSidebar/Send Email.svg";
import Sendemail1 from "./assets/icons/AdminSidebar/Send Email1.svg";
import Emaillog from "./assets/icons/AdminSidebar/Email Logs.svg";
import Emaillog1 from "./assets/icons/AdminSidebar/Email Logs1.svg";
import Template from "./assets/icons/AdminSidebar/Templates.svg";
import Template1 from "./assets/icons/AdminSidebar/Templates1.svg";
import RentRollLeaseing from "views/source/RentRollLeaseing";

// new admins
import PropertyType from "views/source/PropertyType";
import Rentals from "views/source/Rentals";
import PropDetails from "views/source/PropDetails";
import AddPropertyType from "views/source/AddPropertyType";
import RentalownerTable from "views/source/RentalownerTable";
import TenantsTable from "views/source/TenantsTable";
import RentRoll from "views/source/RentRoll";
import Applicants from "views/source/Applicants";
import UpcomingRenewal from "views/source/UpcomingRenewal";
import TenantDetailPage from "views/source/TenantDetailPage";
import Leaseing from "views/source/Leaseing";
import RentRollDetail from "views/source/RentRollDetail";
import ApplicantSummary from "views/source/ApplicantSummary";
import Rentalowner from "views/AdminViews/Rentals/ManageRentalOnwer";
import RentalOwnerDetail from "views/AdminViews/Rentals/RentalOwnerDetail";
import AddPayment from "views/source/AddPayment";
import AddCharge from "views/source/AddCharge";
import Vendor from "views/source/Vendor";
import AddVendor from "views/source/AddVendor";
import AddWorkorder from "views/source/AddWorkorder";
import Workorder from "views/source/Workorder";
import WorkOrderDetails from "views/source/WorkOrderDetails";
import Reports from "views/source/Reports";
import RentersInsurance from "views/source/RentersInsurance";
import ExpiringLease from "views/source/ExpiringLease";
import DelinquentTenants from "views/source/DelinquentTenants";
import OpenWorkReport from "views/source/OpenWorkReport";
import CompletedWorkReport from "views/source/CompletedWorkReport";
import Dailytransactionreport from "views/source/DailyTransactionReport";
import Rentalownerreport from "views/source/Rentalownerreport";
import Templates from "views/source/Templates";
import AddTemplates from "views/source/AddTemplate";
import Emails from "views/source/Emails";
import Emaillogs from "views/source/Emaillogs";
import PropertiesTables from "views/source/PropertiesTable";
import PaymentReport from "views/Payment-Exception-Report/Report";
import RecurringReport from "views/Recurring-Payment-Report/Report";
import ConvenienceFeeOverrideReport from "views/Convenience-Fee-Override-Report/Report";

var routes = [
  {
    path: "/StaffdashBoard",
    name: "DashBoard",
    icon: Dashboard,
    icon2: Dashboard2,
    component: <StaffDashBoard />,
    layout: "/staff",
    is_display: true,
  },
  {
    path: "/staffprofile",
    name: "Profile",
    icon: Admin,
    icon2: admin2,
    component: <StaffProfile />,
    layout: "/staff",
    is_display: true,
  },
  {
    path: "/staffpropertytype",
    name: "Property Type",
    icon: Property,
    icon2: Property2,
    component: <PropertyType />,
    layout: "/staff",
    is_display: true,
  },

  {
    name: "Rentals",
    icon: Key,
    icon2: Key1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        path: "/staffrentals",
        name: "Properties",
        icon: Building,
        icon2: Building1,
        component: <PropertiesTables />,
        layout: "/staff",
        is_display: true,
      },
      {
        path: "/staffrentalownertable",
        name: "Rental Owner",
        icon: Owner,
        icon2: Owner1,
        component: <RentalownerTable />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Tenants",
        path: "/stafftenanttable",
        icon: User,
        icon2: User1,
        component: <TenantsTable />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    name: "Leasing",
    icon: Thumb,
    icon2: Thumb1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Rent Roll",
        path: "/staffrentroll",
        icon: Lease,
        icon2: Lease1,
        component: <RentRoll />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Applicants",
        path: "/staffapplicanttable",
        icon: Applicant,
        icon2: Applicant1,
        component: <Applicants />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Upcoming Renewal",
        path: "/staffupcomingrenewal",
        icon: Upcomingicon,
        icon2: Upcomingicon1,
        component: <UpcomingRenewal />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    name: "Maintenance",
    icon: Maintenance,
    icon2: Maintenance1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Vendor",
        path: "/staffvendor",
        icon: Vendors,
        icon2: Vendors1,
        component: <Vendor />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Work Order",
        path: "/staffworktable",
        icon: Works,
        icon2: Works1,
        component: <Workorder />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    name: "Add Template",
    path: "/add-template",
    component: <AddTemplates />,
    layout: "/staff",
    is_display: false,
  },
  {
    name: "Communication",
    icon: Communication,
    icon2: Communication1,
    layout: "/staff",
    is_display: true,
    is_dropdown: true,
    childers: [
      {
        name: "Send E-mail",
        path: "/emails",
        icon: Sendemail,
        icon2: Sendemail1,
        component: <Emails />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "E-mail Logs",
        path: "/email-logs",
        icon: Emaillog,
        icon2: Emaillog1,
        component: <Emaillogs />,
        layout: "/staff",
        is_display: true,
      },
      {
        name: "Templates",
        path: "/templates",
        icon: Template,
        icon2: Template1,
        component: <Templates />,
        layout: "/staff",
        is_display: true,
      },
    ],
  },
  {
    path: "/staffreports",
    name: "Reports",
    icon: Report,
    icon2: Report1,
    component: <Reports />,
    layout: "/staff",
    is_display: true,
  },

  {
    path: "/staffaddrentroll",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffrentrolldetail/:lease_id",
    name: "Staffrentrolldetail",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentroll/:lease_id/:applicant_id",
    name: "StaffAddRentRoll",
    component: <RentRollLeaseing />,
    layout: "/staff",
  },
  {
    path: "/staffapplicantsummary/:id",
    name: "StaffApplicantsummary",
    component: <ApplicantSummary />,
    layout: "/staff",
  },
  {
    path: "/stafftenantdetail/:id",
    name: "Tenant Detail",
    component: <TenantDetailPage />,
    layout: "/staff",
  },
  {
    path: "/staffedittenant/:tenant_id",
    name: "Tenant Edit",
    component: <Leaseing />,
    layout: "/staff",
  },
  {
    path: "/staffaddtenant",
    name: "Tenant Add",
    component: <Leaseing />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd",
    name: "Staff Property Add",
    component: <Rentals />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype",
    name: "Staff Add Property Type",
    component: <AddPropertyType />,
    layout: "/staff",
  },
  {
    path: "/staffaddpropertytype/:id",
    name: "Staff Add Property Type",
    component: <AddPropertyType />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner",
    name: "Staff Add Rental Owner",
    component: <Rentalowner />,
    layout: "/staff",
  },
  {
    path: "/staffaddrentalowner/:id",
    name: "Staff Add Rental Owner",
    component: <Rentalowner />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail",
    name: "Staff Rental Owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/staff",
  },
  {
    path: "/staffrentalownerdetail/:id",
    name: "Staff Rental Owner Detail",
    component: <RentalOwnerDetail />,
    layout: "/staff",
  },
  // {
  //   path: "/stafftenantdetail/:tenant_id",
  //   name: "Tenant Detail",
  //   component: <StaffTenantDetail />,
  //   layout: "/staff",
  // },
  // {
  //   path: "/staffedittenant/:tenant_id",
  //   name: "Tenant Edit",
  //   component: <StaffEditTenant />,
  //   layout: "/staff",
  // },
  // {
  //   path: "/staffaddtenant",
  //   name: "Tenant Add",
  //   component: <StaffEditTenant />,
  //   layout: "/staff",
  // },
  {
    path: "/staffaddvendor",
    name: "Staff Add Vendor",
    component: <AddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffaddvendor/:vendor_id",
    name: "Staff Add Vendor",
    component: <AddVendor />,
    layout: "/staff",
  },
  {
    path: "/staffpropertyadd/:rental_id",
    name: "Staff Property Edit",
    component: <Rentals />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id",
    name: "Staff Add Payment",
    component: <AddPayment />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id",
    name: "Staff Add Charge",
    component: <AddCharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddcharge/:lease_id/:charge_id",
    name: "Staff Add Charge",
    component: <AddCharge />,
    layout: "/staff",
  },
  {
    path: "/staffaddpayment/:lease_id/:payment_id",
    name: "Staff Add Payment",
    component: <AddPayment />,
    layout: "/staff",
  },
  {
    path: "/staffpropertydetail/:rental_id",
    name: "Property",
    component: <PropDetails />,
    layout: "/staff",
  },
  {
    path: "/staffworktable?status",
    name: "Work Order",
    icon: Work,
    icon2: Work2,
    component: <Workorder />,
    layout: "/staff",
  },
  {
    name: "Staff Renters Insurance",
    path: "/staffrentersinsurance",
    component: <RentersInsurance />,
    layout: "/staff",
  },
  {
    name: "Staff Delinquent Tenants",
    path: "/staffdelinquenttenants",
    component: <DelinquentTenants />,
    layout: "/staff",
  },
  {
    name: "Staff Expiring Lease",
    path: "/staffexpiringleases",
    component: <ExpiringLease />,
    layout: "/staff",
  },
  {
    name: "Staff Open Work Orders",
    path: "/staffopenworkorders",
    component: <OpenWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Completed Work Orders",
    path: "/staffcompletedWorkorders",
    component: <CompletedWorkReport />,
    layout: "/staff",
  },
  {
    name: "Staff Daily Transaction Report",
    path: "/staffdailytransactionreport",
    component: <Dailytransactionreport />,
    layout: "/staff",
  },
  {
    name: "Staff Rental Owner Report",
    path: "/staffrentalownerreport",
    component: <Rentalownerreport />,
    layout: "/staff",
  },
  {
    name: "Staff Payment Exception Report",
    path: "/staffpaymentexceptionreport",
    component: <PaymentReport />,
    layout: "/staff",
  },
  {
    name: "Staff Recurring Payments Configuration",
    path: "/staffrecurringpaymentsconfiguration",
    component: <RecurringReport />,
    layout: "/staff",
  },
  {
    name: "Staff Convenience Fee Override",
    path: "/staffconveniencefeeoverride",
    component: <ConvenienceFeeOverrideReport />,
    layout: "/staff",
  },
  {
    path: "/addworkorder/:id",
    component: <AddWorkorder />,
    layout: "/staff",
  },
  {
    path: "/addworkorder",
    component: <AddWorkorder />,
    layout: "/staff",
  },
  {
    path: "/staffworkdetails/:workorder_id",
    name: "Work Order Detail",
    component: <WorkOrderDetails />,
    layout: "/staff",
  },
  {
    path: "/staffsetting",
    name: "Staff Setting",
    component: <StaffSetting />,
    layout: "/staff",
  },
];

export default routes;
