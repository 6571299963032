import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Card, Row, Col } from "reactstrap";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import axiosInstance from "axiosInstance";

const StaffApplicantForm = ({ applyId, setShowApplicantForm }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { id, admin } = useParams();

  useEffect(() => {
    const sidenavMain = document.getElementById("sidenav-main");
    const headerElement = document.querySelector(".header");
    if (sidenavMain) {
      sidenavMain.style.display = "none";
    }
    if (headerElement) {
      headerElement.style.display = "none";
    }
    return () => {
      if (sidenavMain) {
        sidenavMain.style.display = "block";
      }
      if (headerElement) {
        headerElement.style.display = "block";
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(
            `/applicant/applicant_details/${id}`
          );
          setFormData(response.data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [id]);

  const [formData, setFormData] = useState({
    applicant_id: "",
    admin_id: "",

    applicant_firstName: "",
    applicant_lastName: "",
    applicant_email: "",
    applicant_phoneNumber: "",
    applicant_birthDate: "",
    applicant_streetAddress: "",
    applicant_city: "",
    applicant_state: "",
    applicant_country: "",
    applicant_postalCode: "",
    agreeBy: "",

    emergency_contact: {
      first_name: "",
      last_name: "",
      relationship: "",
      email: "",
      phone_number: "",
    },

    rental_history: {
      rental_adress: "",
      rental_city: "",
      rental_state: "",
      rental_country: "",
      rental_postcode: "",
      start_date: "",
      end_date: "",
      rent: "",
      leaving_reason: "",
      rentalOwner_firstName: "",
      rentalOwner_lastName: "",
      rentalOwner_primaryEmail: "",
      rentalOwner_phoneNumber: "",
    },

    employment: {
      name: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      employment_primaryEmail: "",
      employment_phoneNumber: "",
      employment_position: "",
      supervisor_firstName: "",
      supervisor_lastName: "",
      supervisor_title: "",
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const object = { ...formData, applicant_id: id || applyId };
      const url = `/applicant/application/${id || applyId}`;
      const response = await axiosInstance.post(url, object);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: "top-center",
        });
        setShowApplicantForm(false);
        // navigate(`/staff/staffapplicantsummary/${id}`);
      } else {
        toast.error(response.data.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleApplicantChange = (e) => {
    const { name, value } = e.target;
    const [mainField, nestedField] = name.split(".");
    if (nestedField) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [mainField]: {
          ...prevFormData[mainField],
          [nestedField]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      {/* <Container style={{ paddingLeft: 30, paddingRight: 30 }}> */}
      <Container
        className=""
        fluid
        style={{
          marginTop: "3rem",
          height: "100vh",
          paddingLeft: "6% ",
          paddingRight: "2%",
        }}
      >
        <Box>
          <section
            // className=" justify-content-center align-items-center"
            style={{
              backgroundColor: "white",
              zIndex: 10000,
              borderRadius: 15,
            }}
          >
            <div
              style={{
                marginRight: "auto",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                msOverflowStyle: "none",
                maxHeight: "100vh",
                paddingRight: "10px",
              }}
            >
              <form style={{ width: "100%" }}>
                {/* 5150 start  */}
                <div>
                  <h2
                    className="labelfontstyle textcolorblue fontfamilysty"
                    // className="d-flex justify-content-start"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Applicant information
                  </h2>
                  <Card
                    className="cardstyborderradius"
                    style={{
                      border: "1px solid #324567",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      padding: "0px 23px",
                    }}
                  >
                    <div className="form-row  mt-4">
                      <div className="col-lg-3  applicantmarginbottomstyle">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          First name
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter first name "
                          name="applicant_firstName"
                          value={formData?.applicant_firstName}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Last name
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter last name..."
                          name="applicant_lastName"
                          value={formData?.applicant_lastName}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Email
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Applicant email fontstylerentmodal"
                          name="applicant_email"
                          value={formData?.applicant_email}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Phone Number
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="Number"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Applicant phone number..."
                          name="applicant_phoneNumber"
                          value={formData?.applicant_phoneNumber}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-3 mb-3">
                      <div className="col-lg-3">
                        <label
                          htmlFor="applicantHomePhone"
                          className="fontstylerentr titleecolor fontfamilysty"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Applicant birth date
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="date"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="birthdate"
                          name="applicant_birthDate"
                          value={formData?.applicant_birthDate}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                  </Card>
                  <div className="mt-4">
                    <h2
                      className="labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Applicant Street Address
                    </h2>
                  </div>
                  <Card
                    className="cardstyborderradius"
                    style={{
                      border: "1px solid #324567",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      padding: "0px 23px",
                    }}
                  >
                    <div className="form-row mt-4">
                      <div className="col-lg-3">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Street Address..."
                          name="applicant_streetAddress"
                          value={formData?.applicant_streetAddress}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="City"
                          name="applicant_city"
                          value={formData?.applicant_city}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 ">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="State"
                          name="applicant_state"
                          value={formData?.applicant_state}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Country"
                          name="applicant_country"
                          value={formData?.applicant_country}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col-lg-3 mb-3">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Zip"
                          name="applicant_postalCode"
                          value={formData?.applicant_postalCode}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                  </Card>
                  <h2
                    className="mt-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Emergency contact
                  </h2>
                  <Card
                    className="cardstyborderradius"
                    style={{
                      border: "1px solid #324567",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      padding: "0px 23px",
                    }}
                  >
                    <div>
                      <div className="form-row mt-4">
                        <div className="col-lg-3 form-group applicantmarginbottomstyle">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="firstName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            First name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter first name..."
                            name="emergency_contact.first_name"
                            value={formData?.emergency_contact?.first_name}
                            onChange={handleApplicantChange}
                          />
                        </div>

                        <div className="col-lg-3 form-group">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="lastName"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Last name
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter last name..."
                            name="emergency_contact.last_name"
                            value={formData?.emergency_contact?.last_name}
                            onChange={handleApplicantChange}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="emergencyContactRelationship"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Relationship
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="text"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter relationship"
                            name="emergency_contact.relationship"
                            value={formData?.emergency_contact?.relationship}
                            onChange={handleApplicantChange}
                          />
                        </div>
                        <div className="col-lg-3 applicantmarginbottomstyle">
                          <label
                            className="fontstylerentr titleecolor fontfamilysty"
                            htmlFor="emergencyContactRelationship"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Email
                          </label>
                          <input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            type="email"
                            className="form-control fontstylerentmodal titleecolor"
                            placeholder="Enter email"
                            name="emergency_contact.email"
                            value={formData?.emergency_contact?.email}
                            onChange={handleApplicantChange}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="form-row mt-3">
                          <div className="col-lg-3 mb-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="applicantHomePhone"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Phone number
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="Number"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter phone number"
                              name="emergency_contact.phone_number"
                              value={formData?.emergency_contact?.phone_number}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <h2
                    className="mt-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Rental history
                  </h2>
                  <Card
                    className="cardstyborderradius"
                    style={{
                      border: "1px solid #324567",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      padding: "20px 23px",
                    }}
                  >
                    <div className="form-row mt-4">
                      <div className="col-lg-3">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Street Address..."
                          name="rental_history.rental_adress"
                          value={formData?.rental_history?.rental_adress}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="City"
                          name="rental_history.rental_city"
                          value={formData?.rental_history?.rental_city}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="State"
                          name="rental_history.rental_state"
                          value={formData?.rental_history?.rental_state}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Street Address"
                          name="rental_history.rental_country"
                          value={formData?.rental_history?.rental_country}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col-lg-3">
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Zip"
                          name="rental_history.rental_postcode"
                          value={formData?.rental_history?.rental_postcode}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="firstName"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Start date
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="date"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter start date"
                          id="firstName"
                          name="rental_history.start_date"
                          value={formData?.rental_history?.start_date}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="Enddate "
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          End date
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="date"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter end date"
                          id="lastName"
                          name="rental_history.end_date"
                          value={formData?.rental_history?.end_date}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="emergencyContactRelationship"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Monthly rent
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="number"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder=" Enter monthly rent"
                          name="rental_history.rent"
                          value={formData?.rental_history?.rent}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="emergencyContactRelationship"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Reason for leaving
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter reason for leaving"
                          name="rental_history.leaving_reason"
                          value={formData?.rental_history?.leaving_reason}
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                  </Card>

                  <div className="mt-4">
                    <h2
                      className="labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Rental owner information
                    </h2>
                  </div>
                  <Card
                    className="cardstyborderradius"
                    style={{
                      border: "1px solid #324567",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      padding: "0px 23px",
                    }}
                  >
                    <div className="form-row mt-4">
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="firstName"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Rental owner name
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter rental owner name "
                          name="rental_history.rentalOwner_firstName"
                          value={
                            formData?.rental_history?.rentalOwner_firstName
                          }
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="lastName"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Last name
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="text"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter last name"
                          name="rental_history.rentalOwner_lastName"
                          value={formData?.rental_history?.rentalOwner_lastName}
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 applicantmarginbottomstyle">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="emergencyContactRelationship"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Rental owner email
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="email"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter rental owner email"
                          name="rental_history.rentalOwner_primaryEmail"
                          value={
                            formData?.rental_history?.rentalOwner_primaryEmail
                          }
                          onChange={handleApplicantChange}
                        />
                      </div>
                      <div className="col-lg-3 mb-3">
                        <label
                          className="fontstylerentr titleecolor fontfamilysty"
                          htmlFor="firstName"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Rental owner phone number
                        </label>
                        <input
                          style={{
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            borderRadius: "6px",
                          }}
                          type="number"
                          className="form-control fontstylerentmodal titleecolor"
                          placeholder="Enter phone number "
                          name="rental_history.rentalOwner_phoneNumber"
                          value={
                            formData?.rental_history?.rentalOwner_phoneNumber
                          }
                          onChange={handleApplicantChange}
                        />
                      </div>
                    </div>
                  </Card>
                  <div className="mt-4">
                    <h2
                      className="labelfontstyle textcolorblue fontfamilysty"
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Employment
                    </h2>
                    <div>
                      <Card
                        className="mb-3 cardstyborderradius"
                        style={{
                          border: "1px solid #324567",
                          boxShadow: "0px 4px 4px 0px #00000040",
                          padding: "20px 23px",
                        }}
                      >
                        <div className="form-row mt-4">
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Employer name
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Enter employer name"
                              name="employment.name"
                              value={formData?.employment?.name}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                        <div className="form-row mt-3">
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Employer Street address
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Street Address..."
                              name="employment.streetAddress"
                              value={formData?.employment?.streetAddress}
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div
                            className="col-lg-3  applicantmarginbottomstyle"
                            style={{ marginTop: "30px" }}
                          >
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="City"
                              name="employment.city"
                              value={formData?.employment?.city}
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div
                            className="col-lg-3"
                            style={{ marginTop: "30px" }}
                          >
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="State"
                              name="employment.state"
                              value={formData?.employment?.state}
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div
                            className="col-lg-3 applicantmarginbottomstyle"
                            style={{ marginTop: "30px" }}
                          >
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Country"
                              name="employment.country"
                              value={formData?.employment?.country}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                        <div className="form-row mt-3">
                          <div className="col-lg-3">
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Zip"
                              name="employment.postalCode"
                              value={formData?.employment?.postalCode}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                        <div className="form-row mt-3">
                          <div className="col-lg-3 applicantmarginbottomstyle">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Employer email
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="email"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Employer email..."
                              name="employment.employment_primaryEmail"
                              value={
                                formData?.employment?.employment_primaryEmail
                              }
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="firstName"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Employer phone number
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="number"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Employer phone number..."
                              name="employment.employment_phoneNumber"
                              value={
                                formData?.employment?.employment_phoneNumber
                              }
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Position held
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Position held..."
                              name="employment.employment_position"
                              value={formData?.employment?.employment_position}
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div className="col-lg-3 applicantmarginbottomstyle">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="firstName"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Supervisor first name
                            </label>
                            <input
                              type="text"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Supervisor first name..."
                              name="employment.supervisor_firstName"
                              value={formData?.employment?.supervisor_firstName}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                        <div className="form-row mt-3">
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="lastName"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Supervisor last name
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="text"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Supervisor last name..."
                              name="employment.supervisor_lastName"
                              value={formData?.employment?.supervisor_lastName}
                              onChange={handleApplicantChange}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              htmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Supervisor title
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="email"
                              className="form-control fontstylerentmodal titleecolor"
                              placeholder="Supervisor title..."
                              name="employment.supervisor_title"
                              value={formData?.employment?.supervisor_title}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="mt-4">
                      <h2
                        className="barforsummaryrent textcolorblue fontfamilysty"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        Terms and conditions
                      </h2>

                      <div>
                        <div className="form-row mt-3 pl-2">
                          <p
                            className="labelfontstyle titleecolor fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#979797",
                            }}
                          >
                            I understand that this is a routine application to
                            establish credit, character, employment, and rental
                            history. I also understand that this is NOT an
                            agreement to rent and that all applications must be
                            approved. I authorize verification of references
                            given. I declare that the statements above are true
                            and correct, and I agree that the Rental owner may
                            terminate my agreement entered into in reliance on
                            any misstatement made above.
                          </p>
                        </div>

                        <div className="form-row mt-4 pl-4">
                          <div className="col-md-12">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="checked"
                            />
                            <label
                              className="form-check-label fontstylerentr titleecolor fontfamilysty"
                              for="defaultCheck1"
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              Agreed to*
                            </label>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="form-row mt-4">
                          <div className="col">
                            <label
                              className="fontstylerentr titleecolor fontfamilysty"
                              chtmlFor="emergencyContactRelationship"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Agreed by
                            </label>
                            <input
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                              }}
                              type="email"
                              className="form-control fontstylerentmodal"
                              placeholder="Agreed by..."
                              name="agreeBy"
                              value={formData?.agreeBy}
                              onChange={handleApplicantChange}
                            />
                          </div>
                        </div>
                      </div>
                      {/* 6002 change below text by (1) I  */}
                      <div>
                        <div className="form-row mt-4 pl-2">
                          <p
                            className="labelfontstyle titleecolor fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            By submitting this application, (1) I am giving
                            permission to run a background check on me, which
                            may include obtaining my credit report from a
                            consumer reporting agency, and (2) agreeing to the{" "}
                            <span
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                              }}
                            >
                              {" "}
                              Privacy Policy{" "}
                            </span>{" "}
                            and{" "}
                            <span
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "700",
                              }}
                            >
                              {" "}
                              Terms of Service.{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-3 mb-4 d-flex flex-column flex-sm-row"> */}
                  <Row>
                    <Col lg={8} sm={8} md={8} xl={8} xs={12}>
                      <button
                        type="button"
                        className="btn mb-3 mb-sm-0 mr-sm-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                        style={{
                          borderRadius: "6px",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                        onClick={handleSubmit}
                      >
                        Save Application
                      </button>
                      {/* <button
                      type="button"
                      className="btn"
                      style={{ borderRadius: "6px",color:"#152B51" ,backgroundColor:"white",fontFamily:"Poppins",fontSize:"16px",fontWeight:"500"}}
                    >
                      Cancel
                    </button> */}
                      {/* </div> */}
                    </Col>
                  </Row>
                  <br />
                </div>
                {/* 5150 end  */}
              </form>
            </div>
          </section>
        </Box>
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffApplicantForm;
