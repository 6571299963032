import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  FormGroup,
  TextField,
  Typography,
  Grid,
  Switch,
} from "@mui/material";
import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Input,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Tab from "@mui/material/Tab";
import LogoutIcon from "@mui/icons-material/Logout";
import fone from "../../assets/img/icons/common/property_bg.png";
import { RotatingLines } from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import editicon from "../../assets/img/icons/common/editicon.svg";
import profilei from "../../assets/img/icons/common/profilewhite.svg";
import phone from "../../assets/img/icons/common/phone.svg";
import email from "../../assets/img/icons/common/email.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaCheck, FaTimes } from "react-icons/fa";
import deleicon from "../../assets/img/icons/common/delete.svg";
import { Checkbox } from "@mui/material";
//financial
import { todayDate, handleImageChange } from "./Functions/Financial";
import DoneIcon from "@mui/icons-material/Done";
//units
import {
  formatDateWithoutTime,
  handleDeleteUnit,
  handleSubmit,
  UnitEdite,
  handleUnitDetailsEdit,
  addAppliancesSubmit,
  editeAppliancesSubmit,
  deleteAppliance,
} from "./Functions/Units";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./propdetail.css";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { verifyToken } from "components/Functions/Functions";
import RecurringTenants from "./Functions/RecurringTenants";
import moment from "moment";

const StaffPropertyDetail = () => {
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;

  const location = useLocation();
  const navigate = useNavigate();

  const { rental_id, admin } = useParams();

  const [value, setValue] = React.useState("summary");
  const [rentalData, setRentalData] = useState();
  const [rentalOwnerData, setRentalOwnerData] = useState("");
  const [propertyTypeData, setPropertyTypeData] = useState("");
  const [propertyUnitData, setpropertyUnitData] = useState("");
  const [tenantsData, setTenantsData] = useState([]);
  const [tenantsCount, setTenantsCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageItem, setPageItem] = useState(10);
  const [workData, setWorkOrderData] = useState([]);
  const [staffMemberData, setStaffMemberData] = useState("");
  const [clickedUnitObject, setClickedUnitObject] = useState([]);
  const [applianceData, setApplianceData] = useState([]);
  const [propImageLoader, setPropImageLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [unitloader, setUnitLoader] = useState(true);
  const [tenantloader, setTenantLoader] = useState(true);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [unitImageLoader, setUnitImageLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [propSummary, setPropSummary] = useState(false);
  const [addAppliances, setAddAppliances] = useState(false);
  const [unitLeases, setunitLeases] = useState([]);
  const [isPhotoresDialogOpen, setPhotoresDialogOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openEdite, setOpenEdite] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [sortedColumn, setSortedColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hoveredColumn, setHoveredColumn] = useState("createdAt");
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [userroleData, setUserroleData] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  useEffect(() => {
    if (location?.state?.tab) {
      setValue(location?.state?.tab);
    }
  }, [location?.state?.tab]);

  const fetchRentalData = async () => {
    if (rental_id) {
      try {
        const url = `/rentals/rental_summary/${rental_id}`;
        const response = await axiosInstance.get(url);
        setRentalData(response.data?.data[0]);
        setPropertyTypeData(response.data?.data[0].property_type_data);
        setRentalOwnerData(response.data?.data[0].rental_owner_data);
        setStaffMemberData(response.data?.data[0].staffmember_data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setLoader(false);
      }
      setPropImageLoader(false);
    }
  };

  const fetchUnitsData = async () => {
    if (rental_id) {
      try {
        const response = await axiosInstance.get(
          `/unit/rental_unit/${rental_id}`
        );
        setpropertyUnitData(response.data?.data);
        {
          !propertyTypeData?.is_multiunit
            ? setClickedUnitObject(response.data?.data[0] || "")
            : setClickedUnitObject("");
        }
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setUnitLoader(false);
      }
      setUnitImageLoader(false);
    }
  };

  const fetchTenantData = async () => {
    if (rental_id) {
      try {
        const response = await axiosInstance.get(
          `/tenant/rental_tenant/${rental_id}`
        );
        setTenantsData(response.data?.data || []);
        setTenantsCount(response.data?.count || 0);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setTenantLoader(false);
      }
    }
  };

  const fetchWorkOrderData = async () => {
    if (rental_id) {
      try {
        const response = await axiosInstance.get(
          `/work-order/rental_workorder/${rental_id}`
        );

        const workOrderData = response.data?.data || [];
        setWorkOrderData(workOrderData);

        const incompleteTasks = workOrderData.filter(
          (task) => task.status !== "Completed"
        );

        const data1 = Math.ceil(incompleteTasks.length / pageItem);
        const data2 = Math.ceil(
          response.data?.complete_data?.length / pageItem
        );

        setTotalPages(!showCompletedTasks ? data1 : data2);
        setWorkCount(response.data?.count || 0);
        setCompleteCount(response.data?.complete_count || 0);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };
  const fatchunit = async () => {
    if (clickedUnitObject?.unit_id) {
      try {
        const response = await axiosInstance.get(
          `/leases/unit_leases/${clickedUnitObject?.unit_id}`
        );
        setunitLeases(response.data?.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  const [state, setState] = useState({});
  const [isDataReady, setIsDataReady] = useState(false);

  const handleSate = () => {
    if (clickedUnitObject?.unit_id && rentalData?.rental_adress) {
      const data = {
        rental_adress: rentalData?.rental_adress,
        unit_id: clickedUnitObject?.unit_id,
        rental_unit: clickedUnitObject?.rental_unit,
      };
      setState(data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fatchunit();
      await fetchRentalData();
      setIsDataReady(true);
    };

    fetchData();
  }, [rental_id, clickedUnitObject.unit_id]);

  useEffect(() => {
    if (isDataReady) {
      handleSate();
    }
  }, [clickedUnitObject, isDataReady]);

  const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (today >= start && today <= end) {
      return "Active";
    } else if (today < start) {
      return "Inactive";
    } else if (today > end) {
      return "Inactive";
    } else {
      return "-";
    }
  };

  useEffect(() => {
    fetchRentalData();
    fetchUnitsData();
    fetchTenantData();
    fetchWorkOrderData();
  }, [rental_id, showCompletedTasks, pageItem]);

  const fetchApplianceData = async () => {
    if (clickedUnitObject?.unit_id) {
      try {
        const response = await axiosInstance.get(
          `/appliance/appliance/${clickedUnitObject.unit_id}`
        );
        setApplianceData(response.data?.data);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  useEffect(() => {
    fetchApplianceData();
  }, [clickedUnitObject]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addUnitFormik = useFormik({
    initialValues: {
      rental_unit: "",
      rental_unit_adress: "",
      rental_sqft: "",
      rental_bath: "",
      rental_bed: "",
      rental_images: [],
    },

    onSubmit: async (values) => {
      try {
        let res;

        if (clickedUnitObject.unit_id) {
          res = await handleUnitDetailsEdit(
            clickedUnitObject?.unit_id,
            values,
            selectedFiles
          );
        } else {
          res = await handleSubmit(
            rentalData?.rental_id,
            accessType?.admin_id,
            values,
            selectedFiles
          );
        }

        if (res === false) {
          setOpenEdite(false);
          addUnitFormik.resetForm();
          fetchUnitsData();
          setClickedUnitObject([]);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
  });

  const addAppliancesFormin = useFormik({
    initialValues: {
      appliance_name: "",
      appliance_description: "",
      installed_date: "",
      appliance_id: "",
    },
    validationSchema: yup.object({
      appliance_name: yup.string().required("Appliance Name Required"),
      appliance_description: yup.string().required("Descriprion Required"),
      installed_date: yup.date().required("Installed Date Required"),
    }),
    onSubmit: async (values) => {
      try {
        let res;
        if (values.appliance_id === "") {
          res = await addAppliancesSubmit(
            clickedUnitObject.unit_id,
            accessType?.admin_id,
            values
          );
        } else {
          res = await editeAppliancesSubmit(values);
        }

        if (res === false) {
          setAddAppliances(!addAppliances);
          addAppliancesFormin.resetForm();
          fetchApplianceData();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
  });

  const closeModal = () => {
    setOpenEdite(false);
    addUnitFormik.resetForm();
    {
      propertyTypeData?.is_multiunit && setClickedUnitObject([]);
    }
  };

  const openEditeTab = async (event, unit) => {
    event.stopPropagation();
    setOpenEdite(true);
    setClickedUnitObject(unit);
    setSelectedFiles(unit?.rental_images);
    addUnitFormik.setValues({
      rental_unit: unit?.rental_unit,
      rental_unit_adress: unit?.rental_unit_adress,
      rental_sqft: unit?.rental_sqft,
      rental_bath: unit?.rental_bath,
      rental_bed: unit?.rental_bed,
      rental_images: unit?.rental_images,
    });
  };

  const clearSelectedPhoto = (index, name) => {
    if (name === "rental_images") {
      const filteredImage2 = selectedFiles.filter((item, i) => i !== index);
      setSelectedFiles(filteredImage2);
    }
  };

  const fileData = (e) => {
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...e.target.files,
    ]);
  };

  const togglePhotoresDialog = () => {
    setPhotoresDialogOpen((prevState) => !prevState);
  };

  const countTenantsByUnit = () => {
    for (const tenant of tenantsData) {
      for (const unit of propertyUnitData) {
        if (tenant.unit_id === unit.unit_id) {
        }
      }
    }
  };

  useEffect(() => {
    countTenantsByUnit();
  }, [tenantsData, propertyUnitData]);

  // =====================================================================

  const [showModal, setShowModal] = useState(false);
  const [clickedObject, setClickedObject] = useState({});
  const handleMoveOutClick = (tenant) => {
    setClickedObject(tenant);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  // ============================================================================

  const [moveOutDate, setMoveOutDate] = useState("");
  const [noticeGivenDate, setNoticeGivenDate] = useState("");

  useEffect(() => {
    // Set noticeGivenDate to the current date when the component mounts
    const currentDate = new Date().toISOString().split("T")[0];
    setNoticeGivenDate(currentDate);
  }, []);
  const handleMoveout = (lease_id, tenant_id) => {
    if (moveOutDate && noticeGivenDate) {
      const updatedApplicant = {
        moveout_date: moveOutDate,
        moveout_notice_given_date: noticeGivenDate,
        tenant_id: tenant_id,
      };

      axiosInstance
        .post(`/moveout/lease_moveout/${lease_id}`, updatedApplicant)
        .then((res) => {
          if (res.data?.statusCode === 200) {
            toast.success("Move-out Successfully", {
              position: "top-center",
              autoClose: 500,
            });
            handleModalClose();
            fetchTenantData();
          }
        })
        .catch((err) => {
          toast.error("An error occurred while Move-out", {
            position: "top-center",
            autoClose: 500,
          });
          console.error(err);
        });
    } else {
      toast.error("NOTICE GIVEN DATE && MOVE-OUT DATE must be required", {
        position: "top-center",
        autoClose: 500,
      });
    }
  };

  const freetrialData = () => {
    toast.warning("You can not perform any action on Demo Data", {
      position: "top-center",
      autoClose: 1000,
    });
  };

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData = [];
  if (workData) {
    paginatedData = workData?.slice(startIndex, endIndex);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle sorting when a column header is clicked
  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...workData];
    switch (column) {
      case "rental_adress":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_adress.localeCompare(b.rental_adress)
            : b.rental_adress.localeCompare(a.rental_adress)
        );
        break;
      case "work_subject":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_subject.localeCompare(b.work_subject)
            : b.work_subject.localeCompare(a.work_subject)
        );
        break;
      case "work_category":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.work_category.localeCompare(b.work_category)
            : b.work_category.localeCompare(a.work_category)
        );
        break;
      case "staffmember_name":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.staffmember_name.localeCompare(b.staffmember_name)
            : b.staffmember_name.localeCompare(a.staffmember_name)
        );
        break;
      case "createdAt":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      default:
        break;
    }
    setWorkOrderData(sortedData || []);
  };

  // Filter and sort data based on search query and sorting state

  const filterRentalsBySearch = () => {
    let filteredData = [...workData];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery?.toString().toLowerCase();
      filteredData = filteredData?.filter((work) => {
        return (
          (work.rental_adress &&
            work.rental_adress.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.work_subject &&
            work.work_subject.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.work_category &&
            work.work_category.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (work.staffmember_name &&
            work.staffmember_name
              .toLowerCase()
              .includes(lowerCaseSearchQuery)) ||
          (work.createdAt &&
            work.createdAt.toLowerCase().includes(lowerCaseSearchQuery))
        );
      });
    }
    if (!sortedColumn) {
      // If no sorting column is specified, sort by createdAt in descending order
      filteredData?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    if (!showCompletedTasks) {
      filteredData = filteredData?.filter(
        (work) => work.status !== "Completed"
      );
    } else {
      filteredData = filteredData?.filter(
        (work) => work.status === "Completed"
      );
    }

    return filteredData;
  };
  // Paginate the filtered and sorted data
  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterRentalsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const navigateToDetails = (workorder_id) => {
    navigate(`/staff/staffworkdetails/${workorder_id}`, {
      state: { rental_id: rentalData.rental_id },
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.staff_permission);
      }
    };

    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setMoveOutDate(currentDate);
  }, []);

  const editProperty = (id) => {
    navigate(`/staff/staffpropertyadd/${id}`, { state: { id: id } });
  };

  const [tenantCardsModal, setTenantCardsModal] = useState(false);
  const cardToggle = () => setTenantCardsModal(!tenantCardsModal);

  return (
    <>
      <Header />
      <Modal
        isOpen={showModal}
        style={{ borderRadius: "15px", minxWidth: "600px", maxWidth: "700px" }}
      >
        <ModalHeader className=" text-white">
          <strong
            className="barforsummaryrent textcolorblue fontfamilysty"
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Move out tenants
          </strong>
        </ModalHeader>
        <ModalBody>
          <div
            className="labelfontstyle fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#979797",
            }}
          >
            Select tenants to move out. If everyone is moving, the lease will
            end on the last move-out date. Note: Renters insurance policies will
            be permanently deleted upon move-out.
          </div>
          <Card
            className="mt-3"
            style={{
              padding: "15px",
              border: "1px solid #324567",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              borderRadius: "10px",
            }}
          >
            <React.Fragment>
              <Table striped bordered responsive>
                <thead>
                  <tr
                    style={{
                      textTransform: "none",
                      background: "none",
                      border: "0.5px solid #324567",
                    }}
                  >
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Address / Unit
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Lease Type
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Start - End
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ background: "none" }}>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject?.rental_adress}
                      {clickedObject?.rental_unit !== "" &&
                      clickedObject?.rental_unit !== undefined
                        ? `- ${clickedObject?.rental_unit}`
                        : null}
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Fixed
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject?.start_date
                        ? moment(clickedObject?.start_date).format(dateFormat) +
                          " To " +
                          moment(clickedObject?.end_date).format(dateFormat)
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table striped bordered responsive className="mt-3">
                <thead>
                  <tr
                    style={{
                      background: "none",
                      border: "0.5px solid #324567",
                    }}
                  >
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Tenant
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Notice Given Date
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Move-Out Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ background: "none" }}>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject?.tenant_firstName}{" "}
                      {clickedObject?.tenant_lastName}
                    </td>
                    <td
                      className="textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      <div className="col">
                        <input
                          type="date"
                          className="form-control fontstylerentmodal"
                          placeholder="Notice Given Date"
                          value={noticeGivenDate}
                          onChange={(e) => setNoticeGivenDate(e.target.value)}
                        />
                      </div>
                    </td>
                    <td
                      className="textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      <div className="col">
                        <input
                          type="date"
                          className="form-control fontstylerentmodal"
                          placeholder="Move-out Date"
                          value={moveOutDate}
                          onChange={(e) => setMoveOutDate(e.target.value)}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </React.Fragment>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            className="fontstylerentr textcolorblue fontfamilysty backgroundwhitesty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() =>
              handleMoveout(clickedObject.lease_id, clickedObject.tenant_id)
            }
          >
            Move out
          </Button>
        </ModalFooter>
      </Modal>
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Row className="textarrange">
          <Col className="text-left">
            <FormGroup className=" align-item-left text-left ml-2">
              <h1
                className="text-left labelfontstyle textcolorblue fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                {rentalData?.rental_adress}
              </h1>
              <h4
                className="fontstylerentr fontfamilysty"
                style={{
                  color: "#B9BFCB",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {propertyTypeData?.property_type}
              </h4>
            </FormGroup>
          </Col>
          <Col className="text-right">
            <Button
              className="mr-2 btnbottom fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={(e) => {
                e.stopPropagation();
                rentalData?.admin_id === "is_trial"
                  ? freetrialData()
                  : editProperty(rentalData?.rental_id);
              }}
              size="small"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              Edit
            </Button>
            <Button
              className="mr-2 btnbottom fontstylerentr background-colorsty bgtextwhite"
              onClick={() => {
                navigate(`/staff/staffrentals`);
                setClickedUnitObject([]);
              }}
              size="small"
              style={{
                cursor: "pointer",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <TabContext value={value}>
              <CardHeader
                className="mt-3 headersummarybarapplicant backgroundwhitesty"
                style={{
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  border: "0.5px solid #324567",
                }}
              >
                <Box sx={{}}>
                  <TabList
                    className="tablistsummaryapplicant"
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    style={{
                      border: "none",
                    }}
                  >
                    <Tab
                      className="tabsummarybarapplicant textcolorblue fontfamilysty"
                      label="Summary"
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                      value="summary"
                    />

                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label={`Units (${propertyUnitData?.length || 0})`}
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                      value="units"
                    />

                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label={`Tenant (${tenantsCount})`}
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                      value="Tenant"
                    />

                    <Tab
                      className="tabsummarybarapplicant text-nowrap textcolorblue fontfamilysty"
                      label={`Work Order (${
                        !showCompletedTasks ? workCount : completeCount
                      }) `}
                      style={{
                        fontWeight: "500",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                      value="Work Order"
                    />
                  </TabList>
                </Box>
              </CardHeader>

              <TabPanel value="summary" className="mx-0 px-0 mt-2">
                {loader ? (
                  <tbody className="d-flex flex-direction-column justify-content-center align-items-center">
                    <tr>
                      <div className="p-5 m-5">
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="50"
                          visible={loader}
                        />
                      </div>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <div
                      className="main d-flex properdisplay "
                      style={{
                        border: "1px solid #324567",
                        borderRadius: "10px",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        maxHeight: "100%",
                      }}
                    >
                      {!propImageLoader ? (
                        <>
                          <div className="col-lg-3 mt-2 imgstyle ">
                            <label
                              className="imagestyle"
                              htmlFor="rental_image"
                              style={{
                                width: "260px",
                              }}
                            >
                              <img
                                src={
                                  rentalData?.rental_image
                                    ? `${imageGetUrl}/${rentalData?.rental_image}`
                                    : fone
                                }
                                className="img-fluid rounded-start m-image card-image"
                                alt={"..."}
                                style={{
                                  width: "260px",
                                  aspectRatio: "3/2",
                                  overflow: "hidden",
                                  objectFit: "contain",
                                }}
                              />
                            </label>
                            <TextField
                              id="rental_image"
                              name="rental_image"
                              type="file"
                              inputProps={{
                                accept: "image/*",
                                multiple: false,
                              }}
                              onChange={async (e) => {
                                setPropImageLoader(true);
                                const res = await handleImageChange(
                                  e,
                                  rentalData?.rental_id
                                );
                                if (res === true) {
                                  fetchRentalData();
                                } else {
                                  console.error("Image upload failed");
                                  setPropImageLoader(false);
                                }
                              }}
                              style={{ display: "none" }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-md-3 mt-2 d-flex justify-content-center">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={propImageLoader}
                          />
                        </div>
                      )}

                      <div className="col-md-2 col-lg-3 col-sm-12 propertydetail mx-3 ml-5 addressstyle">
                        <div
                          className="card-body mt-1"
                          style={{ padding: "0", marginLeft: "20px" }}
                        >
                          <h5
                            className="labelfontstyle textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Property Details
                          </h5>
                          <div
                            className="h6 fontstylerentr fontfamilysty"
                            style={{
                              color: "#979797",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            ADDRESS
                          </div>

                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {propertyTypeData?.property_type
                              ? propertyTypeData?.property_type + ","
                              : ""}
                          </span>
                          <br />

                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {rentalData?.rental_adress
                              ? rentalData?.rental_adress + ","
                              : ""}
                          </span>
                          <br />
                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {rentalData?.rental_city
                              ? rentalData?.rental_city + ","
                              : ""}
                          </span>
                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {rentalData?.rental_state
                              ? rentalData?.rental_state + ","
                              : ""}
                          </span>
                          <br />
                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {rentalData?.rental_country
                              ? rentalData?.rental_country + ","
                              : ""}
                          </span>
                          <span
                            className="address fontstylerentr textcolorblue fontfamilysty"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {rentalData?.rental_postcode
                              ? rentalData?.rental_postcode
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="align-items-center table-flush"
                      responsive
                      style={{ width: "100%" }}
                    >
                      <div className="">
                        <div className="p-0 mx-0 mt-4">
                          <Row
                            className=" my-3 "
                            style={{
                              fontSize: "18px",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            <Col
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Rental owners
                            </Col>
                          </Row>
                          <Row
                            className="mb-1 m-0 p-0"
                            style={{
                              fontSize: "12px",
                              color: "#000",
                              overflowX: "auto",
                            }}
                          >
                            <Table style={{ width: "100%" }}>
                              <tbody
                                className="tbbody p-0 m-0"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                }}
                              >
                                <tr
                                  className=""
                                  style={{
                                    background: "none",
                                    border: "0.5px solid #324567",
                                  }}
                                >
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Contact Name
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Company Name
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    E-Mail
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Phone Number
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Home Number
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Business Number
                                  </th>
                                </tr>
                                {rentalOwnerData ? (
                                  <>
                                    <>
                                      <tr className="body">
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_name ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_companyName ||
                                            "N/A"}
                                        </td>{" "}
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_primaryEmail ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_phoneNumber ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_homeNumber ||
                                            "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {rentalOwnerData?.rentalOwner_businessNumber ||
                                            "N/A"}
                                        </td>
                                      </tr>
                                    </>
                                  </>
                                ) : null}
                              </tbody>
                            </Table>
                          </Row>
                        </div>
                        <div className="p-0 mx-0 mt-4">
                          <Row
                            className=" my-3 "
                            style={{
                              fontSize: "18px",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            <Col
                              className="labelfontstyle textcolorblue fontfamilysty"
                              style={{
                                fontSize: "20px",
                                fontWeight: "600",
                              }}
                            >
                              Staff Details
                            </Col>
                          </Row>
                          <Row
                            className="mb-1 m-0 p-0"
                            style={{
                              fontSize: "12px",
                              color: "#000",
                            }}
                          >
                            <Table>
                              <tbody
                                className="tbbody p-0 m-0"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                }}
                              >
                                <tr
                                  className=""
                                  style={{
                                    background: "none",
                                    border: "0.5px solid #324567",
                                  }}
                                >
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Staff Member
                                  </th>
                                </tr>
                                {staffMemberData ? (
                                  <>
                                    <>
                                      <tr className="body">
                                        <td
                                          className="fontstylerentmodal textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {`${
                                            staffMemberData?.staffmember_name ||
                                            "No staff member assigned"
                                          }`}
                                        </td>
                                      </tr>
                                    </>
                                  </>
                                ) : null}
                              </tbody>
                            </Table>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value="units" style={{ padding: "0" }}>
                {unitloader ? (
                  <tbody className="d-flex flex-direction-column justify-content-center align-items-center">
                    <tr>
                      <div className="p-5 m-5">
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="50"
                          visible={unitloader}
                        />
                      </div>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    {!propSummary && propertyTypeData?.is_multiunit ? (
                      <div>
                        <div
                          className="mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                          }}
                        >
                          <Button
                            className="btn-icon btn-2 fontstylerentr textcolorblue fontfamilysty backgroundwhitesty"
                            style={{
                              display: propertyTypeData?.is_multiunit
                                ? "block"
                                : "none",
                              border: "1px solid #152B51",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                            size="l"
                            onClick={() => {
                              setOpenEdite(true);
                              setSelectedFiles([]);
                            }}
                          >
                            Add Unit
                          </Button>
                        </div>
                        <Table
                          className="align-items-center table-flush mt-3"
                          responsive
                          style={{ borderCollapse: "collapse" }}
                        >
                          <thead
                            className="fontfamilysty"
                            style={{
                              height: "45px",
                              fontSize: "14px",
                              fontWeight: "600",
                              boxShadow: " 0px 4px 4px 0px #00000040",
                            }}
                          >
                            <tr
                              style={{
                                border: "2px solid rgba(50, 69, 103, 1)",
                              }}
                            >
                              {propertyTypeData?.is_multiunit ? (
                                <th
                                  className="tablefontstyle fontstylerentr textcolorblue"
                                  scope="col"
                                  style={{
                                    borderTopLeftRadius: "15px",
                                  }}
                                >
                                  Unit
                                </th>
                              ) : (
                                ""
                              )}
                              {propertyTypeData?.is_multiunit ? (
                                <th
                                  className="tablefontstyle fontstylerentr textcolorblue"
                                  scope="col"
                                >
                                  Address
                                </th>
                              ) : (
                                ""
                              )}
                              <th
                                scope="col"
                                className={
                                  propertyTypeData?.is_multiunit
                                    ? "fontstylerentr textcolorblue fontfamilysty "
                                    : "text-center fontstylerentr textcolorblue fontfamilysty"
                                }
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Tenants
                              </th>
                              <th
                                scope="col"
                                className={
                                  propertyTypeData?.is_multiunit
                                    ? "fontstylerentr textcolorblue fontfamilysty "
                                    : "text-center fontstylerentr textcolorblue fontfamilysty"
                                }
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              style={{
                                border: "none",
                              }}
                            >
                              <td colSpan="9"></td>
                            </tr>
                            {propertyUnitData &&
                              propertyUnitData?.length > 0 &&
                              propertyUnitData?.map((unit, index) => (
                                <tr
                                  key={index}
                                  onClick={() => {
                                    setPropSummary(true);
                                    setClickedUnitObject(unit);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    border: "0.5px solid rgba(50, 69, 103, 1)",
                                    fontSize: "12px",
                                    height: "40px",
                                    fontWeight: "600",
                                  }}
                                  className="w-100 fontfamilysty"
                                >
                                  {propertyTypeData?.is_multiunit ? (
                                    <td className="bordertopintd tablebodyfont fontstylerentmodal">
                                      {unit.rental_unit || "N/A"}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  {propertyTypeData?.is_multiunit ? (
                                    <td className="bordertopintd tablebodyfont fontstylerentmodal">
                                      {unit.rental_unit_adress || "N/A"}
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                  <td
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      borderTop:
                                        "0.5px solid rgba(50, 69, 103, 1)",
                                    }}
                                    className={
                                      propertyTypeData?.is_multiunit
                                        ? "fontstylerentmodal fontfamilysty"
                                        : "text-center fontstylerentmodal fontfamilysty"
                                    }
                                  >
                                    {unit.tenantCount ? unit.tenantCount : "-"}
                                  </td>
                                  <td
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      borderTop:
                                        "0.5px solid rgba(50, 69, 103, 1)",
                                    }}
                                    className={
                                      propertyTypeData?.is_multiunit
                                        ? "fontstylerentmodal fontfamilysty"
                                        : "text-center fontstylerentmodal fontfamilysty"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openEditeTab(e, unit);
                                    }}
                                  >
                                    <img
                                      className="propertyiconsrentals"
                                      src={editicon}
                                      width={20}
                                      height={20}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        <></>
                      </div>
                    ) : (
                      <>
                        <Grid container className="mt-3 ">
                          <Grid
                            container
                            md={12}
                            lg={12}
                            sm={12}
                            xl={12}
                            xs={12}
                            style={{
                              border: "1px solid #324567",
                              padding: "15px",
                              borderRadius: "10px",
                            }}
                          >
                            {!propertyTypeData?.is_multiunit ? (
                              <Button
                                className="btn-icon btn-2 background-colorsty bgtextwhite fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                                size="sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openEditeTab(e, clickedUnitObject);
                                }}
                              >
                                Update Unit
                              </Button>
                            ) : (
                              <Button
                                className="btn-icon btn-2 background-colorsty bgtextwhite fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                                size="sm"
                                onClick={() => {
                                  setPropSummary(false);
                                  setClickedUnitObject([]);
                                }}
                              >
                                <span className="btn-inner--text fontstylerentr">
                                  Back
                                </span>
                              </Button>
                            )}
                            {propertyTypeData?.is_multiunit && (
                              <Button
                                className="btn-icon btn-2 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                                size="sm"
                                onClick={async () => {
                                  try {
                                    const res = await handleDeleteUnit(
                                      clickedUnitObject?.unit_id
                                    );
                                    if (res.statusCode === 200) {
                                      fetchUnitsData();
                                      toast.success(res.message, {
                                        position: "top-center",
                                        autoClose: 500,
                                      });
                                      setPropSummary(false);
                                      setClickedUnitObject([]);
                                    }
                                    if (res.statusCode === 201) {
                                      toast.warning(res.message, {
                                        position: "top-center",
                                        autoClose: 500,
                                      });
                                    }
                                  } catch (error) {
                                    console.error("Error:", error);
                                  }
                                }}
                              >
                                Delete Unit
                              </Button>
                            )}
                            <Button
                              className="btn-icon btn-2  fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                              style={{
                                // marginTop: "5px",
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(`/staff/staffaddrentroll`, {
                                  state: state,
                                });
                              }}
                            >
                              Add Lease
                            </Button>
                            <div className="d-flex w-100 justify-content-between unitcontainer">
                              <div className="mt-2 mb-2">
                                <label
                                  className="imagestyle"
                                  htmlFor="unit_image"
                                  style={{
                                    width: "260px",
                                  }}
                                >
                                  {unitImageLoader ? (
                                    <>
                                      <RotatingLines
                                        strokeColor="grey"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="50"
                                        visible={true}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={
                                          clickedUnitObject?.rental_images
                                            ? `${imageGetUrl}/${clickedUnitObject?.rental_images[0]}`
                                            : fone
                                        }
                                        className="img-fluid rounded-start card-image"
                                        alt="..."
                                        style={{
                                          width: "260px",
                                          aspectRatio: "3/2",
                                          overflow: "hidden",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </>
                                  )}
                                </label>
                              </div>
                              <Grid
                                item
                                md={7}
                                lg={7}
                                xl={7}
                                style={{
                                  width: "100%",
                                  marginLeft: "80px",
                                  marginTop: "15px",
                                }}
                              >
                                {clickedUnitObject?.rental_unit ? (
                                  <div className="d-flex align-self-end">
                                    <Typography
                                      className="labelfontstyle textcolorblue fontfamilysty"
                                      variant="h6"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        textTransform: "capitalize",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <div>
                                        {clickedUnitObject?.rental_unit}
                                      </div>
                                    </Typography>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <span
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    marginTop: "0px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  <b
                                    className="fontstylerentr fontfamilysty"
                                    style={{
                                      color: "#979797",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    ADDRESS
                                  </b>
                                  <br />
                                  {clickedUnitObject?.rental_unit
                                    ? clickedUnitObject?.rental_unit + ", "
                                    : ""}
                                  {rentalData?.rental_adress
                                    ? rentalData?.rental_adress + ", "
                                    : ""}
                                  <br />
                                  {rentalData?.rental_city
                                    ? rentalData?.rental_city + ", "
                                    : ""}
                                  {rentalData?.rental_state
                                    ? rentalData?.rental_state + ", "
                                    : ""}
                                  <br />
                                  {rentalData?.rental_country
                                    ? rentalData?.rental_country + ", "
                                    : ""}
                                  {rentalData?.rental_postcode
                                    ? rentalData?.rental_postcode
                                    : ""}
                                </span>
                              </Grid>
                              <Grid
                                item
                                md={5}
                                lg={5}
                                xl={5}
                                className="d-flex justify-content-end"
                              >
                                {/* <Paper
                                  elevation={2}
                                  style={{
                                    border: "1px solid #324567",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <CardBody
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "20px",
                                      }}
                                    >
                                      <span
                                        className="fontstylerentr"
                                        style={{
                                          marginTop: "15px",
                                          fontFamily: "Poppins",
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          color: "#152B51",
                                        }}
                                      >
                                        Lease
                                      </span>
                                     
                                      <span
                                        className="fontstylerentr"
                                        style={{
                                          marginTop: "15px",
                                          fontFamily: "Poppins",
                                          fontSize: "18px",
                                          fontWeight: "600",
                                          color: "#152B51",
                                        }}
                                      >
                                        Rental Applications
                                      </span>
                                      <Button
                                        className="fontstylerentmodal"
                                        size="small"
                                        style={{
                                          marginTop: "5px",
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          color: "#fff",
                                          backgroundColor: "#152B51",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          navigate(`/${admin}/Applicants`);
                                        }}
                                      >
                                        Create Applicant
                                      </Button>
                                    </CardBody>
                                  </Card>
                                </Paper> */}
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Row
                              className="w-100 my-3 "
                              style={{
                                fontSize: "18px",
                                textTransform: "capitalize",
                                color: "#000",
                                fontWeight: "600",
                              }}
                            >
                              <Col
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "600",
                                }}
                              >
                                Leases
                              </Col>
                            </Row>
                            <Row
                              className="mb-1 m-0 p-0"
                              style={{ fontSize: "12px", color: "#000" }}
                            >
                              <Table responsive>
                                <tbody
                                  className="tbbody p-0 m-0"
                                  style={{
                                    borderTopRightRadius: "5px",
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                  }}
                                >
                                  <tr
                                    className=""
                                    style={{
                                      background: "none",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    <th
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                    >
                                      Status
                                    </th>
                                    <th
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                    >
                                      Start - End
                                    </th>
                                    <th
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                    >
                                      Tenant
                                    </th>
                                    <th
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                    >
                                      Type
                                    </th>
                                    <th
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                    >
                                      Rent
                                    </th>
                                  </tr>
                                  {unitLeases.length > 0 ? (
                                    unitLeases.map((lease) => (
                                      <>
                                        <tr className="body">
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {getStatus(
                                              lease?.start_date,
                                              lease?.end_date
                                            )}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {lease?.start_date &&
                                            lease?.end_date ? (
                                              <>
                                                <Link
                                                  to={`/staff/staffrentrolldetail/${lease?.lease_id}`}
                                                  onClick={(e) => {}}
                                                >
                                                  {moment(
                                                    lease?.start_date
                                                  ).format(dateFormat) +
                                                    " " +
                                                    "-" +
                                                    " " +
                                                    moment(
                                                      lease?.end_date
                                                    ).format(dateFormat)}
                                                </Link>
                                              </>
                                            ) : (
                                              "N/A"
                                            )}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {lease?.tenant_firstName &&
                                            lease?.tenant_lastName
                                              ? lease?.tenant_firstName +
                                                " " +
                                                lease?.tenant_lastName
                                              : "N/A"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {lease?.lease_type || "N/A"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {lease?.amount || "N/A"}
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  ) : (
                                    <tr>
                                      <th
                                        className="fontstylerentmodal textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                        colSpan={5}
                                      >
                                        Lease not assigned
                                      </th>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </Row>

                            <Row
                              className=" mt-5 mb-3 textcolorblue"
                              style={{
                                fontSize: "18px",
                                textTransform: "capitalize",
                                fontWeight: "600",
                              }}
                            >
                              <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                <span
                                  className="labelfontstyle textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Appliances
                                </span>
                                <Button
                                  className="ml-3 fontstylerentr textcolorblue backgroundwhitesty"
                                  size="small"
                                  style={{
                                    border: "1px solid #152B51",
                                    marginBottom: "5px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => {
                                    setAddAppliances(!addAppliances);
                                    addAppliancesFormin.resetForm();
                                  }}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            {addAppliances ? (
                              <>
                                <Row className="mb-4">
                                  <Col md={6}>
                                    <Card
                                      style={{
                                        position: "relative",
                                        border: " 1px solid #324567",
                                      }}
                                    >
                                      <CloseIcon
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setAddAppliances(!addAppliances);
                                          addAppliancesFormin.resetForm();
                                        }}
                                      />
                                      <CardBody>
                                        <form
                                          onSubmit={
                                            addAppliancesFormin.handleSubmit
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div>
                                              <h5 className="fontstylerentr">
                                                Name
                                              </h5>
                                            </div>
                                            <TextField
                                              className="fontstylerentmodal"
                                              style={{
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                                width: "40%",
                                              }}
                                              type="text"
                                              size="small"
                                              id="appliance_name"
                                              name="appliance_name"
                                              value={
                                                addAppliancesFormin.values
                                                  .appliance_name
                                              }
                                              onChange={
                                                addAppliancesFormin.handleChange
                                              }
                                            />
                                          </div>
                                          {addAppliancesFormin.touched
                                            .appliance_name &&
                                          addAppliancesFormin.errors
                                            .appliance_name ? (
                                            <div
                                              className="requiredstylefont"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                addAppliancesFormin.errors
                                                  .appliance_name
                                              }
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <div>
                                              <h5 className="fontstylerentr">
                                                Description
                                              </h5>
                                            </div>
                                            <TextField
                                              className="fontstylerentmodal"
                                              style={{
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                                width: "40%",
                                              }}
                                              type="textarea"
                                              size="small"
                                              id="appliance_description"
                                              name="appliance_description"
                                              value={
                                                addAppliancesFormin.values
                                                  .appliance_description
                                              }
                                              onChange={
                                                addAppliancesFormin.handleChange
                                              }
                                            />
                                          </div>
                                          {addAppliancesFormin.touched
                                            .appliance_description &&
                                          addAppliancesFormin.errors
                                            .appliance_description ? (
                                            <div
                                              className="requiredstylefont"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                addAppliancesFormin.errors
                                                  .appliance_description
                                              }
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <div>
                                              <div>
                                                <h5 className="fontstylerentr">
                                                  Installed Date
                                                </h5>
                                              </div>
                                              <TextField
                                                className="fontstylerentmodal"
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                }}
                                                type="date"
                                                size="small"
                                                id="installed_date"
                                                name="installed_date"
                                                value={
                                                  addAppliancesFormin.values
                                                    .installed_date
                                                }
                                                onChange={
                                                  addAppliancesFormin.handleChange
                                                }
                                              />
                                            </div>
                                          </div>
                                          {addAppliancesFormin.touched
                                            .installed_date &&
                                          addAppliancesFormin.errors
                                            .installed_date ? (
                                            <div
                                              className="requiredstylefont"
                                              style={{ color: "red" }}
                                            >
                                              {
                                                addAppliancesFormin.errors
                                                  .installed_date
                                              }
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div
                                            style={{ marginTop: "10px" }}
                                            className="mt-3 "
                                          >
                                            <Button
                                              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                cursor: "pointer",
                                              }}
                                              type="submit"
                                            >
                                              Save
                                            </Button>
                                            <Button
                                              className="cancelbtnapp fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                backgroundColor: "white",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setAddAppliances(
                                                  !addAppliances
                                                );
                                                addAppliancesFormin.resetForm();
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                          </div>
                                        </form>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row
                                  className="mb-4 m-0 p-0"
                                  style={{ fontSize: "12px", color: "#000" }}
                                >
                                  <Table responsive>
                                    <tbody
                                      className="tbbody p-0 m-0"
                                      style={{
                                        borderTopRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                      }}
                                    >
                                      <tr
                                        className=""
                                        style={{
                                          background: "none",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Name
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Description
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Installed Date
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Action
                                        </th>
                                      </tr>
                                      {applianceData?.length > 0 ? (
                                        applianceData?.map(
                                          (appliance, index) => (
                                            <tr className="body" key={index}>
                                              <td
                                                className="fontstylerentmodal textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                  border: "0.5px solid #324567",
                                                }}
                                              >
                                                {appliance.appliance_name}
                                              </td>
                                              <td
                                                className="fontstylerentmodal textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                  border: "0.5px solid #324567",
                                                }}
                                              >
                                                {
                                                  appliance.appliance_description
                                                }
                                              </td>
                                              <td
                                                className="fontstylerentmodal textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                  border: "0.5px solid #324567",
                                                }}
                                              >
                                                {moment(
                                                  appliance.installed_date
                                                ).format(dateFormat)}
                                              </td>
                                              <td
                                                className="textcolorblue fontfamilysty"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "500",
                                                  border: "0.5px solid #324567",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={async () => {
                                                      try {
                                                        const res =
                                                          await deleteAppliance(
                                                            appliance.appliance_id
                                                          );
                                                        if (res === 200) {
                                                          fetchApplianceData();
                                                        }
                                                      } catch (error) {
                                                        console.error(
                                                          "Error occurred while deleting appliance:",
                                                          error
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <img
                                                      className="propertyiconsrentals"
                                                      src={deleicon}
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setAddAppliances(true);
                                                      addAppliancesFormin.setValues(
                                                        {
                                                          appliance_description:
                                                            appliance.appliance_description,
                                                          appliance_name:
                                                            appliance.appliance_name,
                                                          installed_date:
                                                            appliance.installed_date,
                                                          appliance_id:
                                                            appliance.appliance_id,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      className="propertyiconsrentals"
                                                      src={editicon}
                                                      width={20}
                                                      height={20}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr
                                          style={{
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                            colSpan={5}
                                          >
                                            You don't have any appliance for
                                            this unit right now
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </Row>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </TabPanel>

              <TabPanel value="Work Order" className="mx-0 px-0 mt-2">
                <Row className="d-flex ml-0 mb-3">
                  <Col lg="6">
                    <Row>
                      <FormGroup className="mr-sm-2 searchspace searchstyle">
                        <Input
                          className="filterh"
                          type="text"
                          placeholder="Search here..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            minWidth: "200px",
                            fontWeight: "500",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                          }}
                        />
                      </FormGroup>
                      <div
                        className="viewmapstyle viewmaptext"
                        style={{ flexDirection: "row" }}
                      >
                        <Checkbox
                          className="checkview"
                          checked={showCompletedTasks}
                          onChange={(e) =>
                            setShowCompletedTasks(!showCompletedTasks)
                          }
                          name="completetask"
                          sx={{
                            "&.Mui-checked": {
                              color: "#152B51",
                            },
                          }}
                        />
                        <Label
                          className="font-view textcolorblue fontfamilysty"
                          size="small"
                          style={{
                            marginTop: "13px",
                            fontWeight: "600",
                          }}
                        >
                          Show Completed Tasks
                        </Label>
                      </div>
                    </Row>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      className="workorderbtn background-colorsty bgtextwhite fontfamilysty"
                      onClick={() => {
                        userroleData?.workorder_add &&
                          navigate("/staff/addworkorder", {
                            state: {
                              rental_id: rentalData?.rental_id,
                              rental_adress: rentalData?.rental_adress,
                            },
                          });
                      }}
                      size="small"
                    >
                      Add Work Order
                    </Button>
                  </Col>
                </Row>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=" fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                          cursor: "pointer",
                          width: "15%",
                        }}
                        onClick={() => handleSort("work_subject")}
                        onMouseEnter={() => setHoveredColumn("work_subject")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Work Order
                        {hoveredColumn === "work_subject" ||
                        sortedColumn === "work_subject" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer", width: "15%" }}
                        onClick={() => handleSort("work_category")}
                        onMouseEnter={() => setHoveredColumn("work_category")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Category
                        {hoveredColumn === "work_category" ||
                        sortedColumn === "work_category" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Billable
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Assigned
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Status
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ cursor: "pointer", wdth: "15%" }}
                        onClick={() => handleSort("createdAt")}
                        onMouseEnter={() => setHoveredColumn("createdAt")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Created At
                        {hoveredColumn === "createdAt" ||
                        sortedColumn === "createdAt" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Updated At
                      </th>
                    </tr>
                  </thead>
                  {workData?.length === 0 ? (
                    <tbody>
                      <tr
                        className="text-center"
                        style={{ border: "0.5px solid rgba(50, 69, 103, 1)" }}
                      >
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Workorder Added
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((rental) => (
                        <tr
                          className="fontfamilysty"
                          key={rental.workOrder_id}
                          onClick={() =>
                            navigate(
                              `/${admin}/workorderdetail/${rental?.workOrder_id}`,
                              { state: { rental_id: rentalData?.rental_id } }
                            )
                          }
                          style={{
                            cursor: "pointer",
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontWeight: "600",
                          }}
                        >
                          <td
                            className="bordertopintd tablebodyfont tabletextcolor"
                            style={{
                              minWidth: "500px",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {rental?.work_subject}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.work_category}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.is_billable ? (
                              <FaCheck style={{ color: "#152B51" }} />
                            ) : (
                              " "
                            )}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.staffmember_name || "-"}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {rental.status || "-"}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(rental?.createdAt).format(dateFormat)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {moment(rental?.updatedAt).format(dateFormat)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </TabPanel>

              <TabPanel value="Tenant">
                {tenantloader ? (
                  <tbody className="d-flex flex-direction-column justify-content-center align-items-center">
                    <tr>
                      <div className="p-5 m-5">
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="50"
                          visible={tenantloader}
                        />
                      </div>
                    </tr>
                  </tbody>
                ) : (
                  <>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="fontstylerentr textcolorblue fontfamilysty"
                        style={{
                          cursor: "pointer",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                        onClick={cardToggle}
                      >
                        Enable Recurring Payments
                      </span>
                      <Switch
                        className="custom-switch"
                        checked={tenantsData.some((item) => item?.recurring)}
                        onChange={async (e) => {
                          if (e.target.checked) {
                            cardToggle();
                          } else {
                            try {
                              const response = await axiosInstance.put(
                                `/recurring-cards/disable-cards/${rental_id}`
                              );
                              if (response.data.statusCode === 200) {
                                fetchTenantData();
                              } else {
                                fetchTenantData();
                              }
                            } catch (error) {
                              console.error("Error: ", error);
                            }
                          }
                        }}
                      />
                    </div>
                    <Row>
                      {tenantsData?.length > 0 ? (
                        <Grid container spacing={2}>
                          {tenantsData?.map((tenant, index) => (
                            <Col
                              xl="4"
                              lg="6"
                              md="6"
                              sm="12"
                              xs="12"
                              key={index}
                            >
                              <Box
                                border="1px solid #324567"
                                boxShadow="0px 4px 4px 0px #00000040"
                                borderRadius="10px"
                                padding="16px"
                                marginTop="20px"
                                width="100%"
                              >
                                <Row className="d-flex">
                                  <Col xl="2" lg="2" md="2" xs="2">
                                    <Box
                                      className="background-colorsty bgtextwhite "
                                      width="40px"
                                      height="40px"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      borderRadius="4px"
                                      fontSize="24px"
                                    >
                                      <img
                                        className="imageprofilerent propertyiconsrentals"
                                        src={profilei}
                                        width={20}
                                        height={20}
                                        alt="Profile"
                                      />
                                    </Box>
                                  </Col>
                                  <Col xl="6" lg="6" md="6" xs="6">
                                    <div
                                      className="labelfontstyle textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {tenant.tenant_firstName}{" "}
                                      {tenant.tenant_lastName}
                                    </div>
                                  </Col>
                                  <Col xl="4" lg="4" md="4" xs="4">
                                    {!tenant?.moveout_notice_given_date ? (
                                      <div
                                        className="d-flex justify-content-end h5 p-0 fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                        onClick={() =>
                                          tenant.admin_id === "is_trial"
                                            ? freetrialData()
                                            : handleMoveOutClick(tenant)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <LogoutIcon
                                          fontSize="small"
                                          style={{ color: "#152B51" }}
                                        />{" "}
                                        Move out
                                      </div>
                                    ) : (
                                      <div className="d-flex justify-content-end h5 p-0 fontstylerentr modaltenantfontstyle">
                                        <DoneIcon
                                          fontSize="small"
                                          style={{
                                            cursor: "pointer",
                                            color: "green",
                                            fontWeight: "500",
                                            fontSize: "20px",
                                            fontFamily: "Poppins",
                                          }}
                                        />{" "}
                                        &nbsp;
                                        <div> Moved Out</div>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="2" lg="2" md="2" xs="2"></Col>
                                  <Col xl="6" lg="6" md="6" xs="6">
                                    <div
                                      className="mt-3 fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {moment(tenant.start_date).format(
                                        dateFormat
                                      )}{" "}
                                      to{" "}
                                      {moment(tenant.end_date).format(
                                        dateFormat
                                      )}
                                    </div>
                                    <div
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Typography
                                        style={{ paddingRight: "3px" }}
                                      >
                                        <img
                                          className="propertyiconsrentals"
                                          src={phone}
                                          width={20}
                                          height={20}
                                          alt="Phone"
                                        />
                                      </Typography>
                                      <Typography
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          marginTop: "2px",
                                        }}
                                      >
                                        {tenant.tenant_phoneNumber}
                                      </Typography>
                                    </div>
                                    <div
                                      className="emailstyletenant fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <Typography
                                        style={{ paddingRight: "3px" }}
                                      >
                                        <img
                                          className="imagetenant propertyiconsrentals"
                                          src={email}
                                          width={20}
                                          height={20}
                                          alt="Email"
                                        />
                                      </Typography>
                                      <span
                                        className="emailrentrollsty emailstyletenant textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                          maxWidth: "220px",
                                          display: "inline-block",
                                        }}
                                      >
                                        {tenant.tenant_email || "N/A"}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col xl="4" lg="4" md="4" xs="4"></Col>
                                </Row>
                              </Box>
                            </Col>
                          ))}
                        </Grid>
                      ) : (
                        <div className="fontstylerentr">
                          Tenant not assigned for this property.
                        </div>
                      )}
                    </Row>
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Col>
        </Row>
        <ToastContainer />
      </Container>

      <Dialog
        open={openEdite}
        onClose={closeModal}
        PaperProps={{
          style: {
            borderRadius: "8px",
            overflowY: "hidden",
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle
          className="labelfontstyle textcolorblue fontfamilysty"
          style={{
            fontSize: "20px",
            fontWeight: "600",
            border: "none",
          }}
        >
          {addUnitFormik.values.rental_unit
            ? "Edit Unit Details"
            : "Add Unit Details"}
        </DialogTitle>
        <CloseIcon
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={closeModal}
        />
        <UnitEdite
          openEdite={openEdite}
          closeModal={closeModal}
          setOpenEdite={setOpenEdite}
          clickedObject={clickedUnitObject}
          addUnitFormik={addUnitFormik}
          selectedFiles={selectedFiles}
          setOpen={setOpen}
          open={open}
          clearSelectedPhoto={clearSelectedPhoto}
          setSelectedFiles={setSelectedFiles}
          fileData={fileData}
          togglePhotoresDialog={togglePhotoresDialog}
          addUnitDialogOpen={propertyTypeData?.property_type}
          is_multiunit={propertyTypeData?.is_multiunit}
        />
      </Dialog>

      <RecurringTenants
        isOpen={tenantCardsModal}
        toggle={cardToggle}
        tenantsData={tenantsData}
        admin_id={accessType?.admin_id}
        rental_id={rental_id}
        fetchTenantData={fetchTenantData}
      />
    </>
  );
};

export default StaffPropertyDetail;
