import { ListItemText, MenuItem, Select } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { generateCSV, generateExcel, generatePDF } from "./functions";
import moment from "moment";

const InputGroup = ({
  label,
  type = "text",
  name = "name",
  placeholder = "Enter Name",
  error = "",
  onChange,
  ...props
}) => {
  return (
    <FormGroup className="d-flex flex-column">
      {label && (
        <label
          className="form-control-label fontstylerentr titleecolor fontfamilysty"
          htmlFor="input-unitadd"
          style={{
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {label}
        </label>
      )}

      <Input
        style={{
          boxShadow: "0px 4px 4px 0px #00000040 ",
          borderRadius: "6px",
          width: "100%",
        }}
        className="form-control-alternative fontstylerentmodal titleecolor"
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
        {...props}
      />
      {error}
    </FormGroup>
  );
};

const PaymentReport = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { admin } = useParams();

  const [cookies, setCoockie, removeCookie] = useCookies();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [userProfile, setUserProfile] = useState();

  // select date range

  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [isCustomDate, setIsCustomDate] = useState(true);
  const [datedropdownopen, setDateDropdownOpen] = useState(false);
  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    setCurrentPage(1);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
    setCurrentPage(1);
  };

  const toggle4 = () => setDateDropdownOpen((prevState) => !prevState);

  const [setdate, setDate] = useState("Custom Date");

  const handlerentalownerdate = (type) => {
    setDate(type);
    setIsCustomDate(type === "Custom Date");
    const today = new Date();
    let start, end;

    switch (type) {
      case "Today":
        start = today.toISOString().split("T")[0];
        end = today.toISOString().split("T")[0];
        break;
      case "This Week":
        start = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 1))
        ).format("YYYY-MM-DD"); // Monday of the current week
        end = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 7))
        ).format("YYYY-MM-DD"); // Sunday of the current week
        break;
      case "This Month":
        start = moment(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ).format("YYYY-MM-DD"); // First day of the month
        end = moment(
          new Date(today.getFullYear(), today.getMonth() + 1, 0)
        ).format("YYYY-MM-DD"); // Last day of the month
        break;
      case "This Year":
        start = moment(new Date(today.getFullYear(), 0, 1)).format(
          "YYYY-MM-DD"
        ); // January 1st of the current year
        end = moment(new Date(today.getFullYear(), 11, 31)).format(
          "YYYY-MM-DD"
        ); // December 31st of the current year
        break;
      case "Custom Date":
        start = "";
        end = "";
        break;
      default:
        break;
    }

    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      if (accessData) {
        if (accessData?.admin_id) {
          try {
            const [response1, userData] = await Promise.all([
              axiosInstance.get(
                `/payment/exception-payments/${accessData?.admin_id}`
              ),
              axiosInstance.get(`/admin/admin_profile/${accessData?.admin_id}`),
            ]);
            setData(response1.data?.data);
            setUserProfile(userData?.data?.data);
          } catch (error) {
            console.error(error);
          } finally {
            setLoader(false);
          }
        }
      } else {
        setLoader(false);
      }
    };

    handleCheckToken();
  }, []);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [exportsData, setExportsData] = useState([]);

  const handleSearch = (data, search) => {
    const entries = data || [];
    const searchQuery = search.toString().trim().toLowerCase();

    const filteredData = entries.filter((item) => {
      if (
        item?.rental_data?.rental_adress
          ?.toString()
          ?.toLowerCase()
          .includes(searchQuery) ||
        item?.unit_data?.rental_unit
          ?.toString()
          ?.toLowerCase()
          .includes(searchQuery) ||
        item?.payment_type?.toString()?.toLowerCase().includes(searchQuery) ||
        item?.entry?.some(
          (entryItem) =>
            entryItem?.account
              ?.toString()
              ?.toLowerCase()
              .includes(searchQuery) ||
            entryItem?.amount?.toString()?.toLowerCase().includes(searchQuery)
        ) ||
        item?.total_amount?.toString()?.toLowerCase().includes(searchQuery)
      ) {
        return true;
      } else {
        return false;
      }
    });

    return filteredData;
  };

  const handleChange = () => {
    if (data) {
      let chargesData = data;

      setExportsData(chargesData);

      if (selectedStartDate && selectedEndDate) {
        chargesData = chargesData.filter((item) => {
          const itemDate = item.entry[0]?.date;
          return (
            itemDate >= moment(selectedStartDate).format("YYYY-MM-DD") &&
            itemDate <= moment(selectedEndDate).format("YYYY-MM-DD")
          );
        });
      } else if (selectedEndDate) {
        chargesData = chargesData.filter((item) => {
          const itemDate = item.entry[0]?.date;
          return itemDate <= moment(selectedEndDate).format("YYYY-MM-DD");
        });
      } else if (selectedStartDate) {
        chargesData = chargesData.filter((item) => {
          const itemDate = item.entry[0]?.date;
          return itemDate >= moment(selectedStartDate).format("YYYY-MM-DD");
        });
      }

      const startIndex = (currentPage - 1) * rowPerPage;
      const endIndex = currentPage * rowPerPage;

      const finalData = search
        ? handleSearch(chargesData, search)
        : chargesData;

      setTotalPages(Math.ceil((finalData?.length || 1) / rowPerPage));

      const paginatedData = finalData?.slice(startIndex, endIndex);

      setTableData(paginatedData);
    }
  };

  useEffect(() => {
    handleChange();
  }, [
    data,
    search,
    currentPage,
    rowPerPage,
    selectedStartDate,
    selectedEndDate,
  ]);

  const renderRow = (entry, index) => (
    <tr
      style={{
        fontSize: "12px",
        height: "40px",
        fontFamily: "Poppins",
        fontWeight: "600",
      }}
    >
      <td
        className="dailyreporttablefont rentrollcolorsty"
        style={{ border: 0, verticalAlign: "top" }}
      >
        <span
          onClick={() =>
            navigate(`/${admin}/rentrolldetail/${entry?.lease_id}`)
          }
          style={{ cursor: "pointer" }}
        >
          {(entry?.rental_data?.rental_adress || "") +
            (entry?.unit_data?.rental_unit
              ? ` - ${entry?.unit_data?.rental_unit}`
              : " ")}
        </span>
      </td>

      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          verticalAlign: "top",
        }}
      >
        {entry?.payment_type || "-"}
      </td>
      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          verticalAlign: "top",
        }}
      >
        {moment(entry?.entry[0]?.date)?.format("YYYY-MM-DD")}
      </td>
      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          verticalAlign: "top",
        }}
      >
        {entry?.check_number ? entry?.check_number : "N/A"}
      </td>
      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          verticalAlign: "top",
        }}
      >
        {entry?.entry?.length > 0
          ? entry.entry.map((item) => (
              <span
                key={item?.account}
                className="d-flex justify-content-between"
              >
                <span className="tabletextcolor dailyreporttablefont">
                  {item?.account}
                </span>
                <span className="tabletextcolor dailyreporttablefont">
                  ${item?.amount?.toFixed(2)}
                </span>
              </span>
            ))
          : "No account"}
      </td>

      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          textAlign: "right",
          fontWeight: "bold",
          verticalAlign: "top",
        }}
      >
        {!entry.total_amount
          ? "$0.00"
          : `$${
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry.total_amount) || "0.00"
            }`}
      </td>
    </tr>
  );

  return (
    <>
      <Container
        className="stylecontainer pt-3"
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {"Payment Exception Report"}
              </h2>
            </CardHeader>
          </Col>
        </Row>

        <br />

        <Row className="mb-3">
          <Col xl="2" lg="2" md="3" sm="5">
            <FormGroup className="d-flex flex-column">
              <InputGroup
                label="Search"
                name="search"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Search here..."
              />
            </FormGroup>
          </Col>

          <Col xl="2" lg="4" md="3" sm="4" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Date Range
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={datedropdownopen}
                toggle={toggle4}
                style={{ width: "100%" }}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {setdate}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Today")}
                  >
                    Today
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Week")}
                  >
                    This Week
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Month")}
                  >
                    This Month
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Year")}
                  >
                    This Year
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Custom Date")}
                  >
                    Custom Date
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" md="3" sm="4" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-startdate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                From
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-startdate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={!isCustomDate}
                max={moment(selectedEndDate).format("YYYY-MM-DD") || null}
              />
            </FormGroup>
          </Col>
          <Col xl="2" lg="4" md="3" sm="5" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-enddate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                To
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-enddate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                disabled={!isCustomDate}
                min={
                  moment(selectedStartDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD") || null
                }
              />
            </FormGroup>
          </Col>

          {tableData?.length > 0 && (
            <Col className="d-flex justify-content-end addtotnstyle mt-4">
              <Select
                labelId="user-select-label"
                className="background-colorsty bgtextwhite"
                id="user-select"
                name="payment_method"
                value=""
                onChange={(e) => {
                  if (e.target.value === "PDF") {
                    generatePDF(exportsData, userProfile);
                  }
                  if (e.target.value === "Excel") {
                    generateExcel(exportsData, userProfile);
                  }
                  if (e.target.value === "CSV") {
                    generateCSV(exportsData, userProfile);
                  }
                }}
                displayEmpty
                renderValue={(selected) => {
                  return selected || "Exports";
                }}
                sx={{
                  "& .MuiSelect-icon": {
                    color: "var(--text-white-color)",
                  },
                }}
              >
                {[
                  { label: "PDF", value: "PDF" },
                  { label: "Excel", value: "Excel" },
                  { label: "CSV", value: "CSV" },
                ]?.length > 0 &&
                  [
                    { label: "PDF", value: "PDF" },
                    { label: "Excel", value: "Excel" },
                    { label: "CSV", value: "CSV" },
                  ]?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      <ListItemText primary={item?.label || ""} />
                    </MenuItem>
                  ))}
              </Select>
            </Col>
          )}
        </Row>

        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <thead
                className="fontfamilysty"
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "none",
                  border: 0,
                }}
              >
                <tr style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={5}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    Grand Total
                  </td>
                  <td
                    className="tabletextcolor  text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {`$${
                      new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(
                        tableData?.reduce(
                          (acc, item) => acc + Number(item?.total_amount || 0),
                          0
                        )
                      ) || "0.00"
                    }`}
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "25%" }}
                  >
                    Property
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "10%" }}
                  >
                    Type
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "15%" }}
                  >
                    Date
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "15%" }}
                  >
                    Payment
                  </th>

                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ fontWeight: "Bold", border: 0, width: "25%" }}
                  >
                    Detail
                  </th>
                  <th
                    className="tablefontstyle textcolorblue text-right"
                    scope="col"
                    style={{ fontWeight: "Bold", border: 0, width: "10%" }}
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData?.length > 0 &&
                  tableData.map((entry, index) => (
                    <React.Fragment key={`${entry?.lease_id}-${index}`}>
                      {renderRow(entry)}
                    </React.Fragment>
                  ))}

                {(!tableData || tableData?.length === 0) && (
                  <tr className="text-center py-3">
                    <td
                      colSpan="6"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}
        </Table>

        <Row>
          <Col className="text-right mt-3 mb-3">
            <Select
              labelId="user-select-label"
              className="mx-2"
              id="user-select"
              name="payment_method"
              value={rowPerPage}
              onChange={(e) => setRowPerPage(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                return selected || "Select Event";
              }}
            >
              {[10, 25, 50, 100].length > 0 &&
                [10, 25, 50, 100]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <ListItemText primary={item || ""} />
                  </MenuItem>
                ))}
            </Select>
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-left"
                viewBox="0 0 16 16"
              >
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
              </svg>
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>{" "}
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentReport;
