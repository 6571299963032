import axios from "axios";
import { Cookies } from "react-cookie";

// Create an instance of axios
const interceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token;
};

const getId = () => {
  const cookies = new Cookies();
  const id = cookies.get("ID");
  return id;
};

// Request interceptor to add the access token to headers
interceptor.interceptors.request.use(
  async (config) => {
    const accessToken = await getToken();
    const id = await getId();

    if (accessToken) {
      config.headers["authorization"] = `CRM ${accessToken}`;
      config.headers["id"] = `CRM ${id}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors and success messages
interceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorResponse = "";
    if (error?.response?.data?.data?.error)
      errorResponse = error.response.data.data.error;
    else if (error?.response?.data?.message)
      errorResponse = error.response.data.message;
    else errorResponse = "Network Problem";
    if (typeof errorResponse !== "string") JSON.stringify(errorResponse);
    return Promise.reject(errorResponse);
  }
);

export default interceptor;
