import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import PolicyDialog from "../../components/PolicyDialog";
import deleicon from "../../assets/img/icons/common/delete.svg";
import editicon from "../../assets/img/icons/common/editicon.svg";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const TenantDetailPage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id, admin } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [tenantDetails, setTenantDetails] = useState({});
  const [TenantInsuranceId, setTenantInsuranceId] = useState(null);
  const getTenantData = async () => {
    if (id) {
      try {
        const apiUrl = `/tenant/tenant_details/${id}`;
        const response = await axiosInstance.get(apiUrl);
        console.log(response);
        setTenantDetails(response.data.data[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getTenantData();
  }, [id]);

  const handleAdd = async () => {
    if (
      tenantDetails.tenantInsurance.some((item) => item.status === "ACTIVE")
    ) {
      swal({
        title: "Are you sure?",
        text: "If you add new renters insurance then older one get expired!",
        icon: "warning",
        buttons: ["Yes", "Cancel"],
        dangerMode: true,
      }).then(async (willAdd) => {
        if (!willAdd) {
          setIsModalOpen(true);
        } else {
          toast.success("Renters Insurance is safe!", {
            position: "top-center",
            autoClose: 800,
          });
        }
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handlePolicyDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this insurance policy!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason-input",
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: false,
          visible: true,
          className: "swal-button--confirm",
        },
        cancel: {
          text: "Cancel",
          visible: true,
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const inputValue = document.querySelector("#delete-reason-input").value;

        if (!inputValue.trim()) {
          swal("Error", "Reason for deletion is required!", "error");
          return;
        }

        try {
          const apiUrl = `/tenantinsurance/tenantinsurance/${id}`;
          const response = await axiosInstance.delete(apiUrl, {
            data: { reason: inputValue },
          });

          if (response.data.statusCode === 200) {
            toast.success("Insurance deleted successfully!", {
              position: "top-center",
              autoClose: 2000,
            });
            swal.close();
            getTenantData();
          } else {
            toast.error(response.data.message, {
              position: "top-center",
            });
          }
        } catch (error) {
          toast.error(error.message, {
            position: "top-center",
          });
          console.error("Error:", error.message);
        }
      } else {
        toast.success("Insurance policy is safe :)", {
          position: "top-center",
          autoClose: 500,
        });
      }
    });

    const confirmButton = document.querySelector(".swal-button--confirm");
    confirmButton.disabled = true;

    const inputField = document.querySelector("#delete-reason-input");
    inputField.oninput = () => {
      if (inputField.value.trim() === "") {
        confirmButton.disabled = true;
      } else {
        confirmButton.disabled = false;
      }
    };
  };

  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  const getStatus1 = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (today >= start && today <= end) {
      return "TENANT";
    } else if (today < start) {
      return "FUTURE TENANT";
    } else if (today > end) {
      return "PAST TENANT";
    } else {
      return "-";
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    getTenantData();
  };

  const editNavigate = (id) => {
    navigate(
      admin ? `/${admin}/Leaseing/${id}` : `/staff/staffedittenant/${id}`,
      { state: { id: id } }
    );
  };

  const handlebackclick = () => {
    if (location?.state?.id) {
      navigate(-1);
    } else {
      navigate(admin ?"/" + admin + "/TenantsTable" : `/staff/stafftenanttable`);
    }
  };

  return (
    <div>
      <Header />

      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Row className="mb-3 headerstylee">
          <Col className="text-left">
            <FormGroup className=" align-item-left text-left ml-4">
              {loading ? (
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              ) : (
                <>
                  <h1
                    className="text-left labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    {tenantDetails.tenant_firstName +
                      " " +
                      tenantDetails.tenant_lastName}
                  </h1>
                  <h4
                    className="fontstylerentr titleecolor fontfamilysty"
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Tenant
                  </h4>
                </>
              )}
            </FormGroup>
          </Col>
          <Col className="text-right mr-4">
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                navigate(admin ?`/${admin}/send-email` : `/staff/send-email`, {
                  state: { selectedTenants: [tenantDetails.tenant_id] },
                });
              }}
              size="small"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                cursor: "pointer",
              }}
            >
              Send Mail
            </Button>
            {(admin || permissions?.tenant_edit) && (
              <Button
                className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                onClick={() => {
                  editNavigate(tenantDetails.tenant_id);
                }}
                size="small"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  cursor: "pointer",
                }}
              >
                Edit
              </Button>
            )}
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
             
              onClick={() => handlebackclick()}
              size="small"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                cursor: "pointer",
              }}
            >
              Back
            </Button>
            
          </Col>
        </Row>
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className="mb-3 mx-1 headproptypebar cardbordertenantdetail background-colorsty"
            style={{
              borderRadius: "10px",
              border: "0.5px solid #324567",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              Summary
            </h2>
          </CardHeader>
        </Col>
        <div className="table-responsive">
          <div className="row m-1" style={{ overflow: "hidden" }}>
            <div className="col-md-12">
              {loading ? (
                <tbody className="d-flex flex-direction-column justify-content-center align-items-center">
                  <tr>
                    <div className="p-5 m-5">
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="50"
                        visible={loading}
                      />
                    </div>
                  </tr>
                </tbody>
              ) : tenantDetails.tenant_id ? (
                <>
                  <div
                    className="align-items-center table-flush"
                    responsive
                    style={{ width: "100%" }}
                  >
                    <div className="" style={{ width: "100%" }}>
                      <Row>
                        <Col>
                          <Card
                            className="cardbordertenantdetail backgroundwhitesty"
                            style={{
                              borderRadius: "10px",
                              border: "0.5px solid #324567",
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              padding: "20px",
                            }}
                          >
                            <Row className="my-2">
                              <Col>
                                <span
                                  className="labelfontstyle textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                  }}
                                >
                                  Contact Information
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <Col xs={12} md={4}>
                                <span
                                  className="fontstylerentr titleecolor fontfamilysty"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Name
                                </span>
                                <br />
                                <span
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.tenant_firstName +
                                    " " +
                                    tenantDetails.tenant_lastName || "N/A"}
                                </span>
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                              >
                                <span
                                  className="fontstylerentr titleecolor fontfamilysty"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Phone
                                </span>
                                <br />
                                <a
                                  className="fontstylerentr"
                                  href={`tel:${tenantDetails.tenant_phoneNumber}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="currentColor"
                                    className="bi bi-telephone-outbound"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5" />
                                  </svg>{" "}
                                  {tenantDetails.tenant_phoneNumber || "N/A"}
                                </a>
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                              >
                                <span
                                  className="fontstylerentr titleecolor fontfamilysty"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Email
                                </span>
                                <br />
                                <a
                                  className="fontstylerentr"
                                  href={`mailto:${tenantDetails.tenant_email}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    fill="currentColor"
                                    className="bi bi-envelope-paper"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2zm10 4.267.470.25A1 1 0 0 1 15 5.4v.817l-1 .6zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267zm13 .566v5.734l-4.778-2.867zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083zM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                                  </svg>{" "}
                                  {tenantDetails.tenant_email || "N/A"}
                                </a>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <Card
                            className="cardbordertenantdetail backgroundwhitesty"
                            style={{
                              borderRadius: "10px",
                              border: "0.5px solid #324567",
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              padding: "20px",
                            }}
                          >
                            <Row
                              className="w-100 my-2 textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                textTransform: "capitalize",
                                fontWeight: "600",
                              }}
                            >
                              <Col className="labelfontstyle" xs={12}>
                                Personal Information
                              </Col>
                            </Row>

                            <Row
                              className="w-100 mb-4 titleecolor fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              <Col
                                className="titleecolor fontfamilysty"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                xs={12}
                                md={4}
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  Birth Date
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-calendar-event"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>{" "}
                                  {tenantDetails?.tenant_birthDate
                                    ? moment(
                                        tenantDetails?.tenant_birthDate
                                      ).format(dateFormat)
                                    : "N/A"}
                                </span>
                              </Col>
                              <Col
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                xs={12}
                                md={4}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 titleecolor fontfamilysty"
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  TaxPayer Id
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.taxPayer_id || "N/A"}
                                </span>
                              </Col>
                              <Col
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                xs={12}
                                md={4}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 titleecolor fontfamilysty"
                              >
                                <span className="d-block fontstylerentr  titleecolor fontfamilysty">
                                  Comments
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.comments || "N/A"}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                      {/* 6002 making it look proper */}
                      <Row className="mt-4">
                        <Col>
                          <Card
                            className="cardbordertenantdetail backgroundwhitesty"
                            style={{
                              borderRadius: "10px",
                              border: "0.5px solid #324567",
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              padding: "20px",
                            }}
                          >
                            <Row
                              className="w-100 my-2 textcolorblue fontfamilysty"
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            >
                              <Col className="labelfontstyle" xs={12}>
                                Emergency Contact
                              </Col>
                            </Row>
                            <Row
                              className="w-100 mb-4 titleecolor fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              <Col
                                className="titleecolor fontfamilysty"
                                xs={12}
                                md={3}
                                lg={4}
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  Contact Name
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.emergency_contact?.name ||
                                    "N/A"}
                                </span>
                              </Col>
                              <Col
                                xs={12}
                                md={3}
                                lg={4}
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 titleecolor fontfamilysty"
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  {" "}
                                  Relation With Tenants
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.emergency_contact?.relation ||
                                    "N/A"}
                                </span>
                              </Col>
                            </Row>
                            <Row
                              className="w-100 mb-1 titleecolor fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              <Col
                                xs={12}
                                md={3}
                                lg={4}
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 titleecolor fontfamilysty"
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  {" "}
                                  Emergency Email
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.emergency_contact?.email ||
                                    "N/A"}
                                </span>
                              </Col>
                              <Col
                                xs={12}
                                md={3}
                                lg={4}
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                                className="mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 titleecolor fontfamilysty"
                              >
                                <span className="d-block fontstylerentr titleecolor fontfamilysty">
                                  {" "}
                                  Emergency Phone
                                </span>
                                <span
                                  className="d-block mt-1 fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {tenantDetails.emergency_contact
                                    ?.phoneNumber || "N/A"}
                                </span>
                              </Col>
                            </Row>
                            <Row
                              className="w-100 mt-1 mb-2 textcolorblue fontfamilysty"
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            ></Row>
                          </Card>
                        </Col>
                      </Row>
                      {/* 6002 end */}
                    </div>
                  </div>
                  <div className=" mt-4 ml-0">
                    <Row>
                      <Col>
                        <Card
                          className="cardbordertenantdetail"
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                            border: "0.5px solid #324567",
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            padding: "20px",
                          }}
                        >
                          <Row
                            className="w-100 my-2 textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            <Col className="labelfontstyle d-flex" xs={12}>
                              Renters Insurance Policy
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  marginLeft: "8px",
                                }}
                              >
                                <Button
                                  className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                  onClick={() => handleAdd()}
                                  style={{
                                    marginBottom: "12px",
                                    fontSize: "16px",
                                    border: "1px solid #152B5180",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    fontWeight: "500",
                                  }}
                                >
                                  Add Policy
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row
                            className="mb-1 m-0 p-0"
                            style={{ fontSize: "12px", color: "#000" }}
                          >
                            <Table responsive className="col-12">
                              <tbody
                                className="tbbody p-0 m-0"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  border: "0.5px solid #324567",
                                }}
                              >
                                <tr className="backgroundwhitesty">
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Insurance Company
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Policy Id
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Liability Coverage
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Effective Date
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Expiration Date
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                                {tenantDetails?.tenantInsurance?.length !==
                                0 ? (
                                  tenantDetails?.tenantInsurance?.map(
                                    (item) => (
                                      <tr className="body" key={item.lease_id}>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.status || "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.Provider || "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.policy_id || "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.LiabilityCoverage || "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.EffectiveDate
                                            ? moment(
                                                item?.EffectiveDate
                                              ).format(dateFormat)
                                            : "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          {item.ExpirationDate
                                            ? moment(
                                                item?.ExpirationDate
                                              ).format(dateFormat)
                                            : "N/A"}
                                        </td>
                                        <td
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              marginLeft: "8px",
                                            }}
                                          >
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handlePolicyDelete(
                                                  item.TenantInsurance_id
                                                );
                                              }}
                                            >
                                              <img
                                                src={deleicon}
                                                width={20}
                                                height={20}
                                              />
                                            </div>
                                            {item.status === "ACTIVE" && (
                                              <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setIsModalOpen(true);
                                                  setTenantInsuranceId(
                                                    item.TenantInsurance_id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={editicon}
                                                  width={20}
                                                  height={20}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr className="body">
                                    <td
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                      colSpan={8}
                                    >
                                      Policy Not Added
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 ml-0">
                    <Row>
                      <Col>
                        <Card
                          className="cardbordertenantdetail backgroundwhitesty"
                          style={{
                            borderRadius: "10px",
                            border: "0.5px solid #324567",
                            boxShadow: " 0px 4px 4px 0px #00000040 ",
                            padding: "20px",
                          }}
                        >
                          <Row
                            className="w-100 my-2 textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            <Col className="labelfontstyle" xs={12}>
                              Lease Details
                            </Col>
                          </Row>
                          <Row
                            className="mb-1 m-0 p-0"
                            style={{ fontSize: "12px", color: "#000" }}
                          >
                            <Table responsive className="col-12">
                              <tbody
                                className="tbbody p-0 m-0"
                                style={{
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  borderBottomLeftRadius: "5px",
                                  borderBottomRightRadius: "5px",
                                  border: "0.5px solid #324567",
                                }}
                              >
                                <tr className="backgroundwhitesty">
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Start - End
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Property
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Type
                                  </th>
                                  <th
                                    className="fontstylerentr titleecolor fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      border: "0.5px solid #324567",
                                    }}
                                  >
                                    Rent
                                  </th>
                                </tr>
                                {tenantDetails.leaseData?.length !== 0 ? (
                                  tenantDetails?.leaseData?.map((item) => (
                                    <tr className="body" key={item.lease_id}>
                                      <td
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        {getStatus1(
                                          item.start_date,
                                          item.end_date
                                        )}
                                      </td>
                                      <td
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        {item?.start_date
                                          ? moment(item?.start_date).format(
                                              dateFormat
                                            ) +
                                            " To " +
                                            moment(item?.end_date).format(
                                              dateFormat
                                            )
                                          : "N/A"}
                                      </td>
                                      <td
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        {item.rental_adress || "N/A"}
                                      </td>
                                      <td
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        {item.lease_type || "N/A"}
                                      </td>
                                      <td
                                        className="fontstylerentr textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        {item.rent_amount || "N/A"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr className="body">
                                    <td
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        border: "0.5px solid #324567",
                                      }}
                                      colSpan={8}
                                    >
                                      Lease Not Added
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <tbody>
                  <tr>
                    <td>No tenant details found.</td>
                  </tr>
                </tbody>
              )}
            </div>
          </div>
        </div>
        <br />
        <br />

        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "600px", borderRadius: "20px" }}
        >
          <ModalHeader
            className=" mx-3 mt-5 text-white headproptypebar background-colorsty bgtextwhite"
            style={{
              borderRadius: "6px",
              height: "45px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              padding: "10px 7px 1px 21px",
            }}
          >
            <span
              className=" align-items-center headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "22px",
              }}
            >
              Add Insurance Policy
            </span>
          </ModalHeader>
          <ModalBody>
            <PolicyDialog
              id={id}
              closeModal={closeModal}
              TenantInsuranceId={TenantInsuranceId}
              admin_id={accessType?.admin_id}
            />
          </ModalBody>
        </Modal>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default TenantDetailPage;
