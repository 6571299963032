import { Typography } from "@mui/material";
import { Card, CardBody, Col, Row } from "reactstrap";

const CardTemplate = ({ card }) => {
  const formatExpirationDate = (dateString) => {
    if (dateString?.length !== 4) return dateString;
    const month = dateString.slice(0, 2);
    const year = dateString.slice(2, 4);
    return `${month}/${year}`;
  };

  return (
    <Card
      className="col-9"
      style={{
        boxShadow: "0px 4px 4px 0px #00000040 ",
        borderRadius: "10px",
        width: "75%",
        height: "auto",
        background: (() => {
          if (
            card.cc_type?.toLowerCase() === "mastercard" ||
            card.cc_type?.toLowerCase() === "discover"
          ) {
            return "linear-gradient(103.24deg, #121E2E 0.63%, #3A6194 99.37%)";
          } else if (
            card.cc_type?.toLowerCase() === "visa" ||
            card.cc_type?.toLowerCase() === "jcb"
          ) {
            return "linear-gradient(103.66deg, #000000 1.66%, #666666 98.66%)";
          } else {
            return "linear-gradient(103.23deg, #949BA5 1.73%, #393B3F 99.21%)";
          }
        })(),
        marginTop: "15px",
      }}
    >
      <CardBody className="creditcardbodysty">
        <Row className="d-flex justify-content-space-between">
          <Col className="d-flex justify-content-start">
            <img
              className=""
              src={card.card_logo}
              alt={`${card.cc_type} Logo`}
              style={{
                width: "50px",
                height: "fit-conent",
              }}
            />
          </Col>
          <Col
            className="d-flex justify-content-end fontstylerentmodal"
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            {card.card_type} CARD
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-4">
          <Col>
            <Typography
              className="fontstylerentmodal"
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "600",
                color: "white",

                textTransform: "capitalize",
                marginRight: "10px",
              }}
              color="text.secondary"
              gutterBottom
            >
              {card.cc_number.replace(/(.{4})/g, "$1 ")}
            </Typography>
          </Col>
        </Row>
        <Row className="d-flex justify-content-space-between mt-3">
          <Col
            className="d-flex justify-content-start fontstylerentmodal"
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            Card Holder
          </Col>
          <Col
            className="d-flex justify-content-end fontstylerentmodal"
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            Valid Thru
          </Col>
        </Row>
        <Row className="d-flex justify-content-space-between">
          <Col
            className="d-flex justify-content-start fontstylerentmodal"
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            {card.cardholderName}
          </Col>
          <Col
            className="d-flex justify-content-end fontstylerentmodal"
            style={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "500",
              color: "white",
            }}
          >
            {formatExpirationDate(card.cc_exp)}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardTemplate;
