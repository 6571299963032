import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, CardHeader } from "reactstrap";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import swal from "sweetalert";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

function Plans() {
  const { admin } = useParams();
  let navigate = useNavigate();
  const location  = useLocation();
  const [plans, setPlans] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [loader, setLoader] = React.useState(true);

  useEffect(() => {
    // Fetch plans from the API using Axios
    axiosInstance
      .get(`/plans/plans`)
      .then((response) => {
        // Reverse the order and set the plans state
        setPlans(response.data.data.reverse());
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching plans:", error);
      });
  }, []);

  const FeaturesList = ({ features }) => {
    const [showAllFeatures, setShowAllFeatures] = useState(false);

    // Display the first four features by default
    const displayFeatures = showAllFeatures ? features : features.slice(0, 4);

    return (
      <>
        <ul>
          {displayFeatures.map((feature, index) => (
            <li
              className="fontstylerentmodal"
              style={{
                color: "#152B51",
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
              key={index}
            >
              {feature.features}
            </li>
          ))}
        </ul>
        {!showAllFeatures && features.length > 4 && (
          <Button
            type="button"
            className="btn btn-link"
            onClick={() => setShowAllFeatures(true)}
          >
            Read More
          </Button>
        )}
      </>
    );
  };

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
    };
    fetchData();
  }, []);

  const checkKey = async (plan) => {
    try {
      const res = await axiosInstance.get(
        `/nmi-keys/nmi-keys/${accessType.admin_id}`
      );
      if (res.data.data && admin) {
        navigate("/" + admin + "/Planpurchases", {
          state: { plan: plan },
        });
      } else if (res.data.data) {
        navigate("/plan/Planpurchases", {
          state: { plan: plan },
        });
      } else {
        swal(
          "Warning!",
          "Your NMI account is not linked, contact support.",
          "warning"
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Container
        fluid
        className="stylecontainer containerremovespace mt-5"
        id="home"
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className="mt-3 headproptypebar"
            style={{
              backgroundColor: "#152B51",
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee"
              style={{
                color: "#ffffff",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              Premium Plans
            </h2>
          </CardHeader>
        </Col>
        {loader ? (
          <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={loader}
            />
          </div>
        ) : (
          <Row className="justify-content-center mx-3">
            {plans.map(
              (plan) =>
                plan?.plan_name !== "Free Plan" && (
                  <Col
                    key={plan._id}
                    xs={12}
                    sm={7}
                    md={6}
                    lg={6}
                    xl={3}
                    className="mt-4 mb-3 d-flex flex-column interactive-card mx-3"
                    style={{
                      borderTopRightRadius: "30px",
                    }}
                  >
                    <div
                      className="card h-100 premium-cards premiumcardswidth"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 5px",
                        borderRadius: "20px",
                        border: " 1px solid #152B51",
                      }}
                    >
                      <div
                        className="card-header premiumcardsstyles"
                        style={{
                          backgroundColor: "#152B51",
                          color: "white",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      >
                        <b
                          className="labelfontstyle"
                          style={{ color: "white" }}
                        >
                          <i
                            className="fa-solid fa-calendar"
                            style={{ marginRight: "10px", fontSize: "20px" }}
                          ></i>
                          {plan?.plan_name}
                        </b>
                      </div>
                      <div className="card-body">
                        <div
                          className="card-body"
                          style={{
                            color: "#152B51",
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                          }}
                        >
                          <b
                            className="mb-5 fontstylerentr"
                          
                          >
                            $ {plan.plan_price}
                            <span
                              className="card-title"
                            >
                              /{plan?.billing_interval}
                            </span>
                          </b>
                        </div>
                        <FeaturesList features={plan?.features} />
                        <br />
                      </div>
                      <div
                        className="text-center cardpremiumsstles"
                        style={{ alignItems: "center", marginBottom: "10px" }}
                      >
                        <Button
                          type="button"
                          className="btn btn-secondary first-button fontstylerentmodal"
                          style={{
                            backgroundColor: "#152B51",
                            color: "white",
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            boxShadow: "0px 4px 4px 0px #00000040",
                            border: 0,
                          }}
                          onClick={() => checkKey(plan)}
                        >
                          Get Started
                        </Button>
                      </div>

                      <div
                        className="card-footer  text-center premiumcardfooter"
                        style={{
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <a
                          className="fontstylerentmodal"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            color: "#152B51",
                          }}
                        >
                          Terms apply.
                        </a>
                      </div>
                    </div>
                  </Col>
                )
            )}
          </Row>
        )}
      </Container>
    </>
  );
}

export default Plans;
