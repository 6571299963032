import axiosInstance from "axiosInstance";

export const getUserPermission = async (adminId) => {
  try {
    const response = await axiosInstance.get(
      `/permission/permission/${adminId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    return null;
  }
};
