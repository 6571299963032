import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "./TemplateEditor.css";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
// import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize";

const TemplateEditor = ({
  data,
  setData,
  type = "Manual Tenant",
  isToolTip = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef(null);

  const tipsObject = {
    "Reset password": [
      { "${Name}": "Receiver Name" },
      { "${Url}": "Reset password link" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    Invitation: [
      { "${Name}": "Receiver Name" },
      { "${EmailAddress}": "Email Address" },
      { "${Password}": "Password" },
      { "${Url}": "Login Link" },
      { "${CompanyName}": "Company Name" },
    ],
    "Property assign": [
      { "${Name}": "Receiver Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Unit}": "Unit Address" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Lease creation": [
      { "${Name}": "Receiver Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Unit}": "Unit Address" },
      { "${StartDate}": "Start Date" },
      { "${EndDate}": "End Date" },
      { "${Rent}": "Rent Amount" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Work-order creation": [
      { "${Name}": "Receiver Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Unit}": "Unit Address" },
      { "${Title}": "title" },
      { "${Category}": "Category" },
      { "${Priority}": "Priority" },
      { "${Work_Perform}": "Work to be performed" },
      { "${Status}": "Status" },
      { "${DueDate}": "Due Date" },
      { "${Entry_Allowed}": "Entry allowed" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Work-order updation": [
      { "${Name}": "Receiver Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Unit}": "Unit Address" },
      { "${Title}": "title" },
      { "${Category}": "Category" },
      { "${Priority}": "Priority" },
      { "${Work_Perform}": "Work to be performed" },
      { "${Status}": "Status" },
      { "${DueDate}": "Due Date" },
      { "${Entry_Allowed}": "Entry allowed" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Payment reciept": [
      { "${Name}": "Receiver Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${PaymentDate}": "Payment Date" },
      { "${CurrentDate}": "Current Date" },
      { "${AmountPaid}": "Amount Paid" },
      { "${AmountDue}": "Due Amount" },
      { "${TransactionId}": "Transaction Id" },
      { "${PaymentMethod}": "Payment Method" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "LateFee reminder": [
      { "${Name}": "Receiver Name" },
      { "${Amount}": "Rent amount" },
      { "${LateFee}": "Late Fee percentage" },
      { "${Duration}": "late fee duration" },
      { "${RentalAddress}": "Rental Address" },
      // { "${Unit}": "Unit Name" },
      { "${DueDate}": "Due date to pay Rent" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Lease end Reminder": [
      { "${Name}": "Receivers Name" },
      { "${EndDate}": "Lease End Date" },
      { "${RentalAddress}": "Rental Address" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Express Payment": [
      { "${Name}": "Receivers Name" },
      { "${RentalOwner}": "Rental Owner Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Url}": "Express Payment URL" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
      { "${Duration}": "link valid untill days" },
    ],
    "Payment refund": [
      { "${Name}": "Receiver Name" },
      { "${Amount}": "Refund Amount" },
      { "${TransactionId}": "Transaction Id" },
      { "${Date}": "Refund Date" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Applicant application": [
      { "${Name}": "Receiver Name" },
      { "${RentalAddress}": "Rental Address" },
      { "${Url}": "Application link" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
    "Manual Tenant": [
      { "${Name}": "Receiver Name" },
      { "${EmailAddress}": "E-mail Address" },
      { "${CompanyName}": "Company Name" },
    ],
  };

  const ToolTips = () => {
    if (type) {
      const tips = tipsObject[type];
      if (tips && tips.lenght === 0) return null;

      return (
        <ul>
          {tips.map((tip, index) => {
            const [variable, description] = Object.entries(tip)[0];
            return (
              <li key={index}>
                {variable} - {description}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  };

  const handleSelectVariable = (variable, editor) => {
    const content = editor.getData();
    const olElement = document.createElement("div");
    olElement.innerHTML = content;

    const newData =
      content.slice(0, content.lastIndexOf("<")) +
      variable.split("$")[1] +
      content.slice(content.lastIndexOf("<"), content.lenght);

    setData(newData);
    setDropdownOpen(false);
  };

  const handleEditorChange = (event, editor) => {
    const content = editor.getData();
    setData(content);

    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    const olElement = document.createElement("div");
    olElement.innerHTML = content;
    const lastChar = olElement.textContent.slice(-1);

    if (lastChar === "$") {
      const editorContainer = document.querySelector(".ck-content");
      const editorRect = editorContainer.getBoundingClientRect();

      setDropdownPosition({
        top: rect.top === 0 ? editorRect.top + 30 : rect.top + 10,
        left: rect.left === 0 ? editorRect.left + 30 : rect.left + 10,
      });
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  };

  return (
    <>
      <div className="editor-wrapper">
        <div className="d-flex justify-content-between">
          <label
            className="form-control-label fontstylerentr titleecolor fontfamilysty"
            htmlFor="editor"
            style={{
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            Body
          </label>

          {isToolTip && (
            <Tooltip
              title={
                <Box style={{ fontSize: "14px" }}>
                  You can personalize transaction templates using the following
                  dynamic variables:
                  <ToolTips />
                </Box>
              }
              placement="bottom"
              arrow
            >
              <IconButton>
                <Info />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div id="editor-toolbar"></div>

        <div id="editor-container" className="editor-container">
          <CKEditor
            editor={DecoupledEditor}
            data={data}
            onChange={handleEditorChange}
            onReady={(editor) => {
              editorRef.current = editor;
              const toolbarContainer =
                document.querySelector("#editor-toolbar");
              toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            }}
            config={{
              toolbar: {
                items: [
                  "undo",
                  "redo",
                  "|",
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "insertTable",
                  "blockQuote",
                  "|",
                  "trackChanges",
                  "comment",
                  "|",
                  "alignment",
                  "|",
                  "fontSize",
                  "fontColor",
                  "fontBackgroundColor",
                ],
              },
              fontSize: {
                options: ["tiny", "small", "default", "big", "huge"],
              },
              fontFamily: {
                options: [
                  "default",
                  "Arial",
                  "Courier New",
                  "Georgia",
                  "Times New Roman",
                  "Verdana",
                ],
              },
              font: {
                options: ["default", "Arial", "Times New Roman"],
              },
              styles: {
                "p, h1, h2, h3, h4, h5, h6, body": {
                  "line-height": 0.5,
                },
              },
            }}
          />

          {dropdownOpen && (
            <Menu
              open={dropdownOpen}
              anchorReference="anchorPosition"
              anchorPosition={{
                top: dropdownPosition.top,
                left: dropdownPosition.left,
              }}
              onClose={() => setDropdownOpen(false)}
            >
              {tipsObject[type]?.map((tip, index) => {
                const [variable] = Object.keys(tip);
                return (
                  <MenuItem
                    key={index}
                    onClick={() =>
                      handleSelectVariable(variable, editorRef.current)
                    }
                  >
                    {variable}
                  </MenuItem>
                );
              })}
            </Menu>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateEditor;
