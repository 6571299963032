import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Header from "components/Headers/Header";
import axios from "axios";
import Cookies from "universal-cookie";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jwtDecode } from "jwt-decode";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "axiosInstance";

const Payment = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [prodropdownOpen, setproDropdownOpen] = React.useState(false);
  const [propertyData, setPropertyData] = React.useState([]);
  const [selectedAccount, setselectedAccount] = React.useState("Select");
  const [accountData, setAccountData] = React.useState([]);
  const [bankdropdownOpen, setbankDropdownOpen] = React.useState(false);
  const [selectedProp, setSelectedProp] = React.useState("Select");
  const [daterangedropdownOpen, setDateRangeDropdownOpen] =
    React.useState(false);
  const [selecteddaterange, setSelectedDateRAnge] = React.useState("Select");
  const [GeneralLedgerData, setGeneralLedgerData] = useState();
  const [loader, setLoader] = React.useState(true);
  const toggle1 = () => setproDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setbankDropdownOpen((prevState) => !prevState);
  const toggle3 = () => setDateRangeDropdownOpen((prevState) => !prevState);

  const handlePropSelection = (propertyType) => {
    setSelectedProp(propertyType);
  };
  const handleAccountSelection = (value) => {
    setselectedAccount(value);
  };
  const handleDateRange = (value) => {
    setSelectedDateRAnge(value);
  };
 
  useEffect(() => {
    axiosInstance.get(`/rentals/property_onrent`)
      .then((response) => {
        const data = response.data;
        if (data.statusCode === 200) {
          setPropertyData(data.data);
        } else {
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  }, []);

  useEffect(() => {
    axiosInstance.get(`/addaccount/find_accountname`)
      .then((response) => {
        const data = response.data;
        if (data.statusCode === 200) {
          setAccountData(data.data);
        } else {
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  }, []);
 let cookies = new Cookies();
  const [accessType, setAccessType] = useState(null);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      const jwt = jwtDecode(localStorage.getItem("token"));
      setAccessType(jwt.accessType);
    } else {
      navigate("/auth/login");
    }
  }, [navigate]);

  const getGeneralLedgerData = async () => {
    try {
      const response = await axiosInstance.get(`/payment/payment`);
      setLoader(false);
      setGeneralLedgerData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getGeneralLedgerData();
  }, []);
  function formatDateWithoutTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }
  return (
    <>
      <Header />
      <style>
        {`
            .custom-date-picker {
            background-color: white;
            }
        `}
      </style>
      <Container className="mt--8" fluid>
      <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <h1 style={{ color: "white" }}>Lease Ledger</h1>
            </FormGroup>
          </Col>
          <Col className="text-right">
            <Button
              color="primary"
             //  href="#rms"
              onClick={() => navigate("/admin/AddPayment")}
              size="sm"
              style={{ background: "white", color: "blue" }}
            >
              Recieve Payment
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
     
            <Card className="shadow">
              <CardHeader className="border-0">
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Tenant First Name</th>
                  </tr>
                </thead>
                <tbody>
                  {GeneralLedgerData?.map((generalledger) => (
                    <tr key={generalledger._id}>
                      <td>
                        {formatDateWithoutTime(generalledger.date) || "N/A"}
                      </td>
                      <td>{generalledger.amount}</td>
                      <td>{generalledger.payment_method}</td>
                      <td>{generalledger.tenant_firstName}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
        
          )}
          </div>
        </Row>
        <br />
        <br />
      </Container>
    </>
  );
};

export default Payment;
