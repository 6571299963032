// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* / media  / */

/* / 1680 / */
@media (max-width: 1700px) {}

/* / 1500 / */
@media (max-width: 1680px) {}

/* / 1440 / */
@media (max-width: 1449px) {}

/* / 1280 / */
@media (max-width: 1439px) {}

/* / 992 / */
@media (max-width: 1279px) {}

/* / 768 / */
@media (max-width: 991px) {
 .navbar-toggler{
    display: flex;
    justify-content: end !important;
 }
}

/* / 576 / */
@media (max-width: 767px) {
    .navbar-toggler{
        display: flex;
        justify-content: end !important;
     }
}

/* / 525 / */
@media (max-width: 575px) {
    .navbar-toggler{
        display: flex;
        justify-content: end !important;
     }
}

/* / 480 / */
@media (max-width: 524px) {
    .navbar-toggler{
        display: flex;
        justify-content: end !important;
     }
}

/* / 425 / */
@media (max-width: 479px) {}

/* / 375 / */
@media (max-width: 424px) {}

/* / 320 / */
@media (max-width: 374px) {}

/* 991 */
@media (max-width: 991px) {

    .rentrollbtns {
        width: 132px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        height: 33px !important;
        padding: 3px !important;
        border-radius: 4px !important;
      }
}

`, "",{"version":3,"sources":["webpack://./src/views/source/RentRollDetail.css"],"names":[],"mappings":";AACA,eAAe;;AAEf,aAAa;AACb,4BAA4B;;AAE5B,aAAa;AACb,4BAA4B;;AAE5B,aAAa;AACb,4BAA4B;;AAE5B,aAAa;AACb,4BAA4B;;AAE5B,YAAY;AACZ,4BAA4B;;AAE5B,YAAY;AACZ;CACC;IACG,aAAa;IACb,+BAA+B;CAClC;AACD;;AAEA,YAAY;AACZ;IACI;QACI,aAAa;QACb,+BAA+B;KAClC;AACL;;AAEA,YAAY;AACZ;IACI;QACI,aAAa;QACb,+BAA+B;KAClC;AACL;;AAEA,YAAY;AACZ;IACI;QACI,aAAa;QACb,+BAA+B;KAClC;AACL;;AAEA,YAAY;AACZ,2BAA2B;;AAE3B,YAAY;AACZ,2BAA2B;;AAE3B,YAAY;AACZ,2BAA2B;;AAE3B,QAAQ;AACR;;IAEI;QACI,uBAAuB;QACvB,0BAA0B;QAC1B,2BAA2B;QAC3B,uBAAuB;QACvB,uBAAuB;QACvB,6BAA6B;MAC/B;AACN","sourcesContent":["\n/* / media  / */\n\n/* / 1680 / */\n@media (max-width: 1700px) {}\n\n/* / 1500 / */\n@media (max-width: 1680px) {}\n\n/* / 1440 / */\n@media (max-width: 1449px) {}\n\n/* / 1280 / */\n@media (max-width: 1439px) {}\n\n/* / 992 / */\n@media (max-width: 1279px) {}\n\n/* / 768 / */\n@media (max-width: 991px) {\n .navbar-toggler{\n    display: flex;\n    justify-content: end !important;\n }\n}\n\n/* / 576 / */\n@media (max-width: 767px) {\n    .navbar-toggler{\n        display: flex;\n        justify-content: end !important;\n     }\n}\n\n/* / 525 / */\n@media (max-width: 575px) {\n    .navbar-toggler{\n        display: flex;\n        justify-content: end !important;\n     }\n}\n\n/* / 480 / */\n@media (max-width: 524px) {\n    .navbar-toggler{\n        display: flex;\n        justify-content: end !important;\n     }\n}\n\n/* / 425 / */\n@media (max-width: 479px) {}\n\n/* / 375 / */\n@media (max-width: 424px) {}\n\n/* / 320 / */\n@media (max-width: 374px) {}\n\n/* 991 */\n@media (max-width: 991px) {\n\n    .rentrollbtns {\n        width: 132px !important;\n        font-size: 14px !important;\n        font-weight: 600 !important;\n        height: 33px !important;\n        padding: 3px !important;\n        border-radius: 4px !important;\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
