import {
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import "./tenantstyle.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Img from "../../assets/img/theme/site-logo.png";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const DelinquentTenants = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [tentalsData, setTenantsDate] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  let [loader, setLoader] = React.useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = React.useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [accessType, setAccessType] = React.useState(null);
  let navigate = useNavigate();
  const location  = useLocation();

  const [cookies, setCoockie, removeCookie] = useCookies();

  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  let getTenantsData = async () => {
    if (accessType?.admin_id) {
      try {
        let response = await axiosInstance.get(
          `/charge/delinquent/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          let data = response.data;
          setTenantsDate(data);
          setTotalPages(Math.ceil(data.length / pageItem));
          // setLoader(false);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching tenants data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getTenantsData();
  }, [pageItem, accessType, upArrow, sortBy]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      } 
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  // const paginatedData = tentalsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filterTenantsBySearch = () => {
    let filteredData = [...tentalsData.data];

    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((tenant) => {
        if (!tenant) {
          return false;
        }

        return (
          // tenant.tenants.tenant_name
          //   .toLowerCase()
          //   .includes(lowerCaseSearchQuery) ||
          tenant.rental_address.toLowerCase().includes(lowerCaseSearchQuery)
          // tenant.tenant_phoneNumber.toLowerCase().includes(lowerCaseSearchQuery) ||
          // tenant.tenant_email.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }

    if (upArrow.length > 0) {
      const sortingArrows = upArrow;
      sortingArrows.forEach((sort) => {
        switch (sort) {
          case "tenant_lastName":
            filteredData.sort((a, b) =>
              a.tenant_lastName.localeCompare(b.tenant_lastName)
            );
            break;
          case "tenant_phoneNumber":
            filteredData.sort(
              (a, b) => a.tenant_phoneNumber - b.tenant_phoneNumber
            );
            break;
          case "tenant_email":
            filteredData.sort((a, b) =>
              a.tenant_email.localeCompare(b.tenant_email)
            );
            break;
          case "createdAt":
            filteredData.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            );
            break;
          case "updatedAt":
            filteredData.sort(
              (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
            );
            break;
          default:
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData.slice(startIndex, endIndex);
    return paginatedData;
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  // Function to generate PDF from table data
  const generatePDF = (selectedOption) => {
    const doc = new jsPDF();

    // Centering the logo at the top with increased width
    const img1Width = 15; // Increased width for Img1
    const img1Height = 13; // Adjust height if needed
    doc.addImage(
      Img,
      "JPEG",
      10, // X position (10 units from the left edge)
      15, // Y position (10 units from the top edge)
      img1Width,
      img1Height
    );

    // Add the title "Delinquent Tenants"
    const titleYPosition = 10 + 10; // 10 is the top position, img1Height is the height of the image, 10 is additional space
    doc.setFontSize(20);
    doc.text(
      "Delinquent Tenants",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Get the current date and format it
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const subtitleYPosition = titleYPosition + 10; // Adjust space after the title
    doc.setFontSize(12);
    doc.text(
      `As of ${formattedDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add the contact information on the right
    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");

    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20; // Margin from the top
    const lineHeight = 4; // Adjust this value to increase or decrease the line height

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight // Starting 10 units from the top, adjust line height
      );
    });

    // Reset font size for table and other content
    doc.setFontSize(12);

    // Prepare table data
    const tableData = tentalsData.data
      .map((lease) => {
        const leaseHeader = [
          {
            content: lease.rental_address,
            colSpan: 8,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ];

        const tenantsData = lease.tenants.map((tenant) => [
          tenant.unit_details,
          tenant.tenant_name,
          `$${tenant.pdf_data.totalDaysAmount.toFixed(2)}`,
          `$${tenant.pdf_data.last_30_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_31_to_60_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_61_to_90_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_91_plus_days.toFixed(2)}`,
        ]);

        const totalHeader = [
          { content: `Total`, colSpan: 2, styles: { fontStyle: "bold" } },
          {
            content: `$${lease.alltotalamount.totalDaysAmount.toFixed(2)}`,
            styles: { fontStyle: "bold" },
          },
          {
            content: `$${lease.alltotalamount.last_30_days.toFixed(2)}`,
            styles: { fontStyle: "bold" },
          },
          {
            content: `$${lease.alltotalamount.last_31_to_60_days.toFixed(2)}`,
            styles: { fontStyle: "bold" },
          },
          {
            content: `$${lease.alltotalamount.last_61_to_90_days.toFixed(2)}`,
            styles: { fontStyle: "bold" },
          },
          {
            content: `$${lease.alltotalamount.last_91_plus_days.toFixed(2)}`,
            styles: { fontStyle: "bold" },
          },
        ];

        return [leaseHeader, ...tenantsData, totalHeader];
      })
      .flat();

    // Add the grand total row separately
    const grandTotalHeader = [
      {
        content: `Grand Total of all Properties`,
        colSpan: 2,
        styles: { fontStyle: "bold" },
      },
      {
        content: `$${tentalsData.grandtotal.totalDaysAmount.toFixed(2)}`,
        styles: { fontStyle: "bold" },
      },
      {
        content: `$${tentalsData.grandtotal.last_30_days.toFixed(2)}`,
        styles: { fontStyle: "bold" },
      },
      {
        content: `$${tentalsData.grandtotal.last_31_to_60_days.toFixed(2)}`,
        styles: { fontStyle: "bold" },
      },
      {
        content: `$${tentalsData.grandtotal.last_61_to_90_days.toFixed(2)}`,
        styles: { fontStyle: "bold" },
      },
      {
        content: `$${tentalsData.grandtotal.last_91_plus_days.toFixed(2)}`,
        styles: { fontStyle: "bold" },
      },
    ];

    // Draw the table
    doc.autoTable({
      startY: subtitleYPosition + 20,
      head: [
        [
          "Unit",
          "Tenant",
          // "Last payment",
          "Total",
          "0-30 days",
          "31-60 days",
          "61-90 days",
          "91+ days",
        ],
      ],
      body: [...tableData, grandTotalHeader],
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
    });

    const pageCount = doc.getNumberOfPages();
    const currentDates = new Date();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `${moment(currentDates).format("YYYY-MM-DD HH:ss:mm")}`,
        10,
        doc.internal.pageSize.getHeight() - 8
      );
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 8,
        { align: "right" }
      );
    }

    doc.save("Delinquent_tenants.pdf");
  };

  // Function to generate Excel from table data
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();

    // Create the worksheet data with column headers
    const worksheetData = [
      [
        "Unit",
        "Tenant",
        "Total",
        "0-30 days",
        "31-60 days",
        "61-90 days",
        "91+ days",
      ],
    ];

    tentalsData.data.forEach((lease) => {
      worksheetData.push([lease.rental_address, "", "", "", "", "", ""]);

      lease.tenants.forEach((tenant) => {
        worksheetData.push([
          tenant.unit_details,
          tenant.tenant_name,
          `$${tenant.pdf_data.totalDaysAmount.toFixed(2)}`,
          `$${tenant.pdf_data.last_30_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_31_to_60_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_61_to_90_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_91_plus_days.toFixed(2)}`,
        ]);
      });

      worksheetData.push([
        "Total",
        `$${lease.alltotalamount.totalDaysAmount.toFixed(2)}`,
        `$${lease.alltotalamount.last_30_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_31_to_60_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_61_to_90_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_91_plus_days.toFixed(2)}`,
      ]);
    });

    worksheetData.push([
      "Grand Total",
      `$${tentalsData.grandtotal.totalDaysAmount.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_30_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_31_to_60_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_61_to_90_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_91_plus_days.toFixed(2)}`,
    ]);

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    const wscols = [
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
    ];

    ws["!cols"] = wscols;

    worksheetData.forEach((row, rowIndex) => {
      if (rowIndex % 2 === 0 && rowIndex > 0) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
        if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
        ws[cellAddress].s = { font: { bold: true } };
      } else if (rowIndex % 2 === 1 && row[1] === "Total") {
        row.forEach((_, colIndex) => {
          const cellAddress = XLSX.utils.encode_cell({
            r: rowIndex,
            c: colIndex,
          });
          if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
          ws[cellAddress].s = { font: { bold: true } };
        });
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, "Delinquent Tenants");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "delinquent_tenants.xlsx"
    );
  };

  // Function to generate CSV from table data
  const generateCSV = () => {
    const worksheetData = [
      [
        "Unit",
        "Tenant",
        "Total",
        "0-30 days",
        "31-60 days",
        "61-90 days",
        "91+ days",
      ],
    ];

    tentalsData.data.forEach((lease) => {
      worksheetData.push([lease.rental_address, "", "", "", "", "", ""]);

      // Add tenant data
      lease.tenants.forEach((tenant) => {
        worksheetData.push([
          tenant.unit_details,
          tenant.tenant_name,
          `$${tenant.pdf_data.totalDaysAmount.toFixed(2)}`,
          `$${tenant.pdf_data.last_30_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_31_to_60_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_61_to_90_days.toFixed(2)}`,
          `$${tenant.pdf_data.last_91_plus_days.toFixed(2)}`,
        ]);
      });

      worksheetData.push([
        "Total",
        `$${lease.alltotalamount.totalDaysAmount.toFixed(2)}`,
        `$${lease.alltotalamount.last_30_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_31_to_60_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_61_to_90_days.toFixed(2)}`,
        `$${lease.alltotalamount.last_91_plus_days.toFixed(2)}`,
      ]);
    });

    worksheetData.push([
      "Grand Total",
      `$${tentalsData.grandtotal.totalDaysAmount.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_30_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_31_to_60_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_61_to_90_days.toFixed(2)}`,
      `$${tentalsData.grandtotal.last_91_plus_days.toFixed(2)}`,
    ]);

    // Convert worksheet data to CSV format
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ",", RS: "\n", strip: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "delinquent_tenants.csv");
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Delinquent Tenants
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Row className="mb-3">
                  <Col className="d-flex justify-content-end addtotnstyle">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        className="background-colorsty bgtextwhite fontfamilysty"
                        caret
                        style={{
                          marginBottom: "12px",
                          fontSize: "16px",
                          border: "1px solid #152B5180",
                          boxShadow: "0px 4px 4px 0px #00000040",
                          fontWeight: "500",
                        }}
                      >
                        Exports
                      </DropdownToggle>
                      <DropdownMenu style={{ minWidth: "120px" }}>
                        <DropdownItem
                          className="textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          onClick={() => handleOptionClick("PDF")}
                        >
                          PDF
                        </DropdownItem>
                        <DropdownItem
                          className="textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          onClick={() => handleOptionClick("XLSX")}
                        >
                          XLSX
                        </DropdownItem>
                        <DropdownItem
                          className="textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          onClick={() => handleOptionClick("CSV")}
                        >
                          CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className="fontfamilysty"
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Unit
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Tenant name
                      </th>

                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Total Amount
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Last 30 Days
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Last 31 to 60 Days
                      </th>

                      <th className="tablefontstyle textcolorblue" scope="col">
                        Last 61 to 90 Days
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Last 91+ Days
                      </th>
                    </tr>
                  </thead>
                  {tentalsData?.length === 0 && !loader ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="8" style={{ fontSize: "15px" }}>
                          No Delinquent Tenants
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage().map((property, index) => (
                        <>
                          <tr
                            className="fontfamilysty"
                            key={index}
                            style={{
                              cursor: "pointer",
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontWeight: "800",
                              color: "blue",
                            }}
                          >
                            <td
                              className="bordertopintd tablebodyfont"
                              colSpan={7}
                            >
                              {property.rental_address}
                            </td>
                          </tr>
                          {property.tenants.map((tenant) => (
                            <tr
                              key={tenant.tenant_id}
                              style={{
                                cursor: "pointer",
                                border: "0.5px solid rgba(50, 69, 103, 1)",
                                fontSize: "12px",
                                height: "40px",
                                fontFamily: "poppins",
                                fontWeight: "600",
                              }}
                            >
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                {tenant.unit_details || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                {tenant.tenant_name || "-"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                $
                                {tenant.pdf_data?.totalDaysAmount.toFixed(2) ||
                                  "0.00"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                $
                                {tenant.pdf_data?.last_30_days.toFixed(2) ||
                                  "0.00"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                $
                                {tenant.pdf_data?.last_31_to_60_days.toFixed(
                                  2
                                ) || "0.00"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                $
                                {tenant.pdf_data?.last_61_to_90_days.toFixed(
                                  2
                                ) || "0.00"}
                              </td>
                              <td className="bordertopintd tablebodyfont tabletextcolor">
                                $
                                {tenant.pdf_data?.last_91_plus_days.toFixed(
                                  2
                                ) || "0.00"}
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  )}
                </Table>
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default DelinquentTenants;
