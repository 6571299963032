import { ListItemText, MenuItem, Select } from "@mui/material";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";
import { generateCSV, generateExcel, generatePDF } from "./functions";

const InputGroup = ({
  label,
  type = "text",
  name = "name",
  placeholder = "Enter Name",
  error = "",
  onChange,
  ...props
}) => {
  return (
    <FormGroup className="d-flex flex-column">
      {label && (
        <label
          className="form-control-label fontstylerentr titleecolor fontfamilysty"
          htmlFor="input-unitadd"
          style={{
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {label}
        </label>
      )}

      <Input
        style={{
          boxShadow: "0px 4px 4px 0px #00000040 ",
          borderRadius: "6px",
          width: "100%",
        }}
        className="form-control-alternative fontstylerentmodal titleecolor"
        placeholder={placeholder}
        type={type}
        name={name}
        onChange={onChange}
        {...props}
      />
      {error}
    </FormGroup>
  );
};

const Report = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { admin } = useParams();

  const [cookies, setCoockie, removeCookie] = useCookies();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      if (accessData) {
        if (accessData?.admin_id) {
          try {
            const [response1, userData] = await Promise.all([
              axiosInstance.get(
                `/payment/admin_balance/${accessData?.admin_id}?report=${true}`
              ),
              axiosInstance.get(`/admin/admin_profile/${accessData?.admin_id}`),
            ]);
            setData(response1.data?.data);
            setUserProfile(userData?.data?.data);
          } catch (error) {
            console.error(error);
          } finally {
            setLoader(false);
          }
        }
      } else {
        setLoader(false);
      }
    };

    handleCheckToken();
  }, []);

  const types = ["Charges", "Payments"];
  const month = {
    Charges: ["Current Month", "Last Month", "All"],
    Payments: ["Current Month", "Last Month"],
  };

  const [selectedType, setSelectedType] = useState("Charges");
  const [selectedMonth, setSelectedMonth] = useState("Current Month");
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [tableData, setTableData] = useState([]);
  const [exportsData, setExportsData] = useState([]);

  const handleSearch = (data, search) => {
    const entries = data?.charges || data?.payments || [];
    const searchQuery = search.toString().trim().toLowerCase();

    const filteredData = entries.filter((item) => {
      const search = searchQuery.toLowerCase();
    
      // Check if any tenant matches the search query
      const tenantMatch = item?.tenant_data?.some((tenant) => {
        const tenantName = `${tenant?.tenant_firstName || ""} ${
          tenant?.tenant_lastName || ""
        }`.toLowerCase();
    
        return (
          tenant?.tenant_firstName?.toLowerCase().includes(search) ||
          tenant?.tenant_lastName?.toLowerCase().includes(search) ||
          tenantName.includes(search)
        );
      });
    
      return (
        item?.rental_data?.rental_adress?.toString().toLowerCase().includes(search) ||
        item?.unit_data?.rental_unit?.toString().toLowerCase().includes(search) ||
        tenantMatch
      );
    });    
    return data?.charges
      ? { charges: filteredData, total: data?.total }
      : { payments: filteredData, total: data?.total };
  };

  const handleChange = () => {
    if (selectedType && selectedMonth && data) {
      let chargesData;

      if (selectedType === "Charges") {
        if (selectedMonth === "Current Month")
          chargesData = data?.currentDueRentCharges;
        else if (selectedMonth === "Last Month")
          chargesData = data?.lastDueRentCharges;
        else if (selectedMonth === "All") chargesData = data?.dueRentCharges;
      } else if (selectedType === "Payments") {
        if (selectedMonth === "Current Month")
          chargesData = data?.currentPayments;
        else if (selectedMonth === "Last Month")
          chargesData = data?.lastPayments;
      }

      chargesData = chargesData?.charges
        ? {
            charges: chargesData?.charges?.sort((a, b) => {
              if (typeof a.rental_data?.rental_adress === "string") {
                return a.rental_data?.rental_adress?.localeCompare(
                  b.rental_data?.rental_adress
                );
              }
              return (
                a.rental_data?.rental_adress - b.rental_data?.rental_adress
              );
            }),
            total: chargesData?.total,
          }
        : {
            payments: chargesData?.payments?.sort((a, b) => {
              if (typeof a.rental_data?.rental_adress === "string") {
                return a.rental_data?.rental_adress?.localeCompare(
                  b.rental_data?.rental_adress
                );
              }
              return (
                a.rental_data?.rental_adress - b.rental_data?.rental_adress
              );
            }),
            total: chargesData?.total,
          };

      setExportsData(chargesData);

      const finalData = search
        ? handleSearch(chargesData, search)
        : chargesData;

      const startIndex = (currentPage - 1) * rowPerPage;
      const endIndex = currentPage * rowPerPage;

      setTotalPages(
        Math.ceil(
          (finalData?.charges?.length || finalData?.payments?.length || 1) /
            rowPerPage
        )
      );

      const paginatedData = finalData?.charges
        ? finalData?.charges?.slice(startIndex, endIndex)
        : finalData?.payments?.slice(startIndex, endIndex);

      setTableData(
        finalData?.charges
          ? {
              charges: paginatedData?.sort((a, b) => {
                if (typeof a.rental_data?.rental_adress === "string") {
                  return a.rental_data?.rental_adress?.localeCompare(
                    b.rental_data?.rental_adress
                  );
                }
                return (
                  a.rental_data?.rental_adress - b.rental_data?.rental_adress
                );
              }),
              total: finalData?.total,
            }
          : {
              payments: paginatedData?.sort((a, b) => {
                if (typeof a.rental_data?.rental_adress === "string") {
                  return a.rental_data?.rental_adress?.localeCompare(
                    b.rental_data?.rental_adress
                  );
                }
                return (
                  a.rental_data?.rental_adress - b.rental_data?.rental_adress
                );
              }),
              total: finalData?.total,
            }
      );
    }
  };

  useEffect(() => {
    handleChange();
  }, [selectedType, selectedMonth, data, search, currentPage, rowPerPage]);

  useEffect(() => {
    if (location.state.type) {
      setSelectedType(location.state.type);
    }
    if (location.state.month) {
      setSelectedMonth(location.state.month);
    }
  }, [location.state]);

  const renderRow = (entry) => (
    <tr
      key={entry?.lease_id}
      style={{
        fontSize: "12px",
        height: "40px",
        fontFamily: "Poppins",
        fontWeight: "600",
      }}
    >
      <td
        className="dailyreporttablefont rentrollcolorsty"
        style={{ border: 0, cursor: "pointer" }}
        onClick={() => navigate(`/${admin}/rentrolldetail/${entry?.lease_id}`)}
      >
        {(entry?.rental_data?.rental_adress || "") +
          (entry?.unit_data?.rental_unit
            ? ` - ${entry?.unit_data?.rental_unit}`
            : " ")}
      </td>

      <td className="dailyreporttablefont tabletextcolor" style={{ border: 0 }}>
        {entry.tenant_data.length > 0 ? (
          entry.tenant_data.map((tenant, index) =>
            index === 0 ? (
              <>{tenant.tenant_firstName + " " + tenant.tenant_lastName}</>
            ) : (
              <>
                ,
                <br />
                {tenant.tenant_firstName + " " + tenant.tenant_lastName}
              </>
            )
          )
        ) : (
          <span>{"-"}</span>
        )}
      </td>

      <td
        className="dailyreporttablefont tabletextcolor"
        style={{
          border: 0,
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        {!entry.total
          ? "$0.00"
          : `$${
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry.total) || "0.00"
            }`}
      </td>
    </tr>
  );

  return (
    <>
      <Container className="stylecontainer pt-3" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                {location.state.title || "Report"}
              </h2>
            </CardHeader>
          </Col>
        </Row>

        <br />

        <Row className="mb-3">
          <Col lg="2" md="3" sm="5" xl="2">
            <FormGroup className="d-flex flex-column">
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-date"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Select Type
              </label>

              <Select
                labelId="user-select-label"
                id="user-select"
                name="payment_method"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                  setSelectedMonth("Current Month");
                }}
                displayEmpty
                renderValue={(selected) => {
                  return selected || "Select Event";
                }}
              >
                {types.length > 0 &&
                  types?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <ListItemText primary={item || ""} />
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup>
          </Col>

          <Col lg="2" md="3" sm="5" xl="2">
            <FormGroup className="d-flex flex-column">
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-date"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Select Month
              </label>

              <Select
                labelId="user-select-label"
                id="user-select"
                name="payment_method"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                displayEmpty
                renderValue={(selected) => {
                  return selected || "Select Event";
                }}
              >
                {month[selectedType]?.length > 0 &&
                  month[selectedType]?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <ListItemText primary={item || ""} />
                    </MenuItem>
                  ))}
              </Select>
            </FormGroup>
          </Col>

          <Col lg="2" md="3" sm="5" xl="2">
            <FormGroup className="d-flex flex-column">
              <InputGroup
                label="Search"
                name="search"
                type="text"
                autoComplete="off"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Search here..."
              />
            </FormGroup>
          </Col>

          {(tableData?.charges?.length > 0 ||
            tableData?.payments?.length > 0) && (
            <Col className="d-flex justify-content-end addtotnstyle mt-4">
              <Select
                labelId="user-select-label"
                className="background-colorsty bgtextwhite"
                id="user-select"
                name="payment_method"
                value=""
                onChange={(e) => {
                  if (e.target.value === "PDF") {
                    generatePDF(exportsData, userProfile, location.state.title);
                  }
                  if (e.target.value === "Excel") {
                    generateExcel(exportsData, userProfile);
                  }
                  if (e.target.value === "CSV") {
                    generateCSV(exportsData, userProfile);
                  }
                }}
                displayEmpty
                renderValue={(selected) => {
                  return selected || "Exports";
                }}
                sx={{
                  "& .MuiSelect-icon": {
                    color: "var(--text-white-color)",
                  },
                }}
              >
                {[
                  { label: "PDF", value: "PDF" },
                  { label: "Excel", value: "Excel" },
                  { label: "CSV", value: "CSV" },
                ]?.length > 0 &&
                  [
                    { label: "PDF", value: "PDF" },
                    { label: "Excel", value: "Excel" },
                    { label: "CSV", value: "CSV" },
                  ]?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      <ListItemText primary={item?.label || ""} />
                    </MenuItem>
                  ))}
              </Select>
            </Col>
          )}
        </Row>

        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <thead
                className="fontfamilysty"
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "none",
                  border: 0,
                }}
              >
                <tr className="" style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={2}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {selectedType === "Charges" ? "Rent Due" : "Rent Paid"}
                  </td>
                  <td
                    className="tabletextcolor  text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {`$${
                      new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(tableData?.total) || "0.00"
                    }`}
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "50%" }}
                  >
                    Property
                  </th>

                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0, width: "30%" }}
                  >
                    Tenant
                  </th>

                  <th
                    className="tablefontstyle textcolorblue text-right"
                    scope="col"
                    style={{ fontWeight: "Bold", border: 0, width: "20%" }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData?.charges?.length > 0 &&
                  tableData?.charges?.map(renderRow)}

                {tableData?.payments?.length > 0 &&
                  tableData?.payments?.map(renderRow)}

                {(!tableData?.charges || tableData?.charges?.length === 0) &&
                  (!tableData?.payments ||
                    tableData?.payments?.length === 0) && (
                    <tr className="text-center py-3">
                      <td
                        colSpan="3"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        No Data Available
                      </td>
                    </tr>
                  )}
              </tbody>
            </>
          )}
        </Table>

        <Row>
          <Col className="text-right mt-3 mb-3">
            <Select
              labelId="user-select-label"
              className="mx-2"
              id="user-select"
              name="payment_method"
              value={rowPerPage}
              onChange={(e) => setRowPerPage(e.target.value)}
              displayEmpty
              renderValue={(selected) => {
                return selected || "Select Event";
              }}
            >
              {[10, 25, 50, 100].length > 0 &&
                [10, 25, 50, 100]?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <ListItemText primary={item || ""} />
                  </MenuItem>
                ))}
            </Select>
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-left"
                viewBox="0 0 16 16"
              >
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
              </svg>
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>{" "}
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </Button>{" "}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Report;
