import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Col, Row } from "reactstrap";
import axiosInstance from "axiosInstance";
import LeaseTable from "../components/LeaseTable";
import CronPaymentTable from "components/CronPaymentTable";

const  DailyCronPayments
 = ({ adminId }) => {
      const [payments, setPayments] = useState([]);
      const [paymentLoader, setPaymentLoader] = useState(false);
      const [totalPayments, setTotalPayments] = useState(0);
      const [paymentPage, setPaymentPage] = useState(0);
      const [paymentRowsPerPage, setPaymentRowsPerPage] = useState(5);

    const fetchPayments = async (page, limit, admin_id) => {
        setPaymentLoader(true);
      try {
        page = parseInt(page)+1;
        const response = await axiosInstance.get(`/payment/cronjob-payments/${admin_id}`, {
          params: { page, limit},
        });
        setPayments(response.data.data || []);
        setTotalPayments(response.data.metadata.total || 0);
      } catch (error) {
        console.error("Error fetching policies:", error);
      } finally {
        setPaymentLoader(false);
      }
    };

  useEffect(() => {
    if (adminId) {
        fetchPayments(paymentPage, paymentRowsPerPage, adminId);
    }
  }, [paymentPage, paymentRowsPerPage, adminId]);
  return (
    <div style={{ width: "100%" }}>
      {/* <Row>
        <Col className="mx-3"> */}
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center dashtable-title"
                style={{
                  height: "50px",
                  backgroundColor: "#324B77",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                  fontSize: "16px",
                  width: "100%",
                }}
              >
                Payments Last 7 days
              </span>
            </Typography>
            {payments.length === 0 ? (
              <Typography>No failed payment data found.</Typography>
            ) : (
              <CronPaymentTable
                payments={payments}
                paymentLoader={paymentLoader}
                totalPayments={totalPayments}
                paymentPage={paymentPage}
                paymentRowsPerPage={paymentRowsPerPage}
                setPaymentPage={setPaymentPage}
                setPaymentRowsPerPage={setPaymentRowsPerPage}
                fetchPayments={fetchPayments}
                adminId={adminId}
              />
            )}
          </CardContent>
        {/* </Col>
      </Row> */}
    </div>
  );
};

export default DailyCronPayments;
;
