const TabButton = ({ onClick, name, label, selectedOption }) => {
  return (
    <li style={{ borderBottom: "1px solid #ccc" }}>
      <div
        className="barforsummaryrent"
        color="link"
        onClick={() => onClick(name)}
        style={{
          backgroundColor: selectedOption === name ? "#D3D3D3" : "inherit",
          color: "#152B51",
          cursor: "pointer",
          fontWeight: "600",
          padding: "20px 15px",
          fontFamily: "Poppins",
        }}
      >
        {label}
      </div>
    </li>
  );
};

export default TabButton;
