//6002 upcoming renewal
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCookies } from "react-cookie";
import Header from "components/Headers/Header";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./vendor.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";

const StaffUpcomingRenewal = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const location  = useLocation();
  const { admin } = useParams();
  const [upcomingrenewal, setUpcomingRenewal] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [upArrow, setUpArrow] = useState([]);
  const [sortBy, setSortBy] = useState([]);

  useEffect(() => {
    getRenewalData();
  }, [pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (upcomingrenewal) {
    paginatedData = upcomingrenewal.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const getRenewalData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/leases/renewal_leases/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          setUpcomingRenewal(response.data.data);
          setTotalPages(Math.ceil(response.data.data.length / pageItem));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    getRenewalData();
  }, [accessType, pageItem]);

  const Notrenew = async (id) => {
    // Show a confirmation dialog to the user
    swal({
      title: "Are you sure?",
      text: "You will not be able to renew this lease!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    }).then(async (willToggle) => {
      if (willToggle) {
        try {
          const response = await axiosInstance.put(
            `/leases/update_not_renewing/${id}`
          );

          if (response.data.statusCode === 200) {
            toast.success("Lease will not renew!", {
              position: "top-center",
              autoClose: 1000,
            });
            getRenewalData();
          } else {
            toast.error(response.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error not renewing:", error);
        }
      }
    });
  };

  const filterTenantsBySearch = () => {
    let filteredData = upcomingrenewal;

    if (searchQuery) {
      filteredData = filteredData.filter((renewal) => {
        const isNameMatch = renewal?.rental_address
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const isDesignationMatch = renewal?.tenantNames
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const isMailMatch = renewal?.remainingDays
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        return isNameMatch || isDesignationMatch || isMailMatch;
      });
    }

    if (upArrow.length > 0) {
      upArrow.forEach((value) => {
        switch (value) {
          case "rental_address":
            filteredData.sort((a, b) =>
              a.rental_address?.localeCompare(b.rental_address)
            );
            break;

          case "remainingdays":
            filteredData.sort((a, b) => a.remainingDays - b.remainingDays);
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    if (upArrow.length === 0) {
      upArrow.forEach((value) => {
        switch (value) {
          case "rental_address":
            filteredData.sort((a, b) =>
              b.rental_address?.localeCompare(a.rental_address)
            );
            break;

          case "remainingdays":
            filteredData.sort((a, b) => b.remainingDays - a.remainingDays);
            break;
          default:
            // If an unknown sort option is provided, do nothing
            break;
        }
      });
    }

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };
  const sortData = (value) => {
    if (!sortBy.includes(value)) {
      setSortBy([...sortBy, value]);
      setUpArrow([...upArrow, value]);
      filterTenantsBySearchAndPage();
    } else {
      setSortBy(sortBy.filter((sort) => sort !== value));
      setUpArrow(upArrow.filter((sort) => sort !== value));
      filterTenantsBySearchAndPage();
    }
    // setOnClickUpArrow(!onClickUpArrow);
  };

  useEffect(() => {
    getRenewalData();
  }, [upArrow, sortBy]);

  const displayTenantNames = (tenantNames) => {
    if (!Array.isArray(tenantNames)) {
      tenantNames = tenantNames ? tenantNames.split(",") : [];
    }

    const pairs = [];
    for (let i = 0; i < tenantNames.length; i += 2) {
      pairs.push(
        <>
          <span
            key={i}
            className=" "
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={(e) => {
              navigate(`/staff/stafftenantdetail/${tenantNames[i].id}`, {
                state: { id: "id" },
              });
            }}
          >
            {tenantNames[i].name}
          </span>
          {tenantNames[i + 1] && (
            <span
              key={i}
              className=" "
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={(e) => {
                navigate(`/staff/stafftenantdetail/${tenantNames[i + 1].id}`, {
                  state: { id: "id" },
                });
              }}
            >
              , {tenantNames[i + 1].name}
            </span>
          )}
          <br />
        </>
      );
    }
    return pairs;
  };

  return (
    <>
      <Header />
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Upcoming Renewal
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />

        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                {/* <Card className="shadow"> */}
                {/* <CardHeader className="border-0"> */}
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh"
                        // fullWidth
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          borderTopLeftRadius: "15px",
                        }}
                      >
                        Property address
                        {sortBy.includes("rental_address") ? (
                          upArrow.includes("rental_address") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("rental_address")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("rental_address")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("rental_address")}
                          />
                        )}
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Tenant Name
                      </th>
                      <th className="tablefontstyle textcolorblue" scope="col">
                        Number of days
                        {sortBy.includes("remainingdays") ? (
                          upArrow.includes("remainingdays") ? (
                            <ArrowDropUpIcon
                              onClick={() => sortData("remainingdays")}
                            />
                          ) : (
                            <ArrowDropDownIcon
                              onClick={() => sortData("remainingdays")}
                            />
                          )
                        ) : (
                          <ArrowDropDownIcon
                            onClick={() => sortData("remainingdays")}
                          />
                        )}
                      </th>
                      <th
                        className="tablefontstyle text-center textcolorblue"
                        scope="col"
                        style={{
                          borderTopRightRadius: "15px",
                        }}
                      >
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  {upcomingrenewal?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="4">No Upcoming Renewal Found</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        {/* Empty row */}
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((renewal) => (
                        <tr
                          key={renewal.lease_id}
                          style={{
                            border: "0.5px solid rgba(50, 69, 103, 1)",
                            fontSize: "12px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          <td
                            className="bordertopintd tablebodyfont tabletextcolor"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              navigate(
                                `/staff/staffrentrolldetail/${renewal.lease_id}`,
                                { state: { leas_id: renewal.lease_id } }
                              );
                            }}
                          >
                            {renewal.rental_address}{" "}
                            {renewal?.unit ? " - " + renewal?.unit : null}{" "}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {displayTenantNames(renewal.tenantNames)}
                          </td>
                          <td className="bordertopintd tablebodyfont tabletextcolor">
                            {renewal.remainingDays} Days
                          </td>
                          <td className="bordertopintd tablebodyfont text-center">
                            <div style={{ gap: "5px" }}>
                              <Button
                                className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
                                onClick={() => {
                                  navigate(
                                    `/staff/staffrentrolldetail/${renewal.lease_id}`,
                                    { state: { lease_id: renewal.lease_id } }
                                  );
                                }}
                                size="small"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "10px",
                                  border: "0",
                                }}
                              >
                                Renew lease
                              </Button>
                              <Button
                                className="vendorbtnstyle background-colorsty bgtextwhite fontfamilysty"
                                onClick={() => Notrenew(renewal.lease_id)}
                                size="small"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "10px",
                                  border: "0",
                                }}
                              >
                                Not renewing
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {/* </Card> */}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default StaffUpcomingRenewal;
//end
