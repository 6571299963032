// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#editor-toolbar {
  background-color: #f0f0f0;
  border: 1px solid var(--bg-blue-color);
  border-bottom: 0;
}

.editor-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* border: 1px solid var(--bg-blue-color) !important; */
  height: 40vh;
}

.ck.ck-toolbar {
  border: none;
}

.ck-editor__editable_inline {
  height: 100%;
  border: 1px solid var(--bg-blue-color) !important;
  outline: none !important;
  border-top: 0;
}

.ck-focused {
  border: 1px solid var(--bg-blue-color) !important;
  outline: none !important;
  border-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/source/TemplateEditor.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,sCAAsC;EACtC,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uDAAuD;EACvD,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iDAAiD;EACjD,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,iDAAiD;EACjD,wBAAwB;EACxB,aAAa;AACf","sourcesContent":[".editor-wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n#editor-toolbar {\n  background-color: #f0f0f0;\n  border: 1px solid var(--bg-blue-color);\n  border-bottom: 0;\n}\n\n.editor-container {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  /* border: 1px solid var(--bg-blue-color) !important; */\n  height: 40vh;\n}\n\n.ck.ck-toolbar {\n  border: none;\n}\n\n.ck-editor__editable_inline {\n  height: 100%;\n  border: 1px solid var(--bg-blue-color) !important;\n  outline: none !important;\n  border-top: 0;\n}\n\n.ck-focused {\n  border: 1px solid var(--bg-blue-color) !important;\n  outline: none !important;\n  border-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
