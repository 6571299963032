import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

const Changepassword = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { role } = useParams();
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    axios
      .get(`${baseUrl}/admin/check_token_status/${token}`)
      .then((response) => {
        const data = response.data;
        if (data.expired) {
          setTokenExpired(true);
        } else {
          setEmail(token);
        }
      })
      .catch((error) => console.error("Error checking token status:", error));
  }, [baseUrl, location.search]);

  const passwordValidationSchema = yup.object({
    newPassword: yup.string()
    .min(12, 'Password must be at least 12 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .notOneOf([/1234/, /abcd/, /qwerty/, /password/, /\d{4}/, /[a-z]{4}/], 'Avoid sequential or repeating patterns like "1234", "abcd", etc.')
    .required('Password is required'),

    confirmPassword: yup
      .string()
      .required("Confirm your password")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const response = await axios.put(
          `${baseUrl}/admin/reset_passwords/${email}`,
          { password: formik.values.newPassword, role: role },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          toast.success("Password Changed Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate(response.data.url);
          }, 1000);
        } else if(response.status === 203){
          swal({
            text: response.data.message,
            icon: "warning"
           })
        }else {
          toast.error("Failed To Change Password", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        setErrors({
          newPassword: "An error occurred while changing the password",
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          {tokenExpired ? (
            <div className="text-danger">
              The password reset link has expired. Please request a new one.
            </div>
          ) : (
            <div>
              <div className="text-center text-muted mb-4">
                <big>Change Password</big>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                      className="form-control"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <IconButton
                      type="button"
                      style={{ padding: "7px" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </InputGroup>
                  {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="text-danger">
                      {formik.errors.newPassword}
                    </div>
                  )}
                </FormGroup>

                <FormGroup className="mb-4">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type={showPassword1 ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="form-control"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <IconButton
                      type="button"
                      style={{ padding: "7px" }}
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </InputGroup>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="text-danger">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </FormGroup>

                <div className="text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={formik.isSubmitting}
                    style={{ backgroundColor: "#152B51", color: "#fff" }}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="grey"
                    onClick={() => navigate(`/auth/login`)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          )}
        </CardBody>
      </Card>
      <ToastContainer />
    </Col>
  );
};

export default Changepassword;
