import { useEffect, useState } from "react";
import BaseInput from "components/ReusableComponents/BaseInput";
import BaseButton from "components/ReusableComponents/BaseButton";
import "./Form.scss";

const PaymentForm = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address1: "",
    city: "",
    country: "",
    state: "",
    zip: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (props.preFillData) {
      for (const key in props.preFillData) {
        setFormData((pre) => ({
          ...pre,
          [key]: props.preFillData[key],
        }));
      }
    }
  }, [props.preFillData]);

  const areFieldsFilled = () => {
    return Object.values(formData).every((field) => field?.trim() === "");
  };

  const labelStyles = {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    margin: "5px 0px",
  };

  /* global CollectJS */
  useEffect(() => {
    setDisabled(areFieldsFilled());

    try {
      setLoader(false);
      if (typeof CollectJS !== "undefined") {
        CollectJS.configure({
          callback: async function (response) {
            setLoader(true);
            const responseData = {
              ...formData,
              payment_token: response.token,
              cc_bin: response.card?.bin,
            };
            props.addCardInfo(responseData);
          },
          variant: "inline",
          invalidCss: {
            color: "#B40E3E",
          },
          validCss: {
            color: "#14855F",
          },
          customCss: {
            border: " 1px solid #ccc",
            width: " 100%",
            border: "radius: 5px",
            height: " 40px",
            padding: "0.625rem 0.75rem",
            outline: " none",
            "font-size": "15px",
          },
          fields: {
            cvv: {
              placeholder: "CVV",
            },
            ccnumber: {
              placeholder: "Credit Card",
            },
            ccexp: {
              placeholder: "MM / YY",
            },
          },
        });
      } else {
        console.warn("CollectJS is not defined.");
      }
    } catch (error) {
      console.error("An error occurred while configuring CollectJS:", error);
    }
  }, [formData]);

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const handlePhoneChange = (e) => {
    if (formData.phone?.length > e.target.value?.length) {
      setFormData({ ...formData, phone: e.target.value });
    } else {
      const formattedValue = formatPhoneNumber(e.target.value);
      setFormData({ ...formData, phone: formattedValue });
    }
  };

  return (
    <form style={{ margin: "0px auto" }}>
      <input type="hidden" name="variant" value="inline" />
      <input type="hidden" name="amount" value="5.00" />
      <div className="formInner">
        <div style={{ display: "flex", gap: "10px" }} className="full-fields">
          <BaseInput
            showStar
            onChange={(event) => handleChange(event)}
            name="first_name"
            placeholder="First Name"
            label="First Name"
            value={formData.first_name}
            styles={{ flex: "1" }}
          />
          <BaseInput
            showStar
            onChange={(event) => handleChange(event)}
            name="last_name"
            placeholder="Last Name"
            label="Last Name"
            value={formData.last_name}
            styles={{ flex: "1" }}
          />
        </div>
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          placeholder="Email"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
        />
        <BaseInput
          showStar
          onChange={(e) => handlePhoneChange(e)}
          placeholder="Enter phone number"
          label="Valid Phone Number"
          name="phone"
          type="text"
          pattern="^\+?[0-9\-]+$"
          value={formData.phone}
        />
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          name="address1"
          placeholder="Address"
          label="Address"
          value={formData.address1}
        />
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          placeholder="City"
          label="City"
          name="city"
        />
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          placeholder="State"
          label="State"
          name="state"
        />
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          placeholder="Zip"
          label="Zip"
          name="zip"
        />
        <BaseInput
          showStar
          onChange={(event) => handleChange(event)}
          placeholder="Country"
          label="Country"
          name="country"
        />

        <div id="payment-fields">
          <div className="full-fields">
            <label style={labelStyles}>
              Card Number <span style={{ color: "red" }}>*</span>
            </label>
            <div className="payment-field" id="ccnumber"></div>
          </div>
          <div className="half-fields">
            <label style={labelStyles}>
              Expiry Date <span style={{ color: "red" }}>*</span>
            </label>
            <div className="payment-field" id="ccexp"></div>
          </div>
          <div className="half-fields">
            <label style={labelStyles}>
              CVV <span style={{ color: "red" }}>*</span>
            </label>
            <div className="payment-field" id="cvv"></div>
          </div>
        </div>
      </div>
      <p style={{ margin: "20px 0px" }}>
        Please fill all the required fields{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>*</span>
      </p>
      <div style={{ display: "flex", gap: "10px" }}>
        <BaseButton
          id="payButton"
          type="submit"
          disabled={disabled || loader}
          label={ loader ? "Loading..." : "Add Card"}
        />
        <BaseButton onClick={props.onHide} variant="outline" label="Cancel" />
      </div>
    </form>
  );
};

export default PaymentForm;
